import styled from 'styled-components';


export const FormularioComponent = styled.div.attrs({
    className: ``
})`
    
    .carregamento{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background: #80808036;
        border-radius: 3px;
    }
    
    .filtros{
        display: grid;
        grid-gap: 0.8em;
    }

    .preview-markdown{
        min-height: 40px;
        overflow: auto;
        max-height: 60vh;

        .wmde-markdown{

            img{
                max-width: unset;
            }
        }
    }

    .editor-texto{

        .w-md-editor-toolbar{

            ul{

                &:nth-child(2){

                    li{

                        &:not(:last-child){
                            display: none;
                        }
                    }
                }
            }
        }

        .w-md-editor-content{

            .w-md-editor-input{
                width: 100%;
            }

            .w-md-editor-preview{
                display: none;
            }
        }
    }

    .form-control{
        min-height: unset;
        height: 31px;
    }

    .select-ficha{
        font-size: 0.875rem;

        > div:nth-of-type(1){
            padding: 0;
            min-height: unset;

            > div{
                padding: 0 8px;

                &:last-child{
                    padding-right: 4px;
                }

            }

            > div:nth-of-type(2){
                > div{
                    padding: 0 8px;
                }
            }
        }
    }

    .custom-switch{
        font-size: 0.9rem;
        padding-left: 2.25em;

        .custom-control-input{
            width: 1em;
            height: 1.25em;
            
            &:checked ~ .custom-control-label::after{
                transform: translateX(0.75em);
            }
        }
        
        .custom-control-label{
            &::before{    
                top: 0.25em;
                left: 1.5em;
                display: block;
                width: 1em;
                height: 1em;
                
                left: -2.25em;
                width: 1.75em;
                border-radius: 0.5em;
                
            }

            &::after{
                top: calc(0.25em + 2px);
                left: calc(-2.25em + 2px);
                width: calc(1em - 4px);
                height: calc(1em - 4px);
                border-radius: 0.5em;
            }
        }
    }

`