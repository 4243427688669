import { useEffect, useState } from "react";
import axios from 'axios';
import { BrowserView, MobileView } from "react-device-detect";
import { Card } from "react-bootstrap";

import Registro from "./Registro";
import Paginacao from "../../../../Componentes/Paginacao";
import FormularioVisualizacao from "./FormularioVisualizacao";

import { ListaPedidosTesteHistoricoComponent } from "./components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function ListaPedidosTesteHistorico({
    idRazaoSocial
}){

    // controle de estados
    const [ carregando, alterarCarregando ] = useState(true);
    
    // prepara parametros
    const [ parametros, alterarParametros ] = useState({
        pagina: 1,
        registrosPorPagina: 3,
    });

    // controle de registros
    const [ totalRegistros, alterarTotalRegistros ] = useState(0);
    const [ registros, alterarRegistros ] = useState([]);
    const [ registroVisualizacao, alterarRegistroVisualizacao ] = useState(null);

    useEffect(() => {

        // faz a consulta
        consultar();

    }, [parametros]);

    // função para consultar
    async function consultar(){

        // cria o carregamento
        alterarCarregando(true)

        try{

            // faz a consulta
            let { data } = await axios.get(`/empresas/${idRazaoSocial}/pedidosTesteHistorico`, {
                params: parametros
            });

            // pega os registros
            alterarTotalRegistros(data.totalRegistros);
            alterarRegistros(data.registros);

        }catch(e){
            // erro
        }finally{

            // remove o carregamento
            alterarCarregando(false)
        }
    }

    // função para retornar registros
    function retornarRegistros(){

        // percorre registros
        return registros.map((registro) => {

            // retorna
            return <Registro
                key={registro.dados.id}
                dados={registro.dados}
                clicouVisualizar={() => {
                    alterarRegistroVisualizacao(registro);
                }}
            />
        });
    }

    // verifica se não encontrou registros
    if(registros.length === 0){
        return <></>;
    }

    // verifica se carregando
    return <ListaPedidosTesteHistoricoComponent>
        <Card.Header>Pedidos de teste</Card.Header>
        <Card.Body>
            {carregando ? <p className="text-center">
                <FontAwesomeIcon icon={faSpinner} pulse /> Carregando
            </p> : <div>
                <BrowserView>
                    <table className="table table-hover tabela">
                        <thead>
                            <tr>
                                <th>Pedido em</th>
                                <th>Fim teste</th>
                                <th>Versão</th>
                            </tr>
                        </thead>
                        <tbody>
                            { retornarRegistros() }
                        </tbody>
                    </table>
                </BrowserView>

                <MobileView>
                    { retornarRegistros() }
                </MobileView>

            </div>}
            
            <Paginacao
                pagina={parametros.pagina}
                registrosPorPagina={parametros.registrosPorPagina}
                totalRegistros={totalRegistros}
                alterouPagina={(pagina) => {
                    alterarParametros({...parametros, pagina: pagina});
                }}
            />

        </Card.Body>
        <FormularioVisualizacao
            dados={registroVisualizacao ? registroVisualizacao.dados : null}
            mostrar={registroVisualizacao ? true : false}
            fechar={() => {

                // fecha
                alterarRegistroVisualizacao(null);
            }}
        />
    </ListaPedidosTesteHistoricoComponent>;

}