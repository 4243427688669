import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import './PainelNavbar.scss';
import styles from './index.module.scss';
import imagemVazia from './imagem.png';
import { CasaIcone, RelogioIcone, RoscaIcone } from './../../../icones/svg';

// componentes
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavDropdown, ButtonToolbar, OverlayTrigger, Tooltip, Button, Overlay } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { Menu2Icone, MenuPontosIcone } from './../../../icones/svg';

// contextos
import { PainelContexto } from './../PainelContexto';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';

// navbar
export default function PainelNavbar({
    numero=8,
    painel,
    encolherMenu,
    menuEncolhido
}){

    // estados
    let painelContextoDados = useContext(PainelContexto);
    const loginReducer = useSelector(state => state.loginReducer);
    const configuracaoReducer = useSelector(state => state.ConfiguracaoReducer);
    
    const dispatch = useDispatch();
    let [hora, alterarHora] = useState(null);

    useEffect(() => {
        console.log(configuracaoReducer);
    }, []);

    // clicou para fazer logoff
    function deslogar(e){
        
        // desloga
        dispatch({ type: 'loginReducer/LOGOFF' });
    }
    

    return <>
        <nav className="painel-navbar navbar navbar-expand-lg ">

            <div className="container-fluid">
                {/* <p className="navbar-brand"> Usuários / Cadastrar / </p> */}
                {/* <a className={[styles.navbarBrand, 'navbar-brand'].join(' ')} href="/">{login.dadosUsuario.login}</a> */}
                
                <div className={["collapse navbar-collapse justify-content-end", isMobile ? 'mobile' : '' ].join(' ')}>
                    
                    <ul className={["navbar-nav mr-auto"].join(' ')}>
                        {/* <li className="nav-item">
                            <a className="nav-link" href="#pablo">
                                <span className="no-icon">Account</span>
                            </a>
                        </li> */}

                        {/* Se não for para mostrar tudo, então a função não precisa */}
                        {/* <ItemMenuTipo2
                            onClick={() => {}}
                            icone={["fas", verificar ? "eye" : "eye-slash"]}
                            label={verificar ? 'Esconder' : 'Mostrar'}
                        /> */}

                        
                        <ItemMenuTipo2
                            onClick={() => {
                                encolherMenu(!menuEncolhido);
                            }}
                            // icone={["fas", menuEncolhido ? 'ellipsis-v' : 'bars']}
                            svg={menuEncolhido ? <MenuPontosIcone width={25} height={25} /> : <Menu2Icone width={25} height={25} />}
                            label={menuEncolhido ? 'Expandir' : 'Minimizar'}
                        />
                        

                        <Link className={[styles.navbarBrand, 'pl-2'].join(' ')} data-toggle="dropdown" to={'/painel'}>
                            
                            { loginReducer.dadosUsuario.foto ?
                                <img src={`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/fotos/${loginReducer.dadosUsuario.foto}`} className={styles.foto} />
                                :
                                <FontAwesomeIcon className={styles.iconeUsuario} icon={["fas", "user-circle"]}></FontAwesomeIcon>
                            }

                            {/* <FontAwesomeIcon className={styles.iconeUsuario} icon={["fas", "user-circle"]}></FontAwesomeIcon> */}

                            { isBrowser &&
                                <div className={styles.info}>
                                    <span 
                                        className={styles.cargo}
                                        style={{
                                            color: painelContextoDados.socketStatus ? 'green' : 'red'
                                        }}
                                    >
                                        <FontAwesomeIcon className='mr-1' size={'xs'} icon={['fas', 'circle']} />
                                        Bem vindo
                                    </span>
                                    <span className={[styles.nome].join(' ')}>
                                        {loginReducer.dadosUsuario.nome}
                                    </span>
                                </div>
                            }

                        </Link>
                        {/* <ItemMenuTipo1
                            link={'/painel/configuracoes'}
                            icone={["fas", "cog"]}
                            label={'Configurações'}
                        /> */}
                    </ul>
                    
                    <ul className="nav navbar-nav ml-auto">

                        <ItemMenuTipo1
                            link={'/painel'}
                            // icone={["fas", "home"]}
                            svg={<CasaIcone />}
                            label={'Inicio'}
                        />
                        <ItemMenuTipo1
                            link={'/painel/historico'}
                            icone={["fas", "clock-rotate-left"]}
                            // svg={<RelogioIcone />}
                            label={'Histórico alterações'}
                        />
                        <ItemMenuTipo1
                            link={'/painel/historico/logs'}
                            // icone={["fas", "clock"]}
                            svg={<RelogioIcone />}
                            label={'Histórico logs'}
                        />
                        <ItemMenuTipo1
                            link={'/painel/configuracao'}
                            // icone={["fas", "cog"]}
                            svg={<RoscaIcone />}
                            label={'Configurações'}
                        />
                        <ItemMenuTipo1
                            link={'/painel/perfil'}
                            icone={faUserEdit}
                            label={'Perfil'}
                            className={'icone-perfil'}
                        />
                        {/* <ItemMenuTipo2
                            onClick={() => {}}
                            icone={["far", "plus-square"]}
                            label={'exemplo'}
                            className={'text-success'}
                        /> */}
                        {/* <ItemMenuTipo1
                            link={'/painel/historico'}
                            icone={["fas", "user-clock"]}
                            label={'Histórico Usuários'}
                        /> */}
                        
                        {/* <li className="nav-item">
                            <Link className="nav-link" data-toggle="dropdown" to="/">
                                <FontAwesomeIcon className="icone" icon={["fas", "home"]}></FontAwesomeIcon>
                                <span className="d-lg-none">Inicio</span>
                            </Link>
                        </li> */}
                        
                        {/* <li className="dropdown nav-item show">
                            <NavDropdown className="nav-link nav-link-dropdown" title={(
                                <span className="">
                                    <FontAwesomeIcon className="icone" icon={["fas", "home"]}></FontAwesomeIcon>
                                    <b className="caret" />
                                    <span className="notification">{numero}</span>
                                </span>
                            )} >
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                            </NavDropdown>
                        </li> */}

                        
                        <li className="nav-item">
                            <button className={[styles.botaoSair, ""].join('')} onClick={deslogar}>
                                {/* <span className="no-icon">Sair</span> */}
                                <FontAwesomeIcon className={[styles.icone, 'text-danger'].join(' ')} icon={["fas", "power-off"]}></FontAwesomeIcon>
                            </button>
                        </li>

                    </ul>
                </div>
            </div>
            
        </nav>
    </>
}

function ItemMenuTipo1({
    link,
    label,
    icone,
    className='',
    svg
}){

    return <li className={[styles.link, 'nav-item'].join(' ')}>
        <BrowserView>
            <OverlayTrigger
                placement={'bottom'}
                // trigger={'hover'}
                overlay={
                    <Tooltip>{label}</Tooltip>
                }
            >
                <Link className={[styles.navLink, 'nav-link p-2 ', className].join(' ')} data-toggle="dropdown" to={link}>
                    { svg ?
                        svg
                        :
                        <FontAwesomeIcon className="icone" icon={icone}></FontAwesomeIcon>
                    }
                    
                </Link>
            </OverlayTrigger>
        </BrowserView>

        <MobileView>
            <Link className={[styles.navLink, 'nav-link p-2', className].join(' ')} data-toggle="dropdown" to={link}>
                {/* <FontAwesomeIcon className="icone" icon={icone}></FontAwesomeIcon> */}
                { svg ?
                    svg
                    :
                    <FontAwesomeIcon className="icone" icon={icone}></FontAwesomeIcon>
                }
            </Link>
        </MobileView>

    </li>

}

function ItemMenuTipo2({
    onClick,
    label,
    icone,
    className='',
    svg
}){

    return <li className={[styles.link, 'nav-item'].join(' ')}>
        <BrowserView>
            <OverlayTrigger
                placement={'bottom'}
                overlay={
                    <Tooltip>{label}</Tooltip>
                }
            >
                <Button className={[styles.linkButton,'nav-link p-2 bg-transparent', className].join(' ')} onClick={onClick}>
                    {/* <FontAwesomeIcon className="icone fa-fw" icon={icone}></FontAwesomeIcon> */}
                    { svg ?
                        svg
                        :
                        <FontAwesomeIcon className="icone fa-fw" icon={icone}></FontAwesomeIcon>
                    }
                </Button> 
            </OverlayTrigger>
        </BrowserView>
        
        <MobileView>
            <Button className={[styles.linkButton,'nav-link p-2', className].join(' ')} onClick={onClick}>
                {/* <FontAwesomeIcon className="icone fa-fw" icon={icone}></FontAwesomeIcon> */}
                { svg ?
                    svg
                    :
                    <FontAwesomeIcon className="icone fa-fw" icon={icone}></FontAwesomeIcon>
                }
            </Button> 
        </MobileView>
    </li>

}
