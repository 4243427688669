
import axios from 'axios';
import moment from 'moment';
import * as R from 'ramda';

// converte para reais o valor
export function converterReal(valor, direcao = 'up'){
    if(direcao === 'up'){
        return parseFloat(valor).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
    }else{
        let calcDec = Math.pow(10, 2);
        valor = Math.trunc(valor * calcDec) / calcDec;
        return parseFloat(valor).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
    }
    
}

// verifica se a data é valida
export function validarData(data, tipo = 'valid'){
    if(data !== null && data.length === 10){

        if(tipo === 'valid' && moment(new Date(data)).isValid()){
           return true;
        }else if(!moment(new Date(data)).isValid()){
            return true;
        }
    }
    if(tipo === 'valid'){
        return false;
    }else{
        return false;
    }
}

export function mascaraCpf(valor){
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4");
}

export function mascaraCnpj(valor) {
    return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5");
}

// intersect object
export function intersectObject(obj1, obj2){
    let foo = R.clone(obj1);
    let bar = R.clone(obj2);
    for (var key in bar) {
        if (key in foo)
            foo[key] = bar[key];
    }
    return foo;
}

// função para copiar campo
export function copiaCampo(campo){

    let className = null;
    var disabled = false
    // verifica se está desabilitado
    if(campo.disabled){
        disabled = true
        campo.disabled = false
    }

    // verifica se está desabilitado
    if(!campo.offsetParent){
        
        // define
        className = campo.className.split(' ');

        // remove classes
        className.map((cn) => {

            // remove a classe
            campo.classList.remove(cn);
        });
    }

    // seleciona o valor
    campo.select()

    // copia conteudo do campo
    document.execCommand('copy'); 

    // se estava desabilitado, desabilita
    if(disabled){
        campo.disabled = true
    }

    // verifica se pegou classes
    if(className){

        // percorre classes e adiciona
        className.map((cn) => {

            // adiciona a classe
            campo.classList.add(cn);
        });
    }

}

// função para transformar camelCase em snake_case
export function decamelize(str, separator = '_'){

    // substitui informações
	return str
        .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
        .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
        .toLowerCase();
}

/**
 * Função para formatar o tamanho em bytes para leitura
 * 
 * @param bytes Tamanho em bytes.
 * @param si True para usar unidade de medida (SI), 1000 bytes. False para binário (IEC), 1024 bytes.
 * @param dp Número de casas decimais.
 * 
 * @return String formatada.
 */
export function formatarTamanhoBytes(bytes, si = null, dp = 1){

    // verifica
    si = si == null ? (navigator.platform === 'Win32' ? false : true) : si;
    const thresh = si ? 1000 : 1024;
    
    // verifica se é menor que a unidade
    if(Math.abs(bytes) < thresh){

        // retorna, mantendo bytes
        return bytes + ' B';
    }
    
    // prepara as unidades de medida de acordo com a configuração
    const units = (
        si  ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
        : ['KB', 'MB', 'GB', 'TB', 'PiB', 'EiB', 'ZiB', 'YiB']
    );

    // prepara variáveis
    let u = -1;
    const r = 10**dp;
    
    do {

        // divide pelo valor da unidade configurada
        bytes /= thresh;
        
        // incrementa o controle das unidades
        ++u;

    } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh
        && u < units.length - 1
    );
    
    // retorna o valor já com a unidade correta
    return `${bytes.toFixed(dp)} ${units[u]}`;
    
}

/*
    função para baixar o arquivo o arquivo da nuvem
*/
// função para fazer download do arquivo
export async function downloadArquivoNuvem(idArquivo, nome, extensao){
    
    try{

        // consulta o arquivo para download
        let response = await axios.get(`/empresas/arquivos/${idArquivo}/download`, {
            params: {
                // configurações
            },
            responseType: 'blob'
        });     
        let { data } = response;

        // resgata o retorno e cria uma url
        const href = URL.createObjectURL(data);
        
        // cria um elemento a
        const link = document.createElement('a');

        // atribui o link
        link.href = href;

        // pega o valor do content-disposition no cabeçalho
        const contentDisposition = response.headers['content-disposition'];

        // prepera o nome padrão do arquivo + extensão
        let fileName = `${nome}.${extensao}`;

        // verifica se encontrou o conteúdo para resgatar o nome
        if(contentDisposition){

            // pega o match do filename
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/) ?? '';
            
            // verifica se deu match no padrão esperado
            if(fileNameMatch.length === 2){

                // pega o nome do arquivo (já com a extensão)
                fileName = decodeURI(fileNameMatch[1]);
            }
        }

        // altera o atributo e nome do arquivo
        link.setAttribute('download', fileName);

        // adiciona tag a ao body
        document.body.appendChild(link);

        // faz o clique na tag a para realizar o download
        link.click();

        // retira a tag a do body
        document.body.removeChild(link);

        // retira link
        URL.revokeObjectURL(href);

    }catch(e){

        console.log(e.response);
        // verifica se retornado erro
        if(e.response && e.response.data){

            // pega como o blob como json
            const isJsonBlob = (data) => data instanceof Blob && data.type === "application/json";
            const responseData = isJsonBlob(e.response.data) ? await (e.response.data).text() : e.response.data || {};
            const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;
    
            // pega os erros
            let erros = responseJson;

            // prepara descrição
            let descricao = `
                Não foi possível fazer o download!
                ${
                    Object.keys(erros).filter((erro) => !['info', 'mensagemS3'].includes(erro)).map((erro) => {
                        return `<br>
                            ${erro === 'conexao' ? 
                                `Ocorreu um erro de conexão. Por favor, tente novamente.` 
                                : `&nbsp;&nbsp;&rsaquo; ${erros[erro]}`
                            }
                        `
                    }).join('')
                }
            `;

        }

    }finally{

    }

}

/*
    função para baixar o arquivo o arquivo da nuvem
*/
// função para fazer download do arquivo
export async function downloadArquivoNuvemPorUrl(idArquivo, nome, extensao){
    
    try{

        // consulta o arquivo para download
        let response = await axios.get(`/empresas/arquivos/${idArquivo}/url`, {
            params: {
                // configurações
            }
        });     
        let { data } = response;

        // resgata o retorno e cria uma url
        const href = data.url;
        
        // cria um elemento a
        const link = document.createElement('a');

        // atribui o link
        link.href = href;

        // prepera o nome padrão do arquivo + extensão
        let fileName = `${nome}.${extensao}`;

        // verifica se encontrou o conteúdo para resgatar o nome
        if(data.dados){

            // define
            fileName = `${data.dados.nome}.${data.dados.extensao}`;
        }

        // altera o atributo e nome do arquivo
        link.setAttribute('download', fileName);

        // adiciona tag a ao body
        document.body.appendChild(link);

        // faz o clique na tag a para realizar o download
        link.click();

        // retira a tag a do body
        document.body.removeChild(link);

    }catch(e){

        console.log(e.response);
        // verifica se retornado erro
        if(e.response && e.response.data){

            // pega como o blob como json
            const isJsonBlob = (data) => data instanceof Blob && data.type === "application/json";
            const responseData = isJsonBlob(e.response.data) ? await (e.response.data).text() : e.response.data || {};
            const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;
    
            // pega os erros
            let erros = responseJson;

            // prepara descrição
            let descricao = `
                Não foi possível fazer o download!
                ${
                    Object.keys(erros).filter((erro) => !['info', 'mensagemS3'].includes(erro)).map((erro) => {
                        return `<br>
                            ${erro === 'conexao' ? 
                                `Ocorreu um erro de conexão. Por favor, tente novamente.` 
                                : `&nbsp;&nbsp;&rsaquo; ${erros[erro]}`
                            }
                        `
                    }).join('')
                }
            `;

        }

    }finally{

    }

}