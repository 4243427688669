import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const RelatoriosEnderecosPesquisasComponent = styled.div.attrs({
    className: ``
})`

    .card-totais-gerais{
        
        .total-grupo{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #828282;
            font-size: 0.9em;
            padding: 0.5em 0;
            border-bottom: 1px solid #f0f0f0;
            
            &:last-child{
                border-bottom: 0;
            }

            .total-geral{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: #828282;
                font-size: 0.9em;
                padding: 0.5em 0;
                width: 80%;
                white-space: nowrap;

                .destaque-total{
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                }

                .total{
                    font-size: 2em;
                    line-height: 0.9em;
                    font-weight: 600;

                    &.integracao{
                        color: #3aa872;
                    }

                    &.empresas-integradas{
                        color: #418273;
                    }

                    &.integracao-sistemas{
                        color: #90d90b;
                    }

                    &.pedidos-integracao{
                        color: #f3910d;
                    }
                }

            }

            .sub-total{
                display: flex;
                align-items: center;
                font-size: 0.8em;
                width: 100%;
                justify-content: space-around;
                border-left: 1px solid #f9f9f9;
                margin: 0.2em 1em;
                margin-bottom: 0;
                padding: 0.3em;

                .total-geral{
                    border: 0;
                }
            }
        }
        
    }

    .grafico-content{
        background: #fafafa;
    }
`;