import React from 'react';

// components
import { RegistroComponent } from './components';
import { Card, Badge, Container } from 'react-bootstrap';
import BotaoTabela from './../../../../../Componentes/BotaoTabela';

export default function Registro({
    dados,
    clicouEditar
}){

    // define a cor do metodo
    let corMetodo = '';
    switch(dados.metodo){
        case 'get': corMetodo = 'success'; break;
        case 'post': corMetodo = 'primary'; break;
        case 'put': corMetodo = 'info'; break;
        case 'delete': corMetodo = 'danger'; break;
    }

    return <RegistroComponent>
        <td className="estreito text-right">
            <Badge variant={corMetodo}>{dados.metodo.toUpperCase()}</Badge>
        </td>
        <td className="estreito font-weight-bold">
            {dados.url}
        </td>
        <td>{dados.descricao}</td>
        <td className="text-nowrap estreito">
            <BotaoTabela 
                onClick={clicouEditar}
                icone={["fas", "pencil-alt"]}
            >
                <span>Editar</span>
            </BotaoTabela>
        </td>
    </RegistroComponent>
}