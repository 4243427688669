import React, { useState, useEffect, useContext } from 'react';

// componentes
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Row, Col, FormCheck, FormLabel, Badge } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';
import { Link } from 'react-router-dom';


export default function ParametrosConsulta({
    parametros, 
    alterarParametros
}){

    return <div className="parametros">
        <div className="parametros-inicial">
            <Link to='/painel/documentacao-api/cadastrar'>
                <Button variant="padrao" className="botao-cadastrar">
                    <FontAwesomeIcon className="icone" icon={["fas", "plus"]} />
                    { isBrowser && <span>Cadastrar</span>}
                </Button>
            </Link>
            <DebounceInput 
                placeholder="" 
                debounceTimeout={400}
                element={Form.Control} 
                value={parametros.texto}
                onChange={(e) =>{
                    alterarParametros({...parametros, descricao: e.target.value, pagina: 1})
                }}
            />
        </div>
    </div>
}