import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { Card, Table, Button, Badge, InputGroup, FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { useHistory, useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Registro from './Registro';
import Paginacao from './../../../../Componentes/Paginacao';
import ParametrosConsulta from './ParametrosConsulta';
// import ModalExcluir from './ModalExcluir';

// contextos    
import { PainelContexto } from './../../../PainelContexto';

export default function Consultar(){

    // estados
    let painelContextoDados = useContext(PainelContexto);
    let history = useHistory();
    let { path, url } = useRouteMatch();
    let [carregando, alterarCarregando] = useState(false);
    let [totalRegistros, atualizarTotalRegistros] = useState(0);
    let [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: isMobile ? 3 : 10,
        texto: ''
    });
    let [consulta, alterarConsulta] = useState([]);
    let [mostrarFiltros, alterarMostrarFiltros] = useState(false);
    let [campoBusca, alterarCampoBusca] = useState('');

    // inicializa
    useEffect(() => {
        
        // faz a consulta
        fazerConsulta();

    }, [parametros]);

    // faz consulta
    async function fazerConsulta(){

        // carregando
        alterarCarregando(true);

        // faz a requisição
        try{
            let { data } = await axios.get('/apisGrupos', {
                params: parametros
            });

            // finalizado
            alterarConsulta(data.registros);
            atualizarTotalRegistros(data.totalRegistros)

        }catch(e){

        }finally{
            alterarCarregando(false);
        }
    }
    
    // retorna registro
    let retornaRegistros = registro => {

        // retorna registros organizado
        return consulta.map(registro => 
            <Registro 
                key={registro.dados.id}
                dados={registro.dados}
                clicouEditar={() => {
                    history.push(`/painel/documentacao-api/grupos/alterar/${registro.dados.id}`);
                }}
            />
        )
    }

    return <>
        <Card className="painel-consulta border-0" >
            <Card.Header>
                <div className={'d-flex '}>
                    <Card.Title className={'flex-grow-1'}>Grupos das apis</Card.Title>
                </div>
            </Card.Header>
            <Card.Body>
                <ParametrosConsulta 
                    parametros={parametros}
                    alterarParametros={alterarParametros}
                    mostrarFiltros={mostrarFiltros}
                    alterarMostrarFiltros={alterarMostrarFiltros}
                />

                <div 
                    className={'my-3'}
                >
                    <BrowserView>
                        <table className="table table-hover tabela">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Descrição</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                { retornaRegistros() }
                            </tbody>
                        </table>
                    </BrowserView>

                    <MobileView>
                        { retornaRegistros() }
                    </MobileView>
                </div>
                
                <Paginacao
                    pagina={parametros.pagina}
                    registrosPorPagina={parametros.registrosPorPagina}
                    totalRegistros={totalRegistros}
                    alterouPagina={(pagina) => {
                        alterarParametros({...parametros, pagina: pagina});
                    }}
                />
            </Card.Body>
        </Card>
    </>
}