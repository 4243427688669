import styled from 'styled-components';

export const PermissaoComponent = styled.div.attrs({
    className: ``
})`


    



`;