import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import stylesGerais from './../index.module.scss';
import { retornaModoCliente } from './../Funcoes';
import moment from 'moment';

// componentes
import { useSelector } from 'react-redux';
import { Card, Form, Button, Row, Col, OverlayTrigger, Popover, Tooltip, Badge, Alert } from 'react-bootstrap';
import { BrowserView, MobileView, isMobile, isBrowser } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BotaoTabela from './../../../../Componentes/BotaoTabela';
import { converterReal } from './../../../../Funcoes';
import { faSignInAlt, faUser, faUserTie } from '@fortawesome/free-solid-svg-icons';

// componente
export default function Registro({
    dados,
    informacoes,
    online=0,
    clicouEditar,
    clicouEnviarEmail,
    habilitarIgnorarIds,
    clicouIgnorarCliente,
    mostrarOpcaoAbrirServidor,
    clicouAbrirFichaServidor
}){

    // // estados
    // let configuracao = useSelector(state => state.ConfiguracaoReducer);
    // const configuracaoUsuarioReducer = useSelector(state => state.ConfiguracaoUsuarioReducer);

    // retorna opções
    let retornarOpcoes = () => {

        // retorna componentes
        return <>
            { habilitarIgnorarIds &&
                <BotaoTabela 
                    onClick={clicouIgnorarCliente}
                    icone={["fas", "times"]}
                >
                    <span>Ignorar cliente</span>
                </BotaoTabela>
            }
            <BotaoTabela 
                onClick={clicouEnviarEmail}
                icone={["fas", "envelope"]}
            >
                <span>Escrever Email</span>
            </BotaoTabela>
            <BotaoTabela 
                onClick={clicouEditar}
                icone={["fas", "pencil-alt"]}
            >
                <span>Editar</span>
            </BotaoTabela>
            {
                mostrarOpcaoAbrirServidor &&
                <>
                    <BotaoTabela 
                        onClick={clicouAbrirFichaServidor}
                        icone={["fas", "server"]}
                    >
                        <span>Ficha servidor</span>
                    </BotaoTabela>
                </>
            }
        </>
    }

    // pega o modo
    const modoCliente = retornaModoCliente(dados);
    
    return <> 

        {/* NAVEGADOR */}
        { isBrowser &&
            <tr
                style={{cursor: 'pointer'}}
                onClick={mostrarOpcaoAbrirServidor ? clicouAbrirFichaServidor : clicouEditar}
                className={[styles.estiloRegistro, stylesGerais.corStatusCliente, stylesGerais[modoCliente]].join(' ')}
            >
                <td className={'estreito text-center font-weight-bold'}>
                    {dados.id}
                </td>
                <td className={'estreito text-center font-weight-bold'}>
                    <Badge className={['rounded-pill', styles.codigoEmpresa].join(' ')}>
                        {dados.codigo}
                    </Badge>
                </td>
                <td className='estreito'>
                    <Badge variant={parseInt(online) > 0 ? 'success' : 'warning'} className={['rounded-pill', styles.online].join(' ')}>
                        {online}
                    </Badge>
                </td>
                <td>
                    {dados.razaoSocial} <small>({dados.nomeFantasia})</small> <br/>
                    <small style={{color: "#4c4c4c", gap: 5, display: 'flex'}}>
                        <span><b>CNPJ: </b> {dados.cnpj}</span>
                        {
                            informacoes && <>
                                <span><b>Versão:</b> {informacoes.versao} </span>
                                <span className="d-flex align-items-center">
                                    <span><b>Servidor:</b> {informacoes.servidor}</span>
                                    {
                                        informacoes.cavaloTroia === 'S' &&
                                        <Badge variant="warning ml-1">Cavalo de Troia</Badge>
                                    }
                                </span>
                                {informacoes.idBanco && <span><b>Banco:</b> {informacoes.nomeBanco}</span>}
                            </>
                        }
                    </small>
                </td>
                <td className={'text-nowrap estreito text-right'}>
                    { retornarOpcoes() }
                </td>
            </tr>
        }

        {/* MOBILE */}
        {isMobile &&
            <Card
                className={'border-0 bg-light mb-2'}
                onClick={clicouEditar}
            >
                <Card.Body className={'p-2'}>
                    <div className={[isBrowser ? 'd-flex' : ''].join(' ')}>
                        <div className={'flex-grow-1'}>
                            <div>
                                <span>
                                    {dados.razaoSocial}
                                </span>
                            </div>
                        </div>
                        <div className={'text-right'}>
                            { retornarOpcoes() }
                        </div>
                    </div>
                </Card.Body>
            </Card>
        }
    </>
}