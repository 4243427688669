import styled from 'styled-components';


export const ArquivoComponent = styled.div.attrs({
    className: ``
})`
    height: 52px;
    width: 140px;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: 0px 0px 1px #b8b8b8;
    background: #dff5ffbf;
    margin: 0 10px;
    display: flex;
    align-items: center;
    position: relative;

    .icone{
        width: 35px;
        margin: 5px;

    }

    .opcoes{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 0.8em;
        color: #afafaf;
        padding: 2px 7px;
        margin: -1em;

        span{
            background: #ffffff96;
            border-radius: 50%;
            padding: 0.5em;
            box-shadow: 0px 0px 1px #eaecef;
            display: flex;
            align-items: center;
            justify-content: center;
            
            &:hover{
                background: #f3dbdb96;
                color: #9e3838;
            }
        }
    }

    .info{
        flex-grow: 1;
        padding: 3px;
        padding-right: 7px;

        i{
            font-size: 0.6em;
        }

        &.download{
            color: #afafaf !important;
            text-decoration: none !important;

            i{
                font-size: 0.8em;
            }

            .icon{
                color: #afafaf;
            }

            &:hover{
                i{
                    color: grey;
                }

                .icon{
                    filter: drop-shadow(0px 0px 1px #222729);
                    color: #d8e3e7;
                }
            }
        }
    }


`