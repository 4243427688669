import React from 'react';
import styles from './index.module.scss';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Toast, Card, Image, Badge  } from 'react-bootstrap';
import Funcao from './Funcao';

// componente
export default function Usuario({
    dados
}){

    return <>
        <div
            className={['', styles.container].join(' ')}
        >
            {/* <Image src="holder.js/171x180" roundedCircle /> */}
            { (dados.dados && dados.dados.foto !== null) ?
                // <img src={`${process.env.REACT_APP_URL_API}/arquivos/fotos/${dados.dados.foto}`} className={styles.foto} />
                <img src={`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/fotos/${dados.dados.foto}`} className={styles.foto} />
                :
                <FontAwesomeIcon className={styles.iconeUsuario} icon={["fas", "user-circle"]}></FontAwesomeIcon>
            }
            
            

            <div
                className={'flex-grow-1'}
            >
                
                <div
                    className={styles.nomeUsuario}
                >
                    { dados.dados !== null ?
                        <>{dados.dados.nome}</>
                        :
                        <Badge variant='warning'>Sistema</Badge>
                    }
                </div>
                
                <div className={['py-1 rounded ', styles.funcoes].join(' ')}>

                    { dados.funcoes.map(funcaoEvento => 
                        <Funcao 
                            key={funcaoEvento.dados.id}
                            dados={funcaoEvento}
                        />
                    )}

                </div>
            </div>
        </div>
    
    </>

}