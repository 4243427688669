import axios from 'axios';
import { useEffect, useState } from 'react';
import { Route, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Button, Card } from 'react-bootstrap';

import Paginacao from '../../Componentes/Paginacao';
import Confirmar from '../../Componentes/Confirmar';

import Registro from './Registro';
import { EmpresasIntegracaoSistemasComponent } from "./components";
import { toast } from 'react-toastify';
import ModalCadastrar from './ModalCadastrar';

export default function EmpresasIntegracaoSistemas({
    idEmpresa
}){

    // pega location
    const history = useHistory();
    const location = useLocation();
    let { path, url } = useRouteMatch();

    // parametros da url
    const {
        // idEmpresa = null
    } = useParams();

    // estados
    const [ carregando, alterarCarregando ] = useState(null);
    const [ mostrarFiltros, alterarMostrarFiltros ] = useState(false);
    const [ parametros, alterarParametros ] = useState({
        registrosPorPagina: 8,
        pagina: 1,
        pesquisa: null
    });
    const [ registros, alterarRegistros ] = useState([]);
    const [ totalRegistros, alterarTotalRegistros ] = useState(null);
    const [ confirmar, alterarConfirmar ] = useState(null);

    // efeitos

    // prepara
    let pathsConsultar = [
        '/painel/empresas/editar/:idEmpresa/integracao'
    ].map((p) => p.replace(':idEmpresa', idEmpresa));

    useEffect(() => {

        // verifica
        if(carregando !== null && pathsConsultar.includes(location.pathname)){

            // consulta registros
            consultar();
        }

    }, [parametros]);

    useEffect(() => {

        // pega
        let {
            aPartirDe
        } = location.state ?? {};

        // se retornar a essa rota
        if(pathsConsultar.includes(location.pathname) && (!aPartirDe || !['cadastrarEmpresaIntegracaoSistema'].includes(aPartirDe))){

            // consulta
            consultar();
        }

    }, [location.pathname]);

    // função para consultar
    async function consultar(){

        // cria o carregamento
        alterarCarregando(true);

        try{

            // consulta
            let { data } = await axios.get(`/empresas/integracaoSistemas`, {
                params: {
                    idEmpresa,
                    ...parametros
                }
            });

            // sucesso

            // pega os registros e total
            alterarRegistros(data.registros);
            alterarTotalRegistros(data.totalRegistros);

        }catch(e){
            // erro
        }finally{
            // finalizou

            // remove o carregamento
            alterarCarregando(false);
        }
    }

    // faz a exclusão
    async function deletar(dados){

        try{

            // faz a exclusão
            let { data } = await axios.delete(`empresas/integracaoSistemas/${dados.id}`);

            // excluído com sucesso

            // consulta

            // atualiza registros
            consultar();
            
        }catch({response}){

            // informa erro
            toast(<>
                <div
                    style={{background: '#ff6271'}}
                >
                    <div className="toast-header">
                        <strong className="mr-auto">Integração não pôde ser deletada</strong>
                        <button 
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                        </button>
                    </div>
                    <div className="toast-body text-light">
                        {
                            (response && response.data) &&
                            Object.keys(response.data).map((erro) => <p className={'m-0'} key={erro}>{response.data[erro]}<br/></p>)
                        }
                    </div>
                </div>
            </>);

        }finally{

            // remove o carregamento
            alterarCarregando(false);

        }

    }

    // ao clicar para excluir
    function clicouExcluirRegistro(registro){

        // confirma
        alterarConfirmar({
            aberto: true,
            titulo: 'Remover integração',
            texto: `Tem certeza que deseja remover esta integração da empresa?`,
            textoBotao: 'Remover',
            variantConfirmar: 'danger',
            variantCancelar: 'secondary',
            backdrop: true,
            aoConfirmar: () => {

                // deleta o registro
                deletar(registro.dados);
            },
            aoCancelar: () => {
                // ao cancelar
            },
            aoFechar: () => {
                // ao fechar
            }
        })
    }

    // retorna registro
    function retornaRegistros(){

        // retorna registros organizado
        return registros.map(registro => 
            <Registro
                key={registro.dados.id}
                dados={registro.dados}
                dadosIntegracaoSistema={registro.dadosIntegracaoSistema}
                status={registro.status}
                
                clicouExcluir={() => {

                    // envia o registro
                    clicouExcluirRegistro(registro);
                }}
            />
        )
    }

    return <EmpresasIntegracaoSistemasComponent>
        <Confirmar config={confirmar} alterar={alterarConfirmar}/>
        
        <Card.Body className="py-2 px-3">
            <Card.Header className={'px-0'}>
                <div className={'d-flex '}>
                    <Card.Title className={'flex-grow-1 d-flex align-items-center mb-0'}>
                        Integrações
                    </Card.Title>
                    <div>
                        <Button
                            variant='success'
                            size='sm'
                            onClick={() => {
                                history.push(`${url}/cadastrar`);
                            }}
                        >
                            <FontAwesomeIcon className="icone" icon={faPlus} />
                            <span>Novo</span>
                        </Button>
                    </div>
                </div>
            </Card.Header>
            <div>
                {
                carregando ? <div className="p-5 text-center">
                    <FontAwesomeIcon icon={faSpinner} pulse /> Carregando...
                </div> : (
                        <div
                            className={'my-2'}
                        >
                            <table className='tabela'>
                                {totalRegistros > 0 && <tbody>
                                    { retornaRegistros() }
                                </tbody>}
                            </table>
                        </div>
                    )
                }
                
                <Paginacao
                    pagina={parametros.pagina}
                    registrosPorPagina={parametros.registrosPorPagina}
                    totalRegistros={totalRegistros}
                    alterouPagina={(pagina) => {
                        alterarParametros({...parametros, pagina: pagina});
                    }}
                />

            </div>
        </Card.Body>

        {/* rotas */}
        <Route exact path={[
            `/painel/empresas/editar/:idEmpresa/integracao/cadastrar`
        ]}>
            <ModalCadastrar
                idEmpresa={idEmpresa}
            />
        </Route>
        
    </EmpresasIntegracaoSistemasComponent>
    
}