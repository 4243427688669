import styled from 'styled-components';
import { Card } from 'react-bootstrap';

import { ListaEmpresasEstatisticasComponent } from './../components';

export const ListaEmpresasEstatisticasUsoIntegracaoComponent = styled(ListaEmpresasEstatisticasComponent).attrs({
    className: `painel-consulta shadow-sm`
})`
    padding: 0.5em 1em;
    margin-bottom: 0.5em;
    
    .tabela{
        
        .registro{
            font-size: 0.9em;
            border-radius: 0.3em;

            &:first-child{
                margin-top: 0;
            }

            td, th{
                border-left: 1px solid #f3f3f3;
                background: #fdfdfd;
                border: 1px solid #f3f3f3;
                padding: 0.25em 0.5em;

                &:first-child{
                    border-top-left-radius: 0.3em;
                    border-bottom-left-radius: 0.3em;
                }
                
                &:last-child{
                    border-top-right-radius: 0.3em;
                    border-bottom-right-radius: 0.3em;
                }
                
                &.indice{
                    color: #525252;
                    font-size: 0.9em;
                    background: #f7fbff;
                    border-color: #e4edf7;
                    border-radius: 0.3em;
                    min-width: 25px;
                }

                .totais{
                    display: flex;
                    align-items: center;
                    gap: 0.5em;
                    
                    .geral{

                        b{
                            color: #0d42af;
                        }
                    }

                    .v1{
                        
                        b{
                            color: #e56804;
                        }
                    }
                    
                    .v2{

                        b{
                            color: #daac06;
                        }
                    }
                    
                    .v3{

                        b{  
                            color: #05ab3a;
                        }
                    }
                }

                .versao{
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    background: #f4f4f4;
                    height: fit-content;
                    align-self: center;
                    line-height: 1.2rem;
                    font-size: 0.9em;
                    padding: 0 0.3em;
                    border-radius: 0.3em;
                    font-weight: 700;
                    margin-right: 0.3em;
                    
                    &.gg{
                        color: #0d42af;
                    }

                    &.v1{
                        color: #e56804;
                    }
                    
                    &.v2{
                        color: #e5b404;
                    }
                    
                    &.v3{
                        color: #06c142;
                    }
                }
            }
        }
    }

`;