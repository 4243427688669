import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Route, useHistory, useLocation, useRouteMatch, useParams } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faDatabase, faFilter, faGear, faHistory, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { toast } from 'react-toastify';
import { Button, Card, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { EmpresasInstalacaoComponent } from "./components";

import Paginacao from '../../../Componentes/Paginacao';
import Confirmar from '../../../Componentes/Confirmar';
import ModalFiltros from './ModalFiltros';
import Registro from './Registro';
import { downloadArquivoNuvem } from '../../../Funcoes';
import ModalInstalar from '../../EmpresasBackups/ModalInstalar';
import ModalConfigurarDemos from '../ModalConfigurarDemos';
import { ListaComponent } from '../../../Componentes/components';

export default function ModalEmpresasInstalacao({
    urlVoltar
}){

    // pega location
    const history = useHistory();
    const location = useLocation();

    // estados
    const [ carregando, alterarCarregando ] = useState(false);
    const [ mostrarFiltros, alterarMostrarFiltros ] = useState(false);
    const [ parametros, alterarParametros ] = useState({
        registrosPorPagina: 8,
        pagina: 1,
        pesquisa: null
    });
    const [ registros, alterarRegistros ] = useState([]);
    const [ totalRegistros, alterarTotalRegistros ] = useState(null);
    const [ confirmar, alterarConfirmar ] = useState(null);
    const [ mostrar, alterarMostrar ] = useState(true);
    
    // referencias
    const refAbrirFiltros = useRef(null);

    // efeitos

    useEffect(() => {

        // prepara
        let pathsConsultar = [
            '/painel/servidores/aguardandoInstalacao/historico'
        ];
        let {
            aPartirDe
        } = location.state ?? {};

        // se retornar a essa rota
        if(pathsConsultar.includes(location.pathname)){

            // consulta
            consultar();
        }

    }, [location.pathname, parametros]);

    // função para consultar
    async function consultar(){

        // cria o carregamento
        alterarCarregando(true);

        try{

            // consulta
            let { data } = await axios.get(`/empresas/instalar/historico`, {
                params: {
                    ...parametros
                }
            });

            // sucesso

            // pega os registros e total
            alterarRegistros(data.registros);
            alterarTotalRegistros(data.totalRegistros);

        }catch(e){
            // erro
        }finally{
            // finalizou

            // remove o carregamento
            alterarCarregando(false);
        }
    }

    // retorna registro
    function retornaRegistros(){

        // retorna registros organizado
        return registros.map(registro => 
            <Registro
                key={registro.dados.id}
                dados={registro.dados}
                dadosEmpresa={registro.dadosEmpresa}
                arquivo={registro.arquivo}
                clicouAbrir={() => {

                    // abre o servidor da empresa
                    history.push(`/painel/empresas/editar/${registro.dados.idEmpresa}/servidor`);
                }}
            />
        )
    }

    return <EmpresasInstalacaoComponent
        show={mostrar}
        enforceFocus={false}
        onExited={() => {

            // fecha
            alterarMostrar(false);

            // volta e dá refresh pra atualizar a listagem
            history.push(urlVoltar ?? '/painel/servidores/aguardandoInstalacao');
            
        }}
        onHide={() => {

            // fecha
            alterarMostrar(false);
        }}
        size={'lg'}
    >
        <Modal.Body>
            <ModalFiltros
                elemento={refAbrirFiltros.current}
                mostrar={mostrarFiltros}
                alterarMostrar={alterarMostrarFiltros}
                parametros={parametros}
                alterarParametros={alterarParametros}
            />
            <Confirmar config={confirmar} alterar={alterarConfirmar}/>
            
            <Card.Header className={'px-0'}>
                <ListaComponent className={'d-flex'}>
                    <Card.Title className={'flex-grow-1 d-flex align-items-center mb-0'}>
                        Instalações
                    </Card.Title>
                    <div className='d-flex' style={{gap: '0.5em'}}>
                        
                        <Button
                            variant='light'
                            size='sm'
                            ref={refAbrirFiltros}
                            onClick={() => {

                                // define
                                alterarMostrarFiltros(true);
                            }}
                            style={{
                                fontSize: '0.9em',
                                boxShadow: '0 0 1px #b1b1b1',
                                border: 0,
                                display: 'flex',
                                gap: '0.2em',
                                alignItems: 'center'
                            }}
                        >
                            <FontAwesomeIcon className="icone" icon={faFilter} />
                            Filtrar
                        </Button>
                    </div>
                </ListaComponent>
            </Card.Header>
            <div>
                {
                    carregando ? <div className="p-5 text-center">
                        <FontAwesomeIcon icon={faSpinner} pulse /> Carregando...
                    </div> : (
                        <div
                            className={'my-3'}
                        >
                        <BrowserView>
                            <table className="table table-hover tabela">
                                {totalRegistros > 0 && <>
                                    {/* <thead>
                                        <tr>
                                            <th className={'text-center'}></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead> */}
                                    <tbody>
                                        { retornaRegistros() }
                                    </tbody>
                                </>}
                            </table>
                        </BrowserView>

                        <MobileView>
                            { retornaRegistros() }
                        </MobileView>
                    </div>)
                }
                <Paginacao
                    pagina={parametros.pagina}
                    registrosPorPagina={parametros.registrosPorPagina}
                    totalRegistros={totalRegistros}
                    alterouPagina={(pagina) => {
                        alterarParametros({...parametros, pagina: pagina});
                    }}
                />

            </div>
        </Modal.Body>
        
    </EmpresasInstalacaoComponent>
    
}