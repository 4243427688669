import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';

// componentes
import { useSelector } from 'react-redux';
import { Card, Row, Col } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import useIsMounted from 'ismounted';

import CardEmpresas from './CardEmpresas';
import CardUsuarios from './CardUsuarios';
import CardDadosEmpresa from './CardDadosEmpresa';
import Filtros from './Filtros';
import TotaisGeral from './TotaisGeral';

// contextos    
import { PainelContexto } from './../../PainelContexto';

// função para o relatório de acessos
export default function RelatorioAcessos(){
      
    // dados
    const permissaoReducer = useSelector(state => state.permissaoReducer);
    const isMounted = useIsMounted();
    const history = useHistory();
    
    const [dadosEmpresaSelecionada, alterarDadosEmpresaSelecionada] = useState(null);
    const [parametros, alterarParametros] = useState({
		data_inicial: moment().format('YYYY-MM-DD'),
		data_final: moment().format('YYYY-MM-DD'),
		id_razao_social: null,
		limite: 50,
		somente_nao_possuem_token: 'N'
	});
	const [idEmpresa, alterarIdEmpresa] = useState(null);
	
    // inicializa
    useEffect(() => {


    }, [parametros]);

	// ao selecionar empresa, faz a alteração
	function aoSelecionarEmpresa(empresa){

		// verifica se está selecionado
		if(empresa){
			
			// seleciona a empresa e altera os dados dela
			alterarEmpresa(empresa.dados.id)
			alterarDadosEmpresaSelecionada(empresa.dados)

		}else{
			// desselecionou empresa

			// volta para null
			alterarEmpresa(null)
			alterarDadosEmpresaSelecionada(null)

		}

	}

	// altera o id da empresa
	function alterarEmpresa(idEmpresa){
		alterarIdEmpresa(idEmpresa);
	}

    return <>
		<Col>
			<Row>
				<Col lg={5} className='px-1 mb-2'>
					<Card className="border-0">
						<Card.Body>
							<Filtros 
								parametros={parametros}
								alterarParametros={alterarParametros}
								idUsuario={idEmpresa}
								aoAlterarSelecaoUsuario={aoSelecionarEmpresa}
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col lg={7} className='px-1 mb-2'>
					<TotaisGeral
						parametros={parametros}
					/>
				</Col>
				<Col lg={5} className='px-1 mb-2'>
					{
						(idEmpresa) ?
						<CardDadosEmpresa
							dadosEmpresa={dadosEmpresaSelecionada}
							idEmpresa={idEmpresa}
							parametros={parametros}
						/>
						:
						<CardUsuarios
							parametros={parametros}
						/>
					}
				</Col>
				<Col lg={7} className='px-1 mb-2'>
					<CardEmpresas
						parametros={parametros}
						alterarParametros={alterarParametros}
						selecionarEmpresa={(id) => {
							console.log(id)
							alterarEmpresa(id)
						}}
					/>
				</Col>
			</Row>
		</Col>	
    </>
}
