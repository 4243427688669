import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { useSelector } from 'react-redux';
import { Modal, Container, Button, Form } from 'react-bootstrap';
import PaginacaoComponente from './../../../../Componentes/Paginacao';
import Carregador from './../../../../Componentes/Carregador';
import Imagem from './Imagem';

// modal para cadastrar/buscar imagem para incluir ao email
export default function ModalImagensEmail({
    fechou,
    alterou
}){

    // estados
    let login = useSelector(state => state.login);
    let [mostrar, alterarMostrar] = useState(true);
    let [dados, alterarDados] = useState({
        pagina: 1,
        registrosPorPagina: 5
    });
    let [registros, alterarRegistros] = useState([]);
    let [totalRegistros, atualizarTotalRegistros] = useState(0);
    let [carregando, mostrarCarregando] = useState(false);
    let [upload, alterarUpload] = useState({
        salvando: false,
        progresso: 0
    });

    // inicialiar
    useEffect(() => {
        mostrarCarregando(true);

        // consulta as imagens já cadastradas
        axios.get(`/imagensEmails`, { params: dados})
        .then(({data, ...retorno}) => {
            alterarRegistros(data.registros);
            atualizarTotalRegistros(data.totalRegistros);
        })
        .catch(() => {})
        .then(() => {
            mostrarCarregando(false);
        });

    }, [dados]);

    // cria referencia
    let inputRef = React.createRef();

    // salvar imagem
    function salvarImagem(){

        // sem arquivos não executa
        if(inputRef.current.files.length <= 0){
            return;
        }

        // salva imagem
        upload.salvando = true;
        alterarUpload({...upload});

        let arquivo = inputRef.current.files[0];
        let salvarImagem = new FormData();
        salvarImagem.append('arquivo', arquivo);

        // verifica o progresso da imagem do card
        function progressoArquivo(progressEvent){
            let progress = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            upload.progresso = progress
            alterarUpload({...upload});
        }

        // faz a solicição
        axios.post(`/imagensEmails`, salvarImagem, {
            onUploadProgress: progressoArquivo,
            headers: {'Content-type': 'multipart/form-data'}
        })
        .then(({data, ...retorno}) => {

            // finalizou, alteras
            upload.salvando = false;
            upload.progresso = 0;
            alterarUpload({ ...upload });

            // recarrega registros
            dados.pagina = 1;
            alterarDados({...dados});
        })
        .catch(({response, ...retorno}) => {

            // erro
            if(response){

                // resposta
                if(response.status === 400){
                    // alterarErros(response.data);
                    console.log(response.data);
                }
            }
        })
        .then(() => {

        });


    }

    return <Modal
        show={mostrar} 
        onExited={fechou}
        onHide={() => {alterarMostrar(false)}}
        size={'md'}
    >
        <Modal.Header className='border-0'>
            <Modal.Title>
                Imagens para email
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>

            {/* input de referencia */}
            <input 
                hidden type='file' 
                ref={inputRef}
                onChange={salvarImagem}
            />

            { !upload.salvando &&
                <div
                    className='text-right'
                >
                    <Button
                        onClick={() => inputRef.current.click()}
                        size='sm'
                        variant='padrao'
                    >
                        <span>Adicionar Imagem</span>
                    </Button>
                </div>
            }
            { upload.salvando &&
                <div>
                    {upload.progresso}
                </div>
            }

            {registros.map(registro => 
                <Imagem 
                    key={registro.id}
                    dados={registro}
                    incluir={() => {
                        alterou(registro);
                        alterarMostrar(false);
                    }}
                />     
            )}


            <PaginacaoComponente
                pagina={dados.pagina}
                registrosPorPagina={dados.registrosPorPagina}
                totalRegistros={totalRegistros}
                alterouPagina={(pagina) => {
                    alterarDados({...dados, pagina: pagina});
                }}
            />
        
        </Modal.Body>
        <Modal.Footer  className='border-0'>
            <Button
                variant='danger'
                onClick={() => {alterarMostrar(false)}}
                // disabled={salvando}
            >
                <span>Fechar</span>
            </Button>
            {/* <Button
                onClick={salvar}
                variant='success'
                disabled={salvando}
            >
                {salvando ? 
                    <>
                        <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                        <span>Salvando</span>
                    </> : 
                    <span>Salvar</span>
                }
            </Button> */}
        </Modal.Footer>
        {carregando && 
            <Carregador className="bg-transparent text-dark" />
        }
    </Modal>

}
