import { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';

// componentes
import Select from 'react-select';
import useIsMounted from 'ismounted';
import { Modal, Container, Card, Row, Col, Form, Button, InputGroup, Spinner, Alert, OverlayTrigger, Tooltip, Badge} from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faRandom } from '@fortawesome/free-solid-svg-icons';

import { intersectObject, copiaCampo } from './../../../Funcoes';
import { FichaComponent } from './components';

// contexto
import { PainelContexto } from './../../PainelContexto';

// faz o cadastro
export default function ModalEditar({
    
}){

    // dados
    const isMounted = useIsMounted();
    const { path, url } = useRouteMatch();
    const painelContextoDados = useContext(PainelContexto);
    const history = useHistory();

    // parametros da url
    const {
        id = null
    } = useParams();

    // referencias
    let camposRef = {
        escrita: useRef(),
        leitura: useRef(),
        usuarioMaster: useRef(),
        senhaMaster: useRef()
    }

    // prepara
    const dadosPadrao = {
        id: null,
        nome: null,
        escrita: null,
        leitura: null,
        usuarioMaster: null,
        senhaMaster: null
    }
    
    // estados
    const [ dadosIniciais, alterarDadosIniciais ] = useState({});
    const [ dados, alterarDados ] = useState({...dadosPadrao});
    const [ mostrar, alterarMostrar ] = useState(true);
    const [ salvando, alterarSalvando ] = useState(false);
    const [ carregando, alterarCarregando ] = useState(false);
    const [ erros, alterarErros ] = useState({});

    // inicializa
    useEffect(() => {
        
        // verifica se enviado o id
        if(id){

            // consulta
            consultarId();
        }
    }, []);

    // consulta dados do usuário
    async function consultarId(){

        // cria o carregamento
        alterarCarregando(true);

        try{
            
            // faz a requisição
            let { data } = await axios.get(`/bancosDados/${id}`);
            
            // define os dados
            alterarDadosIniciais({...data.dados});
            alterarDados({...data.dados});

        }catch(e){
            console.log('pegou o erro')
        }finally{
            if (isMounted.current) {
                alterarCarregando(false);
            }
        }

    }

    // finaliza
    async function salvar(){

        // salva registro
        alterarSalvando(true);
        alterarErros({});

        // faz a requisição
        try{

            // pega dados
            let dadosSalvar = intersectObject(dadosPadrao, dados);

            // faz a requisição
            let { data } = await axios.post(`/bancosDados`, {
                dados: dadosSalvar
            });

            // mensagem de cadastro realizado com sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Banco de dados editado!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    {dadosIniciais.nome}
                </div>
            </>);

            // // vai para a consulta de sms's enviados (por enquanto volta para a consulta de clientes)
            // history.push('/painel/caixa-saida');
            
            // altera
            alterarSalvando(false);
            alterarMostrar(false);
            
            // monta a url para retroceder
            let urlBack = url.split("/bancosDados").map((parte, i) => i > 0 ? '/bancosDados' : parte).join('');
            
            // volta e dá refresh pra atualizar a listagem
            history.push(urlBack, {refresh: true});

        }catch({response}){

            // define erros retornados
            alterarErros(response.data);

        }finally{
            if (isMounted.current) {
                alterarSalvando(false);
            }
        }
    }

    // return <>
    //     Aqui retorna
    // </>
    return <Modal 
        show={mostrar}
        centered
        onExited={() => {

            // monta a url para retroceder
            let urlBack = url.split("/bancosDados").map((parte, i) => i > 0 ? '/bancosDados' : parte).join('');
            
            // volta e dá refresh pra atualizar a listagem
            history.push(urlBack, {
                aPartirDe: 'editarBancoDados'
            });
            
            // fecha
            alterarMostrar(false);
        }}
        onHide={() => {

            // fecha
            alterarMostrar(false);
        }}
        size={'md'}
    >
        <Modal.Header className='border-0'>
            <Modal.Title 
                as='h5' 
                className={[
                    'text-center w-100',
                    'text-success'
                ].join(' ')}
            >
                {dadosIniciais.nome ? <>{dadosIniciais.nome} ({dados.id})</> : <>Novo banco</>}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'pt-0'}>
            {
                carregando ?
                <Col align="center">
                    <Spinner animation="border" />
                </Col>
                : <FichaComponent>
                    <Container>
                        <Form.Row>
                            <Col>
                                <Form.Label className="mb-0">Nome</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        value={dados.nome ?? ''}
                                        onChange={e => {
                                            alterarDados({...dados, nome: e.target.value});
                                        }}
                                    />

                                    {(erros.nome) && 
                                        <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.nome}</p></Alert>
                                    }

                                </InputGroup>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Form.Label className="mb-0">Host escrita</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        size="sm"
                                        className={[''].join(' ')}
                                        ref={camposRef.escrita}
                                        value={dados.escrita || ''}
                                        onChange={(e) => {
                                            alterarDados({...dados, escrita: e.target.value});
                                        }}
                                    />
                                    {(erros.escrita) && 
                                        <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.escrita}</p></Alert>
                                    }
                                    <InputGroup.Append>
                                        <Button  
                                            size="sm"
                                            className={[''].join(' ')}
                                            variant="outline-secondary"
                                            onClick={() => {
                                                
                                                // copia campo
                                                copiaCampo(camposRef.escrita.current);

                                            }}
                                        >
                                            <FontAwesomeIcon style={{fontSize: 15}} className="icone" icon={["fas", "copy"]}/>
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>
                        </Form.Row>
                        
                        <Form.Row>
                            <Col>
                                <Form.Label className="mb-0">Host leitura</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        size="sm"
                                        className={[''].join(' ')}
                                        ref={camposRef.leitura}
                                        value={dados.leitura || ''}
                                        onChange={(e) => {
                                            alterarDados({...dados, leitura: e.target.value});
                                        }}
                                    />
                                    {(erros.leitura) && 
                                        <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.leitura}</p></Alert>
                                    }
                                    <InputGroup.Append>
                                        <Button  
                                            size="sm"
                                            className={[''].join(' ')}
                                            variant="outline-secondary"
                                            onClick={() => {
                                                
                                                // copia campo
                                                copiaCampo(camposRef.leitura.current);

                                            }}
                                        >
                                            <FontAwesomeIcon style={{fontSize: 15}} className="icone" icon={["fas", "copy"]}/>
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>
                        </Form.Row>

                        <fieldset className="border rounded p-3 mt-3">
                            <legend className="w-auto h5 px-3 font-weight-normal mb-0">Dados acesso Master</legend>

                            <Form.Row>
                                <Col>
                                    <Form.Label className="mb-0">Usuário</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            size="sm"
                                            className={[''].join(' ')}
                                            ref={camposRef.usuarioMaster}
                                            value={dados.usuarioMaster || ''}
                                            onChange={(e) => {
                                                alterarDados({...dados, usuarioMaster: e.target.value});
                                            }}
                                        />
                                        {(erros.usuarioMaster) && 
                                            <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.usuarioMaster}</p></Alert>
                                        }
                                        <InputGroup.Append>
                                            <Button  
                                                size="sm"
                                                className={[''].join(' ')}
                                                variant="outline-secondary"
                                                onClick={() => {
                                                    
                                                    // copia campo
                                                    copiaCampo(camposRef.usuarioMaster.current);

                                                }}
                                            >
                                                <FontAwesomeIcon style={{fontSize: 15}} className="icone" icon={["fas", "copy"]}/>
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label className="mb-0">Senha</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            size="sm"
                                            className={[''].join(' ')}
                                            ref={camposRef.senhaMaster}
                                            value={dados.senhaMaster || ''}
                                            onChange={(e) => {
                                                alterarDados({...dados, senhaMaster: e.target.value});
                                            }}
                                        />
                                        {(erros.senhaMaster) && 
                                            <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.senhaMaster}</p></Alert>
                                        }
                                        <InputGroup.Append>
                                            <Button  
                                                size="sm"
                                                className={[''].join(' ')}
                                                variant="outline-secondary"
                                                onClick={() => {
                                                    
                                                    // copia campo
                                                    copiaCampo(camposRef.senhaMaster.current);

                                                }}
                                            >
                                                <FontAwesomeIcon style={{fontSize: 15}} className="icone" icon={["fas", "copy"]}/>
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Form.Row>
                        </fieldset>
                        
                    </Container>
                </FichaComponent>
            }
        </Modal.Body>
        <Modal.Footer className='border-0'>
            <Button
                variant='danger'
                onClick={() => {alterarMostrar(false)}}
                disabled={salvando}
            >
                <span>Fechar</span>
            </Button>
            <Button
                onClick={salvar}
                variant='success'
                disabled={salvando}
            >
                {salvando ? 
                    <>
                        <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                        <span>Salvando</span>
                    </> : 
                    <span>Salvar</span>
                }
            </Button>
        </Modal.Footer>
    </Modal>
}   