import React, { useState, useEffect, useContext } from 'react';

// componentes
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Row, Col, FormCheck } from 'react-bootstrap';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import { DebounceInput } from 'react-debounce-input';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import InputDataCombo from './../../../../Componentes/InputDataCombo';
import ComboUsuarios from './../../../../Componentes/ComboUsuarios';


export default function Filtros({
    parametros, 
    alterarParametros, 
    idUsuario,
    aoAlterarSelecaoUsuario
}){
    
    const [slider, alterarValorSlider] = useState(parametros.limite);
    const [timer, alterarTimer] = useState(null);

    // funcao ao alterar o slider
    const alterouSlider = (e) => {

        // limpa o timeout
        clearTimeout(timer);

        // pega o novo valor
        let novoValor = parseInt(e.target.value);

        // altera o valor do slider
        alterarValorSlider(novoValor)

        // aguarda tempo sem rolar o slider
        alterarTimer(setTimeout(() => {
            alterarParametros({...parametros, limite: novoValor})
        }, 300));
        
    }

    console.log(idUsuario);
    return <>
        <Row className="my-2">
            <Col>
                <Form.Label>
                    A partir de
                </Form.Label>
                <InputDataCombo 
                    placeholder="A partir de"
                    data={parametros.data_inicial}
                    onChange={(novaData) => {
                        alterarParametros({...parametros, data_inicial: novaData})
                    }}
                />
            </Col>
            <Col>
                <Form.Label>
                    Até
                </Form.Label>
                <InputDataCombo 
                    placeholder="Até"
                    data={parametros.data_final}
                    onChange={(novaData) => {
                        alterarParametros({...parametros, data_final: novaData})
                    }}
                />
            </Col>
        </Row>
        <Row className="my-2">
            <Col>
                <Form.Label>
                    Top <b>{slider}</b> empresas
                </Form.Label>
                <RangeSlider
                    min={20}
                    max={100}
                    value={slider}
                    onChange={alterouSlider}
                />
            </Col>
        </Row>
        <Row className="my-2">
            <Col>
                <Form.Label>
                    Empresa
                </Form.Label>
                <ComboUsuarios
                    id={idUsuario || null}
                    alterou={usuario => {
                        aoAlterarSelecaoUsuario(usuario);
                    }}
                    alteradoDadosRegistro={(dados) => {
                        aoAlterarSelecaoUsuario(dados);
                    }}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <Form.Check
                    type="switch"
                    id="switch-reajuste"
                    label="Não considerar empresas que possuem token"
                    checked={parametros.somente_nao_possuem_token === 'S'}
                    onChange={e => {
                        alterarParametros({...parametros, somente_nao_possuem_token: (e.target.checked) ? 'S' : 'N', pagina: 1});
                    }}
                />
            </Col>
        </Row>
    </>
}