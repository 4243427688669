import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const HistoricoComponent = styled(Card).attrs({
    className: `painel-consulta border-0 shadow-sm`
})`
    flex: 1%;
    display: flex;
    flex-direction: column;
    
    .card-header{
        padding: 0;
        font-size: 0.9em;

        .h5{
            font-size: 1.25em;
        }

        .btn{
            padding: 0.25em 0.5em;
            font-size: 0.875em;
        }
    }

    .card-body{
        padding-top: 0.5rem;
    }

    .form-control{
        min-height: unset;
        height: 31px;
    }

    .btn{
        padding: 0.25em 0.5em;
        font-size: 0.875em;
        line-height: 1.5;
        border-radius: 0.2em;
    }

    .card{
        flex: 1;
        display: flex;
        flex-direction: column;

        > .card-header{
            border-left: 3px solid var(--cor-cliente-ficha) !important;
        }

        .card{
            > .card-header{
                border-left: unset !important;
            }
        }

    }

    .card-body{
        flex: 1%;
        display: flex;
        flex-direction: column;

        > div:first-child{
            display: flex;
            flex-direction: column;
            flex: 1;
            
            > div:first-child{
                flex: 1;
            }
        }
    }
    
    .tabela{
        width: 100%;
        border-radius: 0.3em;
        border-collapse: separate;
        font-size: 0.95em;
        margin-top: 0.75em;
        -webkit-border-horizontal-spacing: 0.2em;
        -webkit-border-vertical-spacing: 0.25em;
        
        &:empty{
            position: relative;
            min-height: 96px;

            &::after{
                content: "Sem registros";
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                color: grey;
                background: #fbfbfb;
                border-radius: 0.3em;
                box-shadow: 0px 0px 1px inset #cacaca;
            }
        }
        
        .registro{
            --cor-status-registro: black;
            /* display: flex;
            align-items: flex-start; */
            font-size: 0.9em;
            border-radius: 0.3em;

            &.registro-divisor-data{
                font-weight: 600;
                height: fit-content;

                td{
                    height: fit-content;
                    font-size: 0.8em;
                    text-align: center;
                    border-bottom: 1px solid #c3c0c0;
                    border-left: 1px solid #c3c0c0;
                    padding: 0 5px;

                    &:hover{
                        background: #fdfdfd;
                    }
                }
            }
            
            &:first-child{
                margin-top: 0;
            }

            &.cadastrar{
                --cor-status-registro: #5da218;
            }
            
            &.alterar{
                --cor-status-registro: #d17401;
            }

            &.deletar{
                --cor-status-registro: #d24b4c;
            }
            
            &.outros{
                --cor-status-registro: #816ecd;
            }

            td, th{
                border-left: 1px solid #f3f3f3;
                background: #fdfdfd;
                border: 1px solid #f3f3f3;
                padding: 0.25em 0.5em;

                &:first-child{
                    border-top-left-radius: 0.3em;
                    border-bottom-left-radius: 0.3em;
                }
                
                &:last-child{
                    border-top-right-radius: 0.3em;
                    border-bottom-right-radius: 0.3em;
                }
                
                &.integracao{
                    color: var(--cor-status-registro);
                    font-size: 0.8em;
                    border-left: 3px solid var(--cor-status-registro);
                }
                
            }
        }
    }

    .paginacao{
        font-size: 0.85em;

        .total-registros{
            padding: 0.125em 1.2em !important;
        }
    }
    
    .legenda{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5em;
        font-size: 0.8em;
        margin: 0.7em;

        div{
            display: flex;
            padding: 0 0.4em;
            border-radius: 0.3em;
            border-left: 3px solid;
            background: #fafafa;
            font-weight: 400;
            color: #434343;

            &.evento-cadastrar{
                border-color: #5da218;
            }
            
            &.evento-alterar{
                border-color: #d17401;
            }

            &.evento-deletar{
                border-color: #d24b4c;
            }
            
            &.evento-outros{
                border-color: #816ecd;
            }

        }
    }

`;