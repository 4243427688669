import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';

// componentes
import { useSelector } from 'react-redux';
import { Card, Form, Button, Row, Col, OverlayTrigger, Popover, Tooltip, Badge, Alert } from 'react-bootstrap';
import { BrowserView, MobileView, isMobile, isBrowser } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// componente
export default function Registro({
    dados
}){

    return <> 
        <tr>
            <td>
                {dados.nome_fantasia}
            </td>
            <td className={'text-center'}>
                {dados.acessos}
            </td>
            <td className={'text-center'}>
                {dados.atualizacoes_pagina}
            </td>
            <td className={'text-center estreito'}>
                {dados.novas_guias}
            </td>
            <td className={'text-center estreito'}>
                {dados.carregamentos_mapa}
            </td>
            <td className={'text-center'}>
                {dados.usuarios_empresa}
            </td>
        </tr>
    </>
}