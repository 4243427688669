import React, { useState, useEffect, useContext, useRef } from 'react';
import styles from './index.module.scss';
import { Card, Row, Col, Form, Button, InputGroup, Tabs, Tab, Spinner} from 'react-bootstrap';

// icones
import iconeCliente from './../Icones/cliente.png';
import iconeClienteLight from './../Icones/clientelight.png';
import iconeParceiro from './../Icones/parceiro.png';
import iconeDemo from './../Icones/demo.png';
import iconeDemoLight from './../Icones/demolight.png';
import iconeBloqueado from './../Icones/bloqueado.png';
import iconeCancelado from './../Icones/cancelado.png';

// função para o relatório de acessos
export default function Legenda(){

    useEffect(() => {
        
    }, [])

    return <>
        <div className={[styles.legendaMapa].join(' ')}>
            <div>
                <img src={iconeCliente}/>
                Cliente
            </div>
            <div>
                <img src={iconeClienteLight}/>
                Cliente Light
            </div>
            <div>
                <img src={iconeParceiro}/>
                Parceiro
            </div>
            <div>
                <img src={iconeBloqueado}/>
                Bloqueado
            </div>
            <div>
                <img src={iconeCancelado}/>
                Cancelado
            </div>
            <div>
                <img src={iconeDemo}/>
                Demo
            </div>
            <div>
                <img src={iconeDemoLight}/>
                Demo Light
            </div>
        </div>
    </>

}
