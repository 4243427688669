import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import moment from 'moment';
import styles from './../index.module.scss';

// componentes
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Row, Col, Form, Button, InputGroup, Tabs, Tab, Spinner, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import useIsMounted from 'ismounted';

import GraficoPie from './../../GraficoPie';


// contextos    
import { PainelContexto } from './../../../../PainelContexto';
import { left } from '@popperjs/core';

// função para o relatório de acessos
export default function TotaisAcessos({
    parametros
}){
      
    // dados
    const permissaoReducer = useSelector(state => state.permissaoReducer);
    const isMounted = useIsMounted();
    const history = useHistory();
    
    const [dados, alterarDados] = useState(null);
    const [carregando, alterarCarregando] = useState(true);

    // consulta os dados das empresas
    async function consultar(){

		// inicia carregamento
        alterarCarregando(true);
        
        try{
            
            // faz a requisição
            let { data } = await axios.get(`/relatorios/acessos/totais`, {
                params: parametros
            });
            
			alterarDados({...data.dados});

			// // desseleciona empresa
			// alterarEmpresaSelecionada(null);
			// alterarDadosEmpresaSelecionada(null)
			
        }catch(e){
            console.log('pegou o erro', e)
        }finally{
            alterarCarregando(false);
        }

	}
	
    // inicializa
    useEffect(() => {

        // executa
		consultar();

    }, [parametros]);

    // função para montar os dados do gráfico
	const retornaDadosPie = (dados) => {

        return [
            {
				name: 'Login',
				value: parseInt(dados.acessos)
            },
            {
				name: 'Atualização página',
				value: parseInt(dados.atualizacoes_pagina)
			},
            {
				name: 'Novas guias',
				value: parseInt(dados.novas_guias)
			}
        ]

	}

    return <>
        <Card className='border-0 h-100'>
            <Card.Header className='py-2 mb-0 border-0 bg-white'>
                <b>Acessos</b>
                <OverlayTrigger 
                    trigger="click"
                    overlay={
                        <Tooltip 
                            className={[styles.tooltipInfo].join(' ')}
                        >
                            <ul>
                                <li>Login: quantidade de novos logins, pela página de login.</li>
                                <li>Atualizações: quantidade de atualizações da página após o login.</li>
                                <li>Novas guias: quantidade de guias abertas.</li>
                                <li>Mapa: quantidade de vezes que o mapa foi carregado.</li>
                            </ul>
                        </Tooltip>
                    }
                    placement='bottom'
                    delay={{ show: 150, hide: 400 }}
                >
                    <FontAwesomeIcon 
                        className={[styles.icone].join(' ')} 
                        icon={'question-circle'}
                    ></FontAwesomeIcon>
                </OverlayTrigger>
            </Card.Header>
            <Card.Body className='py-2'>
                <Row className='h-100'>
                    {
                        carregando  ?

                        <Col align="center">
                            <Spinner animation="border" />
                        </Col>

                        :
                        <>
                            <Col>

                                <Row>
                                    <Col align="center">
                                        <b 
                                            style={{
                                                color: '#dc3019',
                                                fontSize: 'clamp(1.5em, 2.5vw, 3em)'
                                            }}
                                        >
                                            {dados.total_acessos}
                                        </b>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row>
                                            Login: {dados.acessos}
                                        </Row>
                                        <Row>
                                            Atualizações: {dados.atualizacoes_pagina}
                                        </Row>
                                        <Row>
                                            Novas guias: {dados.novas_guias}
                                        </Row>
                                        <Row>
                                            Mapa: {dados.carregamentos_mapa}
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            {
                                retornaDadosPie(dados).filter((e) => e.value > 0).length > 0 && 
                                <Col>
                                    <Row className="mt-3 justify-content-center">
                                        <GraficoPie
                                            dadosGrafico={retornaDadosPie(dados)}
                                            config={{
                                                tamanho: 150,
                                                graficoMetade: true,
                                                cores: ['#fe9f13', '#c62c18', '#03A9F4'],
                                            }}
                                        />
                                    </Row>
                                </Col>
                            }
                            
                        </>
                    }
                </Row>
            </Card.Body>
        </Card>
    </>
}
