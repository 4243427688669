import React from 'react';
import { Route } from "react-router-dom";

// componentes
import Consultar from './Consultar';
import ModalCadastro from './ModalCadastro';

// escreve o email
export default function GruposChaveGoogle(){
      
    return <>

        <div className={[].join(' ')}>
            <Consultar />

            {/* rotas */}
            <Route exact path={[`/painel/gruposChaveGoogle/cadastrar`, `/painel/gruposChaveGoogle/editar/:id`]}>
                <ModalCadastro />
            </Route>
        </div>
    </>
}
