import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';
import axios from 'axios';
import moment from 'moment';

// componentes
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Row, Col, Form, Button, InputGroup, Tabs, Tab} from 'react-bootstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import useIsMounted from 'ismounted';

// contextos    
import { PainelContexto } from './../PainelContexto';

import RelatorioAcessos from './RelatorioAcessos';
import EmpresasMapa from './EmpresasMapa';

// função para o relatório de acessos
export default function Relatorios(){
      
    // dados
    const permissaoReducer = useSelector(state => state.permissaoReducer);
    const isMounted = useIsMounted();
    const history = useHistory();
    
    const [relatorio, alterarRelatorio] = useState(null);

    // inicializa
    useEffect(() => {


    }, []);

    return <>
    <Tabs defaultActiveKey="acessos" id="tab-relatorios" className={[styles.navRelatorios, 'mb-2'].join(' ')}>
        <Tab eventKey="geral" title="Geral" disabled>
            Relatório geral
        </Tab>
        <Tab eventKey="acessos" title="Acessos">
            <RelatorioAcessos />
        </Tab>
        <Tab eventKey="mapa" title="Mapa">
            <EmpresasMapa/>
        </Tab>
    </Tabs>
    </>
}
