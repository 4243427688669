import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Route, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, Modal } from 'react-bootstrap';

import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';

import Paginacao from '../../Componentes/Paginacao';
import Confirmar from '../../Componentes/Confirmar';

import Registro from './Registro';
import { HistoricoComponent } from "./components";
import { toast } from 'react-toastify';
import ModalFiltros from './ModalFiltros';

export default function Historico({
    // idEmpresa
    idHistorico,
    esconderHeader=false
}){

    // pega location
    const history = useHistory();
    const location = useLocation();
    let { path, url } = useRouteMatch();

    // parametros da url
    const {
        idEmpresa = null
    } = useParams();

    // estados
    const [ carregando, alterarCarregando ] = useState(null);
    const [ mostrarFiltros, alterarMostrarFiltros ] = useState(false);
    const [ parametros, alterarParametros ] = useState({
        registrosPorPagina: 10,
        pagina: 1,
        pesquisa: null,
        idEmpresa: idEmpresa ?? null
    });
    const [ registros, alterarRegistros ] = useState([]);
    const [ totalRegistros, alterarTotalRegistros ] = useState(null);
    const [ confirmar, alterarConfirmar ] = useState(null);
    const [ registroMostrarDados, alterarRegistroMostrarDados ] = useState(null);
    const [ registroMostrarFilhos, alterarRegistroMostrarFilhos ] = useState(null);
    const refAbrirFiltros = useRef(null);

    // efeitos

    // prepara
    let pathsConsultar = [
        '/painel/historico',
        '/painel/empresas/editar/:idEmpresa/historico'
    ].map((p) => p.replace(':idEmpresa', idEmpresa));

    useEffect(() => {

        // verifica
        if(carregando !== null && pathsConsultar.includes(location.pathname)){

            // consulta registros
            consultar();
        }

    }, [parametros]);

    useEffect(() => {

        // pega
        let {
            aPartirDe
        } = location.state ?? {};

        // se retornar a essa rota
        if(pathsConsultar.includes(location.pathname) && (!aPartirDe || !['cadastrarEmpresaIntegracaoSistema'].includes(aPartirDe))){

            // consulta
            consultar();
        }

    }, [location.pathname]);

    // função para consultar
    async function consultar(){

        // cria o carregamento
        alterarCarregando(true);

        try{

            // consulta
            let { data } = await axios.get(`/historico`, {
                params: {
                    idEmpresa,
                    idHistorico,
                    ...parametros
                }
            });

            // sucesso

            // pega os registros e total
            alterarRegistros(data.registros);
            alterarTotalRegistros(data.totalRegistros);

        }catch(e){
            // erro
        }finally{
            // finalizou

            // remove o carregamento
            alterarCarregando(false);
        }
    }

    // retorna registro
    function retornaRegistros(){

        // retorna registros organizado
        return registros.map((registro, indice) => 
            <Registro
                key={registro.dados.id}
                dados={registro.dados}
                funcionario={registro.funcionario}
                usuario={registro.usuario}
                dataAnterior={indice > 0 ? registros[indice - 1].dados.dataCadastro : null}
                mostrarAbrir={registro.dados.dadosHistorico}
                clicouAbrir={() => {

                    // altera para mostrar o modal com os dados do registro
                    alterarRegistroMostrarDados(registro);
                }}
                possuiFilhos={registro.possuiFilhos === 'S'}
                clicouAbrirFilhos={() => {

                    // altera para mostrar os filtros
                    alterarRegistroMostrarFilhos(registro);
                }}
            />
        )
    }

    return <HistoricoComponent>
        <ModalFiltros
            elemento={refAbrirFiltros.current}
            mostrar={mostrarFiltros}
            alterarMostrar={alterarMostrarFiltros}
            parametros={parametros}
            alterarParametros={alterarParametros}
        />
        <Confirmar config={confirmar} alterar={alterarConfirmar}/>
        
        <Card.Body className="py-2 px-3">
            {!esconderHeader && <Card.Header className={'px-0'}>
                <div className={'d-flex '}>
                    <Card.Title className={'flex-grow-1 d-flex align-items-center mb-0'}>
                        Histórico
                    </Card.Title>
                    <div className='d-flex' style={{gap: '0.5em'}}>
                        <Button
                            variant='light'
                            size='sm'
                            ref={refAbrirFiltros}
                            onClick={() => {

                                // define
                                alterarMostrarFiltros(true);
                            }}
                            style={{
                                fontSize: '0.9em',
                                boxShadow: '0 0 1px #b1b1b1',
                                border: 0,
                                display: 'flex',
                                gap: '0.2em',
                                alignItems: 'center'
                            }}
                        >
                            <FontAwesomeIcon className="icone" icon={faFilter} />
                            Filtrar
                        </Button>
                    </div>
                </div>
            </Card.Header>}
            <div>
                {
                carregando ? <div className="p-5 text-center">
                    <FontAwesomeIcon icon={faSpinner} pulse /> Carregando...
                </div> : (
                        <div
                            className={'my-2'}
                        >
                            <table className='tabela'>
                                {totalRegistros > 0 && <tbody>
                                    { retornaRegistros() }
                                </tbody>}
                            </table>
                        </div>
                    )
                }
                
                <Paginacao
                    pagina={parametros.pagina}
                    registrosPorPagina={parametros.registrosPorPagina}
                    totalRegistros={totalRegistros}
                    alterouPagina={(pagina) => {
                        alterarParametros({...parametros, pagina: pagina});
                    }}
                />

                <div className='legenda'>
                    <div className='evento-cadastrar'>Cadastro</div>
                    <div className='evento-alterar'>Alteração</div>
                    <div className='evento-deletar'>Exclusão</div>
                    <div className='evento-outros'>Outros</div>
                </div>

            </div>
        </Card.Body>

        <Modal
            show={!!registroMostrarDados}
            onExited={() => {}}
            onHide={() => {
                alterarRegistroMostrarDados(null);
            }}
            size={'md'}
            backdrop
            style={{
                zIndex: 1040
            }}
        >
            <Modal.Header className='border-0'>
                <Modal.Title 
                    as='h5' 
                    className={[
                        'text-center w-100',
                        'text-success'
                    ].join(' ')}
                >
                    Informações histórico
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={'pt-0'}>
                <JSONPretty
                    id="json-info-servidor"
                    data={registroMostrarDados ? registroMostrarDados.dados.dadosHistorico : null}
                    space="4"
                    mainStyle='color: #3f8a98;background: #f7f7f7;padding: 3px;border-radius: 3px;max-height: 80vh;overflow: auto;'
                />
            </Modal.Body>
        </Modal>
        
        <Modal
            show={!!registroMostrarFilhos}
            onExited={() => {}}
            onHide={() => {
                alterarRegistroMostrarFilhos(null);
            }}
            size={'lg'}
            centered
            style={{
                zIndex: 1040
            }}
        >
            <Modal.Header className='border-0 pb-0'>
                <Modal.Title 
                    as='h5' 
                    className={[
                        'text-center w-100',
                        'text-success'
                    ].join(' ')}
                >
                    Filhos histórico
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={'pt-0 d-flex flex-column'} style={{
                minHeight: '50vh'
            }}>
                {registroMostrarFilhos && <Historico
                    idHistorico={registroMostrarFilhos.dados.id}
                    esconderHeader={true}
                />}
            </Modal.Body>
        </Modal>
    </HistoricoComponent>
    
}