import React, { useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Badge, Button, Dropdown, FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';

import { FiltrosComponent } from './components';
import Filtro from './Filtro';

// prepara o menu customizado
const CustomMenu = React.forwardRef(({
    children,
    style,
    className,

    pesquisa,
    alterarPesquisa,

    'aria-labelledby': labeledBy
}, ref) => {

    return (
        <div
            ref={ref}
            style={style}
            className={className}
            aria-labelledby={labeledBy}
        >
            <Row>
                <Col className={'mx-2 mb-2'}>
                    <FormControl
                        autoFocus
                        size={'sm'}
                        placeholder="Digite..."
                        onChange={(e) => alterarPesquisa(e.target.value)}
                        value={pesquisa}
                    />
                </Col>
            </Row>
            <ul className={["lista-menu-filtros", "list-unstyled", 'm-0'].join(' ')}>
                {React.Children.toArray(children).filter((child) =>
                    !pesquisa || child.props.children.toLowerCase().startsWith(pesquisa)
                )}
            </ul>
        </div>
      );
    },
);
  
export default function Filtros({
    titulo,
    filtros,
    alterarFiltros,
    remover
}){

    // estados
    const [ filtrosAplicados, alterarFiltrosAplicados ] = useState([]);
    const [ pesquisaMenuNovoFiltro, alterarPesquisaMenuNovoFiltro ] = useState('');

    // prepara filtros padrão
    let filtrosPadrao = {
        anteriorOuIgualVersao: {
            descricao: `Anterior ou igual a versão`,
            tipo: "idVersao"
        },
        posteriorOuIgualVersao: {
            descricao: `Posterior ou igual a versão`,
            tipo: "idVersao"
        },
        versao: {
            descricao: `Versão`,
            tipo: "text",
        },
        versaoSubversao: {
            descricao: `Versão %`,
            tipo: "text"
        },
        anteriorVersao: {
            descricao: `Anterior a versão`,
            tipo: "idVersao"
        },
        posteriorVersao: {
            descricao: `Posterior a versão`,
            tipo: "idVersao"
        },
        emIdsEmpresas: {
            descricao: `Por empresas`,
            tipo: "arrayEmpresas"
        },
        naoEmIdsEmpresas: {
            descricao: `Ignorar empresas`,
            tipo: "arrayEmpresas"
        },
        emIds: {
            descricao: `Por usuários`,
            tipo: "arrayUsuarios"
        },
        naoEmIds: {
            descricao: `Ignorar usuários`,
            tipo: "arrayUsuarios"
        },
        situacao: {
            descricao: `Situação (empresa)`,
            tipo: "selectMultiplo",
            combo: [
                {
                    valor: "cliente",
                    descricao: "Cliente"
                },
                {
                    valor: "demo",
                    descricao: "Demo"
                },
                {
                    valor: "parceiro",
                    descricao: "Parceiro"
                },
                {
                    valor: "bloqueado",
                    descricao: "Bloqueado"
                },
                {
                    valor: "cancelado",
                    descricao: "Cancelado"
                }
            ]
        },
        semAcesso: {
            descricao: `Sem acesso`,
            tipo: "simNao"
        },
        possuiIntegracao: {
            descricao: `Possui integração`,
            tipo: "simNao"
        },
        posteriorDataCliente: {
            descricao: `Data cliente após`,
            tipo: "date"
        },
        usarTokenGoogleCliente: {
            descricao: `Usa token google cliente`,
            tipo: "simNao"
        },
        servidorProprio: {
            descricao: `Servidor próprio`,
            tipo: "simNao"
        },
        idsServidorAluguel: {
            descricao: `Servidor alugado`,
            tipo: "arrayServidorAluguel"
        },
        planoSistema: {
            descricao: `Plano sistema`,
            tipo: "select",
            combo: [
                {valor: 'light', descricao: "Light"},
                {valor: 'padrao', descricao: "Padrão"}
            ]
        },
        master: {
            descricao: `Usuário master`,
            tipo: "simNao"
        }
    };

    useEffect(() => {

        // altera
        alterarFiltrosAplicados(Object.keys(filtros));
    }, [filtros]);

    return <FiltrosComponent>
        <div className={["d-flex", "justify-content-between", "align-items-center"].join(' ')}>
            <Badge className="p-2" variant="info">{titulo}</Badge>

            <div className="d-flex">
                <Dropdown
                    size="sm"
                    className="mr-2"
                >

                    <OverlayTrigger
                        overlay={<Tooltip>Nova condição para o grupo (mesmo que "e")</Tooltip>}
                        placement='left'
                    >
                        <Dropdown.Toggle
                            variant='success'
                            size='sm'
                            className={'btn-adicionar-filtro'}
                        >
                            <FontAwesomeIcon className="icone" icon={["fas", "plus"]} />
                            <span>Novo</span>
                        </Dropdown.Toggle>
                    </OverlayTrigger>

                    <Dropdown.Menu
                        as={CustomMenu}
                        
                        pesquisa={pesquisaMenuNovoFiltro}
                        alterarPesquisa={alterarPesquisaMenuNovoFiltro}
                    >
                        {
                            Object.keys(filtrosPadrao).filter((f) => !filtrosAplicados.includes(f)).map((f) => {

                                // retorna
                                return <Dropdown.Item
                                    key={f}
                                    onClick={() => {

                                        // altera os filtros selecionados
                                        alterarFiltros({...filtros, [f]: null});
                                        alterarPesquisaMenuNovoFiltro('');
                                    }}
                                >{filtrosPadrao[f].descricao}</Dropdown.Item>
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>

                <OverlayTrigger 
                    placement='top'
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                            Remover grupo
                        </Tooltip>
                    }
                >
                    <Button
                        size="sm"
                        variant="danger"
                        onClick={() => {
                            remover()
                        }}
                        style={{fontSize: '0.8em'}}
                    >
                        <FontAwesomeIcon className="icone" icon={["fas", 'trash']} />
                    </Button>
                </OverlayTrigger>
            </div>
        </div>
        <div className={["filtros", "d-flex", "gap-2", "flex-grow-1"].join(' ')}>
            {
                filtrosAplicados.filter((filtro) => filtrosPadrao[filtro]).map((filtro, i) => {
                    return <Filtro
                        key={`filtro-${i}`}
                        filtro={filtrosPadrao[filtro]}
                        nome={filtro}
                        valor={filtros[filtro] ?? null}
                        alterarValor={(valor) => {
                            alterarFiltros({...filtros, [filtro]: valor})
                        }}
                        remover={() => {

                            // prepara
                            let novosFiltros = {};

                            // percorre
                            Object.keys(filtros).forEach((f) => {

                                // verifica se é o mesmo
                                if(f !== filtro){

                                    // adiciona
                                    novosFiltros[f] = filtros[f];
                                }
                            });

                            // altera
                            alterarFiltros(novosFiltros);
                        }}
                        descricao={filtrosPadrao[filtro].descricao}
                    />
                })
            }
        </div>
    </FiltrosComponent>
}