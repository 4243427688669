import { Overlay, Popover } from 'react-bootstrap';
import styled from 'styled-components';
import { FichaComponent } from '../../../Componentes/components';

export const ModalFiltrosComponent = styled(Overlay).attrs({
    className: ``
})`
`;

export const PopoverFiltrosComponent = styled(FichaComponent).attrs({
    as: Popover,
    className: ``
})`
    border: 0;
    box-shadow: rgb(0 0 0 / 8%) 0px 0rem 0.5rem;

    .arrow{

        &::before{
            border-bottom-color: #ebebeb;
        }

        &::after{
            border-bottom-color: #fafafa;
        }
    }

    .popover-header{
        padding: 0.25rem 0.75rem;
        background-color: #fafafa;
        font-weight: 600;
        font-size: 1em;
        display: flex;
        justify-content: space-between;

        [name=botao-limpar]{
            border-radius: 0.5em;
            background: #e7e7e7;
            outline: 0;
            color: #393939;
            border: none;
            box-shadow: 0 0 1px grey;

            &:hover, &:focus{
                opacity: 0.9;
            }
        }
    }
    
    .btn{
        padding: 0.125em 0.5em;
        font-size: 0.875em;
        line-height: 1.5;
        border-radius: 0.2em;
    }

    .row{
        display: flex;
        gap: 0.5em;
        padding-right: 15px;
        padding-left: 15px;

        .col{
            padding: 0;
        }
    }

    .footer{
        margin-top: 0.5em;
        padding-top: 0.5em;
        border-top: 1px solid #f4f4f4;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

`;