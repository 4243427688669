import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

// componentes
import useIsMounted from 'ismounted';
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Card, Row, Col, Form, Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ComboServidoresEmail from './../../../Componentes/ComboServidoresEmail';
import ConteudoEmail from './../../ModelosEmail/ConteudoEmail';
import InfoAdicional from './../../Clientes/EscreverEmail/InfoAdicional';
// import Tags from './../../../Componentes/Tags';

export default function EmailsLista(){

    // pega os parametros da consulta de clientes
    const isMounted = useIsMounted();
    let history = useHistory();
    let location = useLocation();
    let [parametros, alterarParametros] = useState(null);
    let [dados, alterarDados] = useState({
        id: null,
        descricao: null,
        titulo: null,
        conteudo: null,
        responder_para: null,
        agendado_para_data: null,
        agendado_para_hora: null,
        destaque: null,
        id_imagem: null,
        id_email_servidor_envio: null
    });
    let [salvando, alterarSalvando] = useState(false);
    let [lista, alterarLlista] = useState([]);


    // carrega modelo de email
    async function carregarModeloEmailCliente(id){

        // envia email
        if(id === null){
            return;
        }

        // carrega os dados do modelo de email
        try{

            // faz a requisição
            let { data } = await axios.get(`/modelosEmail/${id}`);
            alterarDados(data.dados);
            dados.id_imagem = data.dados.id_imagem;
            dados.titulo = data.dados.titulo;
            dados.destaque = data.dados.destaque;
            dados.conteudo = data.dados.conteudo;
            alterarDados({...dados});


        }catch({response}){

        }
    }


    // salva
    async function salvar(){
        alterarSalvando(true);

        try{

            // envia emails em massa
            let { data } = await axios.post(`/emailsEnviado/lista`, {
                dados: dados,
                lista: lista
            });

            // finalizado
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Emails programados!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </>);
            
            // mostra toast e retorna
            history.push('/painel/caixa-saida');

        }catch({response}){

        }finally{
            alterarSalvando(false);
        }

    }


    return <>
        <Row>
            <Col md='8'>
                <Card className='border-0 shadow-sm'>
                    <Card.Header className='mb-0 border-0 bg-white'>
                        <Card.Title className='pb-0 mb-0'>
                            Envio para lista de emails
                        </Card.Title>
                        
                    </Card.Header>
                    <Card.Body>

                        <Form.Group>
                            <Form.Label>Emails {lista.length > 0 && <Badge variant={'success'}>{lista.split(',').length}</Badge>}</Form.Label>
                            <Form.Control 
                                placeholder="fulano@email.com, ciclano@email.com"
                                value={lista}
                                onChange={e => {
                                    alterarLlista(e.target.value);
                                }}
                            />
                        </Form.Group>

                        <hr />
                        <ConteudoEmail 
                            value={{
                                id_imagem: dados.id_imagem,
                                titulo: dados.titulo,
                                destaque: dados.destaque,
                                conteudo: dados.conteudo
                            }}
                            onChange={dadosNovos => {
                                Object.assign(dados, dadosNovos);
                                alterarDados({...dados});
                            }}
                        />
                    </Card.Body>
                </Card>

                <SalvarFormulario 
                    salvando={salvando}
                    cancelar={() => {
                        history.goBack();
                    }}
                    onClick={() => salvar(dados)}
                />
            </Col>
            <Col md='4'>
                <Card className='border-0 shadow-sm mb-4'>
                    <Card.Body className='pb-0'>

                        <InfoAdicional 
                            dados={dados}
                            alterarDados={alterarDados}
                            alterouIdModelo={carregarModeloEmailCliente}
                        />

                        <Form.Group>
                            <Form.Label>Remetente</Form.Label>
                            <ComboServidoresEmail 
                                id={dados.id_email_servidor_envio}
                                alterou={servidorEmail => {
                                    if(servidorEmail !== null){
                                        dados.id_email_servidor_envio = servidorEmail.dados.id;
                                    }else{
                                        dados.id_email_servidor_envio = null;
                                    }
                                    alterarDados({...dados});
                                }}
                            />
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>

}


// salvar
function SalvarFormulario({
    salvando,
    cancelar,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button variant="success" disabled={salvando} onClick={onClick}>
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Enviando</span>
                </> : 
                <>
                    <FontAwesomeIcon className="icone" icon={["fas", 'paper-plane']} />
                <span>Enviar</span>
                </>
            }
        </Button>
    </Form.Group>
}
