import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSpinner } from '@fortawesome/free-solid-svg-icons';

import Paginacao from '../../../Componentes/Paginacao';
import Registro from './Registro';
import { ListaPedidosIntegracaoComponent } from "./components";
import { Button, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';

import ModalEditar from './ModalEditar';
import ModalFiltros from './ModalFiltros';
import ModalCancelar from './ModalCancelar';
import Confirmar from '../../../Componentes/Confirmar';

export default function ListaPedidosIntegracao(){

    // pega location
    const history = useHistory();
    const location = useLocation();
    let { path, url } = useRouteMatch();

    // estados
    const [ carregando, alterarCarregando ] = useState(false);
    const [ mostrarFiltros, alterarMostrarFiltros ] = useState(false);
    const [ parametros, alterarParametros ] = useState({
        registrosPorPagina: 8,
        pagina: 1,
        pesquisa: null,
        status: ['aguardando', 'integrando']
    });
    const [ registros, alterarRegistros ] = useState([]);
    const [ totalRegistros, alterarTotalRegistros ] = useState(null);
    const [ confirmar, alterarConfirmar ] = useState(null);
    const refAbrirFiltros = useRef(null);

    // efeitos

    useEffect(() => {

        // consulta registros
        consultar();

    }, [parametros]);

    useEffect(() => {

        // prepara
        let pathsConsultar = [
            '/painel/empresas/integracao/pedidosIntegracao'
        ];

        let {
            aPartirDe
        } = location.state ?? {};

        // se retornar a essa rota
        if(pathsConsultar.includes(location.pathname) && (!aPartirDe || !['visualizarPedidoIntegracao'].includes(aPartirDe))){

            // consulta
            consultar();
        }

    }, [location.pathname]);

    // função para consultar
    async function consultar(){

        // cria o carregamento
        alterarCarregando(true);

        try{

            // consulta
            let { data } = await axios.get('/empresas/pedidosIntegracao', {
                params: {
                    ...parametros
                }
            });

            // sucesso

            // pega os registros e total
            alterarRegistros(data.registros);
            alterarTotalRegistros(data.totalRegistros);

        }catch(e){
            // erro
        }finally{
            // finalizou

            // remove o carregamento
            alterarCarregando(false);
        }
    }

    // faz a exclusão
    async function deletar(dados){

        try{

            // faz a exclusão
            let { data } = await axios.delete(`/empresas/pedidosIntegracao/${dados.id}`);

            // excluído com sucesso

            // consulta

            // atualiza registros
            consultar();
            
        }catch({response}){

            // informa erro
            toast(<>
                <div
                    style={{background: '#ff6271'}}
                >
                    <div className="toast-header">
                        <strong className="mr-auto">Pedido de integração não pôde ser deletado</strong>
                        <button 
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                        </button>
                    </div>
                    <div className="toast-body text-light">
                        {
                            (response && response.data) &&
                            Object.keys(response.data).map((erro) => <p className={'m-0'} key={erro}>{response.data[erro]}<br/></p>)
                        }
                    </div>
                </div>
            </>);

        }finally{

        }

    }
    
    // ao clicar para excluir
    function clicouExcluirRegistro(registro){

        // confirma
        alterarConfirmar({
            aberto: true,
            titulo: 'Deletar integração',
            texto: `Tem certeza que deseja deletar esse pedido de integração?`,
            textoBotao: 'Deletar',
            variantConfirmar: 'danger',
            variantCancelar: 'secondary',
            backdrop: true,
            aoConfirmar: () => {

                // deleta o registro
                deletar(registro.dados);
            },
            aoCancelar: () => {
                // ao cancelar
            },
            aoFechar: () => {
                // ao fechar
            }
        })
    }

    // retorna registro
    function retornaRegistros(){

        // retorna registros organizado
        return registros.map(registro => 
            <Registro
                key={registro.dados.id}
                dados={registro.dados}
                dadosEmpresa={registro.dadosEmpresa}
                dadosUsuario={registro.dadosUsuario}
                dadosIntegracaoSistema={registro.dadosIntegracaoSistema}
                empresaPossuiIntegracaoSistema={registro.empresaPossuiIntegracaoSistema}
                
                clicouAbrir={() => {
                    
                    // abre a ficha do cliente (na integração)
                    history.push(`/painel/empresas/editar/${registro.dadosEmpresa.id}/integracao`)
                }}
                clicouEditar={() => {
                    
                    // verifica a url
                    let urlPedidos = `${url}${url.includes('/pedidosIntegracao') ? '' : '/pedidosIntegracao'}`;
                    
                    // vai na edição
                    history.push(`${urlPedidos}/${registro.dados.id}`);
                }}

                clicouCancelar={() => {

                    // verifica a url
                    let urlPedidos = `${url}${url.includes('/pedidosIntegracao') ? '' : '/pedidosIntegracao'}`;
                    
                    // vai na edição
                    history.push(`${urlPedidos}/${registro.dados.id}/cancelar`);
                }}

                clicouExcluir={() => {
                    
                    // envia o registro
                    clicouExcluirRegistro(registro);
                }}
            />
        )
    }

    return <ListaPedidosIntegracaoComponent>
        <Confirmar config={confirmar} alterar={alterarConfirmar}/>

        <ModalFiltros
            elemento={refAbrirFiltros.current}
            mostrar={mostrarFiltros}
            alterarMostrar={alterarMostrarFiltros}
            parametros={parametros}
            alterarParametros={alterarParametros}
        />
        <Card.Header className={'px-0 pb-0'}>
            <div className={'d-flex '}>
                <Card.Title className={'flex-grow-1 d-flex align-items-center mb-0'}>
                    Pedidos de integração
                </Card.Title>
                <div>
                    <Button
                        variant='light'
                        size='sm'
                        ref={refAbrirFiltros}
                        onClick={() => {

                            // define
                            alterarMostrarFiltros(true);
                        }}
                        style={{
                            fontSize: '0.9em',
                            boxShadow: '0 0 1px #b1b1b1',
                            border: 0,
                            display: 'flex',
                            gap: '0.2em',
                            alignItems: 'center'
                        }}
                    >
                        <FontAwesomeIcon className="icone" icon={faFilter} />
                        Filtrar
                    </Button>
                </div>
            </div>
        </Card.Header>
        <div>
            {
            carregando ? <div className="p-5 text-center">
                <FontAwesomeIcon icon={faSpinner} pulse /> Carregando...
            </div> : (
                    <div
                        className={'my-3'}
                    >
                        <BrowserView>
                            <table className="table table-hover tabela">
                                <thead>
                                    <tr>
                                        <th className={'text-center'}></th>
                                        <th>Razão social</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { retornaRegistros() }
                                </tbody>
                            </table>
                        </BrowserView>

                        <MobileView>
                            { retornaRegistros() }
                        </MobileView>
                    </div>
                )
            }
            
            <Paginacao
                pagina={parametros.pagina}
                registrosPorPagina={parametros.registrosPorPagina}
                totalRegistros={totalRegistros}
                alterouPagina={(pagina) => {
                    alterarParametros({...parametros, pagina: pagina});
                }}
            />

        </div>

        {/* rotas */}
        <Route exact path={[
            '/painel/empresas/integracao/pedidosIntegracao/:id'
        ]}>
            <ModalEditar />
        </Route>
        {/* rotas */}
        <Route exact path={[
            '/painel/empresas/integracao/pedidosIntegracao/:id/cancelar'
        ]}>
            <ModalCancelar />
        </Route>
    </ListaPedidosIntegracaoComponent>
    
}