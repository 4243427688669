import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';

// componentes
import { useSelector } from 'react-redux';
import { Card, Form, Button, Row, Col, OverlayTrigger, Popover, Tooltip, Badge, Alert } from 'react-bootstrap';
import { BrowserView, MobileView, isMobile, isBrowser } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BotaoTabela from './../../../../Componentes/BotaoTabela';
import { converterReal } from './../../../../Funcoes';
import { faUserShield, faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';

// componente
export default function Registro({
    dados,
    clicouEditar,
    clicouExcluir,
    clicouDownload,
    mostrarDownload
}){

    // // estados
    // let configuracao = useSelector(state => state.ConfiguracaoReducer);
    // const configuracaoUsuarioReducer = useSelector(state => state.ConfiguracaoUsuarioReducer);

    // retorna opções
    let retornarOpcoes = () => {

        // retorna componentes
        return <>
            {
                mostrarDownload &&
                <BotaoTabela 
                    onClick={clicouDownload}
                    icone={["fas", "download"]}
                >
                    <span>Arquivo chave</span>
                </BotaoTabela>
            }
            <BotaoTabela 
                onClick={clicouEditar}
                icone={["fas", "pencil-alt"]}
            >
                <span>Editar</span>
            </BotaoTabela>
            <BotaoTabela 
                onClick={clicouExcluir}
                icone={["fas", "trash"]}
            >
                <span>Excluir</span>
            </BotaoTabela>
        </>
    }

    return <> 

        {/* NAVEGADOR */}
        { isBrowser &&
            <tr
                style={{cursor: 'pointer'}}
                onClick={clicouEditar}
            >
                <td className={'estreito text-center'}>
                    <FontAwesomeIcon 
                        className={["icone"].join(' ')} 
                        style={{color: '#4d4d4d'}} 
                        icon={dados.dados.interno === 'S' ? faUserShield : faGlobeAmericas} 
                    />
                </td>
                <td>
                    {dados.dados.nome}
                    {parseInt(dados.totalEmpresas) > 0 && 
                        <Badge variant={'info'} className={['rounded-pill mx-2 px-2'].join(' ')}>
                            {dados.totalEmpresas}
                        </Badge>
                    }
                </td>
                <td className={'text-nowrap estreito text-right'}>
                    { retornarOpcoes() }
                </td>
            </tr>
        }

        {/* MOBILE */}
        {isMobile &&
            <Card
                className={'border-0 bg-light mb-2'}
                onClick={clicouEditar}
            >
                <Card.Body className={'p-2'}>
                    <div className={[isBrowser ? 'd-flex' : ''].join(' ')}>
                        <div className={'flex-grow-1'}>
                            <div>
                                <span>
                                    {dados.dados.nome}
                                </span>
                            </div>
                        </div>
                        <div className={'text-right'}>
                            { retornarOpcoes() }
                        </div>
                    </div>
                </Card.Body>
            </Card>
        }
    </>
}