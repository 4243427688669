import React, { useState, useEffect, useContext } from 'react';
import { Route, useHistory, useRouteMatch, useLocation, useParams } from "react-router-dom";
import styles from './index.module.scss';
import axios from 'axios';
import { toast } from 'react-toastify';

// componentes
import { Card, Table, Button, Badge, InputGroup, FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Registro from './Registro';
import Paginacao from './../../../Componentes/Paginacao';
import Confirmar from './../../../Componentes/Confirmar';
import ParametrosConsulta from './ParametrosConsulta';
import ModalEditar from './ModalEditar';

// contextos    
import { PainelContexto } from './../../PainelContexto';
import { UsuariosIcone } from './../../../../icones/svg';

export default function Consultar({
    parametrosConsulta={},
    filtrosAberto=true
}){

    // estados
    let painelContextoDados = useContext(PainelContexto);
    let history = useHistory();
    let {
        path,
        url
    } = useRouteMatch();
    const urlParams = useParams();
    const idEmpresa = urlParams.idEmpresa ?? parametrosConsulta.idRazaoSocial ?? null;
    const location = useLocation();
    const [carregando, alterarCarregando] = useState(false);
    const [totalRegistros, atualizarTotalRegistros] = useState(0);
    const [parametros, alterarParametros] = useState(Object.assign({
        pagina: 1,
        registrosPorPagina: isMobile ? 3 : 10,
        pesquisa: '',               // pesquisa
        permissao: null,          // permissao do usuário
        online: idEmpresa ? [] : ['S'],
        idRazaoSocial: idEmpresa ?? null
    }, parametrosConsulta));
    const [consulta, alterarConsulta] = useState([]);
    const [editando, alterarEditando] = useState(null);
    const [mostrarFiltros, alterarMostrarFiltros] = useState(filtrosAberto);
    const [ confirmar, alterarConfirmar ] = useState(null);
    const [ primeiraConsulta, alterarPrimeiraConsulta ] = useState(true);

    // prepara
    const pathConsulta = [
        `/painel/empresas/usuarios`,
        `/painel/empresas/editar/:idEmpresa/usuarios`
    ];
    let pathFichaUsuario = [];
    
    // percorre
    pathConsulta.forEach((p) => {

        // prepara
        pathFichaUsuario.push(p + `/cadastrar`);
        pathFichaUsuario.push(p + `/editar/:id`);
    });

    // inicializa
    useEffect(() => {
        // faz a consulta
        fazerConsulta();
    }, [parametros]);

    useEffect(() => {

        // prepara
        let pathsConsultar = pathConsulta.map((p) => p.replace(':idEmpresa', idEmpresa));

        // se retornar a essa rota
        if(!primeiraConsulta && pathsConsultar.includes(location.pathname)){
            fazerConsulta();
        }
    }, [location.pathname]);

    // quando um usuário muda o status online ou offline, atualiza
    useEffect(() => {

        // recebe um sinal de que algum usuário está online ou offline
        painelContextoDados.socket.current.on('usuarioStatusAlterado', atualizarStatusUsuario);

        return () => {
            painelContextoDados.socket.current.off('usuarioStatusAlterado', atualizarStatusUsuario);
        }

    }, [consulta]);

    function atualizarStatusUsuario(quemFoi){
        
        let novaConsulta = consulta.map(usuarioDados => {
            if(parseInt(quemFoi.id) === parseInt(usuarioDados.dados.id)){
                usuarioDados.statusOnline = quemFoi.status;
            }
            return usuarioDados;
        });
        
        // atualiza consulta
        alterarConsulta(novaConsulta);
    }

    // faz consulta
    async function fazerConsulta(){

        // carregando
        alterarCarregando(true);
        alterarPrimeiraConsulta(false);
        
        try{

            // prepara parametros
            let params = {
                ...parametros,
                permissao: parametros.permissao ? [parametros.permissao] : null
            }

            // faz a requisição
            let { data } = await axios.get('/empresas/usuarios', {
                params
            });

            // finalizado
            alterarConsulta(data.registros);
            atualizarTotalRegistros(data.totalRegistros)

        }catch(e){

        }finally{
            alterarCarregando(false);
        }
    }
    
    async function abrirModalEditar(id){

        // faz a requisição
        let { data } = await axios.get(`/cliente/${id}`);
        console.log(data)
    }


    function clicouLoginUsuario(dadosCliente){
        console.log(dadosCliente);
        // dadosCliente.dados

        // pega endereco do servidor com barra
        let enderecoServidor = dadosCliente.empresa.enderecoServidor;
        if(enderecoServidor[enderecoServidor.length - 1] !== '/'){
            enderecoServidor = enderecoServidor + "/";
        }

        let form = window.document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", enderecoServidor);
        form.setAttribute("target", "_blank");
        
        // parametro usuario
        let input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'usuario';
        input.value = dadosCliente.dados.usuario;
        form.appendChild(input);

        // parametro senha
        let inputSenha = document.createElement('input');
        inputSenha.type = 'hidden';
        inputSenha.name = 'senha';
        inputSenha.value = dadosCliente.senha;  
        form.appendChild(inputSenha);

        document.body.appendChild(form);
        
        //note I am using a post.htm page since I did not want to make double request to the page 
        //it might have some Page_Load call which might screw things up.
        // window.open("http://localhost", name, windowoption);
        form.submit();
        document.body.removeChild(form);

    }

    // função para enviar os dados de acesso do usuário
    async function enviarEmailDadosAcesso(id){

        try{

            // envia o email
            let { data } = await axios.post(`/empresas/usuarios/${id}/enviarEmailDadosAcesso`);

            // sucesso

            // informa
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Email programado!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    Email com dados de acesso do usuário foi programado.
                </div>
            </>);

        }catch(e){
            // algum erro
        }finally{
            // terminou
        }
    }

    // função para enviar os dados de acesso da consulta atual
    async function enviarEmailDadosAcessoConsulta(){

        try{

            // envia o email
            let { data } = await axios.post(`/empresas/usuarios/enviarEmailDadosAcesso`, parametros);

            // sucesso

            // verifica total
            if(data.totalRegistros == 0){
                // nenhum registro, ignora
            }else{

                // informa
                toast(({closeToast }) => <>
                    <div className="toast-header">
                        <strong className="mr-auto">Email programado!</strong>
                        <button 
                            onClick={closeToast} 
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="toast-body">
                        Email com dados de acesso dos usuários atuais foi programado.
                    </div>
                </>);
            }

        }catch(e){
            // algum erro
        }finally{
            // terminou
        }
    }

    // retorna registro
    let retornaRegistros = () => {

        // retorna registros organizado
        return consulta.map(registro => 
            <Registro 
                key={registro.dados.id}
                dados={registro.dados}
                empresa={registro.empresa}
                statusOnline={registro.statusOnline}
                mostrarInfoEmpresa={idEmpresa ? false : true}
                clicouEditar={() => {
                    history.push(`${url}/editar/${registro.dados.id}`);
                }}
                clicouEnviarEmail={() => {
                    history.push(`${url}/enviar-email/${registro.dados.id}`);
                }}
                clicouEnviarDadosAcesso={() => {

                    // confirma
                    alterarConfirmar({
                        aberto: true,
                        titulo: 'Enviar dados acesso',
                        texto: `Tem certeza que deseja enviar um email com os dados de acesso para o usuário?`,
                        textoBotao: 'Enviar dados acesso',
                        variantConfirmar: 'info',
                        variantCancelar: 'secondary',
                        aoConfirmar: () => {
                            // envia
                            enviarEmailDadosAcesso(registro.dados.id);
                        },
                        aoCancelar: () => {
                            // ao cancelar
                        },
                        aoFechar: () => {
                            // ao fechar
                        }
                    })
                    
                }}
                clicouLoginUsuario={() => {
                    clicouLoginUsuario(registro);
                }}
            />
        )
    }

    return <>
        <Confirmar config={confirmar} alterar={alterarConfirmar}/>
        <Card className="painel-consulta border-0" >
            <Card.Header>
                <div className={'d-flex '}>
                    <Card.Title className={'flex-grow-1 d-flex align-items-center'}>
                        {/* <UsuariosIcone className="icone mr-1" width={30} height={30}/> */}
                        Usuários
                    </Card.Title>
                    {parametros.idRazaoSocial && <div>
                        <OverlayTrigger 
                            overlay={<Tooltip>Envia email com dados de acesso para todos os usuários presentes na consulta atual!</Tooltip>}
                            placement='left'
                        >
                            <Button
                                variant='warning'
                                size='sm'
                                onClick={() => {
                                    
                                    // envia
                                    enviarEmailDadosAcessoConsulta();
                                }}
                                className="mr-1"
                            >
                                <FontAwesomeIcon className="icone" icon={["fas", "mail-bulk"]} />
                                <span>Enviar dados acesso</span>
                            </Button>
                        </OverlayTrigger>
                        <Button
                            variant='success'
                            size='sm'
                            onClick={() => {
                                history.push(`${url}/cadastrar`);
                            }}
                        >
                            <FontAwesomeIcon className="icone" icon={["fas", "plus"]} />
                            <span>Novo</span>
                        </Button>
                    </div>}
                </div>
            </Card.Header>
            <Card.Body>
                <ParametrosConsulta 
                    parametros={parametros}
                    alterarParametros={alterarParametros}
                    mostrarFiltros={mostrarFiltros}
                    alterarMostrarFiltros={alterarMostrarFiltros}
                />

                <div 
                    className={'my-3'}
                >
                    <BrowserView>
                        <table className="table table-hover tabela">
                            <thead>
                                <tr>
                                    <th className={'text-center'}><UsuariosIcone className="icone" width={22} height={22} style={{filter: 'grayscale(0.3)'}}/></th>
                                    <th></th>
                                    <th>Nome</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                { retornaRegistros() }
                            </tbody>
                        </table>
                    </BrowserView>

                    <MobileView>
                        { retornaRegistros() }
                    </MobileView>
                </div>
                
                <Paginacao
                    pagina={parametros.pagina}
                    registrosPorPagina={parametros.registrosPorPagina}
                    totalRegistros={totalRegistros}
                    alterouPagina={(pagina) => {
                        alterarParametros({...parametros, pagina: pagina});
                    }}
                />
            </Card.Body>
        </Card>

        {/* rotas */}
        <Route exact path={pathFichaUsuario}>
            <ModalEditar />
        </Route>
    </>
}