import styled from 'styled-components';
import { Card, Modal } from 'react-bootstrap';
import { ListaComponent } from './../components';

export const ListaTopEmpresasComponent = styled(ListaComponent).attrs({
    className: `painel-consulta shadow-sm`
})`
    height: 100%;
    display: flex;
    flex-direction: column;

    .card-body{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .tabela{
        
        .registro{
            --cor-status-registro: black;
                
            small{
                line-height: 1.25em;
            }

            .indice{
                color: #525252;
                font-size: 0.9em;
                background: #f7fbff;
                border-color: #e4edf7;
                border-radius: 0.3em;
                min-width: 25px;
            }
            
            .totais{
                display: flex;
                align-items: center;
                gap: 0.5em;
                justify-content: flex-end;
                margin-top: 0.3em;
                
                .total{
                    padding: 0 0.3em;
                    font-weight: 500;
                    min-width: 50px;
                    display: flex;
                    justify-content: center;
                    box-shadow: 0 0 1px #cbc9c9;

                    b{
                        display: none;
                    }
                }
            }
        }
    }

    .legenda{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5em;
        font-size: 0.8em;
        margin: 0.7em;

        div{
            display: flex;
            padding: 0 0.4em;
            border-radius: 0.3em;
            border-left: 3px solid;
            background: #fafafa;
            font-weight: 400;
            color: #434343;

            &.nao-encontrado{
                border-color: #d24b4c;
            }

            &.google{
                border-color: #d17401;
            }

            &.retornoReutilizado{
                border-color: #816ecd;
            }

            &.interno{
                border-color: #5da218;
            }
        }
    }

    .legenda div, .registro .total{
        display: flex;
        align-items: center;
        padding: 0 0.4em;
        border-radius: 0.3em;
        border-left: 3px solid;
        background: #fafafa;
        font-weight: 400;
        color: #434343;
        gap: 0.3em;

        &.geral{
            border-color: #3aa872;
        }

        &.login{
            border-color: #90d90b;
        }

        &.refresh{
            border-color: #f3910d;
        }

        &.new-tab{
            border-color: #418273;
        }
    }
`;