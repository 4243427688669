import moment from "moment";
import { Badge, Card } from "react-bootstrap";
import { isBrowser, isMobile } from "react-device-detect";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import BotaoTabela from "../../../Componentes/BotaoTabela";

export default function Registro({
    dados,
    dadosIntegracaoSistema,
    status,
    clicouExcluir
}){

    // retorna opções
    let retornarOpcoes = () => {

        // retorna componentes
        return <>
            <BotaoTabela 
                onClick={clicouExcluir}
                icone={faTimes}
            >
                <span>Excluir</span>
            </BotaoTabela>
        </>
    }

    return <> 

        <tr className={['registro', status].join(' ')}>

            <td className={'estreito text-center font-weight-bold integracao'}>
                {dadosIntegracaoSistema.tipo}
            </td>

            <td className="descricao">
                {dadosIntegracaoSistema.nome}
            </td>

            <td className={'text-nowrap estreito text-right'}>
                { retornarOpcoes() }
            </td>
        </tr>
    </>
}