import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

// componentes
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

// contexto
import { PermissaoContexto } from './../PermissaoContexto';

// componente para habilitar/desabilitar alguma parâmetro
export default function OpcaoBoleana({
    descricao,
    chave
}){

    // estados
    const dispatch = useDispatch();
    const { loginReducer } = useSelector(state => state);
    const { dados, alterarDados, dadosFuncionario } = useContext(PermissaoContexto);
    const [salvando, alterarSalvando] = useState(false);
    const [valorNovo, alterarValorNovo] = useState(dados[chave]);

    // salva código de configuracao
    async function salvar(valorAlterado){
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/funcionarioPermissao`, {
                chave: chave,
                valor: valorAlterado,
                id_funcionario: dadosFuncionario.dados.id
            });
            alterarValorNovo(data.valor);

            // finalizado, altera os dados
            dados[chave] = data.valor;
            alterarDados({...dados});

            // atualiza reducer
            if(parseInt(dadosFuncionario.dados.id) === parseInt(loginReducer.dadosUsuario.id)){
                let chaveAtualizar = {};
                chaveAtualizar[chave] = data.valor;
                dispatch({
                    type: 'permissaoReducer/ATUALIZAR',
                    dados: chaveAtualizar
                });
            }


        }catch({response}){

        }finally{
            alterarSalvando(false);
        }

    }

    return <>
        <div
            className='d-flex p-2'
        >
            <p className='flex-grow-1 m-0'>
                {descricao}
            </p>
            <div
                className='ml-2 d-flex'
            >
                <Form.Check 
                    type="switch"
                    id={`switch-configuracao-${chave}`}
                    label={''}
                    checked={valorNovo === 'S' ? true : false}
                    onChange={e => {
                        salvar(e.target.checked ? 'S' : 'N');
                    }}
                    disabled={salvando}
                />
                <div
                    className='text-secondary'
                    style={{width: `15px`}}
                >
                    {salvando && <FontAwesomeIcon pulse icon={faSpinner} /> }
                </div>
            </div>
        </div>
    </>

}