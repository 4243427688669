import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

// componentes
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Card, Row, Col, Form, Button, InputGroup, Tabs, Tab, ProgressBar} from 'react-bootstrap';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { toast } from 'react-toastify';
import { intersectObject, converterReal } from './../../../Funcoes';
import useIsMounted from 'ismounted';
import Formulario from './Formulario';
import FormularioTrello from './FormularioTrello';
import { Link } from 'react-router-dom';

import { FichaComponent } from './components';

// contextos    
import { PainelContexto } from './../../PainelContexto';

import Foto from './Foto';

// escreve o email
export default function Ficha({
    perfil = 'N'
}){

    // dados
    const { loginReducer, permissaoReducer } = useSelector(state => state);
    const isMounted = useIsMounted();
    const history = useHistory();
    let { id } = useParams(); // parametro url
    const dispatch = useDispatch();

    const [ modo, alterarModo ] = useState('cadastro'); // cadastro, edicao
    const [leitura, alterarLeitura] = useState(true); // se for aberto por terceiros, é somente leitura
    const [erros, alterarErros] = useState({});
    const dadosPadrao = {
        id: id || (perfil === 'S' ? loginReducer.dadosUsuario.id : null),
        nome: null,
        cpf: null, 
        rg: null,
        cep: null,
        endereco: null,
        cidade: null,
        estado: null,
        email: null,
        telefone: null,

        // dados acesso
        login: null,
        senha: null,
        confirmarSenha: null,
        senhaAtual: null,
        cargoAtual: null
    };
    const [arquivoFoto, alterarArquivoFoto] = useState({
        imagem: dadosPadrao.foto,
        arquivo: null,
        // onChange: (arquivo) => {
        //     alterarFoto({...foto, arquivo});
        // },
        progresso: null
    });
    const [dadosTrello, alterarDadosTrello] = useState({
        senha: null,
        token: (loginReducer.trello !== null) ? loginReducer.trello.token : null,
        chave: (loginReducer.trello !== null) ? loginReducer.trello.chave : null
    });
    
    const [dados, alterarDados] = useState(Object.assign({}, dadosPadrao));
    const [salvando, alterarSalvando] = useState(false);
    const [carregando, alterarCarregando] = useState(false);
    
    // inicializa
    useEffect(() => {
        // verifica se é alteração
        if(dados.id){
            alterarModo('edicao');
            consultarIdUsuario();

        }else{

        }

        alterarLeitura(!(!id || permissaoReducer.acessoFuncionarios === 'S'));

    }, []);

    // consulta os dados do cliente
    async function consultarIdUsuario(){

        try{
            
            // faz a requisição
            let { data } = await axios.get(`/funcionario/${dados.id}`);
            
            alterarDados({
                ...data.dados,
                senha: null,
                telefone: data.dados.telefone ? data.dados.telefone.replace(/\s/g, '') : null
            });

        }catch(e){
            console.log('pegou o erro', e)
        }finally{
            alterarCarregando(false);
        }

    }

    // salva edição
    async function salvar(){
        alterarSalvando(true);
        
        // faz a requisição
        try{

            // formata o nome
            dados.nome = dados.nome.replace(/(^\w|\s\w)/g, m => m.toUpperCase()).trim();

            // formata o nome da pessoa avisar
            dados.pessoaAvisar = dados.pessoaAvisar && dados.pessoaAvisar.replace(/(^\w|\s\w)/g, m => m.toUpperCase()).trim();
            
            // faz a requisição
            let { data } = await axios.post(`/funcionario`, {
                dados: intersectObject(dadosPadrao, dados),
                trello: dadosTrello
            });
            
            // se alterado a imagem, quando salvar, faz upload
            if(arquivoFoto.arquivo !== null){
                salvarFoto(data.id);
            }

            if(dadosTrello.senha !== null){
                dispatch({
                    type: 'loginReducer/TRELLO',
                    trello: {chave: dadosTrello.chave, token: dadosTrello.token}
                });
            }

            // mensagem de cadastro realizado com sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">{modo === 'cadastro' ? `Funcionário criado` : `Dados atualizados!`}</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    {modo === 'cadastro' ? `Novo funcionário foi cadastrado com sucesso` : `
                        ${dados.id === loginReducer.dadosUsuario.id ? `Seus dados cadastrais` : `Dados do funcionário`} foram atualizados.
                    `}
                </div>
            </>);

            // atualiza dados do usuário no login

            // verifica se é o próprio usuário que foi editado
            if(dados.id === loginReducer.dadosUsuario.id){

                // altera o login
                dispatch({
                    type: 'loginReducer/ATUALIZAR',
                    dadosUsuario: intersectObject(dadosPadrao, dados)
                });
    
            }else{

                // volta para a listagem de funcionários
                history.push('/painel/funcionarios');
            }
            
            // // vai para a consulta de sms's enviados (por enquanto volta para a consulta de clientes)
            // history.push('/painel/caixa-saida');

            alterarErros({});

        }catch({response}){
            alterarErros(response.data)
        }finally{
            if (isMounted.current) {
                alterarSalvando(false);
            }
        }

    }

    // salva a foto
    async function salvarFoto(id){

        // instancia um toast
        let toastId = toast(({ closeToast }) => <></>, {
            autoClose: false
        });

        try{

            // dados para salvar a ibagem
            let formData = new FormData();
            formData.append('imagem', arquivoFoto.arquivo);
            formData.append('id', id);

            // verifica o progresso da imagem do card
            function progressoFoto(progressEvent){
                let progresso = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                if(progresso === 100){
                    progresso = null;
                }
                
                // altera o progresso do arquivo
                alterarArquivoFoto({...arquivoFoto, progresso: progresso});
                toast.update(toastId, {
                    render: <>
                        <div className="toast-header">
                            <strong className="mr-auto">
                                {progresso === null ?
                                    <>Finalizado</>
                                    :
                                    <>Fazendo upload da sua foto!</>
                                }
                            </strong>
                            <button 
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                            </button>
                        </div>
                        <div className="toast-body">
                            {(progresso === null) ?
                                <>
                                    <FontAwesomeIcon className='mr-2' icon={faCheck} />
                                    <span>Finalizado!!</span>
                                </>
                                :
                                <ProgressBar animated now={progresso} label={`${progresso}%`} />
                            }
                        </div>
                    </>
                });
            }

            // faz a requisição
            let { data } = await axios.post(`/funcionario/foto`, formData, {
                onUploadProgress: progressoFoto,
                headers: {'Content-type': 'multipart/form-data'},
            });
            
            // para não fazer upload novamente da foto
            alterarArquivoFoto({...arquivoFoto, arquivo: null});

            // atualiza foto de perfil do usuário
            dispatch({
                type: 'loginReducer/ATUALIZAR',
                dadosUsuario: { foto: data.foto }
            });
            
        }catch({response}){
            if(response){
                if(response.status === 400){
                    
                    toast(<>
                        <div
                            style={{background: '#ff6271'}}
                        >
                            <div className="toast-header">
                                <strong className="mr-auto">Não Conseguimos salvar sua foto :(</strong>
                                <button 
                                    className="ml-2 mb-1 close btn-outline-light outline-0"
                                >
                                </button>
                            </div>
                            <div className="toast-body text-light">
                                Não foi possível salvar sua foto, tente novamente!
                            </div>
                        </div>
                    </>);
                }
            }
        }finally{
            
            // quando finalizar, espera um tempo ainda para fechar
            toast.update(toastId, {autoClose: 1000});
        }

        return true;
    }
      
    if(carregando){
        return <div>Carregando...</div>
    }
    
    return <>
        <FichaComponent className={[''].join(' ')}>
            <Row className='justify-content-md-center'>
                {
                    isBrowser &&
                    <>
                        <Col md={3} >
                            <Row>
                                <Col className={['mb-3'].join(' ')}>
                                    <Foto 
                                        leitura={leitura}
                                        imagem={dados.foto}
                                        alterou={arquivo => {
                                            alterarArquivoFoto({
                                                ...arquivoFoto, 
                                                arquivo: arquivo
                                            });
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </>
                }

                <Col md={9}>
                    <Formulario
                        dados={dados}
                        alterarDados={dadosNovos => {
                            Object.assign(dados, dadosNovos);
                            alterarDados({...dados});
                        }}
                        funcaoSalvar={salvar}
                        salvando={salvando}
                        modo={modo}
                        erros={erros}
                        alterarErros={alterarErros}
                        leitura={leitura}
                    />

                    {dados.id === loginReducer.dadosUsuario.id &&
                        <FormularioTrello 
                            dados={dadosTrello}
                            alterarDados={alterarDadosTrello}
                            erros={erros}
                            alterarErros={alterarErros}
                        />
                    }


                    { !leitura &&
                        <Row>
                            <Col>
                                <SalvarFormulario 
                                    salvando={salvando}
                                    modo={modo}
                                    cancelar={() => {
                                        history.push(`/painel`);
                                    }}
                                    onClick={() => {
                                        salvar(dados);
                                    }}
                                />
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </FichaComponent>
    </>
}


function SalvarFormulario({
    salvando,
    cancelar,
    modo,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button 
            variant="success" 
            disabled={salvando} 
            onClick={onClick}
        >
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <>
                    <FontAwesomeIcon className="icone" icon={["fas", 'check']} />
                <span>{modo === 'edicao' ? 'Salvar' : 'Cadastrar'}</span>
                </>
            }
        </Button>
    </Form.Group>
}
