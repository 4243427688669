import React, { useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import { useSelector  } from 'react-redux';

// componentes
import Login from './Login';
import Painel from './Painel';

function Sistema({

}){

    // reducers
    let loginReducer = useSelector(state => state.loginReducer);
    
    console.log(loginReducer);

    return <>
        <Router>
            <Switch>
                <Route path={`/login`}>
                    { loginReducer.logado ? <Redirect to="/painel" /> : <Login /> }
                </Route>
                <Route path={`/painel`}>
                    { loginReducer.logado ? <Painel /> : <Redirect to="/login" /> }
                </Route>
                <Route path={`/`}>
                    <Redirect to='/painel' />
                </Route>
            </Switch>
        </Router>
    </>
}

export default Sistema;