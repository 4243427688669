export default function Registro({
    indice,
    dados,
    dadosEmpresa
}){

    return <> 

        <tr className={['registro'].join(' ')}>

            <td className={'text-center font-weight-bold indice'}>
                {indice}
            </td>

            <td className="descricao">
                <div className="d-flex align-items-center" style={{gap: 3}}>
                    {dadosEmpresa.razaoSocial} <small>({dadosEmpresa.codigo})</small>
                </div>
                <div className="totais">
                    <small className="geral">
                        <b>Geral: </b> {dados.geral}
                    </small>
                    <small className="v1">
                        <b>V1: </b> {dados.v1}
                    </small>
                    <small className="v2">
                        <b>V2: </b> {dados.v2}
                    </small>
                    <small className="v3">
                        <b>V3: </b> {dados.v3}
                    </small>
                </div>
            </td>
        </tr>
    </>
}