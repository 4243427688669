
import { useRef, useState } from 'react';
import { Button, Col, Form, Row, Popover } from 'react-bootstrap';
import Select from 'react-select';

import { ModalFiltrosComponent, PopoverFiltrosComponent } from './components';

export default function ModalFiltros({
    elemento,
    mostrar,
    alterarMostrar,
    parametros,
    alterarParametros
}){

    // estados
    const [ filtros, alterarFiltros ] = useState({
        pesquisa: null,
        tipo: null,
        habilitado: null
    });

    // prepara filtro de tipo
    const tipo = [
        {label: 'GeoGrid / Mapa', value: 'geogridMapa'},
        {label: 'Pesquisa de endereço', value: 'pesquisaEndereco'}
    ];

    // prepara filtro de público/privado
    const habilitado = [
        {label: 'Habilitado', value: 'S'},
        {label: 'Desabilitado', value: 'N'}
    ];

    // função para limpar os filtros
    function limparFiltros(){

        // prepara
        let filtrosAlterar = {...filtros};

        // percorre filtros
        Object.keys(filtros).map((filtro) => {

            // limpa
            filtrosAlterar[filtro] = null;

        });

        // altera
        alterarFiltros(filtrosAlterar);
        // alterarParametros({...parametros, ...filtrosAlterar});

    }

    // função para cancelar (sair sem aplicar)
    function cancelar(){

        // fecha
        alterarMostrar(false);

        // altera os filtros para o estado inicial
        let filtrosAlterar = {...filtros};

        // percorre
        Object.keys(filtros).map((filtro) => {

            // altera
            filtrosAlterar[filtro] = parametros[filtro] ?? null;
        });

        // altera os filtros
        alterarFiltros(filtrosAlterar);

    }

    return <ModalFiltrosComponent
        show={mostrar}
        target={elemento}
        placement="bottom"
        rootClose
        onHide={() => {
            cancelar();
        }}
    >
        <PopoverFiltrosComponent
            size={'sm'}
            title={'Filtros'}
        >
            <Popover.Title>
                Filtros
                <Button
                    size="sm"
                    name="botao-limpar"
                    onClick={() => {

                        // limpa filtros
                        limparFiltros();
                    }}
                >
                    Limpar
                </Button>
            </Popover.Title>
            <Popover.Content>
                <Row>
                    <Col>
                        <Form.Label>Pesquisa</Form.Label>
                        <Form.Control 
                            value={filtros.pesquisa || ''}
                            onChange={e => {

                                // altera
                                alterarFiltros({...filtros, pesquisa: e.target.value});
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>Tipo</Form.Label>
                        <Select
                            className="select-ficha"
                            size="sm"
                            options={tipo}
                            value={tipo.filter((s) => filtros.tipo && filtros.tipo.includes(s.value))}
                            isMulti={true}
                            placeholder='Sem filtro'
                            onChange={(opcoes) => {
                                
                                // altera os filtros selecionados
                                alterarFiltros({
                                    ...filtros,
                                    tipo: (
                                        opcoes ? opcoes.map((s) => s.value) : null
                                    )
                                });
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>Habilitado</Form.Label>
                        <Select
                            className="select-ficha"
                            size="sm"
                            options={habilitado}
                            value={habilitado.find((p) => filtros.habilitado && filtros.habilitado.includes(p.value))}
                            placeholder='Sem filtro'
                            onChange={(habilitado) => {
                                
                                // altera os filtros selecionados
                                alterarFiltros({
                                    ...filtros,
                                    habilitado: habilitado ? [habilitado.value] : null
                                });
                            }}
                            isClearable
                        />
                    </Col>
                </Row>
                
                <div className='footer'>
                    <Button
                        size="sm"
                        variant={'light'}
                        onClick={() => {

                            // cancela
                            cancelar();
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        size="sm"
                        variant={'success'}
                        onClick={() => {

                            // altera os parâmetros
                            alterarParametros({...parametros, ...filtros, pagina: 1});
                        }}
                    >
                        Aplicar
                    </Button>
                </div>
            </Popover.Content>
        </PopoverFiltrosComponent>
    </ModalFiltrosComponent>
    
}