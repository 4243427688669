import moment from "moment";
import { Badge, Card } from "react-bootstrap";
import { isBrowser, isMobile } from "react-device-detect";
import { faFileAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import BotaoTabela from "../../../../Componentes/BotaoTabela";

export default function Registro({
    indice,
    dataAnterior,
    dados,
    dadosUsuario,
    dadosEmpresa,
    clicou
}){

    return <>
        {
            (
                !dataAnterior ||
                moment(dataAnterior).format('DD/MM/YYYY') !== moment(dados.dataCadastro).format('DD/MM/YYYY')
            ) && <tr className="registro registro-divisor-data">
                <td className="divisor-data estreito">{moment(dados.dataCadastro).format('DD/MM/YYYY')}</td>
            </tr> 
        }
        <tr
            className={[
                'registro',
                `tipo-${dados.tipo}`
            ].join(' ')}
            style={{
                cursor: clicou ? 'pointer' : 'auto'
            }}
            onClick={() => {
                clicou();
            }}
        >
            <td className={'text-center estreito hora'}>
                {moment(dados.dataCadastro).format('HH:mm:ss')}
            </td>

            <td className="descricao">
                <div className="d-flex align-items-center" style={{gap: 3}}>
                    {
                        dadosUsuario ? <>
                            {dadosUsuario.nome} <small>({dadosUsuario.usuario} - {dadosUsuario.permissao})</small>
                        </> : <>
                            <i>Usuário não encontrado</i>
                        </>
                    }
                </div>
                <div className="d-flex align-items-center" style={{gap: 3}}>
                    <small>{
                        dadosEmpresa ? <>
                            {dadosEmpresa.razaoSocial} <small>({dadosEmpresa.codigo})</small>
                        </> : <>
                            <i>Sem empresa</i>
                        </>
                    }</small>
                </div>
            </td>
            {
                clicou && <td className="estreito">
                    <BotaoTabela
                        onClick={clicou}
                        icone={faFileAlt}
                    >
                        <span>Abrir</span>
                    </BotaoTabela>
                </td>
            }
        </tr>
    </>
}