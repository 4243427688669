import React from 'react';
import styles from './index.module.scss';

// componentes
import { Card, Row, Col, Badge } from 'react-bootstrap';
import { BrowserView, MobileView, isMobile, isBrowser } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// componente
export default function CartaoDados({
    cor,
    descricao,
    quantidade,
    quantidadeMes=null,
    icone,
    className='',
    foco=false,
    onClick
}){

    return <Card 
        className={[
            'border-0',
            styles.card,
            foco ? 'shadow-sm' : '',
            foco ? styles.foco : '',
            className
        ].join(' ')}
        onClick={onClick}
    >
        <Card.Body className={'p-3'}>
            <div 
                className={[
                    'd-flex align-items-center font-weight-bold', 
                    cor
                ].join(' ')}
            >
                <>
                    <BrowserView>
                        <FontAwesomeIcon className="icone mr-2 fa-fw fa-2x" icon={["fas", icone]} />
                    </BrowserView>
                    <div className={'flex-grow-1'}>
                        <p className={'m-0'}>
                            {isMobile && <FontAwesomeIcon className="icone mr-2 fa-fw fa-xs" icon={["fas", icone]} /> }
                            {descricao}
                        </p>
                        <p className='m-0'>
                            {quantidade} {(quantidadeMes && quantidadeMes !== quantidade && parseInt(quantidadeMes) > 0) && <small title={'Entrou esse mês'}>({quantidadeMes})</small>}
                        </p>
                    </div>
                </>

            </div>
        </Card.Body>
    </Card>

}