import { useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { Card, Tab, Tabs } from "react-bootstrap";

import { IntegracaoSistemasComponent } from "./components";
import ListaGoogleChaves from "./ListaGoogleChaves";
import RelatoriosEnderecosPesquisas from "./RelatoriosEnderecosPesquisas";
import ListaEnderecosPesquisas from "./ListaEnderecosPesquisas";
import ListaConsumo from "./ListaConsumo";

export default function Enderecos(){

    // pega parametros
    const params = useParams();
    const tabInicial = params.tab ?? null;

    // prepara o histórico da página
    const history = useHistory();
    const {
        path,
        url
    } = useRouteMatch();

    // estados
    const [ tab, alterarTab ] = useState(tabInicial ?? 'pesquisas');

    useEffect(() => {

        // verifica se alterou a tab
        if((tabInicial ?? 'pesquisas') !== tab){

            // define
            alterarTab(tabInicial ?? 'pesquisas');
        }
    }, [tabInicial]);

    // retorna
    return <IntegracaoSistemasComponent>
        <Card.Header>
            <div className={'d-flex '}>
                <Card.Title className={'flex-grow-1 d-flex align-items-center mb-0'}>
                    Pesquisas de endereço
                </Card.Title>
            </div>
        </Card.Header>
        <Card.Body className={'py-1'}>
            <RelatoriosEnderecosPesquisas />
            <Tabs
                id="empresas-integracao-sistemas-tabs"
                className={['tabs', 'mb-2'].join(' ')}
                activeKey={tab}
                onSelect={(t) => {

                    // altera
                    alterarTab(t);

                    // prepara
                    let caminhoTab = `${url}/${t}`;

                    // verifica se possui tab inicial
                    if(tabInicial){

                        // altera para remover a última tab
                        caminhoTab = `./${t}`;
                    }

                    // adiciona
                    history.push(caminhoTab);
                }}
            >
                <Tab eventKey="pesquisas" title="Pesquisas">
                    <ListaEnderecosPesquisas />
                </Tab>
                <Tab eventKey="googleChaves" title="Chaves">
                    <ListaGoogleChaves />
                </Tab>
                <Tab eventKey="consumo" title="Consumo">
                    <ListaConsumo />
                </Tab>           
            </Tabs>
        </Card.Body>
    </IntegracaoSistemasComponent>;
}