import React from 'react';
import * as R from 'ramda';

// componentes
import { ParametroTesteComponent } from './components';
import { DebounceInput } from 'react-debounce-input';
import { Button, Form, OverlayTrigger, Tooltip, Row, Col, Card, Badge } from 'react-bootstrap';
import ParametrosTesteLista from './../ParametrosTesteLista';
import DescricaoJson from './DescricaoJson';

// teste
export default function ParametroTeste({
    registro,
    alterou,
    raiz=false
}){

    return <ParametroTesteComponent raiz={raiz} className='parametro-teste'>

        <div className=" align-items-center mb-2 dados">
            <div className="">
                <div className="d-flex align-items-center">
                <Form.Check 
                    custom
                    label=""
                    // type="switch"
                    id={`switch-parametro-teste-${registro.dados.id || registro.tempId}`}
                    size="sm"
                    checked={registro.teste || registro.dados.obrigatorio === 'S'}
                    disabled={registro.dados.obrigatorio === 'S' ? true : false}
                    onChange={e => {
                        registro.teste = e.target.checked;
                        alterou();
                    }}
                />
                    <small className="mr-2"><Badge variant="secondary">{registro.dados.tipo}</Badge></small>
                    <small>{registro.dados.parametro}</small>
                </div>
            </div>
            {(registro.dados.tipo !== 'objeto' && registro.dados.tipo !== 'objetoArray') && 
                <div className="">
                    <DebounceInput 
                        placeholder={registro.dados.valor === null ? 'null' : ''}
                        debounceTimeout={300}
                        element={Form.Control}
                        value={registro.dados.valor || ''}
                        onChange={(e) =>{
                            registro.dados.valor = e.target.value;
                            alterou();
                        }}
                        className='form-control-sm'
                    />
                </div>
            }

        </div>


        {registro.dados.tipo === 'objetoArray' ?
            <>
                {registro.filhosArray.map((filhoArray, pos) =>
                    <ParametrosTesteLista 
                        key={pos}
                        filhos={filhoArray}
                        alterou={alterou}
                    />
                )}
                <button onClick={() => {
                    registro.filhosArray.push(R.clone(registro.copia));
                    alterou();
                }}>+</button>
            </>
            :
                <>
                {registro.filhos.length > 0 &&
                    <ParametrosTesteLista 
                        filhos={registro.filhos}
                        alterou={alterou}
                    />
                }
            </>
        }
    
    </ParametroTesteComponent>

}