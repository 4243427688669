import React, { useState, useEffect } from 'react';

// componentes
import { Card, Image } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import imagemIcone from './imagem.png';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FotoComponente } from './styles';

// componente
export default function Foto({
    leitura=false,
    imagem = null,
    alterou
}){

    // imagem default
    let [imagemTemporaria, alterarImagemTemporaria] = useState(null);
    
    // referencias
    let inputRef = React.createRef();

    // inicializa
    useEffect(() => {
        if(imagem !== null){
            alterarImagemTemporaria(`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/fotos/${imagem}`);
        }
    }, []);

    return <>
        <FotoComponente>
            <Card
                className='border-0 shadow-sm '
            >
                <Card.Body>
                    <div
                        className={'perfil'}
                    >
                        <div
                            className={'foto bg-light rounded-circle mb-3'}
                        >
                            { imagemTemporaria ?
                                // <img src={imagemTemporaria} />
                                <Image src={imagemTemporaria} roundedCircle fluid  />
                                :
                                <div className='sem-imagem'>
                                    <FontAwesomeIcon icon={faUserCircle} size='7x' />
                                </div>
                            }
                            { !leitura &&
                                <FontAwesomeIcon 
                                    icon={faPencilAlt} 
                                    className={'editar'} 
                                    onClick={() => {
                                        inputRef.current.click();
                                    }}
                                />
                            }
                        </div>
                        
                    </div>
                </Card.Body>
            </Card>
        </FotoComponente>
        <input hidden type="file" ref={inputRef} onChange={() => {

            if(inputRef.current.files.length > 0){
                // função para carregar imagem
                let reader = new FileReader();

                // ao carregar imagem
                reader.onload = (e) => {
                    
                    // alterouArquivo(inputRef.current.files[0]);
                    alterou(inputRef.current.files[0]);
                    alterarImagemTemporaria(e.target.result);
                };

                // pega a imagem
                reader.readAsDataURL(inputRef.current.files[0]);
            }
        }} />
    </>

}