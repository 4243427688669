import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

// componentes
import Sistema from './Sistema';

function App() {
    return <>
        <ToastContainer 
            toastClassName='toast fade show p-0'
            closeOnClick={false}
            hideProgressBar={true}
            closeButton={false}
        />
        <Sistema />
    </>
}

export default App;
