import React from 'react';
import styles from './index.module.scss';

// componente options
function OptionSelect({data, ...props}){

    // propriedades importantes
    let { innerProps, innerRef, isFocused } = props;
    // console.log(data);
    // retorna componente
    return <div tabIndex='-1' ref={innerRef} {...innerProps} className={[styles.option, isFocused && styles.focado].join(' ')}>
        <div className={styles.dadosUsuario}>
            {/* { data.dados.avatar &&
                <img src={`${data.dados.avatar}/50.png`} className='rounded' />
            } */}
            <div>
                {/* <p><b>{data.id}</b> - {data.nome}</p> */}
                <p><b>{data.dados.descricao}</b></p>
                {/* <p>{data.dados.usuario}</p> */}
            </div>
        </div>
    </div>
}

// opção
export default OptionSelect;