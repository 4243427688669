import React from 'react';
import styles from './index.module.scss';

// componentes
import { Card, Form } from 'react-bootstrap';
import OpcaoBoleana from './../../OpcaoBoleana';


import { ClientesIcone, UsuariosIcone, FuncionariosIcone, GraficoIcone, TrelloIcone, EmailIcone } from './../../../../../../icones/svg';



// permissões funcionários
export default function Funcionarios({
    value,
    chaveAtualizando,
    onChange
}){


    return <>
        <Card
            className='border-0 mb-3'
        >
            <Card.Body
            >
                <div className="font-weight-bold">
                    <FuncionariosIcone width={35} height={35} /> <span>Funcionarios</span>
                </div>
                <OpcaoBoleana 
                    descricao="Acessar consulta de funcionários"
                    chave="acessoFuncionarios"
                />
                <OpcaoBoleana 
                    descricao="Acessar relatórios"
                    chave="acessoRelatorios"
                />
                
            </Card.Body>
        </Card>
    </>
}