import * as React from "react";

const SvgClientesIcone = (props) => {
  props = {
    width: 15.5,
    height: 15.5,
    ...props,
  };
  return (
    <svg viewBox="0 0 128 128" {...props}>
      <style>
        {
          ".clientes-icone_svg__st88{fill:#98fb98}.clientes-icone_svg__st89{fill:#266b42}"
        }
      </style>
      <g id="clientes-icone_svg__clientes">
        <linearGradient
          id="clientes-icone_svg__SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1={19.5}
          y1={71.5}
          x2={45.5}
          y2={71.5}
        >
          <stop offset={0} stopColor="#277549" />
          <stop offset={0.5} stopColor="#2e8b57" />
          <stop offset={1} stopColor="#277549" />
        </linearGradient>
        <path
          d="M43.5 42h-22c-1.1 0-2 .9-2 2v57h26V44c0-1.1-.9-2-2-2z"
          fill="url(#clientes-icone_svg__SVGID_1_)"
        />
        <linearGradient
          id="clientes-icone_svg__SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1={83}
          y1={71.5}
          x2={109}
          y2={71.5}
        >
          <stop offset={0} stopColor="#277549" />
          <stop offset={0.5} stopColor="#2e8b57" />
          <stop offset={1} stopColor="#277549" />
        </linearGradient>
        <path
          d="M107 42H85c-1.1 0-2 .9-2 2v57h26V44c0-1.1-.9-2-2-2z"
          fill="url(#clientes-icone_svg__SVGID_2_)"
        />
        <linearGradient
          id="clientes-icone_svg__SVGID_3_"
          gradientUnits="userSpaceOnUse"
          x1={44}
          y1={60.5}
          x2={84}
          y2={60.5}
        >
          <stop offset={0} stopColor="#32965f" />
          <stop offset={0.5} stopColor="#3cb371" />
          <stop offset={1} stopColor="#32965f" />
        </linearGradient>
        <path
          d="M81 20H47c-1.7 0-3 1.3-3 3v78h40V23c0-1.7-1.3-3-3-3z"
          fill="url(#clientes-icone_svg__SVGID_3_)"
        />
        <path
          className="clientes-icone_svg__st88"
          d="M59.3 39h-7c-.6 0-1-.4-1-1v-7c0-.6.4-1 1-1h7c.6 0 1 .4 1 1v7c0 .6-.5 1-1 1zM75.8 39h-7c-.6 0-1-.4-1-1v-7c0-.6.4-1 1-1h7c.6 0 1 .4 1 1v7c0 .6-.5 1-1 1zM59.3 54h-7c-.6 0-1-.4-1-1v-7c0-.6.4-1 1-1h7c.6 0 1 .4 1 1v7c0 .6-.5 1-1 1zM36 59h-7c-.6 0-1-.4-1-1v-7c0-.6.4-1 1-1h7c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1zM36 74h-7c-.6 0-1-.4-1-1v-7c0-.6.4-1 1-1h7c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1zM100 58h-7c-.6 0-1-.4-1-1v-7c0-.6.4-1 1-1h7c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1zM100 73h-7c-.6 0-1-.4-1-1v-7c0-.6.4-1 1-1h7c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1zM75.8 54h-7c-.6 0-1-.4-1-1v-7c0-.6.4-1 1-1h7c.6 0 1 .4 1 1v7c0 .6-.5 1-1 1zM59.3 69h-7c-.6 0-1-.4-1-1v-7c0-.6.4-1 1-1h7c.6 0 1 .4 1 1v7c0 .6-.5 1-1 1zM75.8 69h-7c-.6 0-1-.4-1-1v-7c0-.6.4-1 1-1h7c.6 0 1 .4 1 1v7c0 .6-.5 1-1 1z"
        />
        <path
          className="clientes-icone_svg__st89"
          d="M78 79H50c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1h28c.6 0 1-.4 1-1v-2c0-.6-.4-1-1-1z"
        />
        <path className="clientes-icone_svg__st88" d="M52.8 83h22.5v18H52.8z" />
        <path className="clientes-icone_svg__st89" d="M63 83h2v18h-2z" />
        <path fill="#ccc" d="M18.5 101h91v7h-91z" />
      </g>
    </svg>
  );
};

export default SvgClientesIcone;
