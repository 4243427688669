import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import ModalCadastrar from './ModalCadastrar';

// componentes
import { Button, Row, Col, Card } from 'react-bootstrap';
import { CardsComponent } from './components';
import { BrowserView, MobileView, isMobile, isBrowser } from "react-device-detect";
import CardTrello from './CardTrello';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

// cards do cliente
export default function Cards({
    idRazaoSocial
}){

    // pega os dados do usuario
    const { loginReducer } = useSelector(state => state);
    const [carregando, alterarCarregando] = useState();
    const [consulta, alterarConsulta] = useState([]);
    const [novo, alterarNovo] = useState(null);
    const [parametros, alterarParametros] = useState({
        idCliente: idRazaoSocial,
        fechado: ['S', 'N']
    });

    // salva card para o cliente
    async function fazerConsulta(){
        alterarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/trelloQuadrosCards`, { params: parametros });
            alterarConsulta(data.registros);

        }catch({response}){}
        finally{
            alterarCarregando(false);
        }
    }

    // inicializa
    useEffect(() => {
        fazerConsulta();
    }, []);


    // retorna registro
    let retornaRegistros = () => {

        return consulta.map(registro => 
            <CardTrello 
                key={registro.dados.id}
                dados={registro.dados}
                membros={registro.membros}
                funcionarios={registro.funcionarios}
                lista={registro.lista}
                etiquetas={registro.etiquetas}
                responsavel={registro.responsavel}
            />
        )
    }

    // se carregando
    if(carregando){
        return <div className="p-5 text-center">
            <FontAwesomeIcon icon={faSpinner} pulse /> Aguarde!
        </div>
    }

    return <>

        { novo && 
            <ModalCadastrar 
                idRazaoSocial={novo}
                fechou={() => {
                    alterarNovo(null);
                }}
                salvou={() => {
                    fazerConsulta();
                }}
            />
        }

        <p className="text-right">
            <Button
                size="sm"
                variant="warning"
                onClick={e => {
                    
                }}
                className="mr-2"
            >
                Vincular
            </Button>
            
            {loginReducer.trello &&
                <Button
                    size="sm"
                    variant="info"
                    onClick={e => {
                        alterarNovo(idRazaoSocial)
                    }}
                >
                    Novo
                </Button>
            }
        </p>

        <CardsComponent>

            <div className='container-cards'>
                <div>
                    { retornaRegistros() }
                </div>
            </div>

        </CardsComponent>


    </>

};