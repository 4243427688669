import styled from 'styled-components';
import { Card } from 'react-bootstrap';

import { ListaEmpresasEstatisticasComponent } from './../components';

export const ListaEmpresasEstatisticasBaseComponent = styled(ListaEmpresasEstatisticasComponent).attrs({
    className: `painel-consulta shadow-sm`
})`
    padding: 0.5em 1em;
    margin-bottom: 0.5em;
    
    .tabela{
        
        .registro{
            font-size: 0.9em;
            border-radius: 0.3em;

            &:first-child{
                margin-top: 0;
            }
            
            td, th{
                border-left: 1px solid #f3f3f3;
                background: #fdfdfd;
                border: 1px solid #f3f3f3;
                padding: 0.25em 0.5em;

                &:first-child{
                    border-top-left-radius: 0.3em;
                    border-bottom-left-radius: 0.3em;
                }
                
                &:last-child{
                    border-top-right-radius: 0.3em;
                    border-bottom-right-radius: 0.3em;
                }
                
                &.indice{
                    color: #525252;
                    font-size: 0.9em;
                    background: #f7fbff;
                    border-color: #e4edf7;
                    border-radius: 0.3em;
                    min-width: 25px;
                }

                .totais{
                    display: flex;
                    flex-wrap: wrap;
                    
                    small{
                        margin-right: 5px;
                        white-space: nowrap;
                    }
                }
            }
        }
    }

`;