import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import styles from './index.module.scss';
import axios from 'axios';
import { toast } from 'react-toastify';

// componentes
import Select from 'react-select';
import useIsMounted from 'ismounted';
import { Modal, Container, Card, Row, Col, Form, Button, InputGroup, Spinner, Alert, OverlayTrigger, Tooltip, Badge} from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faRandom } from '@fortawesome/free-solid-svg-icons';

import { intersectObject, copiaCampo } from './../../../../Funcoes';
import { FichaComponent } from './components';

// contexto
import { PainelContexto } from './../../../PainelContexto';

// faz o cadastro
export default function ModalEditar({
    
}){

    // dados
    const isMounted = useIsMounted();
    const { path, url } = useRouteMatch();
    const painelContextoDados = useContext(PainelContexto);
    const history = useHistory();

    // parametros da url
    const {
        id = null
    } = useParams();

    // prepara
    const dadosPadrao = {
        id: null,
        nome: null,
        tipo: null,
        status: null,
        publico: null
    }
    
    // estados
    const [ dadosIniciais, alterarDadosIniciais ] = useState({});
    const [ dados, alterarDados ] = useState({...dadosPadrao});
    const [ mostrar, alterarMostrar ] = useState(true);
    const [ salvando, alterarSalvando ] = useState(false);
    const [ carregando, alterarCarregando ] = useState(false);
    const [ erros, alterarErros ] = useState({});
    const [ tiposIntegracao, alterarTiposIntegracao ] = useState([
        {value: 'erp', label: 'ERP'},
        {value: 'chatbot', label: 'ChatBot'},
        {value: 'ordemServico', label: 'Ordem de serviço'}
    ]);

    // prepara array com permissões
    const statusIntegracao = [
        {value: 'integrado', label: 'Integrado (concluído)'},
        {value: 'processo', label: 'Em processo'},
        {value: 'aguardo', label: 'Aguardando'}
    ];

    const camposRef = {
        usuario: React.useRef(),
        senha: React.useRef(),
        nome: React.useRef(),
        email: React.useRef(),
        tokenIntegrador: React.useRef()
    }

    // inicializa
    useEffect(() => {
        
        // consulta os tipos de integração
        consultarTiposIntegracao();

        // verifica se enviado o id
        if(id){

            // consulta
            consultarId();
        }
    }, []);

    // consulta dados do usuário
    async function consultarId(){

        // cria o carregamento
        alterarCarregando(true);

        try{
            
            // faz a requisição
            let { data } = await axios.get(`/integracaoSistemas/${id}`);
            
            // define os dados
            alterarDadosIniciais({...data.dados});
            alterarDados({...data.dados});

        }catch(e){
            console.log('pegou o erro')
        }finally{
            if (isMounted.current) {
                alterarCarregando(false);
            }
        }

    }

    // função para consultar os tipos de integração
    async function consultarTiposIntegracao(){

        // cria o carregamento
        alterarCarregando(true);

        try{
            
            // faz a requisição
            let { data } = await axios.get(`/integracaoSistemas/tipos`);
                
            // altera
            alterarTiposIntegracao(data.registros.map((registro) => {

                // retorna
                return {
                    value: registro.dados.id,
                    label: registro.dados.descricao
                }
            }));

        }catch(e){
            console.log('pegou o erro')
        }finally{
            if (isMounted.current) {
                alterarCarregando(false);
            }
        }

    }

    // finaliza
    async function salvar(){

        // salva registro
        alterarSalvando(true);
        alterarErros({});

        // faz a requisição
        try{

            // pega dados
            let dadosSalvar = intersectObject(dadosPadrao, dados);

            // faz a requisição
            let { data } = await axios.post(`/integracaoSistemas`, {
                dados: dadosSalvar
            });

            // mensagem de cadastro realizado com sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Integração editada!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    {dadosIniciais.nome}
                </div>
            </>);

            // // vai para a consulta de sms's enviados (por enquanto volta para a consulta de clientes)
            // history.push('/painel/caixa-saida');
            
            // altera
            alterarSalvando(false);
            alterarMostrar(false);
            
            // monta a url para retroceder
            let urlBack = url.split("/integracaoSistemas").map((parte, i) => i > 0 ? '/integracaoSistemas' : parte).join('');
            
            // volta e dá refresh pra atualizar a listagem
            history.push(urlBack, {refresh: true});

        }catch({response}){

            // define erros retornados
            alterarErros(response.data);

        }finally{
            if (isMounted.current) {
                alterarSalvando(false);
            }
        }
    }

    // return <>
    //     Aqui retorna
    // </>
    return <Modal 
        show={mostrar}
        centered
        onExited={() => {

            // monta a url para retroceder
            let urlBack = url.split("/integracaoSistemas").map((parte, i) => i > 0 ? '/integracaoSistemas' : parte).join('');
            
            // volta e dá refresh pra atualizar a listagem
            history.push(urlBack, {
                aPartirDe: 'editarIntegracaoSistema'
            });
            
            // fecha
            alterarMostrar(false);
        }}
        onHide={() => {

            // fecha
            alterarMostrar(false);
        }}
        size={'md'}
    >
        <Modal.Header className='border-0'>
            <Modal.Title 
                as='h5' 
                className={[
                    'text-center w-100',
                    'text-success'
                ].join(' ')}
            >
                {dadosIniciais.nome && <>{dadosIniciais.nome} ({dados.id})</>}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'pt-0'}>
            {
                carregando ?
                <Col align="center">
                    <Spinner animation="border" />
                </Col>
                : <FichaComponent>
                    <Container>
                        <Form.Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control 
                                        ref={camposRef.nome}
                                        value={dados.nome || ''}
                                        onChange={e => {
                                            alterarDados({...dados, nome: e.target.value});
                                        }}
                                    />

                                    {(erros.nome) && 
                                        <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.nome}</p></Alert>
                                    }

                                </Form.Group>
                            </Col>
                        </Form.Row>
                        
                        <Form.Row>
                            <Col lg={6}>
                                <Form.Label>Tipo</Form.Label>
                                <CreatableSelect
                                    className="select-ficha"
                                    options={tiposIntegracao}
                                    value={tiposIntegracao.filter((e) => dados.tipo === e.value)[0]}
                                    isMulti={false}
                                    placeholder='Tipo'
                                    onChange={(e) => {
                                        // altera os filtros selecionados
                                        alterarDados({...dados, tipo: e.value});
                                    }}
                                />
                                {(erros.tipo) && 
                                    <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.tipo}</p></Alert>
                                }
                            </Col>
                            <Col lg={6}>
                                <Form.Label>Status</Form.Label>
                                <Select
                                    className="select-ficha"
                                    options={statusIntegracao}
                                    value={statusIntegracao.filter((e) => dados.status === e.value)[0]}
                                    isMulti={false}
                                    placeholder='Status'
                                    onChange={(e) => {
                                        // altera os filtros selecionados
                                        alterarDados({...dados, status: e.value});
                                    }}
                                />
                                {(erros.status) && 
                                    <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.status}</p></Alert>
                                }
                            </Col>
                        </Form.Row>

                        <Form.Row className={['mt-2']}>
                            <Col>
                                <Form.Group>
                                    <Form.Check 
                                        type="switch"
                                        id="custom-switch-publico"
                                        label={<div className='d-flex align-items-center' style={{gap: '0.5em'}}>
                                            Público
                                            <OverlayTrigger
                                                placement='right'
                                                overlay={
                                                    <Tooltip>
                                                        As integrações públicas ficam disponíveis para seleção nos pedidos de integração.
                                                    </Tooltip>
                                                }
                                            >
                                                <FontAwesomeIcon color="#535353" icon={faInfoCircle}/>
                                            </OverlayTrigger>
                                        </div>}
                                        checked={dados.publico === 'S'}
                                        onChange={e => {

                                            // pega o novo valor
                                            let publico = e.target.checked ? 'S' : 'N';
        
                                            // altera dados
                                            alterarDados({...dados, publico});
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </Container>
                </FichaComponent>
            }
        </Modal.Body>
        <Modal.Footer className='border-0'>
            <Button
                variant='danger'
                onClick={() => {alterarMostrar(false)}}
                disabled={salvando}
            >
                <span>Fechar</span>
            </Button>
            <Button
                onClick={salvar}
                variant='success'
                disabled={salvando}
            >
                {salvando ? 
                    <>
                        <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                        <span>Salvando</span>
                    </> : 
                    <span>Salvar</span>
                }
            </Button>
        </Modal.Footer>
    </Modal>
}   