
import { useRef, useState } from 'react';
import { Button, Col, Form, Row, Popover } from 'react-bootstrap';
import Select from 'react-select';

import { ModalFiltrosComponent, PopoverFiltrosComponent } from './components';

import InputDataCombo from "../../../../Componentes/InputDataCombo";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { intersectObject } from '../../../../Funcoes';
import ComboGruposChaveGoogleMultiplo from '../../../../Componentes/ComboGruposChaveGoogleMultiplo';

export default function ModalFiltros({
    elemento,
    mostrar,
    alterarMostrar,
    parametros,
    alterarParametros
}){

    // estados
    const filtrosPadrao = {
        pesquisa: null,
        dataInicio: null,
        dataFinal: null,
        horaInicio: null,
        horaFinal: null,
        idsGruposChaveGoogle: [],
        empresaUsarTokenGoogleCliente: []
    };
    const [ filtros, alterarFiltros ] = useState(intersectObject({...filtrosPadrao}, parametros ?? {}));
    const refs = {
        dataInicioCol: useRef(null),
        dataFinalCol: useRef(null)
    };

    // função para limpar os filtros
    function limparFiltros(){

        // prepara
        let filtrosAlterar = {...filtros};

        // percorre filtros
        Object.keys(filtros).map((filtro) => {

            // limpa
            filtrosAlterar[filtro] = filtrosPadrao[filtro] ?? null;

        });

        // altera
        alterarFiltros(filtrosAlterar);
        // alterarParametros({...parametros, ...filtrosAlterar});

    }

    // função para cancelar (sair sem aplicar)
    function cancelar(){

        // fecha
        alterarMostrar(false);

        // altera os filtros para o estado inicial
        let filtrosAlterar = {...filtros};

        // percorre
        Object.keys(filtros).map((filtro) => {

            // altera
            filtrosAlterar[filtro] = parametros[filtro] ?? filtrosPadrao[filtro] ?? null;
        });

        // altera os filtros
        alterarFiltros(filtrosAlterar);

    }

    return <ModalFiltrosComponent
        show={mostrar}
        target={elemento}
        placement="bottom"
        rootClose
        onHide={() => {
            cancelar();
        }}
    >
        <PopoverFiltrosComponent
            size={'sm'}
            title={'Filtros'}
        >
            <Popover.Title>
                Filtros
                <Button
                    size="sm"
                    name="botao-limpar"
                    onClick={() => {

                        // limpa filtros
                        limparFiltros();
                    }}
                >
                    Limpar
                </Button>
            </Popover.Title>
            <Popover.Content>
                <Row>
                    <Col>
                        <Form.Label>Pesquisa</Form.Label>
                        <Form.Control 
                            value={filtros.pesquisa || ''}
                            onChange={e => {

                                // altera
                                alterarFiltros({...filtros, pesquisa: e.target.value});
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>Data</Form.Label>
                        <Row>
                            <Col className='position-relative' ref={refs.dataInicioCol}>
                                {refs.dataInicioCol.current && <InputDataCombo
                                    size="sm"
                                    placeholder="A partir de"
                                    data={filtros.dataInicio}
                                    onChange={(novaData) => {
                                        alterarFiltros({...filtros, dataInicio: novaData})
                                    }}
                                    options={{
                                        appendTo: refs.dataInicioCol.current
                                    }}
                                />}
                            </Col>
                            <Col className='position-relative' ref={refs.dataFinalCol}>
                                {refs.dataFinalCol.current && <InputDataCombo
                                    size="sm"
                                    placeholder="Até"
                                    data={filtros.dataFinal}
                                    onChange={(novaData) => {
                                        alterarFiltros({...filtros, dataFinal: novaData})
                                    }}
                                    options={{
                                        appendTo: refs.dataFinalCol.current
                                    }}
                                />}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>Hora</Form.Label>
                        <Row>
                            <Col>
                                <Form.Control
                                    type='time'
                                    placeholder="A partir de"
                                    value={filtros.horaInicio ?? ''}
                                    onChange={(e) => {
                                        alterarFiltros({...filtros, horaInicio: e.target.value})
                                    }}
                                />
                            </Col>
                            <Col>
                                <Form.Control
                                    type='time'
                                    placeholder='Até'
                                    value={filtros.horaFinal ?? ''}
                                    onChange={(e) => {
                                        alterarFiltros({...filtros, horaFinal: e.target.value})
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {(!parametros.idsEmpresas || parametros.idsEmpresas.length === 0) && <>
                    <Row>
                        <Col className='mt-2'>
                            <Form.Label>Grupos chave</Form.Label>
                            <ComboGruposChaveGoogleMultiplo
                                className="select-ficha"
                                ids={filtros.idsGruposChaveGoogle || []}
                                filtros={{
                                    interno: ['S', 'N'],
                                    possuiEmpresa: 'S'
                                }}
                                alterou={idsGruposChaveGoogle => {
                                
                                    // altera
                                    alterarFiltros({
                                        ...filtros,
                                        idsGruposChaveGoogle
                                    });
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mt-2'>
                            <Form.Check 
                                inline
                                checked={filtros.empresaUsarTokenGoogleCliente && filtros.empresaUsarTokenGoogleCliente.includes('N')}
                                id={`parametro-empresa-usar-token-google-cliente`}
                                type='checkbox'
                                label={`Ignorar empresas com token Google`}
                                onChange={(e) => {
                                    
                                    // altera
                                    alterarFiltros({
                                        ...filtros,
                                        empresaUsarTokenGoogleCliente: e.target.checked ? ['N'] : []
                                    });
                                }}
                            />
                        </Col>
                    </Row>
                </>}

                <div className='footer'>
                    <Button
                        size="sm"
                        variant={'light'}
                        onClick={() => {

                            // cancela
                            cancelar();
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        size="sm"
                        variant={'success'}
                        onClick={() => {

                            // altera os parâmetros
                            alterarParametros({...parametros, ...filtros, pagina: 1});
                        }}
                    >
                        Aplicar
                    </Button>
                </div>
            </Popover.Content>
        </PopoverFiltrosComponent>
    </ModalFiltrosComponent>
    
}