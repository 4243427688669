import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Marker, InfoWindow } from '@react-google-maps/api';

// icones
import iconeCliente from './../Icones/cliente.png';
import iconeClienteLight from './../Icones/clientelight.png';
import iconeParceiro from './../Icones/parceiro.png';
import iconeDemo from './../Icones/demo.png';
import iconeDemoLight from './../Icones/demolight.png';
import iconeBloqueado from './../Icones/bloqueado.png';
import iconeCancelado from './../Icones/cancelado.png';

import Marcador from './Marcador';

// função para o relatório de acessos
export default function Marcadores({
    dados,
    empresaSelecionada=null,
    config=null
}){

    const [aberto, alterarAberto] = useState(false)
    const [configIcon, alterarConfig] = useState({});
    let icones = []
    
    const retornaMarcador = (empresa) => {
        let marcador = iconeDemo
        switch(empresa.modo){
            case 'cliente':
                marcador = iconeCliente
                break;
            case 'clientelight':
                marcador = iconeClienteLight
                break;
            case 'parceiro':
                marcador = iconeParceiro
                break;
            case 'demolight':
                marcador = iconeDemoLight
                break;
            case 'bloqueado':
                marcador = iconeBloqueado
                break;
            case 'cancelado':
                marcador = iconeCancelado
                break;
        }

        // retorna
        return marcador;
    }

    // função que retorna o icone
    const retornaIcone = (empresa) => {

        // monta a chave para o ícone
        let iconeChave = `${empresa.modo}_${configIcon.size}`;

        let icone;
        
        // se não existe, cria
        if(typeof icones[iconeChave] === "undefined"){
            icones[iconeChave] = criaMarcador(empresa);
        }

        // pega o ícone
        icone = icones[iconeChave];
        
        // retorna
        return icone;
    }

    const criaMarcador = (empresa) => {
        
        // altera o icone
        return new window.google.maps.MarkerImage(
            retornaMarcador(empresa),
            null, /* size is determined at runtime */
            null, /* origin is 0,0 */
            null, /* anchor is bottom center of the scaled image */
            new window.google.maps.Size(configIcon.size, configIcon.size)
        );

    }

    useEffect(() => {

        // se a config dos ícones foi alterada
        if(config.size !== configIcon.size){
            // salva a config
            alterarConfig(config);
        }

    }, [dados, config])

    return <>
        {
            dados.map((empresa) => {
                
                return <Marcador
                    key={empresa.dados.id}
                    dados={empresa.dados}
                    abrir={(empresaSelecionada) && empresaSelecionada === empresa.dados.id}
                    icon={retornaIcone(empresa.dados)}
                />
            })
        }
    </>

}
