import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Route, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Alert, Badge, Button, Card, Col, Form, InputGroup, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Paginacao from '../../../../../Componentes/Paginacao';

import Registro from './Registro';
import { ListaLogsApiComponent, ModalPainelVisualizarLog } from "./components";
import ModalFiltros from './../ModalFiltros';
import moment from 'moment';
import { copiaCampo } from '../../../../../Funcoes';
import JSONPretty from 'react-json-pretty';
import ReactSelect from 'react-select';
import { FichaComponent } from '../../../../../Componentes/components';

// painel para visualizar dados do log
function PainelVisualizarLog({
    mostrar,
    fechar,
    dados,
    dadosUsuario
}){

    // estados

    // prepara campos
    const camposRef = {
        nomeUsuario: useRef(),
        requisicao: useRef(),
        conteudo: useRef(),
        parametros: useRef()
    };

    // prepara array com permissões
    const permissoes = [
        {value: 'projetista', label: 'Projetista'},
        {value: 'tecnico', label: 'Técnico'},
        {value: 'vendedor', label: 'Vendedor'},
        {value: 'viabilidade', label: 'Viabilidade'}
    ];

    // nenhum dado
    if(!dados) return <></>;

    return <ModalPainelVisualizarLog
        show={mostrar}
        centered
        onExited={() => {}}
        onHide={() => {
            fechar();
        }}
        size={'md'}
    >
        <Modal.Header className='border-0'>
            <Modal.Title 
                as='h5' 
                className={[
                    'text-center w-100',
                    'text-success'
                ].join(' ')}
            >
                <div className='d-flex flex-column'>
                    Requisição
                    <small>{moment(dados.dataCadastro).format('DD/MM/YYYY HH:mm:ss')}</small>
                </div>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'pt-0'}>
            <FichaComponent>
                {
                    dadosUsuario && <>
                    <Form.Row>
                        <Col>
                            <Form.Label>Usuário</Form.Label>
                            <Form.Control
                                ref={camposRef.nomeUsuario}
                                size={'sm'}
                                value={dadosUsuario.nome || ''}
                                disabled
                            />
                        </Col>
                        <Col md={4}>
                            <Form.Label>Permissão</Form.Label>
                            <ReactSelect
                                size={'sm'}
                                className="select-ficha"
                                options={permissoes}
                                defaultValue={permissoes.filter((e) => dadosUsuario.permissao === e.value)[0]}
                                isMulti={false}
                                placeholder='Permissão'
                                isDisabled
                            />
                        </Col>
                    </Form.Row>
                </>}
                <Form.Row className='mb-2'>
                    <Col>
                        <Form.Label>Requisição</Form.Label>
                        <InputGroup>
                            <Form.Control 
                                size={'sm'}
                                value={dados.metodo || ''}
                                style={{
                                    width: '60px',
                                    flex: 'unset',
                                    textAlign: 'center'
                                }}
                                disabled
                            />
                            <Form.Control 
                                ref={camposRef.requisicao}
                                size={'sm'}
                                value={dados.requisicao || ''}
                                disabled
                            />
                            <InputGroup.Append>
                                <Button 
                                    size="sm"
                                    className={[].join(' ')}
                                    variant="outline-secondary"
                                    onClick={() => {

                                        // pega o campo
                                        let campo = camposRef.requisicao.current
                                        copiaCampo(campo);

                                    }}
                                >
                                    <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "copy"]}/>
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className='d-flex align-items-center justify-content-between mb-1'>
                            <Form.Label className='mb-0'>Parâmetros</Form.Label>
                            <Button 
                                size="sm"
                                className={[].join(' ')}
                                variant="outline-secondary"
                                onClick={() => {

                                    // pega o campo
                                    let campo = camposRef.parametros.current;
                                    copiaCampo(campo);

                                }}
                            >
                                <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "copy"]}/>
                            </Button>
                        </div>
                        <Form.Control
                            ref={camposRef.parametros}
                            className='d-none'
                            value={dados.parametros ?? ''}
                            disabled
                        />
                        <JSONPretty
                            id="json-info-parametros"
                            data={dados.parametros ?? null}
                            space="4"
                            mainStyle='color: #3f8a98;background: #f7f7f7;padding: 5px;border-radius: 3px;max-height: 180px;overflow: auto;'
                        />
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className='d-flex align-items-center justify-content-between mb-1'>
                            <Form.Label className='mb-0'>Conteúdo</Form.Label>
                            <Button 
                                size="sm"
                                className={[].join(' ')}
                                variant="outline-secondary"
                                onClick={() => {

                                    // pega o campo
                                    let campo = camposRef.conteudo.current;
                                    copiaCampo(campo);

                                }}
                            >
                                <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "copy"]}/>
                            </Button>
                        </div>
                        <Form.Control
                            ref={camposRef.conteudo}
                            className='d-none'
                            value={dados.conteudo ?? ''}
                            disabled
                        />
                        <JSONPretty
                            id="json-info-requisicao"
                            data={dados.conteudo ?? null}
                            space="4"
                            mainStyle='color: #3f8a98;background: #f7f7f7;padding: 5px;border-radius: 3px;max-height: 180px;overflow: auto;'
                        />
                    </Col>
                </Form.Row>
            </FichaComponent>
        </Modal.Body>
    </ModalPainelVisualizarLog>
}

export default function ListaLogsApi({
    idEmpresa
}){

    // pega location
    const history = useHistory();
    const location = useLocation();
    let { path, url } = useRouteMatch();

    // parametros da url
    const {
        // idEmpresa = null
    } = useParams();

    // estados
    const [ carregando, alterarCarregando ] = useState(null);
    const [ mostrarFiltros, alterarMostrarFiltros ] = useState(false);
    const [ parametros, alterarParametros ] = useState({
        registrosPorPagina: 8,
        pagina: 1,
        pesquisa: null
    });
    const [ registros, alterarRegistros ] = useState([]);
    const [ totalRegistros, alterarTotalRegistros ] = useState(null);
    const [ erros, alterarErros ] = useState({});
    const [ registroMostrarDados, alterarRegistroMostrarDados ] = useState(null);
    const refAbrirFiltros = useRef(null);

    // prepara
    const pathsConsultar = [
        '/painel/empresas/editar/:idEmpresa/integracao'
    ].map((p) => p.replace(':idEmpresa', idEmpresa));

    // efeitos

    useEffect(() => {

        // verifica se é o path
        if(carregando !== null && pathsConsultar.includes(location.pathname)){

            // consulta registros
            consultar();
        }

    }, [parametros]);

    useEffect(() => {

        // pega
        let {
            aPartirDe
        } = location.state ?? {};

        // se retornar a essa rota
        if(pathsConsultar.includes(location.pathname) && (!aPartirDe || !['cadastrarEmpresaIntegracaoSistema'].includes(aPartirDe))){

            // consulta
            consultar();
        }

    }, [location.pathname]);

    // função para consultar
    async function consultar(){

        // cria o carregamento
        alterarCarregando(true);

        try{

            // consulta
            let { data } = await axios.get(`/empresas/${idEmpresa}/integracaoSistemas/logs`, {
                params: {
                    ...parametros
                }
            });

            // sucesso

            // pega os registros e total
            alterarRegistros(data.registros);
            alterarTotalRegistros(data.totalRegistros);

        }catch(e){
            // erro

            // pega a resposta
            let response = e.response;
            // console.log(e);

            // verifica se tem a resposta
            if(response){

                // verifica se é erro 404
                if(response.status === 404){

                    // informa erro
                    toast(<>
                        <div
                            style={{background: '#ff6271'}}
                        >
                            <div className="toast-header">
                                <strong className="mr-auto">Empresa desatualizada</strong>
                                <button 
                                    className="ml-2 mb-1 close btn-outline-light outline-0"
                                >
                                </button>
                            </div>
                            <div className="toast-body text-light">
                                A empresa não possui a versão com a consulta de logs da API.
                            </div>
                        </div>
                    </>);

                    // define o erro
                    alterarErros({
                        semApi: `A empresa não possui a versão com a consulta de logs da API.`
                    });
                }else{

                    // pega erros
                    let data = response.data;

                    // verifica se encontrou erro de conexão
                    if(data){
                        
                        // define o erro
                        alterarErros(data);
                    }
                }
            }

        }finally{
            // finalizou

            // remove o carregamento
            alterarCarregando(false);
        }
    }

    // retorna registro
    function retornaRegistros(){

        // retorna registros organizado
        return registros.map((registro, indice) => 
            <Registro
                key={registro.dados.id}
                dados={registro.dados}
                dataAnterior={indice > 0 ? registros[indice - 1].dados.dataCadastro : null}
                dadosUsuario={registro.dadosUsuario}
                
                clicouAbrir={() => {

                    // altera para mostrar o modal com os dados do registro
                    alterarRegistroMostrarDados(registro);
                }}
            />
        )
    }

    return <ListaLogsApiComponent>
        <ModalFiltros
            elemento={refAbrirFiltros.current}
            mostrar={mostrarFiltros}
            alterarMostrar={alterarMostrarFiltros}
            parametros={parametros}
            alterarParametros={alterarParametros}
        />
        <Card.Body className="py-2 px-3">
            <Card.Header className={'px-0'}>
                <div className={'d-flex '}>
                    <Card.Title className={'flex-grow-1 d-flex align-items-center mb-0'}>
                        Logs - Uso da API
                    </Card.Title>
                    
                    <div>
                        <Button
                            variant='light'
                            size='sm'
                            ref={refAbrirFiltros}
                            onClick={() => {

                                // define
                                alterarMostrarFiltros(true);
                            }}
                            style={{
                                fontSize: '0.9em',
                                boxShadow: '0 0 1px #b1b1b1',
                                border: 0,
                                display: 'flex',
                                gap: '0.2em',
                                alignItems: 'center'
                            }}
                        >
                            <FontAwesomeIcon className="icone" icon={faFilter} />
                            Filtrar
                        </Button>
                    </div>
                </div>
            </Card.Header>
            <div>
                {
                carregando ? <div className="p-5 text-center">
                    <FontAwesomeIcon icon={faSpinner} pulse /> Carregando...
                </div> : (
                        <div
                            className={'position-relative my-2'}
                        >
                            <table className='tabela'>
                                {totalRegistros > 0 && <tbody>
                                    { retornaRegistros() }
                                </tbody>}
                            </table>
                
                            {
                                Object.keys(erros).map((erro) => {
                                    return <Alert
                                        key={erro}
                                        className={['alerta', 'my-1'].join(' ')}
                                        dismissible
                                        onClose={() => alterarErros({})}
                                        variant="danger"
                                    >
                                        <p>{erros[erro]}</p>
                                    </Alert>
                                })
                            }
                        </div>
                    )
                }
                
                <Paginacao
                    pagina={parametros.pagina}
                    registrosPorPagina={parametros.registrosPorPagina}
                    totalRegistros={totalRegistros}
                    alterouPagina={(pagina) => {
                        alterarParametros({...parametros, pagina: pagina});
                    }}
                />
            </div>
        </Card.Body>

        {/* modal para informações da requisição */}
        <PainelVisualizarLog
            mostrar={!!registroMostrarDados}
            fechar={() => {

                // fecha
                alterarRegistroMostrarDados(null); 
            }}
            {...(registroMostrarDados ?? {})}
        />
    </ListaLogsApiComponent>
    
}