import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';

import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';

import Paginacao from '../../../Componentes/Paginacao';
import Confirmar from '../../../Componentes/Confirmar';
import Registro from './Registro';
import { ListaTopUsuariosComponent } from "./components";
import { intersectObject } from '../../../Funcoes';
import moment from 'moment';

export default function ListaTopUsuarios({
    filtros
}){

    // pega location
    const history = useHistory();
    const location = useLocation();
    let { path, url } = useRouteMatch();

    // estados
    const [ carregando, alterarCarregando ] = useState(false);
    const parametrosPadrao = {
        registrosPorPagina: 8,
        pagina: 1,
        pesquisa: null,
        dataInicio: moment().format(`YYYY-MM-DD`),
        dataFinal: moment().format(`YYYY-MM-DD`),
        horaInicio: null,
        horaFinal: null,
        idsGruposChaveGoogle: null,
        empresaUsarTokenGoogleCliente: null
    };
    const [ parametros, alterarParametros ] = useState(parametrosPadrao);
    const [ registros, alterarRegistros ] = useState([]);
    const [ totalRegistros, alterarTotalRegistros ] = useState(null);
    const [ confirmar, alterarConfirmar ] = useState(null);

    // efeitos

    useEffect(() => {

        // consulta registros
        consultar();

    }, [parametros]);

    useEffect(() => {

        // pega os parametros
        let parametrosAlterar = {
            ...parametros,
            ...intersectObject(parametrosPadrao, filtros ?? null)
        };

        // verifica se alterando
        if(JSON.stringify(parametrosAlterar) !== JSON.stringify(parametros)){

            // altera e consulta
            alterarParametros({...parametrosAlterar, pagina: 1});
        }

    }, [filtros]);

    // função para consultar
    async function consultar(){

        // cria o carregamento
        alterarCarregando(true);

        try{

            // consulta
            let { data } = await axios.get('/empresas/acessos/topUsuarios', {
                params: {
                    ...parametros
                }
            });

            // sucesso

            // pega os registros e total
            alterarRegistros(data.registros);
            alterarTotalRegistros(data.totalRegistros);

        }catch(e){
            // erro
        }finally{
            // finalizou

            // remove o carregamento
            alterarCarregando(false);
        }
    }

    // retorna registro
    function retornaRegistros(){

        // retorna registros organizado
        return registros.map((registro, indice) => 
            <Registro
                key={registro.indice ?? indice}
                indice={registro.indice ?? indice}
                dados={registro.dados}
                dadosUsuario={registro.dadosUsuario}
                dadosEmpresa={registro.dadosEmpresa}
            />
        )
    }

    return <ListaTopUsuariosComponent>
        <Confirmar config={confirmar} alterar={alterarConfirmar}/>
        
        <Card.Body>
            <Card.Header className={'px-0'}>
                <Card.Title className={'flex-grow-1 d-flex align-items-center mb-0'}>
                    Top usuários
                </Card.Title>
            </Card.Header>
            <div>
            {
                    carregando ? <div className="p-5 text-center">
                        <FontAwesomeIcon icon={faSpinner} pulse /> Carregando...
                    </div> : (
                        <div
                            style={{flexGrow: 1}}
                        >
                        <table className="table table-hover tabela">
                            <tbody>
                                { retornaRegistros() }
                            </tbody>
                        </table>
                    </div>)
                }
                <Paginacao
                    pagina={parametros.pagina}
                    registrosPorPagina={parametros.registrosPorPagina}
                    totalRegistros={totalRegistros}
                    alterouPagina={(pagina) => {
                        alterarParametros({...parametros, pagina: pagina});
                    }}
                />
            </div>
        </Card.Body>
        
    </ListaTopUsuariosComponent>
    
}