import React from 'react';

// componentes
import Select from 'react-select';
import { Form, Row, Col } from 'react-bootstrap';
import InputDataCombo from './../../../../Componentes/InputDataCombo';
import moment from 'moment';

// componente
export default function ParametrosConsulta({
    parametros,
    alterarParametros
}){

    let funcoesDisponiveis = [
        {value: 'funcionario/permissao', label: 'Abriu permissões de funcionário'},
        {value: 'cliente/consultar', label: 'Consultou clientes'},
        {value: 'funcionario/consultar', label: 'Consultou funcionários'},
        {value: 'logRequisicao/consultar', label: 'Consultou histórico'}
    ];


    return <>
        <Row>
            <Col lg={6}>
                <Form.Group>
                    <Form.Label>A partir de</Form.Label>
                    <InputDataCombo 
                        data={parametros.data_inicio}
                        onChange={(data) => {
                            parametros.data_inicio = (data === null) ? moment().format('YYYY-MM-DD') : data;
                            alterarParametros({...parametros});
                        }}
                    />
                </Form.Group>
            </Col>
            <Col lg={6}>
                <Form.Group>
                    <Form.Label>Até</Form.Label>
                    <InputDataCombo 
                        data={parametros.data_fim}
                        onChange={(data) => {
                            parametros.data_fim = (data === null) ? moment().format('YYYY-MM-DD') : data;
                            alterarParametros({...parametros});
                        }}
                    />
                </Form.Group>
            </Col>
        </Row>
        
        <Form.Group>
            <Form.Label>Eventos</Form.Label>
            <Select
                value={funcoesDisponiveis.filter(funcaoEvento => {
                    if(parametros.funcao.includes(funcaoEvento.value)){
                        return true;
                    }
                    return false;
                })}
                options={funcoesDisponiveis}
                isMulti={true}
                placeholder='Funções'
                onChange={(e) => {
                    e = e || []; // mantem vazio se for null

                    // altera os filtros selecionados
                    parametros.funcao = e.map((option) => option.value);
                    alterarParametros({...parametros});
                }}
            />
        </Form.Group>
    
    </>
}