import styled from 'styled-components';

export const ParametroComponent = styled.div.attrs({
    className: `rounded p-2 mt-3 shadow-sm bg-white`
})`

    position: relative;

    &:first-child {
        margin-top: 0 !important;
    }

    .cabecalho {

        .opcoes {

            label {
                font-size: 0.8rem;
                cursor: pointer;
                line-height: 25px;

                &:after {
                    /* top: 4px; */
                }

                &:before {
                    /* top: 2px; */
                }
            }

            .select-onde, .select-versao {
                border: none;
                max-width: 80px;
                border-radius: 0;
                /* border-bottom: 1px solid #ddd; */
                font-size: 0.8rem;
                height: auto;
                text-transform:capitalize;
            }

            .select-versao {
                background: #d7ddff;
                color: black;
                font-weight: bold;
                text-align: center;
            }

            &.geral {
                position: absolute;
                top: -10px;
                right: -6px;
            }

            .incluir-descricao {
                cursor: pointer;

                &:hover {
                    color: black;
                }
            }

        }
    }

    button {
        padding: 0px 3px;
    }

    &:hover {
        background: #eee;
    }

    .descricao {
        position: relative;

        input {
            padding-right: 24px;
        }

        .opcao {
            display: block;
            position: absolute;
            top: 5px;
            /* bottom: 0; */
            right: 5px;
            font-size: 0.7rem;
            padding: 1px;
            height: 19px;
            width: 19px;
            margin: auto;
        }
    }

    input {
        border: 0;
        border-bottom: 1px solid #ddd;
        border-radius: 0;
    }

    .dados {

        select {
            border: 0;
            border-bottom: 1px solid #ddd;
            border-radius: 0;
        }

        .select-tipo {
            text-transform: capitalize;
        }
    }

`;
