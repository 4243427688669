import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import styles from './index.module.scss';
import axios from 'axios';
import { toast } from 'react-toastify';

// componentes
import Select from 'react-select';
import useIsMounted from 'ismounted';
import { Modal, Container, Card, Row, Col, Form, Button, InputGroup, Spinner, Alert, OverlayTrigger, Tooltip, Badge} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// contexto
import { PainelContexto } from './../../PainelContexto';
import ComboIntegracaoSistemas from '../../../Componentes/ComboIntegracaoSistemas';

import { intersectObject, copiaCampo } from './../../../Funcoes';
import { FichaComponent } from './components';

// faz o cadastro
export default function ModalCadastrar({
    idEmpresa
}){

    // dados
    const isMounted = useIsMounted();
    const { path, url } = useRouteMatch();
    const painelContextoDados = useContext(PainelContexto);
    const history = useHistory();

    // parametros da url
    const {
        // idEmpresa,
        id = null
    } = useParams();

    // prepara
    const dadosPadrao = {
        id: null,
        idEmpresa,
        idIntegracaoSistema: null
    }
    
    // estados
    const [ dados, alterarDados ] = useState({
        ...dadosPadrao,
        tipo: 'erp'
    });
    const [ parametrosComboIntegracaoSistemas, alterarParametrosComboIntegracaoSistemas ] = useState({
        tipo: [dados.tipo]
    });
    const [ mostrar, alterarMostrar ] = useState(true);
    const [ salvando, alterarSalvando ] = useState(false);
    const [ carregando, alterarCarregando ] = useState(false);
    const [ erros, alterarErros ] = useState({});
    const [ tiposIntegracao, alterarTiposIntegracao ] = useState([
        {value: 'erp', label: 'ERP'},
        {value: 'chatbot', label: 'ChatBot'},
        {value: 'ordemServico', label: 'Ordem de serviço'}
    ]);

    // pega o modo inicial se edição
    const tipoInicial = tiposIntegracao.filter((e) => dados.tipo === e.value)[0];

    // inicializa
    useEffect(() => {
        
        // consulta os tipos de integração
        consultarTiposIntegracao();

    }, []);

    // finaliza
    async function salvar(){

        // salva registro
        alterarSalvando(true);
        alterarErros({});

        // faz a requisição
        try{

            // pega dados
            let dadosSalvar = intersectObject(dadosPadrao, dados);

            // faz a requisição
            let { data } = await axios.post(`/empresas/integracaoSistemas`, {
                dados: dadosSalvar
            });

            // mensagem de cadastro realizado com sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Integração adicionada!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </>);

            // // vai para a consulta de sms's enviados (por enquanto volta para a consulta de clientes)
            // history.push('/painel/caixa-saida');
            
            // altera
            alterarSalvando(false);
            alterarMostrar(false);
            
            // monta a url para retroceder
            let urlBack = url.split("/integracao").map((parte, i) => i > 0 ? '/integracao' : parte).join('');
            
            // volta e dá refresh pra atualizar a listagem
            history.push(urlBack, {refresh: true});

        }catch({response}){

            // informa erro
            toast(<>
                <div
                    style={{background: '#ff6271'}}
                >
                    <div className="toast-header">
                        <strong className="mr-auto">Integração não pôde ser adicionada</strong>
                        <button 
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                        </button>
                    </div>
                    <div className="toast-body text-light">
                        {
                            (response && response.data) &&
                            Object.keys(response.data).map((erro) => <p className={'m-0'} key={erro}>{response.data[erro]}<br/></p>)
                        }
                    </div>
                </div>
            </>);

            // define erros
            alterarErros(response.data);

        }finally{
            if (isMounted.current) {
                alterarSalvando(false);
            }
        }
    }

    // função para consultar os tipos de integração
    async function consultarTiposIntegracao(){

        // cria o carregamento
        alterarCarregando(true);

        try{
            
            // faz a requisição
            let { data } = await axios.get(`/integracaoSistemas/tipos`);
                
            // altera
            alterarTiposIntegracao(data.registros.map((registro) => {

                // retorna
                return {
                    value: registro.dados.id,
                    label: registro.dados.descricao
                }
            }));

        }catch(e){
            console.log('pegou o erro')
        }finally{
            if (isMounted.current) {
                alterarCarregando(false);
            }
        }

    }

    // return <>
    //     Aqui retorna
    // </>
    return <Modal 
        show={mostrar}
        centered
        onExited={() => {

            // monta a url para retroceder
            let urlBack = url.split("/integracao").map((parte, i) => i > 0 ? '/integracao' : parte).join('');
            
            // volta e dá refresh pra atualizar a listagem
            history.push(urlBack, {
                aPartirDe: 'cadastrarEmpresaIntegracaoSistema'
            });
            
            // fecha
            alterarMostrar(false);
        }}
        onHide={() => {

            // fecha
            alterarMostrar(false);
        }}
        size={'md'}
    >
        <Modal.Header className='border-0'>
            <Modal.Title 
                as='h5' 
                className={[
                    'text-center w-100',
                    'text-success'
                ].join(' ')}
            >
                Adicionar integração empresa
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'pt-0'}>
            <FichaComponent>
                <Container>
                    <Form.Row>
                        <Col lg={5}>
                            <Form.Label>Tipo</Form.Label>
                            <Select
                                className="select-ficha"
                                options={tiposIntegracao}
                                defaultValue={tipoInicial}
                                isMulti={false}
                                placeholder='Tipo'
                                onChange={(e) => {

                                    // altera os filtros selecionados
                                    alterarDados({
                                        ...dados,
                                        tipo: e.value,
                                        idIntegracaoSistema: null
                                    });

                                    // altera os parametros para o combo
                                    alterarParametrosComboIntegracaoSistemas({
                                        ...parametrosComboIntegracaoSistemas.tipo,
                                        tipo: [e.value]
                                    });
                                }}
                            />
                        </Col>
                        <Col>
                            <Form.Label>Integração</Form.Label>
                            <ComboIntegracaoSistemas
                                className="select-ficha"
                                isMulti={false}
                                valor={dados.idIntegracaoSistema}
                                aoAlterar={(idIntegracaoSistema) => {
                                    
                                    // altera
                                    alterarDados({
                                        ...dados,
                                        idIntegracaoSistema
                                    });
                                }}
                                parametros={parametrosComboIntegracaoSistemas}
                            />
                            
                            {(erros.idIntegracaoSistema) && 
                                <Alert
                                    className={['alerta', 'my-1'].join(' ')}
                                    dismissible
                                    onClose={() => alterarErros({})}
                                    variant="danger"
                                >
                                    <p>{erros.idIntegracaoSistema}</p>
                                </Alert>
                            }
                        </Col>
                    </Form.Row>

                </Container>
            </FichaComponent>
        </Modal.Body>
        <Modal.Footer className='border-0'>
            <Button
                variant='danger'
                onClick={() => {alterarMostrar(false)}}
                disabled={salvando}
            >
                <span>Fechar</span>
            </Button>
            <Button
                onClick={salvar}
                variant='success'
                disabled={salvando}
            >
                {salvando ? 
                    <>
                        <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                        <span>Salvando</span>
                    </> : 
                    <span>Salvar</span>
                }
            </Button>
        </Modal.Footer>
    </Modal>
}   