import React, { useState, useEffect, useContext } from 'react';
import ReactJson from 'react-json-view';

// contexto
import { CadastrarContexto } from './../CadastrarContexto';

// componentes
import ParametrosLista from './../ParametrosLista';
import { Card, Form, Row, Col, Badge } from 'react-bootstrap';

// aba de criação
export default function AbaCriacao(){

    const { dados, alterarDados,
        parametros, alterarParametros, 
        objetoBody 
    } = useContext(CadastrarContexto);


    return <>                       
        <Row>
            <Col lg={7}>
                <Form.Group>
                    <ParametrosLista 
                        value={parametros}
                        onChange={novoArray => {
                            alterarParametros(novoArray);
                        }}
                    />
                </Form.Group>
            </Col>
            <Col lg={5}>

                <div className='mt-2'>

                    <small>Dados para envio</small>
                    <Card
                        className='border-0 bg-dark'
                    >
                        <Card.Body
                            style={{overflowX: 'scroll'}}
                        >
                            <ReactJson 
                                name={false}
                                style={{
                                    whiteSpace: 'nowrap',
                                    background: 'transparent'
                                }}
                                theme='monokai'
                                collapsed={false}
                                src={objetoBody}
                            />
                        </Card.Body>
                    </Card>
                </div>
            </Col>
        </Row>
    </>

}