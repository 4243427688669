import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const ModalAcessosPorEmpresaComponent = styled(Modal).attrs({

})`
    .modal-content{

        .tabela-container{
            min-height: 35vh;
                    
            .tabela{

                tbody{

                    &:empty{
                        min-height: 35vh;
                    }
                }
            }
        }

        .carregamento{
            min-height: 35vh;
        }
    }
`