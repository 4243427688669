import React from 'react';
import styles from './index.module.scss';
import moment from 'moment';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Row, Col, Badge, Button } from 'react-bootstrap';

// filtra data
function FiltroDataCabecalho({
    value,
    onChange,
    carregando=false
}){

    return <>
        <Card className='border-0'>
            <Card.Body className='p-2'>
                <div className={[styles.cabecalho, 'd-flex justify-content-between align-items-center'].join(' ')}>
                    <Button
                        variant='padrao'
                        size='sm'
                        onClick={() => {
                            let dataNova = moment(value).subtract(1, 'month');
                            onChange(dataNova.format('YYYY-MM-DD'));
                        }}
                        disabled={carregando}
                    >
                        <FontAwesomeIcon className='icone' icon={['fas', 'angle-left']} />
                        <span>{moment(value).subtract(1, 'month').format('MM/YYYY')}</span>
                    </Button>
                    <span>
                        {moment(value).format('MMMM [de] YYYY')}
                    </span>
                    <Button
                        variant='padrao'
                        size='sm'
                        onClick={() => {
                            let dataNova = moment(value).add(1, 'month');
                            onChange(dataNova.format('YYYY-MM-DD'));
                        }}
                        disabled={carregando}
                    >
                        <span>{moment(value).add(1, 'month').format('MM/YYYY')}</span>
                        <FontAwesomeIcon className='icone' icon={['fas', 'angle-right']} />
                    </Button>
                </div>
            </Card.Body>
        </Card>
    </>

}


export default FiltroDataCabecalho;