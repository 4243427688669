import React, { useState, useEffect, useContext } from 'react';

// componentes
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Row, Col, FormCheck } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import InputDataCombo from './../../../../Componentes/InputDataCombo';
import ComboServidores from './../../../../Componentes/ComboServidores';


export default function ParametrosConsulta({
    parametros, 
    alterarParametros, 
    mostrarFiltros,
    alterarMostrarFiltros
}){

    // filtros padrão para a pesquisa
    let filtrosStatus = [
        {value: 1, label: 'Aguardando'},
        {value: 2, label: 'Erro no envio'},
        {value: 3, label: 'Enviado'}
    ];


    return <div className="parametros">
        <div className="parametros-inicial">
            <DebounceInput 
                placeholder="" 
                debounceTimeout={400}
                element={Form.Control} 
                value={parametros.texto}
                onChange={(e) =>{
                    alterarParametros({...parametros, texto: e.target.value, pagina: 1})
                }}
                placeholder={'Buscar por email, ou nome'}
            />
            <div
                className={'w-75'}
            >
                <Select
                    options={filtrosStatus}
                    isMulti
                    value={filtrosStatus.filter(f => parametros.status.includes(f.value))}
                    placeholder='Status'
                    onChange={(e) => {
                        e = e || []; // mantem vazio se for null

                        // altera os filtros selecionados
                        alterarParametros({...parametros, status: e.map((option) => option.value), pagina: 1});
                    }}
                />
            </div>
            {/* <Button 
                variant="padrao"
                onClick={(e) => {
                    alterarMostrarFiltros(!mostrarFiltros)
                }}
            >
                <FontAwesomeIcon className="icone" icon={mostrarFiltros ? ["fas", "search-minus"] : ["fas", "search-plus"]} />
            </Button> */}
        </div>
    </div>
}