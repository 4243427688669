import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';

// componentes
import { useSelector } from 'react-redux';
import { Card, Form, Button, Row, Col, OverlayTrigger, Popover, Tooltip, Badge, Alert } from 'react-bootstrap';
import { BrowserView, MobileView, isMobile, isBrowser } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BotaoTabela from './../../../../Componentes/BotaoTabela';
import { converterReal } from './../../../../Funcoes';
// componente
export default function Registro({
    dados,
    membros,
    funcionarios,
    lista,
    etiquetas,
    responsavel
}){
    
    let retornarOpcoes = () => {

        // retorna componentes
        return <>
            {/* <BotaoTabela 
                onClick={clicouEnviarEmail}
                icone={["fas", "envelope"]}
            >
                <span>Escrever Email</span>
            </BotaoTabela>
            <BotaoTabela 
                onClick={clicouEditar}
                icone={["fas", "pencil-alt"]}
            >
                <span>Editar</span>
            </BotaoTabela> */}
        </>
    }

    // retorna a cor com base na cor
    function verificarCor(cor){

        // ajusta cores
        let cores = {
            background: cor || '#bcbcbc',
            color: 'white'
        };

        // altera as cores conforme o nome
        switch(cor){
            case 'sky': cores.background = '#00c2e0' ;break;
            case 'purple': cores.background = '#c377e0'; break;
            case 'yellow': cores.background = '#f2d600'; break;
            case 'red': cores.background = '#eb5a46'; break;
            case 'lime': cores.background = '#51e898'; break;
        }

        return cores;
    }

    return <>
    
        {/* NAVEGADOR */}
        { isBrowser &&

        <Card
            className={['border-0 mb-3', styles.card].join(' ')}
        >
            <Card.Body className='p-2'>
                <div className={styles.etiquetas}>
                    {etiquetas.map(etiqueta =>
                        <Badge
                            key={etiqueta.id}
                            className={[styles.etiqueta].join(' ')}
                            style={{...verificarCor(etiqueta.cor)}}
                        >
                            {etiqueta.descricao}
                        </Badge>
                    )}
                </div>
                <div className={styles.titulo}>
                    {dados.titulo}
                </div>
                <div className={styles.membros}>
                    { membros.map(membro => 
                        <div
                            key={membro.dados.id}
                            className={styles.membro}
                        >
                            { (membro.funcionario && membro.funcionario.foto) &&
                                <img src={`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/fotos/${membro.funcionario.foto}`} className={styles.icone} />
                                // <img src={`${membro.avatar}/170.png`} className={styles.icone} />
                            }
                            { (responsavel && responsavel.id === membro.dados.id) &&
                                <FontAwesomeIcon className={["icone", styles.responsavel].join(' ')} icon={["fas", "crown"]} />
                            }
                        </div>    
                    )}
                </div>
            </Card.Body>
        </Card>
            // <tr>
            //     <td className={'estreito text-center font-weight-bold'}>
            //         {dados.id}
            //     </td>
            //     <td>
            //         {dados.titulo}
            //     </td>
            //     <td className={'text-nowrap estreito'}>
            //         { retornarOpcoes() }
            //     </td>
            // </tr>
        }

        {/* MOBILE */}
        {isMobile &&
            <Card
                className={'border-0 bg-light mb-2'}
            >
                <Card.Body className={'p-2'}>
                    <div className={[isBrowser ? 'd-flex' : ''].join(' ')}>
                        <div className={'flex-grow-1'}>
                            <div>
                                <span>
                                    {dados.titulo}
                                </span>
                            </div>
                        </div>
                        <div className={'text-right'}>
                            { retornarOpcoes() }
                        </div>
                    </div>
                </Card.Body>
            </Card>
        }
    </>
}
