import React, { useState, useEffect, useContext} from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import uniqid from 'uniqid';

// contexto
import { ParametrosListaContexto } from './../ParametrosListaContexto';

// componentes
import { ParametroComponent } from './components';
import { DebounceInput } from 'react-debounce-input';
import { Button, Form, OverlayTrigger, Tooltip, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrash, faTimes, faSpinner, faArrowCircleUp, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-regular-svg-icons';
import ComboVersao from './../../../../../Componentes/ComboVersao';
import DescricaoJson from './DescricaoJson';
import Lista from './../Lista';

// componente
export default function Parametro({
    registro,
    alterarRegistro,
    clicouExcluir
}){

    // estados
    const { parametros, alterarParametros } = useContext(ParametrosListaContexto);
    const [teste, alterarTeste] = useState({});

    // se o idApi for registrado
    useEffect(() => {

        // inicializa o registro
        if(!registro.dados){
            registro.dados = {
                id: null,
                idApi: null,
                parametro: '',
                valor: '',
                descricao: null,
                obrigatorio: 'N',
                tipo: 'string',   // 'string', 'int', 'file', 'json', 'date', 'datetime', 'time', 'decimal'
                onde: 'body',     // 'body', 'query', 'path'
                versao: null,
                posicao: 0,  // ATENÇÃO AQUI
                publico: 'N'
            };
            registro.filhos = [];
            // alterarParametros([...parametros]);
            alterarParametros([...parametros]);
        }

    }, []);


    // se não foi inicializado ainda
    if(!registro.dados){
        return <></>
    }

    return <>
        <ParametroComponent>
            <div className="cabecalho d-flex align-items-center mb-1">
                <div className="opcoes flex-grow-1 d-flex align-items-center">
                    <Form.Check
                        inline
                        custom
                        label="Obrigatório"
                        type="switch"
                        id={`switch-parametro-obrigatorio-${registro.dados.id || registro.tempId}`}
                        size="sm"
                        checked={registro.dados.obrigatorio === 'S' ? true : false}
                        onChange={e => {
                            registro.dados.obrigatorio = e.target.checked ? 'S' : 'N';
                            alterarParametros([...parametros]);
                        }}
                    />
                    <Form.Check 
                        inline
                        custom
                        label="Público"
                        type="switch"
                        id={`switch-parametro-publico-${registro.dados.id || registro.tempId}`}
                        size="sm"
                        checked={registro.dados.obrigatorio === 'S' || registro.dados.publico === 'S' ? true : false}
                        disabled={registro.dados.obrigatorio === 'S'}
                        onChange={e => {
                            registro.dados.publico = e.target.checked ? 'S' : 'N';
                            alterarParametros([...parametros]);
                        }}
                    />
                    <Form.Control 
                        as="select"
                        size="sm"
                        className="select-onde mr-2 bg-light rounded-pill"
                        value={registro.dados.onde}
                        onChange={e => {
                            registro.dados.onde = e.target.value;
                            alterarParametros([...parametros]);
                        }}
                    >
                        {['body', 'query', 'path'].map(onde => 
                            <option key={onde} value={onde}>{(onde)}</option>    
                        )}
                    </Form.Control>
                    <ComboVersao 
                        id={registro.dados.versao}
                        alterou={novaVersao => {
                            registro.dados.versao = novaVersao;
                            alterarParametros([...parametros]);
                        }}
                        defineUltima={true}
                        className="select-versao mr-2 rounded-pill"
                        styles={{}}
                        size="sm"
                        styles={{
                            control: base => ({
                                ...base,
                                minHeight: 25,
                                maxHeight: 25,
                                border: 'none',
                                width: 80,
                                background: '#d7ddff',
                                borderRadius: '50rem'
                            }),
                            dropdownIndicator: base => ({
                                ...base,
                                padding: 2,
                                fontSize: '0.8rem'
                            }),
                            clearIndicator: base => ({
                                display: 'none'
                            }),
                            valueContainer: base => ({
                                ...base,
                                padding: '0px 6px'
                            }),
                            input: base => ({
                                ...base,
                                margin: 0,
                                padding: 0,
                                fontSize: '0.8rem'
                            })
                        }}
                    />
                    {registro.dados.descricao === null &&
                        <FontAwesomeIcon 
                            icon={faComment} 
                            className="incluir-descricao fa-fw text-secondary" 
                            onClick={() => {
                                registro.dados.descricao = '';
                                alterarParametros([...parametros]);
                            }}
                        />
                    }
                </div>
                <div className="opcoes geral">
                    <Button
                        size="sm"
                        variant="padrao"
                        className="mr-2 rounded-pill"
                        onClick={() => {
                            // ATENÇÃO
                            // registro.dados.posicao = parseInt(registro.dados.posicao) + 2 || 0;
                            // let novoArrayPosicionado = parametros
                            // .sort((obj1, obj2) => {
                            //     return obj1.dados.posicao - obj2.dados.posicao;
                            // })
                            // .map((ajustaPosicao, i) => {
                            //     ajustaPosicao.dados.posicao = i;
                            //     return ajustaPosicao;
                            // });
                            // alterarRegistro([...novoArrayPosicionado]);
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowCircleDown} className="fa-fw" />
                    </Button>
                    <Button
                        size="sm"
                        variant="padrao"
                        className="mr-2 rounded-pill"
                        onClick={() => {
                            // ATENÇÃO
                            // registro.dados.posicao = parseInt(registro.dados.posicao) - 2 || 0;
                            // let novoArrayPosicionado = parametros
                            // .sort((obj1, obj2) => {
                            //     return obj1.dados.posicao - obj2.dados.posicao;
                            // })
                            // .map((ajustaPosicao, i) => {
                            //     ajustaPosicao.dados.posicao = i;
                            //     return ajustaPosicao;
                            // });
                            // alterarRegistro([...novoArrayPosicionado]);
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowCircleUp} className="fa-fw" />
                    </Button>
                    <Button
                        size="sm"
                        variant="padrao"
                        className="rounded-pill"
                        onClick={clicouExcluir}
                    >
                        <FontAwesomeIcon icon={faTrash} className="fa-fw" />
                    </Button>
                </div>
            </div>
            <div className="d-flex align-items-center mb-2 dados">
                <div>
                    <Form.Check 
                        custom
                        label=""
                        // type="switch"
                        id={`switch-parametro-teste-${registro.dados.id || registro.tempId}`}
                        size="sm"
                        checked={registro.teste || registro.dados.obrigatorio === 'S'}
                        disabled={registro.dados.obrigatorio === 'S' ? true : false}
                        onChange={e => {
                            registro.teste = e.target.checked;
                            alterarParametros([...parametros]);
                        }}
                    />
                </div>
                <div className="flex-grow-1">
                    <Form.Row>
                        <Col lg={4}>
                            <DebounceInput 
                                placeholder="Váriavel"
                                debounceTimeout={300}
                                element={Form.Control}
                                value={registro.dados.parametro}
                                onChange={(e) =>{
                                    registro.dados.parametro = e.target.value;
                                    alterarParametros([...parametros]);
                                }}
                                className='form-control-sm'
                            />
                        </Col>
                        <Col lg={6}>
                            <DebounceInput 
                                placeholder="Váriavel"
                                debounceTimeout={300}
                                element={Form.Control}
                                value={registro.dados.valor}
                                onChange={(e) =>{
                                    registro.dados.valor = e.target.value;
                                    alterarParametros([...parametros]);
                                }}
                                className='form-control-sm'
                            />
                        </Col>
                        <Col lg={2}>
                            <Form.Control 
                                size="sm"
                                as="select"
                                className='select-tipo'
                                onChange={e => {
                                    registro.dados.tipo = e.target.value;

                                    if(registro.dados.tipo === 'json'){
                                        registro.dados.valor = "{}";
                                    }

                                    alterarParametros([...parametros]);
                                }}
                                value={registro.dados.tipo}
                            >
                                {['string', 'objeto', 'objetoArray', 'json', 'array', 'int', 'file', 'date', 'datetime', 'time', 'decimal'].map(tipo =>
                                    <option key={tipo} value={tipo}>{tipo}</option>
                                )}
                            </Form.Control>
                        </Col>
                    </Form.Row>
                </div>
            </div>
            {registro.dados.tipo === 'json' &&
                <Card
                    className='border-0 bg-dark my-3'
                >
                    <Card.Body
                        style={{overflowX: 'scroll'}}
                    >
                        <DescricaoJson 
                            name={registro.dados.parametro}
                            value={() => {
                                try{
                                    let json = JSON.parse(registro.dados.valor);;
                                    if(typeof json === 'object'){
                                        return json;
                                    }
                                    return {};
                                }catch({}){
                                    return {};
                                }
                            }}
                            onChange={novoValor => {
                                registro.dados.valor = JSON.stringify(novoValor);
                                alterarParametros([...parametros]);
                            }}
                        />
                    </Card.Body>
                </Card>
            }

            { registro.dados.descricao !== null &&
                <div className="descricao d-flex align-items-center">
                    <Form.Control 
                        size="sm"
                        placeholder="Descrição"
                        className={'flex-grow-1'}
                        value={registro.dados.descricao}
                        onChange={e => {
                            registro.dados.descricao = e.target.value;
                            alterarParametros([...parametros]);
                        }}
                        as='textarea'
                        rows={1}
                    />
                    <Button
                        size="sm"
                        variant="padrao"
                        className="opcao rounded-pill"
                        onClick={() => {
                            registro.dados.descricao = null;
                            alterarParametros([...parametros]);
                        }}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </div>
            }

            {(registro.dados.tipo === 'objeto' || registro.dados.tipo === 'objetoArray') && 
                <Lista 
                    parametros={registro.filhos}
                    clicouNovo={() => {
                        registro.filhos.push({tempId: uniqid()});
                        alterarParametros([...parametros]);
                    }}
                    alterou={novoArray => {
                        registro.filhos = novoArray;
                        alterarParametros([...parametros]);
                    }}
                />
            }

        </ParametroComponent>
    </>

}