import React, { useEffect } from 'react';
import Flatpick from 'react-flatpickr';
import { Portuguese } from "flatpickr/dist/l10n/pt";
import styles from './index.module.scss';

// subcomponente data
function InputDataCombo({
    data,
    onChange,
    className='',
    disabled=false,
    placeholder='',
    options=null
}){

    // referencia
    let fp = React.createRef();

    // inicializa
    useEffect(() => {
        
        // habilita ou desabilita o componente de data
        if(fp.current.flatpickr){
            fp.current.flatpickr.altInput.disabled = disabled;
        }
    }, [disabled]);
    

    return <Flatpick 
        // disabled={disabled} // não funciona
        className={["form-control", styles.campoData, className].join(' ')} 
        ref={fp}
        value={data}
        placeholder={placeholder}
        options={{
            dateFormat: "Y-m-d",
            altFormat: "d/m/Y",
            altInput: true,
            locale: Portuguese,
            // allowInput: true,
            onChange: (date, dateStr, instance) => {
                if(dateStr === '') dateStr = null;
                // alterarDados({...dados, saida_data_partida: dateStr});
                // onChange(date, dateStr, instance);
                onChange(dateStr);
            },
            ...(options ?? {})
        }}
    />
}

export default InputDataCombo;