import * as React from "react";

const SvgFuncionariosIcone = (props) => {
  props = {
    width: 15.5,
    height: 15.5,
    ...props,
  };
  return (
    <svg viewBox="0 0 128 128" {...props}>
      <style />
      <g id="funcionarios-icone_svg__funcionarios">
        <radialGradient
          id="funcionarios-icone_svg__SVGID_1_"
          cx={38.713}
          cy={44.953}
          r={23.865}
          gradientTransform="matrix(.9909 0 0 1.0061 .582 -.129)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#bbe371" />
          <stop offset={1} stopColor="#aadc4e" />
        </radialGradient>
        <path
          d="M38.9 69.2c13 0 23.6-10.8 23.6-24.1S51.9 21 38.9 21c-13 0-23.6 10.8-23.6 24.1.1 13.3 10.6 24.1 23.6 24.1z"
          fill="url(#funcionarios-icone_svg__SVGID_1_)"
        />
        <linearGradient
          id="funcionarios-icone_svg__SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1={45.51}
          y1={86.518}
          x2={68.804}
          y2={86.518}
        >
          <stop offset={0} stopColor="#bbe371" />
          <stop offset={1} stopColor="#aadc4e" />
        </linearGradient>
        <path
          d="M59.2 66.1c-3.7 3.7-8.4 6.4-13.7 7.7l5.5 28.5c.2 1-.2 2-.9 2.7l-2.4 1.9c6.6-.8 13.7-2.5 21.1-5.7V80c0-6.2-3.2-10.8-9.6-13.9z"
          fill="url(#funcionarios-icone_svg__SVGID_2_)"
        />
        <linearGradient
          id="funcionarios-icone_svg__SVGID_3_"
          gradientUnits="userSpaceOnUse"
          x1={39.148}
          y1={106.875}
          x2={39.148}
          y2={74.841}
        >
          <stop offset={0} stopColor="#739435" />
          <stop offset={1} stopColor="#80a53b" />
        </linearGradient>
        <path
          fill="url(#funcionarios-icone_svg__SVGID_3_)"
          d="M38 74.8l-5.1 27 6.3 5.1 6.2-5.1-5.1-27z"
        />
        <linearGradient
          id="funcionarios-icone_svg__SVGID_4_"
          gradientUnits="userSpaceOnUse"
          x1={9.5}
          y1={86.583}
          x2={32.695}
          y2={86.583}
        >
          <stop offset={0} stopColor="#aadc4e" />
          <stop offset={1} stopColor="#bbe371" />
        </linearGradient>
        <path
          d="M32.7 73.9c-5.4-1.2-10.2-3.9-14-7.7-6.2 3.1-9.2 7.6-9.2 13.9v21.2c3 1.4 10.6 4.6 21.3 5.7l-2.5-2c-.8-.6-1.2-1.7-.9-2.7l5.3-28.4z"
          fill="url(#funcionarios-icone_svg__SVGID_4_)"
        />
        <g>
          <radialGradient
            id="funcionarios-icone_svg__SVGID_5_"
            cx={95.313}
            cy={48.973}
            r={18.759}
            gradientTransform="matrix(.9909 0 0 1.0061 .582 -.129)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#bbe371" />
            <stop offset={1} stopColor="#aadc4e" />
          </radialGradient>
          <path
            d="M95 68.1c10.2 0 18.5-8.5 18.5-18.9 0-10.4-8.3-18.9-18.5-18.9s-18.5 8.5-18.5 18.9c0 10.4 8.3 18.9 18.5 18.9z"
            fill="url(#funcionarios-icone_svg__SVGID_5_)"
          />
          <linearGradient
            id="funcionarios-icone_svg__SVGID_6_"
            gradientUnits="userSpaceOnUse"
            x1={100.19}
            y1={81.7}
            x2={118.5}
            y2={81.7}
          >
            <stop offset={0} stopColor="#bbe371" />
            <stop offset={1} stopColor="#aadc4e" />
          </linearGradient>
          <path
            d="M110.9 65.7c-2.9 2.9-6.6 5-10.7 6l4.3 22.4c.2.8-.1 1.6-.7 2.1l-1.8 1.5c5.2-.6 10.7-2 16.6-4.5V76.6c-.1-4.9-2.6-8.5-7.7-10.9z"
            fill="url(#funcionarios-icone_svg__SVGID_6_)"
          />
          <linearGradient
            id="funcionarios-icone_svg__SVGID_7_"
            gradientUnits="userSpaceOnUse"
            x1={90.265}
            y1={85.111}
            x2={100.115}
            y2={85.111}
          >
            <stop offset={0} stopColor="#739435" />
            <stop offset={1} stopColor="#80a53b" />
          </linearGradient>
          <path
            fill="url(#funcionarios-icone_svg__SVGID_7_)"
            d="M94.3 72.5l-4 21.2 4.9 4 4.9-4-4-21.2z"
          />
          <linearGradient
            id="funcionarios-icone_svg__SVGID_8_"
            gradientUnits="userSpaceOnUse"
            x1={71.885}
            y1={81.751}
            x2={90.117}
            y2={81.751}
          >
            <stop offset={0} stopColor="#aadc4e" />
            <stop offset={1} stopColor="#bbe371" />
          </linearGradient>
          <path
            d="M90.1 71.8c-4.2-1-8-3.1-11-6.1-4.9 2.4-7.3 6-7.3 10.9v16.7c2.3 1.1 8.3 3.6 16.7 4.5l-2-1.6c-.6-.5-.9-1.3-.7-2.1l4.3-22.3z"
            fill="url(#funcionarios-icone_svg__SVGID_8_)"
          />
        </g>
      </g>
    </svg>
  );
};

export default SvgFuncionariosIcone;
