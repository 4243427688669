import styled from 'styled-components';


export const FiltroComponent = styled.div.attrs({
    className: ``
})`

    ${(props) => ['select', 'arrayUsuarios', 'arrayEmpresas', 'arrayServidorAluguel'].includes(props.tipo)  && `
        min-width: 50%;
    `}

    .card{

        .card-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 13px;
            gap: 1.5em;
        }
    }
    
`;