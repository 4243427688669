import React, { useState, useEffect, useContext, useRef } from 'react';
import styles from './index.module.scss';
import axios from 'axios';
import moment from 'moment';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

// componentes
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Row, Col, Form, Button, InputGroup, Tabs, Tab, Spinner} from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import Select from 'react-select';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import useIsMounted from 'ismounted';


import Marcadores from './Marcadores';
import Legenda from './Legenda';

// contextos    
import { PainelContexto } from './../../PainelContexto';

// função para o relatório de acessos
export default function EmpresasMapa(){
      
    // dados
    const permissaoReducer = useSelector(state => state.permissaoReducer);
    const isMounted = useIsMounted();
    const history = useHistory();
    
	const chaveGoogle = "AIzaSyBtZ42FFaQ3mRmvyLQo20MzIpZ3TFIWclQ";
    const [empresas, alterarEmpresas] = useState(null);
    const [totalRegistros, atualizarTotalRegistros] = useState(null);
    let [carregando, alterarCarregando] = useState(true);
    let [parametros, alterarParametros] = useState({
		tipo_de_acesso: [2],
		descricao: '',
		filtros_modo: ['cliente', 'clientelight', 'parceiro']
    });
	const [mapa, setMapa] = useState(null);
	const [empresaSelecionada, alterarEmpresaSelecionada] = useState(null);
	const [center, alterarCenter] = useState({
		lat: -14.451538469203077,
		lng: -54.7519452727781
	});
	const [zoom, alterarZoom] = useState(4);

    // filtros padrão para a pesquisa
    const filtrosModo = [
        {value: 'cliente', label: 'Clientes'},
        {value: 'clientelight', label: 'Clientes Light'},
        {value: 'demo', label: 'Demos'},
        {value: 'demolight', label: 'Demos Light'},
        {value: 'parceiro', label: 'Parceiros'},
        {value: 'bloqueado', label: 'Bloqueados'},
        {value: 'cancelado', label: 'Cancelados'},
        {value: 'nao-ativo', label: 'Aguardando ativação'}
	];
	
	// faz consulta
	async function consultar(){

		// carregando
		alterarCarregando(true);

		// faz a requisição
		try{
			let { data } = await axios.get('/cliente/mapa', {
				params: Object.assign(parametros, {
					pagina: null
				})
			});

			// finalizado
			alterarEmpresas(data.registros);
			atualizarTotalRegistros(data.totalRegistros)

		}catch(e){

		}finally{
			alterarCarregando(false);
		}
	}

	
    // inicializa
    useEffect(() => {

		consultar()

    }, [parametros]);

    return <>
		<Card className="border-0">
			<Card.Header className='mb-0 border-0 bg-white'>
				Mapa empresas
			</Card.Header>
			<Card.Body className={'py-1'}>
				<Row>
					<Col>
						<DebounceInput 
							placeholder="Empresa" 
							debounceTimeout={400}
							element={Form.Control} 
							value={parametros.texto}
							onChange={(e) =>{
								alterarParametros({...parametros, descricao: e.target.value, pagina: 1})
							}}
						/>
					</Col>
					<Col>
						<Form.Group>
							<Select
								value={filtrosModo.filter((filtro) => {
									return parametros.filtros_modo.includes(filtro.value)
								})}
								options={filtrosModo}
								isMulti={true}
								placeholder='Modo'
								onChange={(e) => {
									e = e || []; // mantem vazio se for null

									// altera os filtros selecionados
									alterarParametros({...parametros, filtros_modo: e.map((option) => option.value), pagina: 1});
								}}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row className={[styles.containerMapa].join(' ')}>
					<LoadScript
						googleMapsApiKey={chaveGoogle}
					>
						<GoogleMap
							onLoad={map => setMapa(map)}
							mapContainerStyle={{
								width: '100%',
								height: '70vh'
							}}
							center={center}
							zoom={zoom}
							onZoomChanged={() => {
								if(mapa){
									alterarZoom(mapa.getZoom());
								}
							}}
						>
						{ /* Child components, such as markers, info windows, etc. */ }
						{
							empresas && 
							<Marcadores
								dados={empresas}
								empresaSelecionada={empresaSelecionada}
								config={{
									size: (zoom < 4) ? 21 : 32
								}}
							/>
						}
						<></>
						</GoogleMap>
					</LoadScript>
					{
						carregando &&
						<Col className={[styles.carregamentoMapa].join(' ')} align="center">
							<Spinner animation="border" />
						</Col>
					}
				</Row>
				<Row>
					<Col>
						<Legenda />
					</Col>	
				</Row>
			</Card.Body>
		</Card>
    </>
}
