import React, { useState, useEffect, useContext } from 'react';

// componentes
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import InputDataCombo from './../../../../Componentes/InputDataCombo';
import ComboServidores from './../../../../Componentes/ComboServidores';


export default function ParametrosConsulta({
    parametros, 
    alterarParametros,
    mostrarFiltros,
    alterarMostrarFiltros
}){

    // filtros padrão para a pesquisa
    let filtrosPermissao = [
        {value: 'projetista', label: 'Projetista'},
        {value: 'tecnico', label: 'Técnico'},
        {value: 'vendedor', label: 'Vendedor'},
        {value: 'viabilidade', label: 'Viabilidade'}
    ];

    // prepara
    let filtrosMasterTerceirizado = [
        {value: 'S', label: 'Gerenciador'},
        {value: 'N', label: 'Padrão'},
        {value: 'terceirizado', label: 'Terceirizado'}
    ];

    return <div className="parametros">
        <div className="parametros-inicial">
            <DebounceInput 
                placeholder="" 
                debounceTimeout={400}
                element={Form.Control} 
                value={parametros.pesquisa}
                onChange={(e) =>{
                    alterarParametros({...parametros, pesquisa: e.target.value, pagina: 1})
                }}
            />
            <Col md={'3'} className={'px-0'}>
                <Select
                    className="select"
                    isClearable
                    options={filtrosMasterTerceirizado}
                    isMulti={false}
                    placeholder='Acesso'
                    onChange={(e) => {

                        // pega o valor
                        let valor = (e) ? e.value : null;
                        let parametrosAcessoAlterar = {
                            master: valor ? [valor] : null,
                            terceirizado: null
                        };

                        // verifica se o valor é o terceirizado
                        if(valor === 'terceirizado'){
                            
                            // altera
                            parametrosAcessoAlterar = {
                                ...parametrosAcessoAlterar,
                                master: null,
                                terceirizado: ['S']
                            };
                        }

                        // altera os filtros selecionados
                        alterarParametros({
                            ...parametros,
                            ...parametrosAcessoAlterar,
                            pagina: 1
                        });
                    }}
                />
            </Col>
            <Col md={'3'} className={'px-0'}>
                <Select
                    className="select"
                    isClearable
                    options={filtrosPermissao}
                    isMulti={false}
                    placeholder='Permissão'
                    onChange={(e) => {
                        let valor = (e) ? e.value : null;
                        
                        // altera os filtros selecionados
                        alterarParametros({...parametros, permissao: valor, pagina: 1});
                    }}
                />
            </Col>
            <Button 
                variant="padrao"
                onClick={(e) => {
                    alterarMostrarFiltros(!mostrarFiltros)
                }}
            >
                <FontAwesomeIcon className="icone" icon={mostrarFiltros ? ["fas", "search-minus"] : ["fas", "search-plus"]} />
            </Button>
        </div>
        {
            mostrarFiltros &&
            <div className='mt-3'>
                {/* <h4 className="my-2">Filtros</h4> */}
                <Row>
                    <Col lg={5}>
                        <Form.Row>
                            <Col md="5">
                                <Form.Label>Usuários online</Form.Label>
                            </Col>
                            <Col>
                                <Form.Check
                                    type="switch"
                                    id="switch-online-sim"
                                    label="Sim"
                                    checked={(parametros.online.includes('S'))}
                                    onChange={e => {
                                        alterarParametros({...parametros, online: (e.target.checked) ? [...parametros.online, 'S'] : parametros.online.filter((e) => e !== 'S'), pagina: 1});
                                    }}
                                />
                            </Col>
                            <Col>
                                <Form.Check
                                    type="switch"
                                    id="switch-online-nao"
                                    label="Não"
                                    checked={(parametros.online.includes('N'))}
                                    onChange={e => {
                                        alterarParametros({...parametros, online: (e.target.checked) ? [...parametros.online, 'N'] : parametros.online.filter((e) => e !== 'N'), pagina: 1});
                                    }}
                                />
                            </Col>
                        </Form.Row>
                    </Col>
                </Row>
            </div>
        }
    </div>
}