import React, { useState, useEffect, useContext, useRef } from 'react';
import styles from './index.module.scss';
import stylesGerais from './../index.module.scss';

// função para o relatório de acessos
export default function Legenda(){

    useEffect(() => {
        
    }, [])

    const BlocoCor = ({ modoCliente }) => {
        return <div className={[styles.blocoCor, stylesGerais.corStatusCliente, stylesGerais[modoCliente]].join(' ')}></div>
    }

    const Item = ({ modoCliente, texto }) => {
        return <div className={[styles.item, stylesGerais.corStatusCliente, stylesGerais[modoCliente]].join(' ')}>
            {texto}
        </div>
    }

    return <>
        <div className={[styles.legendaMapa].join(' ')}>
            
            <div>
                <BlocoCor modoCliente="cliente"/>
                Cliente
            </div>
            <div>
                <BlocoCor modoCliente="clientelight"/>
                Cliente Light
            </div>
            <div>
                <BlocoCor modoCliente="parceiro"/>
                Parceiro
            </div>
            <div>
                <BlocoCor modoCliente="bloqueado"/>
                Bloqueado
            </div>
            <div>
                <BlocoCor modoCliente="cancelado"/>
                Cancelado
            </div>
            <div>
                <BlocoCor modoCliente="demo"/>
                Demo
            </div>
            <div>
                <BlocoCor modoCliente="demolight"/>
                Demo Light
            </div>
            <div>
                <BlocoCor modoCliente="demoEmTeste"/>
                Demo em teste
            </div>
            <div>
                <BlocoCor modoCliente="demolightEmTeste"/>
                Demo Light em teste
            </div>
        </div>
    </>

}
