import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const EmpresasAcessosComponent = styled(Card).attrs({
    className: `painel-consulta border-0`
})`

    .tabs{
        font-size: 14px;
        margin-top: 0.5em;

        a{
            color: #5a5a5a;
            padding: 2px 14px;
            background: none !important;
            border: none !important;
            outline: none;

            &[aria-selected=true]{
                border-bottom: 1px solid #34dc34 !important;
                margin-bottom: -1px !important;
            }
            
            &:hover{
                color: #4b4a4a;
            }
        }

    }

    .tabela{

        thead{

            tr{

                th{
                    background: #f2f2f2;
                }
            }
        }
    }
    
`;

export const ListaComponent = styled.div.attrs({
    className: `painel-consulta border-0`
})`
    
    .card-header{
        /* padding: 0; */
        font-size: 0.9em;

        .h5{
            font-size: 1.25em;
        }

        .btn{
            padding: 0.25em 0.5em;
            font-size: 0.875em;
        }
    }

    .card-body{
        padding-top: 0.5rem;
    }

    .form-control{
        min-height: unset;
        height: 31px;
    }

    .btn{
        padding: 0.25em 0.5em;
        font-size: 0.875em;
        line-height: 1.5;
        border-radius: 0.2em;
    }

    .card{

        > .card-header{
            border-left: 3px solid var(--cor-cliente-ficha) !important;
        }

        .card{
            > .card-header{
                border-left: unset !important;
            }
        }
    }

    .tabela{
        width: 100%;
        border-radius: 0.3em;
        border-collapse: separate;
        font-size: 0.95em;
        /* margin-top: 0.75em; */
        -webkit-border-horizontal-spacing: 0.2em;
        -webkit-border-vertical-spacing: 0.25em;

        tbody{
            border-radius: 0.3em;
            width: 100%;
                
            &:empty{
                position: relative;
                min-height: 140px;
                display: block;

                &::after{
                    content: "Sem registros";
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    color: grey;
                    background: #fbfbfb;
                    border-radius: 0.3em;
                    box-shadow: 0px 0px 1px inset #cacaca;
                }
            }
        }
        
        .registro, thead tr{
            --cor-status-registro: black;
            /* display: flex;
            align-items: flex-start; */
            font-size: 0.9em;
            border-radius: 0.3em;
            position: relative;

            &.registro-divisor-data{
                font-weight: 600;
                height: fit-content;

                td{
                    height: fit-content;
                    font-size: 0.8em;
                    text-align: center;
                    border-bottom: 1px solid #c3c0c0;
                    border-left: 1px solid #c3c0c0;
                    padding: 0 5px;

                    &:hover{
                        background: #fdfdfd;
                    }
                }
            }

            &:first-child{
                margin-top: 0;
            }

            td, th{
                border-left: 1px solid #f3f3f3;
                background: #fdfdfd;
                border: 1px solid #f3f3f3;
                padding: 0.25em 0.5em;
                vertical-align: middle;

                &:first-child{
                    border-top-left-radius: 0.3em;
                    border-bottom-left-radius: 0.3em;
                }
                
                &:last-child{
                    border-top-right-radius: 0.3em;
                    border-bottom-right-radius: 0.3em;
                }
                
                &.destaque{
                    color: var(--cor-status-registro);
                    border-left: 3px solid var(--cor-status-registro);
                }

                &.menor{
                    font-size: 0.8em;
                }

                &.hora{
                    font-size: 0.8em;
                    font-weight: 500;
                }
            }
            
            th{
                background: #fafafa;
            }
        }
    }

    .paginacao{
        font-size: 0.85em;

        .total-registros{
            padding: 0.125em 1.2em !important;
        }
    }
    
    .alerta{
        font-size: 12px;
        padding: 5px 10px !important;
        border-radius: 5px;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        margin: 4px;

        button{
            padding: 1px 2px !important;
            font-size: 20px;
        }

        p{
            margin: 0;
        }
    }
`;