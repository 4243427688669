import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faArrowLeft, faArrowRight, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Card, Row, Col, Form, Button, Modal, Spinner, Alert, InputGroup } from 'react-bootstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { toast } from 'react-toastify';
import useIsMounted from 'ismounted';

import { copiaCampo } from './../../../Funcoes';
import {ModalCadastroServidorComponent} from './components';

import Arquivo from './Arquivo';

// retorna conteudo
export default function ModalCadastro({
    aoFechar=null,
    aoSalvar=null
}){
    
    let { path, url } = useRouteMatch();
    const { loginReducer } = useSelector(state => state);
    const isMounted = useIsMounted();
    const history = useHistory();
    const {id} = useParams(); // parametro url

    const [erros, alterarErros] = useState({});
    const [modo, alterarModo] = useState('cadastro'); // cadastro, edicao
    const dadosPadrao = {
        id: id || null,
        nome: null,
        usuario: null,
        arquivoChave: null,
        arquivoChaveTransferencia: null,
        interno: 'S',
        host: null,
        ip: null
    };
    
    const caminhoArquivos = `${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/servidoresChaves/`;
    let dadosSalvar = Object.assign({}, dadosPadrao);
    const [dadosIniciais, alterarDadosIniciais] = useState(dadosSalvar);
    const [dados, alterarDados] = useState(dadosSalvar);
    const [arquivo, alterarArquivo] = useState(null);
    const [arquivoTransferencia, alterarArquivoTransferencia] = useState(null);
    const [salvando, alterarSalvando] = useState(false);
    const [carregando, alterarCarregando] = useState(false);
    const [mostrar, alterarMostrar] = useState(true);
    const [preencherHostPadrao, alterarPreencherHostPadrao] = useState(false);

    // referencias
    let refs = {
        inputArquivo: React.createRef(),
        inputArquivoTransferencia: React.createRef(),
        host: React.createRef(),
        ip: React.createRef()
    }

    // inicializa
    useEffect(() => {

        // verifica se é alteração
        if(id){
            alterarModo('edicao');
            consultarId();

        }else{

        }

    }, [id]);

    // consulta os dados do cliente
    async function consultarId(){
        alterarCarregando(true);

        try{
            
            // faz a requisição
            let { data } = await axios.get(`/servidores/${dados.id}`);
            
            let dadosSalvar = {...data.dados}
            alterarDadosIniciais(dadosSalvar);
            alterarDados(dadosSalvar);

            // verifica se possui arquivo
            if(data.dados.arquivoChave){
                alterarArquivo({
                    upload: null,
                    caminho: caminhoArquivos + data.dados.arquivoChave
                })
            }

            if(data.dados.arquivoChaveTransferencia){
                alterarArquivoTransferencia({
                    upload: null,
                    caminho: caminhoArquivos + data.dados.arquivoChaveTransferencia
                })
            }

            // se possui host, esconde a opção de preencher
            if(data.dados.host){
                alterarPreencherHostPadrao(true);
            }

        }catch(e){
            console.log('pegou o erro')
        }finally{
            if (isMounted.current) {
                alterarCarregando(false);
            }
        }

    }

    // salva edição
    async function salvar(){
        alterarErros({});
        alterarSalvando(true);

        // faz a requisição
        try{

            // dados para salvar a ibagem
            let formData = new FormData();
            formData.append('nome', dados.nome || '');
            formData.append('usuario', dados.usuario || '');
            formData.append('removerArquivo', dados.removerArquivo || 'N');
            formData.append('removerArquivoTransferencia', dados.removerArquivoTransferencia || 'N');
            formData.append('interno', dados.interno || 'S');
            formData.append('host', dados.host || '');
            formData.append('ip', dados.ip || '');

            if(id){
                formData.append('id', id);
            }

            // só adiciona se tiver o arquivo
            if(arquivo && arquivo.upload){
                formData.append('arquivo', arquivo.upload);
            }

            // só adiciona o arquivo de transferencia se houver
            if(arquivoTransferencia && arquivoTransferencia.upload){
                formData.append('arquivoTransferencia', arquivoTransferencia.upload);
            }

            // faz a requisição
            let { data } = await axios.post(`/servidores`, formData, {
                onUploadProgress: (progressEvent) => {

                    if(arquivo && arquivo.upload){
                        arquivo.progresso = Math.round( (progressEvent.loaded * 100) / progressEvent.total);

                        alterarArquivo({...arquivo});
                    }
                    
                    if(arquivoTransferencia && arquivoTransferencia.upload){
                        arquivoTransferencia.progresso = Math.round( (progressEvent.loaded * 100) / progressEvent.total);

                        alterarArquivoTransferencia({...arquivoTransferencia});
                    }
                },
                headers: {'Content-type': 'multipart/form-data'},
            })

            // mensagem de cadastro realizado com sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Servidor salvo!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    Dados do servidor foram salvos.
                </div>
            </>);

            // executa callback
            if(aoSalvar){
                aoSalvar();
            }else{
                alterarMostrar(false);
            }

        }catch({response}){

            // erro
            if(response.status === 400){
                alterarErros(response.data);
            }

        }finally{
            if (isMounted.current) {
                alterarSalvando(false);
            }
            
        }

    }

    return <>
        <Modal
            className={['modal-personalizado'].join(' ')}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={mostrar}
            onHide={() => {
                alterarMostrar(false);
            }}
            onExited={() => {
                if(aoFechar){
                    aoFechar();
                }else{
                    history.push(`/painel/servidores`, {refresh: true});
                }
            }}
            backdrop={dadosIniciais === dados ? true : 'static'}
        >
            <Modal.Header 
                closeButton 
                onHide={() => {
                    alterarMostrar(false);
                }}
            >
                <Modal.Title className={'text-center flex-grow-1'}>
                    {id ? dadosIniciais.nome : 'Adicionar novo servidor'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ModalCadastroServidorComponent>
                    {
                        carregando ?
                        <Col align="center">
                            <Spinner animation="border" />
                        </Col>
                        :
                        <Form.Group className={['formulario'].join(' ')}>
                            <Form.Row>
                                <Col className={'mb-2'}>
                                    <Form.Label className='mb-0 small'>Nome</Form.Label>
                                    <Form.Control 
                                        className={[styles.input].join(' ')}
                                        value={dados.nome || ''}
                                        onChange={(e) => {
                                            alterarDados({...dados, nome: e.target.value});
                                        }}
                                    />
                                    {(erros.nome) && 
                                        <Alert className={[styles.alerta, 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.nome}</p></Alert>
                                    }
                                </Col>
                                <Col className={'mb-2'}>
                                    <Form.Label className='mb-0 small'>Usuário</Form.Label>
                                    <Form.Control 
                                        className={[styles.input].join(' ')}
                                        value={dados.usuario || ''}
                                        onChange={(e) => {
                                            alterarDados({...dados, usuario: e.target.value});
                                        }}
                                    />
                                    {(erros.nome) && 
                                        <Alert className={[styles.alerta, 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.nome}</p></Alert>
                                    }
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    <Form.Label className='mb-0 small'>Host</Form.Label>
                                    <InputGroup className="mb-2">
                                        <Form.Control 
                                            className={[styles.input].join(' ')}
                                            ref={refs.host}
                                            value={dados.host || ''}
                                            onChange={(e) => {
                                                alterarDados({...dados, host: e.target.value});

                                                // se preencheu o host e apagou
                                                if(!e.target.value && preencherHostPadrao){

                                                    // esconde a opção
                                                    alterarPreencherHostPadrao(false);
                                                }
                                            }}
                                        />
                                        {(erros.host) && 
                                            <Alert className={[styles.alerta, 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.host}</p></Alert>
                                        }
                                        <InputGroup.Append>
                                            <Button  
                                                size="sm"
                                                className={[''].join(' ')}
                                                variant="outline-secondary"
                                                onClick={() => {
                                                    
                                                    // copia campo
                                                    copiaCampo(refs.host.current);

                                                }}
                                            >
                                                <FontAwesomeIcon style={{fontSize: 15}} className="icone" icon={["fas", "copy"]}/>
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                                <Col md={4}>
                                    <Form.Label className='mb-0 small'>IP</Form.Label>
                                    <InputGroup className="mb-2">
                                        <Form.Control 
                                            className={[styles.input].join(' ')}
                                            ref={refs.ip}
                                            value={dados.ip || ''}
                                            onChange={(e) => {
                                                alterarDados({...dados, ip: e.target.value});
                                            }}
                                        />
                                        {(erros.ip) && 
                                            <Alert className={[styles.alerta, 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.ip}</p></Alert>
                                        }
                                        <InputGroup.Append>
                                            <Button  
                                                size="sm"
                                                className={[''].join(' ')}
                                                variant="outline-secondary"
                                                onClick={() => {
                                                    
                                                    // copia campo
                                                    copiaCampo(refs.ip.current);

                                                }}
                                            >
                                                <FontAwesomeIcon style={{fontSize: 15}} className="icone" icon={["fas", "copy"]}/>
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Form.Row>
                            <Form.Row className="mb-1">
                                <Col>
                                    <Form.Check 
                                        type="switch"
                                        id="custom-switch-interno"
                                        label="Interno"
                                        checked={dados.interno === 'S'}
                                        onChange={e => {
                                            let novoValor = e.target.checked ? 'S' : 'N';
        
                                            alterarDados({...dados, interno: novoValor});
                                        }}
                                    />
                                </Col>
                                {
                                    !preencherHostPadrao &&
                                    <Col className="d-flex justify-content-end">
                                        <Form.Check 
                                            type="switch"
                                            id="custom-switch-preencher-padrao"
                                            label="Preencher host padrão"
                                            checked={preencherHostPadrao}
                                            className={'campo-menor'}
                                            onChange={e => {

                                                // esconde a opção
                                                alterarPreencherHostPadrao(true);

                                                let padrao = `.geogridmaps.com.br`;
                                                let nome = dados.nome ? dados.nome.toLowerCase() : ''
                                                
                                                alterarDados({...dados, host: `${dados.host || nome}${padrao}`});

                                            }}
                                        />
                                    </Col>
                                }
                            </Form.Row>
                            <Form.Row className="my-1">
                                <Col className={'mb-2 d-flex flex-column align-items-center'}>
                                    {
                                        arquivo &&
                                        <Arquivo
                                            nomeArquivo={dados.arquivoChave}
                                            dados={dados}
                                            arquivo={arquivo}
                                            removerArquivo={() => {
                                                alterarArquivo(null);

                                                if(dados.arquivoChave){
                                                    alterarDados({...dados, removerArquivo: 'S'});
                                                }

                                            }}
                                        />
                                    }
                                    <div
                                        className='text-center mt-1'
                                    >
                                        <input 
                                            hidden 
                                            type="file" 
                                            ref={refs.inputArquivo} 
                                            onChange={() => {
                                                if(refs.inputArquivo.current.files.length > 0){
                                                    alterarArquivo({
                                                        upload: refs.inputArquivo.current.files[0],
                                                        caminho: null
                                                    });
                                                }
                                            }} 
                                            multiple={false}
                                        />
                                        <Button
                                            variant='padrao'
                                            onClick={() => {
                                                refs.inputArquivo.current.click();
                                            }}
                                            size='sm'
                                        >
                                            <span>Anexar chave padrão</span>
                                        </Button>
                                    </div>
                                    
                                </Col>
                                <Col className={'border-left mb-2 d-flex flex-column align-items-center'}>
                                    {
                                        arquivoTransferencia &&
                                        <Arquivo
                                            nomeArquivo={dados.arquivoChaveTransferencia}
                                            dados={dados}
                                            arquivo={arquivoTransferencia}
                                            removerArquivo={() => {
                                                alterarArquivoTransferencia(null);

                                                if(dados.arquivoChaveTransferencia){
                                                    alterarDados({...dados, removerArquivoTransferencia: 'S'});
                                                }

                                            }}
                                        />
                                    }
                                    <div
                                        className='text-center mt-1'
                                    >
                                        <input 
                                            hidden 
                                            type="file" 
                                            ref={refs.inputArquivoTransferencia} 
                                            onChange={() => {
                                                if(refs.inputArquivoTransferencia.current.files.length > 0){
                                                    alterarArquivoTransferencia({
                                                        upload: refs.inputArquivoTransferencia.current.files[0],
                                                        caminho: null
                                                    });
                                                }
                                            }} 
                                            multiple={false}
                                        />
                                        <Button
                                            variant='padrao'
                                            onClick={() => {
                                                refs.inputArquivoTransferencia.current.click();
                                            }}
                                            size='sm'
                                        >
                                            <span>Anexar chave transferência</span>
                                        </Button>
                                    </div>
                                </Col>
                            </Form.Row>

                        </Form.Group>
                    }
                </ModalCadastroServidorComponent>
            </Modal.Body>
            <Modal.Footer>
                <SalvarFormulario 
                    salvando={salvando}
                    modo={modo}
                    aoFechar={() => {
                        alterarMostrar(false);
                    }}
                    onClick={() => salvar(dados)}
                />
            </Modal.Footer>
        </Modal>
    </>
}

function SalvarFormulario({
    salvando,
    aoFechar,
    modo,
    onClick
}){
    return <Form.Group 
        className={[styles.linhaSalvar, 'd-flex justify-content-around my-2'].join(' ')}
    >
        <Button 
            variant="danger" 
            onClick={aoFechar}
        >Cancelar</Button>
        <Button 
            variant="success" 
            disabled={salvando} 
            onClick={onClick}
        >
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <>
                    <FontAwesomeIcon className="icone" icon={["fas", 'check']} />
                <span>{modo === 'edicao' ? 'Salvar' : 'Cadastrar'}</span>
                </>
            }
        </Button>
    </Form.Group>
}