import * as React from "react";

const SvgMenu2Icone = (props) => {
  props = {
    width: 15.5,
    height: 15.5,
    ...props,
  };
  return (
    <svg viewBox="0 0 128 128" {...props}>
      <style />
      <g id="menu2-icone_svg__menu">
        <linearGradient
          id="menu2-icone_svg__SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1={22.895}
          y1={90}
          x2={105.105}
          y2={90}
        >
          <stop offset={0} stopColor="#7a9cab" />
          <stop offset={0.5} stopColor="#a1c0cd" />
          <stop offset={1} stopColor="#7a9cab" />
        </linearGradient>
        <path
          d="M98.6 83.5H29.4c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5h69.2c3.6 0 6.5-2.9 6.5-6.5s-2.9-6.5-6.5-6.5z"
          fill="url(#menu2-icone_svg__SVGID_1_)"
        />
        <linearGradient
          id="menu2-icone_svg__SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1={22.895}
          y1={64}
          x2={105.105}
          y2={64}
        >
          <stop offset={0} stopColor="#7a9cab" />
          <stop offset={0.5} stopColor="#a1c0cd" />
          <stop offset={1} stopColor="#7a9cab" />
        </linearGradient>
        <path
          d="M98.6 57.5H29.4c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5h69.2c3.6 0 6.5-2.9 6.5-6.5s-2.9-6.5-6.5-6.5z"
          fill="url(#menu2-icone_svg__SVGID_2_)"
        />
        <linearGradient
          id="menu2-icone_svg__SVGID_3_"
          gradientUnits="userSpaceOnUse"
          x1={22.895}
          y1={38}
          x2={105.105}
          y2={38}
        >
          <stop offset={0} stopColor="#7a9cab" />
          <stop offset={0.5} stopColor="#a1c0cd" />
          <stop offset={1} stopColor="#7a9cab" />
        </linearGradient>
        <path
          d="M98.6 31.5H29.4c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5h69.2c3.6 0 6.5-2.9 6.5-6.5s-2.9-6.5-6.5-6.5z"
          fill="url(#menu2-icone_svg__SVGID_3_)"
        />
      </g>
    </svg>
  );
};

export default SvgMenu2Icone;
