import axios from "axios";
import { Button, Col, Container, Form, InputGroup, Modal, OverlayTrigger, ProgressBar, Spinner, Tooltip } from "react-bootstrap";
import { FichaComponent } from "../../../Componentes/components";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFileSignature, faInfoCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Confirmar from "../../../Componentes/Confirmar";
import { useSelector } from "react-redux";
import ComboBancosDados from "../../../Componentes/ComboBancosDados";
import ComboServidores from "../../../Componentes/ComboServidores";
import ComboVersao from "../../../Componentes/ComboVersao";

export default function ModalInstalar({
    idEmpresa,
    idEmpresaBackup,

    instalarDemo='N',

    mostrar,
    alterarMostrar,

    aoFinalizar
}){

    // contextos
    const { loginReducer } = useSelector(state => state);
    
    // dados padrão
    const dadosPadrao = {
        idServidorAluguel: null,
        pasta: null,
        senhaBanco: null,
        instalarArquivos: 'S',
        instalarBancoDados: 'S',
        idBancoDados: null,
        idVersao: null,
        baseTeste: 'N',
        salvarEmpresa: 'S'
    };

    // estados
    const [ dados, alterarDados ] = useState({
        ...dadosPadrao
    });
    const [ dadosEmpresa, alterarDadosEmpresa ] = useState({});
    const [ consultando, alterarConsultando ] = useState(false);
    const [ subindo, alterarSubindo ] = useState(false);
    const [ confirmar, alterarConfirmar ] = useState(null);
    
    // refs
    const toastId = useRef(null);
    
    useEffect(() => {
        
        // se já definido o id
        if(idEmpresa){

            // consulta
            consultar();
        }
    }, [idEmpresa]);

    // função para consultar
    async function consultar(){

        // altera para consultando
        alterarConsultando(true);

        try{

            // consulta
            let { data } = await axios.get(`/empresas/${idEmpresa}`, {
                params: {
                    campos: [
                        'id',
                        'codigo',
                        'razaoSocial',
                        'situacao',
                        'enderecoServidor',
                        'idServidorAluguel',
                        'pasta',
                        'idBanco',
                        'senhaBanco'
                    ]
                }
            });

            // prepara
            let dadosAlterar = {
                ...dados,
                senhaBanco: `${data.dados.codigo}geogrid2015`
            };

            // verifica se é instalação de demo
            if(instalarDemo === 'S'){

                // consulta
                let consultarDadosInstalacaoDemos = await axios.get(`/instalar/configuracao/demos`);
                let dadosInstalacaoDemos = consultarDadosInstalacaoDemos.data;

                // altera
                dadosAlterar = {
                    ...dadosAlterar,
                    idServidorAluguel: dadosInstalacaoDemos.idServidorInstalacaoDemos,
                    idBancoDados: dadosInstalacaoDemos.idBancoDadosInstalacaoDemos
                };

            }else{

                // consulta os dados do banco de teste
                let consultarBancoTeste = await axios.get(`/bancosDados/teste`);
                let dadosBanco = consultarBancoTeste.data.dados;

                // define para alterar
                dadosAlterar.idBancoDados = dadosBanco.id;

            }

            // altera os dados da empresa
            alterarDadosEmpresa(data.dados);

            console.log(dadosAlterar);
            // altera os dados com os dados da empresa
            alterarDados(dadosAlterar);

        }catch(e){

        }finally{

            // finaliza
            alterarConsultando(false);
        }
    }

    // função para gerar backup 
    async function instalar(){
    
        // altera para subindo
        alterarSubindo(true);

        // verifica se está carregando
        if(toastId.current){
            return;
        }

        // esconde
        alterarMostrar(false);

        // faz a requisição
        let oReq = new XMLHttpRequest();
        let ultimaPosicao = null;
        let ultimoJsonRetorno = null;
    
        // prepara para calcular o tempo gasto
        let tempo = 0;
        let verificaTempo = setInterval(function(){
            tempo++
        }, 1000);
        
        // prepara o render
        const toastRender = (progresso = null, mensagem = null) => {

            // retorna
            return <>
                <div className="toast-header">
                    <strong className="mr-auto">
                        {mensagem ?? (progresso === null ?
                            <>Finalizado</>
                            :
                            <>Subindo dados no servidor...</>
                        )}
                    </strong>
                    <button 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                    </button>
                </div>
                <div className="toast-body">
                    {(progresso === null) ?
                        <>
                            <FontAwesomeIcon className='mr-2' icon={faCheck} />
                            <span>Finalizado!!</span>
                        </>
                        :
                        <ProgressBar animated now={progresso} label={`${parseFloat(progresso).toFixed(2)}%`} />
                    }
                </div>
            </>
        }

        // instancia um toast
        toastId.current = toast(({ closeToast }) => toastRender(0), {
            autoClose: false
        });

        // ao receber o retorno da requisição
        oReq.onreadystatechange = async (e) => {
    
            // pega log atual
            let partesRetorno = e.target.responseText.split('}{');
            let totalPartes = partesRetorno.length;
    
            // adiciona
            let logAtual = totalPartes > 1 ? '{' + partesRetorno[totalPartes - 1] : partesRetorno[0];
            ultimaPosicao = e.target.responseText.length;
    
            // pega o retorno
            let retorno = null;
    
            try{
                retorno = JSON.parse(logAtual)
            }catch(e){
                // console.log(e);
            }
    
            // enquanto executa
            if(e.target.readyState === 3 || e.target.readyState === 4){
    
                // verifica se pegou o retorno
                if(!retorno){
                    // sem retorno
                }else{
    
                    // se enviado o processo (último retorno são os dados)
                    if(retorno.concluido){
    
                        // atualiza o toast com a porcentagem
                        toast.update(toastId.current, {
                            render: toastRender(retorno.concluido, retorno.mensagem)
                        });
                        
                    }
    
                    // verifica se tem erros
                    if(retorno.erros){
    
                        // console.log(retorno.erros);
                    }
                    
                }
            
            }
    
            // execução finalizada
            if(e.target.readyState === 4){
    
                // limpa a contagem
                clearInterval(verificaTempo);
    
                // verifica se possui retorno
                if(retorno){
                    
                    // atualiza o toast com a porcentagem
                    toast.update(toastId.current, {
                        render: toastRender(retorno.concluido < 100 ? retorno.concluido : null)
                    });
                }
                
                // remove
                toast.dismiss(toastId.current);
                
                // limpa
                toastId.current = null;

                // altera para não subindo
                alterarSubindo(false);

                // verifica se a importação foi cancelada
                if(e.target.status == 200){
        
                    // executa o callback
                    aoFinalizar();

                    // calcula o tempo da execução
                    let duracao = (tempo / 60)
                    if(duracao < 1){
                        duracao = tempo+'s'
                    }else{
                        duracao = duracao.toFixed(2)+'min'
                    }
    
                    // informa
                    toast(({closeToast }) => <>
                        <div className="toast-header">
                            <strong className="mr-auto">Instalação concluída</strong>
                            <button 
                                onClick={closeToast} 
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="toast-body">
                            {idEmpresaBackup ? <>Backup de dados foi subido com sucesso!</> : <>
                                Base instalada com sucesso!
                            </>} ({duracao})
                        </div>
                    </>);

                }else{
                    // erro
                    
                    // pega os erros
                    let erros = retorno ?? {};
    
                    // mostra novamente?
                    alterarMostrar(true);
    
                    // mensagem de cadastro realizado com sucesso
                    toast(({closeToast }) => <>
                        <div className="toast-header">
                            <strong className="mr-auto">Erro ao subir backup</strong>
                            <button 
                                onClick={closeToast} 
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="toast-body">
                            A base não pôde ser instalada!
                            {
                                Object.keys(erros).filter((erro) => erro !== 'info').map((erro) => {
                                    return <>
                                        <br/>
                                        {
                                            erro === 'conexao' ? 
                                            <>Ocorreu um erro de conexão ao instalar. Por favor, tente novamente.</> 
                                            : <>&nbsp;&nbsp;&rsaquo; {erros[erro]}</>
                                        }
                                    </>
                                })
                            }
                        </div>
                    </>);
                    
                }
            }
    
        };
        
        // inicia a atualização
        oReq.open("POST", `${process.env.REACT_APP_URL_PHP}/empresas/${idEmpresa}/instalar`, true);
        oReq.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        oReq.setRequestHeader("tokenusuario", loginReducer.dadosUsuario.token);
    
        // dados
        oReq.send(JSON.stringify({
            // configuração
            ...dados,
            idEmpresaBackup
        }));
    
    }

    return <>
        <Confirmar config={confirmar} alterar={alterarConfirmar}/>
        <Modal
            show={mostrar}
            centered
            onExited={() => {

                // fecha
                alterarMostrar(false);
            }}
            onHide={() => {

                // fecha
                alterarMostrar(false);
            }}
            size={'md'}
        >
            <Modal.Header className='border-0'>
                <div className={'d-flex w-100'}>
                    <Modal.Title 
                        as='h5' 
                        // className={[
                        //     'text-center w-100',
                        //     'text-success'
                        // ].join(' ')}
                        className={'flex-grow-1 text-center mb-0 text-success'}
                    >
                        {idEmpresaBackup ? <>Subir dados para base</> : <>
                            Instalar base
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip>
                                        Um backup do banco de dados (salvo na empresa) será gerado
                                        e utilizado para a instalação do banco e versão de arquivos.
                                    </Tooltip>
                                }
                            >
                                <FontAwesomeIcon className="ml-1" icon={faInfoCircle} fontSize={12} color="grey"></FontAwesomeIcon>
                            </OverlayTrigger>
                        </>}
                    </Modal.Title>
                        
                    <div className='d-flex' style={{gap: '0.5em'}}>
                        
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip>
                                    Preencher com dados da empresa
                                </Tooltip>
                            }
                        >
                            <Button
                                variant='success'
                                size='sm'
                                onClick={() => {

                                    // altera os dados com os dados da empresa
                                    alterarDados({
                                        ...dados,
                                        senhaBanco: dadosEmpresa.senhaBanco || dados.senhaBanco,
                                        idBancoDados: dadosEmpresa.idBanco || dados.idBancoDados || `${dadosEmpresa.codigo}geogrid2015`,
                                        pasta: dadosEmpresa.enderecoServidor.includes('demo.geogridmaps.com.br') ? null : (dadosEmpresa.enderecoServidor || '').split("/").pop(),
                                        idServidorAluguel: (dadosEmpresa.idServidorAluguel || null)
                                    });
                                }}
                            >
                                <FontAwesomeIcon className="icone" icon={faFileSignature} fontSize={16}></FontAwesomeIcon>
                            </Button>
                        </OverlayTrigger>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body className={'pt-0 px-1'}>
                <FichaComponent>
                    <Container>
                        <Form.Row>
                            <Col>
                                <Form.Label>Endereço</Form.Label>
                                <Form.Row>
                                    <Col>
                                        {consultando ? <div className="text-center">
                                            <FontAwesomeIcon icon={faSpinner} pulse /> Carregando...
                                        </div> : <ComboServidores
                                            className="select-ficha"
                                            placeholder="Servidor"
                                            valor={dados.idServidorAluguel}
                                            alterou={idServidorAluguel => {
                                                alterarDados({...dados, idServidorAluguel: (idServidorAluguel !== '') ? idServidorAluguel : null});
                                            }}
                                            esconderIconeDrop={true}
                                            filtros={{
                                                interno: ''
                                            }}
                                        />}
                                    </Col>
                                    <Col md="7 pl-0">
                                        <InputGroup>
                                            <InputGroup.Text size="sm" className="grupo-texto-inicio">
                                                <small><small>geogridmaps.com.br/</small></small>
                                            </InputGroup.Text>
                                            <Form.Control
                                                placeholder="pasta"
                                                value={dados.pasta || ''}
                                                onChange={e => {
                                                    alterarDados({...dados, pasta: e.target.value});
                                                }}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Form.Row>
                                {instalarDemo === 'S' && <Form.Row>
                                    <Col className="d-flex justify-content-end">
                                        <i><small><small>
                                            Se vazio, montará nome da pasta
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={
                                                    <Tooltip>
                                                        Será usada a pasta definida na empresa, se houver <small>
                                                            (caso não tenha ou seja 'demo', montará o nome da pasta)    
                                                        </small>
                                                    </Tooltip>
                                                }
                                            >
                                                <FontAwesomeIcon className="ml-1" icon={faInfoCircle} fontSize={12} color="grey"></FontAwesomeIcon>
                                            </OverlayTrigger>
                                        </small></small></i>
                                    </Col>
                                </Form.Row>}
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Form.Label>Banco</Form.Label>
                                {consultando ? <div className="text-center">
                                    <FontAwesomeIcon icon={faSpinner} pulse /> Carregando...
                                </div> : <ComboBancosDados
                                    className="select-ficha"
                                    valor={dados.instalarBancoDados !== 'S' ? null : dados.idBancoDados}
                                    disabled={dados.instalarBancoDados !== 'S'}
                                    alterou={idBancoDados => {
                                        alterarDados({...dados, idBancoDados: (idBancoDados !== '') ? idBancoDados : null});
                                    }}
                                />}
                            </Col>
                            <Col>
                                <Form.Label>Senha banco</Form.Label>
                                <Form.Control 
                                    value={dados.instalarBancoDados !== 'S' ? '' : (dados.senhaBanco || '')}
                                    disabled={dados.instalarBancoDados !== 'S'}
                                    onChange={e => {
                                        alterarDados({...dados, senhaBanco: e.target.value});
                                    }}
                                />
                            </Col>
                        </Form.Row>
                        {!idEmpresaBackup && <Form.Row>
                            <Col className="d-flex justify-content-end">
                                <Form.Check 
                                    className='mr-0'
                                    type='switch'
                                    inline
                                    id={'switch-instalar-arquivos'}
                                    label={<>
                                        Instalar banco de dados
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip>
                                                    Cria o banco de dados a partir de um backup da base atual salva na empresa
                                                </Tooltip>
                                            }
                                        >
                                            <FontAwesomeIcon className="ml-1" icon={faInfoCircle} fontSize={12} color="grey"></FontAwesomeIcon>
                                        </OverlayTrigger>
                                    </>}
                                    checked={dados.instalarBancoDados === 'S'}
                                    onChange={e => {
                                        
                                        // altera os dados
                                        alterarDados({...dados, instalarBancoDados: e.target.checked ? 'S' : 'N'});
                                    }}
                                />
                            </Col>
                        </Form.Row>}
                        {
                            (instalarDemo === 'N' && dados.instalarArquivos === 'S') && <>
                                <Form.Row className="mt-3">
                                    <Col>
                                        <InputGroup className="d-flex w-100">
                                            <InputGroup.Text size="sm" className="grupo-texto-inicio">
                                                <small>Selecione a versão dos arquivos</small>
                                            </InputGroup.Text>
                                        {/* <Form.Label>Versão dos arquivos</Form.Label> */}
                                            <ComboVersao
                                                className="select-ficha flex-grow-1"
                                                id={dados.idVersao || null}
                                                alterou={idVersao => {
                                                    alterarDados({...dados, idVersao: (idVersao !== '') ? idVersao : null});
                                                }}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col className="d-flex">
                                        <i><small><small>
                                            <FontAwesomeIcon icon={faInfoCircle} className="mr-1" color="grey"/>
                                            {
                                                idEmpresaBackup ? <>
                                                    Mantenha vazio para instalar com a mesma versão do banco de dados
                                                </> : <>Versão é obrigatória</>
                                            }
                                        </small></small></i>
                                    </Col>
                                </Form.Row>
                            </>
                        }
                        {idEmpresaBackup && <Form.Row>
                            <Col className="d-flex justify-content-end">
                                <Form.Check 
                                    className='mr-0'
                                    type='switch'
                                    inline
                                    id={'switch-instalar-arquivos'}
                                    label={<>
                                        Instalar arquivos (limpar pasta)
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip>
                                                    Instala os arquivos da versão do backup <small>(informações salvas no zip)</small>.
                                                </Tooltip>
                                            }
                                        >
                                            <FontAwesomeIcon className="ml-1" icon={faInfoCircle} fontSize={12} color="grey"></FontAwesomeIcon>
                                        </OverlayTrigger>
                                    </>}
                                    checked={dados.instalarArquivos === 'S'}
                                    onChange={e => {
                                        
                                        // altera os dados
                                        alterarDados({...dados, instalarArquivos: e.target.checked ? 'S' : 'N'});
                                    }}
                                />
                            </Col>
                        </Form.Row>}
                        <Form.Row>
                            <Col className="d-flex justify-content-end">
                                <Form.Check 
                                    className='mr-0'
                                    type='switch'
                                    inline
                                    id={'switch-base-teste'}
                                    label={<>
                                        Base de teste
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip>
                                                    Substitui dados/arquivos da base se já existir, e salva dados de conexão no arquivo de conexão no servidor. 
                                                    <small> Inclui o nome do servidor como prefixo do banco de dados</small>
                                                </Tooltip>
                                            }
                                        >
                                            <FontAwesomeIcon className="ml-1" icon={faInfoCircle} fontSize={12} color="grey"></FontAwesomeIcon>
                                        </OverlayTrigger>
                                    </>}
                                    checked={dados.baseTeste === 'S'}
                                    onChange={e => {
                                        
                                        // prepara
                                        let dadosAlterar = {
                                            ...dados,
                                            baseTeste: e.target.checked ? 'S' : 'N',
                                            salvarEmpresa: e.target.checked ? 'N' : dados.salvarEmpresa
                                        };

                                        // altera os dados
                                        alterarDados(dadosAlterar);

                                    }}
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col className="d-flex justify-content-end">
                                <Form.Check 
                                    className='mr-0'
                                    type='switch'
                                    inline
                                    id={'switch-salvar-empresa'}
                                    label={<>
                                        Salvar na empresa
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip>
                                                    Salva os dados de conexão e servidor na empresa.
                                                </Tooltip>
                                            }
                                        >
                                            <FontAwesomeIcon className="ml-1" icon={faInfoCircle} fontSize={12} color="grey"></FontAwesomeIcon>
                                        </OverlayTrigger>
                                    </>}
                                    checked={dados.salvarEmpresa === 'S'}
                                    onChange={e => {
                                        
                                        // prepara
                                        let dadosAlterar = {
                                            ...dados,
                                            salvarEmpresa: e.target.checked ? 'S' : 'N'
                                        };

                                        // altera os dados
                                        alterarDados(dadosAlterar);

                                    }}
                                />
                            </Col>
                        </Form.Row>
                    </Container>
                </FichaComponent>
            </Modal.Body>
            <Modal.Footer className='border-0 d-flex align-items-center justify-content-between'>
                <Button
                    size="sm"
                    variant='secondary'
                    onClick={() => {alterarMostrar(false)}}
                    disabled={subindo}
                >
                    <span>Fechar</span>
                </Button>
                <Button
                    size="sm"
                    variant='success'
                    onClick={instalar}
                    disabled={(
                        !dados.idServidorAluguel || (instalarDemo !== 'S' && !dados.pasta) || (
                            dados.instalarArquivos === 'S' && !dados.senhaBanco
                        )
                    )}
                >
                    {subindo ? 
                        <>
                            <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                            <span>Subindo</span>
                        </> : 
                        <span>{idEmpresaBackup ? `Subir backup` : `Instalar`}</span>
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}