import React from 'react';
import styles from './index.module.scss';
import TimeField from 'react-simple-timefield';
import { Form } from 'react-bootstrap';

// subcomponente hora
function InputHora({
    value,
    onChange,
    className='',
    disabled=false,
    aceitarNull=true
}){

    return <TimeField 
        disabled={disabled}
        value={value || '00:00'}
        onChange={(valor) => {
            if(valor === '00:00'){
                valor = null
            }else{
                valor += ':00';
            }

            if(valor === null && aceitarNull === false){
                valor = '00:00:00';
            }

            // alterarDados({...dados, hora: value})
            onChange(valor);
        }}
        onFocus={(e) => {
            e.target.select();
        }}
        input={<Form.Control className={className} />}
        colon=":"
    />
}

// exporta
export default InputHora;