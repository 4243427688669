import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import useIsMounted from 'ismounted';

// componentes
import OptionSelect from './OptionSelect';

// componente
export default function ComboServidoresCriar({
    id, // id do modelo
    alterou,
    config = {},
    className='',
    disabled = false,
    carregado=null
}){

    // estados
    let isMounted = useIsMounted();
    let [consulta, alterarConsulta] = useState([]);
    let [carregando, alterarCarregando] = useState(false);
    let [dados, alterarDados] = useState(null);
    const [salvando, alterarSalvando] = useState(false);
    const [timeoutConsultar, alterarTimerConsultar] = useState(null);

    // consulta o id do servidor
    useEffect(() => {

        // carrega alguns
        consultar('');

        // verifica se possui seleção
        if(id !== null && !dados){

            // consulta os dados do item selecionado
            consultarId();

        }else{
            alterarCarregando(false);
        }

    }, []);

    // consulta os responsaveis
    async function consultar(inputValue, callback = null){
        // alterarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/servidores`, {
                params: {
                    texto: inputValue,
                    pagina: (typeof config.pagina !== "undefined") ? config.pagina : 1,
                    registrosPorPagina: (typeof config.registrosPorPagina !== "undefined") ? config.registrosPorPagina : 5
                }
            });

            // pega dados formatados
            let dadosRetorno = data.registros.map((item) => item.dados);

            // retorno
            alterarConsulta(dadosRetorno);
            if(callback !== null){
                callback(dadosRetorno)
            }

            if(id !== null){
                let dadosSelecionados = null;
                data.registros.map((item) => {
                    
                    if(item.dados.id === id){
                        // seleciona o id
                        dadosSelecionados = item.dados;
                        return false;
                    }
                })

                // altera a seleção
                alterarDados(dadosSelecionados)
            }

        }catch({response}){

        }finally{

        }
        
        // alterarCarregando(false);
    }

    // consulta membro
    async function consultarId(){
        alterarCarregando(true);
        
        try{
            
            // faz a consulta do id
            let { data } = await axios.get(`/servidores/${id}`);
            alterarDados(data.dados);

            if(carregado){
                carregado(data.dados);
            }

        }catch({response}){

        }finally{
            alterarCarregando(false);
        }
    }

    // finaliza
    async function salvar(nome){

        // salva registro
        alterarSalvando(true);

        // faz a requisição
        try{

            // faz a requisição
            let { data } = await axios.post(`/servidores`, {
                dados: {
                    id: null,
                    nome,
                    interno: 'N'
                }
            });

            // altera o id
            id = data.id;

            consultarId();

            // mantém seleção
            alterou(id, data.dados);

            // remove o salvando
            alterarSalvando(false);
            // salvou();
            
        }catch({response}){

        }finally{
            if (isMounted.current) {
                alterarSalvando(false);
            }
        }
    }

    // ao alterar a consulta
    const alterouConsulta = (inputValue, callback) => {

        // limpa o timeout
        clearTimeout(timeoutConsultar);

        // aguarda tempo sem rolar o slider
        alterarTimerConsultar(setTimeout(() => {
            consultar(inputValue, callback)
        }, 500));
        
    };

    return <>
        <AsyncCreatableSelect 
            isDisabled={carregando || salvando || disabled}
            isClearable
            loadOptions={alterouConsulta} // ao alterar, carrega
            defaultOptions={consulta}       // lista pré carregada
            value={dados && {
                label: dados.nome
            }} 
            onChange={(e) => {
                if(e === null){
                    alterarDados(null);
                    alterou(null, null);
                }else{
                    // verifica se é um novo item
                    if(e.__isNew__){
                        // salva
                        salvar(e.value)
                    }else{
                        alterarDados({...e});
                        alterou(e.id, e);
                    }
                }
            }}
            components={{
                Option: OptionSelect
            }}
            noOptionsMessage={() => 'Digite algo para fazer a consulta'}
            loadingMessage={() => 'Carregando...'}
            placeholder='Escolher servidor'
            className={className}
        />
    </>

}
