import React from 'react';
import styles from './index.module.scss';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

// componente
export default function Configuracao(){

    // estados
    const { permissaoReducer } = useSelector(state => state);
    const history = useHistory();

    return <>

        { permissaoReducer.habilitarConfiguracaoTrello === 'S' &&
            <Button
                variant='padrao'
                onClick={() => {history.push(`/painel/configuracao/trello`)  }}
            >
                <FontAwesomeIcon className="icone" icon={["fab", "trello"]}></FontAwesomeIcon>
                <span>Configurar Trello</span>
            </Button>
        }
    </>

}