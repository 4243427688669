import * as React from "react";

const SvgEmailIcone = (props) => {
  props = {
    width: 15.5,
    height: 15.5,
    ...props,
  };
  return (
    <svg viewBox="0 0 128 128" {...props}>
      <style />
      <g id="email-icone_svg__caixa_de_saida">
        <g id="email-icone_svg__XMLID_2812_">
          <linearGradient
            id="email-icone_svg__XMLID_9_"
            gradientUnits="userSpaceOnUse"
            x1={64}
            y1={60.194}
            x2={64}
            y2={98.741}
          >
            <stop offset={0} stopColor="#e09138" />
            <stop offset={0.546} stopColor="#ef9d41" />
            <stop offset={1} stopColor="#ffa94a" />
          </linearGradient>
          <path
            id="email-icone_svg__XMLID_2811_"
            d="M10.2 94.7l51.7-34.3c.5-.3 1.1-.3 1.6 0L116.7 94c1.1.7 1.8 1.9 1.8 3.2 0 .8-.7 1.5-1.5 1.5H11c-.8 0-1.5-.7-1.5-1.5V96c0-.5.2-1 .7-1.3z"
            fill="url(#email-icone_svg__XMLID_9_)"
          />
          <linearGradient
            id="email-icone_svg__XMLID_10_"
            gradientUnits="userSpaceOnUse"
            x1={62.682}
            y1={64.23}
            x2={118.5}
            y2={64.23}
          >
            <stop offset={0} stopColor="#d17918" />
            <stop offset={1} stopColor="#de9340" />
          </linearGradient>
          <path
            id="email-icone_svg__XMLID_2795_"
            d="M118.5 96.2V34.3c0-1.6-1.7-2.6-3.1-1.8L62.7 61.9l55.8 34.3z"
            fill="url(#email-icone_svg__XMLID_10_)"
          />
          <linearGradient
            id="email-icone_svg__XMLID_11_"
            gradientUnits="userSpaceOnUse"
            x1={9.5}
            y1={64.273}
            x2={62.682}
            y2={64.273}
          >
            <stop offset={0} stopColor="#de9340" />
            <stop offset={1} stopColor="#d17918" />
          </linearGradient>
          <path
            id="email-icone_svg__XMLID_2792_"
            d="M9.5 96.2V34.4c0-1.6 1.7-2.6 3.1-1.8l50 29.3L9.5 96.2z"
            fill="url(#email-icone_svg__XMLID_11_)"
          />
          <path
            id="email-icone_svg__XMLID_1_"
            d="M9.5 34.9v5.4l53.2 30.6L118.5 39v-4.1c0-1.7-1.4-3.1-3.1-3.1H12.6c-1.7 0-3.1 1.4-3.1 3.1z"
            fill="#ccc"
          />
          <linearGradient
            id="email-icone_svg__XMLID_12_"
            gradientUnits="userSpaceOnUse"
            x1={64}
            y1={29.741}
            x2={64}
            y2={67.799}
          >
            <stop offset={0} stopColor="#e09138" />
            <stop offset={1} stopColor="#ffa94a" />
          </linearGradient>
          <path
            id="email-icone_svg__XMLID_2750_"
            d="M9.5 32.9v5.4l53.2 29.5 55.8-31.9v-3.1c0-1.7-1.4-3.1-3.1-3.1H12.6c-1.7 0-3.1 1.4-3.1 3.2z"
            fill="url(#email-icone_svg__XMLID_12_)"
          />
        </g>
      </g>
    </svg>
  );
};

export default SvgEmailIcone;
