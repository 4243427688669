import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faFilter } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Card } from 'react-bootstrap';

import Paginacao from '../../../../../Componentes/Paginacao';

import Registro from './Registro';
import { ListaLogsApiPorRotaComponent } from "./components";
import { toast } from 'react-toastify';
import ModalFiltros from './../ModalFiltros';

export default function ListaLogsApiPorRota({
    idEmpresa
}){

    // pega location
    const history = useHistory();
    const location = useLocation();
    let { path, url } = useRouteMatch();

    // parametros da url
    const {
        // idEmpresa = null
    } = useParams();

    // estados
    const [ carregando, alterarCarregando ] = useState(null);
    const [ mostrarFiltros, alterarMostrarFiltros ] = useState(false);
    const [ parametros, alterarParametros ] = useState({
        registrosPorPagina: 8,
        pagina: 1,
        pesquisa: null
    });
    const [ registros, alterarRegistros ] = useState([]);
    const [ totalRegistros, alterarTotalRegistros ] = useState(null);
    const [ erros, alterarErros ] = useState({});
    const refAbrirFiltros = useRef(null);

    // prepara
    const pathsConsultar = [
        '/painel/empresas/editar/:idEmpresa/integracao'
    ].map((p) => p.replace(':idEmpresa', idEmpresa));

    // efeitos

    useEffect(() => {

        // verifica se na página de consulta
        if(carregando !== null && pathsConsultar.includes(location.pathname)){
                
            // consulta registros
            consultar();
        }

    }, [parametros]);

    useEffect(() => {

        // pega
        let {
            aPartirDe
        } = location.state ?? {};

        // se retornar a essa rota
        if(pathsConsultar.includes(location.pathname) && (!aPartirDe || !['cadastrarEmpresaIntegracaoSistema'].includes(aPartirDe))){

            // consulta
            consultar();
        }

    }, [location.pathname]);

    // função para consultar
    async function consultar(){

        // cria o carregamento
        alterarCarregando(true);

        try{

            // consulta
            let { data } = await axios.get(`/empresas/${idEmpresa}/integracaoSistemas/logsRota`, {
                params: {
                    ...parametros
                }
            });

            // sucesso

            // pega os registros e total
            alterarRegistros(data.registros);
            alterarTotalRegistros(data.totalRegistros);

        }catch(e){
            // erro

            // pega a resposta
            let response = e.response;
            // console.log(e);

            // verifica se tem a resposta
            if(response){

                // verifica se é erro 404
                if(response.status === 404){

                    // informa erro
                    toast(<>
                        <div
                            style={{background: '#ff6271'}}
                        >
                            <div className="toast-header">
                                <strong className="mr-auto">Empresa desatualizada</strong>
                                <button 
                                    className="ml-2 mb-1 close btn-outline-light outline-0"
                                >
                                </button>
                            </div>
                            <div className="toast-body text-light">
                                A empresa não possui a versão com a consulta de logs da API.
                            </div>
                        </div>
                    </>);

                    // define o erro
                    alterarErros({
                        semApi: `A empresa não possui a versão com a consulta de logs da API.`
                    });
                }else{

                    // pega erros
                    let data = response.data;

                    // verifica se encontrou erro de conexão
                    if(data){
                        
                        // define o erro
                        alterarErros(data);
                    }
                }
            }

        }finally{
            // finalizou

            // remove o carregamento
            alterarCarregando(false);
        }
    }

    // retorna registro
    function retornaRegistros(){

        // retorna registros organizado
        return registros.map(registro => 
            <Registro
                key={`${registro.dados.metodo}-${registro.dados.versao}-${registro.dados.rota}`}
                dados={registro.dados}
            />
        )
    }

    return <ListaLogsApiPorRotaComponent>
        <ModalFiltros
            elemento={refAbrirFiltros.current}
            mostrar={mostrarFiltros}
            alterarMostrar={alterarMostrarFiltros}
            parametros={parametros}
            alterarParametros={alterarParametros}
        />
        <Card.Body className="py-2 px-3">
            <Card.Header className={'px-0'}>
                <div className={'d-flex '}>
                    <Card.Title className={'flex-grow-1 d-flex align-items-center mb-0'}>
                        Logs - Total por rota
                    </Card.Title>
                    <div>
                        <Button
                            variant='light'
                            size='sm'
                            ref={refAbrirFiltros}
                            onClick={() => {

                                // define
                                alterarMostrarFiltros(true);
                            }}
                            style={{
                                fontSize: '0.9em',
                                boxShadow: '0 0 1px #b1b1b1',
                                border: 0,
                                display: 'flex',
                                gap: '0.2em',
                                alignItems: 'center'
                            }}
                        >
                            <FontAwesomeIcon className="icone" icon={faFilter} />
                            Filtrar
                        </Button>
                    </div>
                </div>
            </Card.Header>
            <div>
                {
                carregando ? <div className="p-5 text-center">
                    <FontAwesomeIcon icon={faSpinner} pulse /> Carregando...
                </div> : (
                        <div
                            className={'position-relative my-2'}
                        >
                            <table className='tabela'>
                                {totalRegistros > 0 && <tbody>
                                    { retornaRegistros() }
                                </tbody>}
                            </table>
                
                            {
                                Object.keys(erros).map((erro) => {
                                    return <Alert
                                        key={erro}
                                        className={['alerta', 'my-1'].join(' ')}
                                        dismissible
                                        onClose={() => alterarErros({})}
                                        variant="danger"
                                    >
                                        <p>{erros[erro]}</p>
                                    </Alert>
                                })
                            }
                        </div>
                    )
                }
                
                <Paginacao
                    pagina={parametros.pagina}
                    registrosPorPagina={parametros.registrosPorPagina}
                    totalRegistros={totalRegistros}
                    alterouPagina={(pagina) => {
                        alterarParametros({...parametros, pagina: pagina});
                    }}
                />
            </div>
        </Card.Body>
    </ListaLogsApiPorRotaComponent>
    
}