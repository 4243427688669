import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Route, useHistory, useLocation, useRouteMatch, useParams } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faDatabase, faFilter, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { toast } from 'react-toastify';
import { Button, Card } from 'react-bootstrap';

import { ListaBancosDadosComponent } from "./components";

import Paginacao from '../../../Componentes/Paginacao';
import Confirmar from '../../../Componentes/Confirmar';
import ModalFiltros from './ModalFiltros';
import Registro from './Registro';
import { downloadArquivoNuvemPorUrl } from '../../../Funcoes';
import ModalGerarBackup from '../ModalGerarBackup';
import ModalInstalar from '../ModalInstalar';

export default function Lista(){

    // pega location
    const history = useHistory();
    const location = useLocation();
    let { path, url } = useRouteMatch();

    // parametro url
    const {
        idEmpresa
    } = useParams(); 

    // estados
    const [ carregando, alterarCarregando ] = useState(false);
    const [ mostrarFiltros, alterarMostrarFiltros ] = useState(false);
    const [ parametros, alterarParametros ] = useState({
        registrosPorPagina: 8,
        pagina: 1,
        pesquisa: null,
        idEmpresa
    });
    const [ registros, alterarRegistros ] = useState([]);
    const [ totalRegistros, alterarTotalRegistros ] = useState(null);
    const [ confirmar, alterarConfirmar ] = useState(null);
    const [ mostrarGerarBackup, alterarMostrarGerarBackup ] = useState(false);
    const [ mostrarSubirBackup, alterarMostrarSubirBackup ] = useState(false);
    const [ idEmpresaBackup, alterarIdEmpresaBackup ] = useState(null);

    // referencias
    const refAbrirFiltros = useRef(null);

    // efeitos

    useEffect(() => {

        // consulta registros
        consultar();

    }, [parametros]);

    useEffect(() => {

        // prepara
        let pathsConsultar = [
            '/painel/empresas/backups',
            '/painel/empresas/{idEmpresa}/backups'
        ];
        let {
            aPartirDe
        } = location.state ?? {};

        console.log(location.pathname)
        // se retornar a essa rota
        if(pathsConsultar.includes(location.pathname)){

            // consulta
            consultar();
        }

    }, [location.pathname]);

    // função para consultar
    async function consultar(){

        // cria o carregamento
        alterarCarregando(true);

        try{

            // consulta
            let { data } = await axios.get(`/empresas/backups`, {
                params: {
                    ...parametros
                }
            });

            // sucesso

            // pega os registros e total
            alterarRegistros(data.registros);
            alterarTotalRegistros(data.totalRegistros);

        }catch(e){
            // erro
        }finally{
            // finalizou

            // remove o carregamento
            alterarCarregando(false);
        }
    }

    // faz a exclusão
    async function deletar(dados){

        try{

            // faz a exclusão
            let { data } = await axios.delete(`/empresas/${idEmpresa}/backups/${dados.id}`);

            // excluído com sucesso

            // consulta

            // atualiza registros
            alterarParametros({...parametros, pagina: 1});
            
            // mensagem de sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Backup deletado</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    Backup do banco de dados foi deletado com sucesso!
                </div>
            </>);

        }catch({response}){

            // informa erro
            toast(<>
                <div
                    style={{background: '#ff6271'}}
                >
                    <div className="toast-header">
                        <strong className="mr-auto">Backup não pôde ser deletado</strong>
                        <button 
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                        </button>
                    </div>
                    <div className="toast-body text-light">
                        {
                            (response && response.data) &&
                            Object.keys(response.data).map((erro) => <p className={'m-0'} key={erro}>{response.data[erro]}<br/></p>)
                        }
                    </div>
                </div>
            </>);

        }finally{

            // remove o carregamento
            alterarCarregando(false);

        }

    }

    // ao clicar para excluir
    function clicouExcluirRegistro(registro){

        // confirma
        alterarConfirmar({
            aberto: true,
            titulo: 'Deletar backup',
            texto: `Tem certeza que deseja deletar este backup?`,
            textoBotao: 'Deletar',
            variantConfirmar: 'danger',
            variantCancelar: 'secondary',
            backdrop: true,
            aoConfirmar: () => {

                // deleta o registro
                deletar(registro.dados);
            },
            aoCancelar: () => {
                // ao cancelar
            },
            aoFechar: () => {
                // ao fechar
            }
        })
    }

    // cancela a execução do backup
    async function cancelar(dados){

        try{

            // faz a exclusão
            let { data } = await axios.delete(`/empresas/${idEmpresa}/backups/${dados.id}/cancelar`);

            // excluído com sucesso

            // consulta

            // atualiza registros
            alterarParametros({...parametros, pagina: 1});
            
            // mensagem de sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Backup cancelado</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    Backup do banco de dados foi cancelado com sucesso!
                </div>
            </>);

        }catch({response}){

            // informa erro
            toast(<>
                <div
                    style={{background: '#ff6271'}}
                >
                    <div className="toast-header">
                        <strong className="mr-auto">Backup não pôde ser cancelado</strong>
                        <button 
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                        </button>
                    </div>
                    <div className="toast-body text-light">
                        {
                            (response && response.data) &&
                            Object.keys(response.data).map((erro) => <p className={'m-0'} key={erro}>{response.data[erro]}<br/></p>)
                        }
                    </div>
                </div>
            </>);

        }finally{

            // remove o carregamento
            alterarCarregando(false);

        }

    }

    // retorna registro
    function retornaRegistros(){

        // retorna registros organizado
        return registros.map(registro => 
            <Registro
                key={registro.dados.id}
                dados={registro.dados}
                arquivo={registro.arquivo}
                clicouSubir={() => {

                    // define o backup e mostra o painel
                    alterarIdEmpresaBackup(registro.dados.id);
                    alterarMostrarSubirBackup(true);
                }}
                clicouDownload={() => {
                    
                    // verifica se possui arquivo
                    if(!registro.arquivo){

                        // para aqui
                        return;
                    }

                    // baixa o arquivo
                    downloadArquivoNuvemPorUrl(registro.arquivo.id, registro.arquivo.nome, registro.arquivo.extensao);


                }}
                clicouCancelar={() => {
                    
                    // confirma
                    alterarConfirmar({
                        aberto: true,
                        titulo: 'Cancelar backup',
                        texto: `Tem certeza que deseja cancelar o backup em andamento?`,
                        textoBotao: 'Cancelar backup',
                        variantConfirmar: 'danger',
                        variantCancelar: 'secondary',
                        backdrop: true,
                        aoConfirmar: () => {

                            // deleta o registro
                            cancelar(registro.dados);
                        },
                        aoCancelar: () => {
                            // ao cancelar
                        },
                        aoFechar: () => {
                            // ao fechar
                        }
                    })     
                }}
                clicouExcluir={() => {

                    // envia o registro
                    clicouExcluirRegistro(registro);
                }}
            />
        )
    }

    return <ListaBancosDadosComponent>
        <ModalGerarBackup
            idEmpresa={idEmpresa}
            mostrar={mostrarGerarBackup}
            alterarMostrar={alterarMostrarGerarBackup}
            aoFinalizar={() => {

                // recarrega lista
                alterarParametros({...parametros, pagina: 1});
            }}
        />

        <ModalInstalar
            idEmpresa={idEmpresa}
            idEmpresaBackup={idEmpresaBackup}
            mostrar={mostrarSubirBackup}
            alterarMostrar={alterarMostrarSubirBackup}
            aoFinalizar={() => {

                // limpa
                alterarIdEmpresaBackup(null);
            }}
        />
        
        <ModalFiltros
            elemento={refAbrirFiltros.current}
            mostrar={mostrarFiltros}
            alterarMostrar={alterarMostrarFiltros}
            parametros={parametros}
            alterarParametros={alterarParametros}
        />
        <Confirmar config={confirmar} alterar={alterarConfirmar}/>
        
        <Card.Header className={'px-0'}>
            <div className={'d-flex'}>
                <Card.Title className={'flex-grow-1 d-flex align-items-center mb-0'}>
                    Backups
                </Card.Title>
                <div className='d-flex' style={{gap: '0.5em'}}>
                    <Button
                        variant='light'
                        size='sm'
                        ref={refAbrirFiltros}
                        onClick={() => {

                            // define
                            alterarMostrarFiltros(true);
                        }}
                        style={{
                            fontSize: '0.9em',
                            boxShadow: '0 0 1px #b1b1b1',
                            border: 0,
                            display: 'flex',
                            gap: '0.2em',
                            alignItems: 'center'
                        }}
                    >
                        <FontAwesomeIcon className="icone" icon={faFilter} />
                        Filtrar
                    </Button>
                    
                    <Button
                        variant='success'
                        size='sm'
                        onClick={() => {
                            
                            // mostra o modal para gerar o backup
                            alterarMostrarGerarBackup(true);
                        }}
                    >
                        <FontAwesomeIcon className="icone" icon={faPlus} />
                        <span>Novo</span>
                    </Button>
                </div>
            </div>
        </Card.Header>
        <div>
            {
                carregando ? <div className="p-5 text-center">
                    <FontAwesomeIcon icon={faSpinner} pulse /> Carregando...
                </div> : (
                    <div
                        className={'my-3'}
                    >
                    <BrowserView>
                        <table className="table table-hover tabela">
                            {totalRegistros > 0 && <>
                                {/* <thead>
                                    <tr>
                                        <th className={'text-center'}></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead> */}
                                <tbody>
                                    { retornaRegistros() }
                                </tbody>
                            </>}
                        </table>
                    </BrowserView>

                    <MobileView>
                        { retornaRegistros() }
                    </MobileView>
                </div>)
            }
            <Paginacao
                pagina={parametros.pagina}
                registrosPorPagina={parametros.registrosPorPagina}
                totalRegistros={totalRegistros}
                alterouPagina={(pagina) => {
                    alterarParametros({...parametros, pagina: pagina});
                }}
            />

            <div className='legenda'>
                <div className='backup-sucesso'>
                    <FontAwesomeIcon icon={faDatabase} fontSize={12}></FontAwesomeIcon>
                    Concluído
                </div>
                {/* <div className='backup-sem-arquivo'>Sem arquivo</div> */}
                <div className='backup-automatico'>
                    <FontAwesomeIcon icon={faDatabase} fontSize={12}></FontAwesomeIcon>
                    Automático
                </div>
                <div className='backup-gerando'>
                    <FontAwesomeIcon icon={faClock} fontSize={12}></FontAwesomeIcon>
                    Em andamento
                </div>
                <div className='backup-erro'>
                    <FontAwesomeIcon icon={faDatabase} fontSize={12}></FontAwesomeIcon>
                    Erro
                </div>
                <div className='backup-cancelado'>
                    <FontAwesomeIcon icon={faClock} fontSize={12}></FontAwesomeIcon>
                    Cancelado
                </div>
            </div>
        </div>
        
    </ListaBancosDadosComponent>
    
}