import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';

// componentes
import OptionSelect from './OptionSelect';

// combo para consulta de grupos de api
export default function ComboIntegracaoSistemas({
    valor = null,
    aoAlterar,
    isMulti = false,
    parametros = null,
    ...props
}){

    // estados
    const [ registros, alterarRegistros ] = useState([]);
    const [ selecionados, alterarSelecionados ] = useState([]);
    const [ carregando, alterarCarregando ] = useState(true);

    // consultar grupos de api
    async function consultar(pesquisa = null, callback = null){

        // carrega
        alterarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/integracaoSistemas`, {
                params: {
                    ...(parametros ?? {}),
                    pesquisa,
                    pagina: 1,
                    registrosPorPagina: 20
                }
            });

            // altera os dados
            alterarRegistros([...data.registros]);

            // verifica se possui função
            if(callback){

                // retorna
                callback(data.registros);
            }

        }catch({response}){

        }finally{
            
            alterarCarregando(false);
        }

    }

    // consulta membro
    async function consultarSelecionados(){

        // verifica se possui valor selecionado
        if(!valor || valor.length === 0){
            return;
        }

        // prepara
        alterarCarregando(true);
        
        try{
            
            // faz a consulta do id
            let { data } = await axios.get(`/integracaoSistemas`, {
                params: {
                    ids: isMulti ? valor : [valor]
                }
            });

            // altera os selecionados
            alterarSelecionados(data.registros);

        }catch({response}){

        }finally{
            alterarCarregando(false);
        }
    }

    // verifica se enviado o valor
    useEffect(() => {

        // pega dados
        let valoresSelecionados = selecionados.map((s) => s.dados.id);
        let alterouValor = !valor || (
            isMulti ? (
                valoresSelecionados.some((id) => !valor.includes(id))
                || valor.some((id) => !valoresSelecionados.includes(id))
            ) : valor !== (valoresSelecionados[0])
        );

        // verifica se alterado
        if(alterouValor){

            // cria o carregamento
            alterarCarregando(true);

            // verifica se possui usuário
            if(valor !== null){

                // consulta os dados do membro selecionado
                consultarSelecionados();

            }else{

                // define como vazio
                alterarSelecionados([]);

                // remove o carregamento
                alterarCarregando(false);
            }

        }

    }, [valor]);

    // ao iniciar / alterar parametros
    useEffect(() => {

        // consulta
        consultar();

    }, [parametros]);

    // ao alterar selecionados, retorna ids para o callback
    useEffect(() => {

        // pega os ids
        let idsSelecionados = selecionados.map((s) => s.dados.id);

        // retorna o array ou o id único
        aoAlterar(isMulti ? idsSelecionados : idsSelecionados[0]);

    }, [selecionados]);

    return <AsyncSelect
        isMulti={isMulti}
        isClearable
        isLoading={carregando}
        loadOptions={consultar}
        defaultOptions={registros}
        components={{
            Option: OptionSelect
        }}
        value={(() => {

            // prepara as opções
            let opcoesSelecionadas = selecionados.map((item) => {
                return {
                    value: item.dados.id,
                    label: <div className='d-flex align-items-center'>
                        {item.dados.nome}
                        <small className='ml-1'>({item.dados.tipo})</small>
                    </div>
                }
            });

            // retorna
            return isMulti ? opcoesSelecionadas : (opcoesSelecionadas[0] ?? null);

        })()}
        onChange={(novoValor, evento) => {
            
            // verifica o evento que ocorreu
            switch(evento.action){
                case 'pop-value':
                        
                    // remove o último elemento
                    selecionados.pop();
                    
                    // altera
                    alterarSelecionados([...selecionados]);

                    break;

                case 'remove-value':
                    // removendo uma opção

                    // remove a opção selecionada
                    alterarSelecionados(selecionados.filter((selecionado, a) => {

                        // verifica se é diferente
                        return (
                            selecionado.dados.id !== evento.removedValue.value
                        );
                    }));

                    break;

                case 'clear':
                    // limpando toda a seleção
    
                    // limpa os selecionados
                    alterarSelecionados([]);

                    break;

                case 'select-option':
                    // selecionou uma opção

                    // verifica se é múltiplo
                    if(isMulti){
                        
                        // pega a opção
                        let opcao = evento.option;

                        // encontra
                        let registroSelecionado = selecionados.find(s => {
                            return s.dados.id === opcao.dados.id;
                        });

                        // inclui se não já estiver incluido
                        if(!registroSelecionado){

                            // adiciona
                            alterarSelecionados([...selecionados, opcao]);
                        }
                    }else{

                        // define o novo valor
                        alterarSelecionados([novoValor]);
                    }
                    
                    break;
            }

        }}
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => 'Digite algo para fazer a consulta'}
        placeholder='Integrações'
        {...props}
    />
    
}
