import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, useRouteMatch, useHistory, useLocation } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import './PainelPagina.scss';
import io from 'socket.io-client';

// componentes
import PainelSidebar from './PainelSidebar';
import PainelNavbar from './PainelNavbar';
import Inicio from './Inicio';

import ClienteEscreverEmail from './Clientes/EscreverEmail';
import CaixaSaidaConsultar from './CaixaSaida/Consultar';
import CaixaSaidaEmailsLista from './CaixaSaida/EmailsLista';
import ModelosEmailConsultar from './ModelosEmail/Consultar';
import ModelosEmailCadastrar from './ModelosEmail/Cadastrar';
// import DesenvolvimentoConsultar from './Desenvolvimento/Consultar';
import DesenvolvimentoRelatorio from './Desenvolvimento/Relatorio';
import FuncionariosConsultar from './Funcionarios/Consultar';
import FuncionariosFicha from './Funcionarios/Ficha';
import FuncionariosPermissao from './Funcionarios/Permissao';
import HistoricoLogsConsultar from './HistoricoLogs/Consultar';
import Historico from './Historico';
import Configuracao from './Configuracao';
import ConfiguracaoTrello from './Configuracao/Trello';
import Relatorios from './Relatorios';
import UsuariosConsultar from './Usuarios/Consultar';
import DocumentacaoApiConsultar from './DocumentacaoApi/Consultar';
import DocumentacaoApiCadastrar from './DocumentacaoApi/Cadastrar';
import Servidores from './Servidores';
import DocumentacaoApiApisGruposConsultar from './DocumentacaoApi/ApisGrupos/Consultar';
import DocumentacaoApiApisGruposCadastrar from './DocumentacaoApi/ApisGrupos/Cadastrar';
import GruposChaveGoogle from './GruposChaveGoogle';

// empresas
import EmpresasConsultar from './Empresas/Consultar';
import EmpresasCadastrar from './Empresas/Cadastrar';
import EmpresasUsuariosConsultar from './EmpresasUsuarios/Consultar';
import EmpresasEscreverEmail from './Empresas/EscreverEmail';
import EmpresasEmailsMassa from './Empresas/EmailsMassa';

// notificações
import Notificacoes from './Notificacoes/Consultar';
import NotificacoesFicha from './Notificacoes/Ficha';

// notificações templates
import NotificacoesTemplates from './Notificacoes/NotificacoesTemplates';
import NotificacoesTemplatesFicha from './Notificacoes/NotificacoesTemplates/Ficha';

// icones
import { faBell, faChartBar, faCode, faDatabase, faKey, faLaptopHouse, faMapMarkedAlt, faServer } from '@fortawesome/free-solid-svg-icons';
import { ClientesIcone, UsuariosIcone, FuncionariosIcone, GraficoIcone, TrelloIcone, EmailIcone } from './../../icones/svg';

// cria contexto
import { PainelContexto } from './PainelContexto';
import Enderecos from './Enderecos';
import IntegracaoSistemas from './IntegracaoSistemas';
import EmpresasIntegracaoSistemas from './EmpresasIntegracaoSistemas';
import EmpresasAcessos from './EmpresasAcessos';
import ListaBancosDados from './ListaBancosDados';

export default function Painel(){

    // estados
    const { loginReducer, permissaoReducer } = useSelector(state => state);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    let { path, url } = useRouteMatch();
    let [liberado, alterarLiberado] = useState(false);
    let [menuEncolhido, encolherMenu] = useState(isMobile ? true : false);
    const socket = useRef(null);
    const [socketStatus, alterarSocketStatus] = useState(false);
    const [relatorioUsuarios, alterarRelatorioUsuarios] = useState(null);

    // consulta as permissões do usuário
    useEffect(() => {
        
        // altera token de usuário para as requisições
        axios.defaults.headers.common['tokenusuario'] = loginReducer.dadosUsuario.token;

        // permissões
        consultarPermissoes();

        /* para notificações */

        // cria o evento ao carregar a página
        document.addEventListener('DOMContentLoaded', function() {

            // verifica se possui notificação
            if(!Notification){

                // avisa
                alert('Desktop notifications not available in your browser. Try Chromium.');
                return;
            }
        
            // verifica se não permitido notificação
            if(Notification.permission !== 'granted'){

                // pede para permitir
                Notification.requestPermission();
            }
        });
        
    }, []);

    // inicializa sockets
    useEffect(() => {

        // inicializa
        socket.current = io(`${process.env.REACT_APP_URL_SOCKET}`, {
            transports: ['websocket'],
            query:`dispositivo=financeiro&id=${loginReducer.dadosUsuario.id}`,
            secure: true
        });

        // // viaturas online
        // socket.current.on('usuariosOnline', idsViaturas => {
        //     alterarViaturasOnline(idsViaturas);
        // });
        
        // desconectado
        return () => {
            socket.current.close();
        }

    }, []);

    // verifica se socket está online
    useEffect(() => {

        // define o status do socket
        socket.current.on('connect', statusOnlineSocket);
        socket.current.on('disconnect', statusOfflineSocket);

        // em caso de mudança do status, socket deverá ser destruido para ser reconsturido
        return () => {
            socket.current.off('connect', statusOnlineSocket);
            socket.current.off('disconnect', statusOfflineSocket);
        };
    }, [socketStatus]);
    
    // altera status do socket para online
    function statusOnlineSocket(){
        alterarSocketStatus(true);
    }

    // altera status do socket para offline
    function statusOfflineSocket(){
        alterarSocketStatus(false);
    }

    // função de atualização de relatórios
    useEffect(() => {
        socket.current.on('relatorioUsuarios', atualizarRelatorioUsuario);
        return () => {
            socket.current.off('relatorioUsuarios', atualizarRelatorioUsuario);
        };
    }, [relatorioUsuarios]);

    // função para notificar um novo pedido de integração
    const notificarNovoPedidoIntegracao = () => {

        // verifica a permissão do usuário
        if(permissaoReducer.acessoServidorClientes !== 'S'){
            return;
        }

        // notifica para o browser
        if(Notification.permission === 'granted'){

            // cria a notificação
            let browserNotification = new Notification(
                'Novo pedido de integração!',
                {
                    icon: 'https://suporte.sistemaggm.net.br/favicon.ico',
                    body: `
                        Foi realizado um novo pedido de integração.
                    `,
                }
            );

            // verifica se a página está focada
            let paginaFocada = document.hasFocus();

            // ao clicar
            browserNotification.onclick = () => {
                
                // prepara url para abrir
                const pathAbrir = `/painel/empresas/integracao/pedidosIntegracao`;

                // verifica se tem foco na página
                if(paginaFocada){

                    // verifica se é a mesma página
                    if(location.pathname === pathAbrir){

                        // atualiza a página
                        history.go(0);
                    }else{

                        // abre na própria guia
                        history.push(pathAbrir);
                    }

                }else{
                    
                    // vai para a guia de integrações
                    window.open(`${url.split(`/painel`)[0]}${pathAbrir}`);
                }
            };

        }else{
            // usar um toast?
        }

    };

    // função de atualização de relatórios
    useEffect(() => {
        socket.current.on('empresasNovoPedidoIntegracaoSistema', notificarNovoPedidoIntegracao);
        return () => {
            socket.current.off('empresasNovoPedidoIntegracaoSistema', notificarNovoPedidoIntegracao);
        };
    }, []);

    // pega os dados atualizado de relatório
    function atualizarRelatorioUsuario(novosDadosRelatorio){
        // console.log(novosDadosRelatorio);
        // relatorioUsuarios
        alterarRelatorioUsuarios(novosDadosRelatorio);

    }

    // consultar permissões do usuário
    async function consultarPermissoes(){

        try{

            let { data } = await axios.get(`/funcionario/permissao`);
            // console.log(data);
            // altera a configuração global
            dispatch({
                type: 'permissaoReducer/ATUALIZAR',
                dados: data
            });
        
            alterarLiberado(true);

        }catch({response}){

        }
    }

    // ajustando axios
    if(!liberado){
        // lu lulu se quiser criar um componente de carregando aqui nesse ponto, fica a vontade :D
        return <></>
    }

    return <PainelContexto.Provider value={{
        socketStatus, socket, relatorioUsuarios
    }}>
        
        <div className={['painel-pagina', (menuEncolhido)? 'menu-encolher' : '', isMobile ? 'menu-mobile' : ''].join(' ')}>
            
            <PainelSidebar 
                logo='Midialocal' 
                className={(menuEncolhido)? 'encolher' : ''} encolherMenu={() => encolherMenu(!menuEncolhido)} 
                links={[
                    {svg: ClientesIcone, descricao: 'Clientes', url: '/painel/empresas', cor: '#1fb61f'},
                    {svg: UsuariosIcone, descricao: 'Usuários', url: '/painel/empresas/usuarios', cor: '#1fb61f'},
                    {svg: EmailIcone, descricao: 'Caixa de Saída', url: '/painel/caixa-saida', cor: '#f1ad14'},
                    {svg: TrelloIcone, descricao: 'Desenvolvimento', url: '/painel/desenvolvimento', cor: '#f1ad14', habilitar: permissaoReducer.acessoDesenvolvimento === 'S' ? true : false},
                    {svg: FuncionariosIcone, descricao: 'Funcionários', url: '/painel/funcionarios', cor: '#f1ad14', habilitar: permissaoReducer.acessoFuncionarios === 'S' ? true : false},
                    {icone: faLaptopHouse, descricao: 'Acessos', url: '/painel/empresas/acessos', cor: '#26a27e', habilitar: permissaoReducer.acessoServidores === 'S'},
                    {svg: GraficoIcone, descricao: 'Relatórios', url: '/painel/relatorios', cor: '#f1ad14', habilitar: permissaoReducer.acessoRelatorios === 'S' ? true : false},
                    {icone: faMapMarkedAlt, descricao: 'Endereços', url: '/painel/enderecos/pesquisas', cor: '#6a66a5', habilitar: permissaoReducer.acessoRelatorios === 'S' ? true : false},
                    {icone: faBell, descricao: 'Notificações', url: '/painel/notificacoes', cor: '#9c94f0', habilitar: permissaoReducer.acessoServidores === 'S'},
                    {icone: faCode, descricao: 'Documentação Api', url: '/painel/documentacao-api', cor: '#707070'},
                    {icone: faServer, descricao: 'Servidores', url: '/painel/servidores', cor: '#2687a2', habilitar: permissaoReducer.acessoServidores === 'S'},
                    {icone: faDatabase, descricao: 'Bancos de dados', url: '/painel/bancosDados', cor: '#02485c', habilitar: permissaoReducer.acessoServidores === 'S'},
                    {icone: faChartBar, descricao: 'Integrações / bases', url: '/painel/empresas/integracao', cor: '#54a226', habilitar: permissaoReducer.acessoServidores === 'S'},
                    {icone: faKey, descricao: 'Grupos Chaves', url: '/painel/gruposChaveGoogle', cor: '#f7b806', habilitar: permissaoReducer.acessoGruposChaveGoogle === 'S'},
                ]} 
                
            />
            <div className={["painel-pagina-corpo", isMobile ? 'painel-mobile' : ''].join(' ')}>
                <div className={["content", isMobile ? 'p-0 pt-2' : ''].join(' ')}>
                    <div className="container-fluid">

                            <Route exact path={`${path}/`}>
                                <Inicio />
                            </Route>

                            {/* empresas */}
                            <Route exact path={`/painel/empresas`}>
                                <EmpresasConsultar />
                            </Route>
                            <Route exact path={[
                                `/painel/empresas/cadastrar`,
                                `/painel/empresas/editar/:id`,
                                `/painel/empresas/editar/:id/:tab`,
                                `/painel/empresas/editar/:id/:tab/cadastrar`,
                                `/painel/empresas/editar/:id/:tab/editar/:idUsuario`
                            ]}>
                                <EmpresasCadastrar />
                            </Route>

                            {/* empresas usuários */}
                            <Route exact path={[
                                `/painel/empresas/usuarios`,
                                `/painel/empresas/usuarios/cadastrar`,
                                `/painel/empresas/usuarios/editar/:idUsuario`,
                                // `/painel/empresas/editar/:idEmpresa/usuarios`,
                                // `/painel/empresas/editar/:idEmpresa/usuarios/cadastrar`,
                                // `/painel/empresas/editar/:idEmpresa/usuarios/editar/:idUsuario`
                            ]}>
                                <EmpresasUsuariosConsultar />
                            </Route>

                            {/* acessos das empresas */}
                            <Route
                                exact
                                path={[
                                    `/painel/empresas/acessos`,
                                    `/painel/empresas/acessos/:idEmpresa`
                                ]}
                            >
                                <EmpresasAcessos />
                            </Route>

                            {/* enviar email para empresas */}
                            <Route exact path={`/painel/empresas/emails-massa`}>
                                <EmpresasEmailsMassa />
                            </Route>

                            {/* enviar email para empresa */}
                            <Route exact path={`/painel/empresas/:idEmpresa/enviar-email`}>
                                <EmpresasEscreverEmail />
                            </Route>
                            
                            {/* enviar email para usuário */}
                            <Route exact path={`/painel/empresas/usuarios/:id/enviar-email`}>

                            </Route>

                            {/* clientes */}
                            {/* <Route exact path={`/painel/clientes`}>
                                <ClientesConsultar />
                            </Route> */}
                            {/* <Route exact path={[`/painel/clientes/cadastrar`, `/painel/clientes/editar/:id`, `/painel/clientes/editar/:id/:tab`]}>
                                <ClientesCadastrar />
                            </Route> */}
                            {/* <Route exact path={`/painel/clientes/emails-massa`}>
                                <ClientesEmailsMassa />
                            </Route> */}

                            <Route exact path={`/painel/usuarios`}>
                                <UsuariosConsultar />
                            </Route>

                            {/* {path: '/painel/clientes/abrir-email/:id', component: ClienteEscreverEmail, permitido: (login.permissao.clienteEnviarEmail === 'S')}, */}
                            {/* EMAILS */}
                            <Route exact path={`/painel/caixa-saida`}>
                                <CaixaSaidaConsultar />
                            </Route>
                            <Route exact path={`/painel/clientes/abrir-email/:id`}>
                                <ClienteEscreverEmail />
                            </Route>
                            <Route exact path={`/painel/email/lista`}>
                                <CaixaSaidaEmailsLista />
                            </Route>

                            {/* MODELOS DE EMAIL */}
                            <Route exact path={`/painel/modelos-email`}>
                                <ModelosEmailConsultar />
                            </Route>
                            <Route exact path={[`/painel/modelos-email/cadastrar`, `/painel/modelos-email/alterar/:id`]}>
                                <ModelosEmailCadastrar />
                            </Route>

                            {/* DESENVOLVIMENTO - TRELLO */}
                            { permissaoReducer.acessoDesenvolvimento === 'S' &&
                                <Route exact path={`/painel/desenvolvimento`}>
                                    <DesenvolvimentoRelatorio />
                                </Route>
                            }

                            {/* FUNCIONARIOS */}
                            { permissaoReducer.acessoFuncionarios === 'S' &&
                                <>
                                    <Route hidden={true} exact path={`/painel/funcionarios`}>
                                        <FuncionariosConsultar />
                                    </Route>
                                    <Route exact path={[`/painel/funcionarios/cadastrar`, `/painel/funcionarios/:id`]}>
                                        <FuncionariosFicha />
                                    </Route>
                                </>
                            }

                            <Route exact path={[`/painel/perfil`]}>
                                <FuncionariosFicha perfil="S" />
                            </Route>
    
                            {permissaoReducer.acessoConfiguracaoPermissao === 'S' &&
                                <Route exact path={`/painel/permissao/funcionario/:id`}>
                                    <FuncionariosPermissao />
                                </Route>
                            }

                            {/* HISTORICO */}
                            <Route
                                exact 
                                path={[
                                    `/painel/historico`,
                                    `/painel/empresas/editar/:idEmpresa/historico`
                                ]}
                            >
                                <Historico />
                            </Route>

                            {/* HISTORICO LOGS */}
                            <Route exact path={`/painel/historico/logs`}>
                                <HistoricoLogsConsultar />
                            </Route>

                            {/* CONFIGURAÇÕES */}
                            <Route exact path={`/painel/configuracao`}>
                                <Configuracao />
                            </Route>

                            { permissaoReducer.habilitarConfiguracaoTrello === 'S' &&
                                <Route exact path={`/painel/configuracao/trello`}>
                                    <ConfiguracaoTrello />
                                </Route>
                            }

                            {/* RELATÓRIOS */}
                            { permissaoReducer.acessoRelatorios === 'S' &&
                                <>
                                    <Route hidden={true} exact path={`/painel/relatorios`}>
                                        <Relatorios />
                                    </Route>
                                    
                                    <Route hidden={true} exact path={[`/painel/enderecos`, `/painel/enderecos/:tab`, `/painel/enderecos/:tab/*`]}>
                                        <Enderecos />
                                    </Route>
                                </>
                            }
                            
                            {/* DOCUMENTAÇÃO API */}
                            <Route exact path={`/painel/documentacao-api`}>
                                <DocumentacaoApiConsultar />
                            </Route>
                            <Route exact path={[`/painel/documentacao-api/cadastrar`, `/painel/documentacao-api/alterar/:id`]}>
                                <DocumentacaoApiCadastrar />
                            </Route>

                            <Route exact path={`/painel/documentacao-api/grupos`}>
                                <DocumentacaoApiApisGruposConsultar />
                            </Route>
                            <Route exact path={[`/painel/documentacao-api/grupos/cadastrar`, `/painel/documentacao-api/grupos/alterar/:id`]}>
                                <DocumentacaoApiApisGruposCadastrar />
                            </Route>
                            
                            { permissaoReducer.acessoServidores === 'S' &&
                                <Route exact path={[
                                    `/painel/servidores`,
                                    `/painel/servidores/cadastrar`,
                                    `/painel/servidores/editar/:id`,
                                    `/painel/servidores/:tab`,
                                    `/painel/servidores/:tab/historico`
                                ]}>
                                    <Servidores />
                                </Route>
                            }

                            {/* bancos de dados  */}
                            { permissaoReducer.acessoServidores === 'S' &&
                                <Route
                                    exact
                                    path={[
                                        `/painel/bancosDados`,
                                        `/painel/bancosDados/cadastrar`,
                                        `/painel/bancosDados/:id`
                                    ]}
                                >
                                    <ListaBancosDados />
                                </Route>
                            }

                            { permissaoReducer.acessoGruposChaveGoogle === 'S' &&
                                <Route path={[`/painel/gruposChaveGoogle`]}>
                                    <GruposChaveGoogle />
                                </Route>
                            }

                            {/* notificações */}
                            <Route exact path={[`/painel/notificacoes`]}>
                                <Notificacoes />
                            </Route>

                            <Route exact path={[`/painel/notificacoes/cadastrar`, `/painel/notificacoes/editar/:id`]}>
                                <NotificacoesFicha />
                            </Route>
                            
                            {/* notificações */}
                            <Route exact path={[`/painel/notificacoes/templates`]}>
                                <NotificacoesTemplates />
                            </Route>

                            <Route exact path={[`/painel/notificacoes/templates/cadastrar`, `/painel/notificacoes/templates/editar/:id`]}>
                                <NotificacoesTemplatesFicha />
                            </Route>

                            {/* pedidos de integração */}
                            <Route
                                exact
                                path={[
                                    `/painel/empresas/integracao`,
                                    `/painel/empresas/integracao/:tab`,
                                    `/painel/empresas/integracao/:tab/*`,
                                ]}
                            >
                                <IntegracaoSistemas />
                            </Route>

                            {/* integrações da empresa */}
                            <Route
                                path={[
                                    `/painel/empresas/editar/:idEmpresa/integracaoSistemas`
                                ]}
                            >
                                <EmpresasIntegracaoSistemas />
                            </Route>
                            {/* CONFIGURAÇÕES */}
                            {/* <Route exact path={`${path}/configuracao`}>
                                <Configuracao />
                            </Route> */}
                            
                    </div>
                </div>
            </div>
            
            <PainelNavbar 
                menuEncolhido={menuEncolhido}
                encolherMenu={encolherMenu}
            />
        </div>

    </PainelContexto.Provider>
}