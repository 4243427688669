import styled from 'styled-components';
import { Card } from 'react-bootstrap';
import { ListaComponent } from '../components';

export const ListaGoogleChavesComponent = styled(ListaComponent).attrs({
    className: `painel-consulta border-0`
})`
    
    .tabela{

        .registro{
            --cor-status-registro: #d3d3d3;
            
            td{
                
                .quantidade-empresas{
                    background-color: #099d95;
                    color: white;
                }
            }
        }
    }

`;