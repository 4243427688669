import { faCancel, faClock, faDatabase, faDownload, faPencilAlt, faShare, faToggleOff, faToggleOn, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { isBrowser, isMobile } from "react-device-detect";
import { Badge, Card, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BotaoTabela from "../../../../Componentes/BotaoTabela";
import { formatarTamanhoBytes } from "../../../../Funcoes";
import moment from "moment";

export default function Registro({
    dados,
    arquivo,

    clicouCancelar,
    clicouSubir,
    clicouDownload,
    clicouExcluir
}){

    // retorna opções
    let retornarOpcoes = () => {

        // retorna componentes
        return <>
            {
                (
                    dados.finalizado === 'N' && dados.cancelado === 'N'
                ) && <BotaoTabela 
                    onClick={clicouCancelar}
                    icone={faCancel}
                >
                    <span>Cancelar</span>
                </BotaoTabela>
            }
            {
                (
                    dados.finalizado === 'S' && !dados.erro
                ) && <BotaoTabela 
                    onClick={clicouSubir}
                    icone={faShare}
                >
                    <span>Subir backup</span>
                </BotaoTabela>
            }
            {
                (
                    dados.finalizado === 'S' && !dados.erro
                ) && <BotaoTabela 
                    onClick={clicouDownload}
                    icone={faDownload}
                >
                    <span>Download</span>
                </BotaoTabela>
            }
            {
                (
                    dados.finalizado === 'S' || dados.cancelado === 'S'
                ) &&
                <BotaoTabela
                    onClick={clicouExcluir}
                    icone={faTrashAlt}
                >
                    <span>Excluir</span>
                </BotaoTabela>
            }
        </>
    }

    // prepara o ícone
    let icone = faDatabase;
    let descricao = <>Backup na Amazon</>;
    let info = (
        arquivo ? <>
            {arquivo.nome}.{arquivo.extensao} | <b>{formatarTamanhoBytes(arquivo.tamanho)}</b>
        </> : ``
    );
    let classe = (arquivo ? 'backup-sucesso' : 'backup-sem-arquivo');

    // verifica se é backup autromatico
    if(dados.automatico === 'S'){
        
        // backup automático
        classe += ' backup-automatico';
    }

    // se não finalizado
    if(dados.finalizado === 'N'){

        // define
        icone = faClock;
        descricao = <i>Gerando backup...</i>;
        classe += ' backup-gerando';
    }

    // verifica se encontrou erros
    if(!!dados.erro){
        
        // define a descrição
        descricao = <>ERRO AO GERAR</>;
        info = <>
            <b>Ocorreram erros ao gerar backup...</b> <pre>{dados.erro}</pre>
        </>;
        classe = 'backup-erro';
    }

    // verifica se cancelado
    if(dados.cancelado === 'S'){

        // pega o ícone
        icone = faClock;
        descricao = <>Backup cancelado</>;
        classe = 'backup-cancelado';
    }

    return <> 

        {/* NAVEGADOR */}
        { isBrowser &&
            <tr
                style={{cursor: 'pointer'}}
                onClick={clicouDownload}
                className={['registro', classe].join(' ')}
            >
                <td className={'icone estreito text-center font-weight-bold'}>
                    <FontAwesomeIcon icon={icone} fontSize={18}></FontAwesomeIcon>
                </td>
                <td>
                    <div>
                        <span className="destaque">{moment(dados.dataCadastro).format('DD/MM/YYYY [às] HH:mm')} <b> - {descricao}</b></span><br/>
                        <small>{info}</small>
                    </div>
                    {dados.finalizado === 'N' && <div>
                        <ProgressBar animated={dados.cancelado !== 'S'} now={dados.porcentagem} label={`${parseFloat(dados.porcentagem).toFixed(2)}%`}></ProgressBar>
                    </div>}
                </td>
                <td className={'text-nowrap estreito'}>
                    { retornarOpcoes() }
                </td>
            </tr>
        }

        {/* MOBILE */}
        {isMobile &&
            <Card
                className={'border-0 bg-light mb-2'}
            >
                <Card.Body className={'p-2'}>
                    <div className={[isBrowser ? 'd-flex' : ''].join(' ')}>
                        <div className={'flex-grow-1'}>
                            <div className="d-flex align-items-center" style={{gap: 3}}>
                                {moment(dados.dataCadastro).format('DD/MM/YYYY [às] HH:mm')} <b> - {descricao}</b><br/>
                                <small>{info}</small>
                            </div>
                        </div>
                        <div className={'text-right'}>
                            { retornarOpcoes() }
                        </div>
                    </div>
                </Card.Body>
            </Card>
        }
    </>
}