export default function Registro({
    indice,
    dados,
    dadosEmpresa,

    clicouRegistro
}){

    return <> 

        <tr
            className={['registro'].join(' ')}
            style={{cursor: 'pointer'}}
            onClick={clicouRegistro}
        >

            <td className={'text-center font-weight-bold indice'}>
                {indice}
            </td>

            <td className="descricao">
                <div className="d-flex align-items-center" style={{gap: 3}}>
                    {dadosEmpresa.razaoSocial} <small>({dadosEmpresa.codigo})</small>
                </div>
                <div className="totais">
                    <small className="geral">
                        <b>Tamanho: </b> {dados.tamanho} MB
                    </small>
                    <small className="v1">
                        <b>Cabos: </b> {dados.dados.tabelas.cabos.tamanho} MB | {dados.dados.tabelas.cabos.registros.total} registros
                    </small>
                    <small className="v2">
                        <b>Equipamentos: </b> {dados.dados.tabelas.equipamentos.tamanho} MB | {dados.dados.tabelas.equipamentos.registros.total} registros
                    </small>
                    <small className="v3">
                        <b>Itens rede: </b> {dados.dados.tabelas.itensRede.tamanho} MB | {dados.dados.tabelas.itensRede.registros.total} registros
                    </small>
                </div>
            </td>
        </tr>
    </>
}