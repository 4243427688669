
import { useRef, useState } from 'react';
import { Button, Col, Form, Row, Popover, FormCheck } from 'react-bootstrap';
import Select from 'react-select';

import { ModalFiltrosComponent, PopoverFiltrosComponent } from './components';

import InputDataCombo from "../../../../../Componentes/InputDataCombo";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { intersectObject } from '../../../../../Funcoes';

export default function ModalFiltros({
    elemento,
    mostrar,
    alterarMostrar,
    parametros,
    alterarParametros
}){

    // estados
    const filtrosPadrao = {
        pesquisa: null,
        dataInicio: null,
        dataFinal: null,
        ordenarCampos: ['total']
    };
    const [ filtros, alterarFiltros ] = useState(intersectObject({...filtrosPadrao}, parametros ?? {}));
    const refs = {
        dataInicioCol: useRef(null),
        dataFinalCol: useRef(null)
    };

    const ordenarCampos = [
        {label: 'Tamanho', value: 'tamanhoTotal'},
        {label: 'total', value: 'total'}
    ];

    // prepara filtro de público/privado
    const simNao = [
        {label: 'Sim', value: 'S'},
        {label: 'Não', value: 'N'}
    ];

    // função para limpar os filtros
    function limparFiltros(){

        // prepara
        let filtrosAlterar = {...filtros};

        // percorre filtros
        Object.keys(filtros).map((filtro) => {

            // limpa
            filtrosAlterar[filtro] = filtrosPadrao[filtro] ?? null;

        });

        // altera
        alterarFiltros(filtrosAlterar);
        // alterarParametros({...parametros, ...filtrosAlterar});

    }

    // função para cancelar (sair sem aplicar)
    function cancelar(){

        // fecha
        alterarMostrar(false);

        // altera os filtros para o estado inicial
        let filtrosAlterar = {...filtros};

        // percorre
        Object.keys(filtros).map((filtro) => {

            // altera
            filtrosAlterar[filtro] = parametros[filtro] ?? filtrosPadrao[filtro] ?? null;
        });

        // altera os filtros
        alterarFiltros(filtrosAlterar);

    }

    return <ModalFiltrosComponent
        show={mostrar}
        target={elemento}
        placement="bottom"
        rootClose
        onHide={() => {
            cancelar();
        }}
    >
        <PopoverFiltrosComponent
            size={'sm'}
            title={'Filtros'}
        >
            <Popover.Title>
                Filtros
                <Button
                    size="sm"
                    name="botao-limpar"
                    onClick={() => {

                        // limpa filtros
                        limparFiltros();
                    }}
                >
                    Limpar
                </Button>
            </Popover.Title>
            <Popover.Content>
                <Row>
                    <Col>
                        <Form.Label>Pesquisa <small>(empresa)</small></Form.Label>
                        <Form.Control 
                            value={filtros.pesquisa || ''}
                            onChange={e => {

                                // altera
                                alterarFiltros({...filtros, pesquisa: e.target.value});
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>Data</Form.Label>
                        <Row>
                            <Col className='position-relative' ref={refs.dataInicioCol}>
                                {refs.dataInicioCol.current && <InputDataCombo
                                    size="sm"
                                    placeholder="A partir de"
                                    data={filtros.dataInicio}
                                    onChange={(novaData) => {
                                        alterarFiltros({...filtros, dataInicio: novaData})
                                    }}
                                    options={{
                                        appendTo: refs.dataInicioCol.current
                                    }}
                                />}
                            </Col>
                            <Col className='position-relative' ref={refs.dataFinalCol}>
                                {refs.dataFinalCol.current && <InputDataCombo
                                    size="sm"
                                    placeholder="Até"
                                    data={filtros.dataFinal}
                                    onChange={(novaData) => {
                                        alterarFiltros({...filtros, dataFinal: novaData})
                                    }}
                                    options={{
                                        appendTo: refs.dataFinalCol.current
                                    }}
                                />}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Form.Label>
                            <span className='d-flex align-items-center' style={{gap: '0.125em'}}>
                                <FontAwesomeIcon color="#404040" icon={faSortAmountDown} />
                                Ordenar por
                            </span>
                        </Form.Label>
                        <FormCheck
                            label="Total"
                            id={'ordenarCamposTotal'}
                            name={'ordenarCamposTotal'}
                            type={'radio'}
                            onChange={() => {
                                alterarFiltros({
                                    ...filtros,
                                    ordenarCampos: ['total']
                                })
                            }}
                            checked={filtros.ordenarCampos && filtros.ordenarCampos.includes('total')}
                        />
                        <FormCheck
                            label="Tamanho"
                            id={'ordenarCamposTamanho'}
                            name={'ordenarCamposTamanho'}
                            type={'radio'}
                            onChange={() => {
                                alterarFiltros({
                                    ...filtros,
                                    ordenarCampos: ['tamanhoTotal']
                                })
                            }}
                            checked={filtros.ordenarCampos && filtros.ordenarCampos.includes('tamanhoTotal')}
                        />
                    </Col>
                </Row>

                <div className='footer'>
                    <Button
                        size="sm"
                        variant={'light'}
                        onClick={() => {

                            // cancela
                            cancelar();
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        size="sm"
                        variant={'success'}
                        onClick={() => {

                            // altera os parâmetros
                            alterarParametros({...parametros, ...filtros, pagina: 1});
                        }}
                    >
                        Aplicar
                    </Button>
                </div>
            </Popover.Content>
        </PopoverFiltrosComponent>
    </ModalFiltrosComponent>
    
}