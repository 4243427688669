import moment from "moment";
import { Form, Modal, Row, Col } from "react-bootstrap";
import Select from 'react-select';
import InputDataCombo from "../../../../../Componentes/InputDataCombo";

import { FormularioVisualizacaoComponent } from "./components";

export default function FormularioVisualizacao({
    dados,

    mostrar=true,
    fechar
}){

    // array para o select de modo
    const modosCliente = [
        {value: 'cliente', label: 'Cliente'},
        {value: 'parceiro', label: 'Parceiro'},
        {value: 'demo', label: 'Demo'},
        {value: 'bloqueado', label: 'Bloqueado'},
        {value: 'cancelado', label: 'Cancelado'},
        {value: 'nao-ativo', label: 'Não ativo'}
    ];
    const planosSistema = [
        {value: 'padrao', label: 'Padrão'},
        {value: 'light', label: 'Light'}
    ];

    // pega dados
    const dadosSalvos = dados ? dados.dados : null;
    const dadosEmpresa = dadosSalvos ? dadosSalvos.dados : null;
    let modoInicial = dadosEmpresa ? modosCliente.find((m) => m.value === dadosEmpresa.situacao) : null;
    let planoSistemaInicial = dadosEmpresa ? planosSistema.find((m) => m.value === dadosEmpresa.planoSistema) : null;

    return <FormularioVisualizacaoComponent
        show={mostrar}
        onHide={() => {
            fechar();
        }}
        backdrop={true}
        size={'lg'}
    >
        {!dados ? <></> : <>
            <Modal.Header>
                <Modal.Title>Pedido de {moment().format('DD/MM/YYYY')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className={['formulario'].join(' ')}>
                    <Form.Row>
                        <Col>
                            <Form.Label>Data pedido</Form.Label>
                            <InputDataCombo 
                                size="sm"
                                disabled={true}
                                data={dados.dataPedido}
                            />
                        </Col>
                        <Col>
                            <Form.Label>Data fim do teste</Form.Label>
                            <InputDataCombo 
                                size="sm"
                                disabled={true}
                                data={dados.dataFimTeste}
                            />
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Label>Razão social</Form.Label>
                            <Form.Control 
                                className={['input'].join(' ')}
                                value={dadosEmpresa.razaoSocial || ''}
                                disabled
                            />
                        </Col>
                        <Col>
                            <Form.Label>CNPJ</Form.Label>
                            <Form.Control 
                                className={['input'].join(' ')}
                                value={dadosEmpresa.cnpj || ''}
                                disabled
                            />
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Label>Nome fantasia</Form.Label>
                            <Form.Control 
                                className={['input'].join(' ')}
                                value={dadosEmpresa.nomeFantasia || ''}
                                disabled
                            />
                        </Col>
                        <Col>
                            <Form.Row>
                                <Col>
                                    <Form.Label>Situação</Form.Label>
                                    <Select
                                        className="select-ficha"
                                        isDisabled={true}
                                        options={modosCliente}
                                        defaultValue={modoInicial}
                                        isMulti={false}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Plano sistema</Form.Label>
                                    <Select
                                        className="select-ficha"
                                        isDisabled={true}
                                        options={planosSistema}
                                        defaultValue={planoSistemaInicial}
                                        isMulti={false}
                                    />
                                </Col>
                            </Form.Row>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Label>Responsável</Form.Label>
                            <Form.Control 
                                className={['input'].join(' ')}
                                value={dadosEmpresa.responsavel || ''}
                                disabled
                            />
                        </Col>
                        
                        <Col>
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control 
                                className={['input'].join(' ')}
                                value={dadosEmpresa.email || ''}
                                disabled
                            />
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Label>Data pedido</Form.Label>
                            <InputDataCombo 
                                size="sm"
                                disabled={true}
                                data={dadosEmpresa.dataPedidoTeste}
                            />
                        </Col>
                        <Col>
                            <Form.Label>Data cliente</Form.Label>
                            <InputDataCombo 
                                size="sm"
                                disabled={true}
                                data={(dadosEmpresa.dataCliente && dadosEmpresa.dataCliente !== '0000-00-00') ? dadosEmpresa.dataCliente : ''}
                            />
                        </Col>
                        <Col>
                            {
                                dadosEmpresa.modo !== 'cancelado' ?
                                <>
                                    <Form.Label>Expira em</Form.Label>
                                    <InputDataCombo 
                                        size="sm"
                                        disabled={true}
                                        data={(dadosEmpresa.expiraEm && dadosEmpresa.expiraEm !== '0000-00-00') ? dadosEmpresa.expiraEm : ''}
                                    />
                                </>
                                    :
                                <>
                                    <Form.Label>Data cancelado</Form.Label>
                                    <InputDataCombo 
                                        size="sm"
                                        disabled={true}
                                        data={(dadosEmpresa.dataCancelado && dadosEmpresa.dataCancelado !== '0000-00-00') ? dadosEmpresa.dataCancelado : ''}
                                    />
                                </>
                            }
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Label>Responsável financeiro</Form.Label>
                            <Form.Control 
                                className={['input'].join(' ')}
                                value={dadosEmpresa.responsavelFinanceiro || ''}
                                disabled
                            />
                        </Col>
                        <Col>
                            <Form.Label>E-mail financeiro</Form.Label>
                            <Form.Control 
                                className={['input'].join(' ')}
                                value={dadosEmpresa.emailFinanceiro || ''}
                                disabled
                            />
                        </Col>
                    </Form.Row>
                    <Row className="m-1 my-2">
                        <Form.Label>Obs. Comercial</Form.Label>
                        <textarea 
                            style={{minHeight: 100, fontSize: 14}}
                            value={dadosEmpresa.obsComercial || ''}
                            disabled
                            className="form-control" 
                        ></textarea>
                    </Row>
                    <Row className="m-1 my-2">
                        <Form.Label>Obs.</Form.Label>
                        <textarea 
                            style={{minHeight: 100, fontSize: 14}}
                            value={dadosEmpresa.obs || ''}
                            disabled
                            className="form-control" 
                        ></textarea>
                    </Row>
                </Form.Group>
            </Modal.Body>
        </>}
    </FormularioVisualizacaoComponent>
}