import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Row, Col, Form, Button, InputGroup, Tabs, Tab, Spinner} from 'react-bootstrap';
import { toast } from 'react-toastify';
import useIsMounted from 'ismounted';
import {
  BarChart, Brush, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer
} from 'recharts';

import GraficoPie from '../GraficoPie';


// escreve o email
export default function CardDadosEmpresa({
	dadosEmpresa,
	parametros,
	idEmpresa
}){


    // dados
    const permissaoReducer = useSelector(state => state.permissaoReducer);

    const [dados, alterarDados] = useState(null);
    const [dadosPie, alterarDadosPie] = useState(null);
    const [carregando, alterarCarregando] = useState(true);

    // consulta os dados de acessos de usuários da empresa selecionada
    async function consultar(){

		// mostra carregamento
		alterarCarregando(true);

        try{
            
            // faz a requisição
            let { data } = await axios.get(`relatorios/acessos/empresas/${idEmpresa}/usuarios`, {
                params: parametros
            });
			
			// altera os dados e a seleção da empresa
			alterarDados(data)

        }catch(e){
            console.log('pegou o erro')
        }finally{
            alterarCarregando(false);
        }

	}
	
    // função para montar os dados do gráfico
	const retornaDadosPie = (dados) => {

        // ordena o array
		let dadosPieLocal = dados.concat();
		dadosPieLocal.sort((a, b) => {
			return b.acessos - a.acessos
		})

        // pega 5 primeiros e monta os dados para o gráfico pie
		dadosPieLocal = dadosPieLocal.filter((e, index) => index < 5).map((e) => {
			return {
				name: e.usuario,
				value: parseInt(e.total_acessos)
			}
		})

		return dadosPieLocal;
	}

	const retornaDadosGrafico = (empresas) => {

		let dataGrafico = empresas.map((empresa) => {

			let dadosFormatado = {
				name: empresa.nome_fantasia,
				acessos: empresa.acessos,
				atualizacoes: empresa.atualizacoes_pagina
			}

			return dadosFormatado;
		})

		return dataGrafico;

	}

    // inicializa
    useEffect(() => {

		// consulta acessos de empresa
		consultar()

    }, [parametros, idEmpresa]);

    return <>
		<Card className="border-0">
			<Card.Header className='mb-0 border-0 bg-white'>
				{dadosEmpresa && dadosEmpresa.nome_fantasia}
			</Card.Header>
			<Card.Body>

				{
					carregando  &&
					<Col align="center">
						<Spinner animation="border" />
					</Col>
				}
				{
					(!carregando && dados) &&
					<Col>
						<Row>
							<Col>
								<b>Total</b>
								<Row>
									{dados.totais.total_acessos} acessos
								</Row>
							</Col>
							<Col>
								<Row>
									Login: {dados.totais.acessos}
								</Row>
								<Row>
									Atualizações: {dados.totais.atualizacoes_pagina}
								</Row>
								<Row>
									Novas guias: {dados.totais.novas_guias}
								</Row>
								<Row>
									Mapa: {dados.totais.carregamentos_mapa}
								</Row>
							</Col>
						</Row>
						<hr/>
						<Row className="justify-content-center">
							<GraficoPie
								dadosGrafico={retornaDadosPie(dados.dados)}
								config={{
									tamanho: 250
								}}
							/>
						</Row>
					</Col>
				}
			</Card.Body>
		</Card>
	</>
}