import styled from 'styled-components';


export const FichaClienteComponent = styled.div.attrs({
    className: ``
})`
    --cor-cliente-ficha: var(--cor-demo-em-teste);

    &.cliente{
        --cor-cliente-ficha: var(--cor-cliente);
    }

    &.clientelight{
        --cor-cliente-ficha: var(--cor-clientelight);
    }

    &.demo{
        --cor-cliente-ficha: var(--cor-demo);
    }

    &.demolight{
        --cor-cliente-ficha: var(--cor-demolight);
    }

    &.demoEmTeste{
        --cor-cliente-ficha: var(--cor-demo-em-teste);
    }

    &.demolightEmTeste{
        --cor-cliente-ficha: var(--cor-demolight-em-teste);
    }

    &.parceiro{
        --cor-cliente-ficha: var(--cor-parceiro);
    }

    &.bloqueado{
        --cor-cliente-ficha: var(--cor-bloqueado);
    }

    &.cancelado{
        --cor-cliente-ficha: var(--cor-cancelado);
    }

    &.semFatura{
        --cor-cliente-ficha: var(--cor-sem-fatura);
    }

    .form-label, .form-check-label{
        font-size: 0.8rem;
    }

    .form-check, span{
        font-size: 0.9rem;
    }

    .card-body{
        padding-top: 0.5rem;
    }

    .select-ficha, .aba-usuarios .select{
        font-size: 0.875rem;

        > div:nth-of-type(1){
            padding: 0;
            min-height: unset;

            > div{
                padding: 0 8px;

                &:last-child{
                    padding-right: 4px;
                }

            }

            > div:nth-of-type(2){
                > div{
                    padding: 0 8px;
                }
            }
        }
    }

    .form-control{
        min-height: unset;
        height: 31px;
    }

    .btn{
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
    }

    .custom-switch{
        font-size: 0.9rem;
        padding-left: 2.25em;

        .custom-control-input{
            width: 1em;
            height: 1.25em;
            
            &:checked ~ .custom-control-label::after{
                transform: translateX(0.75em);
            }
        }
        .custom-control-label{
            &::before{    
                top: 0.25em;
                left: 1.5em;
                display: block;
                width: 1em;
                height: 1em;
                
                left: -2.25em;
                width: 1.75em;
                border-radius: 0.5em;
                
            }

            &::after{
                top: calc(0.25em + 2px);
                left: calc(-2.25em + 2px);
                width: calc(1em - 4px);
                height: calc(1em - 4px);
                border-radius: 0.5em;
            }
        }
    }

    .card{

        > .card-header{
            border-left: 3px solid var(--cor-cliente-ficha) !important;

            &.borda-alerta{
                border-left: 3px solid #ffc107 !important;
            }
        }

        .card{
            > .card-header{
                border-left: unset !important;
            }
        }
    }

    .tab-content{
        position: relative;

        .cor-modo-cliente{
            position: absolute;
            top: 0;
            left: 0;
            font-size: 23px;
            opacity: 0.8;
            margin: -1px -1px;
            filter: drop-shadow(0px 0px 2px grey);
        }
    }

    .label-cnpj-titularidade-anterior{
        position: absolute;
        bottom: 100%;
        margin-bottom: 0.2rem;
    }

    .check-mostrar-cnpj-titularidade{
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 1;
        font-size: 0.8em;
        padding-left: 0;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 0.3em;

        input{
            position: relative;
            margin: 0;
            height: 12px;
        }

        label{
            font-size: 0.7rem;
        }
    }
`