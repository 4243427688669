import { useEffect, useState } from "react";
import axios from "axios";
import Chart from "react-apexcharts";

import { Card, Col, Form, Row } from "react-bootstrap";

import { RelatoriosEnderecosPesquisasComponent } from "./components";
import InputDataCombo from "../../../Componentes/InputDataCombo";
import { FichaComponent } from "../../../Componentes/components";
import moment from "moment";

export default function RelatoriosEnderecosPesquisas(){

    // estados
    const [ carregando, alterarCarregando ] = useState(false);
    const [ parametros, alterarParametros ] = useState({
        dataInicio: moment().format(`YYYY-MM-DD`),
        dataFinal: null
    });
    const [ totais, alterarTotais ] = useState({
		pesquisas: 0,
		pesquisasRetornoInterno: 0,
		pesquisasRetornoInternoEncontrado: 0,
		pesquisasRetornoInternoEncontradoAceito: 0,
		pesquisasRetornoGoogle: 0,
		pesquisasRetornoGoogleEncontrado: 0,
		pesquisasRetornoGoogleUsadoGoogleChaveClienteEncontrado: 0,
		pesquisasRetornoReutilizado: 0,
		pesquisasRetornoReutilizadoEncontrado: 0,
		pesquisasRetornoRegistrado: 0,
		pesquisasRetornoEncontrado: 0,
		pesquisasRetornoNaoEncontrado: 0
	});
    const [ labels, alterarLabels ] = useState([]);
    const [ series, alterarSeries ] = useState([]);

    useEffect(() => {

        // consulta
        consultar();
        
    }, [parametros]);

    // função para a consulta
    async function consultar(){
     
        // cria o carregamento
        alterarCarregando(true);

        try{
            
            // consulta
            let { data } = await axios.get('/enderecos/pesquisas/relatorio', {
                params: {
                    ...parametros
                }
            });

            // define os totais
            alterarTotais(data.totais);

            alterarLabels([
                'Base interna',
                'Reutilizado',
                'Google'
            ]);
            alterarSeries([
                parseInt(data.totais.pesquisasRetornoInternoEncontradoAceito),
                parseInt(data.totais.pesquisasRetornoReutilizadoEncontrado),
                parseInt(data.totais.pesquisasRetornoGoogleEncontrado)
            ]);

        }catch(e){
            // erro
        }finally{

            // remove o carregamento
            alterarCarregando(false);
        }
    }

    return <RelatoriosEnderecosPesquisasComponent>
        {/* <Col className="py-2 px-0"> */}
            <Row className="px-2" style={{gap: '1rem', height: '100%'}}>
                <Col className="px-0">
                    <Card className="m-0 card-totais-gerais">
                        <Card.Body>
                            <FichaComponent>
                                <Row>
                                    <Col className='position-relative'>
                                        <Form.Label>De: </Form.Label>
                                        <InputDataCombo
                                            size="sm"
                                            placeholder="A partir de"
                                            data={parametros.dataInicio}
                                            onChange={(novaData) => {
                                                alterarParametros({...parametros, dataInicio: novaData})
                                            }}
                                        />
                                    </Col>
                                    <Col className='position-relative'>
                                        <Form.Label>Até: </Form.Label>
                                        <InputDataCombo
                                            size="sm"
                                            placeholder="Até"
                                            data={parametros.dataFinal}
                                            onChange={(novaData) => {
                                                alterarParametros({...parametros, dataFinal: novaData})
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FichaComponent>
                            <div className="total-grupo">
                                <div className="total-geral">
                                    <span className={"total integracao"}>
                                        {totais.pesquisas}
                                    </span>
                                    pesquisas
                                </div>
                                <div className="sub-total">
                                    <div className="total-geral">
                                        <span className={"total integracao-sistemas"}>
                                            {totais.pesquisasRetornoEncontrado}
                                        </span>
                                        encontradas
                                    </div>
                                    {/* <div className="total-geral">
                                        <span className={"total empresas-integrando"}>
                                            {totais.pesquisasRetornoNaoEncontrado}
                                        </span>
                                        não encontradas
                                    </div> */}
                                    <div className="total-geral">
                                        <span className={"total empresas-integradas"}>
                                            {totais.pesquisasRetornoReutilizadoEncontrado}
                                        </span>
                                        retornos reutilizados
                                    </div>
                                </div>
                            </div>
                            <div className="total-grupo">
                                <div className="total-geral">
                                    <span className={"total empresas-integradas"}>
                                        {totais.pesquisasRetornoInternoEncontrado}
                                    </span>
                                    encontradas interno
                                </div>
                                <div className="sub-total">
                                    <div className="total-geral">
                                        <span className={"total empresas-integrando"}>
                                            {totais.pesquisasRetornoInternoEncontradoAceito}
                                        </span>
                                        aceitas
                                    </div>
                                    <div className="total-geral">
                                        <span className={"total pedidos-integracao"}>
                                            {totais.pesquisasRetornoInternoEncontrado - totais.pesquisasRetornoInternoEncontradoAceito}
                                        </span>
                                        não aceitas
                                    </div>
                                    <div className="total-geral">
                                        <span className={"total empresas-integrando"}>
                                            {totais.pesquisasRetornoInterno - totais.pesquisasRetornoInternoEncontrado}
                                        </span>
                                        não encontradas
                                    </div>
                                </div>
                            </div>
                            <div className="total-grupo">
                                <div className="total-geral">
                                    <span className={"total pedidos-integracao"}>
                                        {totais.pesquisasRetornoGoogle}
                                    </span>
                                    pesquisas Google
                                </div>
                                <div className="sub-total">
                                    <div className="total-geral">
                                        <span className={"total integracao-sistemas"}>
                                            {
                                                totais.pesquisasRetornoGoogleEncontrado
                                                - (totais.pesquisasRetornoGoogleUsadoGoogleChaveClienteEncontrado ?? 0)
                                            }
                                        </span>
                                        encontradas
                                    </div>
                                    <div className="total-geral">
                                        <span className={"total empresas-integradas"}>
                                            {totais.pesquisasRetornoGoogleUsadoGoogleChaveClienteEncontrado}
                                        </span>
                                        usado chave cliente
                                    </div>
                                    <div className="total-geral">
                                        <span className={"total empresas-integrando"}>
                                            {totais.pesquisasRetornoGoogle - totais.pesquisasRetornoGoogleEncontrado}
                                        </span>
                                        não encontradas
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                
                <Col
                    sm={12}
                    md={12}
                    xl={4}
                    className="px-0"
                >
                    <div
                        className={['grafico-content', 'p-1', 'border', 'rounded'].join(' ')}
                    >
                        <Card.Header>
                            <div className={'d-flex '}>
                                <Card.Title className={'flex-grow-1 d-flex align-items-center mb-0'}>
                                    Totais por retorno
                                </Card.Title>
                            </div>
                        </Card.Header>
                        <div style={{height: 200, overflow: 'hidden'}}>
                            <Chart
                                type="donut"
                                height={300} 
                                options={{
                                    parentHeightOffset: 0,
                                    sparkline: {
                                        enabled: true,
                                    },
                                    labels,
                                    plotOptions: {
                                        pie: {
                                            size: 100,
                                            startAngle: -90,
                                            endAngle: 90,
                                            donut: {
                                                size: '55%'
                                            }
                                        }
                                    },
                                    legend: {
                                        show: true,
                                        position: 'top',
                                    },
                                }}
                                series={series}
                            />
                        </div>
                    </div>
                </Col>

                {/* <Col
                    sm={12}
                    md={8}
                    className="px-0"
                >
                    <div
                        className={['grafico-content', 'p-1', 'border', 'rounded'].join(' ')}
                    >
                        <Chart
                            options={{
                                colors: ['#3aa872', '#11ace3', '#f3910d'],
                                labels: [
                                    'Base interna',
                                    'Reutilizado',
                                    'Google'
                                ],
                                chart: {
                                    id: 'totalEmpresasPorIntegracao',
                                    stacked: true
                                },
                                plotOptions: {
                                    bar: {
                                        horizontal: true,
                                        barHeight: 90
                                    }
                                },
                                xaxis: {
                                    integracao: [
                                        'Base interno',
                                        'Reutilizado',
                                        'Google'
                                    ]
                                }
                            }}
                            series={[
                                {
                                    name: 'Total pesquisas encontradas',
                                    data: [
                                        totais.pesquisasRetornoInternoEncontradoAceito,
                                        totais.pesquisasRetornoReutilizadoEncontrado,
                                        totais.pesquisasRetornoGoogleEncontrado
                                    ].map((total) => {
                                        return parseInt(total);
                                    })
                                },
                                {
                                    name: 'Total encontradas não aceitas',
                                    data: [
                                        totais.pesquisasRetornoInternoEncontrado - totais.pesquisasRetornoInternoEncontradoAceito,
                                        0,
                                        0
                                    ].map((total) => {
                                        return parseInt(total);
                                    })
                                },
                                {
                                    name: 'Total pesquisas não encontradas',
                                    data: [
                                        totais.pesquisasRetornoInterno - totais.pesquisasRetornoInternoEncontrado,
                                        totais.pesquisasRetornoReutilizado - totais.pesquisasRetornoReutilizadoEncontrado,
                                        totais.pesquisasRetornoGoogle - totais.pesquisasRetornoGoogleEncontrado
                                    ].map((total) => {
                                        return parseInt(total);
                                    })
                                }
                            ]}
                            type="bar"
                        />
                    </div>
                </Col> */}
            </Row>
        {/* </Col> */}
    </RelatoriosEnderecosPesquisasComponent>
    
}