import React, { useState, useEffect, useContext } from 'react';

// componentes
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import InputDataCombo from './../../../../Componentes/InputDataCombo';
import ComboServidores from './../../../../Componentes/ComboServidores';


export default function ParametrosConsulta({
    parametros, 
    alterarParametros,
    mostrarFiltros,
    alterarMostrarFiltros
}){

    // filtros padrão para a pesquisa
    let filtrosTipoAcesso = [
        {value: '1', label: 'Projetista'},
        {value: '2', label: 'Técnico'},
        {value: '3', label: 'Vendedor'},
        {value: '4', label: 'Viabilidade'}
    ];

    return <div className="parametros">
        <div className="parametros-inicial">
            <DebounceInput 
                placeholder="" 
                debounceTimeout={400}
                element={Form.Control} 
                value={parametros.texto}
                onChange={(e) =>{
                    alterarParametros({...parametros, nome: e.target.value, pagina: 1})
                }}
            />
            <Col md={'3'} className={'px-0'}>
                <Select
                    className="select"
                    isClearable
                    options={filtrosTipoAcesso}
                    isMulti={false}
                    placeholder='Tipo de usuário'
                    onChange={(e) => {
                        let valor = (e) ? e.value : null;
                        
                        // altera os filtros selecionados
                        alterarParametros({...parametros, permissao: valor, pagina: 1});
                    }}
                />
            </Col>
            <Button 
                variant="padrao"
                onClick={(e) => {
                    alterarMostrarFiltros(!mostrarFiltros)
                }}
            >
                <FontAwesomeIcon className="icone" icon={mostrarFiltros ? ["fas", "search-minus"] : ["fas", "search-plus"]} />
            </Button>
        </div>
        {
            mostrarFiltros &&
            <div className='mt-3'>
                {/* <h4 className="my-2">Filtros</h4> */}
                <Row>
                    <Col lg={5}>
                        <Form.Row>
                            <Col md="5">
                                <Form.Label>Usuários online</Form.Label>
                            </Col>
                            <Col>
                                <Form.Check
                                    type="switch"
                                    id="switch-online-sim"
                                    label="Sim"
                                    checked={(parametros.usuarios_online.includes('S'))}
                                    onChange={e => {
                                        alterarParametros({...parametros, usuarios_online: (e.target.checked) ? [...parametros.usuarios_online, 'S'] : parametros.usuarios_online.filter((e) => e !== 'S'), pagina: 1});
                                    }}
                                />
                            </Col>
                            <Col>
                                <Form.Check
                                    type="switch"
                                    id="switch-online-nao"
                                    label="Não"
                                    checked={(parametros.usuarios_online.includes('N'))}
                                    onChange={e => {
                                        alterarParametros({...parametros, usuarios_online: (e.target.checked) ? [...parametros.usuarios_online, 'N'] : parametros.usuarios_online.filter((e) => e !== 'N'), pagina: 1});
                                    }}
                                />
                            </Col>
                        </Form.Row>
                    </Col>
                </Row>
            </div>
        }
    </div>
}