import { useEffect, useRef, useState } from "react";
import axios from "axios";
import Chart from "react-apexcharts";

import { Button, Card, Col, Form, OverlayTrigger, ProgressBar, Row, Tooltip } from "react-bootstrap";

import { RelatorioArquivosEmpresaComponent } from "./components";
import InputDataCombo from "../../../../../Componentes/InputDataCombo";
import { FichaComponent } from "../../../../../Componentes/components";
import { formatarTamanhoBytes } from "../../../../../Funcoes"
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArchive } from "@fortawesome/free-regular-svg-icons";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { faCheck, faExclamationTriangle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Confirmar from "../../../../../Componentes/Confirmar";
import ComboEmpresasArquivosTagsMultiplo from "../../../../../Componentes/ComboEmpresasArquivosTagsMultiplo";

export default function RelatorioArquivosEmpresa({
    idEmpresa
}){

    // contextos
    const { loginReducer, permissaoReducer } = useSelector(state => state);
    
    // estados
    const [ confirmar, alterarConfirmar ] = useState(null);
    const [ carregando, alterarCarregando ] = useState(false);
    const [ parametros, alterarParametros ] = useState({
        dataInicio: null,
        dataFinal: null
    });
    const [ dados, alterarDados ] = useState({
        relatorio: [],
        total: 0,
        tamanhoTotal: 0
    });
    const [ labels, alterarLabels ] = useState([]);
    const [ seriesTotais, alterarSeriesTotais ] = useState([]);
    const [ seriesTamanhos, alterarSeriesTamanhos ] = useState([]);

    // refs
    const toastId = useRef(null);
    
    // efeitos

    useEffect(() => {

        // consulta
        consultar();
        
    }, [parametros]);

    // função para a consulta
    async function consultar(){
     
        // cria o carregamento
        alterarCarregando(true);
        
        try{
            
            // consulta
            let { data } = await axios.get(`/empresas/${idEmpresa}/arquivos/relatorio`, {
                params: {
                    ...parametros
                }
            });

            // define os dados
            alterarDados(data);

            // define informações
            alterarLabels(data.relatorio.map(r => r.descricao));
            alterarSeriesTotais(data.relatorio.map(r => parseFloat(r.total)));
            alterarSeriesTamanhos(data.relatorio.map(r => parseFloat(r.tamanhoTotal)));

        }catch(e){
            // erro
        }finally{

            // remove o carregamento
            alterarCarregando(false);
        }
    }

    // função para gerar zip dos arquivos
    async function gerarZipArquivos({ tag = null } = {}){
        
        // verifica se está carregando
        if(toastId.current){
            return;
        }

        // prepara o render
        const toastRender = (progresso = null) => {

            // retorna
            return <>
                <div className="toast-header">
                    <strong className="mr-auto">
                        {progresso === null ?
                            <>Finalizado, aguarde o download</>
                            :
                            <>Gerando zip dos arquivos...</>
                        }
                    </strong>
                    <button 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                    </button>
                </div>
                <div className="toast-body">
                    {(progresso === null) ?
                        <>
                            <FontAwesomeIcon className='mr-2' icon={faCheck} />
                            <span>Finalizado!!</span>
                        </>
                        :
                        <ProgressBar animated now={progresso} label={`${parseFloat(progresso).toFixed(2)}%`} />
                    }
                </div>
            </>
        }

        // instancia um toast
        toastId.current = toast(({ closeToast }) => toastRender(0), {
            autoClose: false
        });
        
        // faz a requisição
        let oReq = new XMLHttpRequest();
        let ultimaPosicao = null;
        let ultimoJsonRetorno = null;
        
        // ao receber o retorno da requisição
        oReq.onreadystatechange = async (e) => {

            // pega log atual
            let partesRetorno = e.target.responseText.split('}{');
            let totalPartes = partesRetorno.length;

            // adiciona
            let logAtual = totalPartes > 1 ? '{' + partesRetorno[totalPartes - 1] : partesRetorno[0];
            ultimaPosicao = e.target.responseText.length;

            // pega o retorno
            let retorno = null;

            try{
                retorno = JSON.parse(logAtual)
            }catch(e){
                // console.log(e);
            }

            // enquanto executa
            if(e.target.readyState === 3){

                // verifica se pegou o retorno
                if(!retorno){
                    return;
                }
            
                // pega o retorno
                ultimoJsonRetorno = retorno;
            
                console.log({retorno})
                // se retornado o processo
                if(retorno.mensagem){

                    // altera mensagem
                    // toastImportarPostes.descricao(retorno.mensagem);
                }

                // se enviado o processo (último retorno são os dados)
                if(retorno.concluido){

                    // atualiza o toast com a porcentagem
                    toast.update(toastId.current, {
                        render: toastRender(retorno.concluido < 100 ? retorno.concluido : null)
                    });
                    
                }
                
            }

            // execução finalizada
            if(e.target.readyState === 4) {
                // baixar arquivo, cria tag a de download
                
                // quando finalizar, espera um tempo ainda para fechar
                toast.update(toastId.current, {
                    render: toastRender(null),
                    autoClose: 1000
                });
                toastId.current = null;

                // verifica o status
                if(e.target.status == 200){
                    // finalizado com sucesso

                    // pega dados do arquivo gerado no retorno
                    let downloadArquivo = ultimoJsonRetorno.downloadArquivo;

                    // verifica se possui dados
                    if(downloadArquivo){

                        // define
                        const link = document.createElement('a');
                        link.href = `${process.env.REACT_APP_URL_ARQUIVOS}/${downloadArquivo}`;
                        link.setAttribute('target', '_blank');
                    
                        // Append to html link element page
                        document.body.appendChild(link);
                    
                        // Start download
                        link.click();
                    
                        // Clean up and remove the link
                        link.parentNode.removeChild(link);

                        // // cria tag e faz o download
                        // var arquivo = $('<a/>').attr('target','_blank').attr('href', downloadArquivo);
                        // arquivo[0].click();
                        
                    }

                }else{
                    // ocorreu um erro no recebimento das respostas

                
                    // // altera
                    // titulo = `Erro ao exportar`;
                    // descricao = `Ocorreu algum erro ao exportar os dados. ${retorno.conexao ? `
                    //     Erro de conexão:
                    //         ${retorno.conexão}
                    // ` : ``}`;
                }

            }

        };
        
        // inicia a atualização
        oReq.open("POST", `${process.env.REACT_APP_URL_PHP}/empresas/${idEmpresa}/arquivos/backup`, true);
        oReq.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        oReq.setRequestHeader("tokenusuario", loginReducer.dadosUsuario.token);

        // dados
        oReq.send(JSON.stringify(parametros));

    }

    // função para deletar os arquivos
    async function deletarArquivos({ tag = null } = {}){
        
        // verifica se está carregando
        if(toastId.current){
            return;
        }

        // prepara o render
        const toastRender = (progresso = null) => {

            // retorna
            return <>
                <div className="toast-header">
                    <strong className="mr-auto">
                        {progresso === null ?
                            <>Finalizado</>
                            :
                            <>Deletando arquivos...</>
                        }
                    </strong>
                    <button 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                    </button>
                </div>
                <div className="toast-body">
                    {(progresso === null) ?
                        <>
                            <FontAwesomeIcon className='mr-2' icon={faCheck} />
                            <span>Finalizado!!</span>
                        </>
                        :
                        <ProgressBar animated now={progresso} label={`${parseFloat(progresso).toFixed(2)}%`} />
                    }
                </div>
            </>
        }

        // instancia um toast
        toastId.current = toast(({ closeToast }) => toastRender(0), {
            autoClose: false
        });
        
        // faz a requisição
        let oReq = new XMLHttpRequest();
        let ultimaPosicao = null;
        let ultimoJsonRetorno = null;
        
        // ao receber o retorno da requisição
        oReq.onreadystatechange = async (e) => {

            // pega log atual
            let partesRetorno = e.target.responseText.split('}{');
            let totalPartes = partesRetorno.length;

            // adiciona
            let logAtual = totalPartes > 1 ? '{' + partesRetorno[totalPartes - 1] : partesRetorno[0];
            ultimaPosicao = e.target.responseText.length;

            // pega o retorno
            let retorno = null;

            try{
                retorno = JSON.parse(logAtual)
            }catch(e){
                // console.log(e);
            }

            // enquanto executa
            if(e.target.readyState === 3){

                // verifica se pegou o retorno
                if(!retorno){
                    return;
                }
            
                // pega o retorno
                ultimoJsonRetorno = retorno;
            
                console.log({retorno})
                // se retornado o processo
                if(retorno.mensagem){

                    // altera mensagem
                    // toastImportarPostes.descricao(retorno.mensagem);
                }

                // se enviado o processo (último retorno são os dados)
                if(retorno.concluido){

                    // atualiza o toast com a porcentagem
                    toast.update(toastId.current, {
                        render: toastRender(retorno.concluido < 100 ? retorno.concluido : null)
                    });
                    
                }
                
            }

            // execução finalizada
            if(e.target.readyState === 4) {
                // baixar arquivo, cria tag a de download
                
                // quando finalizar, espera um tempo ainda para fechar
                toast.update(toastId.current, {
                    render: toastRender(null),
                    autoClose: 1000
                });
                toastId.current = null;

                // verifica o status
                if(e.target.status == 200){
                    // finalizado com sucesso

                    // informa que foi deletado

                    // atualiza a lista
                    alterarParametros({...parametros});
                    
                }else{
                    // ocorreu um erro no recebimento das respostas

                    // informa erro
                    toast(<>
                        <div
                            style={{background: '#ff6271'}}
                        >
                            <div className="toast-header">
                                <strong className="mr-auto">Erro ao deletar!</strong>
                                <button 
                                    className="ml-2 mb-1 close btn-outline-light outline-0"
                                >
                                </button>
                            </div>
                            <div className="toast-body text-light">
                                {
                                    (ultimoJsonRetorno) &&
                                    Object.keys(ultimoJsonRetorno).filter((erro) => ![
                                        'registrosNaoEncontrados'
                                    ].includes(erro)).map((erro) => <p className={'m-0'} key={erro}>{ultimoJsonRetorno[erro]}<br/></p>)
                                }
                            </div>
                        </div>
                    </>);
                    
                }

            }

        };
        
        // inicia a atualização
        oReq.open("DELETE", `${process.env.REACT_APP_URL_PHP}/empresas/${idEmpresa}/arquivos/limpar`, true);
        oReq.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        oReq.setRequestHeader("tokenusuario", loginReducer.dadosUsuario.token);

        // dados
        oReq.send(JSON.stringify(parametros));

    }

    return <RelatorioArquivosEmpresaComponent>
        <Confirmar
            config={confirmar}
            alterar={alterarConfirmar}
        />
        <Row className="px-2 mb-3">
            <Col className="px-0">
                <Card className="m-0 card-totais-gerais">
                    <Card.Body>
                        <FichaComponent>
                            <Row className="mb-3">
                                <Col md={7}>
                                    <Form.Label className="mb-1">
                                        Tags/funções
                                    </Form.Label>
                                    <ComboEmpresasArquivosTagsMultiplo
                                        className="select-ficha"
                                        ids={parametros.tag || []}
                                        alterou={tag => {
                                            alterarParametros({...parametros, tag});
                                        }}
                                    />
                                </Col>
                                <Col className='position-relative'>
                                    <Form.Label className="mb-1">De: </Form.Label>
                                    <InputDataCombo
                                        size="sm"
                                        placeholder="A partir de"
                                        data={parametros.dataInicio}
                                        onChange={(novaData) => {
                                            alterarParametros({...parametros, dataInicio: novaData})
                                        }}
                                    />
                                </Col>
                                <Col className='position-relative'>
                                    <Form.Label className="mb-1">Até: </Form.Label>
                                    <InputDataCombo
                                        size="sm"
                                        placeholder="Até"
                                        data={parametros.dataFinal}
                                        onChange={(novaData) => {
                                            alterarParametros({...parametros, dataFinal: novaData})
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="opcoes">
                                    <OverlayTrigger 
                                        overlay={<Tooltip>Fazer download dos arquivos em zip</Tooltip>}
                                        placement='bottom'
                                    >
                                        <Button
                                            variant='info'
                                            size='sm'
                                            onClick={() => {
                                                
                                                // confirma que deseja fazer o download
                                                alterarConfirmar({
                                                    aberto: true,
                                                    backdrop: true,
                                                    titulo: 'Baixar arquivos',
                                                    texto: <>
                                                        Tem certeza que deseja fazer o download dos arquivos?
                                                        <br/>
                                                        <small>Um zip com todos os arquivos dos filtros será gerado.</small>
                                                    </>,
                                                    textoBotaoConfirmar: 'Gerar arquivo',
                                                    variantConfirmar: 'info',
                                                    variantCancelar: 'secondary',
                                                    aoConfirmar: () => {

                                                        // gera o arquivo
                                                        gerarZipArquivos();
                                                    },
                                                    aoCancelar: () => {
                                                        // ao cancelar
                                                    }
                                                })
                                            }}
                                        >
                                            <FontAwesomeIcon className="icone mr-1" icon={faFileArchive} />
                                            Baixar arquivos
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger 
                                        overlay={<Tooltip>Deletar arquivos do S3</Tooltip>}
                                        placement='bottom'
                                    >
                                        <Button
                                            variant='danger'
                                            size='sm'
                                            onClick={() => {
                                                
                                                // confirma que deseja deletar os arquivos
                                                alterarConfirmar({
                                                    aberto: true,
                                                    backdrop: true,
                                                    titulo: 'Deletar arquivos',
                                                    texto: <>
                                                        <p>
                                                            Tem certeza que deseja deletar os arquivos da empresa no S3?
                                                        </p>
                                                        <p className="bd-callout bd-callout-info">
                                                            Isso vai limpar os arquivos da empresa no S3, mas
                                                            <u> não afetará registros na base do cliente</u>, ou seja,
                                                            os anexos continuarão existentes na base de dados com <u>referência a um arquivo excluído</u>.
                                                        </p>
                                                        <p className="font-weight-bold">
                                                            <FontAwesomeIcon className="mr-1 text-warning" icon={faExclamationTriangle} />
                                                            Faça backup dos arquivos antes de prosseguir.
                                                        </p>
                                                    </>,
                                                    textoBotaoConfirmar: 'Deletar arquivos',
                                                    variantConfirmar: 'danger',
                                                    variantCancelar: 'secondary',
                                                    aoConfirmar: () => {

                                                        // deleta os arquivos
                                                        deletarArquivos();
                                                    },
                                                    aoCancelar: () => {
                                                        // ao cancelar
                                                    }
                                                })
                                            }}
                                        >
                                            <FontAwesomeIcon className="icone mr-1" icon={faTrashAlt} />
                                            Deletar arquivos
                                        </Button>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </FichaComponent>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Row className="px-2">
            <Col md={7} className="pl-0">
                <table className="dados-base" cellSpacing={0}>
                    <thead>
                        <tr>
                            <th colSpan={3} style={{padding: '0.3em'}}>Arquivos por função</th>
                        </tr>
                        <tr>
                            <th>Geral</th>
                            <th>{dados.total}</th>
                            <th>{formatarTamanhoBytes(dados.tamanhoTotal)}</th>
                        </tr>
                        <tr>
                            <th>Função</th>
                            <th>Total registros</th>
                            <th>Tamanho</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dados.relatorio.map((r) => {
                            return <tr key={r.tag}>
                                <th>{r.descricao}</th>
                                <td>{r.total}</td>
                                <td>{formatarTamanhoBytes(r.tamanhoTotal)}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </Col>
            <Col md={5}>    
                <Row>
                    <Col className="px-0 pb-2">
                        <div
                            className={['grafico-content', 'p-1', 'border', 'rounded'].join(' ')}
                        >
                            <Card.Header>
                                <div className={'d-flex '}>
                                    <Card.Title className={'flex-grow-1 d-flex align-items-center mb-0'}>
                                        Totais
                                    </Card.Title>
                                </div>
                            </Card.Header>
                            <div style={{height: 200, overflow: 'hidden', position: 'relative'}}>
                                {dados.total === 0 ? <div className="position-absolute w-100 h-100 d-flex align-items-center justify-content-center">
                                    Sem arquivos
                                </div> : <Chart
                                    type="donut"
                                    height={300} 
                                    options={{
                                        parentHeightOffset: 0,
                                        sparkline: {
                                            enabled: true,
                                        },
                                        labels,
                                        plotOptions: {
                                            pie: {
                                                size: 100,
                                                startAngle: -90,
                                                endAngle: 90,
                                                donut: {
                                                    size: '55%'
                                                }
                                            }
                                        },
                                        legend: {
                                            show: true,
                                            position: 'top',
                                        },
                                    }}
                                    series={seriesTotais}
                                />}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="px-0">
                        <div
                            className={['grafico-content', 'p-1', 'border', 'rounded'].join(' ')}
                        >
                            <Card.Header>
                                <div className={'d-flex '}>
                                    <Card.Title className={'flex-grow-1 d-flex align-items-center mb-0'}>
                                        Tamanhos
                                    </Card.Title>
                                </div>
                            </Card.Header>
                            <div style={{height: 200, overflow: 'hidden', position: 'relative'}}>
                                {dados.total === 0 ? <div className="position-absolute w-100 h-100 d-flex align-items-center justify-content-center">
                                    Sem arquivos
                                </div> : <Chart
                                    type="donut"
                                    height={300} 
                                    options={{
                                        parentHeightOffset: 0,
                                        sparkline: {
                                            enabled: true,
                                        },
                                        labels,
                                        plotOptions: {
                                            pie: {
                                                size: 100,
                                                startAngle: -90,
                                                endAngle: 90,
                                                donut: {
                                                    size: '55%'
                                                }
                                            }
                                        },
                                        legend: {
                                            show: true,
                                            position: 'top',
                                        },
                                        // dataLabels: {
                                        //     enabled: true,
                                        //     formatter: function(value, { seriesIndex, dataPointIndex, w }){
                                        //         return formatarTamanhoBytes(w.config.series[seriesIndex]);
                                        //     }
                                        // },
                                        tooltip: {
                                            y: {
                                                show: true,
                                                formatter: function(value, { seriesIndex, dataPointIndex, ...w }){
                                                    return formatarTamanhoBytes(value);
                                                }
                                            }
                                        }
                                    }}
                                    series={seriesTamanhos}
                                />}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    </RelatorioArquivosEmpresaComponent>
    
}