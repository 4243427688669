import axios from "axios";
import { Button, Col, Container, Form, InputGroup, Modal, OverlayTrigger, ProgressBar, Spinner, Tooltip } from "react-bootstrap";
import { FichaComponent } from "../../../Componentes/components";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamationTriangle, faFileSignature, faInfoCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Confirmar from "../../../Componentes/Confirmar";
import { useSelector } from "react-redux";
import ComboBancosDados from "../../../Componentes/ComboBancosDados";
import ComboServidores from "../../../Componentes/ComboServidores";

export default function ModalLimparBase({
    idEmpresa,

    mostrar,
    alterarMostrar,

    aoFinalizar
}){

    // contextos
    const { loginReducer } = useSelector(state => state);
    
    // dados padrão
    const dadosPadrao = {
        idServidorAluguel: null,
        pasta: null,
        idBancoDados: null,
        senhaBanco: null
    };

    // estados
    const [ dados, alterarDados ] = useState({
        ...dadosPadrao
    });
    const [ dadosEmpresa, alterarDadosEmpresa ] = useState(null);
    const [ consultando, alterarConsultando ] = useState(false);
    const [ limpando, alterarLimpando ] = useState(false);
    const [ confirmar, alterarConfirmar ] = useState(null);
    
    // refs
    const toastId = useRef(null);
    
    useEffect(() => {
        
        // se já definido o id
        if(idEmpresa && mostrar){

            // consulta
            consultar();
        }else if(!idEmpresa){

            // limpa
            alterarDadosEmpresa(null);
        }
    }, [idEmpresa, mostrar]);

    // função para consultar
    async function consultar(){

        // altera para consultando
        alterarConsultando(true);

        try{

            // consulta
            let { data } = await axios.get(`/empresas/${idEmpresa}`, {
                params: {
                    campos: [
                        'id',
                        'codigo',
                        'razaoSocial',
                        'situacao',
                        'enderecoServidor',
                        'idServidorAluguel',
                        'pasta',
                        'idBanco',
                        'senhaBanco'
                    ]
                }
            });

            // prepara
            let dadosAlterar = {
                ...dados,
                senhaBanco: data.dados.senhaBanco || `${data.dados.codigo}geogrid2015`,
                idBancoDados: data.dados.idBanco || dados.idBancoDados || null,
                pasta: data.dados.pasta || (data.dados.enderecoServidor.includes('demo.geogridmaps.com.br') ? null : (data.dados.enderecoServidor || '').split("/").pop()),
                idServidorAluguel: (data.dados.idServidorAluguel || null)
            };
            
            // altera os dados da empresa
            alterarDadosEmpresa(data.dados);

            // altera se é a primeira vez que está consultando
            if(!dadosEmpresa){

                // altera os dados com os dados da empresa
                alterarDados(dadosAlterar);
            }
            
        }catch(e){

        }finally{

            // finaliza
            alterarConsultando(false);
        }
    }

    // função para limpar os dados da base da empresa
    async function limparBase(){
    
        // define como limpando
        alterarLimpando(true);

        // verifica se está carregando
        if(toastId.current){
            return;
        }

        // esconde
        alterarMostrar(false);

        // faz a requisição
        let oReq = new XMLHttpRequest();
        let ultimaPosicao = null;
        let ultimoJsonRetorno = null;
    
        // prepara para calcular o tempo gasto
        let tempo = 0;
        let verificaTempo = setInterval(function(){
            tempo++
        }, 1000);
        let idProcesso = null;
        let cancelado = false;
        
        // prepara o render
        const toastRender = (progresso = null) => {

            // retorna
            return <>
                <div className="toast-header">
                    <strong className="mr-auto">
                        {progresso === null ?
                            <>Finalizado</>
                            :
                            <>Limpando os dados da empresa...</>
                        }
                    </strong>
                    <button 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                    </button>
                </div>
                <div className="toast-body">
                    {(progresso === null) ?
                        <>
                            <FontAwesomeIcon className='mr-2' icon={faCheck} />
                            <span>Finalizado!!</span>
                        </>
                        :
                        <ProgressBar animated now={progresso} label={`${parseFloat(progresso).toFixed(2)}%`} />
                    }
                    {
                        idProcesso && <Button
                            size="sm"
                            variant="warning"
                            className="mt-1 p-1 px-2"
                            onClick={() => {
    
                                // retorna se não possui o id ainda
                                if(!idProcesso) return;
                        
                                // confirma
                                alterarConfirmar({
                                    aberto: true,
                                    titulo: 'Cancelar limpar',
                                    texto: `Tem certeza que deseja cancelar o limpar iniciado?`,
                                    textoBotaoConfirmar: 'Cancelar limpar',
                                    variantConfirmar: 'info',
                                    variantCancelar: 'secondary',
                                    aoConfirmar: () => {

                                        // chama o cancelar
                                        cancelar();
                            
                                        // define
                                        cancelado = true;
                                    },
                                    aoCancelar: () => {
                                        // ao cancelar
                                    },
                                    aoFechar: () => {
                                        // ao fechar
                                    }
                                })

                            }}
                        >
                            Cancelar
                        </Button>
                    }
                </div>
            </>
        }

        // instancia um toast
        toastId.current = toast(({ closeToast }) => toastRender(0), {
            autoClose: false
        });

        // função para cancelar o processo
        async function cancelar(){

            try{

                // chama o cancelar
                let { data } = await axios.delete(`/empresas/${idEmpresa}/limpar/${idProcesso}/cancelar`);

                // sucesso
                
                // informa
                toast(({closeToast }) => <>
                    <div className="toast-header">
                        <strong className="mr-auto">Limpar cancelado!</strong>
                        <button 
                            onClick={closeToast} 
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="toast-body">
                        Limpar dados foi cancelado! Nenhuma alteralção foi salva.
                    </div>
                </>);
                

            }catch(e){
                // erro

                // mensagem de erro
                toast(({closeToast }) => <>
                    <div className="toast-header">
                        <strong className="mr-auto">Erro ao cancelar</strong>
                        <button 
                            onClick={closeToast} 
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="toast-body">
                        Erro ao cancelar o limpar. Tente novamente ou entre em contato com o desenvolvimento
                    </div>
                </>);
                
            }finally{
                // finalizado

                // remove o carregamento
            }
        }

        // ao receber o retorno da requisição
        oReq.onreadystatechange = async (e) => {
    
            // pega log atual
            let partesRetorno = e.target.responseText.split('}{');
            let totalPartes = partesRetorno.length;
    
            // adiciona
            let logAtual = totalPartes > 1 ? '{' + partesRetorno[totalPartes - 1] : partesRetorno[0];
            ultimaPosicao = e.target.responseText.length;
    
            // pega o retorno
            let retorno = null;
    
            try{
                retorno = JSON.parse(logAtual)
            }catch(e){
                // console.log(e);
            }
    
            // enquanto executa
            if(e.target.readyState === 3 || e.target.readyState === 4){
    
                // verifica se pegou o retorno
                if(!retorno){
                    // sem retorno
                }else{
    
                    // verifica se retornou o id do processo
                    if(retorno.idProcesso){
    
                        // pega o id
                        idProcesso = retorno.idProcesso;
                    }
    
                    // se enviado o processo (último retorno são os dados)
                    if(retorno.concluido){
    
                        // atualiza o toast com a porcentagem
                        toast.update(toastId.current, {
                            render: toastRender(retorno.concluido)
                        });
                        
                    }
    
                    // verifica se tem informações
                    if(retorno.cancelado){
    
                        // define
                        cancelado = cancelado || retorno.cancelado === 'S';
                    }
    
                    // verifica se tem erros
                    if(retorno.erros){
    
                        // console.log(retorno.erros);
                    }
                    
                }
            
            }
    
            // execução finalizada
            if(e.target.readyState === 4){
    
                // limpa a contagem
                clearInterval(verificaTempo);
    
                // verifica se possui retorno
                if(retorno){
                        
                    // atualiza o toast com a porcentagem
                    toast.update(toastId.current, {
                        render: toastRender(retorno.concluido < 100 ? retorno.concluido : null)
                    });
                }
                
                // remove
                toast.dismiss(toastId.current);
                
                // limpa
                toastId.current = null;

                // altera para não deletando
                alterarLimpando(false);

                // verifica se a importação foi cancelada
                if(!cancelado && e.target.status == 200){
    
                    // calcula o tempo da execução
                    let duracao = (tempo / 60)
                    if(duracao < 1){
                        duracao = tempo+'s'
                    }else{
                        duracao = duracao.toFixed(2)+'min'
                    }
    
                    // executa o callback
                    aoFinalizar();

                    // informa
                    toast(({closeToast }) => <>
                        <div className="toast-header">
                            <strong className="mr-auto">Banco de dados limpo</strong>
                            <button 
                                onClick={closeToast} 
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="toast-body">
                            Limpeza do banco de dados finalizada com sucesso! ({duracao})
                        </div>
                    </>);

                }else if(!cancelado){
                    // erro
                    
                    // pega os erros
                    let erros = retorno;
                
                    // mostra novamente
                    alterarMostrar(true);

                    // prepara descrição
                    let descricao = <>
                        O banco de dados não pôde ser limpo!
                        {
                            Object.keys(erros).filter((erro) => erro !== 'info').map((erro) => {
                                return <div key={erro}>
                                    {erro === 'conexao' ? 
                                        <>Ocorreu um erro de conexão ao limpar os dados. Por favor, tente novamente.</> 
                                        : <>&nbsp;&nbsp;&rsaquo; {erros[erro]}</>
                                    }
                                </div>
                            })
                        }
                    </>;
    
                    // mensagem de cadastro realizado com sucesso
                    toast(({closeToast }) => <>
                        <div className="toast-header">
                            <strong className="mr-auto">Erro ao limpar os dados</strong>
                            <button 
                                onClick={closeToast} 
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="toast-body">
                            {descricao}
                        </div>
                    </>);
                    
                }
            }
    
        };
        
        // inicia a atualização
        oReq.open("POST", `${process.env.REACT_APP_URL_PHP}/empresas/${idEmpresa}/limpar/processo`, true);
        oReq.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        oReq.setRequestHeader("tokenusuario", loginReducer.dadosUsuario.token);
    
        // dados
        oReq.send(JSON.stringify({
            // configuração
            ...dados
        }));
    
    }

    return <>
        <Confirmar config={confirmar} alterar={alterarConfirmar}/>
        <Modal
            show={mostrar}
            centered
            onExited={() => {

                // fecha
                alterarMostrar(false);
            }}
            onHide={() => {

                // fecha
                alterarMostrar(false);
            }}
            size={'md'}
        >
            <Modal.Header className='border-0'>
                <div className={'d-flex w-100'}>
                    <Modal.Title 
                        as='h5' 
                        // className={[
                        //     'text-center w-100',
                        //     'text-success'
                        // ].join(' ')}
                        className={'flex-grow-1 text-center mb-0 text-warning'}
                    >
                        Limpar banco de dados
                    </Modal.Title>
                        
                    <div className='d-flex' style={{gap: '0.5em'}}>
                        
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip>
                                    Preencher com dados da empresa
                                </Tooltip>
                            }
                        >
                            <Button
                                variant='success'
                                size='sm'
                                onClick={() => {

                                    // altera os dados com os dados da empresa
                                    alterarDados({
                                        ...dados,
                                        senhaBanco: dadosEmpresa.senhaBanco || dados.senhaBanco || `${dadosEmpresa.codigo}geogrid2015`,
                                        idBancoDados: dadosEmpresa.idBanco || dados.idBancoDados,
                                        pasta: dadosEmpresa.pasta || (dadosEmpresa.enderecoServidor.includes('demo.geogridmaps.com.br') ? null : (dadosEmpresa.enderecoServidor || '').split("/").pop()),
                                        idServidorAluguel: (dadosEmpresa.idServidorAluguel || null)
                                    });
                                }}
                            >
                                <FontAwesomeIcon className="icone" icon={faFileSignature} fontSize={16}></FontAwesomeIcon>
                            </Button>
                        </OverlayTrigger>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body className={'pt-0 px-1'}>
                <FichaComponent>
                    <Container>
                        <Form.Row>
                            <Col>
                                <Form.Label>Endereço</Form.Label>
                                <Form.Row>
                                    <Col>
                                        {consultando ? <div className="text-center">
                                            <FontAwesomeIcon icon={faSpinner} pulse /> Carregando...
                                        </div> : <ComboServidores
                                            className="select-ficha"
                                            placeholder="Servidor"
                                            valor={dados.idServidorAluguel}
                                            alterou={idServidorAluguel => {
                                                alterarDados({...dados, idServidorAluguel: (idServidorAluguel !== '') ? idServidorAluguel : null});
                                            }}
                                            esconderIconeDrop={true}
                                            filtros={{
                                                interno: ''
                                            }}
                                        />}
                                    </Col>
                                    <Col md="7 pl-0">
                                        <InputGroup>
                                            <InputGroup.Text size="sm" className="grupo-texto-inicio">
                                                <small><small>geogridmaps.com.br/</small></small>
                                            </InputGroup.Text>
                                            <Form.Control
                                                placeholder="pasta"
                                                value={dados.pasta || ''}
                                                onChange={e => {
                                                    alterarDados({...dados, pasta: e.target.value});
                                                }}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Form.Row>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Form.Label>Banco</Form.Label>
                                {consultando ? <div className="text-center">
                                    <FontAwesomeIcon icon={faSpinner} pulse /> Carregando...
                                </div> : <ComboBancosDados
                                    className="select-ficha"
                                    valor={dados.idBancoDados}
                                    alterou={idBancoDados => {
                                        alterarDados({...dados, idBancoDados: (idBancoDados !== '') ? idBancoDados : null});
                                    }}
                                />}
                            </Col>
                            <Col>
                                <Form.Label>Senha banco</Form.Label>
                                <Form.Control 
                                    value={dados.senhaBanco || ''}
                                    onChange={e => {
                                        alterarDados({...dados, senhaBanco: e.target.value});
                                    }}
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col className="my-0 d-flex justify-content-center">
                                <small><small><i>
                                    <FontAwesomeIcon icon={faInfoCircle} className="mr-1" color="grey"/>
                                    Para os campos vazios, serão usados os valores salvos na empresa.
                                </i></small></small>
                            </Col>
                        </Form.Row>
                    </Container>
                </FichaComponent>
            </Modal.Body>
            <Modal.Footer className='border-0 d-flex align-items-center justify-content-between'>
                <Button
                    size="sm"
                    variant='secondary'
                    onClick={() => {alterarMostrar(false)}}
                    disabled={limpando}
                >
                    <span>Fechar</span>
                </Button>
                <Button
                    size="sm"
                    variant='warning'
                    onClick={limparBase}
                >
                    {limpando ? 
                        <>
                            <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                            <span>Limpando</span>
                        </> : 
                        <span>Limpar base</span>
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}