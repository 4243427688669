import React from 'react';
import styles from './index.module.scss';

function OptionSelect({
    data, 
    ...props
}){
    
    let { hasValue, innerProps, innerRef, isFocused, isDisabled, isSelected } = props;
    let selected = props.getValue();
    
    // retorna componente
    return <div 
        tabIndex='-1' 
        ref={innerRef} 
        {...innerProps} 
        className={[
            styles.option, 
            isFocused && styles.focado,
            isDisabled && styles.desabilitar,
            (hasValue && data.dados.id === selected[0].id) && styles.selecionado
        ].join(' ')}
    >
        {data.dados.nome || <i>Sem nome</i>}
    </div>
}


export default OptionSelect;