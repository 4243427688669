import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const EmpresaEstatisticasComponent = styled(Card).attrs({
    className: `painel-consulta border-0 shadow-sm`
})`

    .card-header{
        padding: 0;
        font-size: 0.9em;

        .h5{
            font-size: 1.25em;
        }
    }

    table{
        width: 100%;
        border-radius: 0.3em;
        border-collapse: separate;
        font-size: 0.95em;
        margin-top: 0.75em;
        
        &:first-child{
            margin-top: 0;
        }

        tr{
            border-radius: 0.3em;

            td, th {
                text-align: center;
                border: 1px solid #f3f3f3;
                background: #fdfdfd;
                padding: 0 0.3em;
                border-bottom: 0;
            }

            &:last-child{

                td {
                    border-bottom: 1px solid #f3f3f3;
                }
            }
        }

        th{
            font-weight: 600;
        }

        thead{

            tr{
                    
                &:first-child{

                    th, td{

                        &:first-child{
                            border-top-left-radius: 0.3em;
                        }

                        &:last-child{
                            border-top-right-radius: 0.3em;
                        }
                    }
                }
            }
        }

        tbody{

            tr{
                    
                &:last-child{

                    th, td{

                        &:first-child{
                            border-bottom-left-radius: 0.3em;
                        }

                        &:last-child{
                            border-bottom-right-radius: 0.3em;
                        }
                    }
                }
            }
        }

        &.dados-base{

            tbody{

                th{
                    text-align: left;
                }
            }
        }
    }

`;