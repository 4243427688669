import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const ComboListaModalComponent = styled(Modal).attrs({
    className: ``
})`
    
    .modal-header{
        padding: 0.5rem 1rem;
        border-bottom: 0;
    
        .modal-title{
            font-size: 1.125rem;
        }
    }

    .modal-body{

        .container-tabela{
            overflow: auto;
            max-height: 200px;
            min-height: 200px;

            .tabela{
                margin-bottom: unset;
            }
        }
    }

`

export const VisualizarModalComponent = styled(Modal).attrs({
    className: ``
})`
    
    .modal-dialog{
        max-width: 90%;
        width: fit-content;
    }

`