import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import axios from 'axios';
import moment from 'moment';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ComboMembro from './../../../Componentes/ComboMembro';
import { Row, Col, Card, Button } from 'react-bootstrap';
import FiltroDataCabecalho from './FiltroDataCabecalho';
import CartaoDados from './CartaoDados';
import { BrowserView, MobileView, isMobile, isBrowser } from "react-device-detect";
import Registro from './Registro';
import { useSelector } from 'react-redux';

// retorna relatório
export default function Relatorio(){

    // variaveis
    const { loginReducer, permissaoReducer } = useSelector(state => state);
    let [membro, alterarMembro] = useState(null);
    let [consulta, alterarConsulta] = useState(null);
    let [carregando, alterarCarregando] = useState(true);
    let [parametros, alterarParametros] = useState({
        data: moment().format('YYYY-MM-DD'),
        mes: moment().format('MM'),
        ano: moment().format('YYYY')
    });
    let [foco, alterarFoco] = useState(null);

    // faz a consulta do relatório geral do membro
    useEffect(() => {
        if(membro !== null){
            consultarRelatorio();
        }
    }, [membro, parametros]);

    // verifica se funcionário está relacionado a um membro
    useEffect(() => {
        verificarFuncionario();
    }, []);

    // verifica funcionário
    async function verificarFuncionario(){
        alterarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/funcionario/trello`);
            alterarMembro({...data});

        }catch({response}){

        }finally{
            alterarCarregando(false);
        }

    }

    // consulta relatorio
    async function consultarRelatorio(){
        
        try{

            // faz a requisição
            let { data } = await axios.get(`/trello/relatorio/${membro.dados.id}`, {
                params: parametros
            });
            alterarConsulta(data);

        }catch({response}){

        }finally{
            
        }
    }
    
    // retorna registro
    let retornaRegistros = () => {

        if(['aberto', 'progresso', 'teste'].includes(foco)){
            return consulta[foco].registrosTodos.map(registro => 
                <Registro 
                    key={registro.dados.id}
                    dados={registro.dados}
                    membros={registro.membros}
                    funcionarios={registro.funcionarios}
                    lista={registro.lista}
                    etiquetas={registro.etiquetas}
                    responsavel={registro.responsavel}
                />
            )
        }else{
            // retorna registros organizado
            return consulta[foco].registros.map(registro => 
                <Registro 
                    key={registro.dados.id}
                    dados={registro.dados}
                    membros={registro.membros}
                    lista={registro.lista}
                    etiquetas={registro.etiquetas}
                    responsavel={registro.responsavel}
                />
            )
        }
    }

    // se carregando
    if(carregando){
        return <></>
    }

    return <>
        
            <Row className={'mb-4 align-items-center'}>
                <Col>
                {membro && 
                    <FiltroDataCabecalho 
                        value={parametros.data}
                        onChange={novaData => {
                            if(novaData !== null){
                                parametros.mes = moment(novaData).format('MM');
                                parametros.ano = moment(novaData).format('YYYY');
                            }else{
                                parametros.mes = null;
                                parametros.ano = null;
                            }
                            parametros.data = novaData;
                            alterarParametros({...parametros});
                        }}
                        carregando={false}
                    />
                }
                </Col>

                { permissaoReducer.desenvolvimentoHabilitarEscolherFuncionario === 'S' &&
                    <Col lg={4}>
                        <Card
                            className={'border-0'}
                        >
                            <Card.Body
                                className={'p-1'}
                            >
                                <ComboMembro 
                                    id={membro ? membro.dados.id : null}
                                    alterou={membro => {
                                        console.log(membro);
                                        if(membro !== null){
                                            alterarMembro({...membro});
                                        }else{
                                            alterarConsulta(null);
                                            alterarMembro(null);
                                        }
                                    }}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                }
                {/* {   permissaoReducer.trelloSincronizar === 'S' &&
                    <Col lg={1}>
                        <Button
                            variant="padrao"
                        >
                            <FontAwesomeIcon className="icone" icon={["fas", "home"]} />
                        </Button>
                    </Col>
                } */}
            </Row>
        

        { consulta &&
        <div className='mb-3'>
            <Row>
                <Col>
                    <CartaoDados 
                        cor={'text-secondary'}
                        descricao={'Aberto'}
                        quantidade={consulta.aberto.quantidadeTodos}
                        quantidadeMes={consulta.aberto.quantidade}
                        icone={'square'}
                        foco={foco === 'aberto' ? true : false}
                        onClick={() => {
                            alterarFoco('aberto');
                        }}
                    />
                </Col>
                <Col>
                    <CartaoDados 
                        cor={'text-primary'}
                        descricao={'Progresso'}
                        quantidade={consulta.progresso.quantidadeTodos}
                        quantidadeMes={consulta.progresso.quantidade}
                        icone={'code-branch'}
                        foco={foco === 'progresso' ? true : false}
                        onClick={() => {
                            alterarFoco('progresso');
                        }}
                    />
                </Col>
                <Col>
                    <CartaoDados 
                        cor={'text-warning'}
                        descricao={'Teste'}
                        quantidade={consulta.teste.quantidadeTodos}
                        quantidadeMes={consulta.teste.quantidade}
                        icone={'keyboard'}
                        foco={foco === 'teste' ? true : false}
                        onClick={() => {
                            alterarFoco('teste');
                        }}
                    />
                </Col>
                <Col>
                    <CartaoDados 
                        cor={'text-info'}
                        descricao={'Concluido'}
                        quantidade={consulta.concluido.quantidade}
                        icone={'clipboard-check'}
                        foco={foco === 'concluido' ? true : false}
                        onClick={() => {
                            alterarFoco('concluido');
                        }}
                    />
                </Col>
                <Col>
                    <CartaoDados 
                        cor={'text-success'}
                        descricao={'Lançado'}
                        quantidade={consulta.lancado.quantidade}
                        icone={'rocket'}
                        foco={foco === 'lancado' ? true : false}
                        onClick={() => {
                            alterarFoco('lancado');
                        }}
                    />
                </Col>
            </Row>
        </div>
        }

        <Row>
            <Col lg={9}>
                <div className={styles.containerCards}>
                    { foco &&
                        <>
                            {/* <Card
                                className={'border-0'}
                            >
                                <Card.Body>
                                    
                                </Card.Body>
                            </Card> */}
                            <BrowserView>
                                { retornaRegistros() }
                            </BrowserView>

                            <MobileView>
                                { retornaRegistros() }
                            </MobileView>
                        </>
                    }
                </div>
            </Col>
        </Row>
    </>

}