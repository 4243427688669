
import { useRef, useState } from 'react';
import { Button, Col, Form, Row, Popover, FormCheck } from 'react-bootstrap';
import Select from 'react-select';

import { ModalFiltrosComponent, PopoverFiltrosComponent } from './components';

import InputDataCombo from "../../../../../Componentes/InputDataCombo";
import ComboIntegracaoSistemas from '../../../../../Componentes/ComboIntegracaoSistemas';
import ComboServidores from '../../../../../Componentes/ComboServidores';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { intersectObject } from '../../../../../Funcoes';

export default function ModalFiltros({
    elemento,
    mostrar,
    alterarMostrar,
    parametros,
    alterarParametros
}){

    // estados
    const filtrosPadrao = {
        pesquisa: null,
        status: null,
        idsIntegracaoSistema: null,
        idServidorAluguel: null,
        dataInicio: null,
        dataFinal: null,
        basePossuiTabelasCabos: null,
        ordenarCampos: ['tamanho']
    };
    const [ filtros, alterarFiltros ] = useState(intersectObject({...filtrosPadrao}, parametros ?? {}));
    const refs = {
        dataInicioCol: useRef(null),
        dataFinalCol: useRef(null)
    };

    const ordenarCampos = [
        {label: 'Tamanho', value: 'tamanho'},
        {label: 'Tamanho tabela', value: 'v1'},
        {label: 'Uso v2', value: 'v2'},
        {label: 'Uso v3', value: 'v3'}
    ];

    // prepara filtro de público/privado
    const simNao = [
        {label: 'Sim', value: 'S'},
        {label: 'Não', value: 'N'}
    ];

    // prepara tabelas
    const tabelas = {
        cabos: 'Cabos',
        cabosFibras: 'Cabos fibras',
        equipamentos: 'Equipamentos',
        equipamentosPortas: 'Equipamentos portas',
        itensRede: 'Itens rede',
        clientes: 'Clientes',
        poligonos: 'Polígonos',
        pastas: 'Pastas',
        historico: 'Histórico'
    };

    // prepara
    let ordenarCamposTabelas = [];

    // percorre tabelas e monta opções
    Object.keys(tabelas).forEach((tabela) => {

        // prepara o início
        let prefixoTabela = `tabelas${tabela.charAt(0).toUpperCase()}${tabela.slice(1)}`;

        // adiciona opções
        ordenarCamposTabelas = [
            ...ordenarCamposTabelas,
            {label: `${tabelas[tabela]} - Tamanho`, value: `${prefixoTabela}Tamanho`},
            {label: `${tabelas[tabela]} - Total registros`, value: `${prefixoTabela}RegistrosTotal`}
        ];

        // mais opções para a tabela...

    });

    // função para limpar os filtros
    function limparFiltros(){

        // prepara
        let filtrosAlterar = {...filtros};

        // percorre filtros
        Object.keys(filtros).map((filtro) => {

            // limpa
            filtrosAlterar[filtro] = filtrosPadrao[filtro] ?? null;

        });

        // altera
        alterarFiltros(filtrosAlterar);
        // alterarParametros({...parametros, ...filtrosAlterar});

    }

    // função para cancelar (sair sem aplicar)
    function cancelar(){

        // fecha
        alterarMostrar(false);

        // altera os filtros para o estado inicial
        let filtrosAlterar = {...filtros};

        // percorre
        Object.keys(filtros).map((filtro) => {

            // altera
            filtrosAlterar[filtro] = parametros[filtro] ?? filtrosPadrao[filtro] ?? null;
        });

        // altera os filtros
        alterarFiltros(filtrosAlterar);

    }

    return <ModalFiltrosComponent
        show={mostrar}
        target={elemento}
        placement="bottom"
        rootClose
        onHide={() => {
            cancelar();
        }}
    >
        <PopoverFiltrosComponent
            size={'sm'}
            title={'Filtros'}
        >
            <Popover.Title>
                Filtros
                <Button
                    size="sm"
                    name="botao-limpar"
                    onClick={() => {

                        // limpa filtros
                        limparFiltros();
                    }}
                >
                    Limpar
                </Button>
            </Popover.Title>
            <Popover.Content>
                <Row>
                    <Col>
                        <Form.Label>Pesquisa <small>(empresa)</small></Form.Label>
                        <Form.Control 
                            value={filtros.pesquisa || ''}
                            onChange={e => {

                                // altera
                                alterarFiltros({...filtros, pesquisa: e.target.value});
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>Servidor</Form.Label>
                        <ComboServidores
                            className="select-ficha"
                            id={filtros.idServidorAluguel || null}
                            config={{
                                pagina: null,
                                registrosPorPagina: null
                            }}
                            alterou={(idServidorAluguel) => {

                                // altera
                                alterarFiltros({...filtros, idServidorAluguel});
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>Integração</Form.Label>
                        <ComboIntegracaoSistemas
                            className="select-ficha"
                            isMulti={true}
                            valor={filtros.idsIntegracaoSistema}
                            aoAlterar={(idsIntegracaoSistema) => {
                                
                                // altera
                                alterarFiltros({
                                    ...filtros,
                                    idsIntegracaoSistema
                                });
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>Data</Form.Label>
                        <Row>
                            <Col className='position-relative' ref={refs.dataInicioCol}>
                                {refs.dataInicioCol.current && <InputDataCombo
                                    size="sm"
                                    placeholder="A partir de"
                                    data={filtros.dataInicio}
                                    onChange={(novaData) => {
                                        alterarFiltros({...filtros, dataInicio: novaData})
                                    }}
                                    options={{
                                        appendTo: refs.dataInicioCol.current
                                    }}
                                />}
                            </Col>
                            <Col className='position-relative' ref={refs.dataFinalCol}>
                                {refs.dataFinalCol.current && <InputDataCombo
                                    size="sm"
                                    placeholder="Até"
                                    data={filtros.dataFinal}
                                    onChange={(novaData) => {
                                        alterarFiltros({...filtros, dataFinal: novaData})
                                    }}
                                    options={{
                                        appendTo: refs.dataFinalCol.current
                                    }}
                                />}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>Possui tabelas de cabos</Form.Label>
                        <Select
                            className="select-ficha"
                            size="sm"
                            options={simNao}
                            value={simNao.find((p) => filtros.basePossuiTabelasCabos && filtros.basePossuiTabelasCabos.includes(p.value))}
                            placeholder='Sem filtro'
                            onChange={(basePossuiTabelasCabos) => {
                                
                                // altera os filtros selecionados
                                alterarFiltros({
                                    ...filtros,
                                    basePossuiTabelasCabos: basePossuiTabelasCabos ? [basePossuiTabelasCabos.value] : null
                                });
                            }}
                            isClearable
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Form.Label>
                            <span className='d-flex align-items-center' style={{gap: '0.125em'}}>
                                <FontAwesomeIcon color="#404040" icon={faSortAmountDown} />
                                Ordenar por
                            </span>
                        </Form.Label>
                        <FormCheck
                            label="Tamanho"
                            id={'ordenarCamposTamanho'}
                            name={'ordenarCamposTamanho'}
                            type={'radio'}
                            onChange={() => {
                                alterarFiltros({
                                    ...filtros,
                                    ordenarCampos: ['tamanho']
                                })
                            }}
                            checked={filtros.ordenarCampos && filtros.ordenarCampos.includes('tamanho')}
                        />
                        <FormCheck
                            label="Tabelas"
                            id={'ordenarCamposTabelas'}
                            name={'ordenarCamposTabelas'}
                            type={'radio'}
                            onChange={() => {

                                // altera
                                alterarFiltros({
                                    ...filtros,
                                    ordenarCampos: ['tabelasCabosTamanho']
                                });
                            }}
                            checked={filtros.ordenarCampos && filtros.ordenarCampos.some(o => o.includes('tabelas'))}
                        />
                        {
                            (filtros.ordenarCampos && filtros.ordenarCampos.some(o => o.includes('tabelas'))) && <Select
                                className="select-ficha"
                                size="sm"
                                options={ordenarCamposTabelas}
                                value={ordenarCamposTabelas.find((s) => filtros.ordenarCampos && filtros.ordenarCampos.includes(s.value))}
                                isMulti={false}
                                placeholder='Sem filtro'
                                onChange={(ordenarCampos) => {
                                    
                                    // altera os filtros selecionados
                                    alterarFiltros({
                                        ...filtros,
                                        ordenarCampos: (
                                            ordenarCampos ? [ordenarCampos.value] : null
                                        )
                                    });
                                }}
                            />
                        }
                    </Col>
                </Row>

                <div className='footer'>
                    <Button
                        size="sm"
                        variant={'light'}
                        onClick={() => {

                            // cancela
                            cancelar();
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        size="sm"
                        variant={'success'}
                        onClick={() => {

                            // altera os parâmetros
                            alterarParametros({...parametros, ...filtros, pagina: 1});
                        }}
                    >
                        Aplicar
                    </Button>
                </div>
            </Popover.Content>
        </PopoverFiltrosComponent>
    </ModalFiltrosComponent>
    
}