import * as R from 'ramda';

export default function(state = Object.assign({
    logado: false,
    dadosUsuario: null,
    trello: null
}, JSON.parse(window.localStorage.getItem('login'))), action){


    // ação
    let type = action.type.split('/');

    // se a ação for referente a esse reducer
    if(type[0] === 'loginReducer'){

        // novo estados
        let novoEstado = R.clone(state);

        // verifica evento executado
        switch(type[1]){

            case 'LOGIN': 
                Object.assign(novoEstado, {
                    logado: true,
                    dadosUsuario: action.dadosUsuario,
                    permissoes: null
                    // horaLogin: action.horaLogin
                });
            break;

            case 'ATUALIZAR': 
                novoEstado.dadosUsuario = {...novoEstado.dadosUsuario,  ...action.dadosUsuario};
            break;

            case 'TEMPO': 
                novoEstado.horaLogin = new Date();;
            break;

            case 'TRELLO':
                novoEstado.trello = action.trello;
            break;

            case 'LOGOFF': 
                window.localStorage.removeItem('login');
                Object.assign(novoEstado, {
                    logado: false,
                    dadosusuario: null,
                    trello: null
                    // horaLogin: null
                });
            break;
        }

        // atualiza dados do estados se não for logoff
        if(type[1] !== 'LOGOFF'){
            window.localStorage.setItem('login', JSON.stringify(novoEstado));
        }

        // finaliza retorna novo estado
        return novoEstado;

    }else{
        return state;
    }

}