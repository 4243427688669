import React, { useState } from 'react';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

// sincroniza
export default function Sincronizar(){

    // estados
    let [carregando, alterarCarregando] = useState(false);

    // inicia a sincronização
    async function iniciar(){
        alterarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/trello/sincronizar`);

        }catch({response}){}
        finally{
            alterarCarregando(false);
        }
    }

    return <>
        <Button
            variant={'padrao'}
            disabled={carregando}
            onClick={iniciar}
        >
            <FontAwesomeIcon 
                className="icone" 
                spin={carregando}
                icon={["fas", "sync"]}
            ></FontAwesomeIcon>
        </Button>
    </>
}