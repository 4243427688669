import { useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { Card, Tab, Tabs } from "react-bootstrap";

import ListaPedidosIntegracao from "./ListaPedidosIntegracao";

import { IntegracaoSistemasComponent } from "./components";
import ListaIntegracaoSistemas from "./ListaIntegracaoSistemas";
import RelatorioPedidosIntegracao from "./RelatoriosIntegracao";
import ListaEmpresasEstatisticas from "./ListaEmpresasEstatisticas";
import ListaEmpresasArquivos from "./ListaEmpresasArquivos";

export default function IntegracaoSistemas(){

    // pega parametros
    const params = useParams();
    const tabInicial = params.tab ?? null;

    // prepara o histórico da página
    const history = useHistory();
    const {
        path,
        url
    } = useRouteMatch();

    // estados
    const [ tab, alterarTab ] = useState(tabInicial ?? 'pedidosIntegracao');

    useEffect(() => {

        // verifica se alterou a tab
        if((tabInicial ?? 'pedidosIntegracao') !== tab){

            // define
            alterarTab(tabInicial ?? 'pedidosIntegracao');
        }
    }, [tabInicial]);

    // retorna
    return <IntegracaoSistemasComponent>
        <Card.Header>
            <div className={'d-flex '}>
                <Card.Title className={'flex-grow-1 d-flex align-items-center mb-0'}>
                    Integrações
                </Card.Title>
            </div>
        </Card.Header>
        <Card.Body className={'py-1'}>
            <RelatorioPedidosIntegracao />
            <Tabs
                id="empresas-integracao-sistemas-tabs"
                className={['tabs', 'mb-2'].join(' ')}
                activeKey={tab}
                onSelect={(t) => {

                    // altera
                    alterarTab(t);

                    // prepara
                    let caminhoTab = `${url}/${t}`;

                    // verifica se possui tab inicial
                    if(tabInicial){

                        // altera para remover a última tab
                        caminhoTab = `./${t}`;
                    }

                    // adiciona
                    history.push(caminhoTab);
                }}
            >
                <Tab eventKey="pedidosIntegracao" title="Pedidos">
                    <ListaPedidosIntegracao />     
                </Tab>
                <Tab eventKey="integracaoSistemas" title="Sistemas">
                    <ListaIntegracaoSistemas />
                </Tab>
                <Tab eventKey="estatisticas" title="Estatísticas">
                    <ListaEmpresasEstatisticas />
                </Tab>
                <Tab eventKey="empresasArquivos" title="Arquivos">
                    <ListaEmpresasArquivos />
                </Tab>
            </Tabs>
        </Card.Body>
    </IntegracaoSistemasComponent>;
}