import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import useIsMounted from 'ismounted';

import axios from 'axios';

import { toast } from 'react-toastify';

import { Button, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Formulario from './Formulario';

import { FichaComponent } from './components';

// contexto
import { FichaContexto } from './FichaContexto';

export default function Ficha(){

    // hooks
    const isMounted = useIsMounted();
    const { path, url } = useRouteMatch();
    const history = useHistory();

    // pega parametros
    let { id } = useParams();
    
    // estados
    const [ modo, alterarModo ] = useState('cadastro');
    const [ carregando, alterarCarregando ] = useState(false);
    const [ salvando, alterarSalvando ] = useState(false);
    const [ dados, alterarDados ] = useState({
        id: null,
        titulo: '',
        texto: '',
        dataFinal: null,
        notificar: 'S',
        tipo: null
    });
    const [ dadosIniciais, alterarDadosIniciais ] = useState(null);
    const [ textoMarkdown, alterarTextoMarkdown ] = useState('');
    const [ filtros, alterarFiltros ] = useState([]);
    const [ configuracao, alterarConfiguracao ] = useState([]);
    const [ erros, alterarErros ] = useState({});

    // effect
    useEffect(() => {

        // se possui id
        if(id){

            // define como edição
            alterarModo('edicao');

            // consulta pelo id para edição
            consultarId();
            
        }else{
            // cadastro
        }

    }, []);

    // função para consultar por id
    async function consultarId(){

        alterarCarregando(true);

        try{

            // consulta pelo id
            let { data } = await axios.get(`/notificacoes/${id}`);

            // pega dados
            alterarDados(data.dados);
            alterarDadosIniciais(data.dados);
            alterarFiltros(data.filtros.map((f) => f.filtros));
            alterarTextoMarkdown(data.textoMarkdown);
            alterarConfiguracao(data.dados.configuracao);

        }catch(e){
            // erro
            console.log(e)
        }finally{

            alterarCarregando(false);
        }
    }

    // função para salvar
    async function salvar(){

        // carrega
        alterarSalvando(true);

        try{

            // faz a edição / cadastro
            let { data } = await axios.post(`/notificacoes`, {
                dados: {
                    ...dados,
                    configuracao
                },
                textoMarkdown,
                filtros
            });

            // sucesso

            // pega dados
            alterarDados(data.dados);
            alterarFiltros(data.filtros.map((f) => f.filtros));
            alterarTextoMarkdown(data.textoMarkdown);
            alterarConfiguracao(data.dados.configuracao);


            // mensagem de cadastro realizado com sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Notificação salva!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    A notificação foi salva com sucesso!
                </div>
            </>);

            // pega dados
            alterarDados(data.dados);
            alterarModo('edicao'); // mesmo se era cadastro, agora é edição

            // monta a url para retroceder
            let urlBack = url.split("/notificacoes")[0] + '/notificacoes';

            // volta e dá refresh pra atualizar a listagem
            history.push(urlBack);

        }catch(e){
            console.log(e);

            // pega response
            let response = e.response;

            // verifica se retornou resposta
            if(response){

                // define erros
                alterarErros(response.data);

                // informa erro
                toast(<>
                    <div
                        style={{background: '#ff6271'}}
                    >
                        <div className="toast-header">
                            <strong className="mr-auto">Notificação não pôde ser salva</strong>
                            <button 
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                            </button>
                        </div>
                        <div className="toast-body text-light">
                            {
                                (response && response.data) &&
                                Object.keys(response.data).map((erro) => <p className={'m-0'} key={erro}>{response.data[erro]}<br/></p>)
                            }
                        </div>
                    </div>
                </>);

            }
        }finally{
            // finalizou
            alterarSalvando(false);
        }
    }

    // retorna
    return <FichaContexto.Provider value={{
        modo,
        dados, alterarDados,
        dadosIniciais, alterarDadosIniciais,
        filtros, alterarFiltros,
        erros, alterarErros,
        textoMarkdown, alterarTextoMarkdown,
        configuracao, alterarConfiguracao,
        carregando, alterarCarregando
    }}>
        <FichaComponent>

            <Col md={10}>

                <Formulario />

                <SalvarFormulario
                    salvando={salvando}
                    cancelar={() => {

                        // volta
                        history.goBack();
                    }}
                    modo={modo}
                    onClick={() => {

                        // salva
                        salvar();
                    }}
                />
            </Col>
        </FichaComponent>
    </FichaContexto.Provider>
}


function SalvarFormulario({
    salvando,
    cancelar,
    modo,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button 
            variant="success" 
            disabled={salvando} 
            onClick={onClick}
        >
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <>
                    <FontAwesomeIcon className="icone" icon={["fas", 'check']} />
                <span>{modo === 'edicao' ? 'Salvar' : 'Cadastrar'}</span>
                </>
            }
        </Button>
    </Form.Group>
}
