import React from 'react';

// componentes
import { Form } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';

export default function ParametrosConsulta({
    parametros, 
    alterarParametros, 
    mostrarFiltros
}){


    return <div className="parametros">
        <div className="parametros-inicial">
            
            <DebounceInput 
                placeholder="" 
                debounceTimeout={400}
                element={Form.Control} 
                value={parametros.texto}
                onChange={(e) =>{
                    alterarParametros({...parametros, texto: e.target.value, pagina: 1})
                }}
            />
            
        </div>
        {
            mostrarFiltros &&
            <div className='mt-3'></div>
        }
    </div>
}