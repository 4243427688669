import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { useSelector } from 'react-redux';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import ComboServidoresEmail from './../../../Componentes/ComboServidoresEmail';
import ConteudoEmail from './../../ModelosEmail/ConteudoEmail';
import Destinatario from './Destinatario';
import InfoAdicional from './InfoAdicional';
import useIsMounted from 'ismounted';

// escreve o email
export default function EscreverEmail(){

    // dados do email
    const isMounted = useIsMounted();
    const history = useHistory();
    const { id, idEmpresa } = useParams();

    // prepara
    const [ modo, alterarModo ] = useState('cadastro'); // cadastro, edicao
    const [ dados, alterarDados ] = useState({
        id: null,
        id_usuario: null,
        descricao: null,
        titulo: null,
        para_nome: null,
        para_email: null,
        outros_destinatarios: [],
        destaque: null,
        conteudo: null,
        responder_para: null,
        // agendado_para: null, // backend converte
        agendado_para_data: null,
        agendado_para_hora: null,
        data_reenvio: null,
        id_imagem: null,
        id_email_servidor_envio: null
    });
    const [ dadosEmpresa, alterarDadosEmpresa ] = useState({});
    const [ salvando, alterarSalvando ] = useState(false);
    const [ carregando, alterarCarregando ] = useState(true);

    // inicializa
    useEffect(() => {

        // verifica se é alteração
        if(id){

            // editando
            alterarModo('edicao');
            consultarIdEnvio();

        }else if(idEmpresa){

            // consulta cliente
            consultarEmpresa();
        }

    }, []);

    // consulta id do email que foi enviado
    async function consultarIdEnvio(){

        // carrega os dados do email enviado
        try{

            // faz a requisição
            let { data } = await axios.get(`/emailsEnviado/${id}`);
            alterarDados(data.dados);

        }catch({response}){

        }finally{
            alterarCarregando(false);
        }
    }

    // consulta os dados do cliente
    async function consultarEmpresa(){

        try{
            
            // faz a requisição
            let { data } = await axios.get(`/empresas/${idEmpresa}`);

            // pega dados
            dados.id_usuario = idEmpresa;
            dados.para_nome = data.dados.razaoSocial;
            dados.para_email = data.dados.email;

            // altera os dados
            alterarDados({...dados});
            alterarDadosEmpresa(data.dados);


        }catch(e){

        }finally{
            alterarCarregando(false);
        }

    }

    // adiciona destinatario
    function adicionarDestinatario(tipo = ''){
        dados.outros_destinatarios.push({
            nome: '',
            email: '',
            tipo: tipo
        });
        alterarDados({...dados});
    }

    // adicionar destinatários de financeiro
    function adicionarDestinatariosFinanceiro(){

        // separa os e-mails
        let emailsFinanceiro = dadosEmpresa.emailFinanceiro.split(',');
        emailsFinanceiro.forEach(email => {

            // se o email já estiver incluido, ignora
            let buscarEmail = dados.outros_destinatarios.filter(destinatario => {
                
                if(destinatario.email === email){
                    return true;
                }
                return false;
            });

            // se não encontrou nenhum, inclui
            if(buscarEmail.length === 0 && email !== dados.para_email){
                dados.outros_destinatarios.push({
                    nome: '',
                    email: email,
                    tipo: ''
                });
            }
        });
        alterarDados({...dados});

    }

    // carrega modelo de email com os dados do cliente
    function carregarModeloEmailCliente(id){

        // envia email
        if(id === null){
            return;
        }

        // faz a requisição para carregar os dados do modelo de email
        axios.get(`/modelosEmail/${id}/${idEmpresa}`)
        .then(({data, ...retorno}) => {
            dados.id_imagem = data.dados.id_imagem;
            dados.titulo = data.dados.titulo;
            dados.destaque = data.dados.destaque;
            dados.conteudo = data.dados.conteudo;
            alterarDados({...dados});
        })
        .catch(() => {})
        .then(() => {})

    }

    // faz o envio do email
    async function salvar(){
        alterarSalvando(true);

        // envia o email
        try{

            // faz a requisição
            let { data } = await axios.post(`/emailsEnviado/enviar`, dados);

            // mensagem de cadastro realizado com sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Email programado!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    {dadosEmpresa.nome}
                </div>
            </>);

            // vai para a consulta de sms's enviados (por enquanto volta para a consulta de clientes)
            history.push('/painel/caixa-saida');

        }catch({response}){

        }finally{
            if (isMounted.current) {
                alterarSalvando(false);
            }
        }

    }

    if(carregando){
        return <div>Carregando...</div>
    }

    return <>
        <Row>
            <Col md='8'>
                <Card className='border-0 shadow-sm'>
                    <Card.Header className='mb-0 border-0 bg-white'>
                        <Card.Title className='pb-0 mb-0'>Destinatarios</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Destinatario 
                            value={{
                                nome: dados.para_nome,
                                email: dados.para_email
                            }}
                            onChange={dadosNovos => {
                                dados.para_nome = dadosNovos.nome;
                                dados.para_email = dadosNovos.email;
                                alterarDados({...dados});
                            }}
                        />

                        { dados.outros_destinatarios.map((destinatario, a) => 
                            <Destinatario 
                                key={a}
                                value={destinatario}
                                onChange={e => {
                                    alterarDados({...dados});
                                }}
                                excluir={() => {
                                    dados.outros_destinatarios.splice(a, 1);
                                    alterarDados({...dados});
                                }}
                            />
                        )}

                        <div
                            className='d-flex align-items-center  mt-2'
                        >
                            <p className='w-50'>
                                { (modo === 'cadastro' && dadosEmpresa.emailFinanceiro.split(',').length > 0) &&
                                    <Button
                                        onClick={e => {
                                            adicionarDestinatariosFinanceiro();
                                        }}
                                        variant='info'
                                        size='sm'
                                        className='mr-2'
                                    >
                                        Incluir financeiro
                                    </Button>
                                }
                            </p>
                            <p className='text-right w-50'>
                                
                                <Button
                                    onClick={e => {
                                        adicionarDestinatario('');
                                    }}
                                    variant='padrao'
                                    size='sm'
                                    className='mr-2'
                                >
                                    Adicionar
                                </Button>
                                
                                <Button
                                    onClick={e => {
                                        adicionarDestinatario('CC');
                                    }}
                                    variant='padrao'
                                    size='sm'
                                    className='mr-2'
                                >
                                    Adicionar CC
                                </Button>
                                
                                <Button
                                    onClick={e => {
                                        adicionarDestinatario('CCO');
                                    }}
                                    variant='padrao'
                                    size='sm'
                                >
                                    Adicionar CCO
                                </Button>
                            </p>
                        </div>
                    </Card.Body>
                </Card>

                <Card className='border-0 shadow-sm mt-4'>
                    <Card.Header className='mb-0 border-0 bg-white'>
                        <Card.Title className='pb-0 mb-0'>Escrever Email</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <ConteudoEmail 
                            value={{
                                id_imagem: dados.id_imagem,
                                titulo: dados.titulo,
                                destaque: dados.destaque,
                                conteudo: dados.conteudo
                            }}
                            onChange={dadosNovos => {
                                Object.assign(dados, dadosNovos);
                                alterarDados({...dados});
                            }}
                        />
                    </Card.Body>
                </Card>

                <Form.Group className="d-flex justify-content-around my-4">
                        <Button 
                            variant="danger" 
                            onClick={() => {
                                history.goBack();
                            }}
                        >Cancelar</Button>
                        <Button 
                            variant="success" 
                            disabled={(salvando || dados.id_email_servidor_envio === null)} 
                            onClick={() => salvar(dados)}
                            // disabled={modo === 'edicao'}
                            hidden={modo === 'edicao' ? true : false}
                        >
                            {salvando ? 
                                <>
                                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                                    <span>Enviando</span>
                                </> : 
                                <>
                                    <FontAwesomeIcon className="icone" icon={["fas", 'paper-plane']} />
                                    <span>{modo === 'edicao' ? 'Reenviar' : 'Enviar'}</span>
                                </>
                            }
                        </Button>
                    </Form.Group>
            </Col>
            <Col md='4'>
                <Card className='border-0 shadow-sm'>
                    <Card.Body className='pb-0'>

                        <InfoAdicional 
                            dados={dados}
                            alterarDados={alterarDados}
                            alterouIdModelo={carregarModeloEmailCliente}
                        />

                        <Form.Group>
                            <Form.Label>Remetente</Form.Label>
                            <ComboServidoresEmail 
                                id={dados.id_email_servidor_envio}
                                alterou={servidorEmail => {
                                    if(servidorEmail !== null){
                                        dados.id_email_servidor_envio = servidorEmail.dados.id;
                                    }else{
                                        dados.id_email_servidor_envio = null;
                                    }
                                    alterarDados({...dados});
                                }}
                            />
                        </Form.Group>

                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>
}
