import { Button, Col, Container, Form, InputGroup, Modal, OverlayTrigger, ProgressBar, Spinner, Tooltip } from "react-bootstrap";
import { FichaComponent } from "../../../Componentes/components";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { faCheck, faInfoCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Confirmar from "../../../Componentes/Confirmar";
import { useSelector } from "react-redux";
import ComboBancosDados from "../../../Componentes/ComboBancosDados";
import ComboServidores from "../../../Componentes/ComboServidores";
import { intersectObject } from "../../../Funcoes";

export default function ModalGerarBackup({
    idEmpresa,

    mostrar,
    alterarMostrar,

    aoFinalizar
}){

    // contextos
    const { loginReducer } = useSelector(state => state);
    
    // dados padrão
    const dadosConexaoPadrao = {
        idBanco: null,
        host: null,
        usuario: null,
        senha: null,
        banco: null,
    };

    // estados
    const [ dados, alterarDados ] = useState({
        ...dadosConexaoPadrao,
        idServidorAluguel: null,
        pasta: null
    });
    const [ dadosServidor, alterarDadosServidor ] = useState(null);
    const [ consultando, alterarConsultando ] = useState(false);
    const [ gerando, alterarGerando ] = useState(false);
    const [ usarDadosBancoEmpresa, alterarUsarDadosBancoEmpresa ] = useState(true);
    const [ usarDadosBancoEmpresaConexao, alterarUsarDadosBancoEmpresaConexao ] = useState(false);
    const [ dadosBancoEmpresa, alterarDadosBancoEmpresa ] = useState(null);
    const [ liberarEditarBanco, alterarLiberarEditarBanco ] = useState(false);
    const [ confirmar, alterarConfirmar ] = useState(null);
    
    // refs
    const toastId = useRef(null);
    
    useEffect(() => {
        
        // verifica se está mostrando
        if(mostrar && !dadosBancoEmpresa){

            // consulta os dados do banco
            consultarDadosBancoEmpresa();
        }

    }, [mostrar]);

    // função para gerar backup 
    async function gerarBackup(){
    
        // altera para gerando
        alterarGerando(true);

        // verifica se está carregando
        if(toastId.current){
            return;
        }

        // esconde
        alterarMostrar(false);

        // faz a requisição
        let oReq = new XMLHttpRequest();
        let ultimaPosicao = null;
        let ultimoJsonRetorno = null;
    
        // prepara para calcular o tempo gasto
        let tempo = 0;
        let verificaTempo = setInterval(function(){
            tempo++
        }, 1000);
        let idBackup = null;
        let backupCancelado = false;
        
        // prepara o render
        const toastRender = (progresso = null) => {

            // retorna
            return <>
                <div className="toast-header">
                    <strong className="mr-auto">
                        {progresso === null ?
                            <>Finalizado</>
                            :
                            <>Gerando backup dos dados da empresa...</>
                        }
                    </strong>
                    <button 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                    </button>
                </div>
                <div className="toast-body">
                    {(progresso === null) ?
                        <>
                            <FontAwesomeIcon className='mr-2' icon={faCheck} />
                            <span>Finalizado!!</span>
                        </>
                        :
                        <ProgressBar animated now={progresso} label={`${parseFloat(progresso).toFixed(2)}%`} />
                    }
                    {
                        idBackup && <Button
                            size="sm"
                            variant="warning"
                            className="mt-1 p-1 px-2"
                            onClick={() => {
    
                                // retorna se não possui o id ainda
                                if(!idBackup) return;
                        
                                // confirma
                                alterarConfirmar({
                                    aberto: true,
                                    titulo: 'Cancelar backup',
                                    texto: `Tem certeza que deseja cancelar o backup iniciado?`,
                                    textoBotaoConfirmar: 'Cancelar backup',
                                    variantConfirmar: 'info',
                                    variantCancelar: 'secondary',
                                    backdrop: true,
                                    aoConfirmar: () => {

                                        // chama o cancelar
                                        cancelarBackup();
                            
                                        // define
                                        backupCancelado = true;
                                    },
                                    aoCancelar: () => {
                                        // ao cancelar
                                    },
                                    aoFechar: () => {
                                        // ao fechar
                                    }
                                })

                            }}
                        >
                            <small
                                className="font-weight-bolder"
                            >Cancelar</small>
                        </Button>
                    }
                </div>
            </>
        }

        // instancia um toast
        toastId.current = toast(({ closeToast }) => toastRender(0), {
            autoClose: false
        });

        // função para cancelar o processo
        async function cancelarBackup(){

            try{

                // prepara
                let dadosCancelar = {};

                // verifica se não deve usar os dados do banco da empresa
                if(!usarDadosBancoEmpresa){

                    // pega os dados preenchidos para gerar
                    dadosCancelar.dadosConexao = dados;
                            
                    // verifica se é base de teste
                    if(dadosServidor && dados.baseTeste === 'S'){

                        // concatena o servidor
                        dadosCancelar.dadosConexao.usuario = dadosServidor.nome.toLowerCase() + '_' + dados.usuario;
                        dadosCancelar.dadosConexao.banco = dadosServidor.nome.toLowerCase() + '_' + dados.banco;
                    }

                }

                // chama o cancelar
                let { data } = await axios.delete(`/empresas/${idEmpresa}/backups/${idBackup}/cancelar`, {
                    data: dadosCancelar
                });

                // sucesso
                
                // informa
                toast(({closeToast }) => <>
                    <div className="toast-header">
                        <strong className="mr-auto">Backup cancelado!</strong>
                        <button 
                            onClick={closeToast} 
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="toast-body">
                        Backup foi cancelado! Nenhum arquivo de backup foi salvo.
                    </div>
                </>);
                

            }catch(e){
                // erro

                // mensagem de erro
                toast(({closeToast }) => <>
                    <div className="toast-header">
                        <strong className="mr-auto">Erro ao cancelar</strong>
                        <button 
                            onClick={closeToast} 
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="toast-body">
                        Erro ao cancelar o backup. Tente novamente ou entre em contato com o suporte
                    </div>
                </>);
                
            }finally{
                // finalizado

                // remove o carregamento
            }
        }

        // ao receber o retorno da requisição
        oReq.onreadystatechange = async (e) => {
    
            // pega log atual
            let partesRetorno = e.target.responseText.split('}{');
            let totalPartes = partesRetorno.length;
    
            // adiciona
            let logAtual = totalPartes > 1 ? '{' + partesRetorno[totalPartes - 1] : partesRetorno[0];
            ultimaPosicao = e.target.responseText.length;
    
            // pega o retorno
            let retorno = null;
    
            try{
                retorno = JSON.parse(logAtual)
            }catch(e){
                // console.log(e);
            }
    
            // enquanto executa
            if(e.target.readyState === 3 || e.target.readyState === 4){
    
                // verifica se pegou o retorno
                if(!retorno){
                    // sem retorno
                }else{
    
                    // verifica se retornou o id do processo
                    if(retorno.id){
    
                        // pega o id
                        idBackup = retorno.id;
                    }
    
                    // se enviado o processo (último retorno são os dados)
                    if(retorno.concluido){
    
                        // atualiza o toast com a porcentagem
                        toast.update(toastId.current, {
                            render: toastRender(retorno.concluido)
                        });
                        
                    }
    
                    // verifica se tem informações
                    if(retorno.cancelado){
    
                        // define
                        backupCancelado = backupCancelado || retorno.cancelado === 'S';
                    }
    
                    // verifica se tem erros
                    if(retorno.erros){
    
                        // console.log(retorno.erros);
                    }
                    
                }
            
            }
    
            // execução finalizada
            if(e.target.readyState === 4){
    
                // limpa a contagem
                clearInterval(verificaTempo);
    
                // verifica se possui retorno
                if(retorno){
                    
                    // atualiza o toast com a porcentagem
                    toast.update(toastId.current, {
                        render: toastRender(retorno.concluido < 100 ? retorno.concluido : null)
                    });
                }
                
                // remove
                toast.dismiss(toastId.current);
                
                // limpa
                toastId.current = null;

                // altera para não gerando
                alterarGerando(false);

                // executa o callback
                aoFinalizar();

                // verifica se a importação foi cancelada
                if(!backupCancelado && e.target.status == 200){
    
                    // calcula o tempo da execução
                    let duracao = (tempo / 60)
                    if(duracao < 1){
                        duracao = tempo+'s'
                    }else{
                        duracao = duracao.toFixed(2)+'min'
                    }
    
                    // informa
                    toast(({closeToast }) => <>
                        <div className="toast-header">
                            <strong className="mr-auto">Backup finalizado</strong>
                            <button 
                                onClick={closeToast} 
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="toast-body">
                            Backup gerado com sucesso! ({duracao})
                        </div>
                    </>);

                }else if(!backupCancelado){
                    // erro
                    
                    // pega os erros
                    let erros = retorno ?? {};
    
                    // mostra novamente?
                    alterarMostrar(true);
    
                    // mensagem de cadastro realizado com sucesso
                    toast(({closeToast }) => <>
                        <div className="toast-header">
                            <strong className="mr-auto">Erro ao gerar backup</strong>
                            <button 
                                onClick={closeToast} 
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="toast-body">
                            O backup não pôde ser gerado!
                            {
                                Object.keys(erros).filter((erro) => erro !== 'info').map((erro) => {
                                    return <>
                                        <br/>
                                        {
                                            erro === 'conexao' ? 
                                            <>Ocorreu um erro de conexão ao gerar o backup. Por favor, tente novamente.</> 
                                            : <>&nbsp;&nbsp;&rsaquo; {erros[erro]}</>
                                        }
                                    </>
                                })
                            }
                        </div>
                    </>);
                    
                }
            }
    
        };
        
        // inicia a atualização
        oReq.open("POST", `${process.env.REACT_APP_URL_PHP}/empresas/${idEmpresa}/backups/processo`, true);
        oReq.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        oReq.setRequestHeader("tokenusuario", loginReducer.dadosUsuario.token);
    
        // prepara
        let dadosGerar = {};
        
        // verifica se não deve usar os dados do banco da empresa
        if(!usarDadosBancoEmpresa || usarDadosBancoEmpresaConexao){

            // pega os dados preenchidos para gerar
            dadosGerar.dadosConexao = intersectObject(dadosConexaoPadrao, dados);
            
            // verifica se é base de teste
            if(dadosServidor && dados.baseTeste === 'S'){

                // concatena o servidor
                dadosGerar.dadosConexao.usuario = dadosServidor.nome.toLowerCase() + '_' + dados.usuario;
                dadosGerar.dadosConexao.banco = dadosServidor.nome.toLowerCase() + '_' + dados.banco;
            }

            // define
            dadosGerar = {
                // configuração
                ...dadosGerar,
                idServidorAluguel: dados.idServidorAluguel,
                pasta: dados.pasta
            }

        }

        // dados
        oReq.send(JSON.stringify({
            // configuração
            ...dadosGerar
        }));
    
    }

    async function consultarDadosBancoEmpresa(){
 
        // define como consultando
        alterarConsultando(true);

        try{

            // consulta os dados de conexão
            let { data } = await axios.get(`/empresas/${idEmpresa}/base`);

            // sucesso

            // finaliza
            alterarConsultando(false);
            
            // altera os dados do banco da empresa
            alterarDadosBancoEmpresa({
                ...data.conexao,

                ...(data.servidor ? {
                    idServidorAluguel: data.servidor.id,
                    pasta: data.servidor.pasta,

                    dadosServidor: data.dadosServidor
                } : {})
            });

            // verifica se está usando os dados da empresa
            if(usarDadosBancoEmpresa){

                // altera os dados
                alterarDados({
                    ...dados,
                    idBanco: data.conexao.idBanco,
                    host: data.conexao.escrita,
                    usuario: data.conexao.usuario,
                    senha: data.conexao.senha,
                    banco: data.conexao.usuario + `_geogrid`,

                    ...(data.servidor ? {
                        idServidorAluguel: data.servidor.id,
                        pasta: data.servidor.pasta
                    } : {})
                });

                // define os dados do servidor
                alterarDadosServidor(data.dadosServidor);
            }

        }catch(e){
            // empresa não tem os dados de conexão

            // consulta os dados do arquivo de conexão pdo
            consultarDadosBancoEmpresaArquivoConexao();
            
        }
    }

    async function consultarDadosBancoEmpresaArquivoConexao(){
 
        // define como consultando
        alterarConsultando(true);

        try{

            // consulta os dados de conexão
            let { data } = await axios.get(`/empresas/${idEmpresa}/base/arquivoConexao`);

            // sucesso

            // não usar os dados da empresa
            alterarUsarDadosBancoEmpresa(true);
            alterarUsarDadosBancoEmpresaConexao(true);

            // altera os dados do banco da empresa
            alterarDadosBancoEmpresa({
                ...data.conexao,

                ...(data.servidor ? {
                    idServidorAluguel: data.servidor.id,
                    pasta: data.servidor.pasta,

                    dadosServidor: data.dadosServidor
                } : {})
            });

            // verifica se está usando os dados da empresa
            if(usarDadosBancoEmpresa){

                // altera os dados
                alterarDados({
                    ...dados,
                    idBanco: data.conexao.idBanco,
                    host: data.conexao.escrita,
                    usuario: data.conexao.usuario,
                    senha: data.conexao.senha,
                    banco: data.conexao.usuario + `_geogrid`,

                    ...(data.servidor ? {
                        idServidorAluguel: data.servidor.id,
                        pasta: data.servidor.pasta
                    } : {})
                });

                // define os dados do servidor
                alterarDadosServidor(data.dadosServidor);
            }

        }catch(e){
            // empresa não tem os dados de conexão

            // não usar os dados da empresa
            alterarUsarDadosBancoEmpresa(false);

        }finally{

            // finaliza
            alterarConsultando(false);
        }
    }

    return <>
        <Confirmar config={confirmar} alterar={alterarConfirmar}/>
        <Modal
            show={mostrar}
            centered
            onExited={() => {

                // fecha
                alterarMostrar(false);
            }}
            onHide={() => {

                // fecha
                alterarMostrar(false);
            }}
            size={'md'}
        >
            <Modal.Header className='border-0'>
                <Modal.Title 
                    as='h5' 
                    className={[
                        'text-center w-100',
                        'text-success'
                    ].join(' ')}
                >
                    Gerar backup
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={'pt-0'}>
                <FichaComponent>
                    <Container><Form.Row>
                            <Col>
                                <Form.Label>Endereço</Form.Label>
                                <Form.Row>
                                    <Col>
                                        {consultando ? <div className="text-center">
                                            <FontAwesomeIcon icon={faSpinner} pulse /> Carregando...
                                        </div> : <ComboServidores
                                            className="select-ficha"
                                            placeholder="Servidor"
                                            valor={dados.idServidorAluguel}
                                            alterou={(idServidorAluguel, dadosServidorAluguel) => {
                                                alterarDados({
                                                    ...dados,
                                                    idServidorAluguel: (idServidorAluguel !== '') ? idServidorAluguel : null
                                                });
                                                alterarDadosServidor(dadosServidorAluguel);
                                            }}
                                            esconderIconeDrop={true}
                                            filtros={{
                                                interno: ''
                                            }}
                                            disabled={usarDadosBancoEmpresa}
                                        />}
                                    </Col>
                                    <Col md="7 pl-0">
                                        <InputGroup>
                                            <InputGroup.Text size="sm" className="grupo-texto-inicio">
                                                <small><small>geogridmaps.com.br/</small></small>
                                            </InputGroup.Text>
                                            <Form.Control
                                                placeholder="pasta"
                                                value={dados.pasta || ''}
                                                onChange={e => {
                                                    alterarDados({
                                                        ...dados,
                                                        pasta: e.target.value,
                                                        usuario: e.target.value,
                                                        banco: liberarEditarBanco ? dados.banco : e.target.value + '_geogrid'
                                                    });
                                                }}
                                                disabled={usarDadosBancoEmpresa}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Form.Row>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Form.Label>Banco</Form.Label>
                                <ComboBancosDados
                                    className="select-ficha"
                                    valor={dados.idBanco}
                                    alterou={(idBanco, bancoDados) => {
                                        alterarDados({
                                            ...dados,
                                            idBanco: (idBanco !== '') ? idBanco : null,
                                            host: bancoDados ? bancoDados.dados.escrita : dados.host
                                        });
                                    }}
                                    disabled={usarDadosBancoEmpresa}
                                />
                            </Col>
                            <Col>
                                <Form.Label>Senha</Form.Label>
                                <Form.Control 
                                    value={dados.senha || ''}
                                    onChange={e => {
                                        alterarDados({...dados, senha: e.target.value});
                                    }}
                                    disabled={usarDadosBancoEmpresa}
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <div className={'d-flex justify-content-between'}>
                                    <Form.Label>Base GeoGrid</Form.Label>
                                    <Form.Check
                                        id="switch-editar-base-padrao"
                                        label="Editar base padrão"
                                        checked={liberarEditarBanco && !usarDadosBancoEmpresa}
                                        disabled={usarDadosBancoEmpresa}
                                        onChange={e => {
                                            alterarLiberarEditarBanco(!liberarEditarBanco);
                                        }}
                                    />
                                </div>
                                <Form.Control 
                                    value={dados.banco || ''}
                                    onChange={e => {
                                        alterarDados({...dados, banco: e.target.value});
                                    }}
                                    disabled={usarDadosBancoEmpresa || !liberarEditarBanco}
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col className="d-flex justify-content-end">
                                <Form.Check 
                                    className='mr-0'
                                    type='switch'
                                    inline
                                    id={'switch-consultar'}
                                    label={`Usar dados de acesso da empresa`}
                                    checked={usarDadosBancoEmpresa}
                                    disabled={!dadosBancoEmpresa}
                                    onChange={e => {
                                        
                                        // verifica se vai usar os dados
                                        if(!usarDadosBancoEmpresa){
                                                
                                            // altera os dados
                                            alterarDados({
                                                ...dados,
                                                idBanco: dadosBancoEmpresa.idBanco,
                                                host: dadosBancoEmpresa.escrita,
                                                usuario: dadosBancoEmpresa.usuario,
                                                senha: dadosBancoEmpresa.senha,
                                                banco: dadosBancoEmpresa.usuario + `_geogrid`,
                                                pasta: dadosBancoEmpresa.pasta,
                                                idServidorAluguel: dadosBancoEmpresa.idServidorAluguel
                                            });

                                            // define os dados do servidor
                                            alterarDadosServidor(dadosBancoEmpresa.dadosServidor);
                                        }
                                        
                                        // altera
                                        alterarUsarDadosBancoEmpresa(!usarDadosBancoEmpresa);

                                    }}
                                />
                            </Col>
                        </Form.Row>
                        {!usarDadosBancoEmpresa && <Form.Row>
                            <Col className="d-flex justify-content-end">
                                <Form.Check 
                                    className='mr-0'
                                    type='switch'
                                    inline
                                    id={'switch-base-teste'}
                                    label={<>
                                        Base de teste
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip>
                                                    Concatena o servidor em frente à pasta para o usuário do banco
                                                </Tooltip>
                                            }
                                        >
                                            <FontAwesomeIcon className="ml-1" icon={faInfoCircle} fontSize={12} color="grey"></FontAwesomeIcon>
                                        </OverlayTrigger>
                                    </>}
                                    checked={dados.baseTeste === 'S'}
                                    onChange={e => {
                                        
                                        // altera os dados
                                        alterarDados({
                                            ...dados,
                                            baseTeste: e.target.checked ? 'S' : 'N'
                                        });

                                    }}
                                />
                            </Col>
                        </Form.Row>}
                    </Container>
                </FichaComponent>
            </Modal.Body>
            <Modal.Footer className='border-0 d-flex align-items-center justify-content-between'>
                <Button
                    size="sm"
                    variant='secondary'
                    onClick={() => {alterarMostrar(false)}}
                    disabled={gerando}
                >
                    <span>Fechar</span>
                </Button>
                <Button
                    size="sm"
                    variant='success'
                    onClick={gerarBackup}
                    disabled={gerando}
                >
                    {gerando ? 
                        <>
                            <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                            <span>Gerando</span>
                        </> : 
                        <span>Gerar backup</span>
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}