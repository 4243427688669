import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import useIsMounted from 'ismounted';

import axios from 'axios';

import { Button, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Formulario from './Formulario';

import { FichaComponent } from './components';

export default function Ficha(){

    // hooks
    const isMounted = useIsMounted();
    const { path, url } = useRouteMatch();
    const history = useHistory();

    // pega parametros
    let { id } = useParams();
    
    // estados
    const [ modo, alterarModo ] = useState('cadastro');
    const [ carregando, alterarCarregando ] = useState(false);
    const [ salvando, alterarSalvando ] = useState(false);
    const [ dados, alterarDados ] = useState({
        id: null,
        titulo: '',
        texto: ''
    });
    const [ dadosIniciais, alterarDadosIniciais ] = useState(null);
    const [ erros, alterarErros ] = useState({});

    // effect
    useEffect(() => {

        // se possui id
        if(id){

            // define como edição
            alterarModo('edicao');

            // consulta pelo id para edição
            consultarId();
            
        }else{
            // cadastro
        }

    }, []);

    // função para consultar por id
    async function consultarId(){

        alterarCarregando(true);

        try{

            // consulta pelo id
            let { data } = await axios.get(`/notificacoes/templates/${id}`);

            // pega dados
            alterarDados(data.dados);
            alterarDadosIniciais(data.dados);

        }catch(e){
            // erro
            console.log(e)
        }finally{

            alterarCarregando(false);
        }
    }

    // função para salvar
    async function salvar(){

        // carrega
        alterarSalvando(true);
        alterarErros({});

        try{

            // faz a edição / cadastro
            let { data } = await axios.post(`/notificacoes/templates`, {
                dados: {
                    ...dados
                }
            });

            // sucesso

            // pega dados
            alterarDados(data.dados);
            alterarModo('edicao'); // mesmo se era cadastro, agora é edição

            // monta a url para retroceder
            let urlBack = url.split("/notificacoes/templates")[0] + '/notificacoes/templates';
            
            // volta e dá refresh pra atualizar a listagem
            history.push(urlBack);

        }catch(e){
            console.log(e);

            // pega response
            let response = e.response;

            // verifica se retornou resposta
            if(response){

                // define erros
                alterarErros(response.data);
            }
        }finally{
            // finalizou
            alterarSalvando(false);
        }
    }

    // retorna
    return <FichaComponent>
        <Col md={10}>

            <Formulario
                modo={modo}
                dados={dados}
                alterarDados={alterarDados}
                dadosIniciais={dadosIniciais}
                carregando={carregando}
                salvando={salvando}
                erros={erros}
                alterarErros={alterarErros}
            />

            <SalvarFormulario
                salvando={salvando}
                cancelar={() => {

                    // volta
                    history.goBack();
                }}
                modo={modo}
                onClick={() => {

                    // salva
                    salvar();
                }}
            />
        </Col>
    </FichaComponent>
}


function SalvarFormulario({
    salvando,
    cancelar,
    modo,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button 
            variant="success" 
            disabled={salvando} 
            onClick={onClick}
        >
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <>
                    <FontAwesomeIcon className="icone" icon={["fas", 'check']} />
                <span>{modo === 'edicao' ? 'Salvar' : 'Cadastrar'}</span>
                </>
            }
        </Button>
    </Form.Group>
}
