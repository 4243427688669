import styled from 'styled-components';
import { Card, Modal } from 'react-bootstrap';

export const ModalVisualizarEnderecoPesquisaComponent = styled(Modal).attrs({

})`

    .modal-header{

        .modal-title{
            width: 100%;
            text-align: center;

            small{
                font-size: 0.525em;
            }
        }
    }

    .input-group{

        + .input-group{
            margin-top: 5px;
        }
    }

    .conteudo-retorno{
        padding: 0.5em;
        /* background: #f9f9f9;
        border-radius: 0.3em; */
            
        .tabs{
            font-size: 13px;
            margin-bottom: 0;

            a{
                color: #5a5a5a;
                padding: 2px 14px;
                background: none !important;
                border: none !important;
                outline: none;

                &[aria-selected=true]{
                    border-bottom: 1px solid #34dc34 !important;
                    margin-bottom: -1px !important;
                }
                
                &:hover{
                    color: #4b4a4a;
                }

                &:only-child{
                    display: none;
                }
            }

        }

        .tab-content{
            background: #f9f9f9;
            padding: 0.5em;
            border-radius: 0.3em;

            h6{
                color: #434343;
            }

            .info-sem-registros{
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.4em;
                background: #f0f0f0;
                border-radius: 0.2em;
                color: grey;
                box-shadow: inset 0px 0px 1px #dedede;
            }
        }

    }
    
`;