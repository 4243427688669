import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { Form } from 'react-bootstrap';
import axios from 'axios';

// componentes
import OptionSelect from './OptionSelect';

export default function ComboUsuarios({
    id, // id do membro
    alterou,
    parametrosPassados = {},
    alteradoDadosRegistro
}){

    // estados
    let [registros, alterarRegistros] = useState([]);
    let [carregando, alterarCarregando] = useState(true);
    let [dadosRegistro, alterarDadosRegistro] = useState(null);
    let [parametros, alterarParametros] = useState(Object.assign({
        pagina: 1,
        registrosPorPagina: 5,
        tipo_de_acesso: [2],
        filtros_modo: []
    }), parametrosPassados);

    // consulta id do usuário
    useEffect(() => {
        
        // carrega alguns
        consultarRegistros('');

        // verifica se possui usuário
        if(id !== null){

            // consulta os dados do membro selecionado
            consultarId();

        }else{
            alterarCarregando(false);
        }

    }, [id]);

    // consulta membro
    async function consultarId(){
        alterarCarregando(true);
        
        try{
            
            // faz a consulta do id
            let { data } = await axios.get(`/cliente/${id}`);
            alterarDadosRegistro(data);

            // se passada função de retorno
            if(typeof alteradoDadosRegistro !== "undefined"){
                alteradoDadosRegistro(data);
            }

        }catch({response}){

        }finally{
            alterarCarregando(false);
        }
    }

    // consulta os responsaveis
    async function consultarRegistros(inputValue, callback = null){

        // consultar membros
        try {

            let { data } = await axios.get(`/cliente`, {
                params: Object.assign({
                    descricao: inputValue
                }, parametros)
            });
            alterarRegistros(data.registros);
            if(callback !== null){
                callback(data.registros)
            }

        }catch({response}){
            
        }finally{

        }

    }

    return <>
        <AsyncSelect 
            isDisabled={carregando}
            isClearable
            loadOptions={consultarRegistros} // ao alterar, carrega
            defaultOptions={registros}       // lista pré carregada
            value={dadosRegistro && {
                label: dadosRegistro.dados.nome
            }}
            onChange={(e) => {
                
                if(e === null){
                    alterarDadosRegistro(null);
                    alterou(null);
                }else{
                    alterarDadosRegistro({...e});
                    alterou(e);
                }
            }}
            components={{
                Option: OptionSelect
            }}
            noOptionsMessage={() => 'Digite algo para fazer a consulta'}
            loadingMessage={() => 'Carregando...'}
            placeholder='Empresa'
        />
    </>
}