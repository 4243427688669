import styled from 'styled-components';
import { Card, Modal } from 'react-bootstrap';
import { ListaComponent } from './../components';

export const ListaEnderecosPesquisaComponent = styled(ListaComponent).attrs({
    className: `painel-consulta border-0`
})`
    
    .tabela{
        
        .registro{
            --cor-status-registro: black;
            
            &.encontrado-retornoReutilizado{
                --cor-status-registro: #816ecd;
            }

            &.encontrado-interno{
                --cor-status-registro: #5da218;
            }

            &.encontrado-google{
                --cor-status-registro: #d17401;
            }

            &.nao-encontrado{
                --cor-status-registro: #d24b4c;
            }

            small{
                line-height: 1.25em;
            }

        }
    }

    .legenda{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5em;
        font-size: 0.8em;
        margin: 0.7em;

        div{
            display: flex;
            padding: 0 0.4em;
            border-radius: 0.3em;
            border-left: 3px solid;
            background: #fafafa;
            font-weight: 400;
            color: #434343;

            &.nao-encontrado{
                border-color: #d24b4c;
            }

            &.google{
                border-color: #d17401;
            }

            &.retornoReutilizado{
                border-color: #816ecd;
            }

            &.interno{
                border-color: #5da218;
            }
        }
    }

`;