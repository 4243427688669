import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import moment from 'moment';
import styles from './../index.module.scss';

// componentes
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Row, Col, Form, Button, InputGroup, Tabs, Tab, Spinner, Alert, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import useIsMounted from 'ismounted';

import GraficoPie from './../../GraficoPie';

// contextos    
import { PainelContexto } from './../../../../PainelContexto';

// função para o relatório de acessos
export default function TotaisPesquisas({
    parametros
}){
      
    // dados
    const permissaoReducer = useSelector(state => state.permissaoReducer);
    const isMounted = useIsMounted();
    const history = useHistory();
    
    const [dados, alterarDados] = useState(null);
    const [carregando, alterarCarregando] = useState(true);
    const [erro, alterarErro] = useState(false);

    // consulta os dados das empresas
    async function consultar(){

		// inicia carregamento
        alterarCarregando(true);

        try{
            
            // faz a requisição
            let { data } = await axios.get(`/relatorios/pesquisas/totais`, {
                params: parametros
            });
            
			alterarDados({...data.dados});

            alterarErro(false);
			// // desseleciona empresa
			// alterarEmpresaSelecionada(null);
			// alterarDadosEmpresaSelecionada(null)
			
        }catch(e){
            console.log('pegou o erro', e)
            alterarErro(true);
        }finally{
            alterarCarregando(false);
        }

	}
	
    // inicializa
    useEffect(() => {

        // remove erro se tiver
        alterarErro(false);

        // executa
		consultar();

    }, [parametros]);

    // função para montar os dados do gráfico
	const retornaDadosPie = (dados) => {

        return [
            {
				name: 'Encontrados sem google',
				value: parseInt(dados.total_pesquisas_encontradas_sem_google)
            },
            {
				name: 'Google não encontradas',
				value: parseInt(dados.total_pesquisas_google_nao_encontradas)
			},
            {
				name: 'Refeitas',
				value: parseInt(dados.total_pesquisas_refeitas)
			},
            {
				name: 'Pesquisas não encontradas',
				value: parseInt(dados.total_pesquisas_nao_encontradas)
			}
        ]

	}

    
    return <>
        <Card className='border-0'>
            <Card.Header className='py-2 mb-0 border-0 bg-white'>
                <b>Pesquisas</b>
                <OverlayTrigger 
                    trigger="click"
                    overlay={
                        <Tooltip 
                            className={[styles.tooltipInfo].join(' ')}
                        >
                            <ul>
                                <li>Banco: quantidade de pesquisas encontradas no nosso banco e aceitas.</li>
                                <li>Google: quantidade de pesquisas que foram para o google, refeitas ou não encontradas no banco.</li>
                            </ul>
                        </Tooltip>
                    }
                    placement='bottom'
                    delay={{ show: 150, hide: 400 }}
                >
                    <FontAwesomeIcon 
                        className={[styles.icone].join(' ')} 
                        icon={'question-circle'}
                    ></FontAwesomeIcon>
                </OverlayTrigger>
            </Card.Header>
            <Card.Body className='py-2'>
                <Row>
                    {
                        /* se ocorreu algum erro */
                        erro ?
                        <Col align="center">
                            <Alert variant={'danger'}>
                                Ocorreu um erro!
                            </Alert>
                        </Col>
                        :
                        <>
                            {
                                carregando  ?

                                <Col align="center">
                                    <Spinner animation="border" />
                                </Col>

                                :
                                <>
                                    <Col align="center">
                                        <Row>
                                            <Col align="center">
                                                <b 
                                                    style={{
                                                        color: '#007bf0',
                                                        fontSize: 'clamp(1.5em, 2.5vw, 3em)'
                                                    }}
                                                >
                                                    {dados.total_pesquisas}
                                                </b>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Row>
                                                    Banco: {dados.total_pesquisas_encontradas_sem_google}
                                                </Row>
                                                <Row>
                                                    Google: {dados.total_pesquisas_google}
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {
                                        retornaDadosPie(dados).filter((e) => e.value > 0).length > 0 && 
                                        <Col xl={6}>
                                            <Row className="justify-content-center">
                                                <GraficoPie
                                                    dadosGrafico={retornaDadosPie(dados)}
                                                    config={{
                                                        tamanho: 180,
                                                        responsivo: true,
                                                        cores: ['#d97732', '#d8c302', '#d84302', '#e7e938']
                                                    }}
                                                />
                                            </Row>
                                        </Col>
                                    }
                                </>
                            }
                        </>
                    }
                </Row>
            </Card.Body>
        </Card>
    </>
}
