import moment from "moment";
import { Badge } from "react-bootstrap";

export default function Registro({
    dados
}){

    return <> 

        <tr className={['registro', dados.metodo.toLowerCase()].join(' ')}>

            <td className={'estreito text-center font-weight-bold integracao'}>
                {dados.metodo}
            </td>

            <td className="total">
                {dados.total}
            </td>
            
            <td className="descricao">
                <span className={['text-center font-weight-bold versao', dados.versao].join(' ')}>
                    {dados.versao}
                </span>
                {dados.rota}
            </td>

        </tr>
    </>
}