import React, { useState, useEffect } from 'react';
import axios from 'axios';

// componentes
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaskedInput from 'react-maskedinput';
import Select from 'react-select';
import { Card, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';

// retorna conteudo
export default function Formulario({
    dados={},
    alterarDados,
    erros,
    alterarErros,
    leitura,
    modo
}){

    const { loginReducer } = useSelector(state => state);
    const history = useHistory();
    
    // array para o select de tipos sanguíneos
    const cargos = [
        {value: 'Gerente financeiro', label: 'Gerente financeiro'},
        {value: 'Gerente Comercial', label: 'Gerente Comercial'},
        {value: 'Comercial', label: 'Comercial'},
        {value: 'Financeiro', label: 'Financeiro'},
        {value: 'Suporte técnico', label: 'Suporte técnico'},
        {value: 'Programador', label: 'Programador'}
    ]

    let cargoAtual = dados.cargoAtual && cargos.find((e) => e.value === dados.cargoAtual);
    
    if(dados.cargoAtual && !cargoAtual){
        cargoAtual = {
            value: dados.cargoAtual,
            label: dados.cargoAtual
        }
        
        cargos.push(cargoAtual);
    }

    // prepara objeto para armazenar referências
    let referencias = {}

    // busca cep
    function buscarCep(cepBuscar){
        let buscarCepCon = axios.create({ headers: null });

        // se foi totalmente preenchido
        if(cepBuscar.length === 8){

            // faz a busca
            buscarCepCon.get("https://viacep.com.br/ws/"+ cepBuscar +"/json/", {
                responseType: 'json'
            }).then(retorno => {
                
                alterarDados({...dados, 
                    cidade: retorno.data.localidade,
                    estado: retorno.data.uf,
                    endereco: retorno.data.logradouro,
                    bairro: retorno.data.bairro,
                    cep: cepBuscar
                });
            });

        }
    }

    return <>
        <Card className='border-0 shadow-sm mb-4'>
            <Card.Header className='mb-0 border-0 bg-white'>
                <Card.Title className='pb-0 mb-0'>{
                    dados.id === loginReducer.dadosUsuario.id ? 'Meus dados' : (
                        modo === 'cadastro' ? 'Novo funcionário' : 'Ficha'
                    )
                }</Card.Title>
            </Card.Header>
            <Card.Body className={['formulario'].join(' ')}>
                <Form.Group>
                    <Form.Row>
                        <Col>
                            <Form.Label>Nome completo</Form.Label>
                            <Form.Control 
                                size="sm"
                                value={dados.nome || ''}
                                onChange={e => {
                                    alterarDados({...dados, nome: e.target.value});
                                }}
                                readOnly={leitura}
                            />
                            {(erros.nome) && 
                                <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.nome}</p></Alert>
                            }
                        </Col>
                        <Col md={5}>
                            <Form.Label>Cargo</Form.Label>
                            <CreatableSelect 
                                className="select-ficha"
                                value={cargoAtual}
                                isClearable
                                onChange={(e) => {
                                    alterarDados({...dados, cargoAtual: e ? e.value : null});
                                }}
                                options={cargos}
                            />
                            {(erros.cargoAtual) && 
                                <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.cargoAtual}</p></Alert>
                            }
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col sm={12} md={7}>
                            <Form.Label>CPF</Form.Label>
                            <Form.Control 
                                size="sm"
                                value={dados.cpf || ''}
                                onChange={e => {
                                    alterarDados({...dados, cpf: e.target.value});
                                }}
                                readOnly={leitura}
                            />
                            {(erros.cpf) && 
                                <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.cpf}</p></Alert>
                            }
                        </Col>
                        <Col sm={12} md={5}>
                            <Form.Label>RG</Form.Label>
                            <Form.Control 
                                size="sm"
                                value={dados.rg || ''}
                                onChange={e => {
                                    alterarDados({...dados, rg: e.target.value});
                                }}
                                readOnly={leitura}
                            />
                            {(erros.rg) && 
                                <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.rg}</p></Alert>
                            }
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Label>CEP</Form.Label>
                            <MaskedInput
                                className={['form-control'].join(' ')}
                                mask="11111-111"
                                value={dados.cep}
                                ref={referencias.cep = React.useRef()}
                                onChange={(e) => {
                                    let cepNovo = referencias.cep.current.mask.getRawValue().split('_').join('');
                                    buscarCep(cepNovo);
                                    alterarDados({...dados, cep: cepNovo});
                                    
                                }}
                                readOnly={leitura}
                            />
                            {(erros.cep) && 
                                <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.cep}</p></Alert>
                            }
                        </Col>
                        <Col sm={12} md={8}>
                            <Form.Label>Endereço</Form.Label>
                            <Form.Control 
                                size="sm"
                                value={dados.endereco || ''}
                                onChange={e => {
                                    alterarDados({...dados, endereco: e.target.value});
                                }}
                                readOnly={leitura}
                            />
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col xs={9} sm={9} md={9}>
                            <Form.Label>Cidade</Form.Label>
                            <Form.Control 
                                size="sm"
                                value={dados.cidade || ''}
                                onChange={e => {
                                    alterarDados({...dados, cidade: e.target.value});
                                }}
                                readOnly={leitura}
                            />
                        </Col>
                        <Col xs={3} sm={3} md={3}>
                            <Form.Label>Estado</Form.Label>
                            <Form.Control 
                                size="sm"
                                value={dados.estado || ''}
                                onChange={e => {
                                    alterarDados({...dados, estado: e.target.value});
                                }}
                                readOnly={leitura}
                            />
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col md='7'>
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control 
                                size="sm"
                                value={dados.email || ''}
                                onChange={e => {
                                    alterarDados({...dados, email: e.target.value});
                                }}
                                readOnly={leitura}
                            />
                            {(erros.email) && 
                                <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.email}</p></Alert>
                            }
                        </Col>
                        <Col md='5'>
                            <Form.Label>Telefone</Form.Label>
                            <MaskedInput 
                                size="sm"
                                className="form-control"
                                mask="(11) 1111-11111"
                                value={dados.telefone}
                                ref={referencias.telefone = React.useRef()}
                                onChange={(e) => {
                                    alterarDados({...dados, telefone: referencias.telefone.current.mask.getRawValue().split('_').join('')});
                                }}
                                readOnly={leitura}
                            />
                            {(erros.telefone) && 
                                <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.telefone}</p></Alert>
                            }
                        </Col>
                    </Form.Row>
                </Form.Group>

                { !dados.id &&
                    <Form.Group>
                        <Form.Row>
                            <Col>
                                <Form.Label>Login</Form.Label>
                                <Form.Control 
                                    size="sm"
                                    value={dados.login || ''}
                                    onChange={e => {
                                        alterarDados({...dados, login: e.target.value});
                                    }}
                                    readOnly={leitura}
                                />
                                {(erros.login) && 
                                    <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.login}</p></Alert>
                                }
                            </Col>
                            <Col>
                                <Form.Label>Senha</Form.Label>
                                <Form.Control 
                                    size="sm"
                                    value={dados.senha || ''}
                                    onChange={e => {
                                        alterarDados({...dados, senha: e.target.value});
                                    }}
                                    readOnly={leitura}
                                />
                                {(erros.senha) && 
                                    <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.senha}</p></Alert>
                                }
                            </Col>
                            <Col>
                                <Form.Label>Confirmar</Form.Label>
                                <Form.Control 
                                    size="sm"
                                    value={dados.confirmarSenha || ''}
                                    onChange={e => {
                                        alterarDados({...dados, confirmarSenha: e.target.value});
                                    }}
                                    readOnly={leitura}
                                />
                                {(erros.confirmarSenha) && 
                                    <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.confirmarSenha}</p></Alert>
                                }
                            </Col>
                        </Form.Row>
                    </Form.Group>
                }
            </Card.Body>
        </Card>

        { dados.id === loginReducer.dadosUsuario.id &&
            <Card className='card-alterar-senha border-0 shadow-sm mb-4'>
                <Card.Header className='mb-0 border-0 bg-white'>
                    <Card.Title className='pb-0 mb-0'>Alterar senha</Card.Title>
                </Card.Header>
                <Card.Body className={['formulario'].join(' ')}>
                    <Form.Group>
                        <Form.Row>
                            <Col>
                                <Form.Label>Senha atual</Form.Label>
                                <Form.Control 
                                    size="sm"
                                    value={dados.senhaAtual || ''}
                                    onChange={e => {
                                        alterarDados({...dados, senhaAtual: e.target.value});
                                    }}
                                    readOnly={leitura}
                                />
                                {(erros.senhaAtual) && 
                                    <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.senhaAtual}</p></Alert>
                                }
                            </Col>
                            <Col>
                                <Form.Label>Nova senha</Form.Label>
                                <Form.Control 
                                    size="sm"
                                    value={dados.senha || ''}
                                    onChange={e => {
                                        alterarDados({...dados, senha: e.target.value});
                                    }}
                                    readOnly={leitura}
                                />
                                {(erros.senha) && 
                                    <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.senha}</p></Alert>
                                }
                            </Col>
                            <Col>
                                <Form.Label>Confirmar</Form.Label>
                                <Form.Control 
                                    size="sm"
                                    value={dados.confirmarSenha || ''}
                                    onChange={e => {
                                        alterarDados({...dados, confirmarSenha: e.target.value});
                                    }}
                                    readOnly={leitura}
                                />
                                {(erros.confirmarSenha) && 
                                    <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.confirmarSenha}</p></Alert>
                                }
                            </Col>
                        </Form.Row>
                    </Form.Group>
                </Card.Body>
            </Card>
        }
    </>
}
