import React from 'react';

// components
import { ParametrosTesteListaComponent } from './components';
import ParametroTeste from './../ParametroTeste';

// lista com os parametros
export default function ParametrosTesteLista({
    filhos,
    alterou,
    raiz=false
}){

    return <ParametrosTesteListaComponent raiz={raiz}>
        <div className='parametros'>
            {filhos.map(param => 
                <ParametroTeste 
                    key={param.tempId || param.dados.id}
                    registro={param}
                    alterou={alterou}
                    raiz={raiz}
                />
            )}
        </div>
    </ParametrosTesteListaComponent>

}