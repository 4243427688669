import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const RelatorioArquivosEmpresaComponent = styled.div.attrs({
    className: `painel-consulta border-0`
})`
    gap: 1rem;
    height: 100%;

    .grafico-content{
        background: #fafafa;
    }
    
    .opcoes{
        display: flex;
        max-width: fit-content;
        gap: 0.5em;
    }

    table{
        width: 100%;
        height: 100%;
        border-radius: 0.3em;
        border-collapse: separate;
        font-size: 0.95em;
        margin-top: 0.75em;
        background: #fdfdfd;
        
        &:first-child{
            margin-top: 0;
        }

        tr{
            border-radius: 0.3em;
            vertical-align: top;

            td, th {
                text-align: center;
                border: 1px solid #f3f3f3;
                background: #fdfdfd;
                padding: 0 0.3em;
                border-bottom: 0;
            }

            &:last-child{

                td {
                    border-bottom: 1px solid #f3f3f3;
                }
            }
        }

        th{
            font-weight: 600;
        }

        thead{

            tr{
                    
                &:first-child{

                    th, td{

                        &:first-child{
                            border-top-left-radius: 0.3em;
                        }

                        &:last-child{
                            border-top-right-radius: 0.3em;
                        }
                    }
                }
            }
        }

        tbody{

            tr{
                    
                &:last-child{

                    th, td{

                        &:first-child{
                            border-bottom-left-radius: 0.3em;
                        }

                        &:last-child{
                            border-bottom-right-radius: 0.3em;
                        }
                    }
                }
            }

            &:empty{
                box-shadow: inset 0 0 2px #ececec;
                position: relative;

                &::after{
                    content: "Sem arquivos";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 200px;
                    /* color: #bfbdb7; */
                }
            }
        }

    }
`;