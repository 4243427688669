import styled from 'styled-components';


export const ListaComponent = styled.div.attrs({
    className: ``
})`

    .lista-filtros{
        display: flex;
        flex-direction: column;
        grid-gap: 6px;

        &:empty{
            text-align: center;
            font-size: 0.8em;
            font-style: italic;
            background: #e9e9e93b;
            color: grey;
            padding: 0.8em!important;
        
            &:after{
                content: "Sem filtros";
            }
        }
    }
`