import React, { useState, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Container, Button, Form } from 'react-bootstrap';
import ComboTrelloQuadro from './../../../../../Componentes/ComboTrelloQuadro';
import ComboTrelloQuadroLista from './../../../../../Componentes/ComboTrelloQuadroLista';
import ComboMultiploTrelloQuadroEtiquetas from './../../../../../Componentes/ComboMultiploTrelloQuadroEtiquetas';

// modal para cadastrar card para o cliente
export default function ModalCadastrar({
    idRazaoSocial,
    fechou,
    salvou
}){

    // dados
    const { loginReducer } = useSelector(state => state);
    const [mostrar, alterarMostrar] = useState(true);
    const [salvando, alterarSalvando] = useState(false);
    const [carregando, alterarCarregando] = useState(true);
    const [dados, alterarDados] = useState({
        id: null,
        idQuadro: null,
        idQuadroLista: null,
        titulo: null,
        descricao: null,
        etiquetas: [],
        idCliente: idRazaoSocial
    });
    const [erros, alterarErros] = useState({});

    // salva
    async function salvar(){
        alterarErros({});
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/trello/card`, {
                dados: dados,
                trello: loginReducer.trello
            });
            salvou();
            alterarMostrar(false);

        }catch({response}){

        }finally{
            alterarSalvando(false);
        }
    }

    return <Modal 
        show={mostrar} 
        onExited={fechou}
        onHide={() => {}}
        size={'md'}
    >
        {/* <Modal.Header className='border-0'>
            <Modal.Title>
                Card
            </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
            <Form.Group>
                <ComboTrelloQuadro 
                    id={dados.idQuadro}
                    alterou={idQuadro => {
                        alterarDados({...dados, idQuadro: idQuadro});
                    }}
                />
            </Form.Group>
            <Form.Group>
                <ComboTrelloQuadroLista 
                    id={dados.idQuadroLista}
                    idQuadro={dados.idQuadroLista}
                    alterou={idQuadroLista => {
                        alterarDados({...dados, idQuadroLista: idQuadroLista});
                    }}
                />
            </Form.Group>
            <Form.Group>
                <ComboMultiploTrelloQuadroEtiquetas 
                    ids={dados.etiquetas}
                    alterou={etiquetasNovas => {
                        console.log(etiquetasNovas);
                    }}
                    idQuadro={dados.idQuadro}
                />
            </Form.Group>
            <Form.Group>
                <Form.Control 
                    placeholder="Titulo"
                    value={dados.titulo || ''}
                    onChange={e => {
                        dados.titulo = e.target.value;
                        alterarDados({...dados});
                    }}
                    disabled={dados.idQuadro === null}
                />
            </Form.Group>
            <Form.Group>
                <Form.Control 
                    placeholder="Descrição"
                    as='textarea' 
                    value={dados.descricao || ''}
                    onChange={e => {
                        dados.descricao = e.target.value;
                        alterarDados({...dados});
                    }}
                    disabled={dados.idQuadro === null}
                />
            </Form.Group>
        </Modal.Body>
        <Modal.Footer  className='border-0'>
            <Button
                variant='danger'
                onClick={() => {alterarMostrar(false)}}
                disabled={salvando}
            >
                <span>Fechar</span>
            </Button>
            <Button
                onClick={salvar}
                variant='success'
                disabled={salvando}
            >
                {salvando ? 
                    <>
                        <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                        <span>Salvando</span>
                    </> : 
                    <span>Salvar</span>
                }
            </Button>
        </Modal.Footer>
    </Modal>

}