import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import styles from './index.module.scss';
import axios from 'axios';
import { toast } from 'react-toastify';

// componentes
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRandom } from '@fortawesome/free-solid-svg-icons';
import { Modal, Container, Card, Row, Col, Form, Button, InputGroup, Spinner, Alert, OverlayTrigger, Tooltip, Badge} from 'react-bootstrap';
import Select from 'react-select';
import useIsMounted from 'ismounted';
import { intersectObject, copiaCampo } from './../../../../Funcoes';
import { FichaComponent } from './components';

// contexto
import { PainelContexto } from './../../../PainelContexto';

// faz o cadastro
export default function ModalEditar({
    
}){

    // dados
    let isMounted = useIsMounted();
    let { path, url } = useRouteMatch();
    let painelContextoDados = useContext(PainelContexto);
    const history = useHistory();
    const {
        id = null,
        idEmpresa = null
    } = useParams(); // parametro url

    // prepara
    const dadosPadrao = {
        id: null,
        nome: '',
        usuario: '',
        email: null,
        senha: null,
        permissao: null,
        master: 'N',
        terceirizado: 'N',
        habilitarMfa: 'N'
    }
    
    // estados
    const [ dadosIniciais, alterarDadosIniciais ] = useState({});
    const [ dados, alterarDados ] = useState({...dadosPadrao});
    const [ mostrar, alterarMostrar ] = useState(true);
    const [ salvando, alterarSalvando ] = useState(false);
    const [ carregando, alterarCarregando ] = useState(false);
    const [ erros, alterarErros ] = useState({});
    const [ carregandoSenhaAleatoria, alterarCarregandoSenhaAleatoria ] = useState(false);
    const [ carregandoUsuarioAleatorio, alterarCarregandoUsuarioAleatorio ] = useState(false);

    // prepara array com permissões
    const permissoes = [
        {value: 'projetista', label: 'Projetista'},
        {value: 'tecnico', label: 'Técnico'},
        {value: 'vendedor', label: 'Vendedor'},
        {value: 'viabilidade', label: 'Viabilidade'}
    ];

    // pega o modo inicial se edição
    const permissaoInicial = (dados.id) ? permissoes.filter((e) => dados.permissao === e.value)[0] : null

    const camposRef = {
        usuario: React.useRef(),
        senha: React.useRef(),
        nome: React.useRef(),
        email: React.useRef(),
        tokenIntegrador: React.useRef()
    }

    // inicializa
    useEffect(() => {
        
        if(id){
            consultarId();
        }
    }, []);

    // consulta dados do usuário
    async function consultarId(){
        alterarCarregando(true);

        try{
            
            // faz a requisição
            let { data } = await axios.get(`/empresas/usuarios/${id}`);
            
            let dadosSalvar = {
                ...data.dados,
                senha: data.senha
            }
            alterarDadosIniciais(dadosSalvar);
            alterarDados(dadosSalvar);

        }catch(e){
            console.log('pegou o erro')
        }finally{
            if (isMounted.current) {
                alterarCarregando(false);
            }
        }

    }

    // finaliza
    async function salvar(){

        // salva registro
        alterarSalvando(true);
        alterarErros({});

        // faz a requisição
        try{

            // pega dados
            let dadosSalvar = intersectObject(dadosPadrao, dados);

            // verifica se possui id da empresa
            if(idEmpresa){

                // define
                dadosSalvar.idEmpresa = idEmpresa;
            }

            // faz a requisição
            let { data } = await axios.post(`/empresas/usuarios`, {
                dados: dadosSalvar
            });

            // mensagem de cadastro realizado com sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Usuário editado!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    {dadosIniciais.nome}
                </div>
            </>);

            // // vai para a consulta de sms's enviados (por enquanto volta para a consulta de clientes)
            // history.push('/painel/caixa-saida');
            
            alterarSalvando(false);
            alterarMostrar(false);
            
        }catch({response}){

            // define erros retornados
            alterarErros(response.data);

        }finally{
            if (isMounted.current) {
                alterarSalvando(false);
            }
        }
    }

    // função para gerar usuário aleatório
    async function gerarUsuario(){

        alterarCarregandoUsuarioAleatorio(true);
        
        try{

            // faz a requisição
            const { data } = await axios.get(`/empresas/usuarios/usuarioAleatorio`, {
                params: {
                    nome: dados.nome,
                    idEmpresa
                }
            });

            // sucesso

            // pega o usuário e altera
            alterarDados({
                ...dados,
                usuario: data.usuario
            });

        }catch({response}){

        }finally{

            alterarCarregandoUsuarioAleatorio(false);
        }
    }

    // função para gerar senha aleatória
    async function gerarSenha(){

        alterarCarregandoSenhaAleatoria(true);
        try{

            // faz a requisição
            const { data } = await axios.get(`/empresas/usuarios/senhaAleatoria`, {
                nome: dados.nome
            });

            // sucesso

            // pega a senha e altera
            alterarDados({
                ...dados,
                senha: data.senha
            });

        }catch({response}){

        }finally{

            alterarCarregandoSenhaAleatoria(false);
        }
    }

    // return <>
    //     Aqui retorna
    // </>
    return <Modal 
        show={mostrar}
        centered
        onExited={() => {
            
            // monta a url para retroceder
            let urlBack = url.split("/usuarios").map((parte, i) => i > 0 ? '/usuarios' : parte).join('');
            console.log(urlBack)
            history.push(urlBack, {refresh: true});
        }}
        onHide={() => {
            alterarMostrar(false);
        }}
        size={'md'}
    >
        <Modal.Header className='border-0'>
            <Modal.Title 
                as='h5' 
                className={[
                    'text-center w-100',
                    'text-success'
                ].join(' ')}
            >
                {dadosIniciais.nome && <>{dadosIniciais.nome} ({dados.id})</>}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'pt-0'}>
            {
                carregando ?
                <Col align="center">
                    <Spinner animation="border" />
                </Col>
                : <FichaComponent>
                    <Container>
                        <Form.Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Nome</Form.Label>
                                    <InputGroup className="mb-0">
                                        <Form.Control 
                                            ref={camposRef.nome}
                                            value={dados.nome || ''}
                                            onChange={e => {
                                                alterarDados({...dados, nome: e.target.value});
                                            }}
                                        />
                                        <InputGroup.Append>
                                            <Button
                                                size="sm"
                                                variant="outline-secondary"
                                                onClick={() => {

                                                    // pega o campo
                                                    let campo = camposRef.nome.current
                                                    copiaCampo(campo);

                                                }}
                                                className={[styles.btnForm, ""].join(' ')}
                                            >
                                                <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "copy"]}/>
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>

                                    {(erros.nome) && 
                                        <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.nome}</p></Alert>
                                    }

                                </Form.Group>
                            </Col>
                        </Form.Row>
                        
                        <Form.Row>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Usuário</Form.Label>
                                    <InputGroup className="mb-0">
                                        {
                                            !dados.id && <InputGroup.Prepend>
                                                <OverlayTrigger 
                                                    overlay={<Tooltip>Gerar usuário aleatório</Tooltip>}
                                                    placement='top'
                                                >
                                                    <Button
                                                        size="sm"
                                                        className={[styles.btnForm, ''].join(' ')}
                                                        variant="outline-secondary"
                                                        onClick={() => {

                                                            // gera o usuário
                                                            gerarUsuario();
                                                        }}
                                                    >
                                                        <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={faRandom}/>
                                                    </Button>
                                                </OverlayTrigger>
                                            </InputGroup.Prepend>
                                        }
                                        <Form.Control 
                                            ref={camposRef.usuario}
                                            value={dados.usuario || ''}
                                            disabled={carregandoUsuarioAleatorio}
                                            onChange={e => {
                                                alterarDados({...dados, usuario: e.target.value});
                                            }}
                                        />
                                        <InputGroup.Append>
                                            <Button
                                                size="sm"
                                                variant="outline-secondary"
                                                onClick={() => {
                                                    
                                                    // pega o campo
                                                    let campo = camposRef.usuario.current
                                                    copiaCampo(campo);
                
                                                }}
                                                className={[styles.btnForm, ''].join(' ')}
                                            >
                                                <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "copy"]}/>
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    {
                                        !dados.id && <Badge className="w-100 text-left font-weight-normal" variant="light">
                                            <FontAwesomeIcon className="icone mr-2" color="#616161" icon={faRandom}/>
                                            Gerado aleatório se vazio
                                        </Badge>
                                    }
                                    {(erros.usuario) && 
                                        <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.usuario}</p></Alert>
                                    }
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Senha</Form.Label>
                                    <InputGroup className="mb-0">
                                        <InputGroup.Prepend>
                                            <OverlayTrigger 
                                                overlay={<Tooltip>Gerar senha aleatória</Tooltip>}
                                                placement='top'
                                            >
                                                <Button
                                                    size="sm"
                                                    className={[styles.btnForm, ''].join(' ')}
                                                    variant="outline-secondary"
                                                    onClick={() => {

                                                        // gera a senha
                                                        gerarSenha();
                                                    }}
                                                >
                                                    <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={faRandom}/>
                                                </Button>
                                            </OverlayTrigger>
                                        </InputGroup.Prepend>
                                        <Form.Control 
                                            ref={camposRef.senha}
                                            value={dados.senha || ''}
                                            disabled={carregandoSenhaAleatoria}
                                            onChange={e => {
                                                alterarDados({...dados, senha: e.target.value});
                                            }}
                                        />
                                        <InputGroup.Append>
                                            <Button
                                                size="sm"
                                                className={[styles.btnForm, ''].join(' ')}
                                                variant="outline-secondary"
                                                onClick={() => {

                                                    // pega o campo
                                                    let campo = camposRef.senha.current
                                                    copiaCampo(campo);

                                                }}
                                            >
                                                <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "copy"]}/>
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    {
                                        !dados.id && <Badge className="w-100 text-left font-weight-normal" variant="light">
                                            <FontAwesomeIcon className="icone mr-2" color="#616161" icon={faRandom}/>
                                            Gerado aleatório se vazio
                                        </Badge>
                                    }
                                    {(erros.senha) && 
                                        <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.senha}</p></Alert>
                                    }
                                </Form.Group>
                            </Col>
                        </Form.Row>

                        <Form.Row>
                            <Col lg={4}>
                                <Form.Label>Permissão</Form.Label>
                                <Select
                                    className="select-ficha"
                                    options={permissoes}
                                    defaultValue={permissaoInicial}
                                    isMulti={false}
                                    placeholder='Permissão'
                                    onChange={(e) => {
                                        // altera os filtros selecionados
                                        alterarDados({...dados, permissao: e.value});
                                    }}
                                />
                                {(erros.permissao) && 
                                    <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.permissao}</p></Alert>
                                }
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Token integração</Form.Label>
                                    <InputGroup className="mb-0">
                                        <Form.Control 
                                            ref={camposRef.tokenIntegrador}
                                            disabled
                                            value={dados.tokenIntegrador || ''}
                                            onChange={e => {
                                                alterarDados({...dados, tokenIntegrador: e.target.value});
                                            }}
                                        />
                                        <InputGroup.Append>
                                            <Button
                                                size="sm"
                                                variant="outline-secondary"
                                                onClick={() => {
                                                    
                                                    // pega o campo
                                                    let campo = camposRef.tokenIntegrador.current
                                                    copiaCampo(campo);
                                                    
                                                }}
                                                className={[styles.btnForm, 'sm'].join(' ')}
                                            >
                                                <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "copy"]}/>
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    {(erros.tokenIntegrador) && 
                                        <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.tokenIntegrador}</p></Alert>
                                    }
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>E-mail</Form.Label>
                                    <InputGroup className="mb-0">
                                        <Form.Control 
                                            ref={camposRef.email}
                                            value={dados.email || ''}
                                            onChange={e => {
                                                alterarDados({...dados, email: e.target.value});
                                            }}
                                        />
                                        <InputGroup.Append>
                                            <Button
                                                size="sm"
                                                variant="outline-secondary"
                                                onClick={() => {
                                                    
                                                    // pega o campo
                                                    let campo = camposRef.email.current
                                                    copiaCampo(campo);
                                                    
                                                }}
                                                className={[styles.btnForm, ''].join(' ')}
                                            >
                                                <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "copy"]}/>
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>

                                    {(erros.email) && 
                                        <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.email}</p></Alert>
                                    }
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Form.Check
                                    className="check-master"
                                    id={"check-master"}
                                    label={
                                        dados.masterPrincipal === 'S' ?
                                        "Usuário master da empresa" :
                                        "Usuário gerenciador"
                                    }
                                    checked={dados.master === 'S'}
                                    disabled={dados.masterPrincipal === 'S' || dados.terceirizado === 'S'}
                                    onChange={e => {
                                        alterarDados({...dados, master: e.target.checked ? 'S' : 'N'});
                                    }}
                                />
                                {(erros.master) && 
                                    <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.master}</p></Alert>
                                }
                                {(erros.limiteUsuariosGerencia) && 
                                    <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.limiteUsuariosGerencia}</p></Alert>
                                }
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Form.Check
                                    className="check-terceirizado"
                                    id={"check-terceirizado"}
                                    label={"Usuário terceirizado"}
                                    checked={dados.terceirizado === 'S'}
                                    disabled={dados.master === 'S'}
                                    onChange={e => {
                                        alterarDados({...dados, terceirizado: e.target.checked ? 'S' : 'N'});
                                    }}
                                />
                                {(erros.terceirizado) && 
                                    <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.terceirizado}</p></Alert>
                                }
                                {(erros.limiteUsuariosTerceirizado) && 
                                    <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.limiteUsuariosTerceirizado}</p></Alert>
                                }
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Form.Check
                                    className="check-mfa"
                                    id={"check-mfa"}
                                    label={"MFA habilitado"}
                                    checked={dados.habilitarMfa === 'S'}
                                    disabled={dadosIniciais.habilitarMfa === 'N'}
                                    onChange={e => {
                                        alterarDados({...dados, habilitarMfa: e.target.checked ? 'S' : 'N'});
                                    }}
                                />
                                {/* {(erros.terceirizado) && 
                                    <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.terceirizado}</p></Alert>
                                } */}
                                {/* {(erros.limiteUsuariosTerceirizado) && 
                                    <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.limiteUsuariosTerceirizado}</p></Alert>
                                } */}
                            </Col>
                        </Form.Row>
                    </Container>
                </FichaComponent>
            }
        </Modal.Body>
        <Modal.Footer  className='border-0'>
            <Button
                variant='danger'
                onClick={() => {alterarMostrar(false)}}
                disabled={salvando}
            >
                <span>Fechar</span>
            </Button>
            <Button
                onClick={salvar}
                variant='success'
                disabled={salvando}
            >
                {salvando ? 
                    <>
                        <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                        <span>Salvando</span>
                    </> : 
                    <span>Salvar</span>
                }
            </Button>
        </Modal.Footer>
    </Modal>
}   