import moment from 'moment';

// função para retornar o estilo para a empresa
export const retornaModoCliente = (dados) => {

    // verifica o modo do cliente
    switch(dados.situacao){
        case 'demo':
                
            let demo = 'demo';
            // verifica o plano
            if(dados.planoSistema == 'light'){
                demo = 'demolight';
            }
            // verifica se em teste
            if(moment().diff(dados.expiraEm) < 0){
                return demo + 'EmTeste';
            }else{
                return demo;
            }
        case 'cliente':
            
            // verifica o plano
            if(dados.planoSistema == 'light'){
                return 'clientelight';
            }
            
        default:
            // padrão
            return dados.situacao;
    }
}