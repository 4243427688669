import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const EmpresasIntegracaoSistemasComponent = styled(Card).attrs({
    className: `painel-consulta border-0 shadow-sm`
})`
    
    .card-header{
        padding: 0;
        font-size: 0.9em;

        .h5{
            font-size: 1.25em;
        }

        .btn{
            padding: 0.25em 0.5em;
            font-size: 0.875em;
        }
    }

    .card-body{
        padding-top: 0.5rem;
    }

    .form-control{
        min-height: unset;
        height: 31px;
    }

    .btn{
        padding: 0.25em 0.5em;
        font-size: 0.875em;
        line-height: 1.5;
        border-radius: 0.2em;
    }

    .card{

        > .card-header{
            border-left: 3px solid var(--cor-cliente-ficha) !important;
        }

        .card{
            > .card-header{
                border-left: unset !important;
            }
        }
    }

    .tabela{
        width: 100%;
        border-radius: 0.3em;
        border-collapse: separate;
        font-size: 0.95em;
        margin-top: 0.75em;
        -webkit-border-horizontal-spacing: 0.2em;
        -webkit-border-vertical-spacing: 0.25em;

        &:empty{
            position: relative;
            min-height: 96px;

            &::after{
                content: "Sem registros";
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                color: grey;
                background: #fbfbfb;
                border-radius: 0.3em;
                box-shadow: 0px 0px 1px inset #cacaca;
            }
        }
        
        .registro{
            --cor-status-registro: black;
            /* display: flex;
            align-items: flex-start; */
            font-size: 0.9em;
            border-radius: 0.3em;

            &:first-child{
                margin-top: 0;
            }

            &.concluido{
                --cor-status-registro: #03c903;
            }

            &.processo{
                --cor-status-registro: #0db7f3;
            }

            td, th{
                border-left: 1px solid #f3f3f3;
                background: #fdfdfd;
                border: 1px solid #f3f3f3;
                padding: 0.25em 0.5em;

                &:first-child{
                    border-top-left-radius: 0.3em;
                    border-bottom-left-radius: 0.3em;
                }
                
                &:last-child{
                    border-top-right-radius: 0.3em;
                    border-bottom-right-radius: 0.3em;
                }
                
                &.integracao{
                    color: var(--cor-status-registro);
                    font-size: 0.8em;
                    border-left: 3px solid var(--cor-status-registro);
                }
                
            }
        }
    }

    .paginacao{
        font-size: 0.85em;

        .total-registros{
            padding: 0.125em 1.2em !important;
        }
    }
`;