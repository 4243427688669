import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';

// componentes
import OptionSelect from './OptionSelect';

// combo para consulta de versões
export default function ComboGruposChaveGoogleMultiplo({
    ids=[],
    alterou,
    filtros,
    ...props
}){

    // estados
    let [consulta, alterarConsulta] = useState([]);
    let [selecionados, alterarSelecionados] = useState([]);
    let [carregando, alterarCarregando] = useState(true);

    // consultar versões
    async function consultarRegistros(inputValue, callback = null){
        alterarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/gruposChaveGoogle`, {
                params: { 
                    texto: inputValue,
                    pagina: 1,
                    registrosPorPagina: 20,
                    ...(filtros ?? {})
                }
            });

            // filtra registros, desabilita quem já está na lista
            let registros = data.registros.map(registro => {
                // verifica se já está adicionado
                if(selecionados.find(selecionado => {
                    console.log(selecionado.id, registro.dados.id);
                    if(selecionado.id === registro.dados.id){
                        return true;
                    }
                    return false;
                })){
                    registro.isDisabled = true;
                }
                return registro;
            });

            // altera os dados
            alterarConsulta([...registros]);
            if(callback !== null){
                callback(registros);
            }
            alterarCarregando(false);
        }catch({response}){}

    }

    // consultar os ids pré informados
    async function consultarIdsPreSelecionados(){
        alterarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/gruposChaveGoogle`, { params: { 
                ids: ids,
                ...(filtros ?? {})
            }});
            alterarSelecionados(data.registros);
            alterarCarregando(false);

        }catch({response}){}

        // consulta registros padrão de consulta
        consultarRegistros('');

    }

    // pre carrega algumas versões disponiveis
    useEffect(() => {
        if(ids.length > 0){
            consultarIdsPreSelecionados();
        }else{
            consultarRegistros('');
        }
    }, []);

    // ao alterar versões, retorna ids
    useEffect(() => {
        alterou(selecionados.map(g => g.dados.id));
    }, [selecionados]);

    return <AsyncSelect 
        isMulti
        isClearable
        isLoading={carregando}
        loadOptions={consultarRegistros}
        defaultOptions={consulta}
        components={{
            Option: OptionSelect
        }}
        value={selecionados.map(selecionado => {
            return {label: <>{selecionado.dados.nome} <small>{selecionado.dados.chave || <i>Sem chave</i>}</small></>, value: selecionado.dados.id}
        })}
        onChange={(evento, validar) => {

            // verifica ações
            if(validar.action === 'pop-value'){
                selecionados.pop();
                alterarSelecionados([...selecionados]);
            }else if(validar.action === 'remove-value'){
                alterarSelecionados(selecionados.filter((selecionado, a) => {
                    if(selecionado.dados.id === validar.removedValue.value){
                        return false;
                    }
                    return true
                }));
            }else if(validar.action === 'clear'){
                alterarSelecionados([]);
            }else if(validar.action === 'select-option'){
                let buscaFilho = selecionados.find(s => {
                    return s.dados.id === validar.option.dados.id;
                });

                // inclui se não já estiver incluido
                if(!buscaFilho){
                    alterarSelecionados([...selecionados, validar.option]);
                }
            }
        }}
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => 'Digite algo para fazer a consulta'}
        placeholder='Grupos chave'
        {...props}
    />
    
}
