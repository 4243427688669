import React from 'react';

// componentes
import Sincronizar from './Sincronizar';

// componente
export default function Trello(){


    /**
     * Que tipo de configurações?
     * Consultar os quadros que estão vinculados a conta de integração, e escolher quais trazer para o painel
     * Função para sincronizar os dados do trello
     * Informar qual funcionário pertence a qual membro do trello
     * configurar hooks?
     */

    return <>
        <Sincronizar />
    </>

}