import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { Form } from 'react-bootstrap';
import axios from 'axios';

// componentes
import OptionSelect from './OptionSelect';

// componente
export default function ComboModeloEmail({
    id, // id do modelo
    alterou
}){

    // estados
    let [consulta, alterarConsulta] = useState([]);
    let [carregando, alterarCarregando] = useState(false);
    let [dados, alterarDados] = useState(null);

    // consulta o id do modelo de email
    useEffect(() => {

        // carrega alguns
        consultar('');

    }, []);

    // consulta os responsaveis
    async function consultar(inputValue, callback = null){

        try{

            // faz a requisição
            let { data } = await axios.get(`/modelosEmail`, {
                params: {
                    texto: inputValue,
                    pagina: 1,
                    registrosPorPagina: 5
                }
            });

            // retorno
            alterarConsulta(data.registros);
            if(callback !== null){
                callback(data.registros)
            }

        }catch({response}){

        }finally{

        }
    }

    return <>
        <AsyncSelect 
            isDisabled={carregando}
            isClearable
            loadOptions={consultar} // ao alterar, carrega
            defaultOptions={consulta}       // lista pré carregada
            value={dados && {
                label: dados.descricao
            }}  
            onChange={(e) => {

                if(e === null){
                    alterarDados(null);
                    alterou(null);
                }else{
                    alterarDados({...e});
                    alterou(e.id);
                }
            }}
            components={{
                Option: OptionSelect
            }}
            noOptionsMessage={() => 'Digite algo para fazer a consulta'}
            loadingMessage={() => 'Carregando...'}
            placeholder='Escolher modelo de email'
        />
    </>

}
