import * as React from "react";

const SvgTrelloIcone = (props) => {
  props = {
    width: 15.5,
    height: 15.5,
    ...props,
  };
  return (
    <svg viewBox="0 0 128 128" {...props}>
      <style>{".trello-icone_svg__st50{fill:#f2f2f2}"}</style>
      <g id="trello-icone_svg__trello">
        <g id="trello-icone_svg__Group" transform="translate(-9 1)">
          <g id="trello-icone_svg__Trello-Logo" transform="translate(468)">
            <g
              id="trello-icone_svg__Trello-Mark---Blue---Flat"
              transform="translate(1020 420)"
            >
              <g id="trello-icone_svg__Mark" transform="translate(100 100)">
                <linearGradient
                  id="trello-icone_svg__Board_2_"
                  gradientUnits="userSpaceOnUse"
                  x1={-1559.112}
                  y1={-457}
                  x2={-1470.888}
                  y2={-457}
                >
                  <stop offset={0.008} stopColor="#0069a6" />
                  <stop offset={0.081} stopColor="#0079bf" />
                  <stop offset={0.5} stopColor="#29b0ff" />
                  <stop offset={0.92} stopColor="#0079bf" />
                  <stop offset={0.992} stopColor="#0069a6" />
                </linearGradient>
                <path
                  id="trello-icone_svg__Board"
                  d="M-1548.1-501.1h66.2c6.1 0 11 4.9 11 11v66.2c0 6.1-4.9 11-11 11h-66.2c-6.1 0-11-4.9-11-11v-66.2c0-6.1 4.9-11 11-11z"
                  fill="url(#trello-icone_svg__Board_2_)"
                />
                <path
                  id="trello-icone_svg__Right-List"
                  className="trello-icone_svg__st50"
                  d="M-1504-489.6h16.3c2.9 0 5.3 2.4 5.3 5.3v28c0 2.9-2.4 5.3-5.3 5.3h-16.3c-2.9 0-5.3-2.4-5.3-5.3v-28c0-3 2.4-5.3 5.3-5.3z"
                />
                <path
                  id="trello-icone_svg__Left-List"
                  className="trello-icone_svg__st50"
                  d="M-1542.3-489.6h16.3c2.9 0 5.3 2.4 5.3 5.3v50.1c0 2.9-2.4 5.3-5.3 5.3h-16.3c-2.9 0-5.3-2.4-5.3-5.3v-50.1c0-3 2.3-5.3 5.3-5.3z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgTrelloIcone;
