import React, { useState, useEffect, useContext, useRef } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Row, Col, Form, Button, InputGroup, Tabs, Tab} from 'react-bootstrap';
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { toast } from 'react-toastify';
import { intersectObject, converterReal } from './../../../Funcoes';
import useIsMounted from 'ismounted';

import EmpresasUsuarios from '../../EmpresasUsuarios/Consultar';
import EmpresasIntegracaoSistemas from '../../EmpresasIntegracaoSistemas';
import FormularioFicha from './FormularioFicha';
import FormularioServidor from './FormularioServidor';
import FormularioFinanceiro from './FormularioFinanceiro';
import Cards from './Cards';

import { FichaClienteComponent } from './components'

// contextos    
import { PainelContexto } from './../../PainelContexto';
import { retornaModoCliente } from '../Consultar/Funcoes';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { Contrato } from './Contrato';
import EmpresaEstatisticas from './EmpresaEstatisticas';
import ListaLogsApiPorRota from './LogsApi/ListaLogsApiPorRota';
import ListaLogsApi from './LogsApi/ListaLogsApi';
import RelatorioArquivosEmpresa from './Arquivos/Relatorio';
import EmpresasBackups from './../../EmpresasBackups';

// escreve o email
function FichaCliente(){

    // dados
    const { loginReducer, permissaoReducer } = useSelector(state => state);
    const isMounted = useIsMounted();
    const location = useLocation();
    const history = useHistory();
    const {
        url
    } = useRouteMatch();
    const {id} = useParams(); // parametro url
    const tabInicial = useParams().tab;
    const [modo, alterarModo] = useState('cadastro'); // cadastro, edicao
    
    const dadosPadrao = {
        /* -- dados */
        id: null,
        codigo: null,
    
        /* -- dados empresa */
        cnpj: null,
        cnpjTitularidadeAnterior: null,
        numeroDocumento: null,
        estrangeiro: null,
        pessoaFisica: null,
        razaoSocial: null,
        nomeFantasia: null,
        inscricaoEstadual: null,
        logomarca: null,
        campanha: null,
        evento: null,
    
        /* -- contatos */
    
        /* -- principal */
        responsavel: null,
        email: null,
        telefone: null,
    
        /* -- financeiro */
        responsavelFinanceiro: null,
        emailFinanceiro: null,
        telefoneFinanceiro: null,
    
        /* -- tecnico */
        responsavelTecnico: null,
        emailTecnico: null,
        telefoneTecnico: null,
        
        /* -- endereço */
        bairro: null,
        cep: null,
        complemento: null,
        endereco: null,
        numero: null,
        cidade: null,
        estado: null,
        pais: null,

        /* -- coordenadas */
        latitude: null,
        longitude: null,

        /* -- dados controle financeiro / datas */
    
        /* -- situação */
        situacao: null,
        planoSistema: null,
        ativado: null,

        /* -- datas financeiro */

        // -- ativação
        dataAtivado: null,
    
        // -- cliente
        dataCliente: null,
    
        // -- bloqueio
        dataBloqueado: null,
    
        // -- cancelado
        dataCancelado: null,
    
        // -- reajuste
        dataReajuste: null,
    
        /* -- dados financeiro */
        valorMensalidade: null,
        infoMensalidade: null,
        infoLicenca: null,
        tipoAdesao: null,
        diaVencimento: null,
        valorAdesao: null,
        parcelasAdesao: null,
        expiraEm: null,
        notaFiscal: null,
        taxaBoleto: null,
        campanha: null,
        
        /* -- dados controle de usuários simultaneos */
        numeroDeUsuariosTecnico: null,
        numeroDeUsuariosVendedor: null,
        numeroDeUsuariosProjetista: null,
        numeroDeUsuariosViabilidade: null,
    
        // limite de usuários gerenciadores
        limiteUsuariosGerencia: null,

        // limite de usuários terceirizados
        limiteUsuariosTerceirizado: null,

        /* -- dados servidor / acesso */
        ipServidor: null,
        ipServidorProvisorio: null,
        enderecoServidor: null,
        enderecoServidorProprioAcesso: null,
        idBanco: null,
        senhaBanco: null,
        pasta: null,
    
        /* -- integração */
        // idEmpresaIntegracao: null,
        liberarIntegracao: null,
    
        /* -- servidor próprio */
        servidorProprio: null,

        /* -- id para servidor alugado */
        idServidorAluguel: null,
        
        /* -- dados para uso do google */
        idGrupoChaveGoogle: null,
        tokenGoogleGeogrid: null,
        tokenGoogleCliente: null,
        usarTokenGoogleCliente: null,
    
        /* -- controle versão */
        idVersao: null,
        dataAtualizado: null,
    
        /* -- observações */
        obs: null,
        obsComercial: null,
        obsServidor: null,
    
        /* -- outras datas */
        dataPedidoTeste: null,
        dataEditado: null,
        dataCadastro: null
    };
    const dadosPadraoFicha = {
        /* -- dados */
        id: null,
        codigo: null,
    
        /* -- dados empresa */
        cnpj: null,
        cnpjTitularidadeAnterior: null,
        numeroDocumento: null,
        estrangeiro: null,
        pessoaFisica: null,
        razaoSocial: null,
        nomeFantasia: null,
        inscricaoEstadual: null,
        logomarca: null,
        campanha: null,
        evento: null,
    
        /* -- contatos */
    
        /* -- principal */
        responsavel: null,
        email: null,
        telefone: null,
    
        /* -- financeiro */
        responsavelFinanceiro: null,
        emailFinanceiro: null,
        telefoneFinanceiro: null,
    
        /* -- tecnico */
        responsavelTecnico: null,
        emailTecnico: null,
        telefoneTecnico: null,
        
        /* -- endereço */
        bairro: null,
        cep: null,
        complemento: null,
        endereco: null,
        numero: null,
        cidade: null,
        estado: null,
        pais: null,

        /* -- coordenadas */
        latitude: null,
        longitude: null,

        /* -- dados controle financeiro / datas */
    
        /* -- situação */
        situacao: null,
        planoSistema: null,
        ativado: null,

        /* -- datas financeiro */

        // -- ativação
        dataAtivado: null,
    
        // -- cliente
        dataCliente: null,
    
        // -- bloqueio
        dataBloqueado: null,
    
        // -- cancelado
        dataCancelado: null,
    
        // -- reajuste
        dataReajuste: null,
    
        /* -- dados financeiro */
        valorMensalidade: null,
        infoMensalidade: null,
        infoLicenca: null,
        tipoAdesao: null,
        diaVencimento: null,
        valorAdesao: null,
        parcelasAdesao: null,
        expiraEm: null,
        notaFiscal: null,
        taxaBoleto: null,
        campanha: null,
        
        /* -- dados controle de usuários simultaneos */
        numeroDeUsuariosTecnico: null,
        numeroDeUsuariosVendedor: null,
        numeroDeUsuariosProjetista: null,
        numeroDeUsuariosViabilidade: null,
    
        // limite de usuários gerenciadores
        limiteUsuariosGerencia: null,
        
        // limite de usuários terceirizados
        limiteUsuariosTerceirizado: null,

        // limite de armazenamento de arquivos
        limiteArmazenamentoArquivos: null,

        /* -- observações */
        obs: null,
        obsComercial: null,
        obsServidor: null,

        // data informar
        dataPedidoTeste: null,
        dataCadastro: null
    }
    const dadosPadraoFinanceiro = {
        /* -- dados */
        id: null,
        codigo: null,
    
        /* -- dados empresa */
        cnpj: null,
        cnpjTitularidadeAnterior: null,
        numeroDocumento: null,
        estrangeiro: null,
        pessoaFisica: null,
        razaoSocial: null,
        nomeFantasia: null,
        campanha: null,
        evento: null,
    
        /* -- contatos */
    
        /* -- principal */
        responsavel: null,
        email: null,
        telefone: null,
    
        /* -- financeiro */
        responsavelFinanceiro: null,
        emailFinanceiro: null,
        telefoneFinanceiro: null,
    
        /* -- tecnico */
        responsavelTecnico: null,
        emailTecnico: null,
        telefoneTecnico: null,
        
        /* -- endereço */
        bairro: null,
        cep: null,
        complemento: null,
        endereco: null,
        numero: null,
        cidade: null,
        estado: null,
        pais: null,

        /* -- coordenadas */
        latitude: null,
        longitude: null,

        /* -- dados controle financeiro / datas */
    
        /* -- situação */
        situacao: null,
        planoSistema: null,
        ativado: null,

        /* -- datas financeiro */

        // -- ativação
        dataAtivado: null,
    
        // -- cliente
        dataCliente: null,
    
        // -- bloqueio
        dataBloqueado: null,
    
        // -- cancelado
        dataCancelado: null,
    
        // -- reajuste
        dataReajuste: null,
    
        /* -- dados financeiro */
        valorMensalidade: null,
        infoMensalidade: null,
        infoLicenca: null,
        tipoAdesao: null,
        diaVencimento: null,
        valorAdesao: null,
        parcelasAdesao: null,
        expiraEm: null,
        notaFiscal: null,
        taxaBoleto: null,
        campanha: null,
        
        /* -- dados controle de usuários simultaneos */
        numeroDeUsuariosTecnico: null,
        numeroDeUsuariosVendedor: null,
        numeroDeUsuariosProjetista: null,
        numeroDeUsuariosViabilidade: null,

        // servidor
        idServidorAluguel: null,
        servidorProprio: null,

        /* -- observações */
        obsComercial: null,
    }
    const dadosPadraoServidor = {
        /* -- dados */
        id: null,
        codigo: null,
    
        /* -- dados empresa */
        cnpj: null,
        cnpjTitularidadeAnterior: null,
        numeroDocumento: null,
        estrangeiro: null,
        pessoaFisica: null,
        razaoSocial: null,
        nomeFantasia: null,
        campanha: null,
        evento: null,
        
        /* -- dados controle financeiro / datas */
    
        /* -- situação */
        situacao: null,
        planoSistema: null,
        ativado: null,

        /* -- dados controle de usuários simultaneos */
        numeroDeUsuariosTecnico: null,
        numeroDeUsuariosVendedor: null,
        numeroDeUsuariosProjetista: null,
        numeroDeUsuariosViabilidade: null,

        // limite de usuários gerenciadores
        limiteUsuariosGerencia: null,
    
        // limite de usuários terceirizados
        limiteUsuariosTerceirizado: null,

        /* -- dados servidor / acesso */
        ipServidor: null,
        ipServidorProvisorio: null,
        enderecoServidor: null,
        enderecoServidorProprioAcesso: null,
        idBanco: null,
        senhaBanco: null,
        pasta: null,
    
        /* -- integração */
        // idEmpresaIntegracao: null,
        liberarIntegracao: null,
    
        /* -- servidor próprio */
        servidorProprio: null,

        /* -- id para servidor alugado */
        idServidorAluguel: null,
        
        /* -- dados para uso do google */
        idGrupoChaveGoogle: null,
        tokenGoogleGeogrid: null,
        tokenGoogleCliente: null,
        usarTokenGoogleCliente: null,
    
        /* -- controle versão */
        idVersao: null,
        dataAtualizado: null,
    
        /* -- observações */
        obs: null,
        obsComercial: null,
        obsServidor: null,
    }

    const [dados, alterarDados] = useState(Object.assign({}, dadosPadrao));
    const [dadosIniciais, alterarDadosIniciais] = useState(Object.assign({}, dadosPadrao));
    const [ dadosUsuarioMaster, alterarDadosUsuarioMaster ] = useState(null);
    const [infoVersao, alterarInfoVersao] = useState(null);
    const [liberarAtualizarServidor, alterarLiberarAtualizarServidor] = useState('N');
    const [intoVersaoAtual, alterarInfoVersaoAtual] = useState(null);
    const [salvando, alterarSalvando] = useState(false);
    const [carregando, alterarCarregando] = useState(true);
    const [tab, alterarTab] = useState(tabInicial || 'ficha');
    
    // função que retorna os dados para a ficha/formulário
    const retornaDadosFormulario = (ficha) => {

        let dadosForm = null
        switch(ficha){
            case 'ficha':
                dadosForm = intersectObject(dadosPadraoFicha, dados);
                break;
            case 'financeiro':
                dadosForm = intersectObject(dadosPadraoFinanceiro, dados);
                break;
            case 'servidor':
                dadosForm = intersectObject(dadosPadraoServidor, dados);
                break;
        }

        // retorna os dados
        return dadosForm;

    }

    // inicializa
    useEffect(() => {
        // verifica se é alteração
        if(id){
            alterarModo('edicao');
            consultarId();

        }else{

        }

    }, []);

    useEffect(() => {

        // verifica se alterou a tab
        if(tabInicial && tabInicial !== tab){

            // define
            alterarTab(tabInicial);
        }
    }, [tabInicial]);

    // consulta os dados do cliente
    async function consultarId(){

        try{
            
            // faz a requisição
            let { data } = await axios.get(`/empresas/${id}`, {
                params: {
                    consultarUsuarioMaster: 'S'
                }
            });
            
            alterarDados({...data.dados});
            alterarDadosIniciais({...data.dados});

            // verifica se possui dados do usuário master
            if(data.usuarioMaster && data.usuarioMaster.dados){

                // define
                alterarDadosUsuarioMaster({...data.usuarioMaster.dados});
            }

            alterarInfoVersao(data.infoVersao);
            alterarInfoVersaoAtual(data.intoVersaoAtual);
            alterarLiberarAtualizarServidor(data.liberarAtualizarServidor);

        }catch(e){
            console.log('pegou o erro')
        }finally{
            alterarCarregando(false);
        }

    }

    // salva edição
    async function salvar(){
        alterarSalvando(true);

        // faz a requisição
        try{

            // console.log(tab, retornaDadosFormulario(tab));
            // return;

            // faz a requisição
            let { data } = await axios.post(`/empresas`, {
                dados: retornaDadosFormulario(tab)  // pega dados para a tab selecionada
            });

            // altera os dados
            alterarDados({...data.dados});
            alterarDadosIniciais({...data.dados});

            // mensagem de cadastro realizado com sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Cliente editado!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    {dados.razaoSocial}
                </div>
            </>);

            // // vai para a consulta de sms's enviados (por enquanto volta para a consulta de clientes)
            // history.push('/painel/caixa-saida');

        }catch({response}){

            // informa erro
            toast(<>
                <div
                    style={{background: '#ff6271'}}
                >
                    <div className="toast-header">
                        <strong className="mr-auto">Erro ao editar empresa!</strong>
                        <button 
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                        </button>
                    </div>
                    <div className="toast-body text-light">
                        {
                            (response && response.data) &&
                            Object.keys(response.data).map((erro) => <p className={'m-0'} key={erro}>{response.data[erro]}<br/></p>)
                        }
                    </div>
                </div>
            </>);

        }finally{
            if (isMounted.current) {
                alterarSalvando(false);
            }
        }

    }

    // função para alterar o caminho da tab
    const alterarCaminhoTab = (t) => {

        // altera a tab
        alterarTab(t);

        // prepara
        let caminhoTab = `${url}/${t}`;

        // verifica se possui tab inicial
        if(tabInicial){

            // altera para remover a última tab
            caminhoTab = `./${t}`;
        }

        // adiciona
        history.push(caminhoTab);
        
    }
      
    // prepara as informações que serão passadas para os formulários
    const informacoesFormularios = {
        alterarDados: dadosNovos => {
            Object.assign(dados, dadosNovos);
            alterarDados({...dados});
        },
        funcaoSalvar: salvar,
        salvando: salvando,
        modo: modo,
        alterarTab: alterarCaminhoTab,
        atualizarFicha: () => {

            // consulta novamente
            consultarId();
        }
    };

    if(carregando){
        return <div>Carregando...</div>
    }
    
    return <>
        <FichaClienteComponent className={[retornaModoCliente(dados)].join(' ')}>
            <Tabs
                id="ficha-cliente-tabs"
                className={[styles.estiloTabs, 'mb-2'].join(' ')}
                activeKey={tab}
                onSelect={alterarCaminhoTab}
            >
                <Tab eventKey="ficha" title="Ficha">
                    <FormularioFicha 
                        dados={retornaDadosFormulario('ficha')}
                        {...informacoesFormularios}
                    />
                </Tab>
                {

                    permissaoReducer.acessoServidorClientes === 'S' &&
                    <Tab eventKey="financeiro" title="Financeiro">
                        <FormularioFinanceiro
                            dados={retornaDadosFormulario('financeiro')}
                            {...informacoesFormularios}
                        />  
                    </Tab>
                }
                {

                    permissaoReducer.acessoServidorClientes === 'S' &&
                    <Tab eventKey="servidor" title="Servidor">
                        <FormularioServidor
                            dados={retornaDadosFormulario('servidor')}
                            dadosIniciais={intersectObject(dadosPadraoServidor, dadosIniciais)}
                            dadosUsuarioMaster={dadosUsuarioMaster}
                            liberarAtualizarServidor={liberarAtualizarServidor}
                            infoVersao={infoVersao}
                            intoVersaoAtual={intoVersaoAtual}
                            {...informacoesFormularios}
                        />   
                    </Tab>
                }
                {

                    permissaoReducer.acessoServidorClientes === 'S' &&
                    <Tab eventKey="backups" title="Backups">
                        <EmpresasBackups />
                    </Tab>
                }
                {

                    permissaoReducer.acessoServidorClientes === 'S' &&
                    <Tab eventKey="integracao" title="Integração">
                        <Row>
                            <Col className='d-flex flex-column' style={{gap: '0.825em'}}>
                                <ListaLogsApi
                                    idEmpresa={id}
                                />
                                <ListaLogsApiPorRota
                                    idEmpresa={id}
                                />
                            </Col>
                            <Col xl={5} className='d-flex flex-column' style={{gap: '0.825em'}}>
                                <EmpresasIntegracaoSistemas
                                    idEmpresa={id}
                                />
                                <EmpresaEstatisticas
                                    idEmpresa={id}
                                />
                            </Col>
                        </Row>
                    </Tab>
                }
                {
                    permissaoReducer.acessoUsuariosClientes === 'S' &&
                    <Tab eventKey="usuarios" title="Usuários" className="aba-usuarios">
                        <EmpresasUsuarios
                            parametrosConsulta={{
                                idRazaoSocial: dados.id,
                                online: []
                            }}
                            filtrosAberto={true}
                            idEmpresa={dados.id}
                        />
                    </Tab>
                }
                {
                    permissaoReducer.acessoServidorClientes === 'S' &&
                    <Tab eventKey="arquivos" title="Arquivos">
                        <Row>
                            <Col className='d-flex flex-column' style={{gap: '0.825em'}}>
                                <RelatorioArquivosEmpresa
                                    idEmpresa={id}
                                />
                            </Col>
                        </Row>
                    </Tab>
                }
                {
                    permissaoReducer.acessoFaturas === 'S' && 
                    <Tab eventKey="faturas" title="Faturas">
                        Faturas
                    </Tab>
                }
                {
                    permissaoReducer.acessoContrato === 'S' && 
                    <Tab eventKey="contrato" title="Contrato">
                        <Contrato
                            idEmpresa={dados.id}
                        />
                    </Tab>
                }
                
                {/* cards */}
                { loginReducer.trello && 
                    <Tab eventKey="cards" title="Cards" className="aba-cards">
                        { tab === 'cards' && 
                            <Cards
                                idRazaoSocial={dados.id}
                            />
                        }
                        
                    </Tab>
                }

                <Tab eventKey="historico" title="Histórico">
                    {/* <Historico
                        idEmpresa={dados.id}
                    />    */}
                </Tab>

            </Tabs>
        </FichaClienteComponent>
    </>
}

// retorna página de consulta
export default FichaCliente;