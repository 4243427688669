import React, { useState } from 'react';

import { html } from '@codemirror/lang-html';
import CodeMirror from '@uiw/react-codemirror';
import { Editor } from "@tinymce/tinymce-react";

import axios from 'axios';

import { Alert, Col, Form, Card, Spinner } from 'react-bootstrap';

import { FormularioComponent } from './components';
import ComboListaModal from '../../ComboListaModal';
import Confirmar from '../../../../../Componentes/Confirmar';

export default function Formulario({
    modo,
    
    dados,
    alterarDados,

    dadosIniciais,

    erros,
    alterarErros,

    carregando
    
}){


    // prepara outros estados
    const [ mostrarEditorHtml, alterarMostrarEditorHtml ] = useState(false);
    const [ mostrarListaTemplates, alterarMostrarListaTemplates ] = useState(false);
    const [ confirmar, alterarConfirmar ] = useState(null);

    // função para o upload de imagens
    const uploadImagem = (blobInfo, progress, failure) => {

        return new Promise((resolve, reject) => {

            // cria o formulário
            let formData = new FormData();
            formData.append('arquivo', blobInfo.blob(), blobInfo.filename());
    
            // faz a solicição
            axios.post(`/notificacoes/imagem`, formData, {
                onUploadProgress: (e) => {
                    progress((e.loaded / e.total) * 100);
                    if(progress && typeof progress === "function"){
                        const percent = 0;
                        progress(percent);
                    }
                },
                headers: {'Content-type': 'multipart/form-data'}
            })
            .then(({data, ...retorno}) => {
    
                // resolve
                resolve(data.url);
            })
            .catch(({response, ...retorno}) => {
    
                // erro
                if(response){
    
                    // resposta
                    if(response.status === 400){
                        // alterarErros(response.data);
                        console.log(response.data);
                    }
                }

                // rejeita a promise
                reject({ message: "Erro no upload", remove: true });

                // se houver função de callback para erro
                if (failure && typeof failure === "function") {
                    failure("Falha no upload");
                }
            })

        });
    };

    return <FormularioComponent>

        <Confirmar config={confirmar} alterar={alterarConfirmar}/>
        <ComboListaModal
            mostrar={mostrarListaTemplates}
            alterarMostrar={alterarMostrarListaTemplates}
            aoFechar={() => alterarMostrarListaTemplates(false)}
            aoSelecionar={(template) => {
                
                // fecha
                alterarMostrarListaTemplates(false);

                // confirma
                alterarConfirmar({
                    aberto: true,
                    titulo: 'Carregar template',
                    texto: `Tem certeza que deseja substituir o conteúdo pelo template?`,
                    textoBotao: 'Substituir',
                    variantConfirmar: 'danger',
                    variantCancelar: 'secondary',
                    backdrop: true,
                    aoConfirmar: () => {

                        // substitui o texto
                        alterarDados({
                            ...dados,
                            texto: template.dados.texto
                        });
                    },
                    aoCancelar: () => {
                        // ao cancelar
                    },
                    aoFechar: () => {
                        // ao fechar
                    }
                });

            }}
        />
        <Card className='border-0 shadow-sm mb-4'>
            <Card.Header className='mb-0 border-0 bg-white'>
                <Card.Title className='pb-0 mb-0'>{modo === 'cadastro' ? 'Novo template' : 'Editar template'}</Card.Title>
            </Card.Header>
            <Card.Body className={['formulario'].join(' ')}>
                {carregando && <div className="carregamento" align="center">
                    <Spinner 
                        animation="border"
                        size='md' 
                        variant="secondary"
                    />
                </div>}

                <Form.Row>
                    <Col>
                        <Form.Label>Título</Form.Label>
                        <Form.Control
                            size="sm"
                            value={dados.titulo}
                            onChange={(e) => {
                                alterarDados({...dados, titulo: e.target.value})
                            }}
                        />
                        {(erros.titulo) && 
                            <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.titulo}</p></Alert>
                        }
                    </Col>
                </Form.Row>

                <Form.Row className="mt-3">
                    <Col>
                        <Editor
                            apiKey='plaop5dwrwxnx2t7m05fskt6k81oc1tu6o795vim9u2a6s6j'
                            initialValue={dadosIniciais ? dadosIniciais.texto : ''}
                            value={dados.texto}
                            init={{
                                branding: false,
                                height: 400,
                                menubar: true,
                                plugins:
                                    "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                toolbar:
                                    "btnCarregarTemplate undo redo | btnEditorHtml | formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                
                                // plugins: 'powerpaste casechange searchreplace autolink directionality visualblocks visualchars image link media mediaembed codesample table charmap pagebreak nonbreaking anchor tableofcontents insertdatetime advlist lists checklist wordcount tinymcespellchecker editimage help formatpainter permanentpen charmap linkchecker emoticons advtable export autosave advcode fullscreen',
                                // toolbar: "undo redo print spellcheckdialog formatpainter | blocks fontfamily fontsize | bold italic underline forecolor backcolor | link image | alignleft aligncenter alignright alignjustify | code",

                                image_advtab: true,
                                advcode_inline: true,
                                 
                                // upload de imagens
                                automatic_uploads: true,
                                images_reuse_filename: true,
                                images_upload_handler: uploadImagem,

                                setup: editor => {

                                    // prepara
                                    let btnEditorHtmlValor = mostrarEditorHtml;

                                    // botão para mostrar / esconder a edição por html
                                    editor.ui.registry.addButton('btnEditorHtml', {
                                        icon: 'sourcecode',
                                        onAction: (e) => {

                                            // altera
                                            alterarMostrarEditorHtml(!btnEditorHtmlValor);
                                            btnEditorHtmlValor = !btnEditorHtmlValor;
                                        }
                                    });

                                    // botão para mostrar / esconder a edição por html
                                    editor.ui.registry.addButton('btnCarregarTemplate', {
                                        icon: 'export',
                                        onAction: (e) => {

                                            // abre a lista com templates
                                            alterarMostrarListaTemplates(true);
                                        }
                                    });
                                }
                            }}
                            onEditorChange={(texto) => {
                                
                                // altera os dados
                                alterarDados({
                                    ...dados, texto
                                });
                            }}
                        />
                    </Col>
                    {(erros.texto) && 
                        <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.texto}</p></Alert>
                    }
                </Form.Row>

                {mostrarEditorHtml && <Form.Row className="mt-2">
                    <Col>
                        {/* <div className="preview-markdown mt-3 p-2 shadow-sm">
                            <MDEditor.Markdown source={textoMarkdown} />
                        </div> */}
                        {/* <pre contentEditable onChange={(texto) => console.log({texto})}>
                            {dados.texto}
                        </pre> */}
                        <CodeMirror
                            height={400}
                            value={dados.texto}
                            onChange={(valor) => alterarDados({...dados, texto: valor})}
                            extensions={[html()]}
                            basicSetup={{
                                lineNumbers: true,
                                
                            }}
                            indentWithTab={true}
                        />
                    </Col>
                </Form.Row>}

            </Card.Body>
        </Card>
    </FormularioComponent>
}