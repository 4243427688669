import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useIsMounted from 'ismounted';
import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// componentes
import { Col, Container, Row, Card, Form, Button } from 'react-bootstrap';


export default function Cadastrar(){

    // estados
    const history = useHistory();
    const isMounted = useIsMounted();
    const {id} = useParams(); // parametro url
    const [dados, alterarDados] = useState({
        id: id || null,
        descricao: null
    });
    const [salvando, alterarSalvando] = useState(false);
    const [carregando, alterarCarregando] = useState(true);
    const [modo, alterarModo] = useState('cadastro'); // cadastro, edicao

    // consulta id do grupo
    async function consultarId(){

        try{
            
            // faz a requisição
            let { data } = await axios.get(`/apisGrupos/${id}`);
            
            alterarDados({...data.dados});
            alterarCarregando(false);

        }catch(e){
            console.log('Erro');
        }
    }

    // salva registro
    async function salvar(){
        alterarSalvando(true);

        // faz a requisição
        try{

            // faz a requisição
            let { data } = await axios.post(`/apisGrupos`, dados);
            history.push(`/painel/documentacao-api/grupos`);

        }catch({response}){}
    }

    // inicializa
    useEffect(() => {
        if(id){
            alterarModo('edicao');
            consultarId();
        }else{
            alterarCarregando(false);
        }
    }, []);


    if(carregando){
        return <div>Carregando...</div>
    }

    return <>
        <Row>
            <Col lg={6}>
                <Card className='border-0 shadow-sm'>
                    <Card.Header className='mb-0 border-0 bg-white'>
                        <Card.Title className='pb-0 mb-0'>Grupo de api</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form.Group>
                            <Form.Label>Descrição</Form.Label>
                            <Form.Control 
                                value={dados.descricao}
                                onChange={e => {
                                    dados.descricao = e.target.value;
                                    alterarDados({...dados});
                                }}
                            />
                        </Form.Group>
                    </Card.Body>
                </Card>

                <Form.Group className="d-flex justify-content-around my-4">
                    <Button 
                        variant="danger" 
                        onClick={() => {
                            history.push(`/painel/documentacao-api/grupos`);
                        }}
                    >Cancelar</Button>
                    <Button 
                        variant="success" 
                        disabled={salvando} 
                        onClick={salvar}
                    >
                        {salvando ? 
                            <>
                                <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                                <span>Salvando</span>
                            </> : 
                            <>
                                <FontAwesomeIcon className="icone" icon={["fas", 'check']} />
                            <span>{modo === 'edicao' ? 'Salvar' : 'Cadastrar'}</span>
                            </>
                        }
                    </Button>
                </Form.Group>
            </Col>
        </Row>
    </>


}