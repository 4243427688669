import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Row, Col, Form, Button, InputGroup, Tabs, Tab} from 'react-bootstrap';
import { toast } from 'react-toastify';
import useIsMounted from 'ismounted';
import {
  BarChart, Brush, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer
} from 'recharts';


// escreve o email
export default function GraficoBarra({
	dadosGrafico,
	aoSelecionar,
	config={}
}){
	
    // dados
    const permissaoReducer = useSelector(state => state.permissaoReducer);
    
	const [activeIndex, ativarSelecionada] = useState(null);
	const [configuracao, alterarConfiguracao] = useState(Object.assign({
		// configuração padrão
		clicavel: false,
		ativarSelecionado: false
	}, config));

	// função ao selecionar dado
	function selecionouDado(data, index){
		// ativa
		ativarSelecionada(index);

		// executa o callback
		aoSelecionar(index)
	}

    // inicializa
    useEffect(() => {
		
    }, []);

	// verifica se possui dados para o grafico
	if(dadosGrafico && dadosGrafico.length === 0){
		return <>Sem dados</>
	}

    return <>
		<ResponsiveContainer width="95%" height={300}>
			<BarChart
				data={dadosGrafico}
				margin={{
					top: 0,
					bottom: 0,
					left: -20,
					right: 0
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="name" tick={(a) => {
					// retorna mantendo o texto como html
					const {x, y, stroke, payload} = a;
		
					let texts = payload.value.split('<br/>');
					return <>
						<g transform={`translate(${x},${y})`}>
							<text x={0} y={0} dy={16} fill="#666">
								<tspan textAnchor="middle" x="0" dy="20">{texts[0]}</tspan>
							</text>
						</g>
					</>;
				}} />
				<YAxis />
				<Tooltip  
					wrapperStyle={{
						zIndex: 10,
						marginLeft: '-100px'
					}} 
					labelFormatter={(a) => {
						// retorna mantendo o texto como html
						return <>
							<span
								dangerouslySetInnerHTML={{__html: a}}
							>
							</span>
						</>
					}}
				/>
				<Legend />
				<Bar name="Acessos" dataKey="acessos" stackId="a" fill="#f3dc00" onClick={configuracao.clicavel ? selecionouDado : () => {}}>
					{
						/* se gráfico é clicável */
						(dadosGrafico && configuracao.clicavel) && 
						dadosGrafico.map((entry, index) => (
							<Cell cursor="pointer" fill={index === activeIndex && configuracao.ativarSelecionado ? '#8b8b8bb0' : '#f3dc00'} key={`cell-${index}`} />
						))
					}
				</Bar>
				<Bar name="Atualizações" dataKey="atualizacoes" stackId="a" fill="#f03737" onClick={configuracao.clicavel ? selecionouDado : () => {}}>
					{
						/* se gráfico é clicável */
						(dadosGrafico && configuracao.clicavel) && 
						dadosGrafico.map((entry, index) => (
							<Cell cursor="pointer" fill={index === activeIndex && configuracao.ativarSelecionado ? '#535353b0' : '#f03737'} key={`cell-${index}`} />
						))
					}
				</Bar>
				<Bar name="Novas guias" dataKey="novas_guias" stackId="a" fill="#03A9F4" onClick={configuracao.clicavel ? selecionouDado : () => {}}>
					{
						/* se gráfico é clicável */
						(dadosGrafico && configuracao.clicavel) && 
						dadosGrafico.map((entry, index) => (
							<Cell cursor="pointer" fill={index === activeIndex && configuracao.ativarSelecionado ? '#535353b0' : '#03A9F4'} key={`cell-${index}`} />
						))
					}
				</Bar>
			</BarChart>
		</ResponsiveContainer>
    </>
}