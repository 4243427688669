import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';

import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';

import Paginacao from '../../../Componentes/Paginacao';
import Confirmar from '../../../Componentes/Confirmar';

import { ListaUltimosAcessosComponent } from "./components";

import Registro from './Registro';
import ModalFiltros from './ModalFiltros';

export default function ListaUltimosAcessos({
    idEmpresa = null
}){

    // pega location
    const history = useHistory();
    const location = useLocation();
    let { path, url } = useRouteMatch();

    // estados
    const [ carregando, alterarCarregando ] = useState(false);
    const parametrosPadrao = {
        registrosPorPagina: 8,
        pagina: 1,
        pesquisa: null,
        dataInicio: null,
        dataFinal: null,
        idsEmpresas: null
    };
    const [ parametros, alterarParametros ] = useState({
        ...parametrosPadrao,
        idsEmpresas: idEmpresa ? [idEmpresa] : null
    });
    const [ registros, alterarRegistros ] = useState([]);
    const [ dadosEmpresa, alterarDadosEmpresa ] = useState(null);
    const [ totalRegistros, alterarTotalRegistros ] = useState(null);
    const [ mostrarFiltros, alterarMostrarFiltros ] = useState(false);
    const refAbrirFiltros = useRef(null);

    // efeitos

    useEffect(() => {

        if(idEmpresa){
            
            // consulta
            consultarPorIdEmpresa();
        }
    }, [])

    useEffect(() => {

        // consulta registros
        consultar();
    }, [parametros]);

    // função para consultar
    async function consultar(){

        // cria o carregamento
        alterarCarregando(true);

        try{

            // consulta
            let { data } = await axios.get('/empresas/acessos', {
                params: {
                    ...parametros
                }
            });

            // sucesso

            // pega os registros e total
            alterarRegistros(data.registros);
            alterarTotalRegistros(data.totalRegistros);

        }catch(e){
            // erro
        }finally{
            // finalizou

            // remove o carregamento
            alterarCarregando(false);
        }
    }

    // função para consultar
    async function consultarPorIdEmpresa(){

        // cria o carregamento
        alterarCarregando(true);

        try{

            // consulta
            let { data } = await axios.get(`/empresas/${idEmpresa}`);

            // sucesso

            // pega os registros e total
            alterarDadosEmpresa(data.dados);

        }catch(e){
            // erro
        }finally{
            // finalizou

            // remove o carregamento
            alterarCarregando(false);
        }
    }

    // retorna registro
    function retornaRegistros(){

        // retorna registros organizado
        return registros.map((registro, indice) => 
            <Registro
                key={registro.dados.id}
                dataAnterior={indice > 0 ? registros[indice - 1].dados.dataCadastro : null}
                dados={registro.dados}
                dadosUsuario={registro.dadosUsuario}
                dadosEmpresa={registro.dadosEmpresa}

                clicou={idEmpresa ? null : () => {

                    history.push(`${url}/${registro.dados.idEmpresa}`);
                }}
            />
        )
    }

    return <ListaUltimosAcessosComponent>
        <ModalFiltros
            elemento={refAbrirFiltros.current}
            mostrar={mostrarFiltros}
            alterarMostrar={alterarMostrarFiltros}
            parametros={parametros}
            alterarParametros={alterarParametros}
        />
        
        <Card.Body>
            <Card.Header className={'px-0'}>
                <div className={'d-flex '}>
                    <Card.Title className={'flex-grow-1 d-flex align-items-center mb-0'} style={{gap: '0.5em'}}>
                        <span>Últimos acessos</span>
                        {
                            dadosEmpresa && <>
                                <small>|</small>
                                <font color={'#288700'}>
                                    {dadosEmpresa.razaoSocial}
                                </font>
                            </>
                        }
                    </Card.Title>
                    
                    <div>
                        <Button
                            variant='light'
                            size='sm'
                            ref={refAbrirFiltros}
                            onClick={() => {

                                // define
                                alterarMostrarFiltros(true);
                            }}
                            style={{
                                fontSize: '0.9em',
                                boxShadow: '0 0 1px #b1b1b1',
                                border: 0,
                                display: 'flex',
                                gap: '0.2em',
                                alignItems: 'center'
                            }}
                        >
                            <FontAwesomeIcon className="icone" icon={faFilter} />
                            Filtrar
                        </Button>
                    </div>
                </div>
            </Card.Header>
            <div>
            {
                    carregando ? <div className="carregamento">
                        <FontAwesomeIcon icon={faSpinner} pulse /> Carregando...
                    </div> : (
                        <div
                            className="tabela-container"
                            style={{flexGrow: 1}}
                        >
                        <table className="table table-hover tabela">
                            <tbody>
                                { retornaRegistros() }
                            </tbody>
                        </table>
                    </div>)
                }
                <Paginacao
                    pagina={parametros.pagina}
                    registrosPorPagina={parametros.registrosPorPagina}
                    totalRegistros={totalRegistros}
                    alterouPagina={(pagina) => {
                        alterarParametros({...parametros, pagina: pagina});
                    }}
                />
                
                <div className='legenda'>
                    <div className='login'>Login</div>
                    <div className='refresh'>Atualizar página</div>
                    <div className='new-tab'>Nova guia</div>
                    <div className='logout'>Logout</div>
                    <div className='derrubado'>Derrubado</div>
                </div>

            </div>
        </Card.Body>
        
    </ListaUltimosAcessosComponent>
    
}