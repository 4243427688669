import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';
import axios from 'axios';
import { toast } from 'react-toastify';

// componentes
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Container, Card, Row, Col, Form, Button, InputGroup, OverlayTrigger, Tooltip} from 'react-bootstrap';
import Select from 'react-select';
import useIsMounted from 'ismounted';
import { intersectObject, copiaCampo } from './../../../../Funcoes';

// contexto
import { PainelContexto } from './../../../PainelContexto';

// faz o cadastro
export default function ModalEditar({
    registro = null,
    fechou,
    salvou
}){

    // dados
    let isMounted = useIsMounted();
    let painelContextoDados = useContext(PainelContexto);
    let [mostrar, alterarMostrar] = useState(true);
    let [salvando, alterarSalvando] = useState(false);
    let [carregando, alterarCarregando] = useState(true);
    const dadosPadrao = {
        id: null,
        nome: '',
        usuario: '',
        email: null,
        senha: null,
        permissao: null
    }
    const dadosInicial = Object.assign({}, registro);
    let [dados, alterarDados] = useState(Object.assign(Object.assign({}, dadosPadrao), registro));

    const permissoes = [
        {value: '1', label: 'Projetista'},
        {value: '2', label: 'Técnico'},
        {value: '3', label: 'Vendedor'},
        {value: '4', label: 'Viabilidade'}
    ]

    // pega o modo inicial se edição
    const permissaoInicial = (dados.id) ? permissoes.filter((e) => dados.permissao === e.value)[0] : null

    const camposRef = {
        usuario: React.useRef(),
        senha: React.useRef(),
        nome: React.useRef(),
        email: React.useRef()
    }

    // inicializa
    useEffect(() => {
        
    }, []);

    // finaliza
    async function salvar(){

        // salva registro
        alterarSalvando(true);

        // faz a requisição
        try{

            // faz a requisição
            let { data } = await axios.post(`/cliente`, {
                dados: intersectObject(dadosPadrao, dados)
            });

            // mensagem de cadastro realizado com sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Usuário editado!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    {dadosInicial.nome}
                </div>
            </>);

            // // vai para a consulta de sms's enviados (por enquanto volta para a consulta de clientes)
            // history.push('/painel/caixa-saida');
            
            alterarSalvando(false);
            alterarMostrar(false);
            salvou();
            
        }catch({response}){

        }finally{
            if (isMounted.current) {
                alterarSalvando(false);
            }
        }
    }

    // return <>
    //     Aqui retorna
    // </>
    return <Modal 
        show={mostrar} 
        onExited={fechou}
        onHide={() => {
            if(!salvando){
                alterarMostrar(false);
            }
        }}
        size={'md'}
    >
        <Modal.Header className='border-0'>
            <Modal.Title 
                as='h5' 
                className={[
                    'text-center w-100',
                    'text-success'
                ].join(' ')}
            >
                {dadosInicial.nome} ({dados.id})
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'pt-0'}>
            <Container>
                <Form.Group>
                    <Form.Label>Nome</Form.Label>
                    <InputGroup className="mb-0">
                        <Form.Control 
                            ref={camposRef.nome}
                            value={dados.nome || ''}
                            onChange={e => {
                                alterarDados({...dados, nome: e.target.value});
                            }}
                        />
                        <InputGroup.Append>
                            <Button
                                variant="outline-secondary"
                                onClick={() => {

                                    // pega o campo
                                    let campo = camposRef.nome.current
                                    copiaCampo(campo);

                                }}
                                className={[styles.btnForm, ""].join(' ')}
                            >
                                <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "copy"]}/>
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Form.Group>
                
                <Form.Row>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>Usuário</Form.Label>
                            <InputGroup className="mb-0">
                                <Form.Control 
                                    ref={camposRef.usuario}
                                    value={dados.usuario || ''}
                                    onChange={e => {
                                        alterarDados({...dados, usuario: e.target.value});
                                    }}
                                />
                                <InputGroup.Append>
                                    <Button
                                        variant="outline-secondary"
                                        onClick={() => {
                                            
                                            // pega o campo
                                            let campo = camposRef.usuario.current
                                            copiaCampo(campo);
        
                                        }}
                                        className={[styles.btnForm, ''].join(' ')}
                                    >
                                        <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "copy"]}/>
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>Senha</Form.Label>
                            <InputGroup className="mb-0">
                                <Form.Control 
                                    ref={camposRef.senha}
                                    value={dados.senha || ''}
                                    onChange={e => {
                                        alterarDados({...dados, senha: e.target.value});
                                    }}
                                />
                                <InputGroup.Append>
                                    <Button
                                        className={[styles.btnForm, ''].join(' ')}
                                        variant="outline-secondary"
                                        onClick={() => {

                                            // pega o campo
                                            let campo = camposRef.senha.current
                                            copiaCampo(campo);

                                        }}
                                    >
                                        <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "copy"]}/>
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Col lg={4}>
                        <Form.Label>Permissão</Form.Label>
                        <Select
                            options={permissoes}
                            defaultValue={permissaoInicial}
                            isMulti={false}
                            placeholder='Permissão'
                            onChange={(e) => {
                                // altera os filtros selecionados
                                alterarDados({...dados, permissao: e.value});
                            }}
                        />
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>E-mail</Form.Label>
                            <InputGroup className="mb-0">
                                <Form.Control 
                                    ref={camposRef.email}
                                    value={dados.email || ''}
                                    onChange={e => {
                                        alterarDados({...dados, email: e.target.value});
                                    }}
                                />
                                <InputGroup.Append>
                                    <Button
                                        variant="outline-secondary"
                                        onClick={() => {
                                            
                                            // pega o campo
                                            let campo = camposRef.email.current
                                            copiaCampo(campo);
                                            
                                        }}
                                        className={[styles.btnForm, ''].join(' ')}
                                    >
                                        <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "copy"]}/>
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Form.Row>
            </Container>
        </Modal.Body>
        <Modal.Footer  className='border-0'>
            <Button
                variant='danger'
                onClick={() => {alterarMostrar(false)}}
                disabled={salvando}
            >
                <span>Fechar</span>
            </Button>
            <Button
                onClick={salvar}
                variant='success'
                disabled={salvando}
            >
                {salvando ? 
                    <>
                        <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                        <span>Salvando</span>
                    </> : 
                    <span>Salvar</span>
                }
            </Button>
        </Modal.Footer>
    </Modal>
}   