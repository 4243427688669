import React from 'react';

// componentes
import { Card, Row, Col, Form, Button, Alert } from 'react-bootstrap';

// formulário com trello
export default function FormularioTrello({
    dados,
    alterarDados,
    erros,
    alterarErros
}){

    return <>
        <Card className='border-0 shadow-sm'>
            <Card.Header className='mb-0 border-0 bg-white'>
                <Card.Title className='pb-0 mb-0'>Configurar Integração com Trello</Card.Title>
            </Card.Header>
            <Card.Body className={['formulario'].join(' ')}>
                <Form.Group>
                    <Form.Row>
                        <Col>
                            <Form.Label>Senha atual</Form.Label>
                            <Form.Control 
                                size="sm"
                                value={dados.senha || ''}
                                onChange={e => {
                                    let valorNovo = null;
                                    if(e.target.value !== ""){
                                        valorNovo = e.target.value;
                                    }
                                    alterarDados({...dados, senha: valorNovo});
                                }}
                            />
                            {(erros.trello_senha) && 
                                <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.trello_senha}</p></Alert>
                            }
                        </Col>
                        <Col>
                            <Form.Label>Chave</Form.Label>
                            <Form.Control 
                                size="sm"
                                value={dados.chave || ''}
                                onChange={e => {
                                    alterarDados({...dados, chave: e.target.value});
                                }}
                                disabled={dados.senha === null}
                            />
                        </Col>
                        <Col>
                            <Form.Label>Token</Form.Label>
                            <Form.Control 
                                size="sm"
                                value={dados.token || ''}
                                onChange={e => {
                                    alterarDados({...dados, token: e.target.value});
                                }}
                                disabled={dados.senha === null}
                            />
                        </Col>
                    </Form.Row>
                </Form.Group>
            </Card.Body>
        </Card>
    </>

};