import React, { useState, useEffect, useRef } from 'react';
import styles from './index.module.scss';
import stylesGeral from './../index.module.scss';
import axios from 'axios';

// componentes
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { Card, Row, Col, Form, Button, InputGroup, OverlayTrigger, ProgressBar, Tooltip, Spinner } from 'react-bootstrap';
import ComboServidoresCriar from './../../../../Componentes/ComboServidoresCriar';
import ComboIntegracoesCriar from './../../../../Componentes/ComboIntegracoesCriar';
import ComboVersao from './../../../../Componentes/ComboVersao';
import Confirmar from './../../../../Componentes/Confirmar';
import ReactMarkdown from 'react-markdown';
// import htmlParser from 'react-markdown/plugins/html-parser';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import useIsMounted from 'ismounted';

import {copiaCampo} from './../../../../Funcoes';

import InfoServidor from './InfoServidor';

import FormularioPadrao from './../FormularioPadrao';
import { faCheck, faClock, faDatabase, faExclamationTriangle, faEye, faPencilAlt, faServer, faShare, faSkullCrossbones, faSyncAlt, faTrash, faUserTie, faWarning } from '@fortawesome/free-solid-svg-icons';
import ComboGruposChaveGoogle from '../../../../Componentes/ComboGruposChaveGoogle';
import ComboBancosDados from '../../../../Componentes/ComboBancosDados';
import ModalDeletarBase from '../../../EmpresasBackups/ModalDeletarBase';
import ModalInstalar from '../../../EmpresasBackups/ModalInstalar';
import ModalLimparBase from '../../../EmpresasBackups/ModalLimparBase';

// retorna conteudo
export default function FormularioServidor({
    dados={},
    dadosIniciais={},
    dadosUsuarioMaster={},
    liberarAtualizarServidor='N',
    infoVersao=null,
    intoVersaoAtual=null,
    alterarDados,
    funcaoSalvar,
    salvando,
    alterarTab,
    modo,
    atualizarFicha
}){

    // contextos
    const { loginReducer, permissaoReducer } = useSelector(state => state);
    
    const history = useHistory();
    const isMounted = useIsMounted();
    
    const [editarObservacao, alterarEditarObservacao] = useState(false);
    const [liberandoVersao, alterarLiberandoVersao] = useState(false);
    const [ testandoConexaoBanco, alterarTestandoConexaoBanco ] = useState(false);
    const [ confirmar, alterarConfirmar ] = useState(null);
    const [ mostrarIpServidorProvisorio, alterarMostrarIpServidorProvisorio ] = useState(false);
    const [ servidorInstalar, alterarServidorInstalar ] = useState(null);
    const [ mostrarDeletarBase, alterarMostrarDeletarBase ] = useState(false);
    const [ mostrarInstalar, alterarMostrarInstalar ] = useState(false);
    const [ mostrarLimparBase, alterarMostrarLimparBase ] = useState(false);
    
    const fichaLiberada = permissaoReducer.edicaoClientes === 'S';

    const [markdown, alterarMarkdown] = useState(dados.obsServidor || '');
    // const parseHtml = htmlParser({
    //     isValidNode: node => node.type !== 'script',
    //     processingInstructions: [/* ... */]
    // })
    
    // campos referência
    const camposRef = {
        enderecoServidor: React.useRef(),
        enderecoServidorProprioAcesso: React.useRef(),
        tokenIntegrador: React.useRef(),
        servidor: React.useRef(),
        ipServidor: React.useRef(),
        pasta: React.useRef(),
        senhaBanco: React.useRef()
    }

    // refs
    const toastId = useRef(null);
    
    // array para o select de modo
    const servidores = [
        {value: '1', label: 'Aguardando instalação'},
        {value: '2', label: 'Servidor Próprio'},
        {value: '3', label: 'Servidor Alugado'},
        {value: '4', label: 'Quarentena'}
    ]
    
    useEffect(() => {

        // verifica se possui o id banco
        if(dados.idBanco){

            // testa a conexão com o banco de dados
            testarConexaoBancoDados();
        }
    }, []);

    // força entrada como admin la no servidor do cliente
    function clicouEntrarAdmin(){

        // pega endereco do servidor com barra
        let enderecoServidor = dados.enderecoServidor;
        if(enderecoServidor[enderecoServidor.length - 1] !== '/'){
            enderecoServidor = enderecoServidor + "/";
        }

        // verifica se o endereço possui o http://
        if(enderecoServidor.indexOf("http") === -1){
            enderecoServidor = `https://${enderecoServidor}`;
        }

        // envia
        let form = window.document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", enderecoServidor);
        form.setAttribute("target", "_blank");
        
        // parametro usuario
        let input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'usuario';
        input.value = 'admin';
        form.appendChild(input);

        // parametro senha
        let inputSenha = document.createElement('input');
        inputSenha.type = 'hidden';
        inputSenha.name = 'senha';
        inputSenha.value = permissaoReducer.senhaAdmin;  
        form.appendChild(inputSenha);

        let inputEmpresa = document.createElement('input');
        inputEmpresa.type = 'hidden';
        inputEmpresa.name = 'empresa';
        inputEmpresa.value = dadosUsuarioMaster.id;
        form.appendChild(inputEmpresa);

        document.body.appendChild(form);
        
        //note I am using a post.htm page since I did not want to make double request to the page 
        //it might have some Page_Load call which might screw things up.
        // window.open("http://localhost", name, windowoption);
        form.submit();
        document.body.removeChild(form);

    }

    // função para liberar a próxima versão para a empresa
    const liberarVersao = async () => {

        alterarLiberandoVersao(true);

        // faz a requisição
        try{

            // faz a requisição
            let { data } = await axios.post(`/versao/liberar/${infoVersao.proximaVersao.id}`, {
                dados: {
                    empresas: [dados.id]
                }
            });

            // mensagem de cadastro realizado com sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Versão liberada</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    Versão {infoVersao.proximaVersao.versao} liberada para a empresa!
                </div>
            </>);

            // altera para liberada
            infoVersao.proximaVersaoLiberada = true;
            
        }catch({response}){

        }finally{
            if (isMounted.current) {
                alterarLiberandoVersao(false);
            }
        }
        
    }

    // função para liberar a próxima versão para a empresa
    const testarConexaoBancoDados = async (avisoSucesso = false) => {

        // define o carregamento
        alterarTestandoConexaoBanco(true);

        // faz a requisição
        try{

            // faz a requisição
            let { data } = await axios.get(`/empresas/${dados.id}/bancoDados/teste`);

            // verifica se deve alertar sucesso
            if(avisoSucesso){

                // mensagem de cadastro realizado com sucesso
                toast(({closeToast }) => <>
                    <div className="toast-header">
                        <strong className="mr-auto">Banco de dados correto</strong>
                        <button 
                            onClick={closeToast} 
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="toast-body">
                        A conexão com o banco de dados da empresa foi realizada com sucesso!
                    </div>
                </>);
                
            }
        }catch({response}){
            console.log(response)
            
            // informa erro
            toast(<>
                <div
                    style={{background: '#ff6271'}}
                >
                    <div className="toast-header">
                        <strong className="mr-auto">Erro ao conectar no banco!</strong>
                        <button 
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                        </button>
                    </div>
                    <div className="toast-body text-light">
                        {
                            (response && response.data) &&
                            Object.keys(response.data).map((erro) => <p className={'m-0'} key={erro}>{response.data[erro]}<br/></p>)
                        }
                    </div>
                </div>
            </>);

        }finally{
            
            if (isMounted.current) {
                alterarTestandoConexaoBanco(false);
            }
        }
        
    }

    // função para enviar email com os dados de acesso ao servidor
    async function enviarEmailDadosAcessoServidor(){

        try{

            // envia o email
            let { data } = await axios.post(`/empresas/${dados.id}/enviarEmailDadosServidor`);

            // sucesso

            // informa
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Email programado!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    Email com dados do servidor da empresa foi programado.
                </div>
            </>);
        }catch(e){
            // algum erro
        }finally{
            // terminou
        }
    }
    
    // função para atualizar os arquivos do servidor da empresa
    async function atualizarEmpresa(){

        try{

            // envia o email
            let { data } = await axios.post(`/empresas/${dados.id}/atualizar`);

            // sucesso

            // informa
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Atualização iniciada!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    Atualização do servidor da empresa foi iniciada.
                </div>
            </>);
        }catch(e){
            // algum erro
        }finally{
            // terminou
        }
    }
    
    // função para instalar servidor
    async function iniciarInstalacaoServidor(){

        // executa arquivo de instalação

        let usuarioNome = (dados.enderecoServidor || '').split("/").pop();

        try{


            let { data } = await axios.post(
                `https://${servidorInstalar.host}/instalar.php`, 
                {
                    pasta: usuarioNome,
                    usuarioBanco: usuarioNome,
                    senhaBanco: `${dados.codigo}geogrid2015`,
                    nomeBanco: `${usuarioNome}_geogrid`,
                    arquivoVersao: intoVersaoAtual.arquivo
                }
            );

            // mensagem de cadastro realizado com sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Base Instalada</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    Finalizado instalação de base.
                </div>
            </>);

        }catch({response}){

            // mensagem de cadastro realizado com sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Base Não Instalada</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    Não foi possível fazer a instalação da base
                </div>
            </>);
        }


    }

    return <>
        
        <ModalInstalar
            idEmpresa={dados.id}
            mostrar={mostrarInstalar}
            alterarMostrar={alterarMostrarInstalar}

            aoFinalizar={() => {

                // atualiza informações
                atualizarFicha();
            }}
        />

        <ModalDeletarBase
            idEmpresa={dados.id}

            mostrar={mostrarDeletarBase}
            alterarMostrar={alterarMostrarDeletarBase}

            aoFinalizar={() => {

                // atualiza informações
                atualizarFicha();
            }}
        />

        <ModalLimparBase
            idEmpresa={dados.id}

            mostrar={mostrarLimparBase}
            alterarMostrar={alterarMostrarLimparBase}

            aoFinalizar={() => {
                // finalizou
            }}
        />

        <Confirmar config={confirmar} alterar={alterarConfirmar}/>

        <div className='mb-4'>
            <Row>
                <Col md='7'>
                    <Card className='border-0 shadow-sm'>
                        <Card.Header 
                            className='mb-0 border-0 bg-white'
                        >
                            <div className={'d-flex justify-content-between'}>
                                <Card.Title 
                                    className={['pb-0 mb-0'].join(' ')}
                                >
                                    <span>Dados Servidor / Acesso</span>
                                </Card.Title>
                                <div>
                                    {(liberarAtualizarServidor === 'S' && infoVersao && infoVersao.liberarAtualizarServidor === 'S' && infoVersao.proximaVersaoLiberada) && <OverlayTrigger 
                                        overlay={<Tooltip>Atualizar empresa</Tooltip>}
                                        placement='bottom'
                                    >
                                        <Button
                                            className="mr-2"
                                            variant='warning'
                                            size='sm'
                                            onClick={() => {
                                                
                                                // confirma
                                                alterarConfirmar({
                                                    aberto: true,
                                                    titulo: 'Atualizar servidor',
                                                    texto: `Tem certeza que deseja atualizar os arquivos (versão liberada) do servidor da empresa?`,
                                                    textoBotaoConfirmar: 'Atualizar empresa',
                                                    variantConfirmar: 'info',
                                                    variantCancelar: 'secondary',
                                                    aoConfirmar: () => {

                                                        // atualizar
                                                        atualizarEmpresa();
                                                    },
                                                    aoCancelar: () => {
                                                        // ao cancelar
                                                    },
                                                    aoFechar: () => {
                                                        // ao fechar
                                                    }
                                                })
                                            }}
                                        >
                                            <FontAwesomeIcon className="icone" icon={faSyncAlt} color="#464646"/>
                                        </Button>
                                    </OverlayTrigger>}
                                    <OverlayTrigger 
                                        overlay={<Tooltip>Enviar informações servidor cliente</Tooltip>}
                                        placement='bottom'
                                    >
                                        <Button
                                            variant='info'
                                            size='sm'
                                            onClick={() => {
                                                
                                                // confirma
                                                alterarConfirmar({
                                                    aberto: true,
                                                    titulo: 'Enviar dados servidor',
                                                    texto: `Tem certeza que deseja enviar um email com os dados do servidor para a empresa?`,
                                                    textoBotao: 'Enviar dados servidor',
                                                    variantConfirmar: 'info',
                                                    variantCancelar: 'secondary',
                                                    aoConfirmar: () => {
                                                        // envia
                                                        enviarEmailDadosAcessoServidor();
                                                    },
                                                    aoCancelar: () => {
                                                        // ao cancelar
                                                    },
                                                    aoFechar: () => {
                                                        // ao fechar
                                                    }
                                                })
                                            }}
                                        >
                                            <FontAwesomeIcon className="icone" icon={["fas", "envelope"]} />
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </Card.Header>
                        
                        <Card.Body>
                            <FormularioPadrao {...{
                                dados,
                                alterarDados,
                                funcaoSalvar,
                                salvando,
                                modo,
                                edicaoLiberada: false
                            }}/>
                            <Form.Row className='mb-2'>
                                <Col>
                                    <Form.Label>Nome fantasia</Form.Label>
                                    <Form.Control 
                                        size="sm"
                                        disabled={true}
                                        value={dados.nomeFantasia || ''}
                                        onChange={e => {
                                            alterarDados({...dados, nomeFantasia: e.target.value});
                                        }}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row className='mb-2'>
                                <Col>
                                    <Form.Label>Projetistas</Form.Label>
                                    <Form.Control
                                        size="sm" 
                                        disabled
                                        value={dados.numeroDeUsuariosProjetista || ''}
                                        onChange={e => {
                                            alterarDados({...dados, numeroDeUsuariosProjetista: e.target.value});
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Técnicos</Form.Label>
                                    <Form.Control 
                                        size="sm"
                                        disabled
                                        value={dados.numeroDeUsuariosTecnico || ''}
                                        onChange={e => {
                                            alterarDados({...dados, numeroDeUsuariosTecnico: e.target.value});
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Vendedores</Form.Label>
                                    <Form.Control
                                        size="sm" 
                                        disabled
                                        value={dados.numeroDeUsuariosVendedor || ''}
                                        onChange={e => {
                                            alterarDados({...dados, numeroDeUsuariosVendedor: e.target.value});
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Viabilidade</Form.Label>
                                    <Form.Control 
                                        size="sm"
                                        disabled
                                        value={dados.numeroDeUsuariosViabilidade || ''}
                                        onChange={e => {
                                            alterarDados({...dados, numeroDeUsuariosViabilidade: e.target.value});
                                        }}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    <div className={'d-flex justify-content-between'}>
                                        <Form.Label>Versão</Form.Label>
                                    </div>
                                    <ComboVersao
                                        className="select-ficha"
                                        id={dados.idVersao || null}
                                        alterou={idVersao => {
                                            alterarDados({...dados, idVersao: (idVersao !== '') ? idVersao : null});
                                        }}
                                    />
                                    <div className={'d-flex justify-content-end'}>
                                        {
                                            (infoVersao && infoVersao.proximaVersaoLiberada) &&
                                            <>
                                                <span>
                                                    <FontAwesomeIcon style={{fontSize: 12}} color={'#8f9ca5'} className="icone mx-1" icon={faClock} />
                                                    Aguardando receber <b>{infoVersao.proximaVersao.versao}</b>
                                                </span>
                                            </>
                                        }
                                        {
                                            (infoVersao && !infoVersao.proximaVersaoLiberada) &&
                                            <>
                                                <div
                                                    style={{
                                                        width: '25px',
                                                        top:'4px',
                                                        position: 'relative',
                                                        marginRight: '3px',
                                                    }}
                                                    className='d-flex justify-content-center'
                                                >
                                                    <Spinner 
                                                        animation="border"
                                                        size='sm' 
                                                        variant="secondary"
                                                        hidden={!liberandoVersao}
                                                    />
                                                </div>
                                                <Form.Check 
                                                    className='mr-0'
                                                    type='switch'
                                                    inline
                                                    id={'switch-liberar-proxima versao'}
                                                    label={`Liberar versão ${infoVersao.proximaVersao.versao}`}
                                                    checked={false}
                                                    onChange={e => {
                                                        // libera a versão
                                                        liberarVersao();
                                                    }}
                                                    disabled={liberandoVersao}
                                                />
                                            </>
                                        }
                                    </div>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <div className={'d-flex justify-content-between'}>
                                            <Form.Label>Integração</Form.Label>
                                            <Form.Check
                                                id="switch-liberar-token-integracao"
                                                label="Liberado"
                                                checked={dados.liberarIntegracao === 'S'}
                                                onChange={e => {
                                                    alterarDados({...dados, liberarIntegracao: e.target.checked ? 'S' : 'N'});
                                                }}
                                            />
                                        </div>
                                        <Button
                                            size="sm"
                                            variant={'light'}
                                            className={"shadow-sm w-100"}
                                            onClick={() => {

                                                // vai para a aba de integração
                                                alterarTab(`integracao`);
                                            }}
                                        >
                                            Abrir integrações
                                        </Button>
                                        {/* <ComboIntegracoesCriar
                                            className="select-ficha" 
                                            id={dados.idEmpresaIntegracao ? dados.idEmpresaIntegracao : null}
                                            alterou={(id) => {
                                                alterarDados({...dados, idEmpresaIntegracao: id, pagina: 1});
                                            }}
                                            parametrosPassados={{
                                                pagina: null,
                                                registrosPorPagina: null
                                            }}
                                        /> */}
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md='5'>
                    <Card className='border-0 shadow-sm'>
                        <Card.Body>
                            <Form.Row className='mb-2'>
                                <Col md='7'>
                                    {/* servidor alugado */}
                                    <Form.Group className='mb-0'>
                                        <div className={'d-flex justify-content-between'}>
                                            <Form.Label>Servidor de aluguel</Form.Label>
                                            <Form.Check
                                                id="switch-servidor-proprio"
                                                label="Próprio"
                                                checked={dados.servidorProprio === 'S'}
                                                onChange={e => {
                                                    alterarDados({...dados, servidorProprio: e.target.checked ? 'S' : 'N'});
                                                }}
                                            />
                                        </div>
                                        <ComboServidoresCriar 
                                            className="select-ficha"
                                            id={dados.idServidorAluguel || null}
                                            disabled={dados.servidorProprio === 'S'}
                                            alterou={(id, dadosServidor) => {
                                                alterarDados({...dados, idServidorAluguel: id, pagina: 1});
                                                alterarServidorInstalar(dadosServidor);
                                            }}
                                            config={{
                                                pagina: null,
                                                registrosPorPagina: null
                                            }}
                                            carregado={(dadosServidor) => {
                                                alterarServidorInstalar(dadosServidor);
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <div className={'d-flex justify-content-between'}>
                                        <Form.Label>Senha</Form.Label>
                                    </div>
                                    <InputGroup className="mb-0">
                                        <Form.Control
                                            size="sm" 
                                            disabled
                                            ref={camposRef.servidor}
                                            value={(dados.servidor === '2') ? `${dados.codigo}@G30GR1D2015` : `${dados.codigo}geogrid2015`}
                                        />
                                        <InputGroup.Append>
                                            <Button
                                                size="sm" 
                                                className={[stylesGeral.btnFormularios, ''].join(' ')}
                                                variant="outline-secondary"
                                                onClick={() => {
                                                    
                                                    // copia campo
                                                    copiaCampo(camposRef.servidor.current);
                                                    
                                                }}
                                            >
                                                <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "copy"]}/>
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Form.Row>
                            <Form.Row className='mb-2'>
                                <Col>
                                    {
                                        dados.enderecoServidorProprioAcesso && <>

                                            <div
                                                className={[styles.infoServidorProprioRedirecionado].join(' ')}
                                            >
                                                Servidor Próprio (Cavalo de Troia)
                                            </div>

                                        </>
                                    }
                                </Col>
                            </Form.Row>
                            <Form.Row className='mb-2'>
                                <Col>
                                    <div className={'d-flex justify-content-between'}>
                                        <Form.Label>IP servidor (autenticação)</Form.Label>
                                        <Form.Check
                                            id="switch-mostrar-ips-servidor-provisorio"
                                            type="switch"
                                            label="Mostrar provisórios"
                                            checked={mostrarIpServidorProvisorio}
                                            onChange={e => {
                                                alterarMostrarIpServidorProvisorio(e.target.checked);
                                            }}
                                        />
                                    </div>
                                    <InputGroup>
                                        <Form.Control
                                            size="sm" 
                                            value={dados.ipServidor || ''}
                                            ref={camposRef.ipServidor}
                                            onChange={e => {
                                                alterarDados({...dados, ipServidor: e.target.value});
                                            }}
                                        />
                                        <InputGroup.Append>
                                            <Button 
                                                size="sm"
                                                className={[stylesGeral.btnFormularios, ''].join(' ')}
                                                variant="outline-secondary"
                                                onClick={() => {
                                                    
                                                    // copia campo
                                                    copiaCampo(camposRef.ipServidor.current);

                                                }}
                                            >
                                                <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "copy"]}/>
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Form.Row>
                            {mostrarIpServidorProvisorio && <Form.Row className='mb-2'>
                                <Col>
                                    <Form.Label>IPs servidor (provisório - autenticação)</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            size="sm" 
                                            value={dados.ipServidorProvisorio || ''}
                                            ref={camposRef.ipServidorProvisorio}
                                            onChange={e => {
                                                alterarDados({...dados, ipServidorProvisorio: e.target.value});
                                            }}
                                        />
                                    </InputGroup>
                                </Col>
                            </Form.Row>}
                            <Form.Row className='mb-2'>
                                <Col>
                                    <Form.Label>Endereço de acesso - Amazon</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            size="sm" 
                                            ref={camposRef.enderecoServidor}
                                            value={dados.enderecoServidor || ''}
                                            onChange={e => {
                                                alterarDados({...dados, enderecoServidor: e.target.value});
                                            }}
                                        />
                                        <InputGroup.Append>
                                            {dadosUsuarioMaster && <OverlayTrigger 
                                                placement='top'
                                                overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    Entrar como admin
                                                </Tooltip>
                                                }
                                            >
                                                <Button
                                                    size="sm" 
                                                    className={[stylesGeral.btnFormularios, ''].join(' ')}
                                                    variant="outline-secondary"
                                                    onClick={() => {
                                                        clicouEntrarAdmin()
                                                    }}
                                                >
                                                    <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={faUserTie}/>
                                                </Button>
                                            </OverlayTrigger>}
                                            <Button
                                                size="sm" 
                                                className={[stylesGeral.btnFormularios, ''].join(' ')}
                                                variant="outline-secondary"
                                                onClick={() => {

                                                    // copia campo
                                                    copiaCampo(camposRef.enderecoServidor.current);

                                                }}
                                            >
                                                <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "copy"]}/>
                                            </Button>
                                            <Button
                                                size="sm" 
                                                className={[stylesGeral.btnFormularios, ''].join(' ')}
                                                variant="outline-secondary"
                                                onClick={() => {
                                                    // vai para o endereco
                                                    let href = camposRef.enderecoServidor.current.value;
                                                    
                                                    // verifica o link
                                                    if(href.trim() !== ''){
                                                        // verifica se o endereço possui o http://
                                                        if(href.indexOf("http") === -1){
                                                            href = `http://${href}`;
                                                        }

                                                        window.open(href, "_blank") // abre em nova guia
                                                    }
                                                }}
                                            >
                                                <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "link"]}/>
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                                <Col md={4}>
                                    <Form.Label>Pasta</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            size="sm" 
                                            ref={camposRef.pasta}
                                            value={dados.pasta || ''}
                                            onChange={e => {
                                                alterarDados({...dados, pasta: e.target.value});
                                            }}
                                        />
                                        <InputGroup.Append>
                                            <Button
                                                size="sm" 
                                                className={[stylesGeral.btnFormularios, ''].join(' ')}
                                                variant="outline-secondary"
                                                onClick={() => {

                                                    // copia campo
                                                    copiaCampo(camposRef.pasta.current);

                                                }}
                                            >
                                                <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "copy"]}/>
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    <Form.Label className="w-100">
                                        <div className={'w-100 d-flex justify-content-between'}>
                                            Banco de dados
                                            {
                                                dados.idBanco && testandoConexaoBanco &&
                                                <>
                                                    <div
                                                        style={{
                                                            width: '25px',
                                                            top:'4px',
                                                            position: 'relative',
                                                            marginRight: '3px',
                                                        }}
                                                        className='d-flex justify-content-center'
                                                    >
                                                        <Spinner 
                                                            animation="border"
                                                            size='sm' 
                                                            variant="secondary"
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </Form.Label>
                                    <ComboBancosDados
                                        className="select-ficha"
                                        id={dados.idBanco}
                                        alterou={idBanco => {
                                            alterarDados({...dados, idBanco: (idBanco !== '') ? idBanco : null});
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Senha <small>(banco)</small></Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            size="sm" 
                                            ref={camposRef.senhaBanco}
                                            value={dados.senhaBanco || ''}
                                            onChange={e => {
                                                alterarDados({...dados, senhaBanco: e.target.value});
                                            }}
                                        />
                                        <InputGroup.Append>
                                            <Button
                                                size="sm" 
                                                className={[stylesGeral.btnFormularios, ''].join(' ')}
                                                variant="outline-secondary"
                                                onClick={() => {

                                                    // copia campo
                                                    copiaCampo(camposRef.senhaBanco.current);

                                                }}
                                            >
                                                <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "copy"]}/>
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Form.Row>
                            <Form.Row className='mb-2'>
                                <Col>
                                    <Form.Label>Endereço de acesso (próprio - fake)</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            size="sm" 
                                            ref={camposRef.enderecoServidorProprioAcesso}
                                            value={dados.enderecoServidorProprioAcesso || ''}
                                            onChange={e => {
                                                alterarDados({...dados, enderecoServidorProprioAcesso: e.target.value});
                                            }}
                                        />
                                        <InputGroup.Append>
                                            <Button
                                                size="sm" 
                                                className={[stylesGeral.btnFormularios, ''].join(' ')}
                                                variant="outline-secondary"
                                                onClick={() => {

                                                    // copia campo
                                                    copiaCampo(camposRef.enderecoServidorProprioAcesso.current);

                                                }}
                                            >
                                                <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "copy"]}/>
                                            </Button>
                                            <Button
                                                size="sm" 
                                                className={[stylesGeral.btnFormularios, ''].join(' ')}
                                                variant="outline-secondary"
                                                onClick={() => {
                                                    // vai para o endereco
                                                    let href = camposRef.enderecoServidorProprioAcesso.current.value;
                                                    
                                                    // verifica o link
                                                    if(href.trim() !== ''){
                                                        // verifica se o endereço possui o http://
                                                        if(href.indexOf("http") === -1){
                                                            href = `http://${href}`;
                                                        }

                                                        window.open(href, "_blank") // abre em nova guia
                                                    }
                                                }}
                                            >
                                                <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "link"]}/>
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Form.Row>
                            <Form.Row className='mb-2'>
                                <Col>
                                    <div className={'d-flex justify-content-between'}>
                                        <Form.Label>Token Google (geogrid)</Form.Label>
                                    </div>
                                    <ComboGruposChaveGoogle
                                        className="select-ficha"
                                        id={dados.idGrupoChaveGoogle}
                                        alterou={idGrupoChaveGoogle => {
                                            alterarDados({...dados, idGrupoChaveGoogle: (idGrupoChaveGoogle !== '') ? idGrupoChaveGoogle : null});
                                        }}
                                    />

                                    {/* <Form.Control
                                        size="sm"
                                        value={dados.tokenGoogleGeogrid || ''}
                                        onChange={e => {
                                            alterarDados({...dados, tokenGoogleGeogrid: e.target.value.trim() !== '' ? e.target.value : null});
                                        }}
                                    /> */}
                                </Col>
                                <Col>
                                    <div className={'d-flex justify-content-between'}>
                                        <Form.Label>
                                            Token (cliente)
                                        </Form.Label>
                                        <Form.Check
                                            id="switch-usar-token-google-cliente"
                                            label="Desktop"
                                            checked={dados.usarTokenGoogleCliente === 'S'}
                                            onChange={e => {
                                                alterarDados({...dados, usarTokenGoogleCliente: e.target.checked ? 'S' : 'N'});
                                            }}
                                        />
                                    </div>
                                    <Form.Control
                                        size="sm"
                                        value={dados.tokenGoogleCliente || ''}
                                        onChange={e => {
                                            alterarDados({...dados, tokenGoogleCliente: e.target.value.trim() !== '' ? e.target.value : null});
                                        }}
                                    />
                                </Col>
                                
                            </Form.Row>

                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip>
                                        Caso tenha um backup, você pode utilizar a instalação diretamente de um arquivo de backup pela guia
                                        de Backups
                                    </Tooltip>
                                }
                            >
                                <Button
                                    size="sm"
                                    variant={'light'}
                                    className={"shadow-sm w-100"}
                                    onClick={() => {

                                        // mostra o modal de instalar
                                        alterarMostrarInstalar(true);
                                    }}
                                >
                                    Instalar base
                                    <FontAwesomeIcon
                                        className='ml-2'
                                        icon={faShare}
                                        color="gray"
                                    />
                                </Button>
                            </OverlayTrigger>

                            {/* {(servidorInstalar !== null && intoVersaoAtual !== null) && 
                                <Card>
                                    <Card.Header 
                                        className={['mb-0 border-0 d-flex align-items-center justify-content-between'].join(' ')}
                                    >
                                        <span>Instalação de base</span>
                                        <Button onClick={iniciarInstalacaoServidor}>Iniciar</Button>
                                    </Card.Header>
                                    <Card.Body>
                                        <div>Servidor: https://{servidorInstalar.host}</div>
                                        <div>Pasta: {(dados.enderecoServidor || '').split("/").pop()}</div>
                                        <div>Usuário banco: {(dados.enderecoServidor || '').split("/").pop()}</div>
                                        <div>Senha banco: {dados.codigo}geogrid2015</div>
                                        <div>nome banco: {(dados.enderecoServidor || '').split("/").pop()}_geogrid</div>
                                        <div>Versão: {intoVersaoAtual.versao}</div>
                                    </Card.Body>
                                </Card>
                            } */}


                        </Card.Body>
                    </Card>
                    
                    {permissaoReducer.permitirControleBaseClientes === 'S' && <Card className='border-0 shadow-sm mt-2'>
                        <Card.Header
                            className='mb-0 border-0 bg-white borda-alerta d-flex justify-content-between'
                        >
                            <Card.Title 
                                className={['pb-0 mb-0'].join(' ')}
                            >
                                <FontAwesomeIcon icon={faWarning} className="mr-2 text-warning" />
                                <span>Controle dados/base</span>
                            </Card.Title>
                            <div className={'d-flex'}>
                            
                                <OverlayTrigger 
                                    overlay={<Tooltip>
                                        Deletar base
                                    </Tooltip>}
                                    placement='bottom'
                                >
                                    <Button
                                        className="mr-2 position-relative"
                                        variant={'danger'}
                                        size='sm'
                                        onClick={() => {
                                            
                                            // abre o modal
                                            alterarMostrarDeletarBase(true);
                                        }}
                                    >
                                        <FontAwesomeIcon className="icone" icon={faServer} color="#e7e7e7" />
                                        <FontAwesomeIcon className={["icone", styles.iconeVassoura].join(' ')} icon={faSkullCrossbones} fontSize={11} />
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger 
                                    overlay={<Tooltip>
                                        Limpar dados da base
                                    </Tooltip>}
                                    placement='bottom'
                                >
                                    <Button
                                        className="mr-2 position-relative"
                                        variant={'secondary'}
                                        size='sm'
                                        onClick={() => {
                                            
                                            // mostra a base
                                            alterarMostrarLimparBase(true);

                                            // confirma
                                            alterarConfirmar({
                                                aberto: true,
                                                backdrop: true,
                                                titulo: 'Limpar banco de dados',
                                                texto: (<div className="text-center">
                                                    <b>
                                                        <FontAwesomeIcon className="mr-1" icon={faExclamationTriangle} />
                                                        Essa ação é irreversível e deleta todos os registros do banco de dados com o ID da empresa.
                                                    </b><br/><br/>
                                                    <b>Por favor, faça um backup e o download do mesmo antes de limpar os dados.</b><br/>
                                                    <small>Backups serão mantidos por garantia.</small><br/><br/>
                                                    <small>
                                                        Também realize um backup dos arquivos na nuvem. <small><b>Todos os arquivos (que não de backup) serão excluídos da nuvem.</b></small>
                                                    </small>
                                                </div>),
                                                textoBotaoConfirmar: 'Ok, entendi',
                                                mostrarBotaoCancelar: false,
                                                variantConfirmar: 'info',
                                                aoConfirmar: () => {

                                                },
                                                aoCancelar: () => {
                                                    // ao cancelar
                                                    
                                                }
                                            });
                                        }}
                                    >
                                        <FontAwesomeIcon className="icone" icon={faDatabase} color="#e7e7e7" />
                                        <FontAwesomeIcon className={["icone", styles.iconeVassoura].join(' ')} icon={faTrash} fontSize={11} />
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        </Card.Header>
                    </Card>}           
                </Col>
            </Row>
        </div>

        <div>
            <Card className='border-0 shadow-sm'>
                <Card.Header 
                    className='mb-0 border-0 bg-white'
                >
                    <Card.Title>
                        <span className="mr-2">Observações Servidor</span> 
                        <FontAwesomeIcon 
                            className={styles.editarObservacao}
                            icon={editarObservacao ? faEye : faPencilAlt} 
                            onClick={() => {
                                alterarEditarObservacao(!editarObservacao);
                            }}
                        />
                    </Card.Title>
                </Card.Header>
                <Card.Body className='pt-1'>
                    <>
                        <Row className="m-1 my-2">
                            {editarObservacao ?
                                <TextareaAutosize 
                                    style={{fontSize: 14}}
                                    value={dados.obsServidor || ''}
                                    onChange={e => {
                                        alterarDados({...dados, obsServidor: e.target.value});
                                        alterarMarkdown(dados.obsServidor || '');
                                    }}
                                    className="form-control" 
                                />
                                :
                                <div className="bg-light rounded p-3 w-100">
                                    <ReactMarkdown
                                        children={markdown}
                                        escapeHtml={false}
                                        className={[styles.markdown, 'markdown'].join(' ')}
                                    />
                                </div>
                            }
                        </Row>
                    </>
                </Card.Body>
            </Card>
        </div>
        
        <Row>
            <Col md='12'>
                <SalvarFormulario 
                    salvando={salvando}
                    modo={modo}
                    cancelar={() => {
                        history.goBack();
                    }}
                    onClick={async () => {

                        // executa o salvar
                        await funcaoSalvar(dados);
                        
                        // verifica se alterou dados do servidor
                        if(
                            [
                                'idBanco', 'pasta', 'senhaBanco'
                            ].some((campo) => dadosIniciais[campo] !== dados[campo])
                        ){

                            // confere novamente a conexão
                            testarConexaoBancoDados(true);
                        }
                    }}
                />
            </Col>
        </Row>
    </>
}

function SalvarFormulario({
    salvando,
    cancelar,
    modo,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button 
            variant="success" 
            disabled={salvando} 
            onClick={onClick}
        >
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <>
                    <FontAwesomeIcon className="icone" icon={["fas", 'check']} />
                <span>{modo === 'edicao' ? 'Salvar' : 'Cadastrar'}</span>
                </>
            }
        </Button>
    </Form.Group>
}