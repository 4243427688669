import { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';

// componentes
import Select from 'react-select';
import useIsMounted from 'ismounted';
import { Modal, Container, Card, Row, Col, Form, Button, InputGroup, Spinner, Alert, OverlayTrigger, Tooltip, Badge} from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faRandom } from '@fortawesome/free-solid-svg-icons';

import { intersectObject, copiaCampo } from './../../../../Funcoes';
import { FichaComponent } from './components';

// contexto
import { PainelContexto } from './../../../PainelContexto';

// faz o cadastro
export default function ModalEditar({
    
}){

    // dados
    const isMounted = useIsMounted();
    const { path, url } = useRouteMatch();
    const painelContextoDados = useContext(PainelContexto);
    const history = useHistory();

    // parametros da url
    const {
        id = null
    } = useParams();

    // prepara
    const dadosPadrao = {
        id: null,
        chave: null,
        descricao: null,
        tipo: null,
        habilitado: null
    }
    
    // estados
    const [ dadosIniciais, alterarDadosIniciais ] = useState({});
    const [ dados, alterarDados ] = useState({...dadosPadrao});
    const [ mostrar, alterarMostrar ] = useState(true);
    const [ salvando, alterarSalvando ] = useState(false);
    const [ carregando, alterarCarregando ] = useState(false);
    const [ erros, alterarErros ] = useState({});

    // prepara
    const tipos = [
        {value: 'geogridMapa', label: 'GeoGrid / Mapa'},
        {value: 'pesquisaEndereco', label: 'Pesquisa de endereço'}
    ];

    const camposRef = {
        descricao: useRef(),
        chave: useRef()
    }

    // inicializa
    useEffect(() => {
        
        // verifica se enviado o id
        if(id){

            // consulta
            consultarId();
        }
    }, []);

    // consulta dados do usuário
    async function consultarId(){

        // cria o carregamento
        alterarCarregando(true);

        try{
            
            // faz a requisição
            let { data } = await axios.get(`/googleChaves/${id}`);
            
            // define os dados
            alterarDadosIniciais({...data.dados});
            alterarDados({...data.dados});

        }catch(e){
            console.log('pegou o erro')
        }finally{
            if (isMounted.current) {
                alterarCarregando(false);
            }
        }

    }

    // finaliza
    async function salvar(){

        // salva registro
        alterarSalvando(true);
        alterarErros({});

        // faz a requisição
        try{

            // pega dados
            let dadosSalvar = intersectObject(dadosPadrao, dados);

            // faz a requisição
            let { data } = await axios.post(`/googleChaves`, {
                dados: dadosSalvar
            });

            // mensagem de cadastro realizado com sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Integração editada!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    {dadosIniciais.descricao}
                </div>
            </>);

            // // vai para a consulta de sms's enviados (por enquanto volta para a consulta de clientes)
            // history.push('/painel/caixa-saida');
            
            // altera
            alterarSalvando(false);
            alterarMostrar(false);
            
            // monta a url para retroceder
            let urlBack = url.split("/googleChaves").map((parte, i) => i > 0 ? '/googleChaves' : parte).join('');
            
            // volta e dá refresh pra atualizar a listagem
            history.push(urlBack, {refresh: true});

        }catch({response}){

            // define erros retornados
            alterarErros(response.data);

        }finally{
            if (isMounted.current) {
                alterarSalvando(false);
            }
        }
    }

    // return <>
    //     Aqui retorna
    // </>
    return <Modal 
        show={mostrar}
        centered
        onExited={() => {

            // monta a url para retroceder
            let urlBack = url.split("/googleChaves").map((parte, i) => i > 0 ? '/googleChaves' : parte).join('');
            
            // volta e dá refresh pra atualizar a listagem
            history.push(urlBack, {
                aPartirDe: 'editarGoogleChave'
            });
            
            // fecha
            alterarMostrar(false);
        }}
        onHide={() => {

            // fecha
            alterarMostrar(false);
        }}
        size={'md'}
    >
        <Modal.Header className='border-0'>
            <Modal.Title 
                as='h5' 
                className={[
                    'text-center w-100',
                    'text-success'
                ].join(' ')}
            >
                {dadosIniciais.descricao && <>{dadosIniciais.descricao} ({dados.id})</>}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'pt-0'}>
            {
                carregando ?
                <Col align="center">
                    <Spinner animation="border" />
                </Col>
                : <FichaComponent>
                    <Container>
                        <Form.Row>
                            <Col>
                                <Form.Label>Tipo</Form.Label>
                                <CreatableSelect
                                    className="select-ficha"
                                    options={tipos}
                                    value={tipos.filter((e) => dados.tipo === e.value)[0]}
                                    isMulti={false}
                                    placeholder='Tipo'
                                    onChange={(e) => {
                                        // altera os filtros selecionados
                                        alterarDados({...dados, tipo: e.value});
                                    }}
                                />
                                {(erros.tipo) && 
                                    <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.tipo}</p></Alert>
                                }
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control 
                                        ref={camposRef.descricao}
                                        value={dados.descricao || ''}
                                        onChange={e => {
                                            alterarDados({...dados, descricao: e.target.value});
                                        }}
                                    />

                                    {(erros.descricao) && 
                                        <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.descricao}</p></Alert>
                                    }

                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Chave</Form.Label>
                                    <InputGroup>
                                        <Form.Control 
                                            ref={camposRef.chave}
                                            value={dados.chave || ''}
                                            onChange={e => {
                                                alterarDados({...dados, chave: e.target.value});
                                            }}
                                        />
                                        <InputGroup.Append>
                                            <Button 
                                                size="sm"
                                                className={[].join(' ')}
                                                variant="outline-secondary"
                                                onClick={() => {
                                
                                                    // pega o campo
                                                    let campo = camposRef.chave.current
                                                    copiaCampo(campo);
                                
                                                }}
                                            >
                                                <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "copy"]}/>
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>

                                    {(erros.chave) && 
                                        <Alert className={['alerta', 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.chave}</p></Alert>
                                    }

                                </Form.Group>
                            </Col>
                        </Form.Row>
                        
                        <Form.Row className={['mt-2']}>
                            <Col>
                                <Form.Group>
                                    <Form.Check 
                                        type="switch"
                                        id="custom-switch-habilitado"
                                        label={<div className='d-flex align-items-center' style={{gap: '0.5em'}}>
                                            Habilitado
                                            <OverlayTrigger
                                                placement='right'
                                                overlay={
                                                    <Tooltip>
                                                        Chaves habilitadas ficam disponíveis para uso nas funções (de acordo com o tipo).
                                                    </Tooltip>
                                                }
                                            >
                                                <FontAwesomeIcon color="#535353" icon={faInfoCircle}/>
                                            </OverlayTrigger>
                                        </div>}
                                        checked={dados.habilitado === 'S'}
                                        onChange={e => {

                                            // pega o novo valor
                                            let habilitado = e.target.checked ? 'S' : 'N';
        
                                            // altera dados
                                            alterarDados({...dados, habilitado});
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </Container>
                </FichaComponent>
            }
        </Modal.Body>
        <Modal.Footer className='border-0'>
            <Button
                variant='danger'
                onClick={() => {alterarMostrar(false)}}
                disabled={salvando}
            >
                <span>Fechar</span>
            </Button>
            <Button
                onClick={salvar}
                variant='success'
                disabled={salvando}
            >
                {salvando ? 
                    <>
                        <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                        <span>Salvando</span>
                    </> : 
                    <span>Salvar</span>
                }
            </Button>
        </Modal.Footer>
    </Modal>
}   