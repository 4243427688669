import moment from "moment";
import { Badge, Card } from "react-bootstrap";
import { isBrowser, isMobile } from "react-device-detect";
import { faFile, faFolderTree, faTimes } from "@fortawesome/free-solid-svg-icons";

import BotaoTabela from "../../../Componentes/BotaoTabela";

export default function Registro({
    dados,
    funcionario,
    usuario,
    dataAnterior,
    mostrarAbrir,
    clicouAbrir,
    possuiFilhos,
    clicouAbrirFilhos
}){

    // retorna opções
    let retornarOpcoes = () => {

        // retorna componentes
        return <>
            {possuiFilhos && <BotaoTabela 
                onClick={clicouAbrirFilhos}
                icone={faFolderTree}
            >
                <span>Abrir filhos</span>
            </BotaoTabela>}
            {mostrarAbrir && <BotaoTabela 
                onClick={clicouAbrir}
                icone={faFile}
            >
                <span>Abrir</span>
            </BotaoTabela>}
        </>
    }

    return <> 

        {
            (
                !dataAnterior ||
                moment(dataAnterior).format('DD/MM/YYYY') !== moment(dados.dataCadastro).format('DD/MM/YYYY')
            ) && <tr className="registro registro-divisor-data">
                <td className="divisor-data estreito">{moment(dados.dataCadastro).format('DD/MM/YYYY')}</td>
            </tr> 
        }

        <tr className={['registro', dados.evento].join(' ')}>

            <td className={'estreito text-center font-weight-bold integracao'}>
                {moment(dados.dataCadastro).format('HH:mm:ss')}
            </td>

            <td className="descricao">
                <div>
                    {function(){
                        let descricao = ''
                        switch(dados.evento){
                            case 'cadastrar':
                                descricao = 'Cadastrado';
                                break;
                            case 'alterar':
                                descricao = 'Alterado';
                                break;
                            case 'deletar':
                                descricao = 'Deletado';
                                break;
                        }
                        return descricao ? <b>{descricao}</b> : '';
                    }()} {dados.descricao}
                </div>
                <div className="informacoes">
                    <small className="total geral">
                        <b>Origem: </b> {dados.origem.charAt(0).toUpperCase()}{dados.origem.slice(1)}
                    </small>
                    {funcionario && <small className="total geral">
                    {' | '}<b>Funcionário: </b> {funcionario.nome}
                    </small>}
                    {usuario && <small className="total geral">
                    {' | '}<b>Usuário: </b> {usuario.nome}
                    </small>}
                </div>
            </td>

            <td className={'text-nowrap estreito text-right'}>
                { retornarOpcoes() }
            </td>
        </tr>
    </>
}