import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const RelatoriosEmpresasAcessosComponent = styled.div.attrs({
    className: ``
})`
    flex-grow: 1;
    position: relative;
    padding: 0.5em 0;

    .card-totais-gerais{
        
        .total-grupo{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #828282;
            font-size: 0.9em;
            padding-bottom: 0.5em;
            border-bottom: 1px solid #f0f0f0;
            
            &:last-child{
                border-bottom: 0;
            }

            .total-geral{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: #828282;
                /* font-size: 0.9em; */
                padding: 0.5em 0;
                width: 80%;
                white-space: nowrap;

                .destaque-total{
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                }

                .total{
                    font-size: 2em;
                    line-height: 0.9em;
                    font-weight: 600;

                    &.geral{
                        color: #3aa872;
                    }

                    &.login{
                        color: #90d90b;
                    }

                    &.refresh{
                        color: #f3910d;
                    }

                    &.new-tab{
                        color: #418273;
                    }

                }

            }

            .sub-total{
                display: flex;
                align-items: center;
                font-size: 0.8em;
                width: 100%;
                justify-content: space-around;
                border-top: 1px solid #f9f9f9;
                margin: 0.2em 1em;
                margin-bottom: 0;
                padding: 0.3em;

                .total-geral{
                    border: 0;
                }
            }
        }

    }

    .carregamento{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        border-radius: 0.3em;
        background: #eeeeee8f;
        backdrop-filter: blur(3px);
        gap: 0.5em;
    }   

    .grafico-content{
        /* background: #fafafa; */
    }
`;