import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const IntegracaoSistemasComponent = styled(Card).attrs({
    className: `painel-consulta border-0`
})`

    .tabs{
        font-size: 14px;

        a{
            color: #5a5a5a;
            padding: 2px 14px;
            background: none !important;
            border: none !important;
            outline: none;

            &[aria-selected=true]{
                border-bottom: 1px solid #34dc34 !important;
                margin-bottom: -1px !important;
            }
            
            &:hover{
                color: #4b4a4a;
            }
        }

    }

    .tabela{

        thead{

            tr{

                th{
                    background: #f2f2f2;
                }
            }
        }
    }
    
`;