import styled from 'styled-components';
import { Card, Modal } from 'react-bootstrap';
import { ListaComponent } from './../components';

export const ListaUltimosAcessosComponent = styled(ListaComponent).attrs({
    className: `painel-consulta shadow-sm`
})`
    height: 100%;
    display: flex;
    flex-direction: column;

    .card-body{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
    
    .tabela{
        
        tbody{

            &:empty{
                position: relative;
                min-height: 260px;
            }
        }
        
        .registro{
            --cor-status-registro: black;
                
            small{
                line-height: 1.25em;
            }

            &.tipo-login{
                --cor-status-registro: #90d90b;
            }

            &.tipo-refresh{
                --cor-status-registro: #f3910d;
            }

            &.tipo-newTab{
                --cor-status-registro: #418273;
            }

            &.tipo-logout{
                --cor-status-registro: #d24b4c;
            }

            &.tipo-derrubado{
                --cor-status-registro: #d9951b;
            }

            &:not(.registro-divisor-data){

                td{
                    
                    &:first-child{
                        color: var(--cor-status-registro);
                        font-size: 0.8em;
                        border-left: 3px solid var(--cor-status-registro);
                    }
                }
            }
        }
    }

    .legenda{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5em;
        font-size: 0.8em;
        margin: 0.7em;

        div{
            display: flex;
            padding: 0 0.4em;
            border-radius: 0.3em;
            border-left: 3px solid;
            background: #fafafa;
            font-weight: 400;
            color: #434343;

            &.login{
                border-color: #90d90b;
            }

            &.refresh{
                border-color: #f3910d;
            }

            &.new-tab{
                border-color: #418273;
            }

            &.logout{
                border-color: #d24b4c;
            }

            &.derrubado{
                border-color: #d9951b;
            }
        }
    }

    .legenda div, .registro .total{
        display: flex;
        align-items: center;
        padding: 0 0.4em;
        border-radius: 0.3em;
        border-left: 3px solid;
        background: #fafafa;
        font-weight: 400;
        color: #434343;
        gap: 0.3em;

        &.geral{
            border-color: #3aa872;
        }

        &.login{
            border-color: #90d90b;
        }

        &.refresh{
            border-color: #f3910d;
        }

        &.new-tab{
            border-color: #418273;
        }
    }

    .carregamento{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3em;
        gap: 0.5em;
    }
`;