import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';

// componentes
import OptionSelect from './OptionSelect';

// combo para consulta de versões
export default function ComboVersao({
    id=null,
    alterou,
    defineUltima=false,
    className='',
    styles=null
}){

    // estados
    let [consulta, alterarConsulta] = useState([]);
    let [dadosRegistro, alterarDadosRegistro] = useState(null);
    let [carregando, alterarCarregando] = useState(true);
    let [definidoUltima, alterarDefinidoUltima] = useState(false);

    // consultar versões
    async function consultarRegistros(inputValue, callback = null){

        try{

            // faz a requisição
            let { data } = await axios.get(`/controleVersao`, { params: { 
                texto: inputValue,
                pagina: 1,
                registrosPorPagina: 20
            }});

            // altera os dados
            alterarConsulta([...data.registros]);
            if(callback !== null){
                callback(data.registros);
            }

            // define a última
            if(defineUltima && !definidoUltima && id === null){

                // pega o primeiro registro
                alterarDadosRegistro({...data.registros[0]});
                alterou(data.registros[0].dados.id);
                alterarDefinidoUltima(true);
            }

        }catch({response}){}

    }

    // consulta membro
    async function consultarId(){
        alterarCarregando(true);
        
        try{
            
            // faz a consulta do id
            let { data } = await axios.get(`/controleVersao/${id}`);
            alterarDadosRegistro(data);

        }catch({response}){

        }finally{
            alterarCarregando(false);
        }
    }

    // pre carrega algumas versões disponiveis
    useEffect(() => {
        consultarRegistros('');

        // verifica se possui usuário
        if(id !== null){

            // consulta os dados do membro selecionado
            consultarId();

        }else{
            alterarCarregando(false);
        }


    }, []);

    // ao alterar versões, retorna ids
    useEffect(() => {

    });

    return <AsyncSelect 
        isClearable
        loadOptions={consultarRegistros}
        defaultOptions={consulta}
        components={{
            Option: OptionSelect
        }}
        value={dadosRegistro && {
            label: dadosRegistro.dados.versao
        }}
        onChange={(e) => {
            
            if(e === null){
                alterarDadosRegistro(null);
                alterou(null);
            }else{
                
                alterarDadosRegistro({...e});
                alterou(e.dados.id);
            }
        }}
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => 'Digite algo para fazer a consulta'}
        placeholder='Versões'
        styles={styles ? styles : {}}
        className={className}
    />
    
}
