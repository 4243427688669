import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import stylesGeral from './../index.module.scss';
import axios from 'axios';

// componentes
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { Card, Row, Col, Form, Button, InputGroup, Tabs, Tab} from 'react-bootstrap';
import InputDataCombo from './../../../../Componentes/InputDataCombo';

import {copiaCampo} from './../../../../Funcoes';

import FormularioPadrao from './../FormularioPadrao';
import ListaPedidosTesteHistorico from '../ListaPedidosTesteHistorico';

// retorna conteudo
export default function FormularioFicha({
    dados={},
    alterarDados,
    funcaoSalvar,
    salvando,
    modo
}){

    const history = useHistory();
    const permissaoReducer = useSelector(state => state.permissaoReducer);

    const fichaLiberada = permissaoReducer.edicaoClientes === 'S';
    
    // estados
    const [adicionalAberto, alterarMostrarAdicionais] = useState('obs');

    // campos para referencia
    const camposRef = {
        razaoSocial: React.useRef(),
    }
    
    // array para o select de modo
    const modosCliente = [
        {value: 'cliente', label: 'Cliente'},
        {value: 'clientelight', label: 'Clientelight'},
        {value: 'parceiro', label: 'Parceiro'},
        {value: 'demo', label: 'Demo'},
        {value: 'demolight', label: 'Demolight'},
        {value: 'bloqueado', label: 'Bloqueado'},
        {value: 'cancelado', label: 'Cancelado'},
        {value: 'nao-ativo', label: 'Não ativo'}
    ]

    // pega o modo inicial se edição
    const modoInicial = (dados.id) ? modosCliente.filter((e) => dados.modo === e.value) : null
    const [enderecoAberto, alterarMostrarEndereco] = useState(true)

    return <>
        <Row>
            <Col md='8' className='mb-4'>
                <Card className='border-0 shadow-sm'>
                    <Card.Header className='mb-0 border-0 bg-white'>
                        <Card.Title className='pb-0 mb-0'>Ficha</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <FormularioPadrao {...{
                            dados,
                            alterarDados,
                            funcaoSalvar,
                            salvando,
                            modo
                        }}/>
                        <Form.Group>
                            <Form.Row className='mb-2'>
                                <Col md='7'>
                                    <Form.Label>Nome fantasia</Form.Label>
                                    <Form.Control 
                                        size="sm"
                                        disabled={!fichaLiberada}
                                        value={dados.nomeFantasia || ''}
                                        onChange={e => {
                                            alterarDados({...dados, nomeFantasia: e.target.value});
                                        }}
                                    />
                                </Col>
                                <Col md='5'>
                                    <Form.Label>Responsável</Form.Label>
                                    <Form.Control 
                                        size="sm"
                                        disabled={!fichaLiberada}
                                        value={dados.responsavel || ''}
                                        onChange={e => {
                                            alterarDados({...dados, responsavel: e.target.value});
                                        }}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row className='mb-2'>
                                <Col>
                                    <Form.Label>Data pedido</Form.Label>
                                    <InputDataCombo 
                                        size="sm"
                                        data={dados.dataPedidoTeste}
                                        onChange={(novaData) => {
                                            alterarDados({...dados, dataPedidoTeste: novaData});
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Data cliente</Form.Label>
                                    <InputDataCombo 
                                        size="sm"
                                        disabled={!fichaLiberada}
                                        data={(dados.dataCliente && dados.dataCliente !== '0000-00-00') ? dados.dataCliente : ''}
                                        onChange={(novaData) => {
                                            alterarDados({...dados, dataCliente: novaData});
                                        }}
                                    />
                                </Col>
                                <Col>
                                    {
                                        dados.modo !== 'cancelado' ?
                                        <>
                                            <Form.Label>Expira em</Form.Label>
                                            <InputDataCombo 
                                                size="sm"
                                                disabled={!fichaLiberada}
                                                data={(dados.expiraEm && dados.expiraEm !== '0000-00-00') ? dados.expiraEm : ''}
                                                onChange={(novaData) => {
                                                    alterarDados({...dados, expiraEm: novaData});
                                                }}
                                            />
                                        </>
                                            :
                                        <>
                                            <Form.Label>Data cancelado</Form.Label>
                                            <InputDataCombo 
                                                size="sm"
                                                disabled={!fichaLiberada}
                                                data={(dados.dataCancelado && dados.dataCancelado !== '0000-00-00') ? dados.dataCancelado : ''}
                                                onChange={(novaData) => {
                                                    alterarDados({...dados, dataCancelado: novaData});
                                                }}
                                            />
                                        </>
                                    }
                                </Col>
                                <Col>
                                    <Form.Label>Último reajuste</Form.Label>
                                    <InputDataCombo 
                                        size="sm"
                                        disabled={!fichaLiberada}
                                        data={(dados.dataReajuste && dados.dataReajuste !== '0000-00-00') ? dados.dataReajuste : ''}
                                        onChange={(novaData) => {
                                            alterarDados({...dados, dataReajuste: novaData});
                                        }}
                                    /> 
                                </Col>
                            </Form.Row>
                            <Form.Row className='mb-2'>
                                <Col md='7'>
                                    <Form.Label>E-mail</Form.Label>
                                    <Form.Control 
                                        size="sm"
                                        disabled={!fichaLiberada}
                                        value={dados.email || ''}
                                        onChange={e => {
                                            alterarDados({...dados, email: e.target.value});
                                        }}
                                    />
                                </Col>
                                <Col md='5'>
                                    <Form.Label>Telefone</Form.Label>
                                    <Form.Control 
                                        size="sm"
                                        disabled={!fichaLiberada}
                                        value={dados.telefone || ''}
                                        onChange={e => {
                                            alterarDados({...dados, telefone: e.target.value});
                                        }}
                                    />
                                </Col>
                            </Form.Row>
                        </Form.Group>
                        <Card>
                            <Card.Header 
                                className={[styles.expandInfo, 'mb-0 border-0'].join(' ')}
                                onClick={() => alterarMostrarEndereco(!enderecoAberto)}
                            >
                                <span>Localidade</span>
                                <FontAwesomeIcon style={{fontSize: 18, color: '#616161'}} className="icone" icon={enderecoAberto ? ["fas", "angle-up"] : ["fas", "angle-down"]}/>
                            </Card.Header>
                            {
                                enderecoAberto &&
                                <Card.Body>
                                    <Form.Row>
                                        <Col md='8'>
                                            <Form.Label>Endereço</Form.Label>
                                            <Form.Control 
                                                size="sm"
                                                disabled={!fichaLiberada}
                                                value={dados.endereco || ''}
                                                onChange={e => {
                                                    alterarDados({...dados, endereco: e.target.value});
                                                }}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label>Cep</Form.Label>
                                            <Form.Control 
                                                size="sm"
                                                disabled={!fichaLiberada}
                                                value={dados.cep || ''}
                                                onChange={e => {
                                                    alterarDados({...dados, cep: e.target.value});
                                                }}
                                            />
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className='mb-2'>
                                        <Col md='6'>
                                            <Form.Label>Complemento</Form.Label>
                                            <Form.Control 
                                                size="sm"
                                                disabled={!fichaLiberada}
                                                value={dados.complemento || ''}
                                                onChange={e => {
                                                    alterarDados({...dados, complemento: e.target.value});
                                                }}
                                            />
                                        </Col>
                                        <Col md='2'>
                                            <Form.Label>Número</Form.Label>
                                            <Form.Control 
                                                size="sm"
                                                disabled={!fichaLiberada}
                                                value={dados.numero || ''}
                                                onChange={e => {
                                                    alterarDados({...dados, numero: e.target.value});
                                                }}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label>Bairro</Form.Label>
                                            <Form.Control 
                                                size="sm"
                                                disabled={!fichaLiberada}
                                                value={dados.bairro || ''}
                                                onChange={e => {
                                                    alterarDados({...dados, bairro: e.target.value});
                                                }}
                                            />
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col md='6'>
                                            <Form.Label>Cidade</Form.Label>
                                            <Form.Control 
                                                size="sm"
                                                disabled={!fichaLiberada}
                                                value={dados.cidade || ''}
                                                onChange={e => {
                                                    alterarDados({...dados, cidade: e.target.value});
                                                }}
                                            />
                                        </Col>
                                        <Col md='2'>
                                            <Form.Label>Estado</Form.Label>
                                            <Form.Control 
                                                size="sm"
                                                disabled={!fichaLiberada}
                                                value={dados.estado || ''}
                                                onChange={e => {
                                                    alterarDados({...dados, estado: e.target.value});
                                                }}
                                            />
                                        </Col>
                                        <Col md='4'>
                                            <Form.Label>País</Form.Label>
                                            <Form.Control 
                                                size="sm"
                                                disabled={!fichaLiberada}
                                                value={dados.pais || ''}
                                                onChange={e => {
                                                    alterarDados({...dados, pais: e.target.value});
                                                }}
                                            />
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col>
                                            <Form.Label>Latitude</Form.Label>
                                            <Form.Control 
                                                size="sm"
                                                disabled={!fichaLiberada}
                                                value={dados.latitude || ''}
                                                onChange={e => {
                                                    alterarDados({...dados, latitude: e.target.value});
                                                }}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label>Longitude</Form.Label>
                                            <Form.Control 
                                                size="sm"
                                                disabled={!fichaLiberada}
                                                value={dados.longitude || ''}
                                                onChange={e => {
                                                    alterarDados({...dados, longitude: e.target.value});
                                                }}
                                            />
                                        </Col>
                                    </Form.Row>
                                </Card.Body>
                            }
                        </Card>
                        <hr/>
                        <Form.Group>
                            <Form.Row className='my-3'>
                                <Col>
                                    <Form.Label>Gerenciadores</Form.Label>
                                    <Form.Control 
                                        size="sm"
                                        disabled={!fichaLiberada}
                                        value={dados.limiteUsuariosGerencia || ''}
                                        onChange={e => {
                                            alterarDados({...dados, limiteUsuariosGerencia: e.target.value});
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Terceirizados</Form.Label>
                                    <Form.Control 
                                        size="sm"
                                        disabled={!fichaLiberada}
                                        value={dados.limiteUsuariosTerceirizado || ''}
                                        onChange={e => {
                                            alterarDados({...dados, limiteUsuariosTerceirizado: e.target.value});
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Projetistas</Form.Label>
                                    <Form.Control 
                                        size="sm"
                                        disabled={!fichaLiberada}
                                        value={dados.numeroDeUsuariosProjetista || ''}
                                        onChange={e => {
                                            alterarDados({...dados, numeroDeUsuariosProjetista: e.target.value});
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Técnicos</Form.Label>
                                    <Form.Control
                                        size="sm" 
                                        disabled={!fichaLiberada}
                                        value={dados.numeroDeUsuariosTecnico || ''}
                                        onChange={e => {
                                            alterarDados({...dados, numeroDeUsuariosTecnico: e.target.value});
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Vendedores</Form.Label>
                                    <Form.Control 
                                        size="sm"
                                        disabled={!fichaLiberada}
                                        value={dados.numeroDeUsuariosVendedor || ''}
                                        onChange={e => {
                                            alterarDados({...dados, numeroDeUsuariosVendedor: e.target.value});
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Viabilidade</Form.Label>
                                    <Form.Control
                                        size="sm" 
                                        disabled={!fichaLiberada}
                                        value={dados.numeroDeUsuariosViabilidade || ''}
                                        onChange={e => {
                                            alterarDados({...dados, numeroDeUsuariosViabilidade: e.target.value});
                                        }}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row className='my-3'>
                                <Col
                                    className='d-flex'
                                    style={{
                                        gap: '0.5em',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Form.Label className='m-0 text-nowrap'>Limite de armazenamento de arquivos</Form.Label>
                                    <InputGroup
                                        style={{
                                            gap: '0.5em',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Form.Control 
                                            size="sm"
                                            style={{ maxWidth: 50 }}
                                            disabled={!fichaLiberada}
                                            value={dados.limiteArmazenamentoArquivos || ''}
                                            onChange={e => {
                                                alterarDados({...dados, limiteArmazenamentoArquivos: e.target.value});
                                            }}
                                        />
                                        <InputGroup.Append>
                                            <Form.Label className='m-0'>GB</Form.Label>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Form.Row>
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Col>
            <Col md='4'>
                <Card className='border-0 shadow-sm'>
                    <Card.Header 
                        className='mb-0 border-0 bg-white'
                        onClick={() => alterarMostrarAdicionais('obs')}
                    >
                        <Card.Title 
                            className={[styles.expandInfo, 'p-0 mb-0'].join(' ')}
                        >
                            Observações
                        </Card.Title>
                    </Card.Header>
                    {
                        adicionalAberto === 'obs' && 
                        <Card.Body className='pt-1'>
                            {permissaoReducer.edicaoClientes === 'S' &&
                                <>
                                    <Row className="m-1 my-2">
                                        <Form.Label>Adesão</Form.Label>
                                        <textarea 
                                            style={{minHeight: 50, fontSize: 14}}
                                            value={dados.infoLicenca || ''}
                                            onChange={e => {
                                                alterarDados({...dados, infoLicenca: e.target.value});
                                            }}
                                            className="form-control" 
                                        ></textarea>
                                    </Row>
                                    <Row className="m-1 my-2">
                                        <Form.Label>Mensalidade</Form.Label>
                                        <textarea 
                                            style={{minHeight: 50, fontSize: 14}}
                                            value={dados.infoMensalidade || ''}
                                            onChange={e => {
                                                alterarDados({...dados, infoMensalidade: e.target.value});
                                            }}
                                            className="form-control" 
                                        ></textarea>
                                    </Row>
                                    <Row className="m-1 my-2">
                                        <Form.Label>Comercial</Form.Label>
                                        <textarea 
                                            style={{minHeight: 100, fontSize: 14}}
                                            value={dados.obsComercial || ''}
                                            onChange={e => {
                                                alterarDados({...dados, obsComercial: e.target.value});
                                            }}
                                            className="form-control" 
                                        ></textarea>
                                    </Row>
                                    <ListaPedidosTesteHistorico
                                        idRazaoSocial={dados.id}
                                    />
                                </>
                            }
                            {permissaoReducer.acessoServidorClientes === 'S' &&
                                <>
                                    <Row className="m-1 my-2">
                                        <Form.Label>Servidor</Form.Label>
                                        <textarea 
                                            style={{minHeight: 200, fontSize: 14}}
                                            value={dados.obsServidor || ''}
                                            onChange={e => {
                                                alterarDados({...dados, obsServidor: e.target.value});
                                            }}
                                            className="form-control" 
                                        ></textarea>
                                    </Row>
                                </>
                            }
                        </Card.Body>
                    }
                        
                </Card>
                {dados.campanha && <Card className='border-0 shadow-sm mt-2'>
                    <Card.Body className='pt-1'>
                        <Row>
                            <Col>
                                <Form.Label className='font-weight-bold'>Campanha</Form.Label>
                                <Form.Control 
                                    size="sm"
                                    disabled={true}
                                    value={dados.campanha || ''}
                                    onChange={e => {
                                        alterarDados({...dados, campanha: e.target.value});
                                    }}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>}
                {dados.evento && <Card className='border-0 shadow-sm mt-2'>
                    <Card.Body className='pt-1'>
                        <Row>
                            <Col>
                                <Form.Label className='font-weight-bold'>Evento</Form.Label>
                                <Form.Control 
                                    size="sm"
                                    disabled={true}
                                    value={dados.evento || ''}
                                    onChange={e => {
                                        alterarDados({...dados, evento: e.target.value});
                                    }}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>}
            </Col>
        </Row>
        <Row>
            <Col md='8'>
                <SalvarFormulario 
                    salvando={salvando}
                    modo={modo}
                    cancelar={() => {
                        history.goBack();
                    }}
                    onClick={() => funcaoSalvar(dados)}
                />
            </Col>
        </Row>
    </>
}

function SalvarFormulario({
    salvando,
    cancelar,
    modo,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button 
            variant="success" 
            disabled={salvando} 
            onClick={onClick}
        >
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <>
                    <FontAwesomeIcon className="icone" icon={["fas", 'check']} />
                <span>{modo === 'edicao' ? 'Salvar' : 'Cadastrar'}</span>
                </>
            }
        </Button>
    </Form.Group>
}