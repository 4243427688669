import { useParams } from 'react-router-dom';

import { Col, Row } from 'react-bootstrap';

import { ListaConsumoComponent } from "./components";

import ListaConsumoPorEmpresas from './ListaConsumoPorEmpresas';
import ListaConsumoPorUsuarios from './ListaConsumoPorUsuarios';

export default function ListaConsumo(){

    // parametros da url
    const {
        // idEmpresa = null
    } = useParams();

    return <ListaConsumoComponent>
        <Row className='d-flex px-2' style={{gap: '0.825em'}}>
            <Col className='p-0'>
                <ListaConsumoPorUsuarios />
            </Col>
            <Col className='p-0'>
                <ListaConsumoPorEmpresas />
            </Col>
        </Row>
    </ListaConsumoComponent>
    
}