import { useEffect, useState, useRef } from "react";
import axios from "axios";
import ReactToPrint from 'react-to-print';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from "@fortawesome/free-solid-svg-icons";

import { ContratoComponent } from './components'

export function Contrato({
    idEmpresa
}){

    // estados
    const [ carregando, alterarCarregando ] = useState(true);
    const [ htmlContrato, alterarHtmlContrato ] = useState('');
    const contratoRef = useRef();

    useEffect(() => {

        // consulta o contrato
        carregarContrato();

    }, []);

    const carregarContrato = async () => {

        alterarCarregando(true);

        try{

            // consulta o contrato da empresa
            let { data } = await axios.get(`/empresas/${idEmpresa}/contrato`);

            // altera o contrato (retornado html)
            alterarHtmlContrato(data);

        }catch(e){
            // erro
        }finally{
            // ao finalizar

            // remove o carregamento
            alterarCarregando(false);
        }
    }

    // verifica se está carregando
    if(carregando){
        return <>Carregando...</>;
    }

    // retorna
    return <ContratoComponent>
        <ReactToPrint
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return <span
                className="imprimir"
            >
                <FontAwesomeIcon color={'#639f08'} icon={faPrint}/>
                Imprimir
            </span>;
          }}
          content={() => contratoRef.current}
        />
        <div
            className="conteudo-contrato"
            dangerouslySetInnerHTML={{__html: htmlContrato}}
            ref={(ref) => contratoRef.current = ref}
        ></div>
    </ContratoComponent>
}