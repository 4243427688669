import React, { useState, useEffect, useContext }  from 'react';

import { useSelector } from 'react-redux';
import { Container, Card, Row, Col, Form, Button, InputGroup, Tabs, Tab} from 'react-bootstrap';

// contextos    
import { PainelContexto } from './../../Painel/PainelContexto';

// inicializa
export default function Inicio(){

    // dados
    const permissaoReducer = useSelector(state => state.permissaoReducer);
    let painelContextoDados = useContext(PainelContexto);
    const [carregando, alterarCarregando] = useState(true);

    // inicializa
    useEffect(() => {
        
    }, []);

    return <>
        {/* <RelatorioAcessos/>

        <EmpresasMapa/> */}
        <Row>
            <Col md={3}>
                {painelContextoDados.relatorioUsuarios && 
                    <Card
                        className={'border-0'}
                    >
                        <Card.Body>
                            <p className='text-center font-weight-bold'>Usuários</p>
                            <table className='w-100'>
                                <tbody>
                                    <tr>
                                        <td>Online</td>
                                        <td style={{width: '1%'}}>{painelContextoDados.relatorioUsuarios.online}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr />
                            <table className='w-100'>
                                <tbody>
                                    <tr>
                                        <td>Web</td>
                                        <td style={{width: '1%'}}>{painelContextoDados.relatorioUsuarios.desktop}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr />
                            <table className='w-100'>
                                <tbody>
                                    <tr>
                                        <td>Mobile</td>
                                        <td style={{width: '1%'}}>{painelContextoDados.relatorioUsuarios.mobile}</td>
                                    </tr>
                                    <tr>
                                        <td>Android</td>
                                        <td style={{width: '1%'}}>{painelContextoDados.relatorioUsuarios.android}</td>
                                    </tr>
                                    <tr>
                                        <td>IOS</td>
                                        <td style={{width: '1%'}}>{painelContextoDados.relatorioUsuarios.ios}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Card.Body>
                    </Card>
                }
            </Col>
        </Row>

    </>

}