import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { Card, Table, Button, Badge, InputGroup, FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { useHistory, useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Registro from './Registro';
import Paginacao from './../../../../../Componentes/Paginacao';

// contextos    
import { PainelContexto } from './../../../../PainelContexto';

export default function Tabela({
    dados
}){

    // estados
    let painelContextoDados = useContext(PainelContexto);
    let history = useHistory();
    let { path, url } = useRouteMatch();
    
    let [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: isMobile ? 3 : 5
    });

    // inicializa
    useEffect(() => {
        
    }, [dados, parametros]);

    
    // retorna registro
    let retornaRegistros = () => {

        let inicio = (parametros.pagina - 1) * parametros.registrosPorPagina;

        // mantém apenas a página atual
        let dadosLocal = dados.filter((dado, index) => {
            return index >= inicio && index < inicio + parseInt(parametros.registrosPorPagina);
        })

        // retorna registros organizado
        return dadosLocal.map(registro => 
            <Registro 
                key={registro.id_empresa}
                dados={registro}
            />
        )
    }

    return <>
    
        <Card className="painel-consulta border-0" >
            <Card.Body className={'p-0'}>
                <div 
                    className={'my-3'}
                >
                    <table className={[styles.tabelaFontAjuste, "table table-hover tabela"].join(' ')}>
                        <thead>
                            <tr>
                                <th>Empresa</th>
                                <th style={{width: '1%'}}>Acessos</th>
                                <th style={{width: '1%'}}>Atualiz.</th>
                                <th>Guias</th>
                                <th style={{width: '1%'}}>Mapa</th>
                                <th style={{width: '1%'}}>Usuários</th>
                            </tr>
                        </thead>
                        <tbody>
                            { retornaRegistros() }
                        </tbody>
                    </table>
                </div>
                
                <Paginacao
                    pagina={parametros.pagina}
                    registrosPorPagina={parametros.registrosPorPagina}
                    totalRegistros={dados.length}
                    alterouPagina={(pagina) => {
                        alterarParametros({...parametros, pagina: pagina});
                    }}
                />
            </Card.Body>
        </Card>
    </>
}