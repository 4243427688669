
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Card, FormCheck, FormControl } from "react-bootstrap";
import Select from 'react-select';

import { FiltroComponent } from "./components";

import ComboEmpresas from "../../../../../../Componentes/ComboEmpresas";
import ComboEmpresasUsuarios from "../../../../../../Componentes/ComboEmpresasUsuarios";
import ComboServidoresMultiplo from '../../../../../../Componentes/ComboServidoresMultiplo';
import InputDataCombo from "../../../../../../Componentes/InputDataCombo";
import InputData from "../../../../../../Componentes/InputData";
import ComboVersao from "../../../../../../Componentes/ComboVersao";

const OpcaoFiltro = ({
    descricao,
    valor,
    nome,
    alterarValor,
    filtro,
}) => {

    // pega informações
    let {
        tipo,
        combo = null
    } = filtro;

    // verifica o tipo do filtro
    switch(tipo){
        case 'text':
            return <FormControl
                key={`filtro-form-${nome}`}
                size={'sm'}
                name={nome}
                value={valor ?? ''}
                onChange={(e) => {

                    // altera
                    // alterarDados({...dados, valor: e.target.value});
                    alterarValor(e.target.value)
                }}
            />;

            break;
        case 'arrayEmpresas':
            
            return <ComboEmpresas
                className="select-ficha"
                ids={valor ?? []}
                alterou={(array) => {
                    console.log(array)
                    alterarValor(array)
                }}
            />
            break;
        case 'simNao':

            return <div className="d-flex gap-2 justify-content-around">
                <FormCheck
                    inline
                    label="Sim"
                    name={nome}
                    type={'radio'}
                    onChange={() => {
                        alterarValor('S')
                    }}
                    checked={valor == 'S'}
                    id={`${nome}-sim`}
                />
                <FormCheck
                    inline
                    label="Não"
                    name={nome}
                    type={'radio'}
                    onChange={() => {
                        alterarValor('N')
                    }}
                    checked={valor == 'N'}
                    id={`${nome}-nao`}
                />
            </div>

            break;
        case 'select':

            return <Select
                className="select-ficha"
                options={combo.map((opcao) => {
                    return {
                        value: opcao.valor,
                        label: opcao.descricao
                    }
                })}
                value={combo.find((opcao) => opcao.value === valor)}
                placeholder={descricao}
                onChange={(e) => {
                    
                    // pega o valor
                    alterarValor(e.value)
                }}
            />
            break;
        case 'selectMultiplo':

            // prepara
            let opcoes = combo.map((opcao) => {
                return {
                    value: opcao.valor,
                    label: opcao.descricao
                }
            });

            return <Select
                isMulti
                className="select-ficha"
                options={combo.map((opcao) => {
                    return {
                        value: opcao.valor,
                        label: opcao.descricao
                    }
                })}
                value={valor ? opcoes.filter((opcao) => valor.includes(opcao.value)) : []}
                placeholder={descricao}
                onChange={(e) => {
                    // mantem vazio se for null
                    e = e || []; 

                    // pega o valor
                    alterarValor(e.map((option) => option.value))

                }}
            />
            break;
        case 'arrayUsuarios':

            return <ComboEmpresasUsuarios
                className="select-ficha"
                ids={valor ?? []}
                alterou={(array) => {
                    alterarValor(array)
                }}
            />
            break;
        case 'arrayServidorAluguel':
            return <ComboServidoresMultiplo
                className="select-ficha"
                ids={valor ?? []}
                alterou={(array) => {
                    alterarValor(array)
                }}
            />
            break;
        case 'idVersao':
            return <ComboVersao
                className="select-ficha"
                id={valor || null}
                alterou={idVersao => {
                    alterarValor(idVersao)
                }}
            />
            break;
        case 'date':

            return <InputDataCombo
                data={valor}
                onChange={(data) => {
                    // altera
                    alterarValor(data);
                }}
            />
            break;
    }

    // retorna
    return <></>;
}

export default function Filtro({
    descricao,
    nome,
    
    filtro,
    valor,
    alterarValor,
    remover
}){

    return <FiltroComponent tipo={filtro.tipo}>
        <Card className='border-0 shadow-sm m-0 p-0 mt-1'>
            <Card.Header className='mb-0 border-0 py-2 px-3'>
                <strong>{descricao}</strong>
                <FontAwesomeIcon 
                    className="icone" 
                    icon={["fas", 'times']} 
                    onClick={() => {
                        remover();
                    }}
                />
            </Card.Header>
            <Card.Body className={["p-2"].join(' ')}>
                <OpcaoFiltro
                    key={`filtro-form-${nome}`}
                    filtro={filtro}
                    nome={nome}
                    valor={valor}
                    descricao={descricao}
                    alterarValor={alterarValor}
                />
            </Card.Body>
        </Card>
    </FiltroComponent>
}