import React, { useState, useEffect, useContext } from 'react';
import './PainelSidebar.scss';

// componentes
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import imagemSidebar from './sidebar-6.jpg';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { Card } from 'react-bootstrap';

// componente
function PainelSidebar({
    className,
    encolherMenu,
    logo,
    links
}){

    

    // propriedades
    return <div className={["painel-sidebar rounded", className, isMobile ? 'mobile' : ''].join(' ')}>
        <div className="itens">
            {/* <div className="logo">
                <button onClick={encolherMenu}>
                    <FontAwesomeIcon className="icone" size="2x" icon={["fas", 'list-ul']} />
                </button>
                <Link to='/painel' className="texto-simples">{logo}</Link>
            </div> */}
            <ul className="nav">
                { links
                .filter(link => {
                    if(link.habilitar === false){
                        return false;
                    }
                    return true;
                })
                .map((link, a) => {

                    let Icone = () => <FontAwesomeIcon
                        className="icone"
                        style={{padding: '6px', color: link.cor || ''}}
                        icon={link.icone}
                    />;
                    if(link.svg){
                        Icone = link.svg;
                    }

                    return <li key={a} className="nav-item">
                        <Link 
                            to={link.url} 
                            className="nav-link"
                            onClick={() => {
                                if(isMobile){
                                    encolherMenu(true);
                                }
                            }}
                        >
                            <Icone className="icone" width={40} height={40} />
                            
                            <p>{link.descricao}</p>
                        </Link >
                    </li>
                })}
            </ul>
        </div>
        {/* <div 
            className="itens-fundo" 
            style={{
                // backgroundImage: `url(${imagemSidebar})`
            }}
        ></div> */}
    </div>
}

// retorno
export default PainelSidebar;