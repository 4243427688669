import React from 'react';
import styles from './index.module.scss';

// componente options
function OptionSelect({data, ...props}){

    // propriedades importantes
    let { innerProps, innerRef, isFocused } = props;

    // retorna componente
    return <div tabIndex='-1' ref={innerRef} {...innerProps} className={[styles.option, isFocused && styles.focado].join(' ')}>
        <div className={styles.dadosUsuario}>
            <div>
                <p>{data.dados.email}</p>
            </div>
        </div>
    </div>
}

// opção
export default OptionSelect;