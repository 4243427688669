import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { PermissaoContexto } from './PermissaoContexto';

// componentes
import { Row, Col } from 'react-bootstrap';

// funções que possuem permissões
import FuncoesFuncionarios from './Funcoes/Funcionarios';
import FuncoesDesenvolvimento from './Funcoes/Desenvolvimento';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { PermissaoComponent } from './components';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";

import Foto from './../Ficha/Foto';

// retorna 
export default function Permissao(){

    // estados
    const { permissaoReducer, loginReducer } = useSelector(state => state);
    const dispatch = useDispatch();
    const { id } = useParams();
    const [chaveAtualizando, alterarChaveAtualizando] = useState({});
    const [carregando, alterarCarregando] = useState(true);
    const [carregandoFuncionario, alterarCarregandoFuncionario] = useState(true);
    const [dadosFuncionario, alterarDadosFuncionario] = useState(null)
    const [dados, alterarDados] = useState(null);

    // carrega
    useEffect(() => {
        consultarPermissoes();
    }, []);

    // consulta permissões do usuário
    async function consultarPermissoes(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/funcionario/permissao/${id}`);
            alterarDados(data);
            alterarCarregando(false);

        }catch({response}){}

        // consulta os dados do usuário
        try{

            // faz a requisição
            let { data } = await axios.get(`/funcionario/${id}`);
            alterarDadosFuncionario(data);
            alterarCarregandoFuncionario(false);

        }catch({response}){}
    }

    // ao alterar
    async function salvar(chave, valor){

        // define como salvando
        chaveAtualizando[chave] = true;
        alterarChaveAtualizando({ ...chaveAtualizando });

        try{

            // faz a requisição
            let { data } = await axios.post(`/funcionarioPermissao`, {
                chave: chave,
                valor: valor,
                id_funcionario: id
            });

            // finalizado, altera os dados
            dados[chave] = valor;
            alterarDados({...dados});

            // atualiza reducer
            if(id === loginReducer.dadosUsuario.id){
                let chaveAtualizar = {};
                chaveAtualizar[chave] = valor;
                dispatch({
                    type: 'permissaoReducer/ATUALIZAR',
                    dados: chaveAtualizar
                });
            }


        }catch({response}){}
        finally{
            chaveAtualizando[chave] = false;
            alterarChaveAtualizando({ ...chaveAtualizando });
        }

    }

    // carregando permissões do usuário
    if(carregando || carregandoFuncionario){
        return <p className="text-center">
            <FontAwesomeIcon icon={faSpinner} pulse /> Carregando!
        </p>
    }

    return <PermissaoContexto.Provider
        value={{dados, alterarDados, dadosFuncionario}}
    >

        <PermissaoComponent>
            <Row className='justify-content-md-center'>
                {
                    isBrowser &&
                    <>
                        <Col md={3} >
                            <Row>
                                <Col className={['mb-3'].join(' ')}>
                                    <Foto 
                                        leitura={true}
                                        imagem={dadosFuncionario.dados.foto}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </>
                }

                <Col md={9}>
                    <FuncoesFuncionarios 
                        value={dados}
                        chaveAtualizando={chaveAtualizando}
                        onChange={salvar}
                    />

                    <FuncoesDesenvolvimento 
                        value={dados}
                        chaveAtualizando={chaveAtualizando}
                        onChange={salvar}
                    />
                </Col>
            </Row>
        </PermissaoComponent>
        
    </PermissaoContexto.Provider>
}