import { useParams } from 'react-router-dom';

import { Col, Row } from 'react-bootstrap';

import { ListaEmpresasArquivosComponent } from "./components";

import ListaEmpresasArquivosEstatisticas from './ListaEmpresasArquivosEstatisticas';

export default function ListaEmpresasArquivos(){

    // parametros da url
    const {
        // idEmpresa = null
    } = useParams();

    return <ListaEmpresasArquivosComponent>
        <Row className='d-flex px-2' style={{gap: '0.825em'}}>
            <Col className='p-0'>
                <ListaEmpresasArquivosEstatisticas />
            </Col>
        </Row>
    </ListaEmpresasArquivosComponent>
    
}