import React, { useRef, useState } from 'react';

// componentes
import { Button, Form } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';
import ModalFiltros from './../ModalFiltros'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

export default function ParametrosConsulta({
    parametros, 
    alterarParametros
}){

    const [ mostrarFiltros, alterarMostrarFiltros ] = useState(false);
    
    // referencias
    const refAbrirFiltros = useRef(null);

    return <div className="parametros">
        <ModalFiltros
            elemento={refAbrirFiltros.current}
            mostrar={mostrarFiltros}
            alterarMostrar={alterarMostrarFiltros}
            parametros={parametros}
            alterarParametros={alterarParametros}
        />
        
        <div className='d-flex' style={{gap: '0.5em'}}>
            <Button
                variant='light'
                size='sm'
                ref={refAbrirFiltros}
                onClick={() => {

                    // define
                    alterarMostrarFiltros(true);
                }}
                style={{
                    fontSize: '0.9em',
                    boxShadow: '0 0 1px #b1b1b1',
                    border: 0,
                    display: 'flex',
                    gap: '0.2em',
                    alignItems: 'center'
                }}
            >
                <FontAwesomeIcon className="icone" icon={faFilter} />
                Filtrar
            </Button>
            <div className="parametros-inicial flex-grow-1">
                
                <DebounceInput 
                    placeholder="" 
                    debounceTimeout={400}
                    element={Form.Control} 
                    value={parametros.pesquisa}
                    onChange={(e) =>{
                        alterarParametros({...parametros, pesquisa: e.target.value, pagina: 1})
                    }}
                />
                
            </div>

        </div>
    </div>
}