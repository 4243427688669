import React from 'react';

// componentes
import { Card  } from 'react-bootstrap';
import { isMobile, isBrowser } from "react-device-detect";
import BotaoTabela from './../../../../Componentes/BotaoTabela';

// componente
export default function Registro({
    dados,
    clicouEditar,
    clicouExcluir
}){

    // retorna opções
    let retornarOpcoes = () => {

        // retorna componentes
        return <>
            <BotaoTabela 
                onClick={clicouEditar}
                icone={["fas", "pencil-alt"]}
            >
                <span>Editar</span>
            </BotaoTabela>
            <BotaoTabela 
                onClick={clicouExcluir}
                icone={["fas", "trash"]}
            >
                <span>Excluir</span>
            </BotaoTabela>
        </>

    }

    return <> 

        {/* NAVEGADOR */}
        { isBrowser &&
            <tr
                style={{cursor: 'pointer'}}
                onClick={clicouEditar}
                className={['registro'].join(' ')}
            >
                <td className={'estreito text-center font-weight-bold'}>
                    {dados.id}
                </td>
                <td>
                    {dados.titulo}
                </td>
                <td className={'text-nowrap estreito'}>
                    { retornarOpcoes() }
                </td>
            </tr>
        }

        {/* MOBILE */}
        {isMobile &&
            <Card
                className={'border-0 bg-light mb-2'}
                onClick={clicouEditar}
            >
                <Card.Body className={'p-2'}>
                    <div className={[isBrowser ? 'd-flex' : ''].join(' ')}>
                        <div className={'flex-grow-1'}>
                            <div>
                                <span>
                                    {dados.titulo}
                                </span>
                            </div>
                        </div>
                        <div className={'text-right'}>
                            { retornarOpcoes() }
                        </div>
                    </div>
                </Card.Body>
            </Card>
        }
    </>
}