import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { Form } from 'react-bootstrap';
import axios from 'axios';

// componentes
import OptionSelect from './OptionSelect';

// componente
export default function ComboServidores({
    id, // id do modelo
    valor=null,
    alterou,
    placeholder = null,
    defineUltima=false,
    className='',
    filtros,
    config = {},
    disabled = false,
    esconderIconeDrop = false
}){

    // estados
    let [consulta, alterarConsulta] = useState([]);
    let [carregando, alterarCarregando] = useState(false);
    let [dados, alterarDados] = useState(null);
    const [timeoutConsultar, alterarTimerConsultar] = useState(null);
    let [definidoUltima, alterarDefinidoUltima] = useState(false);

    // consulta o id do modelo de email
    useEffect(() => {

        // carrega alguns
        consultar('');

        // verifica se possui usuário
        if(id){

            // consulta os dados do membro selecionado
            consultarId(id);

        }else{
            
        }

    }, []);

    // ao alterar valor
    useEffect(() => {

        // verifica se removido
        if(!valor){

            // altera
            alterarDados(null);
            return;
        }

        // verifica se alterou
        if(!dados || valor !== dados.id){

            // tenta encontrar o valor nos registros atuais
            let registroValor = consulta.find((r) => r.id === valor);

            // verifica se encontrou
            if(registroValor){

                // altera
                alterarDados(registroValor);
            }else{

                // consulta pelo id e preenche
                consultarId(valor);
            }
        }
        
    }, [valor]);

    // consulta os responsaveis
    async function consultar(inputValue, callback = null){
        // alterarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/servidores`, {
                params: {
                    texto: inputValue,
                    pagina: (typeof config.pagina !== "undefined") ? config.pagina : 1,
                    registrosPorPagina: (typeof config.registrosPorPagina !== "undefined") ? config.registrosPorPagina : 5,
                    ...(filtros ?? {})
                }
            });

            // pega dados formatados
            let dadosRetorno = data.registros.map((item) => item.dados);

            // retorno
            alterarConsulta(dadosRetorno);
            if(callback !== null){
                callback(dadosRetorno)
            }

            // define a última
            if(defineUltima && !definidoUltima && id === null){

                // pega o primeiro registro
                alterarDados({...dadosRetorno[0]});
                alterou(dadosRetorno[0].id, {...dadosRetorno[0]});
                alterarDefinidoUltima(true);
            }

        }catch({response}){

        }finally{

        }
        
        // alterarCarregando(false);
    }

    // consulta membro
    async function consultarId(id){
        alterarCarregando(true);
        
        try{
            
            // faz a consulta do id
            let { data } = await axios.get(`/servidores/${id}`);
            alterarDados(data.dados);

        }catch({response}){

        }finally{
            alterarCarregando(false);
        }
    }

    // ao alterar a consulta
    const alterouConsulta = async (inputValue, callback) => {

        // limpa o timeout
        clearTimeout(timeoutConsultar);

        // aguarda a consulta
        await new Promise((resolve) => {

            // aguarda tempo sem rolar o slider
            alterarTimerConsultar(setTimeout(async () => {
                await consultar(inputValue, callback);
                resolve()
            }, 300));
        })
        
    };

    return <AsyncSelect
        className={className}
        isDisabled={disabled}
        isClearable
        isLoading={carregando}
        loadOptions={alterouConsulta} // ao alterar, carrega
        defaultOptions={consulta}       // lista pré carregada
        value={dados && {
            label: dados.nome
        }}
        onChange={(e) => {
            if(e === null){
                alterarDados(null);
                alterou(null, null);
            }else{
                alterarDados({...e});
                alterou(e.id, {...e});
            }
        }}
        components={{
            Option: OptionSelect,
            ...(esconderIconeDrop ? {
                DropdownIndicator:() => null,
                IndicatorSeparator:() => null
            } : {})
        }}
        noOptionsMessage={() => 'Digite algo para fazer a consulta'}
        loadingMessage={() => 'Carregando...'}
        placeholder={placeholder ?? 'Escolher servidor'}
    />

}
