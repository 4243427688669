import { useEffect, useState } from "react";
import axios from "axios";
import Chart from "react-apexcharts";

import { Card, Col, Form, Row } from "react-bootstrap";

import { RelatoriosEmpresasAcessosComponent } from "./components";
import InputDataCombo from "../../../Componentes/InputDataCombo";
import { intersectObject } from "../../../Funcoes";
import ListaTopEmpresas from "../ListaTopEmpresas";
import ListaTopUsuarios from "../ListaTopUsuarios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export default function RelatoriosEmpresasAcessos({
    filtros
}){

    // estados
    const [ carregando, alterarCarregando ] = useState(false);
    const parametrosPadrao = {
        dataInicio: moment().format(`YYYY-MM-DD`),
        dataFinal: moment().format(`YYYY-MM-DD`),
        horaInicio: null,
        horaFinal: null,
        idsGruposChaveGoogle: null,
        empresaUsarTokenGoogleCliente: null
    };
    const [ parametros, alterarParametros ] = useState({...parametrosPadrao});
    const [ totais, alterarTotais ] = useState({
		total: 0,
		totalLogin: 0,
		totalRefresh: 0,
		totalNewtab: 0
	});
    const [ labels, alterarLabels ] = useState([]);
    const [ series, alterarSeries ] = useState([]);

    useEffect(() => {

        // consulta
        consultar();
        
    }, [parametros]);

    useEffect(() => {

        // pega os parametros
        let parametrosAlterar = {
            ...parametros,
            ...intersectObject(parametrosPadrao, filtros ?? null)
        };

        // verifica se alterando
        if(JSON.stringify(parametrosAlterar) !== JSON.stringify(parametros)){

            // altera e consulta
            alterarParametros(parametrosAlterar);
        }

    }, [filtros]);

    // função para a consulta
    async function consultar(){
     
        // cria o carregamento
        alterarCarregando(true);

        try{
            
            // consulta
            let { data } = await axios.get('/empresas/acessos/relatorio', {
                params: {
                    ...parametros
                }
            });

            // define os totais
            alterarTotais(data.totais);

            alterarLabels([
                'Login',
                'Atualizar página',
                'Nova guia'
            ]);
            alterarSeries([
                parseInt(data.totais.totalLogin),
                parseInt(data.totais.totalRefresh),
                parseInt(data.totais.totalNewtab)
            ]);

        }catch(e){
            // erro
        }finally{

            // remove o carregamento
            alterarCarregando(false);
        }
    }

    return <RelatoriosEmpresasAcessosComponent>
        {carregando && <div className="carregamento">
            <FontAwesomeIcon icon={faSpinner} pulse /> Carregando...
        </div>}
        
        <Row className="px-1">
            <Col className="px-0">
                <div className="card-totais-gerais">
                    <div className="total-grupo">
                        <div className="total-geral">
                            <span className={"total geral"}>
                                {totais.total}
                            </span>
                            acessos
                        </div>
                        <div className="sub-total">
                            <div className="total-geral">
                                <span className={"total login"}>
                                    {totais.totalLogin}
                                </span>
                                login
                            </div>
                            <div className="total-geral">
                                <span className={"total refresh"}>
                                    {totais.totalRefresh}
                                </span>
                                atualizar página
                            </div>
                            <div className="total-geral">
                                <span className={"total new-tab"}>
                                    {totais.totalNewtab}
                                </span>
                                nova guia
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
            {(totais.total > 0 && !carregando) && <Col
                className="px-0"
                style={{minWidth: 'fit-content'}}
            >
                <div
                    className={['grafico-content', 'd-flex', 'justify-content-center', 'p-1', 'rounded'].join(' ')}
                >
                    <div style={{width: 225, height: 120}}>
                        <Chart
                            type="donut"
                            height={250}
                            options={{
                                parentHeightOffset: 0,
                                sparkline: {
                                    enabled: true,
                                },
                                labels,
                                plotOptions: {
                                    pie: {
                                        size: 100,
                                        startAngle: -90,
                                        endAngle: 90,
                                        donut: {
                                            size: '35%'
                                        }
                                    }
                                },
                                legend: {
                                    show: false,
                                    position: 'top',
                                },
                                colors: [
                                    '#90d90b', '#f3910d', '#418273'
                                ]
                            }}
                            series={series}
                        />
                    </div>
                </div>
            </Col>}
        </Row>
    </RelatoriosEmpresasAcessosComponent>
    
}