import { useEffect, useRef, useState } from "react";
import { Route, useHistory, useParams, useRouteMatch } from "react-router-dom";

import { Button, Card, Tab, Tabs, Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import { EmpresasAcessosComponent } from "./components";
import RelatoriosEmpresasAcessos from "./RelatoriosEmpresasAcessos";
import { FichaComponent } from "../../Componentes/components";
import InputDataCombo from "../../Componentes/InputDataCombo";
import ListaTopEmpresas from "./ListaTopEmpresas";
import ListaTopUsuarios from "./ListaTopUsuarios";
import ListaUltimosAcessos from "./ListaUltimosAcessos";
import ModalFiltros from "./ModalFiltros";
import ModalAcessosPorEmpresa from "./ModalAcessosPorEmpresa";
import moment from "moment";

export default function EmpresasAcessos(){

    // pega parametros
    const params = useParams();
    const tabInicial = params.tab ?? null;

    // prepara o histórico da página
    const history = useHistory();
    const {
        path,
        url
    } = useRouteMatch();

    // estados
    const [ tab, alterarTab ] = useState(tabInicial ?? 'pesquisas');
    const [ parametros, alterarParametros ] = useState({
        dataInicio: moment().format(`YYYY-MM-DD`),
        dataFinal: moment().format(`YYYY-MM-DD`)
    });
    const [ mostrarFiltros, alterarMostrarFiltros ] = useState(false);
    const refAbrirFiltros = useRef(null);

    useEffect(() => {

        // verifica se alterou a tab
        if((tabInicial ?? 'pesquisas') !== tab){

            // define
            alterarTab(tabInicial ?? 'pesquisas');
        }
    }, [tabInicial]);

    // retorna
    return <EmpresasAcessosComponent>
        <ModalFiltros
            elemento={refAbrirFiltros.current}
            mostrar={mostrarFiltros}
            alterarMostrar={alterarMostrarFiltros}
            parametros={parametros}
            alterarParametros={alterarParametros}
        />

        <Card.Header>
            <div className={'d-flex '}>
                <Card.Title className={'flex-grow-1 d-flex align-items-center mb-0'}>
                Acessos
                </Card.Title>
                
                <div>
                    <Button
                        variant='light'
                        size='sm'
                        ref={refAbrirFiltros}
                        onClick={() => {

                            // define
                            alterarMostrarFiltros(true);
                        }}
                        style={{
                            fontSize: '0.9em',
                            boxShadow: '0 0 1px #b1b1b1',
                            border: 0,
                            display: 'flex',
                            gap: '0.2em',
                            alignItems: 'center'
                        }}
                    >
                        <FontAwesomeIcon className="icone" icon={faFilter} />
                        Mais filtros
                    </Button>
                </div>
            </div>
        </Card.Header>
        <Card.Body className={'py-1'}> 
            <Row>
                <Col
                    className="py-2 d-flex flex-column"
                    sm={12}
                    md={4}
                    xl={4}
                >
                    <Row className="px-2 mb-1">
                        <Col className="px-0">
                            
                            <FichaComponent>
                                <Row>
                                    <Col className='position-relative'>
                                        <Form.Label>De: </Form.Label>
                                        <InputDataCombo
                                            size="sm"
                                            placeholder="A partir de"
                                            data={parametros.dataInicio}
                                            onChange={(novaData) => {
                                                alterarParametros({...parametros, dataInicio: novaData})
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='position-relative'>
                                        <Form.Label>Até: </Form.Label>
                                        <InputDataCombo
                                            size="sm"
                                            placeholder="Até"
                                            data={parametros.dataFinal}
                                            onChange={(novaData) => {
                                                alterarParametros({...parametros, dataFinal: novaData})
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FichaComponent>
                        </Col>
                    </Row>
                </Col>
                <Col md={8}>
                    <Row className="px-2" style={{gap: '1rem', flexGrow: 1}}>
                        <RelatoriosEmpresasAcessos
                            filtros={parametros}
                        />
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ListaTopEmpresas
                        filtros={parametros}
                    />
                </Col>
                <Col>
                    <ListaTopUsuarios
                        filtros={parametros}
                    />
                </Col>
            </Row>
            <ListaUltimosAcessos />
        </Card.Body>

        <Route
            exact
            path={[`/painel/empresas/acessos/:idEmpresa`]}
        >
            <ModalAcessosPorEmpresa />
        </Route>
    </EmpresasAcessosComponent>;
}