import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Row, Col, Form, Button, InputGroup, Tabs, Tab} from 'react-bootstrap';
import { toast } from 'react-toastify';
import useIsMounted from 'ismounted';
import {
	PieChart, Pie, Sector, Cell, Tooltip,ResponsiveContainer
} from 'recharts';

// escreve o email
export default function GraficoPie({
	dadosGrafico,
	config=null
}){
	
    // dados
    const permissaoReducer = useSelector(state => state.permissaoReducer);
    
    const [carregando, alterarCarregando] = useState(false);
	const [configuracao, alterarConfiguracao] = useState(Object.assign({
		// configuração padrão
		clicavel: false,
		animacao: false,
		colorido: true,
		cores: ['#bf5353', '#da732f', '#da9b2f', '#debe31', '#e8df87'],
		mostrarPorcentagem: true,
		graficoMetade: false,
		tamanho: 200,
		responsivo: false
	}, config));

	const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
		cx, cy, midAngle, innerRadius, outerRadius, percent, index,
	}) => {

		const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);
	  
		if(percent < 0.05){
			return <text></text>;
		}

		return (
		  <text x={x} y={y} fill="white" textAnchor={'middle'} dominantBaseline="central">
			{`${(percent * 100).toFixed(0)}%`}
		  </text>
		);
	};  

    // inicializa
    useEffect(() => {
		
    }, []);

	if(carregando){
		return <div>Carregando...</div>
	}

	/* verifica se possui dados maiores que 0 */
	if(dadosGrafico.filter((e) => e.value > 0).length === 0){
		return <></>
	}

    return <>
		<ResponsiveContainer height={configuracao.graficoMetade ? configuracao.tamanho / 2 : configuracao.tamanho} width={configuracao.responsivo ? '100%' : configuracao.tamanho}>
			{
				/* verifica se possui dados maiores que 0 */
				dadosGrafico.filter((e) => e.value > 0).length > 0 &&
				<PieChart 
					{...{
						...(configuracao.graficoMetade ? {
							height: configuracao.tamanho / 2
						} : {
							height: configuracao.tamanho
						}),
						margin: {
							top: 0,
							bottom: 0,
							left: 0,
							right: 0
						}
					}}
				>
					<Pie 
						{...{
							dataKey: "value",
							...(!configuracao.responsivo && {
								cx: configuracao.tamanho / 2,
								cy: configuracao.tamanho / 2
							}),
							...(configuracao.graficoMetade && {
								startAngle: 180, 
								endAngle: 0
							}),
							data: dadosGrafico,
							outerRadius: configuracao.graficoMetade ? configuracao.tamanho / 2 : '100%',
							fill: "#8884d8",
							labelLine: false,
							...(configuracao.mostrarPorcentagem && {label: renderCustomizedLabel}),
							isAnimationActive: configuracao.animacao
						}}
					>
						{
							configuracao.colorido &&
							dadosGrafico.map((entry, index) => <Cell key={`cell-${index}`} fill={configuracao.cores[index % configuracao.cores.length]} />)
						}
					</Pie>
					<Tooltip   
						wrapperStyle={{
							zIndex: 10,
							marginLeft: '-100px'
						}} 
						labelFormatter={(a) => {
							// retorna mantendo o texto como html
							return <>
								<span
									dangerouslySetInnerHTML={{__html: a}}
								>
								</span>
							</>
						}}
					/>
				</PieChart>
			}
		</ResponsiveContainer>
    </>
}