import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import axios from 'axios';
import moment from 'moment';

// componentes
import { Card, Row, Col } from 'react-bootstrap';
import ParametrosConsulta from './ParametrosConsulta';
import Mes from './Mes';

// histórico
export default function Consultar(){

    // estados
    const [historico, alterarHistorico] = useState([]);
    const [carregando, alterarCarregando] = useState(true);
    const [parametros, alterarParametros] = useState({
        data_inicio: moment().subtract(2, 'days').format('YYYY-MM-DD'),
        data_fim: moment().format('YYYY-MM-DD'),
        funcao: [], // 'clientes/consultarId'
        id_funcionario: []
    });

    // incializa
    useEffect(() => {
        consultarHistorico();
    }, [parametros]);


    // consulta historico
    async function consultarHistorico(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/historicoLogs`, { params: parametros });
            alterarHistorico(data.registros);
            alterarCarregando(false);

        }catch({response}){}
        finally{}

    }


    return <>
    <Row>
        <Col
            lg={8}
        >
            <Card
                className='border-0'
            >
                <Card.Body>
                    { historico.map(ano => 
                        <div
                            key={ano.ano}
                        >
                            
                            { ano.meses.map(mes => 
                                <Mes 
                                    key={mes.mes}
                                    dados={mes}
                                />
                            )}
                        </div>
                    )}

                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card
                className={'border-0'}
            >
                <Card.Body>
                    <ParametrosConsulta 
                        parametros={parametros}
                        alterarParametros={alterarParametros}
                    />
                </Card.Body>
            </Card>
        </Col>
    </Row>
    </>
}