import styled from 'styled-components';


export const ModalCadastroGrupoComponent = styled.div.attrs({
    className: ``
})`

    .campo-menor{
        &.form-label, &.form-check-label{
            font-size: 0.8rem;
        }

        &.form-check{
            font-size: 0.9rem;
        }

        &.custom-switch{
            font-size: 0.9rem;
            padding-left: 2.25em;

            .custom-control-input{
                width: 1em;
                height: 1.25em;
                
                &:checked ~ .custom-control-label::after{
                    transform: translateX(0.75em);
                }
            }
            .custom-control-label{
                &::before{    
                    top: 0.25em;
                    left: 1.5em;
                    display: block;
                    width: 1em;
                    height: 1em;
                    
                    left: -2.25em;
                    width: 1.75em;
                    border-radius: 0.5em;
                    
                }

                &::after{
                    top: calc(0.25em + 2px);
                    left: calc(-2.25em + 2px);
                    width: calc(1em - 4px);
                    height: calc(1em - 4px);
                    border-radius: 0.5em;
                }
            }
        }
    }
    
    input{
        &:focus{
            z-index: unset !important;
        }
    }

`