import { faPencilAlt, faToggleOff, faToggleOn, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { isBrowser, isMobile } from "react-device-detect";
import { Badge, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BotaoTabela from "../../../Componentes/BotaoTabela";

export default function Registro({
    dados,
    totalEmpresas,
    clicouEditar,

    clicouExcluir
}){

    // retorna opções
    let retornarOpcoes = () => {

        // retorna componentes
        return <>
            <BotaoTabela 
                onClick={clicouEditar}
                icone={faPencilAlt}
            >
                <span>Editar</span>
            </BotaoTabela>
            {
                parseInt(totalEmpresas) === 0 && 
                <BotaoTabela
                    onClick={clicouExcluir}
                    icone={faTrashAlt}
                >
                    <span>Excluir</span>
                </BotaoTabela>
            }
        </>
    }

    return <> 

        {/* NAVEGADOR */}
        { isBrowser &&
            <tr
                style={{cursor: 'pointer'}}
                onClick={clicouEditar}
                className={['registro'].join(' ')}
            >
                <td className={'estreito text-center font-weight-bold'}>
                    <Badge className={['rounded-pill', 'quantidade-empresas'].join(' ')} style={{fontWeight: 800}}>
                        {totalEmpresas}
                    </Badge>
                </td>
                <td>
                    <div className="d-flex align-items-center" style={{gap: 3}}>
                        {dados.nome}
                    </div>
                </td>
                <td className={'text-nowrap estreito'}>
                    { retornarOpcoes() }
                </td>
            </tr>
        }

        {/* MOBILE */}
        {isMobile &&
            <Card
                className={'border-0 bg-light mb-2'}
                onClick={clicouEditar}
            >
                <Card.Body className={'p-2'}>
                    <div className={[isBrowser ? 'd-flex' : ''].join(' ')}>
                        <div className={'flex-grow-1'}>
                            <div className="d-flex align-items-center" style={{gap: 3}}>
                                {dados.nome}
                            </div>
                        </div>
                        <div className={'text-right'}>
                            { retornarOpcoes() }
                        </div>
                    </div>
                </Card.Body>
            </Card>
        }
    </>
}