import React, { useState, useEffect, useContext } from 'react';

// componentes
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import InputDataCombo from './../../../../Componentes/InputDataCombo';
import ComboServidores from './../../../../Componentes/ComboServidores';


export default function ParametrosConsulta({
    parametros, 
    alterarParametros,
    mostrarFiltros,
    alterarMostrarFiltros
}){

    // filtros padrão para a pesquisa
    let filtrosInterno = [
        {value: 'S', label: 'Interno'},
        {value: 'N', label: 'Público'}
    ];

    return <div className="parametros">
        <div className="parametros-inicial">
            <DebounceInput 
                placeholder="" 
                debounceTimeout={400}
                element={Form.Control} 
                value={parametros.texto}
                onChange={(e) =>{
                    alterarParametros({...parametros, texto: e.target.value, pagina: 1})
                }}
            />
            <Col md={'3'} className={'px-0'}>
                <Select
                    isClearable
                    options={filtrosInterno}
                    isMulti={false}
                    placeholder='Tipo'
                    onChange={(e) => {
                        let valor = (e) ? e.value : '';
                        
                        // altera os filtros selecionados
                        alterarParametros({...parametros, interno: valor, pagina: 1});
                    }}
                />
            </Col>
        </div>
    </div>
}