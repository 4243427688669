import * as React from "react";

const SvgMenuPontosIcone = (props) => {
  props = {
    width: 15.5,
    height: 15.5,
    ...props,
  };
  return (
    <svg viewBox="0 0 128 128" {...props}>
      <style />
      <g id="menu-pontos-icone_svg__menu">
        <radialGradient
          id="menu-pontos-icone_svg__SVGID_1_"
          cx={70.99}
          cy={80.915}
          r={7.267}
          gradientTransform="matrix(.9657 0 0 .9633 -4.553 -13.946)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.5} stopColor="#a1c0cd" />
          <stop offset={1} stopColor="#7a9cab" />
        </radialGradient>
        <circle
          cx={64}
          cy={64}
          fill="url(#menu-pontos-icone_svg__SVGID_1_)"
          r={7}
        />
        <radialGradient
          id="menu-pontos-icone_svg__SVGID_2_"
          cx={70.99}
          cy={107.895}
          r={7.267}
          gradientTransform="matrix(.9657 0 0 .9633 -4.553 -12.935)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.5} stopColor="#a1c0cd" />
          <stop offset={1} stopColor="#7a9cab" />
        </radialGradient>
        <circle
          cx={64}
          cy={91}
          fill="url(#menu-pontos-icone_svg__SVGID_2_)"
          r={7}
        />
        <radialGradient
          id="menu-pontos-icone_svg__SVGID_3_"
          cx={70.99}
          cy={53.935}
          r={7.267}
          gradientTransform="matrix(.9657 0 0 .9633 -4.553 -14.956)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.5} stopColor="#a1c0cd" />
          <stop offset={1} stopColor="#7a9cab" />
        </radialGradient>
        <circle
          cx={64}
          cy={37}
          fill="url(#menu-pontos-icone_svg__SVGID_3_)"
          r={7}
        />
      </g>
    </svg>
  );
};

export default SvgMenuPontosIcone;
