import moment from "moment";
import { Badge, Card } from "react-bootstrap";
import { isBrowser, isMobile } from "react-device-detect";
import { faFileAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import BotaoTabela from "../../../../Componentes/BotaoTabela";

export default function Registro({
    dados,
    dadosEmpresa,
    dadosUsuario,
    encontradoEm,
    dataAnterior=null,

    clicouAbrir
}){

    // retorna opções
    let retornarOpcoes = () => {

        // retorna componentes
        return <>
            <BotaoTabela
                onClick={clicouAbrir}
                icone={faFileAlt}
            >
                <span>Abrir</span>
            </BotaoTabela>
        </>
    }

    return <> 
        {
            (
                !dataAnterior ||
                moment(dataAnterior).format('DD/MM/YYYY') !== moment(dados.dataCadastro).format('DD/MM/YYYY')
            ) && <tr className="registro registro-divisor-data">
                <td className="divisor-data estreito">{moment(dados.dataCadastro).format('DD/MM/YYYY')}</td>
            </tr> 
        }
        <tr
            className={[
                'registro',
                encontradoEm ? `encontrado-${encontradoEm}` : 'nao-encontrado'
            ].join(' ')}
        >
            <td className={'estreito text-center font-weight-bold destaque menor'}>
                {dadosEmpresa.codigo}
            </td>
            

            <td className={'text-center estreito hora'}>
                {moment(dados.dataCadastro).format('HH:mm:ss')}
            </td>
            
            <td className="descricao">
                <div className="d-flex flex-column">
                    <div>
                        {dadosUsuario.nome} (
                            <small>Usuário:</small> {dadosUsuario.usuario}{' '}
                            <small>Empresa:</small> {dadosEmpresa.razaoSocial}
                        )
                        {/* <Badge className={['rounded-pill mx-1'].join(' ')} variant="secondary">
                            {dadosIntegracaoSistema.nome}
                        </Badge> */}
                    </div>
                    <small>
                        {Object.keys(dados.dados).filter((tipo) => dados.dados[tipo]).map((tipo, i) => {
                            return <span key={tipo}>
                                {i > 0 && <> | </>}
                                <b>{tipo}:</b> {dados.dados[tipo]}
                            </span>
                        })}
                    </small>
                </div>
                {/* <span className={['text-center font-weight-bold versao', dados.versao].join(' ')}>
                    {dados.origem}
                </span> */}
            </td>

            <td className="estreito">{retornarOpcoes()}</td>
        </tr>
    </>
}