import styled from 'styled-components';


export const FichaComponent = styled.div.attrs({
    className: ``
})`
    .alerta{
        font-size: 12px;
        padding: 5px 10px !important;
        border-radius: 5px;

        button{
            padding: 4px 2px !important;
            font-size: 20px;
        }

        p{
            margin: 0;
        }
    }

    .form-label, .form-check-label{
        font-size: 0.8rem;
    }

    .form-check{
        font-size: 0.9rem;
    }

    .form-control, .input-group-text{
        min-height: unset;
        height: 31px;
    }

    .select-ficha > div:first-child{
        min-height: unset;
        height: 31px;
    }

    .input-group{

        .select-ficha:not(:first-child) > div:first-child{
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        
        .select-ficha:not(:last-child) > div:first-child{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .grupo-texto-meio{
        border-radius: 0;
        margin-left: -1px;
        margin-right: -1px;
    }
    
    .grupo-texto-inicio{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: -1px;
    }

    .custom-switch{
        font-size: 0.9rem;
        padding-left: 2.25em;

        .custom-control-input{
            width: 1em;
            height: 1.25em;
            
            &:checked ~ .custom-control-label::after{
                transform: translateX(0.75em);
            }
        }
        .custom-control-label{
            &::before{    
                top: 0.25em;
                left: 1.5em;
                display: block;
                width: 1em;
                height: 1em;
                
                left: -2.25em;
                width: 1.75em;
                border-radius: 0.5em;
                
            }

            &::after{
                top: calc(0.25em + 2px);
                left: calc(-2.25em + 2px);
                width: calc(1em - 4px);
                height: calc(1em - 4px);
                border-radius: 0.5em;
            }
        }
        
        &.nowrap{

            .custom-control-label{
                white-space: nowrap;
            }
        }
    }

    .custom-switch-sm{
        font-size: 0.8rem;
        padding-left: 2.25em;
        height: unset;
        min-height: unset;
     
    }
    
    .form-group{
        margin-bottom: 2px;
    }

    .input-group-prepend{

        button{
            z-index: 2 !important;
        }
    }

    .select-ficha, .aba-usuarios .select{
        font-size: 0.875rem;

        > div:nth-of-type(1){
            padding: 0;
            min-height: unset;

            > div{
                padding: 0 8px;

                &:last-child{
                    padding-right: 4px;
                }

            }

            > div:nth-of-type(2){
                > div{
                    padding: 0 8px;
                }
            }
        }
    }

    input{
        &:focus{
            z-index: unset !important;
        }
    }

    .flatpickr-calendar{
        top: unset !important;
        bottom: 100% !important;
        left: unset !important;
        right: 0 !important;

        &.arrowTop{
            bottom: unset !important;
            top: 100% !important;
        }

        &.arrowLeft{

            &:not(.rightMost){
                left: 0 !important;
                right: unset !important;
            }
        }
    }

`

export const ListaComponent = styled.div.attrs({
    className: `painel-consulta border-0`
})`
    
    .card-header{
        /* padding: 0; */
        font-size: 0.9em;

        .h5{
            font-size: 1.25em;
        }

        .btn{
            padding: 0.25em 0.5em;
            font-size: 0.875em;
        }
    }

    .card-body{
        padding-top: 0.5rem;
    }

    .form-control, .input-group-text{
        min-height: unset;
        height: 31px;
    }

    .btn{
        padding: 0.25em 0.5em;
        font-size: 0.875em;
        line-height: 1.5;
        border-radius: 0.2em;
    }

    .card{

        > .card-header{
            border-left: 3px solid var(--cor-cliente-ficha) !important;
        }

        .card{
            > .card-header{
                border-left: unset !important;
            }
        }
    }

    .tabela{
        width: 100%;
        border-radius: 0.3em;
        border-collapse: separate;
        font-size: 0.95em;
        margin-top: 0.75em;
        -webkit-border-horizontal-spacing: 0.2em;
        -webkit-border-vertical-spacing: 0.25em;

        &:empty, tbody:empty{
            position: relative;
            min-height: 260px;

            &::after{
                content: "Sem registros";
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                color: grey;
                background: #fbfbfb;
                border-radius: 0.3em;
                box-shadow: 0px 0px 1px inset #cacaca;
            }
        }
        
        .registro, thead tr{
            --cor-status-registro: black;
            /* display: flex;
            align-items: flex-start; */
            font-size: 0.9em;
            border-radius: 0.3em;
            position: relative;

            &.registro-divisor-data{
                font-weight: 600;
                height: fit-content;

                td{
                    height: fit-content;
                    font-size: 0.8em;
                    text-align: center;
                    border-bottom: 1px solid #c3c0c0;
                    border-left: 1px solid #c3c0c0;
                    padding: 0 5px;

                    &:hover{
                        background: #fdfdfd;
                    }
                }
            }

            &:first-child{
                margin-top: 0;
            }

            td, th{
                border-left: 1px solid #f3f3f3;
                background: #fdfdfd;
                border: 1px solid #f3f3f3;
                padding: 0.25em 0.5em;
                vertical-align: middle;

                &:first-child{
                    border-top-left-radius: 0.3em;
                    border-bottom-left-radius: 0.3em;
                }
                
                &:last-child{
                    border-top-right-radius: 0.3em;
                    border-bottom-right-radius: 0.3em;
                }
                
                &.destaque{
                    color: var(--cor-status-registro);
                    border-left: 3px solid var(--cor-status-registro);
                }

                &.menor{
                    font-size: 0.8em;
                }

                &.hora{
                    font-size: 0.8em;
                    font-weight: 500;
                }
            }
            
            th{
                background: #fafafa;
            }
        }
    }

    .paginacao{
        font-size: 0.85em;

        .total-registros{
            padding: 0.125em 1.2em !important;
        }
    }
    
    .alerta{
        font-size: 12px;
        padding: 5px 10px !important;
        border-radius: 5px;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        margin: 4px;

        button{
            padding: 1px 2px !important;
            font-size: 20px;
        }

        p{
            margin: 0;
        }
    }
`;