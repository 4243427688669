import React, { useState, useContext } from 'react';

// contexto
import { ParametrosListaContexto } from './../ParametrosListaContexto';

// componentes
import { ListaComponent } from './components';
import { Card, Form, Row, Col, Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faPlus, faSpinner, faPlay } from '@fortawesome/free-solid-svg-icons';
import Parametro from './../Parametro';
import * as R from 'ramda';

export default function Lista({
    parametros,
    alterou,
    clicouNovo,
    raiz=false
}){

    // console.log(parametros);
    const { alterarParametros } = useContext(ParametrosListaContexto);

    return <ListaComponent raiz={raiz}>
        <div className='parametros'>
            {parametros.filter(param => !param.remover).map(param => 
                <Parametro 
                    key={param.tempId || param.dados.id}
                    registro={param}
                    clicouExcluir={() => {

                        if(param.dados.id === null){
                            let novoArrayParametros = parametros.filter(paramVerificar => param !== paramVerificar);
                            // parametros = novoArrayParametros;
                            alterou(novoArrayParametros);
                            // Object.assign();
                            // alterarParametros([...parametros]);
                        }else{
                            param.remover = true;
                            // alterou(R.clone(parametros));
                            alterou(parametros);
                            // alterarParametros([...parametros]);
                        }
                    }}
                />
            )}
            
            
            
            <p className="text-left m-0">
                <Button
                    className='m-0 mt-2'
                    variant={'padrao'}
                    size="sm"
                    onClick={() => {
                        clicouNovo();
                    }}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </p>
        </div>


    </ListaComponent>

}