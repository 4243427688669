import styled from 'styled-components';

export const DescricaoApiComponent = styled.div.attrs({
    className: ``
})`

    .markdown {

        img {
            width: 100%;
        }
    }

`