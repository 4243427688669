import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import queryString  from 'query-string';

// contexto
import { CadastrarContexto } from './CadastrarContexto';

// componentes
import { CadastrarComponent } from './components';
import { Card, Form, Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faSpinner, faPlay } from '@fortawesome/free-solid-svg-icons';
import ComboVersao from './../../../Componentes/ComboVersao';
import ComboApisGrupos from './../../../Componentes/ComboApisGrupos';
import AbaCriacao from './AbaCriacao';
import AbaTeste from './AbaTeste';
import DescricaoApi from './DescricaoApi';

// componente
export default function Cadastrar({
    
}){

    // estados
    const history = useHistory();
    const { id } = useParams();
    const [dados, alterarDados] = useState({
        id: id || null,
        metodo: 'get',
        url: '',
        publico: 'N',
        descricao: '',
        versao: null,
        idApiGrupo: null,
        posicao: null
    });
    const [parametros, alterarParametros] = useState([]);
    const [salvando, alterarSalvando] = useState(false);
    const [carregando, alterarCarregando] = useState(true);
    const [urlTeste, alterarUrlTeste] = useState(null);
    const [dadosUsuario, alterarDadosUsuario] = useState(null);
    const [objetoBody, alterarObjetoBody] = useState({});
    const [aba, alterarAba] = useState('criacao'); // criacao , teste
    const [enderecoServidorUsuario, alterarEnderecoServidorUsuario] = useState('');

    // configura url como a mesma irá ficar
    async function ajustarUrlTeste(){

        // pega o objeto
        let parametrosQuery = pegarObjetoQuery(parametros);

        // codifica a query para salvar no navegador
        let encodeQuery = queryString.stringify(parametrosQuery, {arrayFormat: 'bracket'});
        alterarUrlTeste(encodeQuery);

    }

    // salva api
    async function salvar(){
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/apis`, {
                dados: dados,
                parametros: parametros
            });
            alterarDados(data.dados);
            alterarParametros(data.parametros);

            // finalizado
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Api salva com sucesso!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </>);

            // altera a url
            history.replace(`/painel/documentacao-api/alterar/${data.dados.id}`);

        }catch({response}){}
        finally{
            alterarSalvando(false);
        }

    }

    // consulta api
    async function consultarId(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/apis/${dados.id}`);
            alterarDados(data.dados);
            alterarParametros(data.parametros);
            alterarCarregando(false);

        }catch({response}){}
    }


    // pega os dados que seram enviados no body
    function defineObjetoEnvioBody(parametroRaiz){
        
        let body = {};

        // pega os dados
        parametroRaiz
        .filter(param => !param.remover)
        .filter(param => param.dados.onde === 'body' && (param.teste || param.dados.obrigatorio === 'S'))
        .forEach(param => {

            // valor
            let valor = param.dados.valor;
            if(param.dados.tipo === 'json'){
                try{
                    if(typeof JSON.parse(param.dados.valor) === 'object'){
                        valor = JSON.parse(param.dados.valor);
                    }else{
                        valor = {};
                    }
                }catch({}){
                    valor = {};
                }
            }

            // converte para array
            if(param.dados.tipo === 'array'){
                valor = param.dados.valor.split(',');
            }

            // define valor
            body[param.dados.parametro] = valor;

            if(param.dados.tipo === 'objeto'){
                body[param.dados.parametro] = defineObjetoEnvioBody(param.filhos);
            }

            if(param.dados.tipo === 'objetoArray'){
                body[param.dados.parametro] = [defineObjetoEnvioBody(param.filhos)];
            }

        });

        // finaliza
        return body;

    }

    // pega os parametros da query para envio em objeto
    // retorna algo do tipo: {pagina: 1, registrosPorPagina: 10, tipo: [1, 2]}
    function pegarObjetoQuery(parametrosRaiz){
        
        // pega os parametros
        let parametrosQuery = {};

        parametrosRaiz
        .filter(parametrosQuery => !parametrosQuery.remover)
        .filter(parametroQuery => parametroQuery.dados.onde === 'query' && (parametroQuery.teste || parametroQuery.dados.obrigatorio === 'S'))
        .forEach(parametroQuery => {
            if(parametroQuery.dados.tipo === 'array'){
                parametrosQuery[parametroQuery.dados.parametro] = parametroQuery.dados.valor.split(',');
            }else{
                parametrosQuery[parametroQuery.dados.parametro] = parametroQuery.dados.valor;
            }
        });

        // retorna parametros
        return parametrosQuery;
    }

    // api altera e registro, reconfigura url de teste
    useEffect(() => {
        if(!carregando){
            ajustarUrlTeste();
            alterarObjetoBody(defineObjetoEnvioBody(parametros));
        }
    }, [dados, parametros]);

    // inicializa
    useEffect(() => {
        if(dados.id !== null){
            consultarId();
        }else{
            alterarCarregando(false);
        }
    }, []);

    // carregando registro
    if(carregando){
        return <p className="text-center">
            <FontAwesomeIcon icon={faSpinner} pulse /> Carregando
        </p>
    }

    return <CadastrarContexto.Provider value={{
        dados, alterarDados,
        parametros, alterarParametros,
        dadosUsuario, alterarDadosUsuario,
        pegarObjetoQuery, objetoBody,
        enderecoServidorUsuario, alterarEnderecoServidorUsuario,
        urlTeste, alterarUrlTeste
    }}>
        <CadastrarComponent>
            <Row>
                <Col>
                    {/* <Card className="border-0 mb-3">
                        <Card.Body>
                            <DescricaoApi />
                        </Card.Body>
                    </Card> */}

                    <Card className="border-0 mb-3">
                        <Card.Body>
                            <Form.Row>
                                <Col lg={2}>
                                    <ComboVersao 
                                        id={dados.versao}
                                        alterou={novaVersao => {
                                            dados.versao = novaVersao;
                                            alterarDados({...dados});
                                        }}
                                        defineUltima={true}
                                    />
                                </Col>
                                <Col lg={7}>
                                    <Form.Group>
                                        <Form.Control 
                                            placeholder="Breve descrição"
                                            value={dados.descricao}
                                            onChange={e => {
                                                dados.descricao = e.target.value;
                                                alterarDados({...dados});
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>     
                                    <ComboApisGrupos 
                                        id={dados.id_api_grupo}
                                        alterou={novoGrupo => {
                                            dados.id_api_grupo = novoGrupo;
                                            alterarDados({...dados});
                                        }}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col lg={3}>
                                    <Form.Control 
                                        as="select" 
                                        className="text-right"
                                        value={dados.metodo}
                                        onChange={e => {
                                            dados.metodo = e.target.value;
                                            alterarDados({...dados});
                                        }}
                                    >
                                        {['get', 'post', 'put', 'delete'].map(metodo =>
                                            <option key={metodo} value={metodo}>{metodo.toUpperCase()}</option>    
                                        )}
                                    </Form.Control>
                                </Col>
                                <Col lg={9}>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                        <Form.Control 
                                            placeholder="/exemplo/url"
                                            value={dados.url}
                                            onChange={e => {
                                                dados.url = e.target.value;
                                                alterarDados({...dados});
                                            }}
                                        />
                                        </div>
                                        <div className="ml-2">
                                            <Button 
                                                variant="padrao"
                                                onClick={salvar}
                                                disabled={salvando}
                                            >
                                                <FontAwesomeIcon 
                                                    icon={salvando ? faSpinner : faSave} 
                                                    pulse={salvando}
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                </Col>

                            </Form.Row>
                            <p className='bg-light rounded mt-2 p-2'>
                                {enderecoServidorUsuario}/api/v3{dados.url}<span className="text-secondary">?{urlTeste}</span>
                            </p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Tabs defaultActiveKey={aba} id="abas-apis" onSelect={novaAba => {alterarAba(novaAba)}} className="mb-3">
                <Tab eventKey="criacao" title="Edição">
                    { aba === 'criacao' && 
                        <AbaCriacao />
                    }
                </Tab>
                <Tab eventKey="teste" title="Testes">
                    { aba === 'teste' &&
                        <AbaTeste />
                    }
                </Tab>
            </Tabs>



        </CadastrarComponent>
    </CadastrarContexto.Provider>

}