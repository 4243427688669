import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';

import { ArquivoComponent } from "./components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { ProgressBar } from 'react-bootstrap';
import { faAddressBook, faCertificate, faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";

import { FileIcon, defaultStyles } from 'react-file-icon';

export default function Arquivo({ 
    arquivo,
    nomeArquivo,
    dados,
    removerArquivo
}){
        
    const [ext, alterarExtensao] = useState(null);
    defaultStyles.pem = {gradientColor: '#43accf', foldColor: '#cac34d', color: '#eaeaea', labelColor: '#cac34d', labelTextColor: '#eceff2'};

	useEffect(() => {
            
        // prepara regex para pegar a extensão
        const re = /(?:\.([^.]+))?$/;
        alterarExtensao(re.exec(arquivo.caminho || arquivo.upload.name)[1]);

	}, [arquivo])

	return <>
        <ArquivoComponent>
            {
                ext && 
                <>
                    <span className="icone">
                        <FileIcon extension={ext} {...defaultStyles[ext]} />
                    </span>
                    <span className="opcoes">
                        <span 
                            className="remover"
                            onClick={() => {
                                removerArquivo()
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash}/>
                        </span>
                    </span>
                </>
            }
            {
                arquivo.upload &&
                <span className="info">
                    <i>Enviar</i>
                    <ProgressBar 
                        variant={arquivo.erro ? 'danger' : 'success'} 
                        animated
                        now={arquivo.progresso || 0}
                        label={`${arquivo.progresso || 0}%`}
                    />
                </span>
            }
            {
                arquivo.caminho &&
                <a 
                    href={`${process.env.REACT_APP_URL_ARQUIVOS}/download.php?p=servidoresChaves&f=${nomeArquivo}&n=${encodeURIComponent(`${dados.nome}.${ext}`)}`}
                    target="_blank" 
                    className={['d-flex align-items-center justify-content-around info download'].join(' ')}
                >
                    <i>Download</i>
                    <span className="m-1 icon"><FontAwesomeIcon icon={faDownload}/></span>
                </a>
            }
        </ArquivoComponent>
	</>
};
