import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faArrowLeft, faArrowRight, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Card, Row, Col, Form, Button, Modal, Spinner, Alert, InputGroup } from 'react-bootstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { toast } from 'react-toastify';
import useIsMounted from 'ismounted';

import { copiaCampo, intersectObject } from '../../../Funcoes';
import {ModalCadastroGrupoComponent} from './components';

// retorna conteudo
export default function ModalCadastro({
    aoFechar=null,
    aoSalvar=null
}){
    
    let { path, url } = useRouteMatch();
    const { loginReducer } = useSelector(state => state);
    const isMounted = useIsMounted();
    const history = useHistory();
    const {id} = useParams(); // parametro url

    const [erros, alterarErros] = useState({});
    const [modo, alterarModo] = useState('cadastro'); // cadastro, edicao
    const dadosPadrao = {
        id: id || null,
        nome: null,
        chave: null,
        padrao: 'N'
    };
    
    let dadosSalvar = Object.assign({}, dadosPadrao);
    const [dadosIniciais, alterarDadosIniciais] = useState(dadosSalvar);
    const [dados, alterarDados] = useState(dadosSalvar);
    const [arquivo, alterarArquivo] = useState(null)
    const [salvando, alterarSalvando] = useState(false);
    const [carregando, alterarCarregando] = useState(false);
    const [mostrar, alterarMostrar] = useState(true);
    const [preencherHostPadrao, alterarPreencherHostPadrao] = useState(false);

    // referencias
    let refs = {
        chave: React.createRef()
    }

    // inicializa
    useEffect(() => {

        // verifica se é alteração
        if(id){
            alterarModo('edicao');
            consultarId();

        }else{

        }

    }, [id]);

    // consulta os dados do cliente
    async function consultarId(){
        alterarCarregando(true);

        try{
            
            // faz a requisição
            let { data } = await axios.get(`/gruposChaveGoogle/${dados.id}`);
            
            let dadosSalvar = {...data.dados}
            alterarDadosIniciais(dadosSalvar);
            alterarDados(dadosSalvar);

        }catch(e){
            console.log('pegou o erro')
        }finally{
            if (isMounted.current) {
                alterarCarregando(false);
            }
        }

    }

    // salva edição
    async function salvar(){
        alterarErros({});
        alterarSalvando(true);

        // faz a requisição
        try{

            // faz a requisição
            let { data } = await axios.post(`/gruposChaveGoogle`, {
                dados: intersectObject(dadosPadrao, dados)
            })

            // mensagem de cadastro realizado com sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Grupo salvo!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    Dados do grupo foram salvos.
                </div>
            </>);

            // executa callback
            if(aoSalvar){
                aoSalvar();
            }else{
                alterarMostrar(false);
            }

        }catch({response}){

            // erro
            if(response.status === 400){
                alterarErros(response.data);
            }

        }finally{
            if (isMounted.current) {
                alterarSalvando(false);
            }
            
        }

    }

    return <>
        <Modal
            className={['modal-personalizado'].join(' ')}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={mostrar}
            onHide={() => {
                alterarMostrar(false);
            }}
            onExited={() => {
                if(aoFechar){
                    aoFechar();
                }else{
                    history.push(`/painel/gruposChaveGoogle`, {refresh: true});
                }
            }}
            backdrop={dadosIniciais === dados ? true : 'static'}
        >
            <Modal.Header 
                closeButton 
                onHide={() => {
                    alterarMostrar(false);
                }}
            >
                <Modal.Title className={'text-center flex-grow-1'}>
                    {id ? dadosIniciais.nome : 'Adicionar novo Grupo'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ModalCadastroGrupoComponent>
                    {
                        carregando ?
                        <Col align="center">
                            <Spinner animation="border" />
                        </Col>
                        :
                        <Form.Group className={['formulario'].join(' ')}>
                            <Form.Row>
                                <Col className={'mb-2'}>
                                    <Form.Control 
                                        className={[styles.input].join(' ')}
                                        placeholder={"Nome"}
                                        value={dados.nome || ''}
                                        onChange={(e) => {
                                            alterarDados({...dados, nome: e.target.value});
                                        }}
                                    />
                                    {(erros.nome) && 
                                        <Alert className={[styles.alerta, 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.nome}</p></Alert>
                                    }
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    <InputGroup className="mb-2">
                                        <Form.Control 
                                            className={[styles.input].join(' ')}
                                            placeholder={"Chave"}
                                            ref={refs.chave}
                                            value={dados.chave || ''}
                                            onChange={(e) => {
                                                alterarDados({...dados, chave: e.target.value});
                                            }}
                                        />
                                        <InputGroup.Append>
                                            <Button  
                                                size="sm"
                                                className={[''].join(' ')}
                                                variant="outline-secondary"
                                                onClick={() => {
                                                    
                                                    // copia campo
                                                    copiaCampo(refs.chave.current);

                                                }}
                                            >
                                                <FontAwesomeIcon style={{fontSize: 15}} className="icone" icon={["fas", "copy"]}/>
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    {(erros.chave) && 
                                        <Alert className={[styles.alerta, 'my-1'].join(' ')} dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.chave}</p></Alert>
                                    }
                                </Col>
                            </Form.Row>
                            <Form.Row className="mb-1">
                                <Col>
                                    <Form.Check 
                                        type="switch"
                                        id="custom-switch-interno"
                                        label="Chave padrão (demos)"
                                        checked={dados.padrao === 'S'}
                                        onChange={e => {
                                            let novoValor = e.target.checked ? 'S' : 'N';
        
                                            alterarDados({...dados, padrao: novoValor});
                                        }}
                                    />
                                </Col>
                            </Form.Row>
                        </Form.Group>
                    }
                </ModalCadastroGrupoComponent>
            </Modal.Body>
            <Modal.Footer>
                <SalvarFormulario 
                    salvando={salvando}
                    modo={modo}
                    aoFechar={() => {
                        alterarMostrar(false);
                    }}
                    onClick={() => salvar(dados)}
                />
            </Modal.Footer>
        </Modal>
    </>
}

function SalvarFormulario({
    salvando,
    aoFechar,
    modo,
    onClick
}){
    return <Form.Group 
        className={[styles.linhaSalvar, 'd-flex justify-content-around my-2'].join(' ')}
    >
        <Button 
            variant="danger" 
            onClick={aoFechar}
        >Cancelar</Button>
        <Button 
            variant="success" 
            disabled={salvando} 
            onClick={onClick}
        >
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <>
                    <FontAwesomeIcon className="icone" icon={["fas", 'check']} />
                <span>{modo === 'edicao' ? 'Salvar' : 'Cadastrar'}</span>
                </>
            }
        </Button>
    </Form.Group>
}