import styled from 'styled-components';


export const FiltrosComponent = styled.div.attrs({
    className: ``
})`

    .filtros{
        flex-wrap: wrap;
    }
    
    .gap-2{
        gap: 0.5em;
    }

    .lista-menu-filtros{
        overflow: auto;
        max-height: 35vh;
    }

    .dropdown-menu{
        /* right: 0 !important;
        left: unset !important;
        transform: unset !important;
        top: 100% !important;
        margin-top: 2px!important; */
    }

    .btn-adicionar-filtro{
        font-size: 0.75em;
    }
`