import styled from 'styled-components';

export const CadastrarComponent = styled.div.attrs({
    className: ``
})`



`;

export const ParametrosComponent = styled.div.attrs({
    className: ``
})`

`