import { useParams } from 'react-router-dom';

import { Col, Row } from 'react-bootstrap';

import { ListaEmpresasEstatisticasComponent } from "./components";

import ListaEmpresasEstatisticasBase from './ListaEmpresasEstatisticasBase';
import ListaEmpresasEstatisticasUsoIntegracao from './ListaEmpresasEstatisticasUsoIntegracao';

export default function ListaEmpresasEstatisticas(){

    // parametros da url
    const {
        // idEmpresa = null
    } = useParams();

    return <ListaEmpresasEstatisticasComponent>
        <Row className='d-flex px-2' style={{gap: '0.825em'}}>
            <Col className='p-0'>
                <ListaEmpresasEstatisticasUsoIntegracao />
            </Col>
            <Col className='p-0'>
                <ListaEmpresasEstatisticasBase />
            </Col>
        </Row>
    </ListaEmpresasEstatisticasComponent>
    
}