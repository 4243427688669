import styled from 'styled-components';
import { Card } from 'react-bootstrap';
import { ListaComponent } from '../../../Componentes/components';

export const EmpresasDeletarBaseComponent = styled(ListaComponent).attrs({
    className: `painel-consulta border-0`
})`
    
    .tabela .registro, .legenda div{
        --cor-status-registro: black;
        
        &.cancelado{
            --cor-status-registro: var(--cor-cancelado);
        }
        
    }

    .tabela{

        .registro{
            
            .progress{
                font-weight: 500;
                
                .progress-bar{
                    background-color: var(--cor-status-registro);
                }
            }
            
            td{
                
                .destaque{
                    font-size: 13px;
                }

                pre{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 50vw;
                    margin: 0;
                }

                &:last-child{
                    text-align: right;
                }

                &.icone{
                    color: var(--cor-status-registro);
                    border-left: 3px solid var(--cor-status-registro);

                    padding: 0.25em 0.75em;
                }
            }
            
        }
        
    }

    .legenda{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5em;
        font-size: 0.8em;
        margin: 0.7em;

        div{
            display: flex;
            align-items: center;
            padding: 0 0.5em;
            border-radius: 0.3em;
            border-left: 3px solid;
            background: #fafafa;
            font-weight: 400;
            color: #434343;
            gap: 0.325em;

            border-color: var(--cor-status-registro);

            svg{
                color: var(--cor-status-registro);
            }
            
        }   
    }

`;