import axios from "axios";
import { Button, Col, Container, Form, InputGroup, Modal, OverlayTrigger, ProgressBar, Spinner, Tooltip } from "react-bootstrap";
import { FichaComponent } from "../../../Componentes/components";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFileSignature, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import ComboBancosDados from "../../../Componentes/ComboBancosDados";
import ComboServidores from "../../../Componentes/ComboServidores";

export default function ModalConfigurarDemos({
    mostrar,
    alterarMostrar
}){

    // contextos
    let history = useHistory();
    
    // dados padrão
    const dadosPadrao = {
        idServidorInstalacaoDemos: null,
        idBancoDadosInstalacaoDemos: null
    };

    // estados
    const [ dados, alterarDados ] = useState({
        ...dadosPadrao
    });
    const [ consultando, alterarConsultando ] = useState(true);
    const [ salvando, alterarSalvando ] = useState(false);
    
    useEffect(() => {
        
        if(mostrar){

            // consulta
            consultar();
        }
    }, [ mostrar ]);

    // função para consultar
    async function consultar(){

        // altera para consultando
        alterarConsultando(true);

        try{

            // consuta
            let { data } = await axios.get(`/instalar/configuracao/demos`);

            // pega os dados
            alterarDados(data);

        }catch(e){
            // erro ao consultar
        }finally{

            // finaliza
            alterarConsultando(false);
        }
    }

    // função para salvar
    async function salvar(){

        // altera o salvando
        alterarSalvando(true);

        try{

            // consuta
            await axios.post(`/instalar/configuracao/demos`, {
                ...dados
            });

            // sucesso

            // esconde
            alterarMostrar(false);

        }catch(e){
            // erros
            console.log(e)
        }finally{

            // finalizado
            alterarSalvando(false);
        }
    }

    return <>
        <Modal
            show={mostrar}
            centered
            onExited={() => {

                // fecha
                alterarMostrar(false);
            }}
            onHide={() => {

                // fecha
                alterarMostrar(false);
            }}
            size={'md'}
        >
            <Modal.Header className='border-0'>
                <div className={'d-flex w-100'}>
                    <Modal.Title 
                        as='h5' 
                        // className={[
                        //     'text-center w-100',
                        //     'text-success'
                        // ].join(' ')}
                        className={'flex-grow-1 text-center mb-0 text-success'}
                    >
                        Configurar instalação demos
                    </Modal.Title>
                </div>
            </Modal.Header>
            {mostrar && <Modal.Body className={'pt-0 px-1'}>
                <FichaComponent>
                    {
                        consultando ?
                        <Col align="center">
                            <Spinner animation="border" />
                        </Col>
                        :
                        <Container>
                            <Form.Row>
                                <Col>
                                    <Form.Label>Servidor</Form.Label>
                                    <ComboServidores
                                        className="select-ficha"
                                        placeholder="Servidor"
                                        id={dados.idServidorInstalacaoDemos}
                                        alterou={idServidorInstalacaoDemos => {
                                            alterarDados({...dados, idServidorInstalacaoDemos: (idServidorInstalacaoDemos !== '') ? idServidorInstalacaoDemos : null});
                                        }}
                                        filtros={{
                                            interno: ''
                                        }}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    <Form.Label>Banco</Form.Label>
                                    <ComboBancosDados
                                        className="select-ficha"
                                        id={dados.idBancoDadosInstalacaoDemos}
                                        alterou={idBancoDadosInstalacaoDemos => {
                                            alterarDados({...dados, idBancoDadosInstalacaoDemos: (idBancoDadosInstalacaoDemos !== '') ? idBancoDadosInstalacaoDemos : null});
                                        }}
                                    />
                                </Col>
                            </Form.Row>

                        </Container>
                    }
                </FichaComponent>
            </Modal.Body>}
            <Modal.Footer className='border-0 d-flex align-items-center justify-content-between'>
                <Button
                    size="sm"
                    variant='secondary'
                    onClick={() => {alterarMostrar(false)}}
                >
                    <span>Fechar</span>
                </Button>
                <Button
                    size="sm"
                    variant='success'
                    onClick={salvar}
                >
                    Salvar
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}