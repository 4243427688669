import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const ListaPedidosTesteHistoricoComponent = styled(Card).attrs({
    className: ``
})`
    font-size: 0.9em;
    border: none;
    box-shadow: 0px 0px 3px #ebebeb;

    .card-header{
        font-size: 0.93em;
        padding: 0.3em 1em;
        background: #fafafa;
        border: none;
        border-bottom: 1px solid #e9e9e9;
    }

    .card-body{
        padding: 0.2em;

        .tabela{
            margin-bottom: 0.5em;

            thead{

                tr{
                    th{
                        background: #ececec;
                        padding: 0.2em 0.5em;
                        color: #686868;
                        font-size: 0.87em;
                    }
                }
            }

            tr{

                td{
                    padding: 0.25rem 0.5em;
                    font-size: 0.95em;

                    &.plano-sistema{
                        font-size: 0.83em;
                        color: #4a4a4a;
                    }
                }
            }
        }

        .paginacao{

            button{
                font-size: 0.875em;
            }

            .total-registros{
                padding: 0.15em 0.6em !important;
                background-color: #92d3f0 !important;
                font-size: 0.875em;
            }
        }
    }
`;