import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import moment from 'moment';

// componentes
import { useSelector } from 'react-redux';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Row, Col, Form, Button, InputGroup, Tabs, Tab, Spinner} from 'react-bootstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import useIsMounted from 'ismounted';
import {
	PieChart, Pie, Sector, Cell,
} from 'recharts';

import TotaisAcessos from './TotaisAcessos';
import TotaisPesquisas from './TotaisPesquisas';

// contextos    
import { PainelContexto } from './../../../PainelContexto';

// função para o relatório de acessos
export default function TotaisGeral({
    parametros
}){
      
    // dados
    const permissaoReducer = useSelector(state => state.permissaoReducer);
    const isMounted = useIsMounted();
    const history = useHistory();
    const [parametrosTotais, alterarParametrosTotais] = useState(null);

    // inicializa
    useEffect(() => {
        
        // altera
        alterarParametrosTotais({
            ...parametrosTotais, 
            data_inicial: parametros.data_inicial, 
            data_final: parametros.data_final,
            somente_nao_possuem_token: parametros.somente_nao_possuem_token
        })

    }, [parametros.data_inicial, parametros.data_final, parametros.somente_nao_possuem_token]);

    // se ainda não carregou
    if(parametrosTotais === null){
        return <></>
    }

    return <>
        <Card className="border-0">
            <Card.Body>
                <Row>
                    {/* <div className='d-flex' style={{alignItems: 'initial'}}>
                        <div className='w-50'>
                            <TotaisAcessos
                                parametros={parametros}
                            />
                        </div>
                        <div className='w-50'>
                            <TotaisPesquisas
                                parametros={parametros}
                            />
                        </div>
                    </div> */}
                    
                    <Col 
                        className={[
                            styles.colDivisao
                        ].join(' ')}
                    >
                        <Row className='h-100'>
                            <Col>
                                <TotaisAcessos
                                    parametros={parametrosTotais}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <TotaisPesquisas
                                    parametros={parametrosTotais}
                                />
                            </Col>
                        </Row>
                    </Col> 
                </Row>
            </Card.Body>
        </Card>
    </>
}
