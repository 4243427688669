import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { useSelector } from 'react-redux';
import { Card, Form, Button, Row, Col, OverlayTrigger, Popover, Tooltip, Badge, Alert } from 'react-bootstrap';
import { BrowserView, MobileView, isMobile, isBrowser } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BotaoTabela from './../../../../Componentes/BotaoTabela';
import { converterReal } from './../../../../Funcoes';
import { faKey, faUserNinja } from '@fortawesome/free-solid-svg-icons';

// componente
export default function Registro({
    dados,
    empresa,
    statusOnline=false,
    clicouEditar,
    clicouEnviarEmail,
    clicouEnviarDadosAcesso,
    clicouLoginUsuario,
    mostrarInfoEmpresa=true
}){

    // // estados
    // let configuracao = useSelector(state => state.ConfiguracaoReducer);
    // const configuracaoUsuarioReducer = useSelector(state => state.ConfiguracaoUsuarioReducer);
    const [derrubando, alterarDerrubando] = useState(false);
    
    // derruba usuário
    async function derrubarUsuario(){
        alterarDerrubando(true);

        try{

            // faz a requisição
            let { data } = await axios.delete(`/usuarioLogin/${dados.id}`);

        }catch({response}){}
        finally{
            alterarDerrubando(false);
        }

    }

    // retorna opções
    let retornarOpcoes = () => {

        if(!empresa){
            console.log(empresa, dados)
        }
        // retorna componentes
        return <>

            {statusOnline &&
                <BotaoTabela 
                    carregando={derrubando}
                    onClick={() => {
                        derrubarUsuario();
                    }}
                    icone={["fas", "user-injured"]}
                >
                    <span>Derrubar</span>
                </BotaoTabela>
            }

            { empresa && (empresa.idVersao === null || parseInt(empresa.idVersao) >= 173) &&
                <BotaoTabela 
                    onClick={clicouLoginUsuario}
                    icone={faUserNinja}
                >
                    <span>Login com o usuário</span>
                </BotaoTabela>
            }
            <BotaoTabela 
                onClick={clicouEnviarEmail}
                icone={["fas", "envelope"]}
            >
                <span>Escrever Email</span>
            </BotaoTabela>
            <BotaoTabela 
                onClick={clicouEnviarDadosAcesso}
                icone={faKey}
            >
                <span>Enviar dados de acesso</span>
            </BotaoTabela>
            <BotaoTabela 
                onClick={clicouEditar}
                icone={["fas", "pencil-alt"]}
            >
                <span>Editar</span>
            </BotaoTabela>
        </>
    }

    return <> 

        {/* NAVEGADOR */}
        { isBrowser &&
            <tr
                style={{cursor: 'pointer'}}
                onClick={clicouEditar}
            >
                <td className={'estreito text-center font-weight-bold'}>
                    {dados.id}
                </td>
                <td className='estreito'>
                    <FontAwesomeIcon 
                        size='xs' 
                        icon={['fas', 'circle']} 
                        color={statusOnline ? '#1cd71c': '#ff5b5b' }
                    />
                </td>
                <td>
                    {(dados.master === 'S') ? <small><small>{
                        dados.masterPrincipal === 'S' ? <b>MASTER </b> : <b>GERÊNCIA </b>
                    }</small></small> : (
                        dados.terceirizado === 'S' && <small><small><b>TERCEIRIZADO </b></small></small>
                    )}
                    {dados.nome} ({dados.usuario})
                    {mostrarInfoEmpresa && <small>({empresa.razaoSocial})</small>}
                </td>
                <td className={'text-nowrap estreito text-right'}>
                    { retornarOpcoes() }
                </td>
            </tr>
        }

        {/* MOBILE */}
        {isMobile &&
            <Card
                className={'border-0 bg-light mb-2'}
                onClick={clicouEditar}
            >
                <Card.Body className={'p-2'}>
                    <div className={[isBrowser ? 'd-flex' : ''].join(' ')}>
                        <div className={'flex-grow-1'}>
                            <div>
                                <span>
                                    {dados.nome} ({dados.usuario})
                                </span>
                            </div>
                        </div>
                        <div className={'text-right'}>
                            { retornarOpcoes() }
                        </div>
                    </div>
                </Card.Body>
            </Card>
        }
    </>
}