import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';

// componentes
import { useSelector } from 'react-redux';
import { Card, Form, Button, Row, Col, OverlayTrigger, Popover, Tooltip, Badge, Alert } from 'react-bootstrap';
import { BrowserView, MobileView, isMobile, isBrowser } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BotaoTabela from './../../../../../Componentes/BotaoTabela';
import { converterReal } from './../../../../../Funcoes';
import { CardTrelloComponent } from './components';

// componente
export default function CardTrello({
    dados,
    membros,
    funcionarios,
    lista,
    etiquetas,
    responsavel
}){
    
    let retornarOpcoes = () => {

        // retorna componentes
        return <>
            {/* <BotaoTabela 
                onClick={clicouEnviarEmail}
                icone={["fas", "envelope"]}
            >
                <span>Escrever Email</span>
            </BotaoTabela>
            <BotaoTabela 
                onClick={clicouEditar}
                icone={["fas", "pencil-alt"]}
            >
                <span>Editar</span>
            </BotaoTabela> */}
        </>
    }

    // retorna a cor com base na cor
    function verificarCor(cor){

        // ajusta cores
        let cores = {
            background: cor || '#bcbcbc',
            color: 'white'
        };

        // altera as cores conforme o nome
        switch(cor){
            case 'sky': cores.background = '#00c2e0' ;break;
            case 'purple': cores.background = '#c377e0'; break;
            case 'yellow': cores.background = '#f2d600'; break;
            case 'red': cores.background = '#eb5a46'; break;
            case 'lime': cores.background = '#51e898'; break;
        }

        return cores;
    }

    return <CardTrelloComponent>
    
        {/* NAVEGADOR */}
        { isBrowser &&

            <Card.Body className='p-2'>
                <div className='etiquetas'>
                    {etiquetas.map(etiqueta =>
                        <Badge
                            key={etiqueta.id}
                            className='etiqueta'
                            style={{...verificarCor(etiqueta.cor)}}
                        >
                            {etiqueta.descricao}
                        </Badge>
                    )}
                </div>
                <div className='titulo'>
                    {dados.titulo}
                </div>
                <div className='membros'>
                    { membros.map(membro => 
                        <div
                            key={membro.dados.id}
                            className='membro'
                        >
                            { (membro.funcionario && membro.funcionario.foto) &&
                                <img src={`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/fotos/${membro.funcionario.foto}`} className='icone' />
                                // <img src={`${membro.avatar}/170.png`} className={styles.icone} />
                            }
                            { (responsavel && responsavel.id === membro.dados.id) &&
                                <FontAwesomeIcon className='icone responsavel' icon={["fas", "crown"]} />
                            }
                        </div>    
                    )}
                </div>
            </Card.Body>
        }

        {/* MOBILE */}
        {isMobile &&
            <Card.Body className={'p-2'}>
                <div className={[isBrowser ? 'd-flex' : ''].join(' ')}>
                    <div className={'flex-grow-1'}>
                        <div>
                            <span>
                                {dados.titulo}
                            </span>
                        </div>
                    </div>
                    <div className={'text-right'}>
                        { retornarOpcoes() }
                    </div>
                </div>
            </Card.Body>
        }
    </CardTrelloComponent>
}
