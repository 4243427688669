import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Route, useHistory, useLocation, useRouteMatch, useParams } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faDatabase, faFilter, faGear, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { toast } from 'react-toastify';
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { EmpresasDeletarBaseComponent } from "./components";

import Paginacao from '../../../Componentes/Paginacao';
import Confirmar from '../../../Componentes/Confirmar';
import ModalFiltros from './ModalFiltros';
import Registro from './Registro';
import ModalDeletarBase from '../../EmpresasBackups/ModalDeletarBase';

export default function EmpresasDeletarBase(){

    // pega location
    const history = useHistory();
    const location = useLocation();
    let { path, url } = useRouteMatch();

    // parametro url
    const {
        idEmpresa
    } = useParams(); 

    // estados
    const [ carregando, alterarCarregando ] = useState(false);
    const [ mostrarFiltros, alterarMostrarFiltros ] = useState(false);
    const [ parametros, alterarParametros ] = useState({
        registrosPorPagina: 8,
        pagina: 1,
        pesquisa: null,
        idEmpresa
    });
    const [ registros, alterarRegistros ] = useState([]);
    const [ totalRegistros, alterarTotalRegistros ] = useState(null);
    const [ confirmar, alterarConfirmar ] = useState(null);
    const [ idEmpresaDeletarBase, alterarIdEmpresaDeletarBase ] = useState(null);
    
    // referencias
    const refAbrirFiltros = useRef(null);

    // efeitos

    useEffect(() => {

        // prepara
        let pathsConsultar = [
            '/painel/servidores/empresasDeletarBase'
        ];
        let {
            aPartirDe
        } = location.state ?? {};

        // se retornar a essa rota
        if(pathsConsultar.includes(location.pathname)){

            // consulta
            consultar();
        }

    }, [location.pathname, parametros]);

    // função para consultar
    async function consultar(){

        // cria o carregamento
        alterarCarregando(true);

        try{

            // consulta
            let { data } = await axios.get(`/empresas/deletarBase`, {
                params: {
                    ...parametros
                }
            });

            // sucesso

            // pega os registros e total
            alterarRegistros(data.registros);
            alterarTotalRegistros(data.totalRegistros);

        }catch(e){
            // erro
        }finally{
            // finalizou

            // remove o carregamento
            alterarCarregando(false);
        }
    }

    // retorna registro
    function retornaRegistros(){

        // retorna registros organizado
        return registros.map(registro => 
            <Registro
                key={registro.dados.id}
                dados={registro.dados}
                arquivo={registro.arquivo}
                clicouAbrir={() => {

                    // abre o servidor da empresa
                    history.push(`/painel/empresas/editar/${registro.dados.id}/servidor`);
                }}
                clicouDeletarBase={() => {

                    // define para deletar / mostra modal 
                    alterarIdEmpresaDeletarBase(registro.dados.id);
                }}
            />
        )
    }

    return <EmpresasDeletarBaseComponent>
        
        {idEmpresaDeletarBase && <ModalDeletarBase
            idEmpresa={idEmpresaDeletarBase}
            mostrar={!!idEmpresaDeletarBase}
            instalarDemo={'S'}
            alterarMostrar={(mostrar) => {

                // verifica se é para esconder
                if(!mostrar){
                    alterarIdEmpresaDeletarBase(null);
                }
            }}
            aoFinalizar={() => {
    
                // limpa
                alterarIdEmpresaDeletarBase(null);

                // atualiza a lista
                alterarParametros({...parametros, pagina: 1})
            }}
        />}

        <ModalFiltros
            elemento={refAbrirFiltros.current}
            mostrar={mostrarFiltros}
            alterarMostrar={alterarMostrarFiltros}
            parametros={parametros}
            alterarParametros={alterarParametros}
        />
        <Confirmar config={confirmar} alterar={alterarConfirmar}/>
        
        <Card.Header className={'px-0'}>
            <div className={'d-flex'}>
                <Card.Title className={'flex-grow-1 d-flex align-items-center mb-0'}>
                    Empresas deletar base (cancelados)
                </Card.Title>
                <div className='d-flex' style={{gap: '0.5em'}}>
                    
                    
                    <Button
                        variant='light'
                        size='sm'
                        ref={refAbrirFiltros}
                        onClick={() => {

                            // define
                            alterarMostrarFiltros(true);
                        }}
                        style={{
                            fontSize: '0.9em',
                            boxShadow: '0 0 1px #b1b1b1',
                            border: 0,
                            display: 'flex',
                            gap: '0.2em',
                            alignItems: 'center'
                        }}
                    >
                        <FontAwesomeIcon className="icone" icon={faFilter} />
                        Filtrar
                    </Button>
                </div>
            </div>
        </Card.Header>
        <div>
            {
                carregando ? <div className="p-5 text-center">
                    <FontAwesomeIcon icon={faSpinner} pulse /> Carregando...
                </div> : (
                    <div
                        className={'my-3'}
                    >
                    <BrowserView>
                        <table className="table table-hover tabela">
                            {totalRegistros > 0 && <>
                                {/* <thead>
                                    <tr>
                                        <th className={'text-center'}></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead> */}
                                <tbody>
                                    { retornaRegistros() }
                                </tbody>
                            </>}
                        </table>
                    </BrowserView>

                    <MobileView>
                        { retornaRegistros() }
                    </MobileView>
                </div>)
            }
            <Paginacao
                pagina={parametros.pagina}
                registrosPorPagina={parametros.registrosPorPagina}
                totalRegistros={totalRegistros}
                alterouPagina={(pagina) => {
                    alterarParametros({...parametros, pagina: pagina});
                }}
            />

        </div>
        
    </EmpresasDeletarBaseComponent>
    
}