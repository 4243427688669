import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Row, Col, Form, Button, InputGroup, Tabs, Tab, Spinner} from 'react-bootstrap';
import { toast } from 'react-toastify';
import useIsMounted from 'ismounted';
import {
  BarChart, Brush, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer
} from 'recharts';

import GraficoBarra from './../GraficoBarra';
import Tabela from './Tabela';


// escreve o email
export default function CardEmpresas({
	parametros,
	selecionarEmpresa
}){

    // dados
    const permissaoReducer = useSelector(state => state.permissaoReducer);

    const [dados, alterarDados] = useState(null);
    const [carregando, alterarCarregando] = useState(false);
    const [mostrarTabela, alterarMostrarTabela] = useState(false);

    // consulta os dados das empresas
    async function consultar(){

		// inicia carregamento
		alterarCarregando(true);

        try{
            
            // faz a requisição
            let { data } = await axios.get(`/relatorios/acessos/empresas`, {
                params: parametros
            });
            
			alterarDados({...data});
			
        }catch(e){
            console.log('pegou o erro', e)
        }finally{
            alterarCarregando(false);
        }

	}

	const retornaDadosGrafico = (empresas) => {

		let dataGrafico = empresas.map((empresa) => {

			let dadosFormatado = {
				name: empresa.nome_fantasia,
				acessos: empresa.acessos,
				atualizacoes: empresa.atualizacoes_pagina,
				novas_guias: empresa.novas_guias
			}

			return dadosFormatado;
		})

		return dataGrafico;

	}
	
    // inicializa
    useEffect(() => {

		// consulta acessos de empresas
		consultar()
		
    }, [parametros]);

    return <>
		<Card className="border-0">
			<Card.Header className='mb-0 border-0 bg-white'>
				<Row>
					<Col>
						Acessos por empresa
					</Col>
					<Col sm={'auto'}>
						<Form.Check
							type="switch"
							id="switch-mostrar-tabela"
							label="Tabela"
							checked={mostrarTabela}
							onChange={e => {
								alterarMostrarTabela(!mostrarTabela);
							}}
						/>
					</Col>
				</Row>
			</Card.Header>
			<Card.Body>
				{
					carregando  &&
					<Col align="center">
						<Spinner animation="border" />
					</Col>
				}
				{
					(!carregando && dados) &&
					<>
						{
							dados.dados.length === 0 ?

							<Col align="center">
								Sem dados para o gráfico
							</Col> 
							:
							<>
								<GraficoBarra
									dadosGrafico={retornaDadosGrafico(dados.dados)}
									config={{
										clicavel: true
									}}
									aoSelecionar={(index) => {
										selecionarEmpresa(dados.dados[index].id_empresa)
									}}
								/>
								{
									mostrarTabela &&
									<Tabela
										dados={dados.dados}
									/>
								}
							</>
						}
					</>
				}
			</Card.Body>
		</Card>
    </>
}