import * as React from "react";

const SvgRelogioIcone = (props) => {
  props = {
    width: 15.5,
    height: 15.5,
    ...props,
  };
  return (
    <svg viewBox="0 0 128 128" {...props}>
      <style>{".relogio-icone_svg__st28{fill:#8ab0c1}"}</style>
      <g id="relogio-icone_svg__Camada_2">
        <g id="relogio-icone_svg__XMLID_330_">
          <radialGradient
            id="relogio-icone_svg__XMLID_2_"
            cx={62}
            cy={64}
            r={60}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.049} stopColor="#7a9cab" />
            <stop offset={0.284} stopColor="#80a1b0" />
            <stop offset={0.587} stopColor="#90b0be" />
            <stop offset={0.82} stopColor="#a1c0cd" />
            <stop offset={0.878} stopColor="#8eafbd" />
            <stop offset={0.945} stopColor="#7fa1b0" />
            <stop offset={1} stopColor="#7a9cab" />
          </radialGradient>
          <circle
            id="relogio-icone_svg__XMLID_118_"
            cx={62}
            cy={64}
            r={60}
            fill="url(#relogio-icone_svg__XMLID_2_)"
          />
          <circle
            cx={61.8}
            cy={64}
            r={51.9}
            fill="#f2f2f2"
            id="relogio-icone_svg__XMLID_117_"
          />
          <linearGradient
            id="relogio-icone_svg__XMLID_3_"
            gradientUnits="userSpaceOnUse"
            x1={61.565}
            y1={64.559}
            x2={62.449}
            y2={96.633}
          >
            <stop offset={0.049} stopColor="#7a9cab" />
            <stop offset={0.195} stopColor="#80a1b0" />
            <stop offset={0.381} stopColor="#90b0be" />
            <stop offset={0.525} stopColor="#a1c0cd" />
            <stop offset={0.678} stopColor="#8eafbd" />
            <stop offset={0.855} stopColor="#7fa1b0" />
            <stop offset={1} stopColor="#7a9cab" />
          </linearGradient>
          <path
            id="relogio-icone_svg__XMLID_116_"
            d="M59.9 65.4l.5 29.4s2.2 2.9 3.8 0l-.6-28.9h-.2c-.5.2-.6.5-2.1.3-.4 0-.9-.3-1.4-.8z"
            fill="url(#relogio-icone_svg__XMLID_3_)"
          />
          <linearGradient
            id="relogio-icone_svg__XMLID_7_"
            gradientUnits="userSpaceOnUse"
            x1={64.713}
            y1={63.92}
            x2={96.032}
            y2={63.92}
          >
            <stop offset={0.049} stopColor="#7a9cab" />
            <stop offset={0.195} stopColor="#80a1b0" />
            <stop offset={0.381} stopColor="#90b0be" />
            <stop offset={0.525} stopColor="#a1c0cd" />
            <stop offset={0.678} stopColor="#8eafbd" />
            <stop offset={0.855} stopColor="#7fa1b0" />
            <stop offset={1} stopColor="#7a9cab" />
          </linearGradient>
          <path
            id="relogio-icone_svg__XMLID_115_"
            d="M64.7 65.4l30.2-.2s2.7-1.3-.2-2.9l-29.8.5s.2.6.2 1.3c-.1.5-.4 1.3-.4 1.3z"
            fill="url(#relogio-icone_svg__XMLID_7_)"
          />
          <g id="relogio-icone_svg__XMLID_109_">
            <g id="relogio-icone_svg__XMLID_110_">
              <g id="relogio-icone_svg__XMLID_113_">
                <path
                  transform="rotate(-45.001 55.47 57.287)"
                  className="relogio-icone_svg__st28"
                  d="M55 39.3h1v35.9h-1z"
                  id="relogio-icone_svg__XMLID_114_"
                />
              </g>
              <circle
                className="relogio-icone_svg__st28"
                cx={68.1}
                cy={69.7}
                r={0.8}
                id="relogio-icone_svg__XMLID_112_"
              />
              <circle
                className="relogio-icone_svg__st28"
                cx={43.2}
                cy={44.9}
                r={1}
                id="relogio-icone_svg__XMLID_111_"
              />
            </g>
          </g>
          <g id="relogio-icone_svg__XMLID_108_">
            <circle
              className="relogio-icone_svg__st28"
              cx={62}
              cy={64}
              r={3.4}
            />
            <path
              className="relogio-icone_svg__st28"
              d="M62 67.8c-2.1 0-3.8-1.7-3.8-3.8 0-2.1 1.7-3.8 3.8-3.8 2.1 0 3.8 1.7 3.8 3.8 0 2.1-1.7 3.8-3.8 3.8zm0-6.7c-1.6 0-2.9 1.3-2.9 2.9 0 1.6 1.3 2.9 2.9 2.9s2.9-1.3 2.9-2.9c0-1.6-1.3-2.9-2.9-2.9z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgRelogioIcone;
