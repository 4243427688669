import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import stylesGeral from './../index.module.scss';
import axios from 'axios';

// componentes
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { Card, Row, Col, Form, Button, InputGroup, Tabs, Tab} from 'react-bootstrap';
import InputDataCombo from './../../../../Componentes/InputDataCombo';
import { converterReal } from './../../../../Funcoes';

import FormularioPadrao from './../FormularioPadrao';
import ComboServidores from './../../../../Componentes/ComboServidores';

// retorna conteudo
export default function FormularioFinanceiro({
    dados={},
    alterarDados,
    funcaoSalvar,
    salvando,
    modo
}){

    const history = useHistory();
    const permissaoReducer = useSelector(state => state.permissaoReducer);

    const tipoAdesao = [
        {value: 'midiaLocal', label: 'Mídia Local'},
        {value: 'ispShop', label: 'Isp Shop'},
        {value: 'regresso', label: 'Regresso'},
    ]

    // função para montar informação do servidor
    function infoServidor(dados){

        // prepara o padrão
        let info = 'Servidor Alugado';

        // verifica se é próprio
        if(dados.servidorProprio === 'S'){
            info = 'Servidor Próprio';
        }else if(dados.enderecoServidorProprioAcesso){
            // servidor próprio na amazon
            info = 'Cavalo de Troia';
        }

        // verifica se é demo
        if(['demo'].includes(dados.situacao)){

            // servidor de teste
            info = 'Servidor teste'
        }

        // retorna
        return info;
    }

    return <>
        <Row>
            <Col md='8' className='mb-4'>
                <Card className='border-0 shadow-sm'>
                    <Card.Header 
                        className='mb-0 border-0 bg-white'
                    >
                        <Card.Title className={['pb-0 mb-0'].join(' ')}>
                            <span>Dados Financeiro</span>
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <FormularioPadrao {...{
                            dados,
                            alterarDados,
                            funcaoSalvar,
                            salvando,
                            modo,
                            edicaoLiberada: false
                        }}/>
                        <Form.Row className='mb-2'>
                            <Col>
                                <Form.Label>Nome fantasia</Form.Label>
                                <Form.Control 
                                    size="sm"
                                    disabled={true}
                                    value={dados.nomeFantasia || ''}
                                    onChange={e => {
                                        alterarDados({...dados, nomeFantasia: e.target.value});
                                    }}
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row className='mb-2'>
                            <Col md='7'>
                                <Form.Label>Responsável</Form.Label>
                                <Form.Control
                                    size="sm" 
                                    value={dados.responsavelFinanceiro || ''}
                                    onChange={e => {
                                        alterarDados({...dados, responsavelFinanceiro: e.target.value});
                                    }}
                                />
                            </Col>
                            <Col>
                                <Form.Label>Mensalidade</Form.Label>
                                <Form.Control 
                                    size="sm"
                                    value={dados.valorMensalidade ? converterReal(dados.valorMensalidade) : ''}
                                    onChange={e => {
                                        alterarDados({...dados, valorMensalidade: e.target.value});
                                    }}
                                />
                            </Col>
                            <Col>
                                <Form.Label>Dia vencimento</Form.Label>
                                <Form.Control 
                                    size="sm"
                                    value={dados.diaVencimento || ''}
                                    onChange={e => {
                                        alterarDados({...dados, diaVencimento: e.target.value});
                                    }}
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row className='mb-2'>
                            <Col md='7'>
                                <Form.Label>Email</Form.Label>
                                <Form.Control 
                                    size="sm"
                                    value={dados.emailFinanceiro || ''}
                                    onChange={e => {
                                        alterarDados({...dados, emailFinanceiro: e.target.value});
                                    }}
                                />
                            </Col>
                            <Col>
                                <Form.Label>Telefone</Form.Label>
                                <Form.Control 
                                    size="sm"
                                    value={dados.telefoneFinanceiro || ''}
                                    onChange={e => {
                                        alterarDados({...dados, telefoneFinanceiro: e.target.value});
                                    }}
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row className='mb-2'>
                            <Col>
                                {/* servidor alugado */}
                                <Form.Group className='mb-0'>
                                    <Form.Label>Servidor de aluguel</Form.Label>
                                    {
                                        dados.servidorProprio === 'S' ? <Form.Control
                                            size="sm"
                                            disabled={true} 
                                            value={infoServidor(dados) || ''}
                                        /> : <ComboServidores
                                            className="select-ficha"
                                            id={dados.idServidorAluguel || null}
                                            disabled={true}
                                            config={{
                                                pagina: null,
                                                registrosPorPagina: null
                                            }}
                                        />
                                    }
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Label>Adesão</Form.Label>
                                <Select
                                    className="select-ficha"
                                    options={tipoAdesao}
                                    defaultValue={dados.tipoAdesao ? tipoAdesao.find((e) => e.value === dados.tipoAdesao) : tipoAdesao[0]}
                                    isMulti={false}
                                    placeholder='Adesão'
                                    onChange={(e) => {
                                        // altera os filtros selecionados
                                        alterarDados({...dados, tipoAdesao: e.value});
                                    }}
                                />
                            </Col>
                            <Col md='4'>
                                <Form.Label></Form.Label>
                                <Form.Check 
                                    type="switch"
                                    id="custom-switch-notaFiscal"
                                    label="Nota fiscal"
                                    checked={dados.notaFiscal === 'S'}
                                    onChange={e => {
                                        let novoValor = e.target.checked ? 'S' : 'N';
    
                                        alterarDados({...dados, notaFiscal: novoValor});
                                    }}
                                />
    
                                <Form.Check 
                                    type="switch"
                                    id="custom-switch-taxa-boleto"
                                    label="Taxa boleto"
                                    checked={dados.taxaBoleto === 'S'}
                                    onChange={e => {
                                        let novoValor = e.target.checked ? 'S' : 'N';
    
                                        alterarDados({...dados, taxaBoleto: novoValor});
                                    }}
                                />
                            </Col>
                        </Form.Row>
                        <hr/>
                        <Form.Row className='mb-2'>
                            <Col>
                                <Form.Label>Responsável Técnico</Form.Label>
                                <Form.Control 
                                    size="sm"
                                    value={dados.responsavelTecnico || ''}
                                    onChange={e => {
                                        alterarDados({...dados, responsavelTecnico: e.target.value});
                                    }}
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row className='mb-2'>
                            <Col md='7'>
                                <Form.Label>Email Técnico</Form.Label>
                                <Form.Control 
                                    size="sm"
                                    value={dados.emailTecnico || ''}
                                    onChange={e => {
                                        alterarDados({...dados, emailTecnico: e.target.value});
                                    }}
                                />
                            </Col>
                            <Col>
                                <Form.Label>Telefone Técnico</Form.Label>
                                <Form.Control 
                                    size="sm"
                                    value={dados.telefoneTecnico || ''}
                                    onChange={e => {
                                        alterarDados({...dados, telefoneTecnico: e.target.value});
                                    }}
                                />
                            </Col>
                        </Form.Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col md='4'>
                <Card className='border-0 shadow-sm'>
                    <Card.Header 
                        className='mb-0 border-0 bg-white'
                    >
                        <Card.Title>
                            Observações
                        </Card.Title>
                    </Card.Header>
                    <Card.Body className='pt-1'>
                        <Row className="m-1 my-2">
                            <Form.Label>Adesão</Form.Label>
                            <textarea 
                                style={{minHeight: 50, fontSize: 14}}
                                value={dados.infoLicenca || ''}
                                onChange={e => {
                                    alterarDados({...dados, infoLicenca: e.target.value});
                                }}
                                className="form-control" 
                            ></textarea>
                        </Row>
                        <Row className="m-1 my-2">
                            <Form.Label>Mensalidade</Form.Label>
                            <textarea 
                                style={{minHeight: 50, fontSize: 14}}
                                value={dados.infoMensalidade || ''}
                                onChange={e => {
                                    alterarDados({...dados, infoMensalidade: e.target.value});
                                }}
                                className="form-control" 
                            ></textarea>
                        </Row>
                        <Row className="m-1 my-2">
                            <Form.Label>Comercial</Form.Label>
                            <textarea 
                                style={{minHeight: 100, fontSize: 14}}
                                value={dados.obsComercial || ''}
                                onChange={e => {
                                    alterarDados({...dados, obsComercial: e.target.value});
                                }}
                                className="form-control" 
                            ></textarea>
                        </Row>
                    </Card.Body>
                </Card>
                {dados.campanha && <Card className='border-0 shadow-sm mt-2'>
                    <Card.Body className='pt-1'>
                        <Row>
                            <Col>
                                <Form.Label className='font-weight-bold'>Campanha</Form.Label>
                                <Form.Control 
                                    size="sm"
                                    disabled={true}
                                    value={dados.campanha || ''}
                                    onChange={e => {
                                        alterarDados({...dados, campanha: e.target.value});
                                    }}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>}
                {dados.evento && <Card className='border-0 shadow-sm mt-2'>
                    <Card.Body className='pt-1'>
                        <Row>
                            <Col>
                                <Form.Label className='font-weight-bold'>Evento</Form.Label>
                                <Form.Control 
                                    size="sm"
                                    disabled={true}
                                    value={dados.evento || ''}
                                    onChange={e => {
                                        alterarDados({...dados, evento: e.target.value});
                                    }}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>}
            </Col>
        </Row>
        <Row>
            <Col md='8'>
                <SalvarFormulario 
                    salvando={salvando}
                    modo={modo}
                    cancelar={() => {
                        history.goBack();
                    }}
                    onClick={() => funcaoSalvar(dados)}
                />
            </Col>
        </Row>
    </>
}

function SalvarFormulario({
    salvando,
    cancelar,
    modo,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button 
            variant="success" 
            disabled={salvando} 
            onClick={onClick}
        >
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <>
                    <FontAwesomeIcon className="icone" icon={["fas", 'check']} />
                <span>{modo === 'edicao' ? 'Salvar' : 'Cadastrar'}</span>
                </>
            }
        </Button>
    </Form.Group>
}