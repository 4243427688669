import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { Card, Table, Button, Badge, InputGroup, FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Confirmar from './../../../Componentes/Confirmar';
import Paginacao from './../../../Componentes/Paginacao';

import Registro from './Registro';
import ParametrosConsulta from './ParametrosConsulta';
// import ModalExcluir from './ModalExcluir';

// contextos    
import { PainelContexto } from './../../PainelContexto';

export default function Consultar(){

    // estados
    let painelContextoDados = useContext(PainelContexto);
    let { permissaoReducer } = useSelector(state => state);
    let history = useHistory();
    const location = useLocation();
    let { path, url } = useRouteMatch();
    let [carregando, alterarCarregando] = useState(false);
    let [totalRegistros, atualizarTotalRegistros] = useState(0);
    let [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: isMobile ? 3 : 10,
        interno: '',
        texto: ''
    });
    let [consulta, alterarConsulta] = useState([]);
    let [mostrarFiltros, alterarMostrarFiltros] = useState(false);
    const [confirmar, alterarConfirmar] = useState(null);

    // inicializa
    useEffect(() => {
        
        // faz a consulta
        fazerConsulta();

    }, [parametros]);

    useEffect(() => {

        // se retornar a essa rota
        if(location.pathname === "/painel/servidores"){
            fazerConsulta();
        }
    }, [location.pathname]);

    // faz consulta
    async function fazerConsulta(){

        // carregando
        alterarCarregando(true);

        // faz a requisição
        try{
            let { data } = await axios.get('/servidores', {
                params: parametros
            });

            // finalizado
            alterarConsulta(data.registros);
            atualizarTotalRegistros(data.totalRegistros)

        }catch(e){

        }finally{
            alterarCarregando(false);
        }
    }
    
    // faz a exclusão
    async function deletar(dados){

        // faz a requisição
        try{

            let { data } = await axios.delete(`/servidores/${dados.id}`);

        }catch({response}){

            console.log(response);

            toast(<>
                <div
                    style={{background: '#ff6271'}}
                >
                    <div className="toast-header">
                        <strong className="mr-auto">Servidor não pôde ser deletado</strong>
                        <button 
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                        </button>
                    </div>
                    <div className="toast-body text-light">
                        {
                            (response && response.data) &&
                            Object.keys(response.data).map((erro) => <p className={'m-0'} key={erro}>{response.data[erro]}<br/></p>)
                        }
                    </div>
                </div>
            </>);

        }finally{
            alterarCarregando(false);

            // atualiza registros
            fazerConsulta();
        }

    }

    function clicouExcluir(registro){

        // confirma
        alterarConfirmar({
            aberto: true,
            titulo: 'Deletar servidor',
            texto: `Tem certeza que deseja deletar este servidor?`,
            textoBotao: 'Deletar',
            variantConfirmar: 'danger',
            variantCancelar: 'secondary',
            aoConfirmar: () => {
                // deleta o registro
                deletar(registro.dados);
            },
            aoCancelar: () => {
                // ao cancelar
            },
            aoFechar: () => {
                // ao fechar
            }
        })
    }

    // retorna registro
    let retornaRegistros = registro => {

        // retorna registros organizado
        return consulta.map(registro => 
            <Registro 
                key={registro.dados.id}
                dados={registro}
                clicouEditar={() => {
                    history.push(`${path}/editar/${registro.dados.id}`);
                }}
                clicouExcluir={() => {
                    clicouExcluir(registro)
                }}
                mostrarDownload={registro.dados.arquivoChave ? true : false}
                clicouDownload={() => {
                    const re = /(?:\.([^.]+))?$/;
                    const ext = re.exec(registro.dados.arquivoChave)[1];

                    // faz o download
                    window.open(
                        `${process.env.REACT_APP_URL_ARQUIVOS}/download.php?p=servidoresChaves&f=${registro.dados.arquivoChave}&n=${encodeURIComponent(`${registro.dados.nome}.${ext}`)}`, 
                        '_blank'
                    );
                }}
            />
        )
    }

    return <>
    
        <Confirmar config={confirmar} alterar={alterarConfirmar}/>
        <Card className="painel-consulta border-0" >
            <Card.Header>
                <div className={'d-flex '}>
                    <Card.Title className={'flex-grow-1'}>Servidores</Card.Title>
                    <div>
                        <Button
                            variant='success'
                            size='sm'
                            className='ml-1'
                            onClick={() => {
                                history.push('/painel/servidores/cadastrar');
                            }}
                        >
                            <FontAwesomeIcon className="icone" icon={["fas", "plus"]} />
                            <span>Novo</span>
                        </Button>
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                <ParametrosConsulta 
                    parametros={parametros}
                    alterarParametros={alterarParametros}
                    mostrarFiltros={mostrarFiltros}
                    alterarMostrarFiltros={alterarMostrarFiltros}
                />

                <div 
                    className={'my-3'}
                >
                    <BrowserView>
                        <table className="table table-hover tabela">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Servidor</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                { retornaRegistros() }
                            </tbody>
                        </table>
                    </BrowserView>

                    <MobileView>
                        { retornaRegistros() }
                    </MobileView>
                </div>
                
                <Paginacao
                    pagina={parametros.pagina}
                    registrosPorPagina={parametros.registrosPorPagina}
                    totalRegistros={totalRegistros}
                    alterouPagina={(pagina) => {
                        alterarParametros({...parametros, pagina: pagina});
                    }}
                />
            </Card.Body>
        </Card>
    </>
}