import styled from 'styled-components';


export const FichaComponent = styled.div.attrs({
    className: ``
})`
    align-self: center;
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;

    .alerta{
        font-size: 12px;
        padding: 5px 10px !important;
        border-radius: 5px;

        button{
            padding: 4px 2px !important;
            font-size: 20px;
        }

        p{
            margin: 0;
        }
    }

    .card-body{
        padding-top: 0.5rem;
    }
    
    .form-label, .form-check-label{
        font-size: 0.8rem;
    }

    .form-check{
        font-size: 0.9rem;
    }

    .form-control{
        min-height: unset;
        height: 31px;
    }

    .custom-switch{
        font-size: 0.9rem;
        padding-left: 2.25em;

        .custom-control-input{
            width: 1em;
            height: 1.25em;
            
            &:checked ~ .custom-control-label::after{
                transform: translateX(0.75em);
            }
        }
        .custom-control-label{
            &::before{    
                top: 0.25em;
                left: 1.5em;
                display: block;
                width: 1em;
                height: 1em;
                
                left: -2.25em;
                width: 1.75em;
                border-radius: 0.5em;
                
            }

            &::after{
                top: calc(0.25em + 2px);
                left: calc(-2.25em + 2px);
                width: calc(1em - 4px);
                height: calc(1em - 4px);
                border-radius: 0.5em;
            }
        }
    }
    
    .form-group{
        margin-bottom: 2px;
    }

    .input-group-prepend{

        button{
            z-index: 2 !important;
        }
    }

    .select-ficha, .aba-usuarios .select{
        font-size: 0.875rem;

        > div:nth-of-type(1){
            padding: 0;
            min-height: unset;

            > div{
                padding: 0 8px;

                &:last-child{
                    padding-right: 4px;
                }

            }

            > div:nth-of-type(2){
                > div{
                    padding: 0 8px;
                }
            }
        }
    }

    input{
        &:focus{
            z-index: unset !important;
        }
    }

`