import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';

// componentes
import OptionSelect from './OptionSelect';

// combo para consulta de grupos de api
export default function ComboBancosDados({
    id=null,
    valor=null,
    alterou,
    defineUltima=false,
    className='',
    styles=null,
    disabled=false
}){

    // estados
    let [consulta, alterarConsulta] = useState([]);
    let [dadosRegistro, alterarDadosRegistro] = useState(null);
    let [carregando, alterarCarregando] = useState(true);
    let [definidoUltima, alterarDefinidoUltima] = useState(false);

    // consultar grupos de api
    async function consultarRegistros(inputValue, callback = null){

        alterarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/bancosDados`, { params: { 
                pesquisa: inputValue,
                pagina: 1,
                registrosPorPagina: 20
            }});

            // altera os dados
            alterarConsulta([...data.registros]);
            if(callback !== null){
                callback(data.registros);
            }

            // define a última
            if(defineUltima && !definidoUltima && id === null){

                // pega o primeiro registro
                alterarDadosRegistro({...data.registros[0]});
                alterou(data.registros[0].dados.id, {...data.registros[0]});
                alterarDefinidoUltima(true);
            }

        }catch({response}){}
        finally{
            alterarCarregando(false);
        }

    }

    // consulta membro
    async function consultarId(id){
        alterarCarregando(true);
        
        try{
            
            // faz a consulta do id
            let { data } = await axios.get(`/bancosDados/${id}`);
            alterarDadosRegistro(data);

        }catch({response}){

        }finally{
            alterarCarregando(false);
        }
    }

    // pre carrega algumas versões disponiveis
    useEffect(() => {

        consultarRegistros('');

        // verifica se possui usuário
        if(id){

            // consulta os dados do membro selecionado
            consultarId(id);

        }
    }, []);

    // ao alterar valor
    useEffect(() => {

        // verifica se removido
        if(!valor){

            // altera
            alterarDadosRegistro(null);
            return;
        }

        // verifica se alterou
        if(!dadosRegistro || valor !== dadosRegistro.dados.id){

            // tenta encontrar o valor nos registros atuais
            let registroValor = consulta.find((r) => r.dados.id === valor);

            // verifica se encontrou
            if(registroValor){

                // altera
                alterarDadosRegistro(registroValor);
            }else{

                // consulta pelo id e preenche
                consultarId(valor);
            }
        }
        
    }, [valor]);

    return <AsyncSelect 
        isClearable
        isLoading={carregando}
        loadOptions={consultarRegistros}
        defaultOptions={consulta}
        components={{
            Option: OptionSelect
        }}
        value={dadosRegistro && {
            id: dadosRegistro.dados.id,
            label: <>{dadosRegistro.dados.nome || <i>Sem nome</i>} <small>({dadosRegistro.dados.nome})</small></>
        }}
        onChange={(e) => {
            
            if(e === null){
                alterarDadosRegistro(null);
                alterou(null, null);
            }else{
                
                alterarDadosRegistro({...e});
                alterou(e.dados.id, {...e});
            }
        }}
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => 'Digite algo para fazer a consulta'}
        placeholder='Banco'
        styles={styles ? styles : {}}
        className={className}
        isDisabled={disabled}
    />
    
}
