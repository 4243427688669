import moment from "moment";
import { Card } from "react-bootstrap";
import { isMobile, isBrowser } from "react-device-detect";

export default function Registro({
    dados,

    clicouVisualizar
}){

    // retorna
    return <> 

        {/* NAVEGADOR */}
        { isBrowser &&
            <tr
                style={{cursor: 'pointer'}}
                onClick={clicouVisualizar}
                className={['registro'].join(' ')}
            >
                <td className={'estreito text-center'}>
                    {moment(dados.dataPedido).format('DD/MM/YYYY')}
                </td>
                <td className='estreito text-center'>
                    {moment(dados.dataFimTeste).format('DD/MM/YYYY')}
                </td>
                <td className='estreito text-center plano-sistema'>
                    {
                        dados.planoSistema == 'light' ? 'Light' : 'Completa'
                    }
                </td>
            </tr>
        }

        {/* MOBILE */}
        {isMobile &&
            <Card
                className={'border-0 bg-light mb-2'}
                onClick={clicouVisualizar}
            >
                <Card.Body className={'p-2'}>
                    <div className={[''].join(' ')}>
                        <div className={'flex-grow-1'}>
                            <div>
                                <span>
                                    {moment(dados.dataPedido).format('DD/MM/YYYY')}
                                </span>
                            </div>
                            <div>
                                <span>
                                    {moment(dados.dataFimTeste).format('DD/MM/YYYY')}
                                </span>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        }
    </>
}