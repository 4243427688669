import { faFileAlt, faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Badge, Card } from "react-bootstrap";
import { isBrowser, isMobile } from "react-device-detect";

import BotaoTabela from "../../../../Componentes/BotaoTabela";

export default function Registro({
    dados,
    dadosEmpresa,
    dadosUsuario,
    dadosIntegracaoSistema,
    empresaPossuiIntegracaoSistema,

    clicouAbrir,
    clicouEditar,
    clicouCancelar,
    clicouExcluir
}){

    // retorna opções
    let retornarOpcoes = () => {

        // retorna componentes
        return <>
            <BotaoTabela
                onClick={clicouAbrir}
                icone={faFileAlt}
            >
                <span>Abrir ficha da empresa</span>
            </BotaoTabela>
            <BotaoTabela 
                onClick={clicouEditar}
                icone={["fas", "pencil-alt"]}
            >
                <span>Editar pedido</span>
            </BotaoTabela>
            {
                (dados.concluido === 'N' && dados.cancelado === 'N') && <BotaoTabela
                    onClick={clicouCancelar}
                    icone={faTimes}
                >
                    <span>Cancelar</span>
                </BotaoTabela>
            }
            {
                (dados.concluido === 'N' && dados.cancelado === 'S') && <BotaoTabela
                    onClick={clicouExcluir}
                    icone={faTrashAlt}
                >
                    <span>Excluir</span>
                </BotaoTabela>
            }
        </>
    }

    return <> 

        {/* NAVEGADOR */}
        { isBrowser &&
            <tr
                style={{cursor: 'pointer'}}
                onClick={clicouEditar}
                className={[
                    'registro',
                    dados.concluido == 'S' ? 'concluido' : (
                        dados.cancelado === 'S' ? 'cancelado' : (
                            empresaPossuiIntegracaoSistema === 'S' ? 'processo' : 'aguardo'
                        )
                    )
                ].join(' ')}
            >
                <td className={'estreito text-center font-weight-bold integracao'}>
                    <Badge className={['rounded-pill'].join(' ')} style={{fontWeight: 800}}>
                        {dadosEmpresa.codigo}
                    </Badge>
                </td>
                <td>
                    <div>
                        {dadosEmpresa.razaoSocial}
                        <small className="ml-1">
                            ({dadosEmpresa.nomeFantasia})
                        </small>
                        <Badge className={['rounded-pill mx-1'].join(' ')} variant="secondary">
                            {dadosIntegracaoSistema.nome}
                        </Badge>
                        {
                            (
                                dados.concluido == 'N'
                                && dados.cancelado === 'N'
                                && empresaPossuiIntegracaoSistema === 'S'
                            ) && <small><b>(Integrando)</b></small>
                        }
                    </div>
                    <small>
                        <span className="mr-1"><b>Pedido em: </b> {moment(dados.dataCadastro).format('DD/MM/YYYY HH:mm')}</span>
                        {
                            dados.cancelado === 'S' && <span className="mr-1">
                                <b>Cancelado em: </b> {moment(dados.dataCancelado).format('DD/MM/YYYY HH:mm')}
                            </span>
                        }
                        <span><b>Por: </b> {dadosUsuario.nome} ({dadosUsuario.usuario} - {dadosUsuario.permissao})</span>
                    </small>
                </td>
                <td className={'text-nowrap estreito text-right'}>
                    { retornarOpcoes() }
                </td>
            </tr>
        }

        {/* MOBILE */}
        {isMobile &&
            <Card
                className={'border-0 bg-light mb-2'}
                onClick={clicouEditar}
            >
                <Card.Body className={'p-2'}>
                    <div className={[isBrowser ? 'd-flex' : ''].join(' ')}>
                        <div className={'flex-grow-1'}>
                            <div>
                                <span>
                                    {dadosEmpresa.razaoSocial}
                                </span>
                            </div>
                        </div>
                        <div className={'text-right'}>
                            { retornarOpcoes() }
                        </div>
                    </div>
                </Card.Body>
            </Card>
        }
    </>
}