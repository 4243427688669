import styled from 'styled-components';
import { BrowserView, MobileView, isMobile, isBrowser } from "react-device-detect";


export const CardTrelloComponent = styled.div.attrs({
    className: isMobile ? 'card border-0 bg-light mb-2' : 'card border-0 mb-3'
})`

    position: relative;
    width: 100%;
    break-inside: avoid-column;
    -webkit-column-break-inside: avoid;
    box-shadow: 0 2px 3px #e1e1e1;
    

    .etiquetas {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;

        .etiqueta {
            color: rgb(255, 255, 255);
            font-size: 0.7rem;
            white-space: nowrap;
            -webkit-font-smoothing: antialiased;
            font-weight: 700;
            margin-bottom: 0.1rem;
            margin-left: 0.1rem;
        }
    }

    .titulo {
        font-size: 0.8rem;
        color: #515151;
    }

    .membros {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        margin-top: 0.10rem;

        .membro {
            margin-left: 0.25rem;
            position: relative;

            .icone {
                width: 30px;
                height: 30px;
                min-width: 30px;
                border-radius: 15px;
                box-shadow: 0 0 2px #7e7e7e;
            }

            .responsavel {
                position: absolute;
                top: -8px;
                right: 0;
                left: 0;
                margin: auto;
                color: gold;
                filter: drop-shadow(0px 0px 2px black);
                font-size: 0.7rem;
            }
        }

    }

`