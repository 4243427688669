import { faEye } from "@fortawesome/free-solid-svg-icons";

import BotaoTabela from "../../../../../Componentes/BotaoTabela";

export default function Registro({
    dados,
    clicouSelecionar,
    clicouVisualizar
}){

    // retorna opções
    let retornarOpcoes = () => {

        // retorna componentes
        return <>
            <BotaoTabela 
                onClick={clicouVisualizar}
                icone={faEye}
            >
                <span>Visualizar</span>
            </BotaoTabela>
        </>
    }

    return <tr
        style={{cursor: 'pointer'}}
        onClick={clicouSelecionar}
        className={['registro'].join(' ')}
    >
        <td>
            <div className="d-flex align-items-center" style={{gap: 3}}>
                {dados.titulo}
            </div>
        </td>
        <td className={'text-nowrap estreito'}>
            { retornarOpcoes() }
        </td>
    </tr>
}