import React from 'react';
import styles from './index.module.scss';
import { useHistory } from 'react-router-dom';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';

// componente
export default function Funcao({
    dados
}){


    // estados
    const history = useHistory();

    //variaveis
    let descricao = dados.dados.funcao;
    let imagem = null;
    let urlRedirecionar = null;
    let icone = ["fas", "quote-left"];
    switch(dados.dados.funcao){
        case 'funcionario/permissao': descricao = 'Abriu permissões de funcionário'; break;
        case 'cliente/consultar': descricao = 'Consultou clientes'; break;
        case 'funcionario/consultar': descricao = 'Consultou funcionários'; break;
        case 'relatorioGeral/funil': descricao = 'Consultou histórico'; break;
        
    }

    return <>
        <Card
            className={['bg-light border-0 m-1', styles.funcao].join(' ')}
        >
            <Card.Body
                className={['align-items-center p-2'].join(' ')}
            >
                <div
                    className={styles.funcaoDescricao}
                >
                    { (imagem === null) ?
                        <FontAwesomeIcon className={styles.icone} icon={icone}></FontAwesomeIcon>
                        :
                        <img 
                            src={imagem.url} 
                            className={[
                                styles.imagem,
                                urlRedirecionar === null ? '' : styles.redirecionar 
                            ].join(' ')} 
                            onClick={urlRedirecionar === null ? undefined : () => {
                                history.push(urlRedirecionar);
                            }}
                        />
                    }
                    
                    <div className={styles.texto}>
                        {descricao}
                    </div>
                </div>
            </Card.Body>
        </Card>
    </>

}