import { faClock, faDatabase, faFile, faShare } from "@fortawesome/free-solid-svg-icons";
import { isBrowser, isMobile } from "react-device-detect";
import { Badge, Card, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BotaoTabela from "../../../../Componentes/BotaoTabela";
import { formatarTamanhoBytes } from "../../../../Funcoes";
import moment from "moment";

export default function Registro({
    dados,
    dadosEmpresa,

    clicouAbrir
}){

    // retorna opções
    let retornarOpcoes = () => {

        // retorna componentes
        return <>
            <BotaoTabela 
                onClick={clicouAbrir}
                icone={faFile}
            >
                <span>Abrir</span>
            </BotaoTabela>
        </>
    }

    // prepara o ícone
    let classe = dadosEmpresa.situacao;

    // verifica se é light
    if(dadosEmpresa.planoSistema == 'light'){

        // adicona
        classe += 'light';
    }

    // pega a diferença de dias
    let diferencaDias = moment().diff(moment(dados.dataCadastro, 'YYYY-MM-DD'), "days");

    // verifica se é hoje
    if(diferencaDias == 0){

        // define a descrição
        diferencaDias = 'hoje';
    }else{

        // define a descrição
        diferencaDias = `há ${diferencaDias} dia(s)`;
    }

    // prepara
    let info = <></>;
    
    // verifica se encontrou erros
    if(!!dados.erro){
        
        // define a descrição
        info = <>
            <b>Ocorreram erros ao instalar...</b> <pre>{dados.erro}</pre>
        </>;
    }


    return <> 

        {/* NAVEGADOR */}
        { isBrowser &&
            <tr
                style={{cursor: 'pointer'}}
                onClick={clicouAbrir}
                className={['registro', classe].join(' ')}
            >
                <td className={'icone estreito text-center font-weight-bold'}>
                    {dadosEmpresa.codigo}
                </td>
                <td>
                    <div className="descricao">
                        <span className="destaque">{!!dados.erro && <b>[ERRO] | </b>}<b>{dadosEmpresa.razaoSocial}</b> (<small>{dadosEmpresa.cnpj}</small>)</span>
                        <small>
                            <small className="line-small">Em: {moment(dados.dataCadastro).format('DD/MM/YYYY')} <small>- <b>{diferencaDias}</b></small></small><br/>
                            {info}
                        </small>
                    </div>
                </td>
                <td className={'text-nowrap estreito'}>
                    { retornarOpcoes() }
                </td>
            </tr>
        }

        {/* MOBILE */}
        {isMobile &&
            <Card
                className={'border-0 bg-light mb-2'}
            >
                <Card.Body className={'p-2'}>
                    <div className={[isBrowser ? 'd-flex' : ''].join(' ')}>
                        <div className={'flex-grow-1'}>
                            <div className="d-flex align-items-center" style={{gap: 3}}>
                                <span className="destaque"><b>{dadosEmpresa.razaoSocial}</b> (<small>{dadosEmpresa.cnpj}</small>)</span><br/>
                                <small>Em: {moment(dados.dataCadastro).format('DD/MM/YYYY')}</small>
                            </div>
                        </div>
                        <div className={'text-right'}>
                            { retornarOpcoes() }
                        </div>
                    </div>
                </Card.Body>
            </Card>
        }
    </>
}