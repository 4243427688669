export default function Registro({
    indice,
    totais,
    dadosUsuario,
    dadosEmpresa
}){

    return <> 

        <tr className={['registro'].join(' ')}>

            <td className={'text-center font-weight-bold indice'}>
                {indice}
            </td>

            <td className="descricao">
                <div className="d-flex align-items-center" style={{gap: 3}}>
                    {
                        dadosUsuario ? <>
                            {dadosUsuario.nome} <small>({dadosUsuario.usuario} - {dadosUsuario.permissao})</small>
                        </> : <>
                            <i>Usuário não encontrado</i>
                        </>
                    }
                </div>
                <div className="d-flex align-items-center" style={{gap: 3}}>
                    <small>{
                        dadosEmpresa ? <>
                            {dadosEmpresa.razaoSocial} <small>({dadosEmpresa.codigo})</small>
                        </> : <>
                            <i>Sem empresa</i>
                        </>
                    }</small>
                </div>
                <div className="totais">
                    <small className="total geral">
                        <b>Total: </b> {totais.pesquisas}
                    </small>
                    <small className="total interno">
                        <b>Interno: </b> {totais.pesquisasRetornoInternoEncontradoAceito}
                    </small>
                    <small className="total interno-nao-aceito">
                        <b>Interno (não aceito): </b> {totais.pesquisasRetornoInternoEncontrado - totais.pesquisasRetornoInternoEncontradoAceito}
                    </small>
                    <small className="total retornoReutilizado">
                        <b>Retorno reutilizado: </b> {totais.pesquisasRetornoReutilizadoEncontrado}
                    </small>
                    <small className="total google">
                        <b>Google: </b> {totais.pesquisasRetornoGoogleEncontrado}
                    </small>
                    <small className="total nao-encontrado">
                        <b>Não encontrado: </b> {totais.pesquisasRetornoNaoEncontrado}
                    </small>
                </div>
            </td>
        </tr>
    </>
}