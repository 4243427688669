import React from 'react';

// comopnentes
import { Col, Form } from 'react-bootstrap';
import ComboModeloEmail from './../../../../Componentes/ComboModeloEmail';
import InputDataCombo from './../../../../Componentes/InputDataCombo';
import InputHora from './../../../../Componentes/InputHora';

function InfoAdicional({
    dados,
    alterarDados,
    alterouIdModelo,
}){


    return <>
        { dados.conteudo === null &&
            <Form.Group>
                <ComboModeloEmail 
                    id={null}
                    alterou={alterouIdModelo}
                />
            </Form.Group>
        }

        <Form.Group>
            <Form.Label>Agendar</Form.Label>
            <Form.Row>
                <Col>
                    <InputDataCombo 
                        data={dados.agendado_para_data}
                        onChange={(novaData) => {
                            dados.agendado_para_data = novaData;
                            alterarDados({...dados});
                        }}
                    />
                </Col>
                <Col>
                    <InputHora 
                        value={dados.agendado_para_hora}
                        onChange={novaHora => {
                            dados.agendado_para_hora = novaHora;
                            alterarDados({...dados});
                        }}
                    />
                </Col>
            </Form.Row>
        </Form.Group>

        <Form.Group>
            <Form.Label>Responder Para</Form.Label>
            <Form.Control 
                placeholder='responder_para@email.com'
                value={dados.responder_para || ''}
                onChange={e => {
                    dados.responder_para = e.target.value;
                    alterarDados({...dados});
                }}
            />
        </Form.Group>
    </>

}

export default InfoAdicional;