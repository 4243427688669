import React, { useState, useEffect } from 'react';
import './LoginPagina.scss';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

// componentes
import { Link, useHistory } from 'react-router-dom';
import useIsMounted from 'ismounted';
import { Container } from 'react-bootstrap';
import { Alert, Button } from 'react-bootstrap';
import backGroundImage from './placeimg_640_480_tech.jpg';

export default function Login(){

    // estados
    const history = useHistory();
    const isMounted = useIsMounted();
    const dispatch = useDispatch();
    const [carregando, alterarCarregando] = useState(false);
    const [dados, alterarDados] = useState({
        login: '',
        senha: ''
    });
    const [erros, alterarErros] = useState({});
    const [lembrar, alterarLembrar] = useState(false);
    const loginReducer = useSelector(state => state.loginReducer);
    
    // faz login
    async function clicouEntrar(){
        alterarCarregando(true);

        try {

            // faz a requisição
            let { data } = await axios.post('/login', dados);
            
            // finaliza login
            loginFinalizado(data);

        }catch({response}){

            if(response){

                // erro
                if(response.status === 400){
                    alterarErros(response.data);
                }
            }
        }finally{
            if (isMounted.current) {
                alterarCarregando(false);
            }
        }

    }

    // ao finalizar login
    function loginFinalizado(dadosUsuario){
        
        // altera
        dispatch({
            type: 'loginReducer/LOGIN',
            dadosUsuario: dadosUsuario.dados
        });
        dispatch({
            type: 'loginReducer/TRELLO',
            trello: dadosUsuario.trello
        });
    }

    // retorna componente
    return <div className="container-fluid login-pagina">
        <div className="row no-gutter">
            <div 
                className="d-none d-md-flex col-md-4 col-lg-6 bg-image" 
                style={{
                    background: `linear-gradient(rgba(0, 0, 0, 0.45),rgba(0, 0, 0, 0.45)),url(${backGroundImage})`,
                    backgroundSize: `cover`
                }}
            >
                {/* <img src={logoEvolutts} /> */}
            </div>
            <div className="col-md-8 col-lg-6">
                <div className="login d-flex align-items-center py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-lg-8 mx-auto">
                            <h3 className="login-heading mb-4">Bem vindo!</h3>
                                <div className="form-label-group">
                                    <input 
                                        type="text" id="inputEmail"
                                        className={["form-control", (erros.login) ? 'input-erro' : ''].join(' ')} 
                                        placeholder="Endereço de email" 
                                        required autoFocus
                                        value={dados.login}
                                        onChange={(e) => {
                                            dados.login = e.target.value;
                                            alterarDados({...dados});
                                        }}
                                        onKeyDown={e => {
                                            if(e.keyCode === 13) clicouEntrar(e);
                                        }}
                                    />
                                    <label htmlFor="inputEmail">Login</label>
                                    { erros.login && 
                                        <Alert className="alerta-erro" dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.login}</p></Alert>
                                    }
                                    
                                </div>

                                <div className="form-label-group">
                                    <input 
                                        id="inputConfirmPassword"
                                        type="password"
                                        className={["form-control", (erros.senha) ? 'input-erro' : ''].join(' ')}
                                        placeholder="Senha" required 
                                        value={dados.senha}
                                        onChange={(e) => {
                                            dados.senha = e.target.value;
                                            alterarDados({...dados});
                                        }}
                                        onKeyDown={e => {
                                            if(e.keyCode === 13) clicouEntrar(e);
                                        }}
                                    />
                                    <label htmlFor="inputConfirmPassword">Senha</label>
                                    {(erros.senha) && 
                                        <Alert className="alerta-erro" dismissible onClose={() => alterarErros({})} variant="danger"><p>{erros.senha}</p></Alert>
                                    }
                                </div>

                                {/* <div className="custom-control custom-checkbox mb-3">
                                    <input type="checkbox" className="custom-control-input" id="customCheck1"/>
                                    <label className="custom-control-label" htmlFor="customCheck1">Lembrar</label>
                                </div> */}
                                <button 
                                    onClick={clicouEntrar} 
                                    className="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2" 
                                    type="submit"
                                    disabled={carregando}
                                >
                                    {carregando && 
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    }
                                    {carregando ? ' Carregando' : 'Entrar' }
                                </button>

                                <p className='text-center mt-3'>
                                    <Button
                                        variant='success'
                                        className='rounded-pill  px-4'
                                        size='sm'
                                        onClick={() => {
                                            history.push('/cadastro');
                                        }}
                                    >
                                        <span>Cadastrar</span>
                                    </Button>
                                </p>
                                <div className="text-center">
                                    <Link className="small" to="/cadastro">Esqueceu a senha?</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}