import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

// components
import { ConsultarComponent } from './components';
import { Card, Badge, Container, Row, Col, Button } from 'react-bootstrap';
import Api from './Api';
import ParametrosConsulta from './ParametrosConsulta';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function Consultar({

}){

    // estados
    const history = useHistory();
    const [parametros, alterarParametros] = useState({    
    });
    const [consulta, alterarConsulta] = useState();
    const [carregando, alterarCarregando] = useState(true);

    // faz a consulta
    async function fazerConsulta(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/apis/agrupadas`);
            console.log(data);
            alterarConsulta(data.grupos);

        }catch({response}){}
        finally{
            alterarCarregando(false);
        }

    }

    // inicia
    useEffect(() => {
        fazerConsulta();
    }, []);

    // carregando
    if(carregando){
        return <p className="text-center">
            <FontAwesomeIcon icon={faSpinner} pulse /> Carregando
        </p>
    }

    return <>
        <ConsultarComponent>
            <Row className={'justify-content-center'}>
                <Col xl={8}>
                    <Card className="painel-consulta border-0">
                        <Card.Header className="bg-white border-0">
                            <div className={'d-flex '}>
                                <Card.Title className={'flex-grow-1'}>Documentação da Api</Card.Title>
                                <div>
                                    <Button
                                        onClick={() => {
                                            history.push(`/painel/documentacao-api/grupos`);
                                        }}
                                        size="sm"
                                    >
                                        Grupos
                                    </Button>
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body className="pt-0">
                            <ParametrosConsulta 
                                parametros={parametros}
                                alterarParametros={alterarParametros}
                            />
                            {consulta.map(grupo => 
                                <Api 
                                    key={grupo.dados && grupo.dados.id}
                                    dados={grupo.dados}
                                    registros={grupo.registros}
                                />
                            )}

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </ConsultarComponent>
    </>

}