
import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import useIsMounted from 'ismounted';
import moment from 'moment';

import { Button, Col, Form, InputGroup, Modal, Spinner, Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JSONPretty from 'react-json-pretty';
import ReactSelect from 'react-select';

import { copiaCampo } from '../../../../Funcoes';
import { FichaComponent } from '../../ListaGoogleChaves/ModalEditar/components';
import { ModalVisualizarEnderecoPesquisaComponent } from './components';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

// modal para visualizar o json
function ModalVisualizarJSON({
    mostrar,
    alterarMostrar,
    titulo,
    json
}){
    
    return <Modal 
        show={mostrar}
        centered
        onExited={() => {}}
        onHide={() => {
            alterarMostrar(false);
        }}
        size={'md'}
    >
        <Modal.Header className='border-0'>
            <Modal.Title 
                as='h5' 
                className={[
                    'text-center w-100',
                    'text-success'
                ].join(' ')}
            >
                {titulo}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'pt-0'}>
            <JSONPretty
                id="json-info"
                data={json}
                space="4"
                mainStyle='color: #3f8a98;background: #f7f7f7;padding: 3px;border-radius: 3px;max-height: 80vh;overflow: auto;'
            />
        </Modal.Body>
    </Modal>
}

// campos para mostrar o endereço do retorno 
function RetornoEndereco({
    indice,
    tipo,
    dados
}){

    // pega o endereço
    let endereco = dados.endereco;
    let ref = useRef();

    // verifica o tipo de retorno
    switch(tipo){
        case 'interno':
            break;
        default:
            endereco = dados.formatted_address;
            break;
    }

    // retorna
    return <InputGroup>
        <Form.Control
            ref={ref}
            size={'sm'}
            value={endereco || ''}
            disabled
        />
        <InputGroup.Append>
            <Button 
                size="sm"
                className={[].join(' ')}
                variant="outline-secondary"
                onClick={() => {

                    // pega o campo
                    let campo = ref.current
                    copiaCampo(campo);

                }}
            >
                <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "copy"]}/>
            </Button>
        </InputGroup.Append>
    </InputGroup>
}

// painel para visualizar dados do log
export default function ModalVisualizarEnderecoPesquisa(){

    // dados
    const isMounted = useIsMounted();
    const { path, url } = useRouteMatch();
    const history = useHistory();

    // parametros da url
    const {
        id = null
    } = useParams();

    // estados
    const [ mostrar, alterarMostrar ] = useState(true);
    const [ carregando, alterarCarregando ] = useState(false);
    const [ salvando, alterarSalvando ] = useState(false);
    const [ dados, alterarDados ] = useState({});
    const [ dadosEmpresa, alterarDadosEmpresa ] = useState({});
    const [ dadosUsuario, alterarDadosUsuario ] = useState({});
    const [ retornos, alterarRetornos ] = useState([]);
    const [ visualizarJSON, alterarVisualizarJSON ] = useState(null);
    const [ tabRetorno, alterarTabRetorno ] = useState(null);

    // prepara campos
    const camposRef = {
        nomeUsuario: useRef(),
        requisicao: useRef(),
        conteudo: useRef(),
        dados: useRef(),
        retornoRegistrosEndereco: {}
    };

    // prepara array com permissões
    const permissoes = [
        {value: 'projetista', label: 'Projetista'},
        {value: 'tecnico', label: 'Técnico'},
        {value: 'vendedor', label: 'Vendedor'},
        {value: 'viabilidade', label: 'Viabilidade'}
    ];

    // prepara
    const encontradoEm = [
        {label: 'Interno', value: 'interno'},
        {label: 'Retorno reutilizado', value: 'retornoReutilizado'},
        {label: 'Google (chave cliente)', value: 'googleUsadoGoogleChaveCliente'},
        {label: 'Google', value: 'google'},
        {label: 'Não encontrado', value: 'naoEncontrado'}
    ];

    // inicializa
    useEffect(() => {
        
        // consulta
        consultarId();
    }, []);
    
    // consulta dados do usuário
    async function consultarId(){

        // cria o carregamento
        alterarCarregando(true);

        try{
            
            // faz a requisição
            let { data } = await axios.get(`/enderecos/pesquisas/${id}`);
            
            // define os dados
            alterarDados({...data.dados});
            alterarDadosEmpresa({...data.dadosEmpresa});
            alterarDadosUsuario({...data.dadosUsuario});
            alterarRetornos([...data.retornos]);

        }catch(e){
            console.log('pegou o erro')
        }finally{
            if (isMounted.current) {
                alterarCarregando(false);
            }
        }

    }

    // nenhum dado
    if(!dados) return <></>;

    return <>
        <ModalVisualizarJSON
            mostrar={!!visualizarJSON}
            alterarMostrar={(m) => {
                if(!m){

                    alterarVisualizarJSON(null)
                }
            }}
            titulo={`Informações retorno`}
            json={visualizarJSON}
        />
        {!visualizarJSON && <ModalVisualizarEnderecoPesquisaComponent
            show={mostrar}
            centered
            onExited={() => {

                // monta a url para retroceder
                let urlBack = url.split("/enderecos/pesquisas").map((parte, i) => i > 0 ? '/enderecos/pesquisas' : parte).join('');
                
                // volta e dá refresh pra atualizar a listagem
                history.push(urlBack, {
                    aPartirDe: 'visualizarEnderecoPesquisa'
                });
                
                // fecha
                alterarMostrar(false);
            }}
            onHide={() => {

                // fecha
                alterarMostrar(false);
            }}
            size={'lg'}
        >
            <Modal.Header className='border-0'>
                <Modal.Title 
                    as='h5' 
                    className={[
                        'text-center w-100',
                        'text-success'
                    ].join(' ')}
                >
                    <div className='d-flex flex-column'>
                        Pesquisa de endereço
                        <small>{moment(dados.dataCadastro).format('DD/MM/YYYY HH:mm:ss')}</small>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={'pt-0'}>
                {
                    carregando ?
                    <Col align="center">
                        <Spinner animation="border" />
                    </Col>
                : <FichaComponent>
                    <Form.Row>
                        <Col>
                            {
                                dadosEmpresa && <>
                                    <Form.Row>
                                        <Col>
                                            <Form.Label>Empresa</Form.Label>
                                            <Form.Control
                                                size={'sm'}
                                                value={dadosEmpresa.razaoSocial || ''}
                                                disabled
                                            />
                                        </Col>
                                    </Form.Row>
                                </>
                            }
                            {
                                dadosUsuario && <>
                                <Form.Row>
                                    <Col>
                                        <Form.Label>Usuário</Form.Label>
                                        <Form.Control
                                            ref={camposRef.nomeUsuario}
                                            size={'sm'}
                                            value={dadosUsuario.nome || ''}
                                            disabled
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label>Permissão</Form.Label>
                                        <ReactSelect
                                            size={'sm'}
                                            className="select-ficha"
                                            options={permissoes}
                                            defaultValue={permissoes.filter((e) => dadosUsuario.permissao === e.value)[0]}
                                            isMulti={false}
                                            placeholder='Permissão'
                                            isDisabled
                                        />
                                    </Col>
                                </Form.Row>
                            </>}
                            <Form.Row>
                                <Col>
                                    <div className='d-flex align-items-center justify-content-between my-1'>
                                        <Form.Label className='mb-0'>Dados pesquisa</Form.Label>
                                        <Button 
                                            size="sm"
                                            className={[].join(' ')}
                                            variant="outline-secondary"
                                            onClick={() => {

                                                // pega o campo
                                                let campo = camposRef.dados.current;
                                                copiaCampo(campo);

                                            }}
                                        >
                                            <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "copy"]}/>
                                        </Button>
                                    </div>
                                    <Form.Control
                                        ref={camposRef.dados}
                                        className='d-none'
                                        value={dados.dados ?? ''}
                                        disabled
                                    />
                                    <JSONPretty
                                        id="json-info-dados"
                                        data={dados.dados ?? null}
                                        space="4"
                                        mainStyle='color: #3f8a98;background: #f7f7f7;padding: 5px;border-radius: 3px;max-height: 180px;overflow: auto;'
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    <div className='d-flex align-items-center justify-content-between my-1'>
                                        <Form.Label className='mb-0'>Retorno</Form.Label>
                                    </div>
                                    <div className='conteudo-retorno'>
                                        <Tabs      
                                            id="tabs-retornos-endereco-pesquisa"
                                            className={['tabs'].join(' ')}
                                            activeKey={tabRetorno ?? (
                                                (retornos[0] ?? {tipo: null}).tipo === 'google' && retornos[0].usadoGoogleChaveCliente === 'S' ? `googleUsadoGoogleChaveCliente` : (retornos[0] ?? {tipo: null}).tipo
                                            )}
                                            onSelect={(t) => alterarTabRetorno(t)}
                                        >
                                            {
                                                retornos.map((retorno) => {

                                                    // pega o título do retorno
                                                    let tipoRetorno = retorno.tipo === 'google' && retorno.usadoGoogleChaveCliente === 'S' ? `googleUsadoGoogleChaveCliente` : retorno.tipo;
                                                    let titulo = encontradoEm.find((e) => e.value === tipoRetorno).label;
                                                    
                                                    return <Tab
                                                        key={tipoRetorno}
                                                        eventKey={tipoRetorno}
                                                        // disabled={retorno.status != 'ok'}
                                                        title={titulo}
                                                    >
                                                        <h6>{titulo}</h6>
                                                        {
                                                            retorno.status === 'ok' ? <>
                                                                <Form.Row>
                                                                    <Col>
                                                                        <div className='d-flex align-items-center justify-content-between mb-1'>
                                                                            <Form.Label className='mb-0'>Endereço</Form.Label>
                                                                            <Button 
                                                                                size="sm"
                                                                                className={[].join(' ')}
                                                                                variant="light"
                                                                                onClick={() => {

                                                                                    // define
                                                                                    alterarVisualizarJSON(retorno.registros);
                                                                                }}
                                                                            >
                                                                                <FontAwesomeIcon className="icone" color={"#646464"} icon={faInfoCircle}/>
                                                                            </Button>
                                                                        </div>
                                                                        {
                                                                            retorno.registros.map((registro, i) => {

                                                                                let key = `retorno-${tipoRetorno}-registro-${i}`;
                                                                                return <RetornoEndereco
                                                                                    key={key}
                                                                                    indice={i}
                                                                                    tipo={tipoRetorno}
                                                                                    dados={registro}
                                                                                />
                                                                            })
                                                                        }
                                                                    </Col>
                                                                </Form.Row>
                                                            </> : <div className="info-sem-registros">
                                                                Sem registros
                                                            </div>
                                                        }
                                                    </Tab>
                                                })
                                            }
                                        </Tabs>
                                    </div>
                                </Col>
                            </Form.Row>
                        </Col>
                    </Form.Row>
                </FichaComponent>}
            </Modal.Body>
        </ModalVisualizarEnderecoPesquisaComponent>}
    </>
}
