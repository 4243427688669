import styled from 'styled-components';
import { Card, Modal } from 'react-bootstrap';

export const ListaLogsApiComponent = styled(Card).attrs({
    className: `painel-consulta border-0 shadow-sm`
})`
    
    .card-header{
        padding: 0;
        font-size: 0.9em;

        .h5{
            font-size: 1.25em;
        }

        .btn{
            padding: 0.25em 0.5em;
            font-size: 0.875em;
        }
    }

    .card-body{
        padding-top: 0.5rem;
    }

    .form-control{
        min-height: unset;
        height: 31px;
    }

    .btn{
        padding: 0.25em 0.5em;
        font-size: 0.875em;
        line-height: 1.5;
        border-radius: 0.2em;
    }

    .card{

        > .card-header{
            border-left: 3px solid var(--cor-cliente-ficha) !important;
        }

        .card{
            > .card-header{
                border-left: unset !important;
            }
        }
    }

    .tabela{
        width: 100%;
        border-radius: 0.3em;
        border-collapse: separate;
        font-size: 0.95em;
        margin-top: 0.75em;
        -webkit-border-horizontal-spacing: 0.2em;
        -webkit-border-vertical-spacing: 0.25em;

        &:empty{
            position: relative;
            min-height: 260px;

            &::after{
                content: "Sem registros";
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                color: grey;
                background: #fbfbfb;
                border-radius: 0.3em;
                box-shadow: 0px 0px 1px inset #cacaca;
            }
        }
        
        .registro{
            --cor-status-registro: black;
            /* display: flex;
            align-items: flex-start; */
            font-size: 0.9em;
            border-radius: 0.3em;
            position: relative;

            &.registro-divisor-data{
                font-weight: 600;
                height: fit-content;

                td{
                    height: fit-content;
                    font-size: 0.8em;
                    text-align: center;
                    border-bottom: 1px solid #c3c0c0;
                    border-left: 1px solid #c3c0c0;
                    padding: 0 5px;

                    &:hover{
                        background: #fdfdfd;
                    }
                }
            }

            &:first-child{
                margin-top: 0;
            }

            &.get{
                --cor-status-registro: #816ecd;
            }

            &.post{
                --cor-status-registro: #5da218;
            }

            &.put{
                --cor-status-registro: #d17401;
            }

            &.delete{
                --cor-status-registro: #d24b4c;
            }

            td, th{
                border-left: 1px solid #f3f3f3;
                background: #fdfdfd;
                border: 1px solid #f3f3f3;
                padding: 0.25em 0.5em;

                &:first-child{
                    border-top-left-radius: 0.3em;
                    border-bottom-left-radius: 0.3em;
                }
                
                &:last-child{
                    border-top-right-radius: 0.3em;
                    border-bottom-right-radius: 0.3em;
                }
                
                &.integracao{
                    color: var(--cor-status-registro);
                    font-size: 0.8em;
                    border-left: 3px solid var(--cor-status-registro);
                }

                .versao{
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    background: #f4f4f4;
                    height: fit-content;
                    align-self: center;
                    line-height: 1.2rem;
                    font-size: 0.9em;
                    padding: 0 0.3em;
                    border-radius: 0.3em;
                    font-weight: 700;
                    margin-right: 0.3em;
                    
                    &.gg{
                        color: #0d42af;
                    }

                    &.v1{
                        color: #e56804;
                    }
                    
                    &.v2{
                        color: #e5b404;
                    }
                    
                    &.v3{
                        color: #06c142;
                    }
                }

                &.hora{
                    font-size: 0.8em;
                    font-weight: 500;
                }
            }
        }
    }

    .paginacao{
        font-size: 0.85em;

        .total-registros{
            padding: 0.125em 1.2em !important;
        }
    }
    
    .alerta{
        font-size: 12px;
        padding: 5px 10px !important;
        border-radius: 5px;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        margin: 4px;

        button{
            padding: 1px 2px !important;
            font-size: 20px;
        }

        p{
            margin: 0;
        }
    }
`;


export const ModalPainelVisualizarLog = styled(Modal).attrs({

})`

    .modal-header{

        .modal-title{
            width: 100%;
            text-align: center;

            small{
                font-size: 0.525em;
            }
        }
    }
`;