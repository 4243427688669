
import styled from 'styled-components';

// componente
export const FotoComponente = styled.div`
    width: 100%;
    --min-height: 150px;

    .foto {
        min-height: 150px;
        position: relative;

        img {
            object-fit: cover;
            position: relative;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            min-height: var(--min-height);
        }
        
        .editar {
            position: absolute;
            bottom: 0;
            right: 0;
            cursor: pointer;

            &:hover {
                color: blue;
            }
        }

        .sem-imagem {
            display: flex;
            align-items: center;
            min-height: var(--min-height);
            justify-content: center;
            color: grey;
        }
    }

    .nome {
        font-size: 0.8rem;
    }

`;