import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Marker, InfoWindow } from '@react-google-maps/api';

// função para o relatório de acessos
export default function Marcador({
    dados,
    abrir=null,
    icon=null
}){

    const [aberto, alterarAberto] = useState(false)
    
    useEffect(() => {

        // se aberto
        alterarAberto(abrir)    

    }, [dados, abrir])

    if(!icon){
        return <></>
    }

    return <Marker
            key={dados.id}
            position={{
                lat: parseFloat(dados.latitude),
                lng: parseFloat(dados.longitude)
            }}
            onClick={() => {
                alterarAberto(!aberto)
            }}
            icon={icon}
        >
            {aberto && 
                <InfoWindow onCloseClick={() => alterarAberto(false)}>
                    <span>{dados.razao_social}</span>
                </InfoWindow>
            }
        </Marker>

}
