
import React from 'react';

import { Modal, Button } from 'react-bootstrap';

export default function Confirmar({
    config, alterar
}){
    config = Object.assign({
        titulo: 'Confirmar',
        aberto: false,
        texto: null,
        mostrarBotaoCancelar: true,
        mostrarBotaoConfirmar: true,
        textoBotaoConfirmar: 'Confirmar',
        textoBotaoCancelar: 'Cancelar',
        aoFechar: null,
        aoConfirmar: null,
        variantCancelar: 'danger',
        variantConfirmar: 'default'
    }, config);

    return (
        <Modal
            show={config.aberto}
            onHide={config.aoFechar ?? (() => alterar({...config, aberto: false}))}
            onExited={() => alterar({...config, aberto: false})}
            centered={config.centered ?? true}
            backdrop={config.backdrop ?? false}
        >
            <Modal.Header>
                <Modal.Title>{config.titulo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    config.texto
                }
            </Modal.Body>
            <Modal.Footer>
                {config.mostrarBotaoCancelar &&
                    <Button variant={config.variantCancelar} onClick={() => {
                        alterar({...config, aberto: false})
                        // executa callback se tiver
                        if(config.aoCancelar){
                            config.aoCancelar()
                        }
                    }}>
                        {config.textoBotaoCancelar}
                    </Button>
                }
                {config.mostrarBotaoConfirmar &&
                    <Button variant={config.variantConfirmar} onClick={() => {
                        alterar({...config, aberto: false})

                        config.aoConfirmar();
                    }}>
                        {config.textoBotaoConfirmar}
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    )
};
