import React, { useState, useEffect } from 'react';
import axios from 'axios';

// componentes
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, Col, Row, Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carregador from './../../../Componentes/Carregador';
import PaginacaoComponente from './../../../Componentes/Paginacao';
import ParametrosConsulta from './ParametrosConsulta';
import Registro from './Registro';
import { faEnvelope, faFileAlt, faShippingFast } from '@fortawesome/free-solid-svg-icons';

export default function Consultar(){

    // estados
    let history = useHistory();
    let [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: 10,
        ativo: ['S'],
        status: [1],
        texto: ''
    });
    let [totalRegistros, atualizarTotalRegistros] = useState(0);
    let [carregando, mostrarCarregando] = useState(false);
    let [consulta, alterarConsulta] = useState([]);
    let [mostrarFiltros, alterarMostrarFiltros] = useState(false);

    // inicia
    useEffect(() => {
        mostrarCarregando(true);

        axios.get('/emailsEnviado', {
            params: parametros
        })
        .then(({data, ...retorno}) => {
            alterarConsulta(data.registros);
            atualizarTotalRegistros(parseInt(data.totalRegistros));
        })
        .catch(() => {})
        .then(() => {
            mostrarCarregando(false);
        });

    }, [parametros]);

    return <Row>
        <Col>
            <Card className="painel-consulta border-0 shadow-sm" >
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h4 className="card-title font-weight-bolder">
                        <span>Caixa de Saída de emails</span>
                    </h4>
                    <div>
                        <Button 
                            variant='padrao' 
                            size='sm'
                            onClick={() => {
                                history.push('/painel/email/lista');
                            }}
                            className="mr-2"
                        >
                            <FontAwesomeIcon className="icone" icon={faShippingFast} />
                            <span>Lista</span>
                        </Button>
                        <Button 
                            variant='padrao' 
                            size='sm'
                            onClick={() => {
                                history.push('/painel/modelos-email');
                            }}
                        >
                            <FontAwesomeIcon className="icone" icon={faFileAlt} />
                            <span>Modelos</span>
                        </Button>
                    </div>
                    {/* <Parametros parametros={parametros} alterarParametros={alterarParametros} {...props} /> */}
                </div>
                
                <Card.Body className='mt-0'>

                    <ParametrosConsulta 
                        parametros={parametros}
                        alterarParametros={alterarParametros}
                        mostrarFiltros={mostrarFiltros}
                        alterarMostrarFiltros={alterarMostrarFiltros}
                    />
                    
                    <table className="table table-hover tabela mt-3">
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Título</th>
                                <th>Para</th>
                                <th>Data</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {consulta.map((registro, a) => 
                                <Registro 
                                    key={registro.id}
                                    dados={registro}
                                    clicouAbrir={() => {
                                        history.push(`/painel/clientes/abrir-email/${registro.id}`);
                                    }}
                                />
                            )}
                        </tbody>
                    </table>
                    <PaginacaoComponente
                        pagina={parametros.pagina}
                        registrosPorPagina={parametros.registrosPorPagina}
                        totalRegistros={totalRegistros}
                        alterouPagina={(pagina) => {
                            alterarParametros({...parametros, pagina: pagina});
                        }}
                    />
                    {carregando && 
                        <Carregador className="rounded-bottom" />
                    }
                </Card.Body>
            </Card>
        </Col>
    </Row>
}
