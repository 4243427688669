import axios from 'axios';
import { useEffect, useState } from "react";
import { Button, CloseButton, Col, Container, Form, Modal, Spinner } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";

import Registro from './Registro';
import { ComboListaModalComponent, VisualizarModalComponent } from "./components";

export default function ComboListaModal({
    mostrar,
    alterarMostrar,
    aoFechar,
    aoSelecionar
}){

    // estados
    const [ parametros, alterarParametros ] = useState({
        registrosPorPagina: 8,
        pagina: 1,
        pesquisa: null
    });
    const [ registros, alterarRegistros ] = useState([]);
    const [ totalRegistros, alterarTotalRegistros ] = useState(0);
    const [ carregando, alterarCarregando ] = useState(false);
    const [ visualizarTemplate, alterarVisualizarTemplate ] = useState(null);

    // inicializa
    useEffect(() => {
        
        // faz a consulta
        consultar();

    }, [parametros]);

    // faz consulta
    async function consultar(){

        // carregando
        alterarCarregando(true);

        // faz a requisição
        try{
            let { data } = await axios.get('/notificacoes/templates', {
                params: parametros
            });

            // finalizado
            alterarRegistros(data.registros);
            alterarTotalRegistros(data.totalRegistros)

        }catch(e){

        }finally{
            alterarCarregando(false);
        }
    }
    
    // retorna registro
    function retornaRegistros(){

        // retorna registros organizado
        return registros.map(registro => 
            <Registro
                key={registro.dados.id}
                dados={registro.dados}
                clicouSelecionar={() => {

                    // executa callback
                    aoSelecionar(registro);
                }}
                clicouVisualizar={() => {

                    console.log('Visualizar', registro)
                    alterarVisualizarTemplate(registro);
                }}
            />
        )
    }

    // verifica se possui visualização
    if(visualizarTemplate){

        // retorna modal
        return <VisualizarModalComponent
            show={true}
            centered
            size={'md'}
            onExited={() => {

                // fecha
                alterarVisualizarTemplate(null);
            }}
            onHide={() => {

                // fecha
                alterarVisualizarTemplate(null);
            }}
        >
            <Modal.Header className="d-flex align-items-center" style={{padding: '0.5rem 1rem'}}>
                <Modal.Title>{visualizarTemplate.dados.titulo}</Modal.Title>
                <CloseButton
                    onClick={() => {

                        // fecha
                        alterarVisualizarTemplate(null);
                    }}
                />
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={{__html: visualizarTemplate.dados.texto}}></div>
            </Modal.Body>
        </VisualizarModalComponent>
    }

    return <ComboListaModalComponent
        show={mostrar}
        centered
        size={'sm'}
        onExited={() => {

            // executa
            aoFechar();
        }}
        onHide={() => {

            // fecha
            alterarMostrar(false);
        }}
    >
        <Modal.Header>
            <Modal.Title as="h6">Carregar template</Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-0'>
            <Container className="px-0">
                <DebounceInput 
                    placeholder=""
                    size={"sm"}
                    debounceTimeout={400}
                    element={Form.Control} 
                    value={parametros.pesquisa ?? ''}
                    onChange={(e) =>{
                        alterarParametros({...parametros, pesquisa: e.target.value, pagina: 1})
                    }}
                />
            </Container>
            <Container className="container-tabela px-0 mt-1">
                {
                    carregando ?
                    <Col className="mt-1" align="center">
                        <Spinner animation="border" />
                    </Col>
                    : <table className="table table-hover tabela">
                        {totalRegistros > 0 && <>
                            <tbody>
                                { retornaRegistros() }
                            </tbody>
                        </>}
                    </table>
                }
            </Container>
        </Modal.Body>
    </ComboListaModalComponent>
}