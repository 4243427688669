import styled from 'styled-components';

export const ParametrosTesteListaComponent = styled.div.attrs({
    className: ``
})`

    /* display: flex;
    align-items: baseline;


    .linha {
        width: 20px;
        border
    }

    .parametros {
        flex-grow: 1;
    } */

    /* position: relative; */
    margin-right: 0 !important;
    
    ${props => props.raiz && `

        > .parametros > .parametro-teste {
            padding-right: 10px !important;
        }
    `}

    ${props => !props.raiz && `

        padding: 5px;
        padding-right: 0 !important;
        // background: #e8e8e88a;

    
        .parametros {
            padding-left: 10px;
            position: relative;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 7px;
                border-left: 2px solid grey;
                border-bottom: 2px solid grey;
                border-bottom-left-radius: 10px;
            }
        }
    
    `}


`