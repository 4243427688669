import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory, useRouteMatch } from "react-router-dom";

// componentes
import useIsMounted from 'ismounted';
import { Modal } from 'react-bootstrap';

// contexto
import { PainelContexto } from './../../PainelContexto';
import ListaUltimosAcessos from '../ListaUltimosAcessos';

import { ModalAcessosPorEmpresaComponent } from './components';

// faz o cadastro
export default function ModalAcessosPorEmpresa({
    urlVoltar
}){

    // dados
    const isMounted = useIsMounted();
    const { path, url } = useRouteMatch();
    const painelContextoDados = useContext(PainelContexto);
    const history = useHistory();

    // parametros da url
    const {
        idEmpresa = null
    } = useParams();
    
    // estados
    const [ mostrar, alterarMostrar ] = useState(true);
    
    // inicializa
    useEffect(() => {
        
    }, []);

    return <>
        <ModalAcessosPorEmpresaComponent 
            show={mostrar}
            enforceFocus={false}
            onExited={() => {

                // fecha
                alterarMostrar(false);

                // volta e dá refresh pra atualizar a listagem
                history.push(urlVoltar ?? '/painel/empresas/acessos');
                
            }}
            onHide={() => {

                // fecha
                alterarMostrar(false);
            }}
            size={'lg'}
        >
            <ListaUltimosAcessos
                idEmpresa={idEmpresa}
            />
        </ModalAcessosPorEmpresaComponent>
    </>
}   