
import { useRef, useState } from 'react';
import { Button, Col, Form, Row, Popover } from 'react-bootstrap';
import Select from 'react-select';

import { ModalFiltrosComponent, PopoverFiltrosComponent } from './components';

import { intersectObject } from '../../../../Funcoes';

import InputDataCombo from "../../../../Componentes/InputDataCombo";

export default function ModalFiltros({
    elemento,
    mostrar,
    alterarMostrar,
    parametros,
    alterarParametros
}){

    // estados
    const [ filtros, alterarFiltros ] = useState(intersectObject({
        pesquisa: null,
        statusRetorno: null,
        idsIntegracaoSistema: null,
        dataInicio: null,
        dataFinal: null,
        horaInicio: null,
        horaFinal: null
    }, parametros ?? {}));
    const refs = {
        dataInicioCol: useRef(null),
        dataFinalCol: useRef(null)
    };

    // prepara
    const encontradoEm = [
        {label: 'Interno', value: 'interno'},
        {label: 'Retorno reutilizado', value: 'retornoReutilizado'},
        {label: 'Google', value: 'google'},
        // {label: 'Não encontrado', value: 'naoEncontrado'}
    ];

    // prepara
    const statusRetorno = [
        {label: 'Encontrado', value: 'encontrado'},
        {label: 'Não aceito', value: 'naoAceito'},
        {label: 'Não encontrado', value: 'naoEncontrado'},
        {label: 'Não encontrado interno', value: 'naoEncontradoInterno'},
        {label: 'Erro com chave cliente', value: 'erroUsadoGoogleChaveCliente'},
        {label: 'Encontrado com chave cliente', value: 'encontradoUsadoGoogleChaveCliente'}
    ];

    // prepara
    const origem = [
        {label: 'GeoGrid', value: 'geogrid'},
        {label: 'API', value: 'api'}
    ];

    // função para limpar os filtros
    function limparFiltros(){

        // prepara
        let filtrosAlterar = {...filtros};

        // percorre filtros
        Object.keys(filtros).map((filtro) => {

            // limpa
            filtrosAlterar[filtro] = null;

        });

        // altera
        alterarFiltros({...filtrosAlterar});
        // alterarParametros({...parametros, ...filtrosAlterar});

    }

    // função para cancelar (sair sem aplicar)
    function cancelar(){

        // fecha
        alterarMostrar(false);

        // altera os filtros para o estado inicial
        let filtrosAlterar = {...filtros};
        let parametrosClone = {...parametros};

        // percorre
        Object.keys(filtros).map((filtro) => {

            // altera
            filtrosAlterar[filtro] = parametrosClone[filtro] ?? null;
        });

        // altera os filtros
        alterarFiltros({...filtrosAlterar});

    }

    return <ModalFiltrosComponent
        show={mostrar}
        target={elemento}
        placement="bottom"
        rootClose
        onHide={() => {
            cancelar();
        }}
    >
        <PopoverFiltrosComponent
            size={'sm'}
            title={'Filtros'}
        >
            <Popover.Title>
                Filtros
                <Button
                    size="sm"
                    name="botao-limpar"
                    onClick={() => {

                        // limpa filtros
                        limparFiltros();
                    }}
                >
                    Limpar
                </Button>
            </Popover.Title>
            <Popover.Content>
                <Row>
                    <Col>
                        <Form.Label>Pesquisa <small>(empresa/usuário)</small></Form.Label>
                        <Form.Control 
                            value={filtros.pesquisa || ''}
                            onChange={e => {

                                // altera
                                alterarFiltros({...filtros, pesquisa: e.target.value});
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>Encontrado em</Form.Label>
                        <Select
                            className="select-ficha"
                            size="sm"
                            options={encontradoEm}
                            value={encontradoEm.filter((e) => filtros.encontradoEm && filtros.encontradoEm.includes(e.value))}
                            isMulti={true}
                            placeholder='Sem filtro'
                            onChange={(opcoes) => {
                                
                                // altera os filtros selecionados
                                alterarFiltros({
                                    ...filtros,
                                    encontradoEm: (
                                        opcoes ? opcoes.map((s) => s.value) : null
                                    )
                                });
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>Status retorno</Form.Label>
                        <Select
                            className="select-ficha"
                            size="sm"
                            options={statusRetorno}
                            value={statusRetorno.filter((s) => filtros.statusRetorno && filtros.statusRetorno.includes(s.value))}
                            isMulti={true}
                            placeholder='Sem filtro'
                            onChange={(opcoes) => {
                                
                                // altera os filtros selecionados
                                alterarFiltros({
                                    ...filtros,
                                    statusRetorno: (
                                        opcoes ? opcoes.map((s) => s.value) : null
                                    )
                                });
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>Origem</Form.Label>
                        <Select
                            className="select-ficha"
                            size="sm"
                            options={origem}
                            value={origem.filter((s) => filtros.origem && filtros.origem.includes(s.value))}
                            isMulti={true}
                            placeholder='Sem filtro'
                            onChange={(opcoes) => {
                                
                                // altera os filtros selecionados
                                alterarFiltros({
                                    ...filtros,
                                    origem: (
                                        opcoes ? opcoes.map((s) => s.value) : null
                                    )
                                });
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>Data pesquisa</Form.Label>
                        <Row>
                            <Col className='position-relative' ref={refs.dataInicioCol}>
                                {refs.dataInicioCol.current && <InputDataCombo
                                    size="sm"
                                    placeholder="A partir de"
                                    data={filtros.dataInicio}
                                    onChange={(novaData) => {
                                        alterarFiltros({...filtros, dataInicio: novaData})
                                    }}
                                    options={{
                                        appendTo: refs.dataInicioCol.current
                                    }}
                                />}
                            </Col>
                            <Col className='position-relative' ref={refs.dataFinalCol}>
                                {refs.dataFinalCol.current && <InputDataCombo
                                    size="sm"
                                    placeholder="Até"
                                    data={filtros.dataFinal}
                                    onChange={(novaData) => {
                                        alterarFiltros({...filtros, dataFinal: novaData})
                                    }}
                                    options={{
                                        appendTo: refs.dataFinalCol.current
                                    }}
                                />}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>Hora pesquisa</Form.Label>
                        <Row>
                            <Col>
                                <Form.Control
                                    type='time'
                                    placeholder="A partir de"
                                    value={filtros.horaInicio ?? ''}
                                    onChange={(e) => {
                                        alterarFiltros({...filtros, horaInicio: e.target.value})
                                    }}
                                />
                            </Col>
                            <Col>
                                <Form.Control
                                    type='time'
                                    placeholder='Até'
                                    value={filtros.horaFinal ?? ''}
                                    onChange={(e) => {
                                        alterarFiltros({...filtros, horaFinal: e.target.value})
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className='footer'>
                    <Button
                        size="sm"
                        variant={'light'}
                        onClick={() => {

                            // cancela
                            cancelar();
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        size="sm"
                        variant={'success'}
                        onClick={() => {

                            // altera os parâmetros
                            alterarParametros({...parametros, ...filtros, pagina: 1});
                        }}
                    >
                        Aplicar
                    </Button>
                </div>
            </Popover.Content>
        </PopoverFiltrosComponent>
    </ModalFiltrosComponent>
    
}