import React from 'react';
import { Badge } from 'react-bootstrap';
import styles from './index.module.scss';

function OptionSelect({
    data, 
    ...props
}){
    
    let { innerProps, innerRef, isFocused, isDisabled } = props;
    
    // retorna componente
    return <div 
        tabIndex='-1' 
        ref={innerRef} 
        {...innerProps} 
        className={[
            styles.option, 
            isFocused && styles.focado,
            isDisabled && styles.desabilitar
        ].join(' ')}
        // style={{background: data.dados.cor}}
    >
       <Badge variant="light" className="mr-2">{data.dados.id}</Badge>
       {data.dados.usuario}
    </div>
}


export default OptionSelect;