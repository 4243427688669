import React from 'react';

// componentes
import ParametrosTesteLista from './ParametrosTesteLista';

export default function ParametrosTesteContainer({
    parametrosTeste,
    alterarParametrosTeste
}){

    return <>
        <div className='pr-2'>
            <ParametrosTesteLista 
                filhos={parametrosTeste}
                alterou={alterarParametrosTeste}
                raiz={true}
            />
        </div>
    </>
}