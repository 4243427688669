import moment from "moment";
import { Badge, Card } from "react-bootstrap";
import { isBrowser, isMobile } from "react-device-detect";
import { faFileAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import BotaoTabela from "../../../../../../Componentes/BotaoTabela";

export default function Registro({
    dados,
    dataAnterior=null,
    dadosUsuario,

    clicouAbrir
}){

    
    // retorna opções
    let retornarOpcoes = () => {

        // retorna componentes
        return <>
            <BotaoTabela
                onClick={clicouAbrir}
                icone={faFileAlt}
            >
                <span>Abrir</span>
            </BotaoTabela>
        </>
    }

    return <> 
        {
            (
                !dataAnterior ||
                moment(dataAnterior).format('DD/MM/YYYY') !== moment(dados.dataCadastro).format('DD/MM/YYYY')
            ) && <tr className="registro registro-divisor-data">
                <td className="divisor-data estreito">{moment(dados.dataCadastro).format('DD/MM/YYYY')}</td>
            </tr> 
        }
        <tr
            className={[
                'registro',
                dados.metodo.toLowerCase(),
                `status-${dados.status}`
            ].join(' ')}
        >
            <td className={'text-center font-weight-bold integracao estreito'}>
                {dados.metodo}
            </td>

            <td className={'text-center estreito hora'}>
                {moment(dados.dataCadastro).format('HH:mm:ss')}
            </td>

            <td className="descricao">
                    
                <div className="d-flex align-items-center" style={{gap: '0.5em'}}>
                    <div>
                        <span className={['text-center font-weight-bold versao', dados.versao].join(' ')}>
                            {dados.versao}
                        </span>
                        {dados.rota}
                    </div>
                    {dadosUsuario && <small>
                        {dadosUsuario.usuario} <small>({dadosUsuario.permissao})</small>
                    </small>}
                </div>
            </td>

            <td className="estreito">{retornarOpcoes()}</td>
        </tr>
    </>
}