import React, { useEffect, useState } from 'react';
import uniqid from 'uniqid';
import * as R from 'ramda';

import Lista from './Lista';
import { ParametrosListaContexto } from './ParametrosListaContexto';

// parametros
export default function ParametrosLista({
    value : parametros,
    onChange : alterarParametros
}){


    return <ParametrosListaContexto.Provider value={{
        parametros, alterarParametros
    }}>
        <Lista 
            parametros={parametros}
            clicouNovo={() => {
                parametros.push({tempId: uniqid()});
                alterarParametros([...parametros]);
            }}
            alterou={novoArray => {
                parametros.filhos = novoArray;
                alterarParametros([...parametros]);
            }}
            raiz={true}
        />
    </ParametrosListaContexto.Provider>

}