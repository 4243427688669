import React, { useState, useContext } from 'react';

// componentes
import { ListaComponent } from './components';
import { Card, Form, Row, Col, Button, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faPlus, faSpinner, faPlay } from '@fortawesome/free-solid-svg-icons';
import Filtros from './../Filtros';
import { useFicha } from '../../FichaContexto';

export default function ListaFiltros({
    filtros,
    adicionarFiltro
}){

    // contexto
    const { 
        alterarFiltros
    } = useFicha();

    return <ListaComponent>
        <Card className='border-0 shadow-sm mb-4 mt-3'>
            <Card.Header className='mb-0 px-3 pt-3 pb-1 border-0 bg-white'>
                <div className={'d-flex '}>
                    <Card.Title className={'flex-grow-1 mb-0'}>
                        Filtros
                    </Card.Title>
                    <div>

                        <OverlayTrigger 
                            overlay={<Tooltip>Novo grupo de filtros (mesmo que "ou")</Tooltip>}
                            placement='left'
                        >
                            <Button
                                variant='success'
                                size='sm'
                                onClick={() => {

                                    // adicionar o filtro
                                    adicionarFiltro();
                                }}
                            >
                                <FontAwesomeIcon className="icone" icon={["fas", "plus"]} />
                                <span>Novo</span>
                            </Button>
                        </OverlayTrigger>
                    </div>
                </div>
            </Card.Header>
            <Card.Body className={['lista-filtros p-3'].join(' ')}>
                {
                    filtros.map((filtro, i) => {
                        return <Filtros
                            key={`filtros-${i}`}
                            titulo={`Filtro ${i + 1}`}
                            filtros={filtro}
                            alterarFiltros={(novosFiltros) => {
                                alterarFiltros(filtros.map((f, indice) => {
                                    return i === indice ? {
                                        ...novosFiltros
                                    } : f;
                                }))
                            }}
                            remover={() => {
                                alterarFiltros(filtros.filter((f, indice) => indice !== i));
                            }}
                        />
                    })
                }
            </Card.Body>
        </Card>
    </ListaComponent>

}