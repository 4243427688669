import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Card, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import { decamelize } from "../../../../Funcoes";

import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';

import { EmpresaEstatisticasComponent } from './components';
import { faEvernote } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faMemory } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";

export default function EmpresaEstatisticas({
    idEmpresa
}){

    // estados
    const location = useLocation();
    const [ carregando, alterarCarregando ] = useState(false);
    const [ parametros, alterarParametros ] = useState({});
    const [ erros, alterarErros ] = useState({});
    const [ dados, alterarDados ] = useState({});
    const [ dadosBase, alterarDadosBase ] = useState({
        tamanho: 0,
        tabelas: {
            cabos: {
                tamanho: 0,
                registros: {
                    total: 0,
                    ligacao: 0
                }
            },
            equipamentos: {
                tamanho: 0,
                registros: {
                    total: 0,
                    atendimento: 0
                }
            },
            itensRede: {
                tamanho: 0,
                registros: {
                    total: 0,
                    poste: 0,
                    estacao: 0,
                    pontoAcesso: 0,
                    grupoAcesso: 0,
                    caixa: 0,
                    terminal: 0,
                    rack: 0,
                    reserva: 0,
                    interesse: 0,
                    possuiAntenas: 0,
                    mediaAntenas: 0
                }
            }
        }
	});
    const [ dadosUsoIntegracao, alterarDadosUsoIntegracao ] = useState({
        v1: {
            total: 0
        },
        v2: {
            total: 0
        },
        v3: {
            total: 0
        }
    });
    const [ mostrarInfoCompleta, alterarMostrarInfoCompleta ] = useState(false);

    // efeitos

    // prepara
    const pathsConsultar = [
        '/painel/empresas/editar/:idEmpresa/integracao'
    ].map((p) => p.replace(':idEmpresa', idEmpresa));

    useEffect(() => {

        // se retornar a essa rota
        if(pathsConsultar.includes(location.pathname)){

            // consulta
            consultar();
        }

    }, [location.pathname]);


    // função para consultar
    async function consultar(){

        // cria o carregamento
        alterarCarregando(true);

        try{

            // consulta
            let { data } = await axios.get(`/empresas/${idEmpresa}/estatisticas/relatorio`, {
                params: {
                    ...parametros
                }
            });

            // sucesso

            // pega os registros e total
            alterarDados(data);
            alterarDadosBase(data.base);
            alterarDadosUsoIntegracao(data.usoIntegracao);

        }catch(e){
            // erro

            // pega a resposta
            let response = e.response;
            // console.log(e);

            // verifica se tem a resposta
            if(response){

                // verifica se é erro 404
                if(response.status === 404){

                    // informa erro
                    toast(<>
                        <div
                            style={{background: '#ff6271'}}
                        >
                            <div className="toast-header">
                                <strong className="mr-auto">Empresa desatualizada</strong>
                                <button 
                                    className="ml-2 mb-1 close btn-outline-light outline-0"
                                >
                                </button>
                            </div>
                            <div className="toast-body text-light">
                                A empresa não possui a versão com a consulta de logs da API.
                            </div>
                        </div>
                    </>);

                    // define o erro
                    alterarErros({
                        semApi: `A empresa não possui a versão com a consulta de relatório.`
                    });
                }
            }

        }finally{
            // finalizou

            // remove o carregamento
            alterarCarregando(false);
        }
    }

    // retorna
    return <EmpresaEstatisticasComponent>
        <Card.Body className="p-3">
            <Card.Header className={'px-0'}>
                <div className={'d-flex '}>
                    <Card.Title className={'flex-grow-1 d-flex align-items-center mb-0'}>
                        Relatório
                    </Card.Title>
                    <div>
                        <OverlayTrigger
                            overlay={<Tooltip>Ver tudo</Tooltip>}
                            placement='left'
                        >
                            <Button
                                variant='light'
                                size='sm'
                                onClick={() => {
                                    alterarMostrarInfoCompleta(true)
                                }}
                                style={{
                                    fontSize: '0.9em',
                                    boxShadow: '0 0 1px #b1b1b1',
                                    border: 0
                                }}
                            >
                                <FontAwesomeIcon className="icone" icon={faInfoCircle} />
                            </Button>
                        </OverlayTrigger>
                    </div>
                </div>
            </Card.Header>
            <table className="uso-integracao" cellSpacing={0}>
                <thead>
                    <tr>
                        <th colSpan={3}>Integração (Api)</th>
                    </tr>
                    <tr>
                        <th>v1</th>
                        <th>v2</th>
                        <th>v3</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{dadosUsoIntegracao.v1.total}</td>
                        <td>{dadosUsoIntegracao.v2.total}</td>
                        <td>{dadosUsoIntegracao.v3.total}</td>
                    </tr>
                </tbody>
            </table>

            <table className="dados-base" cellSpacing={0}>
                <thead>
                    <tr>
                        <th colSpan={2}>Base de dados</th>
                        <th>{dadosBase.tamanho} MB</th>
                    </tr>
                    <tr>
                        <th></th>
                        <th>Total registros</th>
                        <th>(MB)</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(dadosBase.tabelas).map((tabela) => {
                        return <tr key={tabela}>
                            <th>{tabela.charAt(0).toUpperCase()}{decamelize(tabela).slice(1).split('_').join(' ')}</th>
                            <td>{dadosBase.tabelas[tabela].registros.total}</td>
                            <td>{dadosBase.tabelas[tabela].tamanho}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </Card.Body>
        <Modal
            show={mostrarInfoCompleta}
            onExited={() => {}}
            onHide={() => {
                alterarMostrarInfoCompleta(false);
            }}
            size={'md'}
        >
            <Modal.Header className='border-0'>
                <Modal.Title 
                    as='h5' 
                    className={[
                        'text-center w-100',
                        'text-success'
                    ].join(' ')}
                >
                    Informações
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={'pt-0'}>
                <JSONPretty
                    id="json-info-servidor"
                    data={dados}
                    space="4"
                    mainStyle='color: #3f8a98;background: #f7f7f7;padding: 3px;border-radius: 3px;max-height: 80vh;overflow: auto;'
                />
            </Modal.Body>
        </Modal>
    </EmpresaEstatisticasComponent>;
}