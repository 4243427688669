import { Route } from "react-router-dom";
import Lista from "./Lista";

export default function EmpresasBackups({}){

    return <>
        <Route exact path={`/painel/empresas/editar/:idEmpresa/backups`}>
            <Lista />
        </Route>
    </>
}