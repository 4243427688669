import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Row, Col, FormCheck, FormLabel, Badge, OverlayTrigger, Popover } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';
import Select from 'react-select';

// componentes
import InputDataCombo from './../../../../Componentes/InputDataCombo';
import ComboServidores from './../../../../Componentes/ComboServidores';
import ComboIntegracoes from './../../../../Componentes/ComboIntegracoes';
import ComboMultipleVersoes from './../../../../Componentes/ComboMultipleVersoes';
import ComboGruposChaveGoogleMultiplo from './../../../../Componentes/ComboGruposChaveGoogleMultiplo';
import ComboIntegracaoSistemas from '../../../../Componentes/ComboIntegracaoSistemas';
import ComboBancosDadosMultiplo from '../../../../Componentes/ComboBancosDadosMultiplo';


export default function ParametrosConsulta({
    parametros, 
    alterarParametros, 
    mostrarFiltros,
    alterarMostrarFiltros,
    alterarHabilitarIgnorarIds,
    habilitarIgnorarIds,
    clientesIgnorados,
    alterarClientesIgnorados
}){

    // filtros padrão para a pesquisa
    let filtrosModo = [
        {value: 'cliente', label: 'Clientes'},
        {value: 'clientelight', label: 'Clientes Light'},
        {value: 'demo', label: 'Demos'},
        {value: 'demolight', label: 'Demos Light'},
        {value: 'parceiro', label: 'Parceiros'},
        {value: 'bloqueado', label: 'Bloqueados'},
        {value: 'cancelado', label: 'Cancelados'},
        {value: 'nao-ativo', label: 'Aguardando ativação'}
    ];

    return <div className="parametros">
        <div className="parametros-inicial">
            {/* <Link to='/painel/movimentos/cadastrar'>
                <Button variant="padrao" className="botao-cadastrar">
                    <FontAwesomeIcon className="icone" icon={["fas", "plus"]} />
                    { isBrowser && <span>Novo</span>}
                </Button>
            </Link> */}
            <DebounceInput 
                placeholder="Buscar por cliente" 
                debounceTimeout={400}
                element={Form.Control} 
                value={parametros.pesquisa}
                onChange={(e) =>{
                    alterarParametros({...parametros, pesquisa: e.target.value, pagina: 1})
                }}
            />

            <OverlayTrigger trigger="click" placement="bottom-end" delay={false} transition={false}
                overlay={
                    <Popover id="filtros-empresas" style={{"max-width": "70vw"}} delay={0} transition={false}>
                        <Popover.Content className="p-3 shadow-lg">
                            {/* <h4 className="my-2">Filtros</h4> */}
                            <Row>
                                <Col lg={8}>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Select
                                                    options={filtrosModo}
                                                    value={filtrosModo.filter((e) => parametros.modo.includes(e.value))}
                                                    isMulti={true}
                                                    placeholder='Modo'
                                                    onChange={(e) => {
                                                        e = e || []; // mantem vazio se for null
            
                                                        // altera os filtros selecionados
                                                        alterarParametros({...parametros, modo: e.map((option) => option.value), pagina: 1});
                                                    }}
                                                />
                                            </Form.Group>
            
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>É cliente</Form.Label>
                                                        <Row>
                                                            <Col>
                                                                <InputDataCombo 
                                                                    placeholder="A partir de"
                                                                    data={parametros.dataClienteDataInicio}
                                                                    onChange={(novaData) => {
                                                                        alterarParametros({...parametros, dataClienteDataInicio: novaData, pagina: 1})
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <InputDataCombo 
                                                                    placeholder="Até"
                                                                    data={parametros.dataClienteDataFim}
                                                                    onChange={(novaData) => {
                                                                        alterarParametros({...parametros, dataClienteDataFim: novaData, pagina: 1})
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label>Cadastro feito</Form.Label>
                                                        <Row>
                                                            <Col>
                                                                <InputDataCombo 
                                                                    placeholder="A partir de"
                                                                    data={parametros.dataCadastroDataInicio}
                                                                    onChange={(novaData) => {
                                                                        alterarParametros({...parametros, dataCadastroDataInicio: novaData, pagina: 1})
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <InputDataCombo 
                                                                    placeholder="Até"
                                                                    data={parametros.dataCadastroDataFim}
                                                                    onChange={(novaData) => {
                                                                        alterarParametros({...parametros, dataCadastroDataFim: novaData, pagina: 1})
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Bloqueio</Form.Label>
                                                        <Row>
                                                            <Col>
                                                                <InputDataCombo 
                                                                    placeholder="A partir de"
                                                                    data={parametros.dataBloqueadoDataInicio}
                                                                    onChange={(novaData) => {
                                                                        alterarParametros({...parametros, dataBloqueadoDataInicio: novaData, pagina: 1})
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <InputDataCombo 
                                                                    placeholder="Até"
                                                                    data={parametros.dataBloqueadoDataFim}
                                                                    onChange={(novaData) => {
                                                                        alterarParametros({...parametros, dataBloqueadoDataFim: novaData, pagina: 1})
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label>Cancelado</Form.Label>
                                                        <Row>
                                                            <Col>
                                                                <InputDataCombo 
                                                                    placeholder="A partir de"
                                                                    data={parametros.dataCanceladoDataInicio}
                                                                    onChange={(novaData) => {
                                                                        alterarParametros({...parametros, dataCanceladoDataInicio: novaData, pagina: 1})
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <InputDataCombo 
                                                                    placeholder="Até"
                                                                    data={parametros.dataCanceladoDataFim}
                                                                    onChange={(novaData) => {
                                                                        alterarParametros({...parametros, dataCanceladoDataFim: novaData, pagina: 1})
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
            
                                            { clientesIgnorados.map(cliente => 
                                                <Badge
                                                    key={cliente.dados.id}
                                                    variant={'danger'}
                                                    className='m-1'
                                                >
                                                    <span
                                                        className='mr-2'
                                                    >
                                                        {cliente.dados.razaoSocial}
                                                    </span>
                                                    <FontAwesomeIcon 
                                                        className="icone" 
                                                        icon={["fas", 'times']} 
                                                        onClick={() => {
                                                            alterarClientesIgnorados(clientesIgnorados.filter(clienteVoltar => {
                                                                if(clienteVoltar.dados.id === cliente.dados.id){
                                                                    return false;
                                                                }
                                                                return true;
                                                            }))
                                                        }}
                                                    />
                                                </Badge> 
                                            ) }
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Form.Row>
                                        <Col>
                                            <Form.Check
                                                type="switch"
                                                id="switch-pesquisar-codigo"
                                                label="Pesquisar por código da empresa"
                                                checked={(parametros.filtroCodigoEmpresa === 'S')}
                                                onChange={e => {
                                                    alterarParametros({...parametros, filtroCodigoEmpresa: (e.target.checked) ? 'S' : 'N', pagina: 1});
                                                }}
                                            />
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col>
                                            <Form.Check
                                                type="switch"
                                                id="switch-pesquisar-telefone"
                                                label="Pesquisar por telefone"
                                                checked={(parametros.filtroPesquisarTelefone === 'S')}
                                                onChange={e => {
                                                    alterarParametros({...parametros, filtroPesquisarTelefone: (e.target.checked) ? 'S' : 'N', pagina: 1});
                                                }}
                                            />
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col>
                                            <Form.Check
                                                type="switch"
                                                id="switch-pesquisar-cnpj"
                                                label="Pesquisar por CNPJ"
                                                checked={(parametros.filtroPesquisarCnpj === 'S')}
                                                onChange={e => {
                                                    alterarParametros({...parametros, filtroPesquisarCnpj: (e.target.checked) ? 'S' : 'N', pagina: 1});
                                                }}
                                            />
                                        </Col>
                                    </Form.Row>
                                    <Form.Check
                                        type="switch"
                                        id="switch-ignorar-ids"
                                        label="Ignorar alguns clientes?"
                                        checked={habilitarIgnorarIds}
                                        onChange={e => {
                                            alterarHabilitarIgnorarIds(e.target.checked);
                                        }}
                                    />
            
                                    {
                                        ['cliente', 'clientelight', 'parceiro', 'bloqueado', 'cancelado'].some((modo) => parametros.modo.includes(modo)) &&
                                        <>
                                            <Row>
                                                <Col>
                                                    <Form.Check
                                                        type="switch"
                                                        id="switch-reajuste"
                                                        label="Precisam de reajuste"
                                                        checked={parametros.precisaReajuste == 'S'}
                                                        onChange={e => {
                                                            alterarParametros({...parametros, precisaReajuste: (e.target.checked) ? 'S' : null, pagina: 1});
                                                        }}
                                                    />
                                                    <Form.Check
                                                        type="switch"
                                                        id="switch-sem-fatura"
                                                        label="Sem fatura"
                                                        checked={parametros.semFatura == 'S'}
                                                        onChange={e => {
                                                            alterarParametros({...parametros, semFatura: (e.target.checked) ? 'S' : null, pagina: 1});
                                                        }}
                                                    />
                                                    <Form.Check
                                                        type="switch"
                                                        id="switch-atrasados"
                                                        label="Em atraso"
                                                        checked={parametros.possuiFaturasEmAtraso == 'S'}
                                                        onChange={e => {
                                                            alterarParametros({...parametros, possuiFaturasEmAtraso: (e.target.checked) ? 'S' : null, pagina: 1});
                                                        }}
                                                    />
                                                    <hr />
                                                    <Form.Row>
                                                        <Col md="5">
                                                            <Form.Label>Servidor Próprio</Form.Label>
                                                        </Col>
                                                        <Col>
                                                            <Form.Check
                                                                type="switch"
                                                                id="switch-servidor-proprio-sim"
                                                                label="sim"
                                                                checked={(parametros.servidorProprio.includes('S'))}
                                                                onChange={e => {
                                                                    alterarParametros({...parametros, servidorProprio: (e.target.checked) ? [...parametros.servidorProprio, 'S'] : parametros.servidorProprio.filter((e) => e !== 'S'), pagina: 1});
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Form.Check
                                                                type="switch"
                                                                id="switch-servidor-proprio-nao"
                                                                label="não"
                                                                checked={(parametros.servidorProprio.includes('N'))}
                                                                onChange={e => {
                                                                    alterarParametros({...parametros, servidorProprio: (e.target.checked) ? [...parametros.servidorProprio, 'N'] : parametros.servidorProprio.filter((e) => e !== 'N'), pagina: 1});
                                                                }}
                                                            />
                                                        </Col>
                                                    </Form.Row>
                                                    {
                                                        parametros.servidorProprio.includes('N') &&
                                                        <>
                                                            <Form.Group>
                                                                <ComboServidores 
                                                                    id={parametros.idServidorAluguel || null}
                                                                    alterou={(id) => {
                                                                        alterarParametros({...parametros, idServidorAluguel: id, pagina: 1});
                                                                    }}
                                                                />
                                                            </Form.Group>
                                                            <Form.Row>
                                                                <Col md="5">
                                                                    <Form.Label>Cavalo de Troia</Form.Label>
                                                                </Col>
                                                                <Col>
                                                                    <Form.Check
                                                                        type="switch"
                                                                        id="switch-cavalo-troia-sim"
                                                                        label="sim"
                                                                        checked={(parametros.cavaloTroia.includes('S'))}
                                                                        onChange={e => {
                                                                            alterarParametros({...parametros, cavaloTroia: (e.target.checked) ? [...parametros.cavaloTroia, 'S'] : parametros.cavaloTroia.filter((e) => e !== 'S'), pagina: 1});
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <Form.Check
                                                                        type="switch"
                                                                        id="switch-cavalo-troia-nao"
                                                                        label="não"
                                                                        checked={(parametros.cavaloTroia.includes('N'))}
                                                                        onChange={e => {
                                                                            alterarParametros({...parametros, cavaloTroia: (e.target.checked) ? [...parametros.cavaloTroia, 'N'] : parametros.cavaloTroia.filter((e) => e !== 'N'), pagina: 1});
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Form.Row>
                                                        </>
                                                    }
                                                    <Form.Row>
                                                        <Col md="5">
                                                            <Form.Label>Possui integração</Form.Label>
                                                        </Col>
                                                        <Col>
                                                            <Form.Check
                                                                type="switch"
                                                                id="switch-integracao-sim"
                                                                label="sim"
                                                                checked={(parametros.possuiIntegracao.includes('S'))}
                                                                onChange={e => {
                                                                    alterarParametros({...parametros, possuiIntegracao: (e.target.checked) ? [...parametros.possuiIntegracao, 'S'] : parametros.possuiIntegracao.filter((e) => e !== 'S'), pagina: 1});
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Form.Check
                                                                type="switch"
                                                                id="switch-integracao-nao"
                                                                label="não"
                                                                checked={(parametros.possuiIntegracao.includes('N'))}
                                                                onChange={e => {
                                                                    alterarParametros({...parametros, possuiIntegracao: (e.target.checked) ? [...parametros.possuiIntegracao, 'N'] : parametros.possuiIntegracao.filter((e) => e !== 'N'), pagina: 1});
                                                                }}
                                                            />
                                                        </Col>
                                                    </Form.Row>
                                                    {
                                                        parametros.possuiIntegracao.includes('S') &&
                                                        <Form.Group>
                                                            <ComboIntegracaoSistemas
                                                                valor={parametros.idIntegracaoSistema ?? null}
                                                                aoAlterar={(idIntegracaoSistema) => {
            
                                                                    if(idIntegracaoSistema !== parametros.idIntegracaoSistema){
                                                                        console.log("DISPAROU ALTERAÇÃO")
                                                                    
                                                                        // altera
                                                                        alterarParametros({
                                                                            ...parametros,
                                                                            idIntegracaoSistema
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    }
                                                    <ComboMultipleVersoes 
                                                        id={[]}
                                                        alterou={novos => {
                                                            parametros.idsVersoes = novos;
                                                            alterarParametros({...parametros});
                                                        }}
                                                        ids={parametros.idsVersoes}
                                                        // versoes[]=171
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    {
                                        (parametros.modo.includes('demo') || parametros.modo.includes('demolight')) &&
                                        <>
                                            { parametros.modo.includes('cliente') &&
                                                <hr />
                                            }
                                            <Row>
                                                <Col>
                                                    <Form.Check
                                                        type="switch"
                                                        id="switch-tempo-teste"
                                                        label="Ativo a menos de 10 dias"
                                                        checked={parametros.periodoAtivacao == 10}
                                                        onChange={e => {
                                                            alterarParametros({...parametros, periodoAtivacao: (e.target.checked) ? 10 : null, pagina: 1});
                                                        }}
                                                    />
                                                    <Form.Check
                                                        type="switch"
                                                        id="switch-em-teste"
                                                        label="Em teste"
                                                        checked={parametros.emTeste === 'S'}
                                                        onChange={e => {
                                                            alterarParametros({...parametros, emTeste: (e.target.checked) ? 'S' : 'N', pagina: 1});
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    {
                                        ['demo', 'cliente', 'parceiro', 'bloqueado', 'cancelado'].some((modo) => parametros.modo.includes(modo)) &&
                                        <>
                                            <hr />
                                            <Row>
                                                <Col>
                                                    <Form.Row>
                                                        <Col md="5">
                                                            <Form.Label>Token cliente</Form.Label>
                                                        </Col>
                                                        <Col>
                                                            <Form.Check
                                                                type="switch"
                                                                id="switch-token-google-cliente-sim"
                                                                label="possui"
                                                                checked={(parametros.possuiTokenGoogleCliente.includes('S'))}
                                                                onChange={e => {
                                                                    alterarParametros({...parametros, possuiTokenGoogleCliente: (e.target.checked) ? [...parametros.possuiTokenGoogleCliente, 'S'] : parametros.possuiTokenGoogleCliente.filter((e) => e !== 'S'), pagina: 1});
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Form.Check
                                                                type="switch"
                                                                id="switch-token-google-cliente-nao"
                                                                label="não possui"
                                                                checked={(parametros.possuiTokenGoogleCliente.includes('N'))}
                                                                onChange={e => {
                                                                    alterarParametros({...parametros, possuiTokenGoogleCliente: (e.target.checked) ? [...parametros.possuiTokenGoogleCliente, 'N'] : parametros.possuiTokenGoogleCliente.filter((e) => e !== 'N'), pagina: 1});
                                                                }}
                                                            />
                                                        </Col>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Col>
                                                            <ComboGruposChaveGoogleMultiplo
                                                                ids={parametros.idsGruposChaveGoogle || []}
                                                                alterou={novos => {
                                                                    parametros.idsGruposChaveGoogle = novos;
                                                                    alterarParametros({...parametros});
                                                                }}
                                                            />
                                                        </Col>
                                                    </Form.Row>
                                                </Col>
                                            </Row>
                                            {
                                                parametros.possuiTokenGoogleCliente.includes('S') &&
                                                <Row>
                                                    <Col>
                                                        <Form.Row>
                                                            <Col md="5">
                                                                <Form.Label>Usar no Desktop</Form.Label>
                                                            </Col>
                                                            <Col>
                                                                <Form.Check
                                                                    type="switch"
                                                                    id="switch-usar-token-google-cliente-sim"
                                                                    label="sim"
                                                                    checked={(parametros.usarTokenGoogleCliente.includes('S'))}
                                                                    onChange={e => {
                                                                        alterarParametros({...parametros, usarTokenGoogleCliente: (e.target.checked) ? [...parametros.usarTokenGoogleCliente, 'S'] : parametros.usarTokenGoogleCliente.filter((e) => e !== 'S'), pagina: 1});
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Form.Check
                                                                    type="switch"
                                                                    id="switch-usar-token-google-cliente-nao"
                                                                    label="não"
                                                                    checked={(parametros.usarTokenGoogleCliente.includes('N'))}
                                                                    onChange={e => {
                                                                        alterarParametros({...parametros, usarTokenGoogleCliente: (e.target.checked) ? [...parametros.usarTokenGoogleCliente, 'N'] : parametros.usarTokenGoogleCliente.filter((e) => e !== 'N'), pagina: 1});
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Form.Row>
                                                    </Col>
                                                </Row>
                                            }
            
                                            <hr/>
                                            <Row>
                                                <Col>
                                                    <Form.Row>
                                                        <Col>
                                                            <Form.Label>Banco de dados</Form.Label>
                                                        </Col>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Col>
                                                            <ComboBancosDadosMultiplo
                                                                ids={parametros.idsBancosDados || []}
                                                                alterou={idsBancosDados => {
                                                                    alterarParametros({...parametros, idsBancosDados});
                                                                }}
                                                            />
                                                        </Col>
                                                    </Form.Row>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </Col>
                            </Row>
                        </Popover.Content>
                    </Popover>
                }
            >
                <Button 
                    variant="padrao"
                    onClick={(e) => {
                        // alterarMostrarFiltros(!mostrarFiltros)
                    }}
                >
                    <FontAwesomeIcon className="icone" icon={mostrarFiltros ? ["fas", "search-minus"] : ["fas", "search-plus"]} />
                </Button>
            </OverlayTrigger>
        </div>
    </div>
}