import styled from 'styled-components';


export const CardsComponent = styled.div.attrs({
    className: ''
})`

    .container-cards {
        column-count: 5;
        grid-column-gap: 0px;
        -webkit-column-gap: 0px;
        -moz-column-gap: 0px;
        grid-column-gap: 1rem;
        column-gap: 1rem;
        box-sizing: inherit;
        position: relative;

        > div {
            min-height: 100vh;
        }
    }


`