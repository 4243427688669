import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Row, Col, Form, Button, InputGroup, Tabs, Tab, Spinner} from 'react-bootstrap';
import { toast } from 'react-toastify';
import useIsMounted from 'ismounted';
import {
  BarChart, Brush, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer
} from 'recharts';

import GraficoBarra from './../GraficoBarra';
import GraficoPie from '../GraficoPie';


// escreve o email
export default function CardUsuarios({
	parametros
}){

    // dados
    const permissaoReducer = useSelector(state => state.permissaoReducer);

    const [dados, alterarDados] = useState(null);
    const [carregando, alterarCarregando] = useState(false);

    // consulta os dados das empresas
    async function consultar(){

		// inicia carregamento
		alterarCarregando(true);

        try{
            
            // faz a requisição
            let { data } = await axios.get(`/relatorios/acessos/usuarios`, {
                params: Object.assign(Object.assign({}, parametros), {
					limite: 20 	// mantém o limite de 20 (top 20)
				})
            });
            
			alterarDados({...data});
			
        }catch(e){
            console.log('pegou o erro', e)
        }finally{
            alterarCarregando(false);
        }

	}

	// função para retornar os dados para o gráfico
	const retornaDadosGrafico = (usuarios) => {

		let dataGrafico = usuarios.map((usuario) => {

			let dadosFormatado = {
				name: `${usuario.usuario}<br/>${usuario.razao_social}`,
				acessos: usuario.acessos,
				atualizacoes: usuario.atualizacoes_pagina,
				novas_guias: usuario.novas_guias
			}

			return dadosFormatado;
		})

		return dataGrafico;

	}
	
    // inicializa
    useEffect(() => {

		// consulta acessos de empresa
		consultar()

    }, [parametros]);

    return <>
		<Card className="border-0">
			<Card.Header className='mb-0 border-0 bg-white'>
				Acessos por usuário
			</Card.Header>
			<Card.Body>
				{
					carregando  &&
					<Col align="center">
						<Spinner animation="border" />
					</Col>
				}
				{
					(!carregando && dados) &&
					<>
						{
							dados.dados.length === 0 ?

							<Col align="center">
								Sem dados para o gráfico
							</Col> 
							:
							<GraficoBarra
								dadosGrafico={retornaDadosGrafico(dados.dados)}
								config={{}}
							/>
						}
					</>
				}
			</Card.Body>
		</Card>
    </>
}