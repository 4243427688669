import React, { useState, useEffect, useContext } from 'react';
import * as R from 'ramda';
import ReactJson from 'react-json-view';
import axios from 'axios';
import queryString  from 'query-string';

// contexto
import { CadastrarContexto } from './../CadastrarContexto';

// componentes
import { DebounceInput } from 'react-debounce-input';
import ParametrosTesteContainer from './ParametrosTesteContainer';
import { Card, Form, Row, Col, Badge, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faSpinner, faPlay } from '@fortawesome/free-solid-svg-icons';

export default function AbaTeste(){

    const { dados, alterarDados, alterarUrlTeste,
        parametros, dadosUsuario, alterarDadosUsuario, pegarObjetoQuery,
        enderecoServidorUsuario, alterarEnderecoServidorUsuario
    } = useContext(CadastrarContexto);
    const [ajustado, alterarAjustado] = useState(false);
    const [previsaoBody, alterarPrevisaoBody] = useState({});
    const [retornoRequisicao, alterarRetornoRequisicao] = useState(null);
    const [carregandoUsuarioDados, alterarCarregandoUsuarioDados] = useState(false);

    const [parametrosTeste, alterarParametrosTeste] = useState(R.clone(parametros));


    // pega os dados que seram enviados no body
    function defineObjetoEnvioBody(parametrosPai){
        
        let body = {};

        // pega os dados
        parametrosPai
        .filter(param => !param.remover)
        .filter(param => param.dados.onde === 'body' && (param.teste || param.dados.obrigatorio === 'S'))
        .forEach(param => {

            // valor
            let valor = param.dados.valor;
            if(param.dados.tipo === 'json'){
                try{
                    if(typeof JSON.parse(param.dados.valor) === 'object'){
                        valor = JSON.parse(param.dados.valor);
                    }else{
                        valor = {};
                    }
                }catch({}){
                    valor = {};
                }
            }

            // converte para array
            if(param.dados.tipo === 'array'){
                valor = param.dados.valor.split(',');
            }

            // define valor
            body[param.dados.parametro] = valor;

            if(param.dados.tipo === 'objeto'){
                body[param.dados.parametro] = defineObjetoEnvioBody(param.filhos);
            }

            if(param.dados.tipo === 'objetoArray'){
                body[param.dados.parametro] = [defineObjetoEnvioBody(param.filhos)];
            }

        });

        // finaliza
        return body;

    }

    // ajusta os parametros convertendo os tipo de array objeto para array
    function ajustarParametros(parametrosPai){

        let novoArray = parametrosPai.map(param => {

            if(param.dados.tipo === 'objetoArray'){

                // faz uma copia
                param.copia = R.clone(param.filhos);
                param.filhosArray = [R.clone(param.filhos)];

            }

            param.filhos = ajustarParametros(param.filhos);

            return param;

        });

        return novoArray;

    }

    // consulta os dados do usuário para teste
    async function consultarUsuario(nomeUsuario){
        alterarCarregandoUsuarioDados(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/usuario/login/${nomeUsuario}`);
            alterarDadosUsuario(data);

            let enderecoServidor = data.empresa.enderecoServidor;
            if(enderecoServidor[enderecoServidor.length - 1] === '/'){
                enderecoServidor = enderecoServidor.slice(0, -1);
            }

            // define url do servidor do usuário
            alterarEnderecoServidorUsuario(enderecoServidor);


        }catch({response}){}
        finally{
            alterarCarregandoUsuarioDados(false);
        }
    }

    // praticar o real teste
    async function executarTesteApi(){

        alterarRetornoRequisicao(null);

        // instancia elemento zerado do axios
        let executarApi = axios.create({ headers: {
            'api-key': dadosUsuario.dados.tokenIntegrador
        }});

        let parametroQuery = pegarObjetoQuery(parametrosTeste);
        try{


            if(['get', 'delete'].includes(dados.metodo)){

                let { data, ...props } = await executarApi[dados.metodo](`${enderecoServidorUsuario}/api/v3${dados.url}`, { 
                    params: parametroQuery,
                    data: previsaoBody
                });

                // retorno requisição
                alterarRetornoRequisicao({
                    objeto: data,
                    status: props.status
                });

            }else if(['post', 'put'].includes(dados.metodo)){


                let { data, ...props } = await executarApi[dados.metodo](`${enderecoServidorUsuario}/api/v3${dados.url}`, previsaoBody, {
                    params: parametroQuery
                });

                // retorno requisição
                alterarRetornoRequisicao({
                    objeto: data,
                    status: props.status
                });

            }

        }catch({response}){

            if(response){
                alterarRetornoRequisicao({
                    objeto: response.data,
                    status: response.status
                });
            }
        }

    }

    useEffect(() => {
        if(ajustado){
            alterarPrevisaoBody(defineObjetoEnvioBody(parametrosTeste));

            // pega o objeto
            let parametrosQuery = pegarObjetoQuery(parametrosTeste);

            // codifica a query para salvar no navegador
            let encodeQuery = queryString.stringify(parametrosQuery, {arrayFormat: 'bracket'});
            alterarUrlTeste(encodeQuery);
        }
    }, [ajustado, parametrosTeste]);

    useEffect(() => {
        alterarParametrosTeste(ajustarParametros(parametrosTeste));
        alterarAjustado(true);
    }, []);

    // ajustado
    if(!ajustado){
        return <></>
    }

    return <>
        <Row>
            <Col lg={7}>
                <ParametrosTesteContainer 
                    parametrosTeste={parametrosTeste}
                    alterarParametrosTeste={() => {
                        alterarParametrosTeste(R.clone(parametrosTeste));
                    }}
                />
            </Col>
            <Col lg={5}>
                <div className='mt-2'>
                    <small>Dados para envio</small>
                    <Card
                        className='border-0 bg-dark'
                    >
                        <Card.Body
                            style={{overflowX: 'scroll'}}
                        >
                            <ReactJson 
                                name={false}
                                style={{
                                    whiteSpace: 'nowrap',
                                    background: 'transparent'
                                }}
                                theme='monokai'
                                collapsed={false}
                                src={previsaoBody}
                            />
                        </Card.Body>
                    </Card>
                </div>
                <div className='mt-2'>
                    <Form.Row className="mt-3">
                        <Col lg={{span: 4, offset: 8}}>
                            <div className="d-flex align-items-center">
                                <DebounceInput 
                                    placeholder="Usuario testar" 
                                    debounceTimeout={400}
                                    element={Form.Control}
                                    onChange={(e) =>{
                                        consultarUsuario(e.target.value);
                                    }}
                                    className='form-control-sm mr-2'
                                />
                                <Button
                                    variant="success"
                                    size="sm"
                                    onClick={executarTesteApi}
                                    disabled={carregandoUsuarioDados || dadosUsuario === null}
                                >
                                    <FontAwesomeIcon 
                                        className="fa-fw"
                                        icon={carregandoUsuarioDados ? faSpinner : faPlay} 
                                        pulse={carregandoUsuarioDados}
                                    />
                                </Button>
                            </div>
                        </Col>
                    </Form.Row>

                    {retornoRequisicao !== null ? 
                        <small>Status <Badge variant={retornoRequisicao.status === 200 ? 'success' : 'danger'}>{retornoRequisicao.status}</Badge></small> 
                        :
                        <small>Retorno da requisição</small>
                    }
                    <Card
                        className='border-0 bg-dark'
                    >
                        <Card.Body
                            style={{overflowX: 'scroll'}}
                        >
                            {retornoRequisicao !== null &&
                                <ReactJson 
                                    name={false}
                                    style={{
                                        whiteSpace: 'nowrap',
                                        background: 'transparent'
                                    }}
                                    theme='monokai'
                                    collapsed={true}
                                    src={retornoRequisicao.objeto}
                                />
                            }
                        </Card.Body>
                    </Card>
                </div>
            </Col>
        </Row>
    </>
}