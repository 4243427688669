
import axios from 'axios';

// configura padrões axios // verifica rotas na api
axios.defaults.baseURL = `${process.env.REACT_APP_URL_PHP}`;
axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.headers.common['tokenCliente'] = 'token aqui';

axios.interceptors.response.use(
function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log(response);
    return response;
}, 
function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.log(error);

    // erro no servidor
    if(error.response){

        // se for erro de autorização invalida
        if(error.response.status === 401){

            // token inválido, usuário precisa relogar
            window.alert('você precisa refazer login!');

        }

        // erros relacionados ao banco
        if(error.response.status === 422){

            // token inválido, usuário precisa relogar
            window.alert('Requisição inválida!');
            console.error(error.response.data);

        }



    }else if(error.request){

        // erro na requisição
        window.alert('Erro de conexão!');

    }else{
        window.alert('Erro na solicitação');
    }

    // promessa rejeitada
    return Promise.reject(error);
});