import { useEffect, useState } from "react";
import axios from "axios";
import Chart from "react-apexcharts";

import Select from 'react-select';
import { Card, Col, Row } from "react-bootstrap";

import { RelatorioIntegracaoComponent } from "./components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function RelatorioPedidosIntegracao(){

    // estados
    const [ carregando, alterarCarregando ] = useState(false);
    const [ parametros, alterarParametros ] = useState({
        dataInicio: null,
        dataFinal: null,
        situacaoEmpresa: ['cliente', 'parceiro', 'demo']
    });
    const [ totalIntegracoes, alterarTotalIntegracoes ] = useState(0);
    const [ totalEmpresasIntegradas, alterarTotalEmpresasIntegradas ] = useState(0);
    const [ totalEmpresasIntegrando, alterarTotalEmpresasIntegrando ] = useState(0);
    const [ totalIntegracaoSistemas, alterarTotalIntegracaoSistemas ] = useState(0);
    const [ totalEmpresasPedidos, alterarTotalEmpresasPedidos ] = useState(0);
    const [ registrosTotaisPorIntegracao, alterarRegistrosTotaisPorIntegracao ] = useState([]);
    
    // array para o select de modo
    const modosCliente = [
        {value: 'cliente', label: 'Cliente'},
        {value: 'parceiro', label: 'Parceiro'},
        {value: 'bloqueado', label: 'Bloqueado'},
        {value: 'cancelado', label: 'Cancelado'},
        {value: 'demo', label: 'Demo'}
    ];

    useEffect(() => {

        // consulta
        consultar();
    }, [parametros]);

    // função para a consulta
    async function consultar(){
     
        // cria o carregamento
        alterarCarregando(true);

        try{
            
            // consulta
            let { data } = await axios.get('/integracaoSistemas/relatorio', {
                params: {
                    ...parametros
                }
            });

            // altera informações para os relatórios
            alterarTotalIntegracoes(data.totalIntegracoes);
            alterarTotalEmpresasIntegradas(data.totalEmpresasIntegradas);
            alterarTotalEmpresasIntegrando(data.totalEmpresasIntegrando);
            alterarTotalIntegracaoSistemas(data.totalIntegracaoSistemas);
            alterarTotalEmpresasPedidos(data.totalEmpresasPedidos);
            alterarRegistrosTotaisPorIntegracao(data.totaisPorIntegracao);

        }catch(e){
            // erro
        }finally{

            // remove o carregamento
            alterarCarregando(false);
        }
    }

    return <RelatorioIntegracaoComponent>
        {/* <Col className="py-2 px-0"> */}
            <Row className="px-2" style={{gap: '1rem', height: '100%'}}>
                <Col className="px-0">
                    <Card className="m-0 border-0 card-totais-gerais">
                        <Card.Body className="p-0 pb-2">
                            <Select
                                className="select-ficha"
                                options={modosCliente}
                                value={modosCliente.filter((e) => parametros.situacaoEmpresa.includes(e.value))}
                                isMulti={true}
                                placeholder='Situação empresa'
                                onChange={(e) => {
                                    e = e || []; // mantem vazio se for null

                                    // altera os filtros selecionados
                                    alterarParametros({...parametros, situacaoEmpresa: e.map((option) => option.value), pagina: 1});
                                }}
                            />
                        </Card.Body>
                    </Card>
                    <Card className="m-0 card-totais-gerais position-relative">
                        <Card.Body>
                            <div className="total-geral">
                                <span className={"total integracao"}>
                                    {totalIntegracoes}
                                </span>
                                integrações
                                <div className="sub-total">
                                    <div className="total-geral">
                                        <span className={"total empresas-integrando"}>
                                            {totalEmpresasIntegrando > 0 && `+`}
                                            {totalEmpresasIntegrando}
                                        </span>
                                        integrando
                                    </div>
                                    <div className="total-geral">
                                        <span className={"total pedidos-integracao"}>
                                            {totalEmpresasPedidos > 0 && `+`}
                                            {totalEmpresasPedidos}
                                        </span>
                                        pedidos
                                    </div>
                                </div>
                            </div>
                            <div className="total-geral">
                                <span className={"total empresas-integradas"}>
                                    {totalEmpresasIntegradas}
                                </span>
                                empresas
                            </div>
                            <div className="total-geral">
                                <span className={"total integracao-sistemas"}>
                                    {totalIntegracaoSistemas}
                                </span>
                                sistemas
                            </div>
                            {carregando && <div
                                className="p-5 w-100 h-100 m-auto position-absolute text-center d-flex align-items-center justify-content-center"
                                style={{top: 0}}
                            >
                                <FontAwesomeIcon icon={faSpinner} pulse /> Carregando...
                            </div>}
                        </Card.Body>
                    </Card>
                </Col>
                <Col
                    sm={12}
                    md={8}
                    className="px-0"
                >
                    <div
                        className={['grafico-content', 'p-1', 'border', 'rounded', 'position-relative'].join(' ')}
                    >
                        <Chart
                            height={(
                                registrosTotaisPorIntegracao.length * 20 < 350 ? 350 : (registrosTotaisPorIntegracao.length * 20)
                            )}
                            options={{
                                colors: ['#3aa872', '#11ace3', '#f3910d'],
                                labels: registrosTotaisPorIntegracao.map((registro) => {
                                    // retorna nome da integração
                                    return registro.nome;
                                }),
                                legend: {
                                    position: 'top'
                                },
                                chart: {
                                    id: 'totalEmpresasPorIntegracao',
                                    stacked: true
                                },
                                plotOptions: {
                                    bar: {
                                        horizontal: true,
                                        // barHeight: 90
                                    }
                                },
                                zoom: {
                                    enabled: true,
                                    type: 'x',
                                },
                                xaxis: {
                                    integracao: registrosTotaisPorIntegracao.map((registro) => {
                                        // retorna nome da integração
                                        return registro.nome;
                                    })
                                }
                            }}
                            series={[
                                {
                                    name: 'Total empresas integradas',
                                    data: registrosTotaisPorIntegracao.map((registro) => {
                                        return parseInt(registro.totalEmpresasIntegradas);
                                    })
                                },
                                {
                                    name: 'Total empresas integrando',
                                    data: registrosTotaisPorIntegracao.map((registro) => {
                                        return parseInt(registro.totalEmpresasIntegrando);
                                    })
                                },
                                {
                                    name: 'Total pedidos',
                                    data: registrosTotaisPorIntegracao.map((registro) => {
                                        return parseInt(registro.totalEmpresasPedidos);
                                    })
                                }
                            ]}
                            type="bar"
                        />
                        {carregando && <div
                            className="p-5 w-100 h-100 m-auto position-absolute text-center d-flex align-items-center justify-content-center"
                            style={{top: 0}}
                        >
                            <FontAwesomeIcon icon={faSpinner} pulse /> Carregando...
                        </div>}
                    </div>
                </Col>
            </Row>
        {/* </Col> */}
    </RelatorioIntegracaoComponent>
    
}