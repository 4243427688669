import React from 'react';
import { useHistory } from 'react-router-dom';

// components
import { Card, Badge, Container } from 'react-bootstrap';
import Registro from './Registro';

// componente
export default function Api({
    dados=null,
    registros
}){

    // estados
    const history = useHistory();


    return <>
        <Card className="bg-light my-3">
            <Card.Body>
                {dados && 
                    <p className="font-weight-bold">Clientes</p>
                }

                <div>
                    <table className="table tabela">
                        <tbody>
                            { registros.map(registro => 
                                <Registro 
                                    key={registro.dados.id}
                                    dados={registro.dados}
                                    clicouEditar={() => {
                                        history.push(`/painel/documentacao-api/alterar/${registro.dados.id}`);
                                    }}
                                />
                            )}
                        </tbody>
                    </table>
                </div>
            </Card.Body>
        </Card>
    </>

}