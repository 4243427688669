import { formatarTamanhoBytes } from "../../../../../Funcoes";

export default function Registro({
    indice,
    total,
    tamanhoTotal,
    dadosEmpresa,
    relatorio,

    clicouRegistro
}){

    return <> 

        <tr
            className={['registro'].join(' ')}
            style={{cursor: 'pointer'}}
            onClick={clicouRegistro}
        >

            <td className={'text-center font-weight-bold indice'}>
                {indice}
            </td>
            <td className={'total text-center font-weight-bold'}>
                {total}
            </td>
            <td className={'tamanho text-center font-weight-bold'}>
                {formatarTamanhoBytes(tamanhoTotal)}
            </td>
            <td className="descricao">
                <div className="d-flex align-items-center" style={{gap: 3}}>
                    {dadosEmpresa.razaoSocial} <small>({dadosEmpresa.codigo})</small>
                </div>
                <div className="totais">
                    {
                        relatorio.map((r, i) => {
                            return <small className="relatorio-tag" key={r.tag}>
                                <b>{r.descricao}</b>: {formatarTamanhoBytes(r.tamanhoTotal)} ({r.total} arquivos)
                            </small>
                        })
                    }
                </div>
            </td>
        </tr>
    </>
}