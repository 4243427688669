import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory, useRouteMatch } from "react-router-dom";

import axios from 'axios';
import { toast } from 'react-toastify';

// componentes
import Select from 'react-select';
import useIsMounted from 'ismounted';
import { Modal, Container, Card, Row, Col, Form, Button, InputGroup, Spinner, Alert, OverlayTrigger, Tooltip, Badge} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRandom } from '@fortawesome/free-solid-svg-icons';

import { intersectObject, copiaCampo } from './../../../../Funcoes';
import { FichaComponent } from './components';

// contexto
import { PainelContexto } from './../../../PainelContexto';
import InputDataCombo from '../../../../Componentes/InputDataCombo';
import InputHora from '../../../../Componentes/InputHora';
import Confirmar from '../../../../Componentes/Confirmar';
import moment from 'moment';

// faz o cadastro
export default function ModalCancelar({
    
}){

    // dados
    const isMounted = useIsMounted();
    const { path, url } = useRouteMatch();
    const painelContextoDados = useContext(PainelContexto);
    const history = useHistory();

    // parametros da url
    const {
        id = null
    } = useParams();
    
    // estados
    const [ dados, alterarDados ] = useState({
        motivo: null
    });
    const [ salvando, alterarSalvando ] = useState(false);
    const [ carregando, alterarCarregando ] = useState(false);
    const [ confirmar, alterarConfirmar ] = useState(null);
    const [ mostrar, alterarMostrar ] = useState(true);

    // inicializa
    useEffect(() => {
        
    }, []);

    // finaliza
    async function salvar(){

        // salva registro
        alterarSalvando(true);

        // faz a requisição
        try{

            // faz a requisição
            let { data } = await axios.post(`/empresas/pedidosIntegracao/${id}/cancelar`, {
                dados: {
                    motivo: dados.motivo
                }
            });

            // mensagem de cadastro realizado com sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Integração cancelada!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </>);

            // altera
            alterarSalvando(false);
            alterarConfirmar(null);
            
            // monta a url para retroceder
            let urlBack = url.split("/pedidosIntegracao").map((parte, i) => i > 0 ? '/pedidosIntegracao' : parte).join('');
            
            // volta e dá refresh pra atualizar a listagem
            history.push(urlBack, {refresh: true});

        }catch({response}){

            // informa erro
            toast(<>
                <div
                    style={{background: '#ff6271'}}
                >
                    <div className="toast-header">
                        <strong className="mr-auto">Integração não pôde ser cancelada</strong>
                        <button 
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                        </button>
                    </div>
                    <div className="toast-body text-light">
                        {
                            (response && response.data) &&
                            Object.keys(response.data).map((erro) => <p className={'m-0'} key={erro}>{response.data[erro]}<br/></p>)
                        }
                    </div>
                </div>
            </>);

        }finally{
            if (isMounted.current) {
                alterarSalvando(false);
            }
        }
    }

    return <>
        <Confirmar config={confirmar} alterar={alterarConfirmar}/>
    
        {(!confirmar || !confirmar.aberto) && <Modal 
            show={mostrar}
            centered
            onExited={() => {

                // monta a url para retroceder
                let urlBack = url.split("/pedidosIntegracao").map((parte, i) => i > 0 ? '/pedidosIntegracao' : parte).join('');
                
                // volta e dá refresh pra atualizar a listagem
                history.push(urlBack, {
                    aPartirDe: 'visualizarPedidoIntegracao'
                });
                
                // fecha
                alterarMostrar(false);
            }}
            onHide={() => {

                // fecha
                alterarMostrar(false);
            }}
            size={'md'}
        >
            <Modal.Header className='border-0'>
                <Modal.Title 
                    as='h5' 
                    className={[
                        'text-center w-100',
                        'text-success'
                    ].join(' ')}
                >
                    Cancelar integração
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={'pt-0'}>
                <FichaComponent>
                    <Container>
                        <Form.Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Motivo cancelamento</Form.Label>
                                    <textarea
                                        className="form-control"
                                        style={{minHeight: 50, fontSize: 14}}
                                        value={dados.motivo || ''}
                                        onChange={e => {
                                            alterarDados({...dados, motivo: e.target.value});
                                        }}
                                    ></textarea>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </Container>
                </FichaComponent>
            </Modal.Body>
            <Modal.Footer className='border-0 d-flex justify-content-between'>
                <Button
                    variant='light'
                    onClick={() => {alterarMostrar(false)}}
                    disabled={salvando}
                >
                    <span>Fechar</span>
                </Button>
                
                <Button
                    onClick={() => {

                        // confirma
                        alterarConfirmar({
                            aberto: true,
                            titulo: 'Cancelar integração',
                            texto: `Tem certeza que deseja cancelar esta integração?`,
                            textoBotao: 'Cancelar integração',
                            variantConfirmar: 'danger',
                            variantCancelar: 'light',
                            backdrop: true,
                            aoConfirmar: () => {

                                // conclui
                                salvar();
                            },
                            aoCancelar: () => {
                                // ao cancelar
                            },
                            aoFechar: () => {
                                // ao fechar
                            }
                        })
                    }}
                    variant='danger'
                    disabled={salvando}
                >
                    {salvando ? 
                        <>
                            <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                            <span>Cancelando</span>
                        </> : 
                        <span>Cancelar integração</span>
                    }
                </Button>
            </Modal.Footer>
        </Modal>}
    </>
}   