import moment from "moment";
import { Badge, Card } from "react-bootstrap";
import { isBrowser, isMobile } from "react-device-detect";
import { faFileAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import BotaoTabela from "../../../../Componentes/BotaoTabela";

export default function Registro({
    indice,
    dados,
    dadosEmpresa
}){

    return <>
        <tr
            className={[
                'registro'
            ].join(' ')}
        >
            <td className={'text-center font-weight-bold indice'}>
                {indice}
            </td>

            <td className="descricao">
                <div className="d-flex align-items-center" style={{gap: 3}}>
                    {
                        dadosEmpresa ? <>
                            {dadosEmpresa.razaoSocial} <small>({dadosEmpresa.codigo})</small>
                        </> : <>
                            <i>Sem empresa</i>
                        </>
                    }
                </div>
                <div className="totais">
                    <small className="total geral">
                        <b>Geral: </b> {dados.total}
                    </small>
                    <small className="total login">
                        <b>Login: </b> {dados.totalLogin}
                    </small>
                    <small className="total refresh">
                        <b>Atualizar página: </b> {dados.totalRefresh}
                    </small>
                    <small className="total new-tab">
                        <b>Nova guia: </b> {dados.totalNewtab}
                    </small>
                </div>
            </td>
        </tr>
    </>
}