import * as React from "react";

const SvgUsuariosIcone = (props) => {
  props = {
    width: 15.5,
    height: 15.5,
    ...props,
  };
  return (
    <svg viewBox="0 0 128 128" {...props}>
      <style />
      <g id="usuarios-icone_svg__Camada_2">
        <radialGradient
          id="usuarios-icone_svg__SVGID_1_"
          cx={64.113}
          cy={36.801}
          r={23.945}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#4f33d7" />
          <stop offset={1} stopColor="#382499" />
        </radialGradient>
        <path
          d="M64.1 62.9c11.9 0 21.5-11.7 21.5-26.1 0-14.4-3.2-26.1-21.5-26.1-18.4 0-21.5 11.7-21.5 26.1 0 14.4 9.6 26.1 21.5 26.1z"
          fill="url(#usuarios-icone_svg__SVGID_1_)"
        />
        <radialGradient
          id="usuarios-icone_svg__SVGID_2_"
          cx={64.113}
          cy={91.259}
          r={34.24}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#4f33d7" />
          <stop offset={1} stopColor="#382499" />
        </radialGradient>
        <path
          d="M104.7 101.8c-.4-25.2-3.7-32.3-28.8-36.9 0 0-3.5 4.5-11.8 4.5-8.2 0-11.8-4.5-11.8-4.5C27.5 69.4 24 76.5 23.5 101c0 2-.1 2.1-.1 1.9v2.6s6 12.1 40.7 12.1 40.7-12.1 40.7-12.1v-2c0 .2-.1-.1-.1-1.7z"
          fill="url(#usuarios-icone_svg__SVGID_2_)"
        />
        <g>
          <radialGradient
            id="usuarios-icone_svg__SVGID_3_"
            cx={98.736}
            cy={37.043}
            r={17.505}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#725cdf" />
            <stop offset={1} stopColor="#4f33d7" />
          </radialGradient>
          <path
            d="M94 58.3c9.7 0 17.5-9.5 17.5-21.2S108.9 15.9 94 15.9c-2.5 0-4.7.3-6.5.8 3.4 6.3 3.9 14.1 3.9 20.2 0 7-1.9 13.6-5.4 19.1 2.4 1.4 5.1 2.3 8 2.3z"
            fill="url(#usuarios-icone_svg__SVGID_3_)"
          />
          <radialGradient
            id="usuarios-icone_svg__SVGID_4_"
            cx={110.086}
            cy={80.565}
            r={18.88}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#725cdf" />
            <stop offset={1} stopColor="#4f33d7" />
          </radialGradient>
          <path
            d="M127 89.8c-.3-20.4-3-26.2-23.4-29.9 0 0-2.9 3.7-9.6 3.7h-.8c4.3 1.9 8.1 4.6 11 8.5 4.9 6.8 6.1 15.8 6.3 29.2 13.7-2.7 16.6-8.4 16.6-8.4v-1.6c-.1.1-.1-.1-.1-1.5z"
            fill="url(#usuarios-icone_svg__SVGID_4_)"
          />
        </g>
        <g>
          <radialGradient
            id="usuarios-icone_svg__SVGID_5_"
            cx={29.491}
            cy={37.044}
            r={17.505}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#725cdf" />
            <stop offset={1} stopColor="#4f33d7" />
          </radialGradient>
          <path
            d="M34.2 58.3c2.9 0 5.6-.8 8-2.3-3.5-5.5-5.4-12.2-5.4-19.1 0-6.2.5-13.9 3.9-20.2-1.9-.5-4-.8-6.5-.8-14.9 0-17.5 9.5-17.5 21.2.1 11.7 7.9 21.2 17.5 21.2z"
            fill="url(#usuarios-icone_svg__SVGID_5_)"
          />
          <radialGradient
            id="usuarios-icone_svg__SVGID_6_"
            cx={18.139}
            cy={80.565}
            r={18.88}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#725cdf" />
            <stop offset={1} stopColor="#4f33d7" />
          </radialGradient>
          <path
            d="M35.1 63.5h-.8c-6.7 0-9.6-3.7-9.6-3.7-20.4 3.8-23.1 9.6-23.4 30v3s2.8 5.7 16.5 8.4c.2-13.3 1.3-22.4 6.3-29.2 2.8-3.9 6.7-6.5 11-8.5z"
            fill="url(#usuarios-icone_svg__SVGID_6_)"
          />
        </g>
      </g>
    </svg>
  );
};

export default SvgUsuariosIcone;
