import { combineReducers } from 'redux';
import { withReduxStateSync } from 'redux-state-sync';

// importa reducers e actions
import LoginReducer from './Sistema/Login/LoginReducer';
import PermissaoReducer from './Sistema/Painel/Permissao/PermissaoReducer';
// import ConfiguracaoReducer from './Sistema/Painel/ConfiguracaoReducer';

// unifica reducers
let rootReducer  = combineReducers({
    loginReducer: LoginReducer,
    permissaoReducer: PermissaoReducer
    // ConfiguracaoReducer: ConfiguracaoReducer
});

// retorna reducer global
export default withReduxStateSync(rootReducer);