import React from 'react';
import styles from './index.module.scss';

// componentes
import { Badge } from 'react-bootstrap';
import BotaoTabela from './../../../../Componentes/BotaoTabela';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// elemento
function Registro({
    dados,
    clicouAbrir
}){

    // status do email
    let statusEmail = {
        '1': {descricao: 'Aguardando', cor: 'light'},
        '2': {descricao: 'Erro', cor: 'danger'},
        '3': {descricao: 'Enviado', cor: 'success'},
    };

    return <tr>
        <td className='estreito text-center'>
            <Badge variant={statusEmail[dados.status].cor}>{statusEmail[dados.status].descricao}</Badge>
        </td>
        <td>
            { (dados.tipo_aniversariante === 'S') && 
                <FontAwesomeIcon className="icone mr-1" icon={["fas", 'birthday-cake']} />
            }
            {dados.titulo}
        </td>
        <td>{dados.para_email}</td>
        {   dados.status !== '1' &&
            <>
                { (dados.data_enviado !== null && dados.data_reenvio === null) &&
                    <td>{moment(dados.data_enviado).format('DD/MM/YYYY')} as {moment(dados.data_enviado).format('hh:mm')}</td>
                }

                { (dados.data_reenvio !== null) &&
                    <td>{moment(dados.data_reenvio).format('DD/MM/YYYY')} as {moment(dados.data_reenvio).format('hh:mm')}</td>
                }
            </>
        }
        {   dados.status === '1' &&
            <>
                { dados.agendado_para ?
                    <td>
                        {moment(dados.agendado_para).format('DD/MM/YYYY')} as {moment(dados.agendado_para).format('hh:mm')}
                        <FontAwesomeIcon title='Agendado' className="icone ml-1" icon={["fas", 'clock']} />
                    </td>
                    :
                    <td>Enviando...</td>
                }
            </>
        }

        <td className="text-nowrap estreito">
            <BotaoTabela 
                onClick={clicouAbrir}
                icone={["fas", "envelope-open"]}
            >
                Abrir
            </BotaoTabela>
        </td>
    </tr>

}

export default Registro;