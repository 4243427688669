import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory, useParams, useRouteMatch } from "react-router-dom";

// componentes
import Consultar from './Consultar';
import ModalCadastro from './ModalCadastro';
import { Tab, Tabs } from 'react-bootstrap';
import { ServidoresComponent } from './components';
import EmpresasAguardandoInstalacao from './EmpresasAguardandoInstalacao';
import EmpresasDeletarBase from './EmpresasDeletarBase';

// escreve o email
export default function Servidores(){
    
    // contextos
    const { loginReducer, permissaoReducer } = useSelector(state => state);
    
    // pega parametros
    const params = useParams();
    const tabInicial = params.tab ?? null;

    // prepara o histórico da página
    const history = useHistory();
    const {
        path,
        url
    } = useRouteMatch();

    // estados
    const [ tab, alterarTab ] = useState(tabInicial ?? '');

    useEffect(() => {

        // verifica se alterou a tab
        if((tabInicial ?? '') !== tab){

            // define
            alterarTab(tabInicial ?? '');
        }
        console.log(tabInicial)
    }, [tabInicial]);

    return <ServidoresComponent>

        <div className={[].join(' ')}>
            <Tabs
                id="servidores-tabs"
                className={['tabs', 'mb-2'].join(' ')}
                activeKey={tab}
                onSelect={(t) => {

                    // altera
                    alterarTab(t);

                    // prepara
                    let caminhoTab = url.replace(/\/$/, '');
                    
                    // verifica se possui a tab
                    if(t){

                        // adiciona
                        caminhoTab += `/${t}`;
                    }

                    // verifica se possui tab inicial
                    if(tabInicial){

                        // altera para remover a última tab
                        caminhoTab = `./${t}`;
                    }

                    // adiciona
                    history.push(caminhoTab);
                }}
            >
                <Tab eventKey="" title="Servidores">
                    <Consultar />

                    {/* rotas */}
                    <Route exact path={[`/painel/servidores/cadastrar`, `/painel/servidores/editar/:id`]}>
                        <ModalCadastro />
                    </Route>
                </Tab>
                <Tab eventKey="aguardandoInstalacao" title="Aguardando instalação">
                    <EmpresasAguardandoInstalacao />
                </Tab>
                {permissaoReducer.permitirControleBaseClientes === 'S' && <Tab eventKey="empresasDeletarBase" title="Empresas deletar base">
                    <EmpresasDeletarBase />
                </Tab>}
            </Tabs>
        </div>
    </ServidoresComponent>
}
