import React from 'react';
import styles from './index.module.scss';
import { Button, Badge } from 'react-bootstrap';
import { isMobile, isBrowser } from "react-device-detect";

// paginação
function Paginacao({
    totalRegistros,
    registrosPorPagina,
    pagina,
    alterouPagina,
    desabilitarNumeros=false
}){

    if(totalRegistros === 0){
        return '';
    }

    // calcula o total de páginas
    let totalPaginas = Math.ceil(totalRegistros / registrosPorPagina) || 1;

    // cria os botões
    let botoes = [];

    for(let a = 1;a <= totalPaginas;a++){

        if(((a - 2) <= pagina) && ((a + 2) >= pagina)){
            botoes.push(a);
        }
    }

    //botoes.push(totalPaginas);
    if(!botoes.includes(totalPaginas)){
        botoes.push(totalPaginas);
    }

    if(pagina > 3){
        botoes.unshift(1);
    }

    // retorna paginação
    return <div className={[styles.paginacao, 'paginacao'].join(' ')}>
        {/* {totalPaginas} */}

        <Button 
            className={styles.botaoVoltar} 
            variant="light" 
            disabled={pagina == 1}
            onClick={() => {
                alterouPagina(pagina - 1);
            }}>Voltar</Button>

        <div className={styles.paginas}>
            {/* <Button className={styles.botao} variant="padrao">1</Button>
            <Button className={styles.botao} variant="padrao">2</Button>
            <Button className={styles.botao} variant="padrao">3</Button> */}

            
            { isMobile && 
                <p className='m-0 p-2'>
                    <b>{pagina}</b> de <b>{totalPaginas}</b>
                </p>
            }

            { (isBrowser && !desabilitarNumeros) &&
                <>
                    {botoes.map((botao, a) => (
                        <React.Fragment key={a} >
                            { ((totalPaginas === botao) && (pagina < botao - 3) ) && (
                                <span className={styles.espaco}>...</span>
                            )}

                            <Button 
                            onClick={() => {
                                alterouPagina(botao);
                            }}
                            disabled={pagina == botao}
                            className={[styles.botao, (pagina == botao)? styles.ativo : ''].join(' ')}

                            variant="light"
                            
                            >
                                {botao}
                            </Button>

                            { (botao === 1 && pagina > 3) && (
                                <span className={styles.espaco}>...</span>
                            )}

                        </React.Fragment>
                    ))}
                </>
            }


        </div>

        <Button
            className={styles.botaoAvançar} 
            onClick={() => {
                alterouPagina(pagina + 1);
            }} 
            disabled={pagina == totalPaginas}
            variant="light">
                Avançar
        </Button>
        
        <div
                className={[
                    'total-registros',
                    styles.totalRegistros,
                    'p-1 px-3 rounded bg-info ml-3 font-weight-bold text-white'
                ].join(' ')}
        >
            {totalRegistros}
        </div>
    </div>
}

export default Paginacao;