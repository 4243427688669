import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const ListaIntegracaoSistemasComponent = styled.div.attrs({
    className: `painel-consulta border-0`
})`
    
    .card-body{
        padding-top: 0.5rem;
    }

    .select-ficha, .aba-usuarios .select{
        font-size: 0.875rem;

        > div:nth-of-type(1){
            padding: 0;
            min-height: unset;

            > div{
                padding: 0 8px;

                &:last-child{
                    padding-right: 4px;
                }

            }

            > div:nth-of-type(2){
                > div{
                    padding: 0 8px;
                }
            }
        }
    }

    .form-control{
        min-height: unset;
        height: 31px;
    }

    .btn{
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
    }

    .custom-switch{
        font-size: 0.9rem;
        padding-left: 2.25em;

        .custom-control-input{
            width: 1em;
            height: 1.25em;
            
            &:checked ~ .custom-control-label::after{
                transform: translateX(0.75em);
            }
        }
        .custom-control-label{
            &::before{    
                top: 0.25em;
                left: 1.5em;
                display: block;
                width: 1em;
                height: 1em;
                
                left: -2.25em;
                width: 1.75em;
                border-radius: 0.5em;
                
            }

            &::after{
                top: calc(0.25em + 2px);
                left: calc(-2.25em + 2px);
                width: calc(1em - 4px);
                height: calc(1em - 4px);
                border-radius: 0.5em;
            }
        }
    }

    .card{

        > .card-header{
            border-left: 3px solid var(--cor-cliente-ficha) !important;
        }

        .card{
            > .card-header{
                border-left: unset !important;
            }
        }
    }

    .tab-content{
        position: relative;

        .cor-modo-cliente{
            position: absolute;
            top: 0;
            left: 0;
            font-size: 23px;
            opacity: 0.8;
            margin: -1px -1px;
            filter: drop-shadow(0px 0px 2px grey);
        }
    }

    .registro{
        --cor-status-registro: var(--cor-demo-em-teste);

        &.integrado{
            --cor-status-registro: #03c903;
        }

        &.processo{
            --cor-status-registro: #0db7f3;
        }

        &.aguardo{
            --cor-status-registro: #f3910d;
        }
    }

    td{
        
        &:first-child{
            position: relative;

            &::after{
                content: " ";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: 1px 0;
                background: var(--cor-status-registro);
                width: 5px;
            }
        }

        .quantidade-empresas{
            background-color: #099d95;
            color: white;
        }
    }
`;