import React, { useState, useMemo, useEffect } from 'react';
import ReactJson from 'react-json-view';

export default function DescricaoJson({
    name=false,
    value,
    onChange
}){

    const [valor, alterarValor] = useState(value);
    const [nome, alterarNome] = useState(name);

    useEffect(() => {
        alterarValor(value);
    }, [value]);

    useEffect(() => {
        alterarNome(name);
    }, [name]);

    

    return useMemo(() => {

        return <ReactJson 
                name={nome}
                style={{
                    whiteSpace: 'nowrap',
                    background: 'transparent',
                    minHeight: 80
                }}
                theme='monokai'
                collapsed={false}
                // src={JSON.parse(registro.dados.valor)}
                src={valor}
                onAdd={add => {
                    onChange(add.updated_src);
                }}
                onEdit={(add) => {
                    onChange(add.updated_src)
                }}
                onDelete={(add) => {
                    onChange(add.updated_src)
                }}
            />
    }, [valor, nome]);

}