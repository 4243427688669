import { faClock, faDatabase, faFile, faShare } from "@fortawesome/free-solid-svg-icons";
import { isBrowser, isMobile } from "react-device-detect";
import { Badge, Card, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BotaoTabela from "../../../../Componentes/BotaoTabela";
import { formatarTamanhoBytes } from "../../../../Funcoes";
import moment from "moment";

export default function Registro({
    dados,
    arquivo,

    clicouAbrir,
    clicouInstalar
}){

    // retorna opções
    let retornarOpcoes = () => {

        // retorna componentes
        return <>
            <BotaoTabela 
                onClick={clicouAbrir}
                icone={faFile}
            >
                <span>Abrir</span>
            </BotaoTabela>
            <BotaoTabela 
                onClick={clicouInstalar}
                icone={faShare}
            >
                <span>Instalar</span>
            </BotaoTabela>
        </>
    }

    // prepara o ícone
    let classe = dados.situacao;

    // verifica se é light
    if(dados.planoSistema == 'light'){

        // adicona
        classe += 'light';
    }

    // pega a diferença de dias
    let diferencaDias = moment().diff(moment(dados.dataCliente), "days");

    // verifica se é hoje
    if(diferencaDias == 0){

        // define a descrição
        diferencaDias = 'hoje';
    }else{

        // define a descrição
        diferencaDias = `há ${diferencaDias} dia(s)`;
    }

    return <> 

        {/* NAVEGADOR */}
        { isBrowser &&
            <tr
                style={{cursor: 'pointer'}}
                onClick={clicouAbrir}
                className={['registro', classe].join(' ')}
            >
                <td className={'icone estreito text-center font-weight-bold'}>
                    {dados.codigo}
                </td>
                <td>
                    <div>
                        <span className="destaque"><b>{dados.razaoSocial}</b> (<small>{dados.cnpj}</small>)</span><br/>
                        <small>Cliente em: {moment(dados.dataCliente).format('DD/MM/YYYY')} <small>- <b>{diferencaDias}</b></small></small>
                    </div>
                </td>
                <td className={'text-nowrap estreito'}>
                    { retornarOpcoes() }
                </td>
            </tr>
        }

        {/* MOBILE */}
        {isMobile &&
            <Card
                className={'border-0 bg-light mb-2'}
            >
                <Card.Body className={'p-2'}>
                    <div className={[isBrowser ? 'd-flex' : ''].join(' ')}>
                        <div className={'flex-grow-1'}>
                            <div className="d-flex align-items-center" style={{gap: 3}}>
                                <span className="destaque"><b>{dados.razaoSocial}</b> (<small>{dados.cnpj}</small>)</span><br/>
                                <small>Cliente em: {moment(dados.dataCliente).format('DD/MM/YYYY')}</small>
                            </div>
                        </div>
                        <div className={'text-right'}>
                            { retornarOpcoes() }
                        </div>
                    </div>
                </Card.Body>
            </Card>
        }
    </>
}