import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { ListaComponent } from '../../../Componentes/components';

export const EmpresasInstalacaoComponent = styled(Modal).attrs({
    className: `painel-consulta border-0`
})`
    
    .tabela .registro, .legenda div{
        --cor-status-registro: black;
        
        &.cliente{
            --cor-status-registro: var(--cor-cliente);
        }
        
        &.clientelight{
            --cor-status-registro: var(--cor-clientelight);
        }
        
    }

    .tabela{

        .registro{
            
            .progress{
                font-weight: 500;
                
                .progress-bar{
                    background-color: var(--cor-status-registro);
                }
            }
            
            td{
                
                .descricao{
                    display: flex;
                    flex-direction: column;
                }
                
                .destaque{
                    font-size: 13px;
                }

                .line-small{
                    line-height: 0.9em;
                }

                pre{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: clamp(200px, 40vw, 600px);
                    margin: 0;
                }

                &:last-child{
                    text-align: right;
                }

                &.icone{
                    color: var(--cor-status-registro);
                    border-left: 3px solid var(--cor-status-registro);

                    padding: 0.25em 0.75em;
                }
            }
            
        }
        
    }

    .legenda{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5em;
        font-size: 0.8em;
        margin: 0.7em;

        div{
            display: flex;
            align-items: center;
            padding: 0 0.5em;
            border-radius: 0.3em;
            border-left: 3px solid;
            background: #fafafa;
            font-weight: 400;
            color: #434343;
            gap: 0.325em;

            border-color: var(--cor-status-registro);

            svg{
                color: var(--cor-status-registro);
            }
            
        }   
    }

`;