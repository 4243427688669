import styled from 'styled-components';

export const ParametroTesteComponent = styled.div.attrs({
    className: `rounded p-1 bg-white`
})`

    position: relative;
    background: transparent;
    padding-right: 0 !important;
    margin-right: 0 !important;

    ${props => props.raiz && `
        margin-bottom: 15px !important;
    `}

    &:first-child {
        margin-top: 0 !important;
    }

    button {
        padding: 0px 3px;
    }

    &:hover {
        /* background: #eee; */
    }

    .descricao {
        position: relative;

        input {
            padding-right: 24px;
        }

        .opcao {
            display: block;
            position: absolute;
            top: 5px;
            /* bottom: 0; */
            right: 5px;
            font-size: 0.7rem;
            padding: 1px;
            height: 19px;
            width: 19px;
            margin: auto;
        }
    }

    input {
        border: 0;
        border-bottom: 1px solid #ddd;
        border-radius: 0;
        background: transparent;
    }

    .dados {

        select {
            border: 0;
            border-bottom: 1px solid #ddd;
            border-radius: 0;
        }

        .select-tipo {
            text-transform: capitalize;
        }
    }

`;
