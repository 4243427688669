import styled from 'styled-components';

export const ContratoComponent = styled.div.attrs({
    className: ``
})`

    .imprimir{
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.5em;
        padding: 0.2em 0.5em;
        width: fit-content;
        background: whitesmoke;
        border-radius: 0.3em;
        box-shadow: 0 0 1px grey;
        font-weight: 600;
        font-size: 0.8em;

        &:hover{
            opacity: 0.8;
        }
    }

    .conteudo-contrato{
        overflow: auto;
        max-height: 80vh;
        margin: 0.5em;    
    }
`;