import React, { useContext, useState } from 'react';

export const FichaContexto = React.createContext(null);

// exporta hook
export function useFicha(){

    // pega o contexto
    const fichaContexto = useContext(FichaContexto);

    // retorna
    return fichaContexto;

}