import * as React from "react";

const SvgGraficoIcone = (props) => {
  props = {
    width: 15.5,
    height: 15.5,
    ...props,
  };
  return (
    <svg viewBox="0 0 128 128" {...props}>
      <style />
      <g id="grafico-icone_svg__relatorios">
        <path
          d="M117.5 109.5h-107c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1h107c.6 0 1-.4 1-1v-2c0-.6-.4-1-1-1z"
          fill="#a83130"
        />
        <linearGradient
          id="grafico-icone_svg__SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1={17.361}
          y1={91.5}
          x2={41.466}
          y2={91.5}
        >
          <stop offset={0.033} stopColor="#bf3837" />
          <stop offset={1} stopColor="#ff4b49" />
        </linearGradient>
        <path
          d="M38.5 73.5H20.4c-1.7 0-3 1.3-3 3v33h24.1v-33c0-1.7-1.4-3-3-3z"
          fill="url(#grafico-icone_svg__SVGID_1_)"
        />
        <linearGradient
          id="grafico-icone_svg__SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1={52.471}
          y1={83}
          x2={76.577}
          y2={83}
        >
          <stop offset={0.033} stopColor="#bf3837" />
          <stop offset={1} stopColor="#ff4b49" />
        </linearGradient>
        <path
          d="M73.6 56.5H55.5c-1.7 0-3 1.3-3 3v50h24.1v-50c0-1.7-1.4-3-3-3z"
          fill="url(#grafico-icone_svg__SVGID_2_)"
        />
        <g>
          <linearGradient
            id="grafico-icone_svg__SVGID_3_"
            gradientUnits="userSpaceOnUse"
            x1={86.534}
            y1={74}
            x2={110.639}
            y2={74}
          >
            <stop offset={0.033} stopColor="#bf3837" />
            <stop offset={1} stopColor="#ff4b49" />
          </linearGradient>
          <path
            d="M107.6 38.5H89.5c-1.7 0-3 1.3-3 3v68h24.1v-68c0-1.7-1.3-3-3-3z"
            fill="url(#grafico-icone_svg__SVGID_3_)"
          />
        </g>
        <g>
          <linearGradient
            id="grafico-icone_svg__SVGID_4_"
            gradientUnits="userSpaceOnUse"
            x1={12.12}
            y1={40.501}
            x2={103.303}
            y2={40.501}
          >
            <stop offset={0.033} stopColor="#bf3837" />
            <stop offset={0.2} stopColor="#b53534" />
            <stop offset={0.696} stopColor="#9d2e2d" />
            <stop offset={1} stopColor="#942b2a" />
          </linearGradient>
          <path
            d="M14.2 64.5c-.9 0-1.8-.6-2-1.5-.3-1.1.4-2.2 1.5-2.4.4-.1 45.1-11.3 86.2-43.6.9-.7 2.2-.6 3 .3.7.9.6 2.1-.3 2.8-41.9 32.8-87.4 44.2-87.9 44.3-.1.1-.3.1-.5.1z"
            fill="url(#grafico-icone_svg__SVGID_4_)"
          />
          <g>
            <linearGradient
              id="grafico-icone_svg__SVGID_5_"
              gradientUnits="userSpaceOnUse"
              x1={93.87}
              y1={20}
              x2={105.399}
              y2={20}
            >
              <stop offset={0.033} stopColor="#bf3837" />
              <stop offset={0.2} stopColor="#b53534" />
              <stop offset={0.696} stopColor="#9d2e2d" />
              <stop offset={1} stopColor="#942b2a" />
            </linearGradient>
            <path
              d="M103.8 14.5h-8.4c-.9 0-1.6.7-1.6 1.5s.7 1.5 1.6 1.5h6.8V24c0 .8.7 1.5 1.6 1.5.9 0 1.6-.7 1.6-1.5v-8c0-.8-.7-1.5-1.6-1.5z"
              fill="url(#grafico-icone_svg__SVGID_5_)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgGraficoIcone;
