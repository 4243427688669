import React from 'react';
import styles from './index.module.scss';

// componentes
import { Card, Form } from 'react-bootstrap';
import OpcaoBoleana from './../../OpcaoBoleana';
import { ClientesIcone, UsuariosIcone, FuncionariosIcone, GraficoIcone, TrelloIcone, EmailIcone } from './../../../../../../icones/svg';

// permissões funcionários
export default function Desenvolvimento({
    value,
    chaveAtualizando,
    onChange
}){

    return <>
        <Card
            className='border-0'
        >
            <Card.Body
            >
                <div className="font-weight-bold">
                    <TrelloIcone width={35} height={35} /> <span>Desenvolvimento</span>
                </div>
                <OpcaoBoleana 
                    descricao="Acessar Desenvolvimento"
                    chave="acessoDesenvolvimento"
                />
                <OpcaoBoleana 
                    descricao="Permite sincronizar Trello"
                    chave="trelloSincronizar"
                />
                <OpcaoBoleana 
                    descricao="Habilita painel para configuração do trello"
                    chave="habilitarConfiguracaoTrello"
                />
                <OpcaoBoleana 
                    descricao="Acessar Servidores"
                    chave="acessoServidores"
                />
                
            </Card.Body>
        </Card>
    </>
}