import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

// componentes
import { Card, Table, Button, Badge, InputGroup, FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { useHistory, useRouteMatch } from "react-router-dom";
import { faBell, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { toast } from 'react-toastify';

import Registro from './Registro';

import Paginacao from './../../../Componentes/Paginacao';
import ParametrosConsulta from './ParametrosConsulta';
import { NotificacoesTemplatesComponent } from './components';
import Confirmar from './../../../Componentes/Confirmar';

// import ModalExcluir from './ModalExcluir';

export default function Consultar(){

    // pega o histórico
    let history = useHistory();

    // pega dados
    let { path, url } = useRouteMatch();

    // estados
    const [ carregando, alterarCarregando ] = useState(true);
    const [ totalRegistros, atualizarTotalRegistros ] = useState(0);
    const [ parametros, alterarParametros ] = useState({
        pagina: 1,
        registrosPorPagina: isMobile ? 3 : 10,
        pesquisa: null
    });
    let [ consulta, alterarConsulta ] = useState([]);
    let [ mostrarFiltros, alterarMostrarFiltros ] = useState(false);
    let [ confirmar, alterarConfirmar ] = useState('');

    // inicializa
    useEffect(() => {
        
        // faz a consulta
        fazerConsulta();

    }, [parametros]);

    // faz consulta
    async function fazerConsulta(){

        // carregando
        alterarCarregando(true);

        // faz a requisição
        try{
            let { data } = await axios.get('/notificacoes/templates', {
                params: parametros
            });

            // finalizado
            alterarConsulta(data.registros);
            atualizarTotalRegistros(data.totalRegistros)

        }catch(e){

        }finally{
            alterarCarregando(false);
        }
    }
    
    // faz a exclusão
    async function deletar(dados){

        try{

            // faz a exclusão
            let { data } = await axios.delete(`/notificacoes/templates/${dados.id}`);

            // excluído com sucesso

            // consulta

            // atualiza registros
            alterarParametros({ pagina: 1 });
            
        }catch({response}){

            // informa erro
            toast(<>
                <div
                    style={{background: '#ff6271'}}
                >
                    <div className="toast-header">
                        <strong className="mr-auto">Template não pôde ser deletado</strong>
                        <button 
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                        </button>
                    </div>
                    <div className="toast-body text-light">
                        {
                            (response && response.data) &&
                            Object.keys(response.data).map((erro) => <p className={'m-0'} key={erro}>{response.data[erro]}<br/></p>)
                        }
                    </div>
                </div>
            </>);

        }finally{

            // remove o carregamento
            alterarCarregando(false);

        }

    }
    
    // ao clicar para excluir
    function clicouExcluirRegistro(registro){

        // confirma
        alterarConfirmar({
            aberto: true,
            titulo: 'Deletar template',
            texto: `Tem certeza que deseja deletar o template de notificação?`,
            textoBotao: 'Deletar',
            variantConfirmar: 'danger',
            variantCancelar: 'secondary',
            backdrop: true,
            aoConfirmar: () => {

                // deleta o registro
                deletar(registro.dados);
            },
            aoCancelar: () => {
                // ao cancelar
            },
            aoFechar: () => {
                // ao fechar
            }
        })
    }

    // retorna registro
    let retornaRegistros = () => {

        // retorna registros organizado
        return consulta.map(registro => 
            <Registro 
                key={registro.dados.id}
                dados={registro.dados}
                clicouEditar={() => {
                    history.push(`${path}/editar/${registro.dados.id}`);
                }}
                clicouExcluir={() => {

                    // executa a exclusão
                    clicouExcluirRegistro(registro);
                }}
            />
        )
    }

    // return <div>TESTE</div>

    return <NotificacoesTemplatesComponent>
        <Confirmar config={confirmar} alterar={alterarConfirmar}/>
        <Card className="painel-consulta border-0 bg-transparent" >
            <Card.Header>
                <div className={'d-flex justify-content-between'}>
                    <div className='flex-grow-1'>
                        <Button
                            variant='padrao'
                            size='sm'
                            onClick={() => {
                                history.push('/painel/notificacoes');
                            }}
                        >
                            <FontAwesomeIcon className="icone" icon={["fas", "back"]} />
                            <span>Voltar para notificações</span>
                        </Button>
                    </div>
                    <Card.Title className={'flex-grow-1 mb-0'}>Templates notificações</Card.Title>
                    <div className='flex-grow-1 text-right'>
                        <Button
                            variant='success'
                            size='sm'
                            onClick={() => {
                                history.push('/painel/notificacoes/templates/cadastrar');
                            }}
                        >
                            <FontAwesomeIcon className="icone" icon={["fas", "plus"]} />
                            <span>Novo</span>
                        </Button>
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                <ParametrosConsulta 
                    parametros={parametros}
                    alterarParametros={alterarParametros}
                    mostrarFiltros={mostrarFiltros}
                    alterarMostrarFiltros={alterarMostrarFiltros}
                />

                {
                    carregando ? <div className="p-5 text-center">
                        <FontAwesomeIcon icon={faSpinner} pulse /> Carregando...
                    </div> : <>
                        <div 
                            className={'my-3'}
                        >
                            <BrowserView>
                                <table className="table table-hover tabela">
                                    {totalRegistros > 0 && <>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Título</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { retornaRegistros() }
                                        </tbody>
                                    </>}
                                </table>
                            </BrowserView>

                            <MobileView>
                                { retornaRegistros() }
                            </MobileView>
                        </div>
                        
                        <Paginacao
                            pagina={parametros.pagina}
                            registrosPorPagina={parametros.registrosPorPagina}
                            totalRegistros={totalRegistros}
                            alterouPagina={(pagina) => {
                                alterarParametros({...parametros, pagina: pagina});
                            }}
                        />
                    </>
                }
            </Card.Body>
        </Card>
    </NotificacoesTemplatesComponent>
}